import {Tab} from 'react-admin';
import React from 'react';
import RawPerfomanceLog from '../../../ToolsReports/RawPerfomanceLog';

const Tools = props => {

	const {id, teamData} = props;

	return (
		<Tab label={'Tools'}>
			<RawPerfomanceLog teamId={id} teamData={teamData}/>
		</Tab>
	);

};

export default Tools;