import React from 'react';

const Label = props => {
	const {label, paddingBottom = '26px', color = 'inherit'} = props;

	return (
		<div
			style={{
				fontFamily: 'Montserrat Medium',
				color: color,
				height: '17px',
				paddingBottom: props.paddingBottom?props.paddingBottom :paddingBottom,
				marginBottom: props.marginBottom,
			}}
		>
			{label}
		</div>
	);
};

export default Label;
