import {DefaultNodeModelOptions, NodeModel, NodeModelGenerics} from '@projectstorm/react-diagrams';

import {CustomPortModel} from '../../Common/CustomPort/CustomPortModel';

export interface StartNodeModelOptions extends DefaultNodeModelOptions {
    color?: string;
    innerLabel?: string;
    captionLabel?: string;
    record: any;
    viewRecordDetails?: (record: any) => void
}

export interface StartNodeModelGenerics extends NodeModelGenerics {
    PORT: CustomPortModel;
    OPTIONS: StartNodeModelOptions
}

export class StartNodeModel extends NodeModel<StartNodeModelGenerics> {
    constructor(options?: StartNodeModelOptions) {
        super({
            type: 'start',
            ...options
        });
        
        // TOP ports.
        this.addPort(new CustomPortModel('top-start'));
        this.addPort(new CustomPortModel('top-middle'));
        this.addPort(new CustomPortModel('top-end'));

        // BOTTOM ports.
        this.addPort(new CustomPortModel('bottom-start'));
        this.addPort(new CustomPortModel('bottom-middle'));
        this.addPort(new CustomPortModel('bottom-end'));

        // LEFT ports.
        this.addPort(new CustomPortModel('left-start'));
        this.addPort(new CustomPortModel('left-middle'));
        this.addPort(new CustomPortModel('left-end'));

        // RIGHT ports.
        this.addPort(new CustomPortModel('right-start'));
        this.addPort(new CustomPortModel('right-middle'));
        this.addPort(new CustomPortModel('right-end'));
    }

    setColor(color?: string | null) {
        this.options.color = color;
    }
    getColor() {
        return this.options.color;
    }

    setInnerLabel(innerLabel: string) {
        this.options.innerLabel = innerLabel;
    }
    getInnerLabel(){
        return this.options.innerLabel;
    }

    setCaptionLabel(captionLabel: string) {
        this.options.captionLabel = captionLabel;
    }
    getCaptionLabel(){
        return this.options.captionLabel;
    }

    setRecord(record: any) {
        this.options.record = record;
    }
    getRecord() {
        return this.options.record;
    }

    setViewRecordDetailsHandler(handler: (record: any) => void) {
        this.options.viewRecordDetails = handler;
    }

    viewRecordDetails() {
        if (this.options.viewRecordDetails != null && this.options.record != null) {
            this.options.viewRecordDetails(this.options.record);
        }
    }
}
