import {EmbeddedList, StatusChip, TimeSpanField} from 'components';
import {NumberField, useTranslate} from 'react-admin';

import PercentField from 'components/PercentField';
import React from 'react';

const ObjectiveList = props => {
	const {userData, selectedTrainingRecord, selectedStep, attemptId} = props;

	const translate = useTranslate();

	return (
		<EmbeddedList
			embedded={false}
			subTitle
			title={translate('Observable Progress')}
			resource='listLessonObjectivesForUserLabsRunway'
			columns={[
				{
					id: 'name',
					name: translate('Observable Name'),
					sortable: true,
				},
				{
					id: 'status',
					name: translate('Status'),
					sortable: true,
					align: 'center',
					render: record => <StatusChip record={record} source='status' />,
					isInverted: true,
				},
				{
					id: 'attempts',
					name: translate('Attempts'),
					sortable: true,
					align: 'center',
					render: record => <NumberField record={record} source='attempts' />,
					isInverted: true,
				},
				{
					id: 'totalTimeSec',
					name: translate('time'),
					sortable: true,
					align: 'center',
					render: record => (
						<TimeSpanField record={record} source='totalTimeSec' />
					),
					isInverted: true,
				},
				{
					id: 'score',
					name: translate('Score'),
					sortable: true,
					align: 'center',
					render: record => <NumberField record={record} source='score' />,
					isInverted: true,
				},
				{
					id: 'maxScore',
					name: translate('Max Score'),
					sortable: true,
					align: 'center',
					render: record => <NumberField record={record} source='maxScore' />,
				},
				{
					id: 'displayWeight',
					name: translate('Weight'),
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='displayWeight' />
					),
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[
				{id: 'trainingId', value: selectedTrainingRecord.id},
				{id: 'userId', value: userData.id},
				{id: 'lessonId', value: selectedStep.id},
				{id: 'sId', value: attemptId},
			]}
			dynamicFilters={[
				{
					id: 'status',
					name: translate('Status'),
					type: 'exact',
					choices: [
						{value: '*', label: translate('All Statuses')},
						{value: 'not started', label: translate('Not Started')},
						{value: 'aced', label: translate('Aced')},
						{value: 'passed', label: translate('Passed')},
						{value: 'incomplete', label: translate('Incomplete')},
					],
				},
			]}
			bulkActions={[]}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default ObjectiveList;
