import {
	EmbeddedShow,
	EmbeddedTabs,
	ScreenTitle,
	StatsTilePanel,
	StatusChip,
	TimeDateField,
} from '../../..';
import {GET_LIST, useQueryWithStore} from 'react-admin';
import React, {useState} from 'react';

import Collapsible from '../../../Collapsible';
import Indicators from '../../../TrainingCatalogue/Indicators';
import LoadingStat from '../../../loading/LoadingStat';
import ObjectiveList from '../ObjectiveList';
import Skills from './Skills';
import {useTheme} from '@material-ui/core';

const StepDetail = props => {
	const {
		selectedLab,
		selectedStep,
		selectedTrainingRecord,
		backTrainingAction,
		teamBackAction,
		backLabAction,
		backStepAction,
		userData,
		attemptId,
		teamName,
	} = props;

	const theme = useTheme();

	const [
		activeLearningObjectiveIndex,
		setActiveLearningObjectiveIndex,
	] = useState(0);

	const {data: lessonObjectivesStats, loading} = useQueryWithStore({
		type: GET_LIST,
		resource: 'LessonObjectivesUserTrainingLabStats',
		payload: {
			filter: {
				userId: userData.id,
				trainingId: selectedTrainingRecord.id,
				lessonId: selectedStep.id,
				sId: attemptId,
			},
			pagination: {},
			sort: {},
		},
	});

	return (
		<div>
			<ScreenTitle
				title={selectedStep.name}
				refresh={false}
				subTitle={true}
				coloredCrumb={true}
				titleCrumbs={
					teamName
						? [
								teamName,
								(userData.name &&
									userData.name.trim().length > 0 &&
									userData.name) ||
									userData.userId,
								selectedTrainingRecord.name,
								selectedLab.name,
						  ]
						: [
								(userData.name &&
									userData.name.trim().length > 0 &&
									userData.name) ||
									userData.userId,
								selectedTrainingRecord.name,
								selectedLab.name,
						  ]
				}
				backActionCrumbs={
					teamBackAction
						? [
								teamBackAction,
								backTrainingAction,
								backLabAction,
								backStepAction,
						  ]
						: [backTrainingAction, backLabAction, backStepAction]
				}
			/>
			<div
				style={{
					backgroundColor: theme.palette.background.contained,
					padding: '0px 16px 16px 16px',
					boxShadow: '0px 5px 20px #00000026',
				}}
			>
				<EmbeddedShow
					embedded
					title={selectedStep.name}
					actions={[]}
					details={[
						{
							label: 'Status',
							render: record => (
								<StatusChip record={selectedStep} source='status' />
							),
						},
						{
							label: 'User Added',
							render: record => (
								<TimeDateField record={selectedStep} source='userAdded' />
							),
						},
						{
							label: 'Completed',
							render: () => (
								<TimeDateField record={selectedStep} source='completedAt' />
							),
						},
					]}
				/>
				<div
					style={{
						display: 'flex',
						width: '100%',
						columnGap: '30px',
						paddingBottom: '8px',
					}}
				>
					{selectedStep.description && (
						<div
							style={{
								width:
									selectedStep?.learningObjectives?.length > 0 ? '50%' : '100%',
							}}
						>
							<Collapsible title={'Description'}>
								<p style={{paddingLeft: '15px'}}>{selectedStep.description}</p>
							</Collapsible>
						</div>
					)}
					{selectedStep?.learningObjectives?.length > 0 && (
						<div style={{width: selectedStep.description ? '50%' : '100%'}}>
							<Collapsible title={'Learning Objectives'}>
								<div style={{paddingLeft: '15px'}}>
									<p
										dangerouslySetInnerHTML={{
											__html:
												selectedStep.learningObjectives[
													activeLearningObjectiveIndex
												],
										}}
									></p>
									<Indicators
										length={selectedStep.learningObjectives.length}
										active={activeLearningObjectiveIndex}
										press={index => setActiveLearningObjectiveIndex(index)}
									/>
								</div>
							</Collapsible>
						</div>
					)}
				</div>
			</div>
			<div style={{marginTop: '24px'}}>
				{(lessonObjectivesStats && !loading) ? (
					<StatsTilePanel stats={lessonObjectivesStats} />
				) : (
					<LoadingStat />
				)}
			</div>
			<EmbeddedTabs
				data={{
					tabs: [
						{
							name: 'Observables',
							content: <ObjectiveList {...props} attemptId={attemptId} />,
						},
						{
							name: 'Skills',
							content: (
								<Skills
									userId={userData.id}
									trainingId={selectedTrainingRecord.id}
									lessonId={selectedStep.id}
									labId={selectedStep.id}
									attemptId={attemptId}
								/>
							),
						},
					],
				}}
			/>
		</div>
	);
};

export default StepDetail;
