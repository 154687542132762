import {
	EmbeddedShow,
	ScreenTitle,
	SelectInput,
	StatusChip,
	TextValue,
	TimeDateField,
} from 'components';
import {fetchEnd, fetchStart, GET_LIST, useVersion, withDataProvider} from 'react-admin';
import React, {useEffect, useState} from 'react';

import LessonDetailView from '../../LessonDetailView';
import compose from 'recompose/compose';
import { useDispatch } from 'react-redux';

const LessonDetail = props => {
	const {
		selectedLessonRecord,
		backLessonAction,
		selectedTrainingRecord,
		backTrainingAction,
		teamBackAction,
		dataProvider,
		userData,
		teamData,
	} = props;

	const [state, setState] = useState({});

	const [loading, setLoading] = useState(false);

	const version = useVersion();

	const dispatch = useDispatch();

	useEffect(() => {
		fetch();
	}, [version]);

	const fetch = async () => {
        try {
		    setLoading(true);
			dispatch(fetchStart());
			const data = await dataProvider(GET_LIST, 'lessonusertrainingstats', {
				filter: {
					id: props.id,
					userId: props.id,
					trainingId: selectedTrainingRecord.id,
					lessonId: selectedLessonRecord.id,
				},
				pagination: {},
				sort: {},
			});
	
			setState({
				...state,
				lessonusertrainingstats: data.data,
			});
			const userLessonSkillStats = await dataProvider(
				GET_LIST,
				'UserLessonSkillStatsWithLessonAttempts',
				{
					filter: {
						id: props.id,
						userId: props.id,
						trainingId: selectedTrainingRecord.id,
						lessonId: selectedLessonRecord.id,
					},
					pagination: {},
					sort: {},
				}
			);
			const skillStatsData = getSkillStatsChoices(userLessonSkillStats.data);
			setState({
				...state,
				lessonusertrainingstats: data.data,
				userLessonSkillStats: userLessonSkillStats.data,
				lessonAttemptsChoices: skillStatsData.choices,
				lessonAttemptsSelectedElem: skillStatsData.selectedElem,
				lessonAttemptsSelectedValue: skillStatsData.selectedValue,
			});
		} finally {
			dispatch(fetchEnd());
			setLoading(false);
		}
	};

	const getSkillStatsChoices = data => {
		data.sort(
			(a, b) =>
				new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
		);

		let element = data.find(ele => ele.bestAttempt === true);
		let selectedElem = element ? element : data[0];
		let choices;
		let createdAt;
		if (selectedElem.totalAttempts === 0) {
			choices = [
				{
					id: 'Not Started',
					name: 'Not Started',
				},
			];
			createdAt = 'Not Started';
		} else {
			choices = data.map((elem, index) => {
				let formattedTime = new Date(elem.createdAt)
					.toLocaleString()
					.replace(',', '')
					.replace(/:\d{2}\s/, ' ');
				let prefix = 'Attempt ' + ++index;
				let value = elem.bestAttempt
					? prefix.concat(' (Best)- ', formattedTime)
					: prefix.concat(' - ', formattedTime);
				return {id: elem.createdAt, name: value};
			});
			createdAt = selectedElem.createdAt;
		}

		return {
			choices: choices,
			selectedElem: selectedElem,
			selectedValue: createdAt,
		};
	};

	const handleChange = event => {
		const newValue = event.target.value;
		const userLessonSkillStats = state.userLessonSkillStats;
		let element = userLessonSkillStats.find(ele => ele.createdAt === newValue);
		setState({
			...state,
			lessonAttemptsSelectedElem: element,
			lessonAttemptsSelectedValue: newValue,
		});
	};

	const changeSelectChoiceFromGraph = value => {
		const newValue = value;
		const userLessonSkillStats = state.userLessonSkillStats;
		let element = userLessonSkillStats.find(ele => ele.createdAt === newValue);
		setState({
			...state,
			lessonAttemptsSelectedElem: element,
			lessonAttemptsSelectedValue: newValue,
		});
	};

	return (
		<div>
			<ScreenTitle
				title={selectedLessonRecord.name}
				refresh={false}
				subTitle={true}
				coloredCrumb={true}
				titleCrumbs={
					teamData
						? [
							teamData.name,
								(userData.name &&
									userData.name.trim().length > 0 &&
									userData.name) ||
									userData.userId,
								selectedTrainingRecord.name,
						  ]
						: [
								(userData.name &&
									userData.name.trim().length > 0 &&
									userData.name) ||
									userData.userId,
								selectedTrainingRecord.name,
						  ]
				}
				backActionCrumbs={
					teamBackAction
						? [teamBackAction, backTrainingAction, backLessonAction]
						: [backTrainingAction, backLessonAction]
				}
			/>
			<LessonDetailView
			    {...props}
			    loading={loading}
				lessonData={selectedLessonRecord}
				lessonTrainingStats={state.lessonusertrainingstats}
				lessonSkillStats={state.lessonAttemptsSelectedElem}
				selectedAttemptValue={state.lessonAttemptsSelectedValue}
				userLessonSkillStats={state.userLessonSkillStats}
				changeSelectChoiceFromGraph={changeSelectChoiceFromGraph}
				vectorMapContextData={{
					teamId: undefined,
					userId: userData ? userData.id : undefined,
					trainingId: selectedTrainingRecord
						? selectedTrainingRecord.id
						: undefined,
					lessonId: selectedLessonRecord ? selectedLessonRecord.id : undefined,
				}}
				skillBarPanelActions={[
					{
						render: () => {
							return (
								<SelectInput
									choices={state.lessonAttemptsChoices}
									value={
										state.lessonAttemptsSelectedValue
											? state.lessonAttemptsSelectedValue
											: ''
									}
									disabled={state.lessonAttemptsSelectedValue === 'Not Started'}
									onChange={event => {
										handleChange(event);
									}}
									width='100%'
								/>
							);
						},
					},
				]}
				information={
					<EmbeddedShow
						embedded
						headerless
						details={[
							{
								label: 'Name',
								render: record => (
									<TextValue
										record={selectedLessonRecord}
										source='name'
										maxWidth={'300px'}
									/>
								),
							},
							{
								label: 'User Added',
								render: record => (
									<TimeDateField
										record={selectedLessonRecord}
										source='userAdded'
									/>
								),
							},
							{
								label: 'Completed',
								render: record => (
									<TimeDateField
										record={selectedLessonRecord}
										source='completedDate'
									/>
								),
							},
							{
								label: 'Status',
								render: record => (
									<StatusChip record={selectedLessonRecord} source='status' />
								),
							},
							{
								label: 'Type',
								render: record => (
									<StatusChip
										record={selectedLessonRecord}
										source='lessonType'
									/>
								),
							},
						]}
					/>
				}
			/>
		</div>
	);
};

export default compose(withDataProvider)(LessonDetail);
