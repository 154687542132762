import EmbeddedShow from './EmbeddedShow';
import React from 'react';

const FullscreenError = ({message, statusCode, requestingIP}) => {
	return (
		<div
			style={{
				left: 0,
				top: 0,
				overflowY: 'hidden',
				overflowX: 'hidden',
				position: 'absolute',
				display: 'flex',
				flexDirection: 'column',
				width: '100vw',
				height: '100vh',
			}}
		>
			<div style={{flex: 2}} />
			<div
				style={{
					margin: 'auto',
					fontSize: '16px',
					width: '380px',
				}}
			>
				<EmbeddedShow
					title='Error'
					content={
						<div>
							{statusCode && requestingIP ? (
								<div>
									{`Cannot login from this location.`}
									<br />
									{`Your IP:`}
									<b>{` ${requestingIP}`}</b>
									<br />
									<br />{' '}
									{` Please check with your administrator to whitelist this IP.`}
								</div>
							) :
							`Connection error. Please try again later.`}
						</div>
					}
				/>
			</div>
			<div style={{flex: 4}} />
		</div>
	);
};

export default FullscreenError;
