import React, {useEffect} from 'react';
import {
	Dialog,
	DialogContent,
	IconButton,
	Typography,
	createStyles,
	withStyles,
} from '@material-ui/core';

import {useTheme} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SkillIcon from '../assets/skillIcons';
import {LinearProgressBar} from 'components';

const styles = theme =>
	createStyles({
		paper: {
			top: '30%',
			minWidth: '600px',
		},
		dialogContent: {
			padding: '16px',
		},
		dialogRow: {
			display: 'flex',
			flexDirection: 'row',
		},
		dialogColumn: {
			display: 'flex',
			flexDirection: 'column',
		},
		skillName: {
			fontFamily: 'Archivo Bold',
			fontSize: '16px',
		},
		skillDetails: {
			fontFamily: 'Montserrat Semi Bold',
			fontSize: '14px',
		},
		skillPoints: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		skillPointsReverse: {
			display: 'flex',
			flexDirection: 'row-reverse',
		},
		skillBar: {
			gridArea: 'bar',
			padding: '10px 0',
		},
		displayValue: {
			color: theme.palette.primary.main,
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
		mainContent: {
			display: 'flex',
			flexDirection: 'row',
			columnGap: '20px',
		},
		iconButton: {
			padding: '0',
			float: 'right',
		},
	});

const SkillPopover = props => {
	const {isPopoverOpen, setIsPopoverOpen, classes, skillData} = props;

	const theme = useTheme();

	useEffect(() => {
		const close = e => {
			if (e.keyCode === 27) {
				setIsPopoverOpen(false);
			}
		};
		document.addEventListener('keydown', close);
		return () => document.removeEventListener('keydown', close);
	}, []);

	return (
		<Dialog
			onClick={e => {
				e.stopPropagation();
				setIsPopoverOpen(false);
			}}
			aria-labelledby='customized-dialog-title'
			open={isPopoverOpen}
			maxWidth={'md'}
			classes={{
				paper: classes.paper,
			}}
		>
			{skillData && (
				<DialogContent className={classes.dialogContent}>
					<div className={classes.mainContent}>
						<SkillIcon record={skillData} width={80} height={80} />
						<div style={{flex: '1 1 auto'}}>
							<div style={{display: 'flex', justifyContent: 'space-between'}}>
								<Typography className={classes.skillName}>
									{skillData.displayName}
								</Typography>
								<IconButton
									aria-label='close'
									onClick={e => {
										e.stopPropagation();
										e.preventDefault();
										setIsPopoverOpen(false);
									}}
									className={classes.iconButton}
								>
									<CloseIcon />
								</IconButton>
							</div>
							<p style={{margin: '5px 0'}}>{skillData?.description}</p>
							<div style={{display: 'flex', gap: '20px'}}>
								<div style={{display: 'flex', gap: '5px'}}>
									<Typography className={classes.skillDetails}>
										Skill Type:
									</Typography>
									<span>{skillData?.type}</span>
								</div>
								<div style={{display: 'flex', gap: '5px'}}>
									<Typography className={classes.skillDetails}>
										Skill Category:
									</Typography>
									<span>{skillData?.category}</span>
								</div>
							</div>
						</div>
					</div>
					{skillData.subValue && (
						<div className={classes.dialogColumn}>
							<hr className={classes.divider} />
							<div
								className={
									skillData.orgDisplayValue
										? classes.skillPoints
										: classes.skillPointsReverse
								}
							>
								<Typography className={classes.skillDetails}>
									{skillData.subLabel + ': '}
									<span className={classes.displayValue}>
										{skillData.subValue}
									</span>
								</Typography>

								{skillData.orgDisplayValue && (
									<Typography className={classes.skillDetails}>
										{skillData.orgLabel + ': '}
										<span className={classes.displayValue}>
											{skillData.orgDisplayValue}
										</span>
									</Typography>
								)}
							</div>
							<div className={classes.skillBar}>
								<LinearProgressBar
									hideValue
									value={Math.ceil(skillData.value * 100)}
									backgroundColor={theme.palette.background.default}
								/>
							</div>
							{skillData?.skillFeedback && (
								<div>
									<Typography className={classes.skillDetails}>
										Skill Feedback:
									</Typography>
									<p style={{margin: '5px 0'}}>{skillData?.skillFeedback}</p>
								</div>
							)}
						</div>
					)}
				</DialogContent>
			)}
		</Dialog>
	);
};

export default withStyles(styles)(SkillPopover);
