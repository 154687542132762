import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDataProvider, fetchStart, fetchEnd, useVersion } from "react-admin";
import { StatsTilePanel } from "components";
import LoadingStat from "components/loading/LoadingStat";
import PanelStat from "components/Panels/PanelStat";

const TeamTrainingStats = (props) => {
  const { id, selectedTrainingRecord } = props;
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const [teamtrainingstats, setTeamTrainingStats] = useState(null);
  const [loading, setLoading] = useState(true);

  const version = useVersion();

  useEffect(() => {
    const fetchData = async () => {
        console.log('fetched')
      try {
        dispatch(fetchStart());
        setLoading(true);
        
        const { data } = await dataProvider.getList("teamtrainingstats", {
          filter: {
            id,
            teamId: id,
            trainingId: selectedTrainingRecord.id,
          },
          pagination: {},
          sort: {},
        });
        
        setTeamTrainingStats(data);
        setLoading(false);
        
        dispatch(fetchEnd());
      } catch (error) {
        console.error(error);
        setLoading(false);
        dispatch(fetchEnd());
      }
    };

    fetchData();
  }, [version]);

  return (
    <div style={{ paddingTop: "24px" }}>
      {loading ? (
        <PanelStat>
          <LoadingStat />
        </PanelStat>
      ) : (
        <StatsTilePanel stats={teamtrainingstats} />
      )}
    </div>
  );
};

export default TeamTrainingStats;
