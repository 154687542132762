import List from './List';
import Show from './Show';
import {Module} from 'assets/MenuIcon';

const trainingShow = {
	name: 'trainings',
	show: Show,
	list: List,
	icon: Module,
};

export default trainingShow;
