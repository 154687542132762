import mailIcon from 'assets/MailIcon.svg';
import { LinearProgress } from 'react-admin';
import React from 'react';
import TextValue from './TextValue';
import get from 'lodash/get';
import ResourceRedirect from "components/ResourceRedirect";

const EmailValue = ({ source, nameSource, email, record = {}, altRecord, maxWidth, redirect }) => {
	const renderEmail = value => {
		return (
			<span style={{ display: 'flex' }}>
				{
					redirect ? <ResourceRedirect
						id={record.ownerId}
						value={record[nameSource]}
						resource={'users'}
						fontFamily={'Montserrat Medium'}
						link
					/> : <TextValue record={record} source={nameSource} maxWidth={maxWidth}> </TextValue>
				}
				{value && value !== null && value !== 'null' && value !== undefined && (<div style={{ height: 0, float: 'right' }}>
					<div
						style={{
							marginLeft: '1em',
							width: '24px',
							height: '24px',
							borderRadius: '4px',
						}}
					>
						<div
							style={{
								width: '16px',
								height: '16px',
								margin: 'auto',
							}}
						>
							<a
								href={'mailto:' + value}
								style={{
									fontSize: '16px',
									paddingTop: '40px',
								}}
							>
								<div
									style={{
										paddingTop: '2px',
									}}
								>
									<img
										style={{ width: '16px', }}
										src={mailIcon}
										alt='mail icon'
									/>
								</div>
							</a>
						</div>
					</div>
				</div>)}
			</span>
		);
	};
	if (altRecord) {
		record = altRecord;
	}

	if (!nameSource) {
		nameSource = source;
	}

	if (!record) {
		return <LinearProgress />
	}

	if (!get(record, source)) {
		return <span>---</span>;
	}

	return renderEmail(get(record, email));
};

export default EmailValue;
