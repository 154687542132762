import React from 'react';
import useDevelop from '../../hooks/useDevelop';

/**
 * Renders any parameters passed in. Useful for quick testing a records contents.
 *
 * @param props
 */
const DebugContainer = props => {
	const {label = 'Debug Values', children, width = '100%'} = props;

	const {isDebug} = useDevelop();

	if (!isDebug) {
		return <div />;
	}

	return (
		<div
			style={{
				border: '4px rgba(255, 255, 255, 0.5) solid',
				outline: '1px rgba(255, 255, 255, 1) solid',
				backgroundColor: 'rgba(255, 255, 255, 0.01)',
				background: `repeating-linear-gradient(
					45deg,
					rgba(70, 82, 152, 0.5),
					rgba(70, 82, 152, 0.5) 20px,
					rgba(96, 109, 188, 0.5) 20px,
					rgba(96, 109, 188, 0.5) 40px
				)`,
				width: width,
				overflow: 'none',
			}}
		>
			<div
				style={{backgroundColor: 'gray', padding: '4px', paddingLeft: '16px'}}
			>
				<b style={{fontSize: '20px'}}> {label}</b> <br />
				<br />{' '}
			</div>
			<div style={{padding: '20px'}}>
				<div style={{outline: '2px dashed rgba(0,0,0,0.3)'}}>{children}</div>
			</div>
		</div>
	);
};

export default DebugContainer;
