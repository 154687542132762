var defaultProxy = 'https://restportalproxy.internalg.braincloudservers.com';
var envProxy = process.env.REACT_APP_REST_PROXY;
var targetProxy = envProxy ? envProxy : defaultProxy;

const poulateTermsOfServiceStatus = async (project) => {
    console.log('Populating Terms Of Service Status', project);

    // First, we get the latest runwayOrgConfig.
    let packetId = parseInt(sessionStorage.getItem(project + '-packetId')) + 1;
    if (!packetId) packetId = 1;
    sessionStorage.setItem(project + '-packetId', packetId.toString());

    const runwayOrgconfigRequest = new Request(targetProxy + '/v1/runwayOrgConfig', {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${sessionStorage.getItem(project + '-token')}`,
            'Content-Type': 'application/json',
            'X-PROJECT': project,
            'X-PACKETID': packetId
        }),
    });

    return fetch(runwayOrgconfigRequest).then(async (response) => {
        if (response && response.status >= 200 && response.status < 300) {
            const json = await response.json();
            sessionStorage.setItem('runwayOrgConfig', JSON.stringify(json.data));
        } else {
            sessionStorage.removeItem('runwayOrgConfig');
        }

        // Once we have the latest runwayOrgConfig, we can proceed with getting the Terms Of Service status.
        let packetId = parseInt(sessionStorage.getItem(project + '-packetId')) + 1;
        if (!packetId) packetId = 1;
        sessionStorage.setItem(project + '-packetId', packetId.toString());

        const termsOfServiceStatusRequest = new Request(targetProxy + '/v1/termsOfService', {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${sessionStorage.getItem(project + '-token')}`,
                'Content-Type': 'application/json',
                'X-PROJECT': project,
                'X-PACKETID': packetId
            }),
        });

        return fetch(termsOfServiceStatusRequest).then(async (response) => {
            if (response && response.status >= 200 && response.status < 300) {
                const json = await response.json();
                sessionStorage.setItem(project + '-termsOfService', JSON.stringify(json.data));
            } else {
                sessionStorage.setItem(project + '-termsOfService', JSON.stringify({ error: 'Unable to populate Terms Of Service Status: HTTP Status ' + response.status }));
            }
        });
    }).catch(error => {
        sessionStorage.setItem(project + '-termsOfService', JSON.stringify({ error: 'Unable to populate Terms Of Service Status: Error ' + error }));
    });
};

const hasUserAcceptedLatestTermsOfService = (project) => {

    // const runwayOrgConfigRaw = sessionStorage.getItem('runwayOrgConfig');
    // const runwayOrgConfig = runwayOrgConfigRaw ? JSON.parse(runwayOrgConfigRaw) : null;
    // const termsOfServiceRaw = sessionStorage.getItem(project + '-termsOfService');
    // const termsOfService = termsOfServiceRaw ? JSON.parse(termsOfServiceRaw) : null;

    // if (runwayOrgConfig && runwayOrgConfig.config && runwayOrgConfig.config.TermsOfServiceRequired === 'true') {
    //     if (!termsOfService || !termsOfService.userAccepted) {
    //         return false;
    //     }
    // }

    return sessionStorage.getItem('hasAccepetedTermsOfService') === 'true';
};

const acceptTermsOfService = async (project, termsOfService) => {
    console.log('Accept Terms Of Service', project, termsOfService);

    let packetId = parseInt(sessionStorage.getItem(project + '-packetId')) + 1;
    if (!packetId) packetId = 1;
    sessionStorage.setItem(project + '-packetId', packetId.toString());

    const request = new Request(targetProxy + '/v1/termsOfService', {
        method: 'POST',
        headers: new Headers({
            'Authorization': `Bearer ${sessionStorage.getItem(project + '-token')}`,
            'Content-Type': 'application/json',
            'X-PROJECT': project,
            'X-PACKETID': packetId
        }),
        body: JSON.stringify(termsOfService.termsEntity)
    });

    return fetch(request).then(async (response) => {
        if (response && response.status >= 200 && response.status < 300) {
            termsOfService.userAccepted = true;
            delete termsOfService.error;
            sessionStorage.setItem(project + '-termsOfService', JSON.stringify(termsOfService));
        } else {
            termsOfService.error = 'Unable to accept Terms Of Service: HTTP Status ' + response.status;
            sessionStorage.setItem(project + '-termsOfService', JSON.stringify(termsOfService));
        }
    }).catch(error => {
        termsOfService.error = 'Unable to accept Terms Of Service: Error ' + error;
        sessionStorage.setItem(project + '-termsOfService', JSON.stringify(termsOfService));
    });
};

const termsOfService =  {
    poulateTermsOfServiceStatus: poulateTermsOfServiceStatus,
    hasUserAcceptedLatestTermsOfService: hasUserAcceptedLatestTermsOfService,
    acceptTermsOfService: acceptTermsOfService,
}

export default termsOfService;
