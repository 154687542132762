import {useHistory, useLocation, Redirect} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import {
	fetchEnd,
	fetchStart,
	GET_LIST,
	MenuItemLink,
	useDataProvider,
	useTranslate,
	useVersion,
} from 'react-admin';
import {EmbeddedList, ScreenTitle, TimeDateField, StatusChip} from 'components';
import Grid from 'components/layout/Grid';
import PercentField from 'components/PercentField';
import ConfigurePackage from './ConfigurePackageButton';
import VectorMap from 'components/VectorMap';
import {Cancel} from '@material-ui/icons';
import {Avatar, Button, Chip} from '@material-ui/core';
import {createStyles, withStyles} from '@material-ui/core/styles';
import trainingModuleIcon from 'assets/MiniCardIcons/TrainingModule.svg';

import {loadObject} from 'utils/Util';
import {getUserInfo} from 'utils/Data';
import {vectorMapSystemEnabled, vectorMapUserEnabled} from 'utils/vectorMap';
import ModuleStatFilter from './ModuleStatFilter';
import ModuleFilterDialog from './ModuleFilterDialog';
import LoadingStat from 'components/loading/LoadingStat';
import PanelStat from 'components/Panels/PanelStat';
import TSTheme from 'components/Theme';
import {useDispatch} from "react-redux";
import FilterAltIcon from 'assets/FilterIcon';

const filters = [
	{id: 'accessibility', name: 'licensedModules', value: {licensed: true}},
	{id: 'accessibility', name: 'unlicensedModules', value: {licensed: false}},
	{id: 'accessibility', name: 'allModules', value: {all: true}},
	{id: 'publicationStatus', name: 'inDevelopment', value: 'DRAFT'},
	{id: 'publicationStatus', name: 'inReview', value: 'TALESPIN REVIEW'},
	{id: 'publicationStatus', name: 'published', value: 'PUBLISHED'},
];

const filterTableTitleMapping = {
	allModules: {
		title: 'All Modules',
		description: null
	},
	licensedModules: {
		title: 'Licensed Modules',
		description: 'Modules your organization has licensed including modules created by your organization using the CoPilot Designer authoring tool.'
	},
	unlicensedModules: {
		title: 'Unlicensed Modules',
		description: 'Modules that are available for your organization to license.'
	},
	inDevelopment: {
		title: 'Draft - CoPilot Designer Modules',
		description: 'Modules created by your organization using the CoPilot Designer authoring tool which are currently in the Draft status.'
	},
	inReview: {
		title: 'Talespin Review - CoPilot Designer Modules',
		description: 'Modules created by your organization using the CoPilot Designer authoring tool that have been submitted for Talespin to review prior to final publication.'
	},
	published: {
		title: 'Published - CoPilot Designer Modules',
		description: 'Modules created by your organization using the CoPilot Designer authoring tool that have been published and can be assigned to teams so learners can experience the training within the Talespin App.'
	},
}

const TrainingList = props => {
	const {classes} = props;

	const runwayOrgConfig = loadObject('runwayOrgConfig')?.config;

	const contentCompanyTeamId = runwayOrgConfig?.contentCompanyTeamId;

	const translate = useTranslate();

	const location = useLocation();

	const history = useHistory();

	const dataProvider = useDataProvider();

	const isMounted = useRef(true);

	const [useVectorMap, setUseVectorMap] = useState(vectorMapUserEnabled());

	const dispatch = useDispatch();
	// The location state should be used to pre-populate any "Skill" filter.
	// The state will include a "namedSkill" field if we navigated here from the Skills Map (Vector Map) via the "View Related Training" button.
	// The state will include a "selectedSkill" filed if we navigated from the training detail view either by clikcing the back icon or click on the side menu
	const [selectedSkills, setSelectedSkills] = useState(
		location.state?.namedSkill
			? [
					{
						name: location.state.namedSkill.name,
						displayName: location.state.namedSkill.displayName,
					},
			  ]
			: location.state?.selectedSkills
			? location.state.selectedSkills
			: []
	);

	const [selectedContentOwners, setSelectedContentOwners] = useState(
		location.state?.selectedContentOwners
			? location.state.selectedContentOwners
			: []
	);

	const [selectedCreatedBys, setSelectedCreatedBys] = useState(
		location.state?.selectedCreatedBys ? location.state.selectedCreatedBys : []
	);

	const [openModuleFilter, setOpenModuleFilter] = useState(false);

	const [trainingStats, setTrainingStats] = useState(
		loadObject('trainingStats')
	);

	const [selectedFilterGroup, setSelectedFilterGroup] = useState('skills');

	const [state, setState] = useState({
		firstLoad: true,
		selectedFilter: filters[0],
		tableTitleAndDescription: filterTableTitleMapping.licensedModules
	});

	const version = useVersion();

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const fetchStats = async () => {
		setTrainingStats(null);
		dispatch(fetchStart());
		try {
			const {data: stats} = await dataProvider(GET_LIST, 'TrainingStats', {
				filter: {moduleView: true},
			});
			setTrainingStats(stats);
		} finally{
			dispatch(fetchEnd());
		}
	};

	useEffect(() => {
		fetchStats();
	}, [version]);

	if (getUserInfo().isUser) {
		return (
			<div>
				Access denied. Contact your administrator to raise your permissions
			</div>
		);
	}

	if (!getUserInfo()?.isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: '/login',
					state: {from: props.location},
				}}
			/>
		);
	}


	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to='/termsOfService' />;
	// }

	const selectFilter = value => {
		setState({
			...state,
			selectedFilter: filters.find(filter => filter.name === value),
			tableTitleAndDescription: filterTableTitleMapping[value]
		});
	}

	const removeFilterChip = (filter, filters, filterType) => {
		let updatedFilters = [...filters];

		const targetSkill = updatedFilters.find(item => item.name === filter.name);

		const idx = updatedFilters.indexOf(targetSkill);
		if (idx > -1) {
			updatedFilters.splice(idx, 1);
		}
		return updatedFilters;
	};

	return (
		<div>
			<ScreenTitle
				basePath={props.basePath}
				resource={props.resource}
				id='training-list'
				title='Modules'
			/>

			<Grid perRow={1}>
				<div style={{display: 'flex', columnGap: '10px'}}>
					{trainingStats ? (
						<ModuleStatFilter
							stats={trainingStats}
							onClick={selectFilter}
							selectedFilter={state.selectedFilter?.name}
						/>
					) : (
						<PanelStat>
							<LoadingStat />
						</PanelStat>
					)}
				</div>

				{vectorMapSystemEnabled('org', 'catalog-map') && useVectorMap && (
					<VectorMap
						type={'catalog-map'}
						contextData={{
							teamId: undefined,
							userId: undefined,
							trainingId: undefined,
							lessonId: undefined,
						}}
						onTableViewClicked={() => {
							vectorMapUserEnabled(false);
							setUseVectorMap(false);
						}}
						onViewRecordDetails={record => {
							if (record && record.id)
								history.push('/trainings/' + record.id + '/show');
						}}
					/>
				)}

				{(!vectorMapSystemEnabled('org', 'catalog-map') || !useVectorMap) && (
					<EmbeddedList
						key={'id'}
						embedded={false}
						title={state.tableTitleAndDescription.title}
						tableDescription={state.tableTitleAndDescription.description}
						resource='trainings'
						columns={[
							{
								id: 'name',
								name: translate('Module Name'),
								sortable: true,
								width: '200px',
								render: record => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											columnGap: '10px',
											alignItems: 'center',
											padding: '10px 0px',
											whiteSpace: 'nowrap',
											flex: ' 1',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											minWidth: '0',
										}}
									>
										<Avatar
											variant={'square'}
											alt='Remy Sharp'
											src={
												record.trainingDetail.appIcons &&
												record.trainingDetail.appIcons.length > 0
													? record.trainingDetail.appIcons[0]
													: trainingModuleIcon
											}
										/>
										<span
											title={record.name}
											style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
										>
											{record.name}
										</span>
									</div>
								),
							},
							{
								id: 'seriesName',
								name: translate('Series Name'),
								sortable: true,
								width: '135px',
								align: 'center',
							},
							{
								id: 'licenseStatus',
								name: translate('License Status'),
								sortable: true,
								width: '135px',
								align: 'center',
							},
							...((runwayOrgConfig?.enableCPDPublication === 'true' || runwayOrgConfig?.isLiveApp !== "true") &&
							getUserInfo().trainingModuleEditor
								? [
									{
										id: 'publicationStatus',
										name: translate('Publication Status'),
										sortable: true,
										width: '140px',
										align: 'center',
										render: record => {
											if (record.trainingDetail.contentOwnerTeamId === contentCompanyTeamId) {
												return (
													<StatusChip
													    letterSpacing = {'1px'}
													    width='140px'
														record={record}
														source='publicationStatus'
													/>)
											} return '---';
										},
									},
								]
								: []),
							{
								id: 'lastModified',
								name: translate('Last Modified'),
								sortable: true,
								width: '135px',
								align: 'center',
								render: record => (
									<TimeDateField record={record} source='lastModified' />
								),
							},
							{
								id: 'contentUpdatableOwner',
								name: translate('Content Owner'),
								sortable: true,
								width: '135px',
								align: 'center',
							},
							...((runwayOrgConfig?.enableCPDPublication === 'true' || runwayOrgConfig?.isLiveApp !== "true") &&
							getUserInfo().trainingModuleEditor
								? [
										{
											id: 'createdBy',
											name: translate('Created By'),
											sortable: true,
											width: '135px',
											align: 'center',
										},
								  ]
								: []),
							{
								id: 'users',
								name: translate('Users'),
								sortable: true,
								width: '70px',
								align: 'center',
							},
							{
								id: 'completionRate',
								name: translate('Completion'),
								sortable: true,
								width: '90px',
								align: 'center',
								render: record => (
									<PercentField record={record} source='completionRate' />
								),
							},
							{
								id: 'avgScore',
								name: translate('Avg Score'),
								sortable: true,
								width: '95px',
								align: 'center',
								render: record => (
									<PercentField record={record} source='avgScore' />
								),
							},
						]}
						staticFilters={[
							{
								id: state.selectedFilter.id,
								value: state.selectedFilter.value,
							},
							...(['inDevelopment', 'inReview', 'published'].includes(state.selectedFilter?.name)
								? [
									{
										id: 'isCPD',
										value: true,
									}
								] : []),
							...((runwayOrgConfig?.enableCPDPublication !== 'true' && runwayOrgConfig?.isLiveApp === "true") ||
							!getUserInfo().trainingModuleEditor
								? [
										{
											id: 'publicationStatus',
											value: 'PUBLISHED',
										},
								  ]
								: []),
						]}
						sort={{field: 'name', order: 'ASC'}}
						dynamicFilters={[
							{
								id: 'name',
								name: translate('Search Modules'),
								type: 'safe_regex',
							},
							{
								id: 'moduleFilter',
								value: {
									namedSkills:
										selectedSkills.length > 0
											? {$in: selectedSkills.map(skill => skill.name)}
											: undefined,
									createdBy:
										selectedCreatedBys.length > 0
											? {$in: selectedCreatedBys.map(skill => skill.name)}
											: undefined,
									contentUpdatableOwner:
										selectedContentOwners.length > 0
											? {$in: selectedContentOwners.map(skill => skill.name)}
											: undefined,
								},
								render: () => (
									<Button
										variant={'contained'}
										color={'secondary'}
										onClick={() => setOpenModuleFilter(true)}
										className={classes.filterButton}
										startIcon={<FilterAltIcon color={'primary'} style={{ fontSize: '18px', marginTop: '-2px' }}/>}
									>
										Filters
									</Button>
								),
							},
						]}
						selectedFilterNode={[
							<div style={{display: 'flex'}}>
								{[
									selectedSkills,
									selectedContentOwners,
									selectedCreatedBys,
								].map((filters, idx, array) => {
									return (
										filters.length > 0 && (
											<div
												key={`selected-filter-set-${idx}`}
												className={classes.filterChips}
												style={{
													borderLeft:
														idx > 0 && array[idx - 1].length > 0
															? `1px ${TSTheme.palette.borderColor.secondary} solid`
															: '',
												}}
											>
												{filters.map((filter, i) =>
													i < 2 ? (
														<Chip
															classes={{
																root: classes.selectedFilter,
																icon: classes.icon,
																label: classes.filterName,
																deleteIconOutlinedColorPrimary:
																	classes.cancelIcon,
															}}
															title={filters.name}
															color={'primary'}
															key={filter.name}
															variant={'outlined'}
															size={'medium'}
															label={filter.displayName}
															onDelete={() => {
																if (filters === selectedSkills)
																	setSelectedSkills(
																		removeFilterChip(filter, filters)
																	);
																else if (filters === selectedContentOwners)
																	setSelectedContentOwners(
																		removeFilterChip(filter, filters)
																	);
																else if (filters === selectedCreatedBys)
																	setSelectedCreatedBys(
																		removeFilterChip(filter, filters)
																	);
															}}
															deleteIcon={<Cancel />}
														/>
													) : null
												)}
												{filters.length > 2 && (
													<div
														key={`more-filters-set-${idx}`}
														className={classes.moreFiltersText}
														onClick={() => {
															if (filters === selectedSkills)
																setSelectedFilterGroup('skills');
															else if (filters === selectedContentOwners)
																setSelectedFilterGroup('contentUpdatableOwner');
															else if (filters === selectedCreatedBys)
																setSelectedFilterGroup('createdBy');

															setOpenModuleFilter(true);
														}}
													>{`and ${filters.length - 2} more`}</div>
												)}
											</div>
										)
									);
								})}
							</div>,
						]}
						// bulkActions={[
						// 	vectorMapSystemEnabled('org', 'catalog-map')
						// 		? {
						// 				id: 'switchToVectorMap',
						// 				render: () => (
						// 					<Button
						// 						variant={'outlined'}
						// 						color={'primary'}
						// 						onClick={() => {
						// 							vectorMapUserEnabled(true);
						// 							setUseVectorMap(true);
						// 						}}
						// 					>
						// 						{translate('Map View')}
						// 					</Button>
						// 				),
						// 		  }
						// 		: null,
						// ].filter(item => item != null)}
						actions={[
							{
								id: 'viewModuleDetail',
								render: record => (
									<MenuItemLink
										to={{
											pathname: record.id
												? `trainings/${record.id}/show`
												: `trainings/futureTrainings/${record.appKeyName}`,
											state: {
												selectedSkills: selectedSkills,
											},
										}}
										primaryText={translate('View Module Details')}
										key={'viewModuleDetail'}
									/>
								),
							},
							{
								id: 'configurePackage',
								render: record =>
									getUserInfo().isSuper &&
									record.isCPD &&
									record.publicationStatus === 'PUBLISHED' && record?.accessibility?.licensed?.value &&
									runwayOrgConfig?.packageIntegrationEnabled === 'true' && (
										<ConfigurePackage
											record={record}
											key={'configurePackage'}
										/>
									),
							},
						]}
						rowClicked={record =>
							history.push({
								pathname: record.id
									? '/trainings/' + record.id + '/show'
									: `trainings/futureTrainings/${record.appKeyName}`,
								state: {selectedSkills: selectedSkills},
							})
						}
						selectedIds={null}
						selectionToggled={null}
						showSpinnerOnFilter={true}
					/>
				)}
			</Grid>

			<ModuleFilterDialog
				isOpen={openModuleFilter}
				onClose={setOpenModuleFilter}
				onChange={(skills, contentOwners, createdBys) => {
					setSelectedSkills(skills);
					setSelectedCreatedBys(createdBys);
					setSelectedContentOwners(contentOwners);
				}}
				selectedFilterGroupId={selectedFilterGroup}
				preSelectedSkills={selectedSkills}
				preSelectedContentOwners={selectedContentOwners}
				preSelectedCreatedBys={selectedCreatedBys}
			/>
		</div>
	);
};

const myStyles = theme =>
	createStyles({
		selectedFilter: {
			border: `2px solid ${theme.palette.primary.main}`,
			borderRadius: '20px',
			alignItems: 'center',
		},
		filterName: {
			color: '#000000',
		},
		cancelIcon: {
			color: theme.palette.primary.main,
		},
		filterChips: {
			display: 'flex',
			paddingLeft: '10px',
			paddingRight: '10px',
			columnGap: '10px',
			alignItems: 'center',
		},
		filterButton: {
			height: '32px',
			fontSize: '12px',
			fontFamily: 'Archivo Bold',
			width: '110px'
		},
		moreFiltersText: {
			fontFamily: 'Archivo Bold',
			textDecoration: 'underline',
			cursor: 'pointer',
			fontSize: '16px',
		},
	});

export default withStyles(myStyles)(TrainingList);
