import * as React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {DiagramEngine} from '@projectstorm/react-diagrams-core';

import {SkillNodeModel} from './SkillNodeModel';
import SkillNodeWidget from './SkillNodeWidget';

export class SkillNodeFactory extends AbstractReactFactory<SkillNodeModel, DiagramEngine> {
    constructor() {
        super('skill');
    }

    generateModel(event) {
        return new SkillNodeModel();
    }

    generateReactWidget(event): JSX.Element {
        return <SkillNodeWidget engine={this.engine} node={event.model} />;
    }
}
