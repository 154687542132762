import React, {Component} from 'react';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {secToWeeksFull, secToWeeksSecs} from 'services/formatters';
import {translate, withDataProvider} from 'react-admin';

import {Card} from '@material-ui/core';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';

const myStyles = theme =>
	createStyles({
		root: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.palette.background.contained,
			padding: '8px',
			alignItems: 'flex-start',
			minWidth: '128px',
			minHeight: '85px',
			border: 'none',
			borderRadius: '0px',
			position: 'relative',
			overflow: 'hidden',
			MozBoxShadow: '0px 5px 20px #00000026',
			boxShadow: '0px 5px 20px #00000026',
		},
		title: {
			color: '#CCCCCC',
		},
		mainTitle: {
			display: 'flex',
			flexDirection: 'column',
			flexWrap: 'nowrap',
			alignItems: 'start',
			paddingTop: '0.5em',
			fontFamily: 'Archivo Bold',
			fontSize: '14px',
		},
		data: {
			fontFamily: 'MontSerrat Bold',
			fontSize: '16px',
		},
		icon: {
			color: theme.palette.primary.main,
			position: 'absolute',
			left: '43%',
			top: 0,
			width: ' 100%',
			opacity: 0.15,
			height: '100%',
		},
		value: {
			color: theme.palette.color.default,
		},
		primarySubValue: {
			color: theme.palette.primary.main,
			fontSize: '12px',
		},
		secondarySubValue: {
			fontSize: '12px',
			color: '#8D8D8D',
		},
	});

class StatsTile extends Component {
	render() {
		const {
			classes,
			translate,
			title,
			type,
			value,
			subLabel,
			subValue,
			valueColor,
		} = this.props;

		const getFormattedValue = (val, style, validate) => {
			// We format everything, unless it's a string. Then we assume its already formatted.
			if (val && typeof val !== 'string') {
				switch (type) {
					case 'time':
						return (
							<Tooltip title={secToWeeksFull(val)} placement='right-start'>
								<span
									className={style}
									style={validate ? {color: valueColor} : {}}
								>
									{secToWeeksSecs(val)}
								</span>
							</Tooltip>
						);
					case 'percent':
						return (
							<span
								className={style}
								style={validate ? {color: valueColor} : {}}
							>
								{(val = `${Math.round(val * 100)}%`)}
							</span>
						);
					case 'number':
						return (
							<span
								className={style}
								style={validate ? {color: valueColor} : {}}
							>
								{
									(val = val
										.toFixed(2)
										.replace('.00', '')
										.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
								}
							</span>
						);
					default:
						return (
							<span
								className={style}
								style={validate ? {color: valueColor} : {}}
							>
								{val}
							</span>
						);
				}
			}

			// We only want to enforce the exisitence of the main value
			if (validate) {
				const validateValue = value => {
					if (value === 'null' || value === 'undefined' || value === null) {
						return '---';
					} else {
						return value;
					}
				};

				val = validateValue(val);
			}

			return (
				<span className={style} style={validate ? {color: valueColor} : {}}>
					{val}
				</span>
			);
		};

		let formattedValue = getFormattedValue(value, classes.value, true);
		let formattedSecondarySubValue = getFormattedValue(
			subLabel,
			classes.secondarySubValue,
			false
		);
		let formattedPrimarySubValue = getFormattedValue(
			subValue,
			classes.primarySubValue,
			false
		);

		const icon =
			typeof this.props.icon == 'string' ? (
				<img className={classes.icon} src={this.props.icon} alt='icon' />
			) : (
				<this.props.icon className={classes.icon} />
			);

		return (
			<Card className={classes.root}>
				<div
					style={{
						position: 'relative',
						zIndex: 2,
					}}
				>
					<div>
						<div className={classes.mainTitle}>
							{translate(`stats.${title}`)}
						</div>
						<div className={classes.data}>{formattedValue}</div>
					</div>
					<div className={classes.title}>
						{subLabel && subLabel !== 'undefined' ? (
							formattedSecondarySubValue
						) : (
							<div></div>
						)}
						{subValue && subValue !== 'undefined' ? (
							formattedPrimarySubValue
						) : subLabel && subLabel !== 'undefined' ? (
							subLabel.indexOf('/') > -1 ? (
								''
							) : (
								<span style={{color: '#ffc100'}}> ---</span>
							)
						) : (
							<div></div>
						)}
					</div>
				</div>
				{icon}
			</Card>
		);
	}
}

StatsTile.propTypes = {
	translate: PropTypes.func,
	value: PropTypes.any,
	icon: PropTypes.any,
	title: PropTypes.string,
	type: PropTypes.string,
	subLabel: PropTypes.string,
	subValue: PropTypes.any,
};

const enhance = compose(
	translate,
	withRouter,
	withStyles(myStyles),
	withDataProvider
);

export default enhance(StatsTile);
