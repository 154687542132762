import * as React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {DiagramEngine} from '@projectstorm/react-diagrams-core';

import {TrainingNodeModel} from './TrainingNodeModel';
import TrainingNodeWidget from './TrainingNodeWidget';

export class TrainingNodeFactory extends AbstractReactFactory<TrainingNodeModel, DiagramEngine> {
    constructor() {
        super('training');
    }

    generateModel(event) {
        return new TrainingNodeModel();
    }

    generateReactWidget(event): JSX.Element {
        return <TrainingNodeWidget engine={this.engine} node={event.model} />;
    }
}
