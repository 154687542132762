import PropTypes from 'prop-types';
import React from 'react';

const PercentField = ({source, record = {}, altRecord, style}) => {
	if (altRecord) {
		record = altRecord;
	}

	if (isNaN(record[source])) {
		return <span style={style}>---</span>;
	}
	return <span style={style}>{`${Math.round(record[source] * 100)}%`}</span>;
};

PercentField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
	style: PropTypes.any
};

export default PercentField;



// {(`${Math.round((record[source])* 100)}%`)}
