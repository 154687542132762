// Import libraries.
import React, {Component} from 'react';
import {Button} from '@material-ui/core';

import {CustomDialog} from '../index';

class ConfirmActionDialog extends Component {
	render() {
		const {content, setIsOpen, title, onConfirm, isOpen} = this.props;

		return (
			<CustomDialog
				title={title}
				id={'confirmation-action-dialog'}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				small
				onClose={() => setIsOpen(false)}
				aria-label={title}
				actions={[
					{
						render: () => {
							return (
								<Button
									color='secondary'
									variant='contained'
									onClick={() => setIsOpen(false)}
								>
									Cancel
								</Button>
							);
						},
					},
					{
						render: () => {
							return (
								<Button color='primary' variant='contained' onClick={onConfirm}>
									Confirm
								</Button>
							);
						},
					},
				]}
			>
				<div style={{padding: '20px 0'}}>{content}</div>
			</CustomDialog>
		);
	}
}

export default ConfirmActionDialog;
