import {fetchEnd, fetchStart, GET_LIST, withDataProvider} from 'react-admin';
import React, {useEffect, useState} from 'react';
import compose from 'recompose/compose';
import LessonDetailView from 'components/LessonDetailView';
import {
	EmbeddedShow,
	ScreenTitle,
	StatusChip,
	TextValue,
	TimeDateField,
} from 'components';
import { useDispatch } from "react-redux";
const LessonDetail = props => {
	const {
		selectedLessonRecord,
		dataProvider,
		backLessonAction,
		id,
		trainingName,
	} = props;

	const [lessonStats, setLessonStats] = useState(null);

	const [lessonDetail, setLessonDetail] = useState(null);

	const [lessonSkillStats, setLessonSkillStats] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		fetchLessonDetails();
		fetchLessonStats();
		fetchLessonSkillStats();
	}, []);

	const fetchLessonStats = async () => {
		dispatch(fetchStart());
		try {
			const response = await dataProvider(GET_LIST, 'lessonstats', {
				filter: {
					id: id,
					trainingId: id,
					lessonId: selectedLessonRecord.id,
				},
				pagination: {},
				sort: {},
			});
	
			setLessonStats(response.data);
		} finally {
          dispatch(fetchEnd());
		}
	};

	const fetchLessonSkillStats = async () => {
		dispatch(fetchStart());
		try {
			const response = await dataProvider(
				GET_LIST,
				'LessonSkillStatsOverallRunway',
				{
					filter: {trainingId: id, lessonId: selectedLessonRecord.id},
					pagination: {},
					sort: {},
				}
			);
	
			setLessonSkillStats(response.data);
		} finally {
          dispatch(fetchEnd());
		}
	};

	const fetchLessonDetails = async () => {
		dispatch(fetchStart());
		try {
			const response = await dataProvider(GET_LIST, 'lessonsfortraining', {
				filter: {id: id, lessonId: selectedLessonRecord.id},
				pagination: {page: 1, perPage: 5},
				sort: {},
			});
	
			setLessonDetail(response?.data?.length > 0 ? response.data[0] : {});
		} finally {
          dispatch(fetchEnd());
		}
	};

	return (
		<div>
			<ScreenTitle
				title={
					lessonDetail?.name ? lessonDetail.name : selectedLessonRecord.name
				}
				refresh={false}
				subTitle={true}
				coloredCrumb={true}
				backActionCrumbs={[backLessonAction]}
				titleCrumbs={[trainingName]}
			/>

			<LessonDetailView
				lessonData={lessonDetail}
				lessonTrainingStats={lessonStats}
				lessonSkillStats={lessonSkillStats}
				trainingId={id}
				fromComponent={'trainings'}
				vectorMapContextData={{
					teamId: undefined,
					userId: undefined,
					trainingId: id,
					lessonId: selectedLessonRecord ? selectedLessonRecord.id : undefined,
				}}
				information={
					<EmbeddedShow
						headerless
						embedded
						title={'Information'}
						details={[
							{
								label: 'Name',
								render: record => (
									<TextValue
										record={lessonDetail}
										source='name'
										maxWidth={'300px'}
									/>
								),
							},
							{
								label: 'Created At',
								render: record => (
									<TimeDateField record={lessonDetail} source='createdAt' />
								),
							},
							{
								label: 'Modified',
								render: record => (
									<TimeDateField record={lessonDetail} source='updatedAt' />
								),
							},
							{
								label: 'Type',
								render: record => (
									<StatusChip record={lessonDetail} source='lessonType' />
								),
							},
						]}
					/>
				}
			/>
		</div>
	);
};

export default compose(withDataProvider)(LessonDetail);
