import React from 'react';
import {useTranslate} from 'react-admin';
import MUIButton from '@material-ui/core/Button';
import {
	Dialog,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import {Text, DialogTitle} from 'components';

const PALUnavailableDialog = props => {
	const {errorMessage, onClose, title} = props;

	const translate = useTranslate();

	return (
		<Dialog open={true} maxWidth={'md'}>
			<DialogTitle onClose={onClose}>
				<Text
					style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
					label={title}
				/>
			</DialogTitle>
			<DialogContent
				style={{
					width: '465px',
					height: 'auto',
					display: 'flex',
					fontFamily: 'Montserrat Medium',
					fontSize: '16px',
					justifyContent: 'center',
				}}
			>
				<div style={{display: 'flex', flexDirection: 'column', margin: "16px 0px"}}>
					{errorMessage && <span>{errorMessage}</span>}
					{!errorMessage && (
						<span>There are no Platform Access Licenses remaining.</span>
					)}
					{/* <br />
					<span>
						Contact your Talespin Administrator to get more, or have a Super
						Admin enable the <b>Auto Add</b> checkbox for Platform Access Licenses in the Organization {'--->'} Manage Licenses view.
					</span> */}
				</div>
			</DialogContent>

			<DialogActions>
				<MUIButton
					variant={'outlined'}
					color={'primary'}
					onClick={() => onClose()}
				>
					{translate('OK')}
				</MUIButton>
			</DialogActions>
		</Dialog>
	);
};

export default PALUnavailableDialog;
