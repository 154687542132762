import List from './List';
import Show from './Show';
import {Users} from '../../assets/MenuIcon';

const userShow = {
	name: 'users',
	show: Show,
	list: List,
	icon: Users
};

export default userShow;
