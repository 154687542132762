import React, {useEffect, useState} from 'react';
import {
	CREATE,
	fetchEnd,
	fetchStart,
	GET_LIST,
	useDataProvider,
	useTranslate,
	useVersion,
} from 'react-admin';
import get from 'lodash/get';

import {
	Button,
	Checkbox,
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {
	createTheme,
	MuiThemeProvider,
	ThemeProvider,
} from '@material-ui/core/styles';
import mailIcon from 'assets/MailIcon.svg';
import Clear from '@material-ui/icons/Clear';
import InfoIcon from '@material-ui/icons/Info';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {CustomDialog, ScreenTitle} from 'components';
import ResourceRedirect from 'components/ResourceRedirect';
import LoadingInfo from 'components/loading/LoadingInfo';
import {FilterBarTheme, TSTheme} from 'components/Theme';
import UsedLicenses from './UsedLicenses';
import {loadObject} from 'utils/Util';
import {getUserInfo} from 'utils/Data';

import PALLicenses from 'sections/Organization/Licenses/PALLicenses';
import {LicensesServices} from 'sections/Organization/Licenses/LisensesServices';
import { useDispatch } from 'react-redux';
import EmailIcon from 'assets/EmailIcon';

const Licences = ({orgId, onToggleChildAppDetailUserLicenseView}) => {
	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const planContactEmail = get(runwayOrgConfig, 'config.planContactEmail');

	const nearingEndDate = get(
		runwayOrgConfig,
		'config.LicenseNearingEndDateHighlight'
	);

	const translate = useTranslate();

	const version = useVersion();

	const dispatch = useDispatch();

	const userRoleObj = getUserInfo();

	const [state, setState] = useState({
		data: null,
		ListLicense: null,
		firstLoad: true,
		selectedRecord: null,
		month: null,
		palLicenseUsageFilterMonth: null,
		year: new Date().getFullYear(),
		selectMonth: null,
		searchvalue: '',
		filterYear: new Date().getFullYear(),
		filterTimeOut: 0,
		ListLicenseDetail: null,
		projectId: null,
		listFilterYear: new Date().getFullYear(),
		superAdminEmail: null,
		palLicenseConfigs: null,
		palLicenseUsage: null,
		isUsersPALLicenseView: false,
		palLicenseUsageFilterYear: null,
	});

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const tableBody = document.querySelector(
			"tbody[class^='MuiTableBody-root']"
		);
		if (tableBody) {
			if (tableBody.scrollHeight > tableBody.clientHeight) {
				const tableFooter = document.querySelector(
					"tfoot[class^='MuiTableFooter-root'] tr"
				);
				if (tableFooter) {
					tableFooter.style.paddingRight = '10px';
				}
			} else {
				const tableFooter = document.querySelector(
					"tfoot[class^='MuiTableFooter-root'] tr"
				);
				if (tableFooter) {
					tableFooter.style.paddingRight = '0px';
				}
			}
		}
	});

	const dataProvider = useDataProvider();

	const fetchData = async (value, id) => {
		let filter = null;

		let yearValue, searchValue;

		if (id === 'regex') {
			yearValue = state.year ? state.year : new Date().getFullYear();
			searchValue = value;
			if (value.trim().length > 0) {
				filter = {year: yearValue, name: {$regex: `${value}`, $options: 'i'}};
			} else {
				filter = {year: state.year};
			}
		} else if (id === 'choices' && state.searchvalue) {
			searchValue = state.searchvalue;
			yearValue = value;
			filter = {
				year: value ? value : new Date().getFullYear(),
				name: {$regex: `${searchValue}`, $options: 'i'},
			};
		} else if (id === 'choices' && value) {
			yearValue = value;
			filter = {year: value ? value : new Date().getFullYear()};
		} else {
			filter = {year: value ? value : new Date().getFullYear()};
		}
		// const {data: ListLicense} = await dataProvider(GET_LIST, 'ListLicense', {
		// 	filter: filter,
		// });
        try {
			dispatch(fetchStart());
			const {
				data: ListLicense,
			} = await LicensesServices.fetchListLicenseUsageOverview(
				dataProvider,
				filter,
				orgId
			);
	
			let usersFilter = {
				filter: {role: 'super'},
				pagination: {page: 1, perPage: 10},
				sort: {field: 'createdAt', order: 'ASC'},
			};
			const {data} = !orgId
				? await dataProvider(GET_LIST, 'users', usersFilter)
				: {data: []};
			var eldestSuperAdmin =
				data.length !== 0
					? data.reduce(function(a, b) {
							return a.contactEmail !== null && a.contactEmail !== '' ? a : b;
					  })
					: null;
	
			setState(state => ({
				...state,
				ListLicense: ListLicense,
				superAdminEmail: eldestSuperAdmin?.contactEmail,
			}));
		} finally{
			dispatch(fetchEnd());
		}
	};

	const fetchDetailLicense = async (id, year) => {
		// const { data: ListLicenseDetail } = await dataProvider(
		// 	GET_LIST,
		// 	'ListLicenseDetail',
		// 	{
		// 		filter: { id: id, year: year ? year : state.year },
		// 	}
		// );

        try {
			dispatch(fetchStart());
			const {
				data: ListLicenseDetail,
			} = await LicensesServices.fetchListLicenseUsageDetailOverview(
				dataProvider,
				{id: id, year: year ? year : state.year},
				orgId
			);
	
			setState(state => ({
				...state,
				ListLicenseDetail: ListLicenseDetail,
			}));
		} finally {
			dispatch(fetchEnd());
		}
	};

	useEffect(() => {
		setState(state => ({
			...state,
			ListLicense: null,
		}));
		fetchData();
	}, [version, orgId]);

	const {ListLicense} = state;

	const cancelSelectedRecord = () => {
		setState({...state, selectedRecord: null, month: null});
	};

	const cancelPALUsersLicenseView = () => {
		setState({
			...state,
			isUsersPALLicenseView: false,
		});
		if (onToggleChildAppDetailUserLicenseView)
			onToggleChildAppDetailUserLicenseView();
	};

	const showUsersLicenseView = () => {
		setState({
			...state,
			isUsersPALLicenseView: true,
		});
		if (onToggleChildAppDetailUserLicenseView)
			onToggleChildAppDetailUserLicenseView();
	};

	const selectRecord = item => {
		setState({...state, selectedRecord: item, month: null, year: state.year});
		if (onToggleChildAppDetailUserLicenseView)
			onToggleChildAppDetailUserLicenseView();
	};

	const selectMonth = (record, month) => {
		setState({
			...state,
			month: month,
			year: state.year,
			selectedRecord: record,
		});
	};

	const onChange = value => {
		const {filterTimeout} = state;
		const id = 'regex';
		if (filterTimeout) {
			clearTimeout(filterTimeout);
		}
		let searchValue = '';

		if (value && value[0] === '/') {
			const regexFilter = value.substring(1, value.length);
			searchValue = regexFilter;
		} else {
			const escapedFilter = value.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
			searchValue = escapedFilter;
		}
		setState(state => ({
			...state,
			searchvalue: value,
			filterTimeout: setTimeout(() => {
				fetchData(searchValue, id);
			}, 1000),
		}));
	};

	const filterByYear = value => {
		const id = 'choices';
		setState(state => ({
			...state,
			year: value,
		}));
		fetchData(value, id);
	};

	const filterListDetailLicense = year => {
		fetchDetailLicense(state.projectId, year);
		setState({...state, listFilterYear: year});
	};

	const autoAddLicense = (id, value, index) => {
		ListLicense[index].autoEnableMore = value;
		setState({...state, ListLicense});
		dataProvider(CREATE, 'autoAddLicense', {
			data: {trainingId: id, autoEnableMore: value},
		});
	};

	let adornment = null;
	if (state.searchvalue && state.searchvalue.length > 0) {
		adornment = (
			<InputAdornment position='end'>
				<Clear
					style={{fontSize: 16, marginRight: '5px', cursor: 'pointer'}}
					onClick={e => onChange('')}
				/>
			</InputAdornment>
		);
	}

	const year = new Date().getFullYear();

	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const Months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const openDialouge = id => {
		setIsOpen(true);
		setState({...state, projectId: id});
		fetchDetailLicense(id);
	};
	const closeDialog = () => {
		setIsOpen(false);
		setState({
			...state,
			projectId: null,
			ListLicenseDetail: null,
			listFilterYear: year,
		});
	};
	const handleChange = async Id => {
		let Licenses = ListLicense;
		for (let obj of Licenses) {
			if (obj.id === Id) {
				await dataProvider(CREATE, 'setLicenseAutoEnableMoreVisibleRunway', {
					data: {
						trainingId: Id,
						autoEnableMoreEnabled: obj.autoEnableMoreEnabled ? false : true,
					},
				});
				obj.autoEnableMoreEnabled = obj.autoEnableMoreEnabled ? false : true;
			} else {
				obj.check = false;
				obj.isActive = false;
			}
		}
		setState({...state, ListLicense: Licenses});
	};

	const renderTitle = (record, index) => {
		return (
			<div
				style={{
					width: '100%',
					border: 'none',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					paddingTop: '24px',
					paddingBottom: '16px',
				}}
			>
				<div
					style={{
						textAlign: 'left',
						width: '100%',
						height: '100%', // Height of tab header
						borderTopRightRadius: '4px',
						borderTopLeftRadius: '4px',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Typography
						style={{
							width: '100%',
							fontFamily: 'Archivo Bold',
							fontSize: '16px',
						}}
					>
						{record.name}
					</Typography>

					{!orgId && (
						<div
							style={{
								width: '100%',
								height: '100%',
								display: 'flex',
								position: 'absolute',
								alignItems: 'center',
								fontFamily: 'Montserrat Medium',
							}}
						>
							<span style={{flex: 1}} />
							{userRoleObj?.isSuper && !orgId && (
								<div style={{display: 'flex', alignItems: 'center'}}>
									{translate('Lock Auto-Add Licenses')}
									<IconButton
										onClick={() => handleChange(record.id)}
										id={`switch-1${index}`}
									>
										{record.autoEnableMoreEnabled ? (
											<LockOpenIcon />
										) : (
											<LockIcon />
										)}
									</IconButton>
								</div>
							)}
							<Checkbox
								color={'primary'}
								id={record.id}
								checked={record.autoEnableMore}
								onChange={e =>
									autoAddLicense(record.id, e.target.checked, index)
								}
								size='small'
								disabled={!record.autoEnableMoreEnabled}
								style={{opacity: !record.autoEnableMoreEnabled ? '0.3' : '1'}}
							/>
							<div style={{paddingRight: '10px'}}>
								{translate('Auto-Add Licenses')}
							</div>

							<Tooltip
								title={
									'Check this box to automatically purchase one license when a learner attempts an activation and none are available'
								}
								placement='top-start'
							>
								<InfoIcon style={{fontSize: '20px', color: 'gray'}} />
							</Tooltip>

							<div>
								{userRoleObj?.isAdmin && (
									<Tooltip
										title={
											'Ability to Auto-Add License is locked for this module. Please contact a Super Admin to unlock this feature.'
										}
										placement='top-start'
									>
										<a
											href={
												'mailto:' +
												(state.superAdminEmail
													? state.superAdminEmail
													: planContactEmail) +
												'?subject=Request to unlock Auto Add Licenses for ' +
												record.name
											}
										>
											<img
												style={{
													width: '22px',
													marginLeft: '10px',
													display: !record.autoEnableMoreEnabled ? '' : 'none',
												}}
												src={mailIcon}
												alt='mail icon'
											/>
										</a>
									</Tooltip>
								)}
							</div>
							<span style={{width: '16px'}} />
						</div>
					)}
				</div>
			</div>
		);
	};
	const renderDivider = () => {
		return (
			<div
				style={{
					width: '100%',
				}}
			>
				<div
					style={{
						borderBottom: `2px ${TSTheme.palette.borderColor.secondary} solid`,
						marginTop: '10px',
						marginBottom: '10px',
					}}
				></div>
			</div>
		);
	};

	const renderLicense = record => {
		return (
			<div style={{display: 'flex', flexDirection: 'row'}}>
				<div style={{flexDirection: 'row', textAlign: 'center', width: '100%'}}>
					<div
						style={{
							display: 'flex',
							backgroundColor: TSTheme.palette.background.default,
							fontFamily: 'Montserrat Medium',
							fontSize: '15px',
							borderBottom: `2px ${TSTheme.palette.borderColor.secondary} solid`,
						}}
					>
						<div
							style={{
								display: 'flex',
								width: '70%',
								borderRight: `2px ${TSTheme.palette.borderColor.secondary} solid`,
							}}
						>
							{months.map(month => {
								return renderColumn(month, month);
							})}
						</div>
						<div style={{display: 'flex', width: '30%'}}>
							{['Used', 'Total', 'Expiry'].map((title, index) => {
								return renderColumn(title, title);
							})}
						</div>
					</div>
					<div style={{display: 'flex', marginTop: '10px'}}>
						<div
							style={{
								display: 'flex',
								width: '70%',
								borderRight: `2px ${TSTheme.palette.borderColor.secondary} solid`,
							}}
						>
							{record.monthlyTotals.map((item, index) => {
								return renderColumn(
									item !== 0 ? (
										<span
											style={{
												cursor: 'pointer',
												color: TSTheme.palette.primary.main,
											}}
											onClick={() => selectMonth(record, index + 1)}
										>
											{item}
										</span>
									) : (
										item
									),
									index
								);
							})}
						</div>
						<div style={{display: 'flex', width: '30%'}}>
							{renderColumn(
								record.usedLicenses !== 0 ? (
									<span
										style={{
											cursor: 'pointer',
											color: TSTheme.palette.primary.main,
										}}
										onClick={() => selectRecord(record)}
									>
										{record.usedLicenses}
									</span>
								) : (
									record.usedLicenses
								)
							)}
							{renderColumn(record.totalLicenses)}
							{renderColumn(record.expiry)}
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderColumn = (item, key) => {
		return (
			<div
				style={{
					flexDirection: 'column',
					textAlign: 'center',
					padding: '10px',
					display: 'flex',
					width: '60%',
				}}
				key={key}
			>
				<div
					style={{
						textAlign: 'center',
						minWidth: '40px',
						maxWidth: item[item.length - 1] ? '86px' : '80px',
					}}
					key={key}
				>
					{item}
				</div>
			</div>
		);
	};

	const renderTraningModule = item => {
		return (
			<div
				style={{
					display: 'flex',
					alignContent: 'space-around',
					paddingTop: '10px',
					paddingBottom: '20px',
				}}
			>
				<Button
					variant={'outlined'}
					color={'primary'}
					onClick={() => openDialouge(item.id)}
				>
					View License Comparison
				</Button>
				{!orgId && (
					<Button
						variant={'outlined'}
						color={'primary'}
						style={{marginLeft: '10px'}}
					>
						<ResourceRedirect
							value={'View Training Module'}
							id={item.id}
							resource={'trainings'}
						/>
					</Button>
				)}
			</div>
		);
	};

	const licenseDetail = state.ListLicenseDetail;

	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to='/termsOfService' />;
	// }

	return (
		<div>
			<div>
				{!state.selectedRecord && !state.isUsersPALLicenseView && !orgId && (
					<ScreenTitle
						title={<span style={{fontSize: '24px'}}>Manage Licenses</span>}
						list={'/'}
						actions={[
							{
								render: () => {
									return (
										<Button
											width={'148px'}
											// variant={'raised'}
											variant={'outlined'}
											color={'primary'}
											style={{backgroundColor: '#ffffff'}}
											onClick={() =>
												(window.location.href = 'mailto:' + planContactEmail)
											}
											startIcon={<EmailIcon style={{ fontSize: '18px', marginBottom: '2px' }}/>}
										>
											Get More Licenses
										</Button>
									);
								},
							},
						]}
					/>
				)}
				<PALLicenses
					superAdminEmail={state.superAdminEmail}
					showUsersPALLicenseView={showUsersLicenseView}
					cancelPALUsersLicenseView={cancelPALUsersLicenseView}
					trainingModuleUsageView={state.selectedRecord}
					onToggleChildAppDetailUserLicenseView={
						onToggleChildAppDetailUserLicenseView
					}
					orgId={orgId}
				/>
				{!state.selectedRecord && !state.isUsersPALLicenseView && (
					<>
						<br />
						<div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									backgroundColor: 'white',
									marginTop: '16px',
									height: '61px',
									padding: '0 24px',
									top: 0,
									position: 'sticky',
									zIndex: 1,
								}}
							>
								<span style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}>
									License usage Overview
								</span>
								<div style={{display: 'flex'}}>
									<MuiThemeProvider theme={FilterBarTheme({isLicensed: true})}>
										<FormControl style={{height: '40px'}}>
											<Input
												id={'search-box'}
												value={state.searchvalue}
												onChange={e => onChange(e.target.value)}
												startAdornment={
													<InputAdornment position='start'>
														<SearchIcon />
													</InputAdornment>
												}
												placeholder={'Search'}
												endAdornment={adornment}
												style={{height: '40px'}}
											/>
										</FormControl>
										<FormControl
											style={{
												marginLeft: '10px',
												width: '100%',
												maxWidth: '100px',
											}}
										>
											<Select
												onChange={e => filterByYear(e.target.value)}
												IconComponent={ExpandMoreIcon}
												value={state.year}
											>
												<MenuItem value={year}>{year}</MenuItem>
												<MenuItem value={year - 1}>{year - 1}</MenuItem>
												<MenuItem value={year - 2}>{year - 2}</MenuItem>
											</Select>
										</FormControl>
									</MuiThemeProvider>
								</div>
							</div>
							<div>
								{ListLicense !== null ? (
									ListLicense.length === 0 ? (
										<div
											style={{
												textAlign: 'center',
												height: '120px',
												paddingTop: '60px',
											}}
										>
											{translate('No Training Modules Found')}
										</div>
									) : (
										ListLicense.map((item, index) => {
											return (
												<div
													style={{
														backgroundColor:
															TSTheme.palette.background.contained,
														paddingLeft: '20px',
														paddingRight: '20px',
														marginBottom: '30px',
													}}
													key={index}
												>
													{' '}
													{renderTitle(item, index)}
													{renderLicense(item)}
													{renderDivider()}
													{renderTraningModule(item)}
												</div>
											);
										})
									)
								) : (
									<LoadingInfo />
								)}
							</div>
						</div>
					</>
				)}
			</div>
			{state.selectedRecord && (
				<UsedLicenses
					selectedRecord={state.selectedRecord}
					action={cancelSelectedRecord}
					planContactEmail={planContactEmail}
					month={state.month}
					year={state.year}
					nearingEndDate={nearingEndDate}
					orgId={orgId}
				/>
			)}
			<CustomDialog
				padding='0px'
				width='480px'
				title={'License Comparison'}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				onClose={closeDialog}
				overflow={'none'}
				footer={false}
				actions={[
					{
						render: () => {
							return (
								<Button
									small
									variant={'outlined'}
									onClick={() => closeDialog()}
								>
									Close
								</Button>
							);
						},
					},
				]}
			>
				{licenseDetail ? (
					<div>
						<div
							style={{
								display: 'flex',
								flex: 'column',
								padding: '10px 0px',
								alignItems: 'center',
							}}
						>
							<div>{licenseDetail.name}</div>
							<span style={{flex: 1}} />
							<MuiThemeProvider theme={FilterBarTheme}>
								<FormControl
									style={{marginLeft: '10px', width: '100%', maxWidth: '100px'}}
								>
									<Select
										onChange={e => filterListDetailLicense(e.target.value)}
										value={state.listFilterYear}
									>
										<MenuItem value={year}>{year}</MenuItem>
										<MenuItem value={year - 1}>{year - 1}</MenuItem>
										<MenuItem value={year - 2}>{year - 2}</MenuItem>
									</Select>
								</FormControl>
							</MuiThemeProvider>
						</div>
						<ThemeProvider theme={CustomTheme}>
							<Table paddding={'none'} stickyHeader size='medium'>
								<TableHead>
									<TableRow>
										<TableCell key={'month'} align={'left'}>
											{translate('Month')}
										</TableCell>
										<TableCell key={'standard'} align={'right'}>
											{translate('Standard Licenses')}
										</TableCell>
										<TableCell key={'autoadded'} align={'right'}>
											{translate('Auto-Added Licenses')}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Months.map((row, index) => {
										return (
											<TableRow key={index}>
												<TableCell key={'month'} align={'left'}>
													{row}
												</TableCell>
												<TableCell key={'standard'} align={'right'}>
													{licenseDetail.standardMonthlyTotals[index]}
												</TableCell>
												<TableCell key={'autoadded'} align={'right'}>
													{licenseDetail.autoAddedMonthlyTotals[index]}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
								<TableFooter>
									<TableRow style={{border: 'none'}}>
										<TableCell key={'month'}>{'TOTAL'}</TableCell>
										<TableCell key={'standard'} align={'right'}>
											{licenseDetail.standardTotals}
										</TableCell>
										<TableCell key={'autoadded'} align={'right'}>
											{licenseDetail.autoAddedTotals}
										</TableCell>
									</TableRow>
								</TableFooter>
							</Table>
						</ThemeProvider>
					</div>
				) : (
					<LoadingInfo />
				)}
			</CustomDialog>
		</div>
	);
};

export default Licences;

export const CustomTheme = createTheme({
	...TSTheme,
	overrides: {
		MuiTable: {
			root: {
				maxHeight: '70vh',
				display: 'table',
			},
		},
		MuiTableHead: {
			root: {
				maxHeight: '5vh',
			},
		},
		MuiTableCell: {
			root: {
				// flexBasis: '100%',
				flexGrow: '2',
				lineHeight: '1px',
				display: 'block',
				'&:first-of-type': {
					minWidth: '80px',
					maxWidth: '80px',
				},
			},
			head: {
				lineHeight: '1px',
			},
			paddingNone: {
				paddingLeft: '12px',
			},
			stickyHeader: {
				backgroundColor: TSTheme.palette.background.default,
			},
			body: {
				color: 'ffffff',
			},
			footer: {
				color: 'ffffff',
				lineHeight: '1px',
			},
		},
		MuiTableRow: {
			root: {
				width: '100%',
				display: 'flex',
				flexBasis: '100%',
				flexGrow: '2',
			},
		},
		MuiTableBody: {
			root: {
				maxHeight: '60vh',
				display: 'block',
				overflowY: 'auto',
				position: 'relative',
				'&::-webkit-scrollbar': {
					width: '5px',
					backgroundColor: TSTheme.palette.background.default,
					borderLeft: TSTheme.palette.primary.main,
					borderRight: TSTheme.palette.primary.main,
				},
				'&::-webkit-scrollbar-track': {
					boxShadow: `inset 0 0 1px ${TSTheme.palette.background.default}`,
					webkitBoxShadow: `inset 0 0 1px ${TSTheme.palette.background.default}`,
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: TSTheme.palette.primary.main,
					borderRadius: '20px',
					outline: 'none',
				},
			},
		},
		MuiTableFooter: {
			root: {
				borderCollapse: 'collapse',
				marginRight: '10px',
			},
		},
	},
});
