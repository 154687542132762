import React, {useEffect, useState} from 'react';
import {useTranslate} from 'react-admin';
import Label from 'components/Label';
import StaticTable from 'components/StaticTable';

import {DialogTitle, Text} from 'components';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import MUIButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import {createStyles, withStyles} from '@material-ui/core/styles';
import { AddOutlined } from '@material-ui/icons';

const myStyles = theme =>
	createStyles({
		tabelRow: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			columnGap: '10px',
			marginBottom: '10px',
		},
		titleInfo: {
			fontSize: '12px',
			fontFamily: 'Montserrat Italic',
			margin: '10px 0',
		},
		spinnerStyles: {
			position: 'relative',
			marginRight: '5px',
			top: '2px',
			color: theme.palette.primary.main,
		},
		errorInfo: {
			fontFamily: 'Montserrat Italic',
			fontSize: '12px',
			color: 'red',
		},
		tableContainer: {
			height: '300px',
			overflowY: 'scroll',
			'&::-webkit-scrollbar': {
				width: '0.4em',
				backgroundColor: '#ecf4f6',
			},
			'&::-webkit-scrollbar-track': {
				boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				width: '0.4em',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#346dec',
				width: '0.4em',
				outline: 'none',
				borderRadius: '20px',
			},
		},
	});

function ConfigureUserDetailsDialog({
	title,
	titleInfo,
	data,
	isOpen,
	onClose,
	classes,
	...props
}) {
	const translate = useTranslate();
	const [newText, setNewText] = useState('');
	const [searchText, setSearchText] = useState('');
	const [updatedData, setUpdatedData] = useState({});
	const [tableValues, setTableValues] = useState([]);
	const [tableValuesCopy, setTableValuesCopy] = useState([]);
	const [isDuplicate, setIsDuplicate] = useState(false);
	const [addOnEnter, setAddOnEnter] = useState(false);
	const [
		combineStringsCheckboxValue,
		setCombineStringsCheckboxValue,
	] = useState(false);
	const [appendDigitsCheckboxValue, setAppendDigitsCheckboxValue] = useState(
		false
	);

	useEffect(() => {
		setUpdatedData(data);
		setTableValues(data.values);
		setTableValuesCopy(data.values);
		setCombineStringsCheckboxValue(data.combineStrings);
		setAppendDigitsCheckboxValue(data.addTwoDigits);
	}, [data]);

	const handleDone = () => {
		updatedData.values = tableValuesCopy;
		updatedData.combineStrings = combineStringsCheckboxValue;
		updatedData.addTwoDigits = appendDigitsCheckboxValue;
		if (onClose) onClose(updatedData);
	};

	useEffect(() => {
		const add = e => {
			if (e.keyCode === 13) {
				setAddOnEnter(true);
			}
		};
		document.addEventListener('keydown', add);
		return () => document.removeEventListener('keydown', add);
	}, []);

	useEffect(() => {
		if (addOnEnter) {
			handleAddBtn();
		}
	}, [addOnEnter]);

	const onAddTextChange = event => {
		setNewText(event.target.value);
	};

	const handleAddBtn = () => {
		if (newText.trim() !== '') {
			setAddOnEnter(false);
			const copy = [...tableValues];
			const textToAdd = [
				...newText.split(',').map(item => item.replace(/ /g, '')),
			];
			const textToAddCopy = [...textToAdd];
			let duplicateItems = '';
			textToAdd.forEach((item, idx) => {
				if (copy.find(item1 => item1 === item) !== undefined) {
					duplicateItems += item + ', ';
					textToAddCopy.splice(
						textToAddCopy.findIndex(item2 => item2 === item),
						1
					);
				}
			});
			if (duplicateItems.length !== 0) {
				setNewText(duplicateItems.substring(0, duplicateItems.length - 2));
				setIsDuplicate(true);
			} else {
				setNewText('');
				setIsDuplicate(false);
			}
			copy.push(...textToAddCopy);
			setTableValues(copy);
			setTableValuesCopy(copy);
			setSearchText('');
		}
	};

	const onDeleteItemClicked = (item, index) => {
		const copy = [...tableValuesCopy];
		const indexOfItem = copy.findIndex(element => element === item);
		copy.splice(indexOfItem, 1);
		setTableValues(copy);
		setTableValuesCopy(copy);
		setSearchText('');
	};

	const onSearchTextChange = event => {
		const value = event.target.value;
		setSearchText(value);
		if (value === '') {
			setTableValues(updatedData.values);
			return;
		}
		const filteredData = tableValues.filter(item =>
			item.toLowerCase().includes(value.toLowerCase())
		);
		setTableValues(filteredData);
	};
	return (
		<>
			{isOpen ? (
				<Dialog
					open={isOpen}
					aria-label={title}
					style={{maxHeight: 'flex', zIndex: '11'}}
				>
					<DialogTitle onClose={handleDone}>
						<Text
							style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
							label={title}
						/>
					</DialogTitle>
					<DialogContent style={{width: '500px'}}>
						<div className={classes.titleInfo}>{titleInfo}</div>
						<div className={classes.tabelRow}>
							<TextField
								id='standard-basic'
								placeholder='Wing, Ding, Cat'
								value={newText}
								onChange={event => onAddTextChange(event)}
							/>
							<MUIButton
								color='primary'
								variant='contained'
								style={{
									height: '28px',
								}}
								onClick={() => handleAddBtn()}
								startIcon={<AddOutlined />}
							>
								{translate('Add')}
							</MUIButton>

							<TextField
								id='input-with-icon-textfield'
								label={'Search'}
								placeholder={'Search'}
								values={searchText}
								onChange={onSearchTextChange}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</div>
						{isDuplicate && (
							<div>
								<span className={classes.errorInfo}>
									{translate('Not Added. String already present')}
								</span>
							</div>
						)}
						<div className={classes.tableContainer}>
							<StaticTable
								data={tableValues}
								columns={[
									{
										id: 'strings',
										name: (
											<span style={{fontWeight: 'bold'}}>
												{translate(`Strings (${tableValues.length})`)}
											</span>
										),
									},
								]}
								actions={[
									{
										id: 'delete',
										icon: (
											<IconButton
												style={{paddingRight: '10px', right: '-14px'}}
												aria-label='Close'
											>
												<CloseIcon />
											</IconButton>
										),
										onClick: onDeleteItemClicked,
									},
								]}
							/>
						</div>
						<div className={classes.tabelRow}>
							<Checkbox
								checked={combineStringsCheckboxValue}
								onChange={event =>
									setCombineStringsCheckboxValue(event.target.checked)
								}
								inputProps={{'aria-label': 'primary checkbox'}}
							/>
							<Label
								paddingBottom={0}
								label={translate(
									'Combine strings into random pairs (eg. MsrTig)'
								)}
							/>
						</div>
						<div className={classes.tabelRow}>
							<Checkbox
								checked={appendDigitsCheckboxValue}
								onChange={event =>
									setAppendDigitsCheckboxValue(event.target.checked)
								}
								inputProps={{'aria-label': 'primary checkbox'}}
							/>
							<Label
								paddingBottom={0}
								label={translate('Append a two digit number (eg. Tig88)')}
							/>
						</div>
					</DialogContent>
					<DialogActions>
						<div>
							<MUIButton
								color='primary'
								variant='contained'
								onClick={() => handleDone()}
							>
								{translate('Done')}
							</MUIButton>
						</div>
					</DialogActions>
				</Dialog>
			) : null}
		</>
	);
}

export default withStyles(myStyles)(ConfigureUserDetailsDialog);
