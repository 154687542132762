import React from 'react';
import {useTranslate} from 'react-admin';
import {Field} from 'react-final-form';

import {useCustomFieldStyle} from '../../components/TextInput';

const CustomField = props => {
	const {
		label,
		width = '100%',
		type = 'text',
		autoFocus = false,
	} = props;

	const translate = useTranslate();

	const {inputStyle} = useCustomFieldStyle();

	return (
		<div style={{width: '100%'}}>
			<Field {...props}>
				{({input, meta}) => (
					<div>
						{label && (
							<div style={{color: 'rgb(141, 141, 141)', marginBottom: '16px'}}>
								{translate(label)}
							</div>
						)}

						<input
							className={inputStyle}
							autoFocus={autoFocus}
							placeholder={props.placeholder}
							style={{
								resize: 'none',
								width: width,
								border: props.error
									? '1px solid #DE3737'
									: props.disabled
									? 'none'
									: props.warning
									? '1px solid #ffc107'
									: 'none',
								height: props.height ? props.height : '28px',
							}}
							{...input}
							type={type}
						/>
						{meta.error && meta.touched && (
							<div style={{color: '#DE3737', marginTop: '4px'}}>{meta.error}</div>
						)}
					</div>
				)}
			</Field>

			{props.helperText && (
				<div style={{marginTop: '4px'}}>{props.helperText}</div>
			)}
		</div>
	);
};

export default CustomField;
