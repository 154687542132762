import React, {useEffect, useState} from 'react';
import {withDataProvider} from 'react-admin';
import compose from 'recompose/compose';
import {GET_FUTURE_TRAINING} from 'services/customActions';
import {EmbeddedShow, ScreenTitle, TextValue, TimeDateField} from 'components';
import TrainingCatalogue from '../../../components/TrainingCatalogue';
import LoadingList from '../../../components/loading/LoadingList';
import {getOrgId, loadObject} from '../../../utils/Util';
import {Redirect} from 'react-router-dom';
import {Button, Typography} from '@material-ui/core';
import {getUserInfo} from '../../../utils/Data';
import EditTrainingContent from 'components/TrainingCatalogue/EditTrainingContent';
import ConfirmActionDialog from 'components/dialogs/ConfirmActionDialog';

const TrainingShow = props => {
	useEffect(() => {
		if (document.body.style.overflow === 'hidden') {
			document.body.style.overflow = 'unset!important';
		}

		const element = document.getElementById('main');

		if (element) element.style.overflowY = 'scroll';

		fetchTrainingDetail();
	}, []);

	const appKeyName = props.match?.params?.appKeyName;

	const {dataProvider} = props;

	const [isEditing, setIsEditing] = useState(false);

	const [trainingData, setTrainingData] = useState(null);

	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

	if (!appKeyName) {
		return <Redirect to='/trainings' />;
	}

	if (!getUserInfo().isAdmin && !getUserInfo().isSuper) {
		return <Redirect to='/' />;
	}

	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to='/termsOfService' />;
	// }

	if (
		trainingData &&
		Object.keys(trainingData).length === 0 &&
		trainingData.constructor === Object
	) {
		return <Redirect to='/trainings' />;
	}

	if (getUserInfo().isUser) {
		return (
			<div>
				Access denied. Contact your administrator to raise your permissions
			</div>
		);
	}

	const fetchTrainingDetail = async () => {
		await dataProvider(GET_FUTURE_TRAINING, 'futureTrainings', {
			appKeyName, filter: { orgId: getOrgId()}
		}).then(res => setTrainingData(res.data));
	};

	const processSkills = () => {
		const skills = [];

		for (
			let i = 0;
			i < trainingData.trainingDetail.skillInformation.length;
			i++
		) {
			const skillInfo = trainingData.trainingDetail.skillInformation[i];
			skills.push({
				borderColour: skillInfo.borderColour,
				category: skillInfo.category,
				description: skillInfo.text,
				displayName: skillInfo.displayTitle,
				displayShortName: skillInfo.displayShortName,
				iconUrl: skillInfo.iconUrl,
				mainColour: skillInfo.mainColour,
				name: skillInfo.namedSkill,
				type: skillInfo.type,
				totalPoints: skillInfo.totalPoints,
			});
		}

		return skills;
	};

	const processCategories = () => {
		const trainingCategories = trainingData.trainingDetail.trainingCategories;
		const processedCategories = [];

		for (let i = 0; i < trainingCategories.length; i++) {
			if (
				trainingCategories[i].value &&
				trainingCategories[i].value.trim().length > 0
			) {
				processedCategories.push(trainingCategories[i].value);
			}
		}

		return processedCategories;
	};

	const onCancelEditing = () => {
		setIsEditing(false);
	};

	const onUpdateTrainingData = async () => {
		await fetchTrainingDetail();
		setIsEditing(false);
	};

	return (
		<div>
			<ScreenTitle
				{...props}
				list={'/trainings'}
				basePath={props.basePath}
				resource={props.resource}
				title={'Module Details'}
				listButton
				actions={[
					{
						render: () => {
							if (
								!isEditing &&
								(getUserInfo().role === 'TS-super' ||
									getUserInfo().role === 'TS-admin')
							) {
								return (
									<Button
										width={'148px'}
										variant={'outlined'}
										color={'primary'}
										disabled={!trainingData}
										onClick={() => {
											if (trainingData.publicationStatus === 'PUBLISHED')
												setOpenConfirmationDialog(true);
											else setIsEditing(true);
										}}
									>
										Edit Module Details
									</Button>
								);
							}
						},
					},
				]}
			/>

			{trainingData && !isEditing && (
				<TrainingCatalogue
					trainingData={trainingData}
					isExpandMore={
						loadObject('expandTrainingcatalogue') === null
							? true
							: loadObject('expandTrainingcatalogue')
					}
					toggleKey={'expandTrainingcatalogue'}
					information={
						<EmbeddedShow
							headerless
							embedded
							actions={[]}
							details={[
								{
									label: 'Name',
									render: () => (
										<TextValue
											record={trainingData}
											maxWidth='300px'
											source='name'
										/>
									),
								},
								{
									label: 'Series',
									render: () => (
										<TextValue
											record={trainingData}
											source='seriesName'
											maxWidth={'300px'}
										/>
									),
								},
								{
									label: 'Content Owner',
									render: () => (
										<TextValue
											record={trainingData}
											source='contentUpdatableOwner'
											maxWidth={'300px'}
										/>
									),
								},
								{
									label: 'Created',
									render: () => (
										<TimeDateField record={trainingData} source='createdAt' />
									),
								},
								{
									label: 'Modified',
									render: () => (
										<TimeDateField record={trainingData} source='updatedAt' />
									),
								},
							]}
						/>
					}
				></TrainingCatalogue>
			)}

			{isEditing && (
				<EditTrainingContent
					trainingData={trainingData}
					processedSkills={processSkills()}
					processedCategories={processCategories()}
					onCancelEdit={onCancelEditing}
					onUpdate={onUpdateTrainingData}
				/>
			)}

			<ConfirmActionDialog
				isOpen={openConfirmationDialog}
				onConfirm={() => {
					setOpenConfirmationDialog(false);
					setIsEditing(true);
				}}
				setIsOpen={setOpenConfirmationDialog}
				title={'Caution'}
				content={
					<Typography>
						Changing the global module metadata will change this metadata for
						all users with access to this module. If data has previously been
						exported related to this module the metadata information in new data
						exports related to this module may not match up. Are you sure you
						wish to make changes?
					</Typography>
				}
			></ConfirmActionDialog>

			{!trainingData && <LoadingList />}
		</div>
	);
};

const enhance = compose(withDataProvider);

export default enhance(TrainingShow);
