import {EmailValue, EmbeddedShow, TextValue} from 'components';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {loadObject} from 'utils/Util';
import {useDataProvider, useVersion} from 'react-admin';
import {safeRequestData} from 'utils/Data';
import {useHistory} from 'react-router-dom';

const Plan = () => {

	const isMounted = useRef(true);

	const history = useHistory();

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);
	const projectConfig = loadObject('project');

	const [state, setState] = useState({
		runwayOrgConfig: loadObject('runwayOrgConfig'),
	});

	const dataProvider = useDataProvider();
	const version = useVersion();

	const fetchData = useCallback(async () => {
		const requestList =[{resource: 'runwayOrgConfig', once: true}];

		for (let i = 0; i < requestList.length; i++) {
			const request = requestList[i];
			await safeRequestData({
				dataRequest: request,
				dataProvider: dataProvider,
				isMounted: isMounted,
				setState: setState,
			});
		}
	}, [dataProvider]);

	useEffect(() => {
		fetchData();
	}, [fetchData, version]);

	return (
		<div>
			<EmbeddedShow
				title={'Plan'}
				actions={[
					{
						label: 'Manage Licenses',
						onClick: () => {
							history.push('manageLicenses')
						},
					},
				]}
				perRow={4}
				spaceBetween={true}
				details={[
					{
						label: 'Talespin Facilitator',
						render: () => (
							<EmailValue
								record={state.runwayOrgConfig}
								source='config.planContactEmail'
								nameSource='config.planContactLabel'
								email='config.planContactEmail'
								maxWidth={"auto"}
							/>
						),
					},
					{
						label: 'Authentication Type',
						render: () => (
							<TextValue record={projectConfig} source='config.authTypeDisplay' maxWidth={"auto"}/>
						),
					},
					{
						label: 'Plan Type',
						render: () => (
							<TextValue
								record={state.runwayOrgConfig}
								source='config.orgPlanType'
							/>
						),
					},
				]}
			/>
		</div>
	);
};

export default Plan;
