import {fetchEnd, fetchStart, GET_LIST, NumberField, translate, withDataProvider} from 'react-admin';
import React, {Component} from 'react';
import ActionMenu from './ActionMenu';
import LoadingList from './loading/LoadingList';
import Pagination from './Pagination';
import SearchText from './SearchText';
import SelectBox from './SelectBox';
import TSTheme from './Theme';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import get from 'lodash/get';

import {
	MuiThemeProvider,
	createStyles,
	createTheme,
	withStyles,
} from '@material-ui/core/styles';

import {
	Checkbox,
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	MenuItem,
	Radio,
	Select,
	Typography,
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// eslint-disable-next-line no-extend-native
Array.prototype.contains = function(element) {
	return this.indexOf(element) > -1;
};

const myStyles = theme =>
	createStyles({
		/* Root Styling */
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.palette.background.containedContent,
		},

		rootInner: {
			backgroundColor: theme.palette.background.containedContent,
		},
		select: {
			border: `1px solid ${theme.palette.borderColor.dropdownPopover}`,
		},

		/* Non-embedded Styling */
		header: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			background: theme.palette.background.contained,
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: theme.palette.borderColor.default,
			borderTopLeftRadius: '4px',
			borderTopRightRadius: '4px',
			height: '71px',
			marginLeft: '10px',
			marginRight: '10px',
			paddingTop: '30px',
			paddingBottom: '30px',
		},
		tableWrapper: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			borderWidth: '1px',
			borderStyle: 'solid',
			overflow: 'auto',
			borderColor: theme.palette.borderColor.default,
			backgroundColor: theme.palette.background.containedContent,
			marginLeft: '10px',
			marginRight: '10px',
		},
		pagination: {
			width: '100%',
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			background: theme.palette.background.contained,
			overflow: 'hidden',
		},

		/* Embedded Styling */
		embeddedHeader: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			backgroundColor: 'transparent',
			paddingTop: '4px',
			paddingBottom: '4px',
		},
		embeddedTableWrapper: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderRadius: '0px',
			overflowX: 'auto',
			maxHeight: props => (props.maxHeight ? props.maxHeight : '300px'),
			borderColor: theme.palette.borderColor.default,
			overflowY: 'auto',
			'&::-webkit-scrollbar': {
				width: '0.4em',
				backgroundColor: '#ecf4f6',
			},
			'&::-webkit-scrollbar-track': {
				boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				width: '0.4em',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#346dec',
				width: '0.4em',
				outline: 'none',
				borderRadius: '20px',
			},
		},
		innerTableWrapper: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			borderWidth: 0,
			borderTopWidth: '1px',
			borderStyle: 'solid',
			overflow: 'auto',
			paddingTop: '10px',
			margin: '16px',
			marginTop: '30px',
			borderColor: theme.palette.borderColor.default,
		},
		embeddedPagination: {
			width: '100%',
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			backgroundColor: 'transparent',
			overflow: 'hidden',
		},

		/* Common Styling */
		title: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			marginLeft: '5px',
			fontSize: '20px',
			fontFamily: 'Archivo Bold',
			'& > span': {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
			},
		},
		filters: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			minHeight: '56px',
		},
		filtersAlignedLeft: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},
		filtersAlignedRight: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-end',
		},
		filtersAlignedCenter: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			paddingLeft: '10px',
		},

		filterField: {
			minWidth: '190px',
			// maxWidth: '190px',
			// width: '190px',
			minHeight: '34px',
			maxHeight: '34px',
			height: '34px',
			marginLeft: '5px',
			marginRight: '5px',
			borderColor: theme.palette.borderColor.default,
			borderStyle: 'solid',
			borderWidth: '1px',
			'&:first-of-type': {
				marginLeft: '0px',
			},
			'&:last-of-type': {
				marginRight: '0px',
			},
			'&:hover': {
				borderColor: theme.palette.borderColor.default,
				borderStyle: 'solid',
				borderWidth: '1px',
			},
			'& > *': {
				width: 'auto',
			},
		},
		shrinkedFilters: {
			minWidth: '150px',
			maxWidth: '150px',
			width: '150x',
			minHeight: '34px',
			maxHeight: '34px',
			height: '34px',
			marginLeft: '5px',
			marginRight: '5px',
			borderColor: theme.palette.borderColor.default,
			borderStyle: 'solid',
			borderWidth: '1px',
			'&:first-of-type': {
				marginLeft: '10px',
			},
			'&:last-of-type': {
				marginRight: '0px',
			},
			'&:hover': {
				borderColor: theme.palette.borderColor.default,
				borderStyle: 'solid',
				borderWidth: '1px',
			},
			'& > *': {
				width: 'auto',
			},
		},
		bulkActions: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			//marginRight: '10px',
		},
		bulkAction: {
			paddingLeft: '7px',
			paddingRight: '0px',
		},
		table: {
			width: '100%',
			borderCollapse: 'collapse',
			tableLayout: 'fixed',
			borderSpacing: '0px',
		},
		thead: {
			borderBottomWidth: '1px',
			borderBottomStyle: 'solid',
			borderBottomColor: theme.palette.background.contained,
			'& > tr': {
				height: '36px',
				'& > th': {
					overflow: 'hidden',
					paddingLeft: '12px',
					paddingRight: '12px',
					color: theme.palette.color.informational,
					'& > div': {
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					},
				},
			},
			'& tr:nth-child(1) th': {
				position: 'sticky',
				top: '0',
				zIndex: '10',
				paddingRight: '6px!important',
				backgroundColor: theme.palette.background.default,
				overflow: 'auto',
			},
		},
		tbody: {
			'& > tr > td': {
				overflow: 'hidden',
				paddingLeft: '12px',
				paddingRight: '12px',
			},
		},
		row: {
			height: '52px',
			maxHeight: '32px',
			borderTopWidth: '1px',
			borderTopStyle: 'solid',
			borderTopColor: theme.palette.background.contained,
			// borderBottomWidth: '1px',
			// borderBottomStyle: 'solid',
			// borderBottomColor: theme.palette.background.contained,
			borderBottom: '1px solid #ddd',
			paddingTop: '5px',
			paddingBottom: '5px',
			color: theme.palette.color.default,
			'&:last-of-type': {
				borderStyle: 'none !important',
			},
		},
		clickable: {
			'&:hover': {
				// backgroundColor: 'rgba(255, 255, 255, 0.2)',
				cursor: 'pointer !important',
			},
			'& *:hover': {
				cursor: 'pointer !important',
			},
		},
		// selection: {
		// 	borderBottom: `1px solid ${theme.palette.borderColor.divider}`,
		// },
		emptyRow: {
			height: '200px',
			borderTopStyle: 'none !important',
			borderBottomStyle: 'none !important',
			'& > td': {
				textAlign: 'center',
			},
		},
		loadingRow: {
			height: '200px',
			borderTopStyle: 'none !important',
			borderBottomStyle: 'none !important',
			'& > td': {
				textAlign: 'center',
			},
		},
		errorRow: {
			height: '200px',
			borderTopStyle: 'none !important',
			borderBottomStyle: 'none !important',
			'& > td': {
				textAlign: 'center',
			},
		},
		firstRow: {
			borderTopStyle: 'none !important',
		},
		lastRow: {
			borderBottomStyle: 'none !important',
		},
		evenRow: {},
		oddRow: {
			backgroundColor: 'rgba(255,255,255,0.1)',
		},
		selectedRow: {
			'& > td:first-of-type svg': {
				color: theme.palette.color.important + ' !important',
			},
			'& > td > *': {
				color: theme.palette.color.important,
			},
			'& > td > div > span': {
				color: theme.palette.color.important,
			},
		},
		columnHeader: {
			overflow: 'hidden',
			width: 'auto',
		},
		headerContent: {
			display: 'flex',
			flexDirection: 'row',
			fontSize: '14px',
			color: theme.palette.color.default,
		},
		headerSorted: {
			color: theme.palette.color.default,
		},
		columnToggle: {
			width: '48px',
			padding: '0px !important',
		},
		columnActions: {
			width: '70px',
			padding: '0px !important',
		},
		cell: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		cellContent: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
		},
		justifyContentLeft: {
			justifyContent: 'flex-start',
		},
		justifyContentCenter: {
			justifyContent: 'center',
		},
		justifyContentRight: {
			justifyContent: 'flex-end',
		},
		justifyContentend: {
			justifyContent: 'end',
		},
		cellToggle: {},
		cellActions: {},
		selectedItemText: {
			width: '100%',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		selectedFilters: {
			width: '100%',
			margin: '0 10px 5px 10px',
			overflow: 'hidden',
		},
	});

export const FilterBarTheme = createTheme({
	...TSTheme,
	overrides: {
		MuiFormControl: {
			root: {
				minHeight: '34px',
				maxHeight: '34px',
				height: '34px',
				overflow: 'hidden',
				'&:focus': {
					outlineWidth: 0,
					borderWidth: 0,
				},
				'&:hover': {
					outlineWidth: 0,
					borderWidth: 0,
				},
				backgroundColor: TSTheme.palette.background.default,
				padding: 2,
				borderRadius: 4,
				borderStyle: 'solid',
				borderWidth: '1px',
			},
		},
		MuiSelect: {
			selectMenu: {
				paddingTop: '0px',
				paddingBottom: '0px',
				height: '100%',
				paddingRight: '32px  !important',
			},
			icon: {
				borderRadius: '2px',
				backgroundColor: TSTheme.palette.background.important,
				color: TSTheme.palette.info.main,
			},
		},
		MuiList: {
			root: {
				backgroundColor: TSTheme.palette.background.contained,
			},
		},
		MuiInput: {
			root: {
				flex: '1 1 auto',
				display: 'flex',
				alignItems: 'center',
				height: '100%',
				padding: '5px 0px 0px 4px',
				'& :focus': {
					outlineWidth: 0,
					borderWidth: 0,
				},
				'& :hover': {
					outlineWidth: 0,
					borderWidth: 0,
				},
			},
			underline: {
				border: 'none',
				position: 'relative',
				transition: 'none',
				transform: 'none',
				marginTop: '0 !important',
				'&::before': {
					border: 0,
					outline: 0,
					transform: 'scale(0)',
				},
				'&::after': {
					border: 0,
					outline: 0,
					transform: 'scale(0)',
				},
			},
			input: {
				paddingTop: '0px',
				paddingBottom: '0px',
				'&:hover': {
					border: 0,
					outline: 0,
				},
				border: 0,
				margin: 2,
				'&:-webkit-autofill': {
					transitionDelay: '9999s',
					transitionProperty: 'background-color, color',
				},
			},
		},
		MuiInputLabel: {
			root: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
			},
			animated: {
				width: '100%',
				height: '100%',
				top: 0,
				left: 0,
				marginLeft: 2,
				marginBottom: 0,
				transition: 'none',
				transform: 'none',
			},
			shrink: {
				transform: 'scale(0) !important',
				color: 'transparent',

				'&[class*="Labeled"]': {
					transform: 'scale(1) !important',
					color: 'white !important',
					transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
					transformOrigin: 'top left',
					marginLeft: 0,
					height: 12,
					marginBottom: 0,
					top: 0,
				},
			},
		},
	},
});

/**
 * Column Definitions:
 * {
 *   id: 'fieldId',          // Name of the record field this column represents.
 *   name: 'displayName',    // The display name/label for the column header.
 *   sortable: true,         // Indicated whether this column is sortable.
 *   align: 'center',        // Alignment of the column header and cell contents (left, center or right).
 *   render: function(record, column) { ... } // A custom render function for rendering the cell value.
 * }
 *
 * Filter Definitions:
 * {
 *   id: 'fieldId',          // Name of the record field to filter on.
 *   name: 'displayName',    // The display name/label for the filter.
 *   type: 'regex'           // Type of filter: regex, or exact.
 *   choices: []             // Optional list of available choices (presented as a drop down menu).
 *   display: 'search'       // Type of UI display: text constant
 * }
 *
 * Bulk Action Definitions:
 * {
 *   id: 'bulkActionId',     // A unique identifier for the bulk action.
 *   render: function(selectedIds) { ... } // A custom render function for rendering the bulk action item.
 * }
 *
 * Action Definitions:
 * {
 *   id: 'actionId',         // A unique identifier for the action.
 *   render: function(record) { ... } // A custom render function for rendering the action menu item.
 * }
 */
class EmbeddedList extends Component {
	_isMounted = true;
	state = {
		records: [],
		firstLoad: true,
		error: null,
		total: 0,
		pageSize: this.props.defaultPageSize || 10,
		pageNumber: 1,
		sortBy: null,
		sortDirection: null,
		dynamicFilters: [],
		filterTimeout: 0,
		pageChangeTimeOut: 0,
		showSpinner: false,
	};

	componentDidMount(resetPaging = false) {
		this._isMounted = true;
		const {sort, dynamicFilters} = this.props;

		const filters = [];

		if (dynamicFilters && dynamicFilters.length > 0) {
			dynamicFilters.forEach(filter => {
				filters.push({
					id: filter.id,
					name: filter.name,
					type: filter.type,
					choices: filter.choices,
					render: filter.render,
					value:
						filter.value && filter.value !== 'null'
							? filter.value
							: filter.choices && filter.choices.length > 0
							? filter.choices[0].value
							: '',
					clearBulkSelectionOnFilterChange: filter.clearBulkSelectionOnFilterChange
				});
			});
		}

		let sortBy = null;
		let sortDirection = null;
		if (sort) {
			sortBy = sort.field;
			sortDirection = sort.order;
		}

		if (this._isMounted)
			this.setState(
				{
					sortBy,
					sortDirection,
					dynamicFilters: filters,
					records: [],
					firstLoad: true,
					pageSize: resetPaging ? (this.props.defaultPageSize || 10) : this.state.pageSize,
					pageNumber: resetPaging ? 1 : this.state.pageNumber
				},
				() => {
					this.loadCurrentPage();
				}
			);
	}

	componentDidUpdate(prevProps) {
		let resetPaging = false;

		if (this.props.version !== prevProps.version) {
			this.loadCurrentPage();
		}

		let forceUpdate = false;

		if (this.props.id !== prevProps.id) forceUpdate = true;
		if (this.props.resource !== prevProps.resource) forceUpdate = true;
		if (
			JSON.stringify(this.props.staticFilters) !==
			JSON.stringify(prevProps.staticFilters)
		)
			{
				forceUpdate = true;
				resetPaging = true;
			}
		if (
			JSON.stringify(this.props.dynamicFilters) !==
			JSON.stringify(prevProps.dynamicFilters)
		)
			forceUpdate = true;

		if (forceUpdate) {
			this.componentDidMount(resetPaging);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	loadCurrentPage = () => {
		let {dataProvider, resource, staticFilters} = this.props;
		const {
			pageSize,
			pageNumber,
			sortBy,
			sortDirection,
			isSortInverted,
			dynamicFilters,
		} = this.state;

		// Construct the appropriate payload to send the GET_LIST call.
		const payload = {
			pagination: {
				page: pageNumber,
				perPage: pageSize,
			},
		};

		// Add a sort configuration if sortBy and sortDirection are defined.

		let usedSortDirection = sortDirection;
		if (isSortInverted) {
			if (usedSortDirection === 'ASC') {
				usedSortDirection = 'DSC';
			} else {
				usedSortDirection = 'ASC';
			}
		}
		if (sortBy && sortDirection) {
			payload.sort = {
				field: sortBy,
				order: usedSortDirection,
			};
		}

		// Add a filter configuration if a static filter or dynamic filter is present.
		if (staticFilters || dynamicFilters) {
			payload.filter = {};

			/*
			//https://talespin.atlassian.net/browse/RP-335
			if (sortBy && sortDirection && sortBy === 'avgScore') {
				payload.filter['summaryData.attempts'] = {$gt: 0};
			}

			//https://talespin.atlassian.net/browse/RP-335
			if (sortBy && sortDirection && sortBy === 'completionRate') {
				payload.filter['summaryData.attempts'] = {$gt: 0};
			}*/

			if (staticFilters) {
				staticFilters.forEach(staticFilter => {
					if (staticFilter && staticFilter.id) {
						switch (staticFilter.type) {
							case 'regex':
								payload.filter[staticFilter.id] = {
									$regex: staticFilter.value,
									$options: 'i',
								};
								break;
							case 'safe_regex':
								if (staticFilter.value && staticFilter.value[0] === '/') {
									var regexFilter = staticFilter.value.substring(
										1,
										staticFilter.value.length
									);

									payload.filter[staticFilter.id] = {
										$regex: regexFilter.value,
										$options: 'i',
									};
								} else {
									var escapedFilter = staticFilter.value.replace(
										/[-/\\^$*+?.()|[\]{}]/g,
										'\\$&'
									);
									payload.filter[staticFilter.id] = {
										$regex: escapedFilter.value,
										$options: 'i',
									};
								}

								break;
							default:
								if (
									(this.props.resource === 'trainingsforuser' ||
										this.props.resource === 'listtrainingforteam') &&
									staticFilter.id === 'accessibility'
								) {
									payload.filter['filter'] = {
										accessibility: staticFilter.value,
									};
								} else {
									payload.filter[staticFilter.id] = staticFilter.value;
								}
						}
					}
				});
			}

			if (dynamicFilters) {
				dynamicFilters.forEach(dynamicFilter => {
					if (dynamicFilter && dynamicFilter.id) {
						switch (dynamicFilter.type) {
							case 'regex':
								if (dynamicFilter.choices) {
									if (
										dynamicFilter.value &&
										dynamicFilter.value.trim().length > 0 &&
										dynamicFilter.value !== '*'
									) {
										payload.filter[dynamicFilter.id] = {
											$regex: dynamicFilter.value.trim(),
											$options: 'i',
										};
									}
								} else {
									if (
										dynamicFilter.value &&
										dynamicFilter.value.trim().length > 0
									) {
										payload.filter[dynamicFilter.id] = {
											$regex: dynamicFilter.value.trim(),
											$options: 'i',
										};
									}
								}

								break;

							case 'safe_regex':
								if (
									dynamicFilter.value &&
									dynamicFilter.value.trim().length > 0
								) {
									if (dynamicFilter.value && dynamicFilter.value[0] === '/') {
										var regexFilter = dynamicFilter.value.substring(
											1,
											dynamicFilter.value.length
										);

										payload.filter[dynamicFilter.id] = {
											$regex: regexFilter,
											$options: 'i',
										};
									} else {
										var escapedFilter = dynamicFilter.value.replace(
											/[-/\\^$*+?.()|[\]{}]/g,
											'\\$&'
										);
										payload.filter[dynamicFilter.id] = {
											$regex: escapedFilter,
											$options: 'i',
										};
									}
								}
								break;

							default:
								if (dynamicFilter.choices) {
									if (typeof dynamicFilter.value !== 'string') {
										if (
											(this.props.resource === 'trainingsforuser' ||
												this.props.resource === 'listtrainingforteam') &&
											dynamicFilter.id === 'accessibility'
										) {
											payload.filter['accessibility'] = {
												assigned: dynamicFilter.value,
											};
										} else {
											payload.filter[dynamicFilter.id] = dynamicFilter.value;
										}
									} else if (
										dynamicFilter.value &&
										dynamicFilter.value.trim().length > 0 &&
										dynamicFilter.value !== '*'
									) {
										payload.filter[
											dynamicFilter.id
										] = dynamicFilter.value.trim();
									}
								} else if (
									this.props.resource === 'trainings' &&
									dynamicFilter.id === 'moduleFilter'
								) {
									Object.entries(dynamicFilter.value).forEach(
										([key, value]) => {
											if (value) payload.filter[key] = value;
										}
									);
								} else if (dynamicFilter.value) {
									payload.filter[dynamicFilter.id] = dynamicFilter.value;
								}
						}
					}
				});
			}
		}

		if (Array.isArray(this.props.resource) && this._isMounted) {
			if (this._isMounted)
				this.setState({
					records: resource,
					total: resource.length,
					error: null,
				});
		} else {
			this.setState({ showSpinner: true });
			this.props.fetchStart();
			dataProvider(GET_LIST, resource, payload)
				.then(response => {
					const {data, total} = response;

					if (this._isMounted && !Array.isArray(this.props.resource))
						this.setState({
							records: data != null && Array.isArray(data) ? data : [],
							total: total != null ? total : 0,
							error: null,
						});
				})
				.catch(error => {
					if (this._isMounted)
						this.setState({records: [], total: 0, error: error});
				})
				.finally(() => {
					if (this._isMounted) {
						this.setState({firstLoad: false, showSpinner: false});
					}
					this.props.fetchEnd();
				});
		}
	};

	setPageSize = pageSize => {
		const {total, pageNumber} = this.state;

		const expectedMaxPages = Math.ceil(total / pageSize);
		const currentPage = total === 0 ?
			1 : pageNumber <= expectedMaxPages ? pageNumber : expectedMaxPages;

		if (pageSize != null && !Number.isNaN(pageSize)) {
			if (this._isMounted)
				this.setState(
					{
						pageSize: pageSize,
						pageNumber: currentPage,
						showSpinner: this.props.showSpinnerOnFilter ? true : false,
					},
					() => {
						this.loadCurrentPage();
					}
				);
		}
	};

	setPageNumber = pageNumber => {
		const {pageChangeTimeOut} = this.state;

		if (pageChangeTimeOut) {
			clearTimeout(pageChangeTimeOut);
		}
		if (pageNumber != null && !Number.isNaN(pageNumber)) {
			if (this._isMounted) {
				this.setState({
					showSpinner: this.props.showSpinnerOnFilter ? true : false,
					pageNumber: pageNumber,
					pageChangeTimeOut: setTimeout(() => {
						this.loadCurrentPage();
					}, 600),
				});
			}
		}
	};

	handleFilterChange = (id, value, clearBulkSelectionOnFilterChange) => {
		if (clearBulkSelectionOnFilterChange && this.props.onBulkSelection) this.props.onBulkSelection([], 'remove all');
		const {filterTimeout} = this.state;

		const dynamicFilters = [...this.state.dynamicFilters];

		const targetFilter = dynamicFilters.find(
			item => item != null && item.id === id
		);
		const targetFilterIdx = dynamicFilters.indexOf(targetFilter);

		if (targetFilter && targetFilterIdx > -1) {
			if (filterTimeout) {
				clearTimeout(filterTimeout);
			}

			const updatedFilter = {...targetFilter};

			updatedFilter.value = value;
			dynamicFilters[targetFilterIdx] = updatedFilter;
			if (this._isMounted)
				this.setState({
					pageNumber: 1,
					dynamicFilters,
					showSpinner: this.props.showSpinnerOnFilter ? true : false,
					filterTimeout: setTimeout(() => {
						this.loadCurrentPage();
					}, 1000),
				});
		}
	};

	handleToggleSort = ({id, isInverted}) => {
		const {sortBy, sortDirection} = this.state;

		if (id) {
			let newSortBy = id;
			let newSortDirection = null;

			if (newSortBy === sortBy) {
				if (sortDirection == null || sortDirection === 'DESC')
					newSortDirection = 'ASC';
				else newSortDirection = 'DESC';
			} else {
				newSortDirection = 'ASC';
			}

			if (this._isMounted)
				this.setState(
					{
						isSortInverted: isInverted,
						sortBy: newSortBy,
						sortDirection: newSortDirection,
						showSpinner: this.props.showSpinnerOnFilter ? true : false,
					},
					() => {
						this.loadCurrentPage();
					}
				);
		}
	};

	handleRowSelectionToggle = record => {
		const {selectionToggled} = this.props;

		if (selectionToggled) {
			selectionToggled(record);
		}
	};

	handleRowClick = record => {
		const {rowClicked} = this.props;
		if (rowClicked) rowClicked(record);
	};

	isRecordSelected = key => {
		const {selectedIds} = this.props;

		if (selectedIds) return selectedIds.includes(key);

		return false;
	};

	renderFilters() {
		const {
			classes,
			title,
			bulkActions,
			selectedIds,
			selectedFilterNode,
			tableDescription
		} = this.props;
		const {dynamicFilters} = this.state;

		const filterItems = [];

		if (dynamicFilters && dynamicFilters.length > 0) {
			dynamicFilters.forEach(filter => {
				if (filter.choices) {
					const choices = [];

					filter.choices.forEach(choice => {
						choices.push(
							<MenuItem key={choice.value} value={choice.value}>
								{choice.label}
							</MenuItem>
						);
					});

					filterItems.push(
						<FormControl
							key={'filter-' + filter.id}
							className={classnames({
								[classes.filterField]: !this.props.filterShrink,
								[classes.shrinkedFilters]: this.props.filterShrink,
							})}
						>
							{/* <InputLabel htmlFor={'filter-' + filter.id}>
								{filter.display === 'search' ? (
									<SearchText label={filter.name} />
								) : (
									filter.name
								)}
							</InputLabel> */}
							<Select
								id={'filter-' + filter.id}
								value={filter.value}
								IconComponent={ExpandMoreIcon}
								onChange={e => {
									this.handleFilterChange(filter.id, e.target.value, filter.clearBulkSelectionOnFilterChange)}
								}
								MenuProps={{
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'left',
									},
									transformOrigin: {
										vertical: 'top',
										horizontal: 'left',
									},
									getContentAnchorEl: null,
									classes: {
										paper: classes.select,
									},
								}}
							>
								{choices}
							</Select>
						</FormControl>
					);
				} else if (filter.render) {
					filterItems.push(
						<div key={filter.id}>{filter.render(this.handleFilterChange)}</div>
					);
				} else {
					let adornment = null;

					if (filter.value && filter.value.length > 0) {
						adornment = (
							<InputAdornment position='end'>
								<ClearIcon
									style={{fontSize: 16, marginRight: '5px'}}
									onClick={() => this.handleFilterChange(filter.id, '')}
								/>
							</InputAdornment>
						);
					}

					filterItems.push(
						<FormControl
							className={classnames({
								[classes.filterField]: !this.props.filterShrink,
								[classes.shrinkedFilters]: this.props.filterShrink,
							})}
							key={filter.id}
						>
							<InputLabel htmlFor={'filter-' + filter.id}>
								<SearchText label={filter.name} />
							</InputLabel>
							<Input
								id={'filter-' + filter.id}
								value={filter.value}
								onChange={e =>
									this.handleFilterChange(filter.id, e.target.value)
								}
								endAdornment={adornment}
							/>
						</FormControl>
					);
				}
			});
		}

		const bulkActionItems = [];

		if (bulkActions) {
			bulkActions.forEach(action => {
				if (action && action.render) {
					bulkActionItems.push(
						<div key={action.id} id={action.id} className={classes.bulkAction}>
							{action.render(selectedIds)}
						</div>
					);
				}
			});
		}

		return (
			<div>
				<div
					className={classnames({
						[classes.header]: !this.props.embedded,
						[classes.embeddedHeader]: this.props.embedded,
					})}
				>
					{title && <div className={classes.title}>{title}</div>}

					<MuiThemeProvider theme={FilterBarTheme}>
						<div
							className={classnames({
								[classes.filters]: true,
								[classes.filtersAlignedLeft]:
									title == null || bulkActions != null,
								[classes.filtersAlignedRight]: !(
									title == null || bulkActions != null
								),
								[classes.filtersAlignedCenter]: title && bulkActions,
							})}
						>
							{filterItems}
						</div>
					</MuiThemeProvider>

					<div className={classes.bulkActions}>{bulkActionItems}</div>
				</div>

				{tableDescription && (
					<Typography
						style={{
							fontFamily: 'Montserrat Regular',
							fontSize: '14px',
							marginLeft: '15px',
							marginTop: '-8px',
							marginBottom: '10px',
						}}
					>
						{tableDescription}
					</Typography>
				)}

				{selectedFilterNode && (
					<div className={classes.selectedFilters}>{selectedFilterNode}</div>
				)}
			</div>
		);
	}

	renderTable() {
		const {classes} = this.props;

		return (
			<table className={classes.table}>
				<thead className={classes.thead}>{this.renderHeaders()}</thead>

				<tbody className={classes.tbody}>{this.renderRecords()}</tbody>
			</table>
		);
	}

	renderHeaders() {
		const {
			translate,
			classes,
			columns,
			selectedIds,
			actions,
			customHeaderStyle,
			onBulkSelection,
		} = this.props;
		const {sortBy, sortDirection, records} = this.state;

		const columnHeaders = [];

		// If row selection is enabled via the presence of the 'selectedIds' property, render the toggle checkbox header.
		if (selectedIds) {
			let allDisplayedRecordsSelected = false;
			let atleastOneDisplayedRecordSelected = false;
			if (onBulkSelection) {
				atleastOneDisplayedRecordSelected = records.some(record =>
					selectedIds.includes(record.id)
				);
			}
			if (onBulkSelection) {
				allDisplayedRecordsSelected =
					selectedIds.length > 0 &&
					records.every(record => selectedIds.includes(record.id));
			}

			columnHeaders.push(
				<th
					key={'toggle_column'}
					className={classnames({
						[classes.columnHeader]: true,
						[classes.columnToggle]: true,
					})}
					style={customHeaderStyle}
				>
					<div
						className={classnames({
							[classes.headerContent]: true,
						})}
					></div>
					{onBulkSelection && (
						// <SelectBox
						// 	record={record}
						// 	source={record.id ? 'id' : 'name'}
						// 	isSelected={this.isRecordSelected}
						// 	onChange={() => this.handleRowSelectionToggle(record)}
						// />
						<Checkbox
							style={{marginLeft: '12px'}}
							checked={allDisplayedRecordsSelected}
							indeterminate={
								onBulkSelection &&
								atleastOneDisplayedRecordSelected &&
								!allDisplayedRecordsSelected
							}
							onChange={e =>
								onBulkSelection(
									records,
									atleastOneDisplayedRecordSelected
										? 'remove'
										: e.target.checked
										? 'add'
										: 'remove'
								)
							}
						/>
					)}
				</th>
			);
		}

		// Render each column header.
		if (columns) {
			columns.forEach(column => {
				if (column) {
					const style = customHeaderStyle
						? Object.assign({}, customHeaderStyle)
						: {};

					if (column.width != null) {
						style.minWidth = column.width;
						style.maxWidth = column.width;
						style.width = column.width;
					}

					if (column.sortable) {
						columnHeaders.push(
							<th
								key={column.id}
								style={style}
								className={classes.columnHeader}
							>
								<div
									className={classnames({
										[classes.headerContent]: true,
										[classes.justifyContentLeft]: column.align === 'left',
										[classes.justifyContentCenter]: column.align === 'center',
										[classes.justifyContentRight]: column.align === 'right',
										[classes.headerSorted]: column.id === sortBy,
									})}
								>
									<label
										onClick={() =>
											this.handleToggleSort({
												id: column.id,
												isInverted: column.isInverted,
											})
										}
									>
										{column.name}
									</label>

									{sortBy === column.id && sortDirection === 'ASC' && (
										<KeyboardArrowUpIcon
											onClick={() =>
												this.handleToggleSort({
													id: column.id,
													isInverted: column.isInverted,
												})
											}
										/>
									)}

									{sortBy === column.id && sortDirection === 'DESC' && (
										<KeyboardArrowDownIcon
											onClick={() =>
												this.handleToggleSort({
													id: column.id,
													isInverted: column.isInverted,
												})
											}
										/>
									)}
								</div>
							</th>
						);
					} else {
						columnHeaders.push(
							<th
								key={column.id}
								style={style}
								className={classes.columnHeader}
							>
								<div
									className={classnames({
										[classes.headerContent]: true,
										[classes.justifyContentLeft]: column.align === 'left',
										[classes.justifyContentCenter]: column.align === 'center',
										[classes.justifyContentRight]: column.align === 'right',
									})}
								>
									{typeof column.name === 'string' && (
										<label>{column.name}</label>
									)}
									{typeof column.name !== 'string' && column.name}
								</div>
							</th>
						);
					}
				}
			});
		}

		// If row actions are enabled via the presence of the 'actions' property, render the actions header.
		if (actions && Array.isArray(actions) && actions.length > 0) {
			columnHeaders.push(
				<th
					key={'actions_column'}
					className={classnames({
						[classes.columnHeader]: true,
						[classes.columnActions]: true,
					})}
					style={customHeaderStyle}
				>
					<div
						className={classnames({
							[classes.headerContent]: true,
							[classes.justifyContentCenter]: true,
						})}
					>
						<label>{translate('Actions')}</label>
					</div>
				</th>
			);
		}

		return <tr>{columnHeaders}</tr>;
	}

	renderRecords() {
		const {
			translate,
			classes,
			selectedIds,
			columns,
			stripped,
			actions,
			rowClicked,
			sortSelectionTop,
			splitSelection,
			selectionToggled,
			selectField,
		} = this.props;
		const {firstLoad, error, showSpinner} = this.state;

		let {records} = this.state;

		let colCount = columns.length;
		if (selectedIds) colCount++;
		if (actions && Array.isArray(actions) && actions.length > 0) colCount++;

		const rows = [];

		if (sortSelectionTop) {
			const temp1 = [];

			const temp2 = [];

			records.forEach(record => {
				if (selectedIds && selectedIds.contains(record.id)) {
					temp1.push(record);
				} else {
					temp2.push(record);
				}
			});

			records = temp1.concat(temp2);
		}

		// Render the error if there is an error present.
		if (error) {
			rows.push(
				<tr
					className={classnames({
						[classes.row]: true,
						[classes.errorRow]: true,
					})}
				>
					<td colSpan={colCount}>{'No Records Found'}</td>
				</tr>
			);

			return rows;
		}

		// Render the loading row if this is the first load.
		if ((firstLoad && !Array.isArray(this.props.resource)) || showSpinner) {
			rows.push(
				<tr
					className={classnames({
						[classes.row]: true,
						[classes.loadingRow]: true,
					})}
					key={'loading'}
				>
					<td colSpan={colCount}>{<LoadingList />}</td>
				</tr>
			);

			return rows;
		}

		// Render the no records row if there are no records to display.
		if (!records || records.length === 0) {
			rows.push(
				<tr
					className={classnames({
						[classes.row]: true,
						[classes.emptyRow]: true,
					})}
					key={'no-records'}
				>
					<td colSpan={colCount}>{translate('No Records Found')}</td>
				</tr>
			);

			return rows;
		}

		// Only render the rows if necessary.
		if (columns && records && records.length > 0) {
			// Render a row for each record.
			records.forEach((record, rowIdx) => {
				const cells = [];

				// If row selection is enabled via the presence of the 'selectedIds' property, render the toggle checkbox.
				if (selectedIds) {
					cells.push(
						<td
							className={classnames({
								[classes.cell]: true,
								[classes.cellToggle]: true,
							})}
							key={'check-box'}
						>
							{selectField && selectField === 'radio' ? (
								<Radio
									style={{padding: 0}}
									checked={this.isRecordSelected(record.appId)}
									value={record.id}
									onChange={_e => this.handleRowSelectionToggle(record)}
								/>
							) : (
								<SelectBox
									record={record}
									source={record.id ? 'id' : 'name'}
									isSelected={this.isRecordSelected}
									onChange={() => this.handleRowSelectionToggle(record)}
								/>
							)}
						</td>
					);
				}

				// Render each column cell for the row.
				columns.forEach((column, index) => {
					if (column) {
						const renderedValue =
							column.render != null
								? column.render(record, column)
								: record[column.id];
						const isEmptyValue =
							renderedValue == null ||
							(typeof renderedValue === 'string' &&
								renderedValue.trim().length === 0)
								? true
								: false;
						const cellElement = isEmptyValue ? (
							<label style={{whiteSpace: 'nowrap', flex: '0 0 auto'}}>
								---
							</label>
						) : typeof renderedValue !== 'object' ? (
							<label
								title={renderedValue}
								style={{
									whiteSpace: 'nowrap',
									flex: '0 1 auto',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									minWidth: '0',
								}}
							>
								{isNaN(renderedValue) ? (
									renderedValue
								) : (
									<NumberField source={column.id} record={record} />
								)}
							</label>
						) : (
							renderedValue
						);

						const style = {};

						if (column.width != null) {
							style.minWidth = column.width;
							style.maxWidth = column.width;
							style.width = column.width;
						}

						cells.push(
							<td
								className={classes.cell}
								style={style}
								onClick={() => this.handleRowClick(record)}
								key={index}
							>
								<div
									className={classnames({
										[classes.cellContent]: true,
										[classes.justifyContentLeft]: column.align === 'left',
										[classes.justifyContentCenter]: column.align === 'center',
										[classes.justifyContentRight]: column.align === 'right',
									})}
								>
									{splitSelection ? (
										<div
											style={{
												display: 'flex',
												width: '100%',
												justifyContent: 'space-between',
												alignItems: 'center',
											}}
										>
											<span className={classes.selectedItemText}>
												{cellElement}
											</span>
											<IconButton
												onClick={() => this.handleRowSelectionToggle(record)}
											>
												<CloseIcon color='primary' />
											</IconButton>
										</div>
									) : (
										cellElement
									)}
								</div>
							</td>
						);
					}
				});

				// If row actions are enabled via the presence of the 'actions' property, render the actions menu.
				if (actions && Array.isArray(actions) && actions.length > 0) {
					const actionItems = [];

					actions.forEach(action => {
						if (action && action.render) {
							actionItems.push(action.render(record));
						}
					});

					cells.push(
						<td
							className={classnames({
								[classes.cell]: true,
								[classes.cellActions]: true,
							})}
							key={'action-cell'}
						>
							<div
								className={classnames({
									[classes.cellContent]: true,
									[classes.justifyContentCenter]: true,
								})}
							>
								<ActionMenu label='...'>{actionItems}</ActionMenu>
							</div>
						</td>
					);
				}

				// Add the rendered row to the resulting collection.
				rows.push(
					<tr
						className={classnames({
							[classes.row]: true,
							[classes.evenRow]: stripped && (rowIdx + 1) % 2 === 0,
							[classes.oddRow]: stripped && (rowIdx + 1) % 2 !== 0,
							[classes.selection]:
								selectionToggled || Array.isArray(this.props.resource),
							[classes.selectedRow]:
								selectedIds && selectedIds.includes(record.id),
							[classes.clickable]: rowClicked != null,
						})}
						key={rowIdx}
					>
						{cells}
					</tr>
				);
			});
		}

		return rows;
	}

	renderPagination() {
		const {classes, isPerPageControl, isPageChooser} = this.props;
		const {pageNumber, pageSize, total} = this.state;

		return (
			<div
				className={classnames({
					[classes.pagination]: !this.props.embedded,
					[classes.embeddedPagination]: this.props.embedded,
				})}
			>
				<Pagination
					page={pageNumber}
					perPage={pageSize}
					total={total}
					setPerPage={this.setPageSize}
					setPage={this.setPageNumber}
					isPerPageControl={isPerPageControl}
					isPageChooser={isPageChooser}
				/>
			</div>
		);
	}

	render() {
		const {classes, id, headerNode} = this.props;

		return (
			<div
				id={id}
				className={classnames({
					[classes.root]: true,
					[classes.rootInner]: this.props.inner,
				})}
				style={{
					MozBoxShadow: this.props.embedded ? 'none' : '0px 5px 20px #00000026',
					boxShadow: this.props.embedded ? 'none' : '0px 5px 20px #00000026',
				}}
			>
				<div
					id={id}
					className={classnames({
						[classes.innerTableWrapper]: this.props.inner,
					})}
				>
					{headerNode && (
						<div style={{padding: '24px 12px 8px 12px'}}>{headerNode}</div>
					)}

					{!Array.isArray(this.props.resource) && this.renderFilters()}

					<div
						className={classnames({
							[classes.tableWrapper]: !this.props.embedded,
							[classes.embeddedTableWrapper]: this.props.embedded,
						})}
						id={`${id}-embeddedTableWrapper`}
					>
						{this.renderTable()}
					</div>
					{!Array.isArray(this.props.resource) && this.renderPagination()}
				</div>
			</div>
		);
	}
}

EmbeddedList.propTypes = {
	id: PropTypes.string,
	embedded: PropTypes.bool,
	isPageChooser: PropTypes.bool,
	isPerPageControl: PropTypes.bool,
	title: PropTypes.string,
	resource: PropTypes.any,
	columns: PropTypes.any,
	sort: PropTypes.any,
	staticFilters: PropTypes.any,
	dynamicFilters: PropTypes.any,
	bulkActions: PropTypes.any,
	actions: PropTypes.any,
	rowClicked: PropTypes.func,
	selectedIds: PropTypes.any,
	selectionToggled: PropTypes.func,
	stripped: PropTypes.bool,
	inner: PropTypes.bool,
	customHeaderStyle: PropTypes.any,
	showSpinnerOnFilter: PropTypes.bool,
	headerNode: PropTypes.node, // to render elements on top of Table
	selectedFilterNode: PropTypes.node, // to render selected filters inside renderFilters of Table
	maxHeight: PropTypes.string, // for tables of type embedded the defulat is 300px
	tableDescription: PropTypes.node
};

const mapStateToProps = state => {
	return {
		version: get(state, 'admin.ui.viewVersion'),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	  // dispatching plain actions
	  fetchStart: () => dispatch(fetchStart()),
	  fetchEnd: () => dispatch(fetchEnd())
	}
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withDataProvider,
	translate,
	withStyles(myStyles)
);

export default enhance(EmbeddedList);
