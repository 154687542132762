import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const CustomToolTip= (props) => {
return (
    <div>
        <Tooltip {...props}>
        </Tooltip>
    </div>

);
};

export default CustomToolTip;
