import PropTypes from 'prop-types';
import React from 'react';

const BooleanValue = ({source, record = {}, altRecord, color = 'inherit'}) => {
	if (altRecord) {
		record = altRecord;
	}

	if (record[source] === true) {
		return <div style={{color: color}}>Yes</div>;
	} else if (record[source] === false) {
		return <div style={{color: color}}>No</div>;
	} else {
		return <div>---</div>;
	}
};
BooleanValue.propTypes = {
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
	color: PropTypes.string,
};

export default BooleanValue;
