import {useState} from 'react';
import LabDetail from 'sections/Training/Show/Labs/LabDetail';
import LabList from 'sections/Training/Show/Labs/LabList';

const LabsTab = props => {
	const [selectedLabRecord, setSelectedLabRecord] = useState(null);

	const labSelected = record => {
		setSelectedLabRecord(record);
	};

	const backLabAction = () => {
		setSelectedLabRecord(null);
	};

	if (!selectedLabRecord) return <LabList {...props} labSelected={labSelected}/>;

	return <LabDetail {...props} selectedLabRecord={selectedLabRecord} backLabAction={backLabAction}/>;
};

export default LabsTab;
