import React from 'react';
import {useTranslate} from 'react-admin';
import MUIButton from '@material-ui/core/Button';
import {
	Dialog,
	DialogContent,
	createStyles,
	withStyles,
} from '@material-ui/core';

const styles = theme =>
	createStyles({
		paper: {
			top: '30%',
			minWidth: '400px',
		},
		dialogContent: {
			padding: '16px',
			display: 'flex',
			flexDirection: 'column',
		},
		dialogText: {
			fontFamily: 'Archivo Bold',
			fontSize: '16px',
			paddingBottom: '20px',
		},
	});

const SoftCapPopUp = props => {
	const {
		isPopoverOpen,
		classes,
		numToCreate,
		confirmCreateAccounts,
		cancelCreateAccounts,
	} = props;

	const translate = useTranslate();

	return (
		<Dialog
			onClick={e => {
				e.stopPropagation();
			}}
			aria-labelledby='customized-dialog-title'
			open={isPopoverOpen}
			maxWidth={'md'}
			classes={{
				paper: classes.paper,
			}}
		>
			<DialogContent className={classes.dialogContent}>
				<div className={classes.dialogText}>
					{translate(`Creating ${numToCreate} users. Are you sure?`)}
				</div>

				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<MUIButton
						variant='contained'
						color='secondary'
						style={{height: '32px', width: '100px'}}
						onClick={() => cancelCreateAccounts()}
					>
						{translate('Cancel')}
					</MUIButton>
					<MUIButton
						variant='contained'
						color='primary'
						style={{height: '32px', width: '100px'}}
						onClick={() => confirmCreateAccounts()}
					>
						{translate('Create')}
					</MUIButton>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default withStyles(styles)(SoftCapPopUp);
