import {LinearProgress} from 'react-admin';
import React from 'react';
import get from 'lodash/get';

const InfoTextDeprecated = ({source, record = {}, altRecord}) => {
	const renderText = value => {
		return (
			<div
				style={{
					whiteSpace: 'wrap',
					flex: '1',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					color: '#8D8D8D',
					fontStyle: 'italic',
				}}
			>
				{value}
			</div>
		);
	};

	if (altRecord) {
		record = altRecord;
	}

	if (!record) {
		return <LinearProgress />;
	}
	if (!get(record, source)) {
		return <div>---</div>;
	}

	return renderText(get(record, source));
};

export default InfoTextDeprecated;
