import 'date-fns';
import React, {useEffect, useState} from 'react';
import MUIButton from '@material-ui/core/Button';
import {useTheme} from '@material-ui/core/styles';
import {CREATE, useDataProvider} from 'react-admin';
import get from 'lodash/get';
import {loadObject} from '../../../utils/Util';
import {InfoTextDeprecated} from '../../../components';
import Grid from '../../../components/layout/Grid';
import {CustomDialog} from 'components';
import DatePicker from 'components/DatePicker';
import LoadingList from "components/loading/LoadingList";
import moment from "moment";

const SendWelcomeEmail = ({isOpen, setIsOpen, teamId}) => {
	const theme = useTheme();

	const [state, setState] = useState({
		disabled: false,
		welcomeEmailTime: Date.now(),
		welcomeEmail: true,
		sendWelcomeType: 'sendNow',
		isClosing: false,
		verifyUsers: null,
		verifyMessage: '',
		timeValue: new Date(Date.now()),
		dateValue: moment(new Date(new Date(Date.now()).setSeconds(0))).add(1, 'm').toDate()
	});

	const [disabled, setDisabled] = useState(false);

	const dataProvider = useDataProvider();

	const project = loadObject('project');
	const authType = get(project, 'config.authType');

	useEffect(() => {
		const verifyUsers = () => {
			//userIds.concat(response.data.newUsers.map(({ id }) => id))

			dataProvider(CREATE, 'sendWelcomeMailTeam', {
				data: {
					teamId: teamId,
					authType: authType,
					welcomeEmailTime:
						state.sendWelcomeType === 'schedule'
							? Date.parse(state.welcomeEmailTime.toUTCString())
							: '',
					sendEmails: false,
				},
			})
				.then(response => {
					//	handleClose();

					const users = response.data.map(({id}) => id);
					setState(prevState => ({
						...prevState,
						verifyUsers: users,
						verifyMessage: (
							<div>
								A welcome message will be sent to{' '}
								<span style={{color: theme.palette.primary.main}}>
									{users.length}
								</span>{' '}
								Users.
							</div>
						),
					}));
				})
				.catch(error => {});
		};

		verifyUsers();
	}, []);

	const sendWelcome = handleClose => {
		//TODO Move handle close to response, and add loading animation on confirm button
		handleClose();
		const timeString = state.timeValue.toString();
		const timeSliced = timeString.slice(15, timeString.length);
		const dateSliced = state.dateValue.toString().slice(0, 15);
		const value = new Date(dateSliced + timeSliced);

		dataProvider(CREATE, 'sendWelcomeMailTeam', {
			data: {
				teamId: teamId,
				authType: authType,
				welcomeEmailTime:
					state.sendWelcomeType === 'schedule'
						? Date.parse(value.toUTCString())
						: '',
				sendEmails: true,
			},
		})
			.then(response => {
				//	handleClose();
			})
			.catch(error => {});
	};

	const isValidDate = (value) => {
		let isValid = true;
		if (Object.prototype.toString.call(value) === "[object Date]") {
			if (isNaN(value)) {
				isValid = false;
			} else {
				isValid = true;
			}
		} else {
			isValid = false;
		}
		return isValid;
	}

	const dialog = () => {
		return (
			<CustomDialog
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={'Send Welcome Email'}
				width={'475px'}
				actions={[
					{
						render: handleClose => {
							return (
								<MUIButton
									color='secondary'
									variant='contained'
									onClick={handleClose}
								>
									Cancel
								</MUIButton>
							);
						},
					},
					{
						render: handleClose => {
							return (
								<MUIButton
									color='primary'
									variant='contained'
									onClick={() => sendWelcome(handleClose)}
									disabled={state.sendWelcomeType === 'schedule' && (!isValidDate(state.dateValue) || !isValidDate(state.timeValue) || disabled)}
								>
									Confirm
								</MUIButton>
							);
						},
					},
				]}
				perRow={1}
				details={[
					{
						render: () => state.verifyMessage,
					},
					{
						render: () => (
							<InfoTextDeprecated
								record={{
									info:
										'Welcome emails will not be sent to members that have already completed enrollment nor to those that have already logged in.',
								}}
								source={'info'}
							/>
						),
					},
					{
						label: 'Schedule Email',
						render: () => <DatePicker state={state} setState={setState} />,
					},
				]}
			>
				{state.verifyUsers && (
					<Grid perRow={1}>
						{state.verifyMessage}
						<InfoTextDeprecated
							record={{
								info:
									'Welcome emails will not be sent to members that have already completed enrollment nor to those that have already logged in.',
							}}
							source={'info'}
						/>
						<DatePicker
							sendWelcomeType={state.sendWelcomeType}
							onDateChange={date => setState({...state, dateValue: date})}
							onTimeChange={date => setState({...state, timeValue: date})}
							handleWelcomeTypeChange={type =>
								setState({...state, sendWelcomeType: type})
							}
							dateValue={state.dateValue}
							timeValue={state.timeValue}
							onDateTimeValidityChange={(val) => {
								setDisabled(val);
							}}
						/>
					</Grid>
				)}
				{!state.verifyUsers && <LoadingList/> }
			</CustomDialog>
		);
	};

	return <div>{dialog()}</div>;
};

export default SendWelcomeEmail;
