import AddTrainingButton from '../../AddTrainingButton';
import {EmbeddedList, TimeSpanField} from 'components';
import {Avatar, MenuItem} from '@material-ui/core';
import {NumberField, useTranslate} from 'react-admin';
import { refreshView } from 'ra-core';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import PercentField from 'components/PercentField';
import React, {useState} from 'react';
import RemoveTrainingsFromTeamButton from '../../RemoveTrainingsFromTeamButton';
import trainingModuleIcon from 'assets/MiniCardIcons/TrainingModule.svg';
import TrainingExpiry from '../../../../components/TrainingExpiry';

import {Button} from '@material-ui/core';
import {vectorMapSystemEnabled, vectorMapUserEnabled} from 'utils/vectorMap';
import VectorMap from 'components/VectorMap';
import SkillFilter from '../../../../components/SkillFilter';

const TrainingList = props => {
	const {trainingSelected, skillsSelected, selectedSkills} = props;

	const translate = useTranslate();

	const [useVectorMap, setUseVectorMap] = useState(vectorMapUserEnabled());

	if (vectorMapSystemEnabled('team', 'catalog-map') && useVectorMap) {
		return (
			<VectorMap
				type={'catalog-map'}
				contextData={{
					teamId: props.id,
					userId: undefined,
					trainingId: undefined,
					lessonId: undefined,
				}}
				onTableViewClicked={() => {
					vectorMapUserEnabled(false);
					setUseVectorMap(false);
				}}
				onViewRecordDetails={record => {
					if (record && record.id) trainingSelected(record.id, null, record);
				}}
			/>
		);
	}

	return (
		<EmbeddedList
			embedded={false}
			subTitle
			showSpinnerOnFilter={true}
			title={translate('Availability')}
			resource='listtrainingforteam'
			columns={[
				{
					id: 'name',
					name: translate('Module Name'),
					sortable: true,
					width: '200px',
					render: record => (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								columnGap: '10px',
								alignItems: 'center',
								padding: '10px 0px',
								whiteSpace: 'nowrap',
								flex: ' 1',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								minWidth: '0',
							}}
						>
							<Avatar
								variant={'square'}
								alt='Remy Sharp'
								src={
									record.trainingDetail.appIcons &&
									record.trainingDetail.appIcons.length > 0
										? record.trainingDetail.appIcons[0]
										: trainingModuleIcon
								}
							/>
							<span
								title={record.name}
								style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
							>
								{record.name}
							</span>
						</div>
					),
				},
				{
					id: 'seriesName',
					name: translate('Series Name'),
					sortable: true,
					width: '135px',
				},
				{
					id: 'lessons',
					name: translate('Lessons'),
					sortable: true,
					align: 'center',
					width: '80px',
					render: record => <NumberField record={record} source='lessons' />,
					isInverted: true,
				},
				{
					id: 'attempts',
					name: translate('Attempts'),
					sortable: true,
					align: 'center',
					width: '80px',
					render: record => <NumberField record={record} source='attempts' />,
					isInverted: true,
				},
				{
					id: 'totalTimeSec',
					name: translate('Time Spent'),
					sortable: true,
					width: '105px',
					align: 'center',
					render: record => (
						<TimeSpanField record={record} source='totalTimeSec' />
					),
				},
				{
					id: 'completionRate',
					name: translate('Completion'),
					sortable: true,
					align: 'center',
					width: '90px',
					render: record => (
						<PercentField record={record} source='completionRate' />
					),
					isInverted: true,
				},
				{
					id: 'avgScore',
					name: translate('avgScore'),
					sortable: true,
					align: 'center',
					width: '90px',
					render: record => <PercentField record={record} source='avgScore' />,
					isInverted: true,
				},
				{
					id: 'expiry',
					name: 'Expiration',
					sortable: true,
					width: '85px',
					align: 'center',
					render: record => <TrainingExpiry record={record} source='expiry' />,
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[{id: 'teamId', value: props.id}]}
			dynamicFilters={[
				{
					id: 'accessibility',
					name: translate('Accessibility'),
					type: 'exact',
					choices: [
						{value: true, label: translate('Assigned')},
						{value: false, label: translate('Unassigned')},
					],
					value: true,
				},
				{
					id: 'namedSkills',
					value:
						selectedSkills.length > 0
							? {$in: selectedSkills.map(skill => skill.name)}
							: undefined,
					render: () => (
						<SkillFilter
							onChange={skills => {
								skillsSelected(skills);
							}}
							preSelectedSkills={
								selectedSkills.length ? selectedSkills : undefined
							}
							skillListContextData={{teamId: props.id}}
						/>
					),
				},
			]}
			bulkActions={[
				{
					id: 'addTraining',
					render: selectedIds => (
						<AddTrainingButton {...props} label='Add Module'/>
					),
				},
				vectorMapSystemEnabled('team', 'catalog-map')
					? {
							id: 'switchToVectorMap',
							render: () => (
								<Button
									variant={'outlined'}
									color={'primary'}
									onClick={() => {
										vectorMapUserEnabled(true);
										setUseVectorMap(true);
									}}
								>
									{translate('Map View')}
								</Button>
							),
					  }
					: null,
			]}
			actions={[
				{
					render: record => (
						<MenuItem key={'View Module Details'}>
							<div onClick={() => trainingSelected(record.id, null, record)}>
								{translate('View Module Details')}
							</div>
						</MenuItem>
					),
				},
				{
					render: record =>
						record.accessibility.assigned.value ? (
							<RemoveTrainingsFromTeamButton
							    {...props}
								key={'remove from team'}
								to={`teams/${record.id}/remove`}
								variant='link'
								label='Remove Module'
								record={record}
								parentRecord={props.record}
								basePath={props.basePath}
								location={props.location}
							/>
						) : (
							<div key={'remove from team'}></div>
						),
				},
			]}
			rowClicked={record => trainingSelected(record.id, null, record)}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(TrainingList);
