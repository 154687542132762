import React from 'react';
import {useTheme} from '@material-ui/core/styles';

import SkillIcon from 'assets/skillIcons';

const SkillPoint = props => {

	const {data} = props;

	// console.log(data);

	const theme = useTheme();

	return (
		<div
			style={{
				fontStretch: 'bold',
				height: '52px',
				width: '33%',
				maxWidth: '33%',
				paddingRight: '20px',
				paddingBottom: '20px',
				marginBottom: '32px',
			}}
		>
			<div
				style={{
					backgroundColor: theme.palette.background.default,
					padding: '10px',
				}}
			>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '52px auto',
						gridTemplateRows: '1',
						gridGap: '8px',
						rowGap: '0px',
						gridTemplateAreas: `'logo title'
                                        'logo text'`,
					}}
				>
					<span
						style={{
							gridArea: 'logo',
							marginTop: 'auto',
							marginBottom: 'auto',
							marginLeft: '8px',
							width: '35px',
							height: '35px',
						}}
					>
						<SkillIcon record={data} width={40} height={40}/>
					</span>

					<div
						style={{
							fontSize: '14px',
							gridArea: 'title',
							paddingLeft: '15px',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							fontFamily: 'Montserrat Medium'
						}}
						title={data.displayName}
					>
						{data.displayName}{' '}
					</div>
					<div
						style={{
							fontSize: '14px',
							paddingLeft: '15px',
							gridArea: 'text',
						}}
					>
						<span style={{color: theme.palette.primary.main}}>{data.value + ' ' + data.valueLabel}</span>{' '}
						<span >{' ' + data.subLabel}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SkillPoint;
