import List from './List';
import Show from './Show';
import {Teams} from '../../assets/MenuIcon';

const teamShow = {
	name: 'teams',
	show: Show,
	list: List,
	icon: Teams,
};

export default teamShow;
