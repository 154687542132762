import React, {useEffect, useState} from 'react';

import {Tab} from 'react-admin';
import TrainingDetail from './TrainingDetail';
import TrainingList from './TrainingList';
import UnassignedTrainingDetail from 'components/UnassignedTrainingDetail.jsx/index.jsx';
import {loadObject} from 'utils/Util';
import {ScreenTitle} from 'components';

const UserTraining = props => {
	const {userData, teamBackAction, teamData} = props;

	const [selectedLessonRecord, setSelectedLessonRecord] = useState(null);

	const [selectedLab, setSelectedLab] = useState(null);

	const [selectedStep, setSelectedStep] = useState(null);

	const [selectedTrainingRecord, setSelectedTrainingRecord] = useState(
		props.selectedRecord ? props.selectedRecord : null
	);

	const [selectedSkills, setSelectedSkills] = useState([]);

	const trainingSelected = (id, basePath, record) => {
		setSelectedTrainingRecord(record);
	};

	const lessonSelected = (id, basePath, record) => {
		setSelectedLessonRecord(record);
	};

	const labSelected = (id, basePath, record) => {
		setSelectedLab(record);
	};

	const stepSelected = (id, basePath, record) => {
		setSelectedStep(record);
	};

	const cancelTrainingSelection = () => {
		setSelectedTrainingRecord(null);
		setSelectedLessonRecord(null);
		setSelectedStep(null);
		setSelectedLab(null);
	};

	const cancelLessonSelection = () => {
		setSelectedLessonRecord(null);
	};

	const cancelLabSelection = () => {
		setSelectedStep(null);
		setSelectedLab(null);
	};

	const cancelStepSelection = () => {
		setSelectedStep(null);
	};

	const skillsSelected = skills => {
		setSelectedSkills(skills);
	};

	useEffect(() => {
		if (props.selectedRecord) {
			setSelectedTrainingRecord(props.selectedRecord);
			window.localStorage.removeItem('activeTrainingInfo');
		}
	}, [props.selectedRecord]);

	return (
		<Tab path='training' label='module'>
			{!selectedTrainingRecord && (
				<React.Fragment>
					{teamData && (
						<ScreenTitle
							title={
								(userData.name &&
									userData.name.trim().length > 0 &&
									userData.name) ||
								userData.userId
							}
							refresh={false}
							subTitle={true}
							coloredCrumb={true}
							backActionCrumbs={[teamBackAction]}
							titleCrumbs={[teamData.name]}
						/>
					)}
					<TrainingList
						{...props}
						trainingSelected={trainingSelected}
						skillsSelected={skillsSelected}
						selectedSkills={selectedSkills}
					/>
				</React.Fragment>
			)}
			{selectedTrainingRecord &&
				(selectedTrainingRecord.id &&
				selectedTrainingRecord.id.trim().length > 0 ? (
					<TrainingDetail
						{...props}
						backTrainingAction={cancelTrainingSelection}
						backLessonAction={cancelLessonSelection}
						backLabAction={cancelLabSelection}
						backStepAction={cancelStepSelection}
						trainingSelected={trainingSelected}
						lessonSelected={lessonSelected}
						labSelected={labSelected}
						stepSelected={stepSelected}
						skillsSelected={skillsSelected}
						selectedTrainingRecord={selectedTrainingRecord}
						selectedLessonRecord={selectedLessonRecord}
						selectedLab={selectedLab}
						selectedStep={selectedStep}
						selectedSkills={selectedSkills}
						userData={userData}
					/>
				) : (
					<UnassignedTrainingDetail
						backActions={
							teamBackAction
								? [teamBackAction, cancelTrainingSelection]
								: [cancelTrainingSelection]
						}
						titleBreadCrumbs={
							teamData
								? [
										teamData.name,
										(userData.name &&
											userData.name.trim().length > 0 &&
											userData.name) ||
											userData.userId,
								  ]
								: [
										(userData.name &&
											userData.name.trim().length > 0 &&
											userData.name) ||
											userData.userId,
								  ]
						}
						selectedTrainingRecord={selectedTrainingRecord}
						isExpandMore={
							loadObject('expandUserTrainingcatalogue') === null
								? false
								: loadObject('expandUserTrainingcatalogue')
						}
						toggleKey={'expandUserTrainingcatalogue'}
					/>
				))}
		</Tab>
	);
};

export default UserTraining;
