import React, {Component} from 'react';
import {createStyles, withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {translate} from 'react-admin';

import StatsTile from './StatsTile';
import getStatsIcon from 'components/StatsIcons';

const myStyles = () =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
		},
		row: {
			gridTemplateColumns: 'repeat(auto-fill,minmax(160px, 1fr))',
			display: 'grid',
			gridGap: '10px',
		},
	});

class StatsTilePanel extends Component {
	renderStatsTileRow(stats, startingIdx, numPerRow) {
		const {classes} = this.props;

		if (stats && stats.length > 0 && startingIdx < stats.length) {
			const endingIdx =
				startingIdx + numPerRow < stats.length
					? startingIdx + numPerRow
					: stats.length;

			const rowStats = stats.slice(startingIdx, endingIdx);

			const tiles = [];

			rowStats.forEach(stat => {
				if (stat) {
					tiles.push(
						<StatsTile
							key={stat.displayName}
							title={stat.displayName}
							value={stat.value}
							type={stat.type}
							icon={getStatsIcon(stat.displayName)}
							subLabel={stat.subLabel}
							subValue={stat.subValue}
							valueColor={stat.valueColor}
						/>
					);
				}
			});

			return (
				<div key={stats.length / numPerRow} className={classes.row}>
					{tiles}
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		const {classes, numPerRow, data, ids} = this.props;

		let stats = {};

		if (this.props && this.props.stats) {
			var statsProp = this.props.stats;
			stats = Object.keys(statsProp).map(function(_) {
				return statsProp[_];
			});
		} else if (this.props.record && this.props.record.array) {
			stats = this.props.record.array.data;
		} else if (data) {
			stats = ids.map(id => data[id]);
		}

		if (stats && stats.length > 0) {
			const tileRows = [];

			if (numPerRow != null && numPerRow > 0) {
				let startIdx = 0;

				while (startIdx * numPerRow < stats.length) {
					tileRows.push(this.renderStatsTileRow(stats, startIdx, numPerRow));
					startIdx = (startIdx + 1) * numPerRow;
				}
			} else {
				tileRows.push(this.renderStatsTileRow(stats, 0, stats.length));
			}

			return <div className={classes.root}>{tileRows}</div>;
		} else {
			return <div></div>;
		}
	}
}

StatsTilePanel.propTypes = {
	stats: PropTypes.any,
	data: PropTypes.any,
	numPerRow: PropTypes.number,
};

const enhance = compose(translate, withStyles(myStyles));

export default enhance(StatsTilePanel);
