import React from 'react';

const Divider = props => {
	const {title} = props;

	return (
		<div
			style={{
				width: '100%',
			}}
		>
			<div
				style={{
					borderBottom: '2px rgb(15, 67, 88) solid',
					marginTop: '16px',
					marginBottom: '16px',
				}}
			></div>

			{title && (
				<div
					style={{fontSize: '20px', marginTop: '16px', marginBottom: '16px'}}
				>
					{title}
				</div>
			)}
		</div>
	);
};

export default Divider;
