import { DataOptions } from "components/VectorMap/OptionsBlade";
import { addChildFilterOption, getFilterOption } from "../options";
import { sortByString, capitalizeString, extractValue } from "../helpers";
import { Availability, humanizeAvailability, humanizeProgressStatus, isGroupingByAvailability, isGroupingByProgressStatus, isGroupingByScore, isGroupingByTimeSpent, ProgressStatus } from "./helpers";
import { VectorMapContext } from "../model";

export const generateOptions = (context: VectorMapContext, data: Array<any>): DataOptions => {
    const options: DataOptions = { filter: [], group: null, sort: null, heatmap: null, detail: [], other: null };

    // Collect the distinct training series.
    const trainingSeries: Array<string> = extractValue(data, 'trainingSeries');
    trainingSeries.sort(sortByString(null));

    // Collect the distinct training types.
    const trainingCategories: Array<string> = extractValue(data, 'trainingType');
    trainingCategories.sort(sortByString(null));

    // Populate the "filter" options.
    addChildFilterOption(options.filter, { id: 'training-info', label: 'Training Info' });
    addChildFilterOption(getFilterOption(options.filter, 'training-info'), { id: 'series', label: 'Series' });
    trainingSeries.forEach(series => {
        addChildFilterOption(getFilterOption(options.filter, 'training-info.series'), { id: series, label: capitalizeString(series), value: true });
    });
    addChildFilterOption(getFilterOption(options.filter, 'training-info'), { id: 'category', label: 'Category' });
    trainingCategories.forEach(category => {
        addChildFilterOption(getFilterOption(options.filter, 'training-info.category'), { id: category, label: capitalizeString(category), value: true });
    });
    addChildFilterOption(getFilterOption(options.filter, 'training-info'), { id: 'availability', label: 'Availability' });
    addChildFilterOption(getFilterOption(options.filter, 'training-info.availability'), { id: Availability.FUTURE, label: humanizeAvailability(Availability.FUTURE), value: true });
    addChildFilterOption(getFilterOption(options.filter, 'training-info.availability'), { id: Availability.AVAILABLE, label: humanizeAvailability(Availability.AVAILABLE), value: true });
    addChildFilterOption(getFilterOption(options.filter, 'training-info.availability'), { id: Availability.LICENSED, label: humanizeAvailability(Availability.LICENSED), value: true });
    addChildFilterOption(getFilterOption(options.filter, 'training-info.availability'), { id: Availability.ASSIGNED, label: humanizeAvailability(Availability.ASSIGNED), value: true });
    if (['user'].includes(context.context)) {
        addChildFilterOption(options.filter, { id: 'user-info', label: 'User Info' });
        addChildFilterOption(getFilterOption(options.filter, 'user-info'), { id: 'status', label: 'Progress Status' });
        addChildFilterOption(getFilterOption(options.filter, 'user-info.status'), { id: ProgressStatus.NOT_STARTED, label: humanizeProgressStatus(ProgressStatus.NOT_STARTED), value: true });
        addChildFilterOption(getFilterOption(options.filter, 'user-info.status'), { id: ProgressStatus.INCOMPLETE, label: humanizeProgressStatus(ProgressStatus.INCOMPLETE), value: true });
        addChildFilterOption(getFilterOption(options.filter, 'user-info.status'), { id: ProgressStatus.COMPLETED, label: humanizeProgressStatus(ProgressStatus.COMPLETED), value: true });
        addChildFilterOption(getFilterOption(options.filter, 'user-info.status'), { id: ProgressStatus.FAILED, label: humanizeProgressStatus(ProgressStatus.FAILED), value: true });
        addChildFilterOption(getFilterOption(options.filter, 'user-info.status'), { id: ProgressStatus.PASSED, label: humanizeProgressStatus(ProgressStatus.PASSED), value: true });
        addChildFilterOption(getFilterOption(options.filter, 'user-info.status'), { id: ProgressStatus.ACED, label: humanizeProgressStatus(ProgressStatus.ACED), value: true });
    }

    // Populate the "group" options.
    options.group = { id: 'root', value: 'series', options: [] };
    options.group.options.push({ value: '', label: 'All' });
    options.group.options.push({ value: 'series', label: 'Series' });
    options.group.options.push({ value: 'category', label: 'Category' });
    options.group.options.push({ value: 'availability', label: 'Availability' });
    if (['user'].includes(context.context)) {
        options.group.options.push({ value: 'status', label: 'Progress Status' });
    }
    if (['user'].includes(context.context)) {
        options.group.options.push({ value: 'score', label: 'Score' });
    } else {
        options.group.options.push({ value: 'score', label: 'Avg Score' });
    }
    if (['user'].includes(context.context)) {
        options.group.options.push({ value: 'time-spent', label: 'Time Spent' });
    } else {
        options.group.options.push({ value: 'time-spent', label: 'Avg Time Spent' });
    }

    // Populate the "sort" options.
    options.sort = { id: 'root', value: 'name-asc', options: [] };
    options.sort.options.push({ value: 'name-asc', label: 'Training Name A>Z' });
    options.sort.options.push({ value: 'name-desc', label: 'Training Name Z>A' });
    options.sort.options.push({ value: 'availability-asc', label: 'Availability LOW>HIGH', hidden: (options: DataOptions) => isGroupingByAvailability(options) });
    options.sort.options.push({ value: 'availability-desc', label: 'Availability HIGH>LOW', hidden: (options: DataOptions) => isGroupingByAvailability(options) });
    if (['user'].includes(context.context)) {
        options.sort.options.push({ value: 'status-asc', label: 'Progress Status LOW>HIGH', hidden: (options: DataOptions) => isGroupingByProgressStatus(options) });
        options.sort.options.push({ value: 'status-desc', label: 'Progress Status HIGH>LOW', hidden: (options: DataOptions) => isGroupingByProgressStatus(options) });
    }
    if (['user'].includes(context.context)) {
        options.sort.options.push({ value: 'score-asc', label: 'Score LOW>HIGH', hidden: (options: DataOptions) => isGroupingByScore(options) });
        options.sort.options.push({ value: 'score-desc', label: 'Score HIGH>LOW', hidden: (options: DataOptions) => isGroupingByScore(options) });
    } else {
        options.sort.options.push({ value: 'score-asc', label: 'Avg Score LOW>HIGH', hidden: (options: DataOptions) => isGroupingByScore(options) });
        options.sort.options.push({ value: 'score-desc', label: 'Avg Score HIGH>LOW', hidden: (options: DataOptions) => isGroupingByScore(options) });
    }
    if (['user'].includes(context.context)) {
        options.sort.options.push({ value: 'time-spent-asc', label: 'Time Spent LOW>HIGH', hidden: (options: DataOptions) => isGroupingByTimeSpent(options) });
        options.sort.options.push({ value: 'time-spent-desc', label: 'Time Spent HIGH>LOW', hidden: (options: DataOptions) => isGroupingByTimeSpent(options) });
    } else {
        options.sort.options.push({ value: 'time-spent-asc', label: 'Avg Time Spent LOW>HIGH', hidden: (options: DataOptions) => isGroupingByTimeSpent(options) });
        options.sort.options.push({ value: 'time-spent-desc', label: 'Avg Time Spent HIGH>LOW', hidden: (options: DataOptions) => isGroupingByTimeSpent(options) });
    }

    // Populate the "heatmap" options.
    options.heatmap = { id: 'root', value: '', options: [] };
    options.heatmap.options.push({ value: '', label: 'None' });
    options.heatmap.options.push({ value: 'lessons', label: '# Lessons' });
    options.heatmap.options.push({ value: 'total-skill-points', label: 'Total Skill Points' });
    options.heatmap.options.push({ value: 'completion-rate', label: 'Completion Rate' });
    if (['user', 'team'].includes(context.context)) {
        options.heatmap.options.push({ value: 'attempts', label: '# Attempts' });
    }
    if (['user'].includes(context.context)) {
        options.heatmap.options.push({ value: 'score', label: 'Score' });
    } else {
        options.heatmap.options.push({ value: 'score', label: 'Avg Score' });
    }
    if (['user'].includes(context.context)) {
        options.heatmap.options.push({ value: 'time-spent', label: 'Time Spent' });
    } else {
        options.heatmap.options.push({ value: 'time-spent', label: 'Avg Time Spent' });
    }
    if (['user'].includes(context.context)) {
        options.heatmap.options.push({ value: 'training-complete', label: 'Training Complete' });
        options.heatmap.options.push({ value: 'training-passed', label: 'Training Passed' });
    }

    // Populate the "detail" options.
    options.detail.push({ id: 'training-info', label: 'Training Info', children: [] });
    options.detail[options.detail.length - 1].children.push({ id: 'category', label: 'Cateogry', value: true });
    options.detail[options.detail.length - 1].children.push({ id: 'availability', label: 'Availability', value: true });
    options.detail[options.detail.length - 1].children.push({ id: 'lessons', label: '# Lessons', value: true });
    options.detail.push({ id: 'user-info', label: 'User Info', children: [] });
    if (['org'].includes(context.context)) {
        options.detail[options.detail.length - 1].children.push({ id: 'users', label: '# Users', value: true });
    }
    if (['user', 'team'].includes(context.context)) {
        options.detail[options.detail.length - 1].children.push({ id: 'attempts', label: '# Attempts', value: true });
    }
    if (['team', 'org'].includes(context.context)) {
        options.detail[options.detail.length - 1].children.push({ id: 'completion-rate', label: 'Completion Rate', value: true });
    }
    if (['user'].includes(context.context)) {
        options.detail[options.detail.length - 1].children.push({ id: 'status', label: 'Progress Status', value: true });
    }
    if (['user'].includes(context.context)) {
        options.detail[options.detail.length - 1].children.push({ id: 'score', label: 'Score', value: true });
    } else {
        options.detail[options.detail.length - 1].children.push({ id: 'score', label: 'Avg Score', value: true });
    }
    if (['user'].includes(context.context)) {
        options.detail[options.detail.length - 1].children.push({ id: 'time-spent', label: 'Time Spent', value: true });
    } else {
        options.detail[options.detail.length - 1].children.push({ id: 'time-spent', label: 'Avg Time Spent', value: true });
    }

    // Populate the "other" options.
    options.other = {
        showOtherOptions: true,
        hideEmptyGroups: false,
        groupsPerRow: data.length > 0 && data.length < 4 ? data.length : 4,
        groupsPerRowOptions: [1, 2, 3, 4, 5, 6],
        itemsPerRow: 4,
        itemsPerRowOptions: [1, 2, 3, 4, 5, 6]
    }

    console.log("Generated Options", options);

    return options;
}

export const optionsChanged = (_context: VectorMapContext, prevOptions: DataOptions, newOptions: DataOptions): DataOptions => {
    console.log("Handling Change To Options...");

    // When an option changes other options may become disabled/hidden. In this case we should update any applicable options to proper
    // default values such that the option (drop downs specifically) continue to have a valid value selected.

    // Get the new group option value.
    const newGroupOption = newOptions && newOptions.group ? newOptions.group : null;
    const newGroupOptionValue = newGroupOption && newGroupOption.value != null ? newGroupOption.value : '';

    // Get the prev sort option value.
    const prevSortOption = prevOptions && prevOptions.sort ? prevOptions.sort : null;
    const prevSortOptionValue = prevSortOption && prevSortOption.value != null ? prevSortOption.value : '';

    // When the group option value changes, this can result in certain sort option values becoming hidden or unavailable.
    // In these cases we automatically change the sort option value to some other reasonable value.
    switch (newGroupOptionValue) {
        case 'availability':
            if (![
                'name-asc', 'name-desc',
                'status',
                'score-asc', 'score-desc',
                'time-spent-asc', 'time-spent-desc',
            ].includes(prevSortOptionValue)) newOptions.sort.value = 'name-asc';

            break;
        case 'status':
            if (![
                'name-asc', 'name-desc',
                'availability',
                'score-asc', 'score-desc',
                'time-spent-asc', 'time-spent-desc',
            ].includes(prevSortOptionValue)) newOptions.sort.value = 'name-asc';

            break;
        case 'score':
            if (![
                'name-asc', 'name-desc',
                'availability',
                'status',
                'time-spent-asc', 'time-spent-desc',
            ].includes(prevSortOptionValue)) newOptions.sort.value = 'name-asc';

            break;
        case 'time-spent':
            if (![
                'name-asc', 'name-desc',
                'availability',
                'status',
                'score-asc', 'score-desc',
            ].includes(prevSortOptionValue)) newOptions.sort.value = 'name-asc';

            break;
        default:
        // Do nothing.
    }

    return newOptions;
}
