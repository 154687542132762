import { DataOptions } from "components/VectorMap/OptionsBlade";
import { addChildFilterOption, addChildGroupOption, addChildHeatmapOption, getFilterOption, getGroupOption, getHeatmapOption } from "../options";
import { extractRawValue, sortByString, capitalizeString, extractValue } from "../helpers";
import { isGroupingByCategory, isGroupingByTraining, isGroupingByLesson } from "./helpers";
import { VectorMapContext, VectorMapContextData } from "../model";

export const generateOptions = (context: VectorMapContext, data: Array<any>, roles?: Array<string> | null, contextData?: VectorMapContextData | null): DataOptions => {
    const options: DataOptions = { filter: [], group: null, sort: null, heatmap: null, detail: null, other: null };

    // TESTING-ONLY: Logging the various related skills (via linked roles, associated training and associated lessons).
    if (roles && roles.length > 0) {
        const roleSkills = data.filter(item => {
            return item.associatedRoles.find(role => roles.includes(role));
        });
        console.log("Role Related Skills", roleSkills);
    }
    if (contextData && contextData.trainingId) {
        const trainingSkills = data.filter(item => {
            return item.associatedTraining.find(training => training.id === contextData.trainingId);
        });
        console.log("Training Related Skills", trainingSkills);
    }
    if (contextData && contextData.lessonId) {
        const lessonSkills = data.filter(item => {
            return item.associatedLessons.find(lesson => lesson.id === contextData.lessonId);
        });
        console.log("Lesson Related Skills", lessonSkills);
    }

    // Collect the distinct associated roles (both the linked roles as well as all other roles).
    const associatedRoles: Array<string> = extractValue(data, 'associatedRoles');
    if (associatedRoles) associatedRoles.sort(sortByString(null));
    const linkedRoles = associatedRoles ? associatedRoles.filter(role => role != null && role.trim() !== '' && roles && roles.includes(role)) : [];
    const otherRoles = associatedRoles ? associatedRoles.filter(role => role != null && role.trim() !== '' && !linkedRoles.includes(role)) : [];

    // Collect the distinct skill types.
    const skillTypes: Array<string> = extractValue(data, 'type');
    skillTypes.sort(sortByString(null));

    // Collect the distinct skill categories for each type.
    const skillCategories: Array<{ type: string, categories: Array<string> }> = [];
    skillTypes.forEach(type => {
        skillCategories.push({ type: type, categories: extractValue(data.filter(item => item.type === type), 'skillGroup') });
        skillCategories[skillCategories.length - 1].categories.sort(sortByString(null));
    });
    skillCategories.sort(sortByString('type'));

    // Collect the distinct associated trainings.
    const associatedTraining: Array<{ id: string, appKeyName: string, name: string, userValue: number, maxValue: number }> | null = extractRawValue(data, 'associatedTraining');
    const distinctAssociatedTraining: Array<{ id: string, name: string }> = [];
    associatedTraining.forEach(training => {
        const existingTraining = distinctAssociatedTraining.find(item => item.id === training.id);
        if (!existingTraining) distinctAssociatedTraining.push({ id: training.id, name: training.name });
    })
    distinctAssociatedTraining.sort(sortByString('name'));

    // Collect the distinct associated lessons.
    const associatedLessons: Array<{ id: string, trainingId: string, sortOrder: number, name: string, userValue: number, maxValue: number }> | null = extractRawValue(data, 'associatedLessons');
    const distinctAssociatedLessons: Array<{ id: string, trainingId: string, sortOrder: number, name: string }> = [];
    associatedLessons.forEach(lesson => {
        const existingLesson = distinctAssociatedLessons.find(item => item.trainingId === lesson.trainingId && item.id === lesson.id);
        if (!existingLesson) distinctAssociatedLessons.push({ id: lesson.id, trainingId: lesson.trainingId, sortOrder: lesson.sortOrder, name: lesson.name });
    })
    distinctAssociatedLessons.sort(sortByString('sortOrder'));

    // Populate the "filter" options.
    addChildFilterOption(options.filter, { id: 'role-info', label: 'Roles' });
    addChildFilterOption(getFilterOption(options.filter, 'role-info'), { id: 'linked-roles', label: 'Linked Roles' });
    if (linkedRoles.length > 0) {
        linkedRoles.forEach(role => {
            addChildFilterOption(getFilterOption(options.filter, 'role-info.linked-roles'), { id: role, label: capitalizeString(role), value: true });
        });
    } else {
        addChildFilterOption(getFilterOption(options.filter, 'role-info.linked-roles'), { id: '__no_job_role_assigned__', label: capitalizeString('No Job Role Assigned'), value: true });
    }
    addChildFilterOption(getFilterOption(options.filter, 'role-info'), { id: 'other-roles', label: 'Other Roles' });
    otherRoles.forEach(role => {
        addChildFilterOption(getFilterOption(options.filter, 'role-info.other-roles'), { id: role, label: capitalizeString(role), value: false });
    });
    addChildFilterOption(options.filter, { id: 'skill-info', label: 'Skills' });
    addChildFilterOption(getFilterOption(options.filter, 'skill-info'), { id: 'related', label: 'Related' });
    addChildFilterOption(getFilterOption(options.filter, 'skill-info.related'), { id: 'related-skills-only', label: 'Show Related Skills Only', value: true });
    addChildFilterOption(getFilterOption(options.filter, 'skill-info'), { id: 'type', label: 'Type' });
    skillTypes.forEach(type => {
        addChildFilterOption(getFilterOption(options.filter, 'skill-info.type'), { id: type, label: capitalizeString(type), value: true });
    });
    skillTypes.forEach(type => {
        addChildFilterOption(getFilterOption(options.filter, 'skill-info'), { id: type + '-categories', label: capitalizeString(type) + ' Categories' });
        const skillCategoriesForType = skillCategories.find(item => item.type === type);
        if (skillCategoriesForType) {
            skillCategoriesForType.categories.forEach(category => {
                addChildFilterOption(getFilterOption(options.filter, 'skill-info.' + type + '-categories'), { id: category, label: capitalizeString(category), value: true });
            });
        }
    });

    // Populate the "group" options.
    const defaultGroupByValue = context && context.subContext ? 'related-training' : 'type';
    options.group = { id: 'root', value: defaultGroupByValue, options: [] };
    options.group.options.push({ value: '', label: 'All' });
    options.group.options.push({ value: 'type', label: 'Type' });
    options.group.options.push({ value: 'category', label: 'Category' });
    options.group.options.push({ value: 'linked-roles', label: 'Linked Roles' });
    addChildGroupOption(options.group, {
        id: 'linked-roles', value: '', options: [
            { value: '', label: 'All Roles' },
            ...linkedRoles.map(role => ({ value: role, label: capitalizeString(role) }))
        ]
    });
    options.group.options.push({ value: 'other-roles', label: 'Other Roles' });
    addChildGroupOption(options.group, {
        id: 'other-roles', value: '', options: [
            { value: '', label: 'All Roles' },
            ...otherRoles.map(role => ({ value: role, label: capitalizeString(role) }))
        ]
    });
    options.group.options.push({ value: 'related-training', label: 'Related Training' });
    if (context.subContext && contextData) {
        const selectedTraining = distinctAssociatedTraining.find(itenm => itenm.id === contextData.trainingId);
        const selectedLesson = distinctAssociatedLessons.find(itenm => itenm.id === contextData.lessonId);

        switch(context.subContext) {
            case 'training':
                if (selectedTraining) {
                    addChildGroupOption(options.group, {
                        id: 'related-training', value: selectedTraining.id, options: [
                            { value: selectedTraining.id, label: selectedTraining.name }
                        ]
                    });
                    addChildGroupOption(getGroupOption(options.group.children, 'related-training'), {
                        id: selectedTraining.id, value: '', options: [
                            { value: '', label: 'All Lessons' },
                            ...distinctAssociatedLessons.filter(item => item.trainingId === selectedTraining.id).map(lesson => ({ value: lesson.id, label: capitalizeString(lesson.name) }))
                        ]
                    });
                } else {
                    addChildGroupOption(options.group, {
                        id: 'related-training', value: '', options: [
                            { value: '', label: 'All Training' }
                        ]
                    });
                }

                break;
            case 'lesson':
                if (selectedTraining) {
                    addChildGroupOption(options.group, {
                        id: 'related-training', value: selectedTraining.id, options: [
                            { value: selectedTraining.id, label: selectedTraining.name }
                        ]
                    });

                    if (selectedLesson) {
                        addChildGroupOption(getGroupOption(options.group.children, 'related-training'), {
                            id: selectedTraining.id, value: selectedLesson.id, options: [
                                { value: selectedLesson.id, label: selectedLesson.name }
                            ]
                        });
                    } else {
                        addChildGroupOption(getGroupOption(options.group.children, 'related-training'), {
                            id: selectedTraining.id, value: '', options: [
                                { value: '', label: 'All Lessons' }
                            ]
                        });
                    }
                } else {
                    addChildGroupOption(options.group, {
                        id: 'related-training', value: '', options: [
                            { value: '', label: 'All Training' }
                        ]
                    });
                }

                break;
            default:
                // Do nothing.
        }
    } else {
        addChildGroupOption(options.group, {
            id: 'related-training', value: '', options: [
                { value: '', label: 'All Training' },
                ...distinctAssociatedTraining.map(training => ({ value: training.id, label: capitalizeString(training.name) }))
            ]
        });
        distinctAssociatedTraining.forEach(training => {
            addChildGroupOption(getGroupOption(options.group.children, 'related-training'), {
                id: training.id, value: '', options: [
                    { value: '', label: 'All Lessons' },
                    ...distinctAssociatedLessons.filter(item => item.trainingId === training.id).map(lesson => ({ value: lesson.id, label: capitalizeString(lesson.name) }))
                ]
            });
        });
    }

    // Populate the "sort" options.
    options.sort = { id: 'root', value: 'name-asc', options: [] };
    options.sort.options.push({ value: 'ontology', label: 'Ontology', hidden: (options: DataOptions) => isGroupingByCategory(options) });
    options.sort.options.push({ value: 'name-asc', label: 'Skill Name A>Z' });
    options.sort.options.push({ value: 'name-desc', label: 'Skill Name Z>A' });
    options.sort.options.push({ value: 'skill-points-acquired-asc', label: 'Points Acquired (Total) LOW>HIGH' });
    options.sort.options.push({ value: 'skill-points-acquired-desc', label: 'Points Acquired (Total) HIGH>LOW' });
    options.sort.options.push({ value: 'skill-points-earnable-training-asc', label: 'Earnable Points (Training) LOW>HIGH', hidden: (options: DataOptions) => !isGroupingByTraining(options) });
    options.sort.options.push({ value: 'skill-points-earnable-training-desc', label: 'Earnable Points (Training) HIGH>LOW', hidden: (options: DataOptions) => !isGroupingByTraining(options) });
    options.sort.options.push({ value: 'skill-points-earnable-lesson-asc', label: 'Earnable Points (Lesson) LOW>HIGH', hidden: (options: DataOptions) => !isGroupingByLesson(options) });
    options.sort.options.push({ value: 'skill-points-earnable-lesson-desc', label: 'Earnable Points (Lesson) HIGH>LOW', hidden: (options: DataOptions) => !isGroupingByLesson(options) });

    // Populate the "heatmap" options.
    options.heatmap = { id: 'root', value: '', options: [] };
    options.heatmap.options.push({ value: '', label: 'None' });
    options.heatmap.options.push({ value: 'linked-roles', label: 'Linked Roles' });
    addChildHeatmapOption(options.heatmap, {
        id: 'linked-roles', value: '', options: [
            { value: '', label: 'All Roles' },
            ...linkedRoles.map(role => ({ value: role, label: capitalizeString(role) }))
        ]
    });
    options.heatmap.options.push({ value: 'other-roles', label: 'Other Roles' });
    addChildHeatmapOption(options.heatmap, {
        id: 'other-roles', value: '', options: [
            { value: '', label: 'All Roles' },
            ...otherRoles.map(role => ({ value: role, label: capitalizeString(role) }))
        ]
    });
    options.heatmap.options.push({ value: 'related-training', label: 'Training' });
    addChildHeatmapOption(options.heatmap, {
        id: 'related-training', value: '', options: [
            { value: '', label: 'All Training' },
            ...distinctAssociatedTraining.map(training => ({ value: training.id, label: capitalizeString(training.name) }))
        ]
    });
    distinctAssociatedTraining.forEach(training => {
        addChildHeatmapOption(getHeatmapOption(options.heatmap.children, 'related-training'), {
            id: training.id, value: '', options: [
                { value: '', label: 'All Lessons' },
                ...distinctAssociatedLessons.filter(item => item.trainingId === training.id).map(lesson => ({ value: lesson.id, label: capitalizeString(lesson.name) }))
            ]
        });
    });
    options.heatmap.options.push({ value: 'skill-points-acquired', label: 'Points Acquired' });
    addChildHeatmapOption(options.heatmap, {
        id: 'skill-points-acquired', value: '', options: [
            { value: '', label: 'All Training' },
            ...distinctAssociatedTraining.map(training => ({ value: training.id, label: capitalizeString(training.name) }))
        ]
    });
    distinctAssociatedTraining.forEach(training => {
        addChildHeatmapOption(getHeatmapOption(options.heatmap.children, 'skill-points-acquired'), {
            id: training.id, value: '', options: [
                { value: '', label: 'All Lessons' },
                ...distinctAssociatedLessons.filter(item => item.trainingId === training.id).map(lesson => ({ value: lesson.id, label: capitalizeString(lesson.name) }))
            ]
        });
    });

    options.other = {
        showOtherOptions: true,
        hideEmptyGroups: true,
        groupsPerRow: data.length > 0 && data.length < 4 ? data.length : 4,
        groupsPerRowOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        itemsPerRow: 10,
        itemsPerRowOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    }

    console.log("Generated Options", options);

    return options;
}

export const optionsChanged = (_context: VectorMapContext, prevOptions: DataOptions, newOptions: DataOptions): DataOptions => {
    console.log("Handling Change To Options...");

    // Since we are now supporting the "No Job Role Assigned" meta-role, we need to flip the state of the "Linked Roles" and "Other Roles" appropriately.
    const newFilterOptions = newOptions.filter;
    const newNoJobRoleAssignedOption = newFilterOptions ? getFilterOption(newFilterOptions, 'role-info.linked-roles.__no_job_role_assigned__') : null;

    if (newNoJobRoleAssignedOption) {
        const prevFilterOptions = prevOptions.filter;
        const prevNoJobRoleAssignedOption = prevFilterOptions ? getFilterOption(prevFilterOptions, 'role-info.linked-roles.__no_job_role_assigned__') : null;

        const newOtherRolesOptions = newFilterOptions ? getFilterOption(newFilterOptions, 'role-info.other-roles') : null;

        if (prevNoJobRoleAssignedOption && newNoJobRoleAssignedOption && !prevNoJobRoleAssignedOption.value && newNoJobRoleAssignedOption.value && newOtherRolesOptions && newOtherRolesOptions.children && newOtherRolesOptions.children.length > 0) {
            newOtherRolesOptions.children.forEach(option => {
                option.value = false;
            });
        } else {
            if (newOtherRolesOptions && newOtherRolesOptions.children && newOtherRolesOptions.children.length > 0) {
                const newOtherRolesChecked = newOtherRolesOptions.children.find(option => option.value === true) != null;

                if (newOtherRolesChecked && newNoJobRoleAssignedOption) {
                    newNoJobRoleAssignedOption.value = false;
                }
            }
        }
    }

    // When an option changes other options may become disabled/hidden. In this case we should update any applicable options to proper
    // default values such that the option (drop downs specifically) continue to have a valid value selected.

    // Get the new group option value.
    const newGroupOption = newOptions && newOptions.group ? newOptions.group : null;
    const newGroupOptionValue = newGroupOption && newGroupOption.value != null ? newGroupOption.value : '';

    // Get the prev sort option value.
    const prevSortOption = prevOptions && prevOptions.sort ? prevOptions.sort : null;
    const prevSortOptionValue = prevSortOption && prevSortOption.value != null ? prevSortOption.value : '';

    // When the group option value changes, this can result in certain sort option values becoming hidden or unavailable.
    // In these cases we automatically change the sort option value to some other reasonable value.
    switch (newGroupOptionValue) {
        case 'linked-roles':
        case 'other-roles':
            if (![
                'name-asc', 'name-desc',
                'ontology',
                'skill-points-acquired-asc', 'skill-points-acquired-desc',
                'skill-points-earnable-asc', 'skill-points-earnable-desc',
            ].includes(prevSortOptionValue)) newOptions.sort.value = 'name-asc';

            break;
        case 'type':
            if (![
                'name-asc', 'name-desc',
                'ontology',
                'skill-points-acquired-asc', 'skill-points-acquired-desc',
                'skill-points-earnable-asc', 'skill-points-earnable-desc',
            ].includes(prevSortOptionValue)) newOptions.sort.value = 'name-asc';

            break;
        case 'category':
            if (![
                'name-asc', 'name-desc',
                'skill-points-acquired-asc', 'skill-points-acquired-desc',
                'skill-points-earnable-asc', 'skill-points-earnable-desc',
            ].includes(prevSortOptionValue)) newOptions.sort.value = 'name-asc';

            break;
        case 'related-training':
            if (![
                'name-asc', 'name-desc',
                'ontology',
                'skill-points-acquired-asc', 'skill-points-acquired-desc',
                'skill-points-earnable-training-asc', 'skill-points-earnable-training-desc',
                'skill-points-earnable-lesson-asc', 'skill-points-earnable-lesson-desc',
            ].includes(prevSortOptionValue)) newOptions.sort.value = 'name-asc';

            break;
        default:
            // Do nothing.
    }

    return newOptions;
}
