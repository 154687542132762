import {CREATE, fetchEnd, fetchStart, useDataProvider, useTranslate, useVersion} from 'react-admin';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {createStyles, withStyles} from '@material-ui/core/styles';

import {Button, Checkbox} from '@material-ui/core';
import {EmbeddedShow} from 'components';
import {GET} from 'services/customActions';
import UserProfile from 'components/UserProfile';
import get from 'lodash/get';
import {getUserInfo, safeRequestData} from 'utils/Data';
import {loadObject} from 'utils/Util';
import { useDispatch } from 'react-redux';
import EmailIcon from 'assets/EmailIcon';

const myStyles = theme =>
	createStyles({
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
		fieldColumn: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			width: '100%',
		},
		InfoText: {
			marginLeft: '15px',
			paddingTop: '6px',
			fontFamily: 'Montserrat Italic',
			fontSize: '12px',
		},
		fieldRow: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			marginTop: '5px',
			padding: '10px',
			border: '1px solid',
			borderRadius: '0px',
			alignItems: 'baseline',
		},
		info: {
			marginLeft: '15px',
			paddingTop: '6px',
			fontFamily: 'Montserrat Italic',
			fontSize: '12px',
		},
		checkbox: {
			'&.Mui-disabled': {
				color: '#0E4258',
			},
		},
		button: {
			minWidth: '200px',
			height: '32px',
			fontSize: '14px',
			'&:disabled': {
				borderColor: 'rgb(15, 67, 88)',
				color: 'rgb(15, 67, 88)',
			},
		},
		manageEmailSettings: {
			cursor: 'pointer',
			color: theme.palette.primary.main,
			textDecoration: 'underline',
			padding: '6px',
		},
		subTitle: {
			fontFamily: 'Montserrat Medium',
			paddingBottom: '12px',
		},
	});

const ProgressReport = props => {
	const version = useVersion();

	const translate = useTranslate();

	const [state, setState] = useState({
		profileInfo: loadObject('profileInfo'),
		isSending: false,
		isSent: false,
		version: version,
		firstLoad: true,
	});

	const isSubscribed = loadObject('isSubscribed');

	const isSubscribe = get(isSubscribed, 'subscribed');

	const email = getUserInfo().email;

	const {classes} = props;

	const [isProfileOpen, setIsProfileOpen] = React.useState(false);

	const [isEnabled, setIsEnabled] = React.useState(isSubscribe);

	const handleChange = e => {
		setIsEnabled(e.target.checked);

		dataProvider(CREATE, 'subscribeMonthlyReports', {
			data: {email: email, enabled: e.target.checked},
		});

		let runwayOrgConfig = loadObject('runwayOrgConfig');

		if (runwayOrgConfig) {
			runwayOrgConfig['isSubscribed'] = e.target.checked;
			sessionStorage.setItem(
				'runwayOrgConfig',
				JSON.stringify(runwayOrgConfig)
			);
		}
	};

	const isMounted = useRef(true);
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const openDialog = () => {
		setIsProfileOpen(true);
	};

	const handleClick = () => {
		setState({isSending: true});
		dataProvider(GET, 'sendInstantMonthlyProgressReportRunway').then(response =>
			response && response.response.data
				? setState({...state, isSending: false, isSent: true})
				: null
		);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const dataProvider = useDataProvider();

	const dispatch = useDispatch();

	const fetchData = useCallback(async () => {
		const requestList = [{resource: 'isSubscribed'}];
		try {
			dispatch(fetchStart());
			for (let i = 0; i < requestList.length; i++) {
				const request = requestList[i];
				await safeRequestData({
					dataRequest: request,
					dataProvider: dataProvider,
					isMounted: isMounted,
					setState: setState,
				});
			}
		} finally {
			dispatch(fetchEnd());
		}
	}, [dataProvider]);

	return (
		<div style={{marginTop: '24px'}}>
			<EmbeddedShow title={'Progress Report'}>
				{email && (
					<div className={classes.cardBody}>
						<div className={classes.fieldColumn}>
							<div className={classes.subTitle}>
								{translate('Module Progress Reports')}
							</div>
							<div className={classes.fieldRow} style={{position: 'relative'}}>
								<Button
									style={{height: '32px', width: '184px'}}
									variant={'contained'}
									color={'primary'}
									onClick={handleClick}
									startIcon={<EmailIcon style={{ fontSize: '18px' }}/>}
								>
									{translate('Send Me Report')}
								</Button>

								<span className={classes.InfoText}>
									{' '}
									{translate(
										'Send you a snapshot of training progress for your users from the last 30 days.'
									)}
								</span>
								<span
									className={classes.manageEmailSettings}
									onClick={openDialog}
								>
									{translate('Manage Email Settings')}
								</span>
								{state.isSending && (
									<div style={{padding: '6px'}}>
										{translate('Processing...')}
									</div>
								)}
								{state.isSent && (
									<div
										style={{
											padding: '6px',
											position: 'absolute',
											top: '0px',
											right: '0px',
											maxWidth: '230px',
										}}
									>
										{translate(
											'You should receive a report by email within a few minutes'
										)}
									</div>
								)}
							</div>
						</div>
						<div style={{flexDirection: 'row', padingTop: '5px'}}>
							<Checkbox
								size='small'
								checked={isEnabled}
								onChange={handleChange}
							/>
							<span>
								{translate('Subscribe to Monthly User Progress Report')}
							</span>
						</div>
						<div className={classes.info}>
							{translate(
								'Automatically send you a user progress report on the 1st of each month.'
							)}
						</div>
					</div>
				)}
				{isProfileOpen && (
					<UserProfile
						isEdit={email ? false : true}
						isOpen={isProfileOpen}
						setIsOpen={setIsProfileOpen}
					/>
				)}
				{!email && (
					<div>
						<div className={classes.info}>
							<div>
								{translate(
									'Dashboard will email you a snapshot of module progress for your users. You need to set up a'
								)}{' '}
								<span
									style={{color: '#326dec', cursor: 'pointer'}}
									onClick={openDialog}
								>
									{translate('contact email address')}
								</span>{' '}
								{translate('for your user account to receive a Progress Report')}
							</div>
						</div>
						<hr className={classes.divider} />
						<Button
							variant='outlined'
							color='primary'
							style={{height: '32px'}}
							onClick={openDialog}
						>
							{translate('Manage Email Settings')}
						</Button>
					</div>
				)}
			</EmbeddedShow>
		</div>
	);
};

export default withStyles(myStyles)(ProgressReport);
