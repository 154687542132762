import {EmbeddedList, StatusChip, TimeSpanField} from 'components';
import {NumberField, useTranslate} from 'react-admin';

import {MenuItem} from '@material-ui/core';
import PercentField from 'components/PercentField';
import React from 'react';
import SkillFilter from '../../../../../components/SkillFilter';

const LessonList = props => {
	const {selectedId, lessonSelected, selectedSkills, selectedTrainingRecord, skillsSelected} = props;

	const translate = useTranslate();

	return (
		<EmbeddedList
			embedded={false}
			subTitle
			title={translate('Lesson Progress')}
			resource='lessonsteamtraining'
			columns={[
				{
					id: 'name',
					name: translate('Name'),
					sortable: true,
				},
				{
					id: 'lessonType',
					name: translate('Type'),
					sortable: true,
					render: record => <StatusChip record={record} source='lessonType' />,
				},
				{
					id: 'attempts',
					name: translate('Attempts'),
					sortable: true,
					align: 'center',
					render: record => <NumberField record={record} source='attempts' />,
				},
				{
					id: 'avgTime',
					name: translate('Avg Time'),
					sortable: true,
					align: 'center',
					render: record => <TimeSpanField record={record} source='avgTime' />,
				},
				{
					id: 'teamAverageRate',
					name: translate('Team Avg Score'),
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='teamAverageRate' />
					),
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[
				{id: 'trainingId', value: selectedId},
				{id: 'teamId', value: props.id},
			]}
			dynamicFilters={[
				{
					id: 'namedSkills',
					value: selectedSkills.length > 0 ? {$in: selectedSkills.map(skill => skill.name)} : undefined,
					render: () => (
						<SkillFilter
							onChange={skills => {
								skillsSelected(skills);
							}}
							preSelectedSkills={
								selectedSkills.length ? selectedSkills : undefined
							}
							skillListContextData={{trainingId: selectedTrainingRecord.id, teamId: props.id}}
						/>
					),
				},
			]}
			bulkActions={[]}
			actions={[
				{
					render: record => (
						<MenuItem>
							<div onClick={() => lessonSelected(record.id, null, record)}>
								{translate('View Lesson Detail')}
							</div>
						</MenuItem>
					),
				},
			]}
			rowClicked={record => lessonSelected(record)}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default LessonList;
