import moment from 'moment';
import { timezones_daylight, timezones_standard } from 'utils/Data';

export const saveObject = (key, value) => {
	sessionStorage.setItem(key, JSON.stringify(value));
};

export const loadObject = key => {
	try {
		return JSON.parse(sessionStorage.getItem(key));
	} catch {
		return null;
	}
};

export const getOrgId = () => {
	const project = window.location.hostname.split('.')[0];

	return project === 'localhost' ? 'dev' : project;
}

export const isValidDate = (value) => {
	let isValid = true;
	if (Object.prototype.toString.call(value) === "[object Date]") {
		if (isNaN(value)) {
			isValid = false;
		} else {
			isValid = true;
		}
	} else {
		isValid = false;
	}
	return isValid;
}

export async function checkOnlineStatus() {
	const myHeaders = new Headers();
	myHeaders.append('pragma', 'no-cache');
	myHeaders.append('cache-control', 'no-cache');

	var myInit = {
		method: 'GET',
		headers: myHeaders,
	};
	try {
		const online = await fetch(`${process.env.PUBLIC_URL}`, myInit);
		return online.status >= 200 && online.status < 300; // either true or false
	} catch (err) {
		return false; // definitely offline
	}
}

export const clearSessionStorageOnUserLogOut = (networkError) => {
	const project = getOrgId();
	if (networkError) sessionStorage.setItem('networkError', 'true');
	sessionStorage.removeItem(project + '-token');
	sessionStorage.removeItem(project + '-packetId');
	sessionStorage.removeItem(project + '-user');
	sessionStorage.removeItem(project + 'appLogo');
}

export const parseJson = (val) => {
	try {
		return JSON.parse(val);
	} catch (e) {
		return null;
	}
}

export const validateEmail = email => {
	const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
	return emailPattern.test(email);
};

export const getUserTimeZone = () => {
	const userData = loadObject(loadObject('project').id + '-user');

	const findClosestOffset = (offset, timezoneList) => {
		let closestOffset = null;
		let minDifference = Infinity;

		for (const timezone of timezoneList) {
			const difference = Math.abs(timezone.id - offset);
			if (difference < minDifference) {
				closestOffset = timezone;
				minDifference = difference;
			}
		}

		return closestOffset;
	}

	const availableTimezones = (moment(new Date()).isDST()
		? timezones_daylight
		: timezones_standard
	).map(tz => {
		return {
			id: tz.offset,
			name: tz.name,
			offsetString: tz.offsetString,
		};
	});

	let userTimeZone = availableTimezones.find(
		zone => zone.id === userData.timeZone
	);

	if (userTimeZone == null) {
		userTimeZone = findClosestOffset(userData.timeZone, availableTimezones);
	}

	return userTimeZone;
}

export const copyToClipboard = (text) => {
	navigator?.clipboard
		.writeText(text)
		.then(() => {
			console.log('Copied to clipboard');
		})
		.catch(() => {
			console.error('Copy to clipboard Failed!');
		});
}

export const writeTextAsync = (p) => {
	const item = new ClipboardItem({
		"text/plain": p.then((result) => {
			if (!result) {
				return Promise.resolve(new Blob([''], { type: "text/plain" }));
			}

			return Promise.resolve(new Blob([result], { type: "text/plain" }));
		}),
	});

	return navigator.clipboard
		.write([item])
		.then(() => {
			console.log('Copied to clipboard');
		})
		.catch((e) => {
			console.error('Copy to clipboard Failed!', e);
		});
}

export const openNewTabWithPromise = (promise, urlPath, sideEffect) => {
	promise.then(data => {
	  let url = data;
	  
	  // Handle nested properties if needed
	  if (urlPath) {
		const properties = urlPath.split('.');
		properties.forEach(prop => {
		  if (url && typeof url === 'object') {
			url = url[prop];
		  } else {
			url = undefined;
		  }
		});
	  }
	  
	  if (url) {
		const link = document.createElement('a');
		link.href = url;
		link.target = '_blank';
  
		const clickEvent = new MouseEvent('click', {
		  view: window,
		  bubbles: true,
		  cancelable: true,
		});
  
		link.dispatchEvent(clickEvent);
	  }
	  if (sideEffect) sideEffect();
	}).catch(e => {
		console.log(e);
		if (sideEffect) sideEffect();
	});
  }
  
export const isGreaterThanZeroAndInteger = value => {
	const number = parseFloat(value);

	if (
		!isNaN(number) &&
		number > 0 &&
		Number.isInteger(number) &&
		/^\d+(\.\d+)?$/.test(value)
	) {
		return true;
	} else {
		return false;
	}
};
