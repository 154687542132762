import {
	CircularProgress,
	IconButton,
	makeStyles,
	Typography,
} from '@material-ui/core';
import {EmbeddedShow} from 'components';
import {CREATE, GET_LIST, useDataProvider, useTranslate} from 'react-admin';
import MUIButton from '@material-ui/core/Button';
import {useEffect, useRef, useState} from 'react';
import LoadingInfo from 'components/loading/LoadingInfo';
import moment from 'moment';
import {Dialog, DialogContent, DialogActions} from '@material-ui/core';
import {Text, DialogTitle} from 'components';
import CloseIcon from '@material-ui/icons/Close';
import {loadObject} from 'utils/Util';
import {CREATE_GENERATE_USERS, GET} from 'services/customActions';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { parseJson } from "utils/Util";
import FileDownload from 'assets/FileDownload';
import { InsertDriveFileOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
	fieldColumn: {
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '100%',
	},
	fieldRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		marginTop: '5px',
		padding: '10px',
		border: '1px solid',
		borderRadius: '0px',
	},
	info: {
		marginLeft: '15px',
		fontFamily: 'Montserrat Italic',
		paddingTop: '6px',
		fontSize: '12px',
	},
	label: {
		paddingTop: '10px',
		paddingBottom: '10px',
		fontFamily: 'Montserrat Medium',
	},
	fieldMultipleColumn: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		marginTop: '5px',
		padding: '10px',
		border: '1px solid',
		borderRadius: '0px',
	},
	divider: {
		border: 0,
		borderBottom: '1px solid',
		color: theme.palette.borderColor.divider,
		width: '100%',
		height: '1px',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
	},
	spinnerStyles: {
		position: 'relative',
		marginRight: '5px',
		top: '2px',
		color: theme.palette.primary.main,
	},
	lastUpdatedText: {
		fontFamily: 'Montserrat Bold',
		display: 'inline',
		paddingLeft: '15px',
		paddingTop: '5px',
	},
	selectedFile: {
		borderRadius: '0px',
		border: `1px solid ${theme.palette.primary.main}`,
		height: '32px',
		textAlign: 'center',
		maxWidth: '204px',
		alignItems: 'center',
		display: 'flex',
	},
	selectedFileName: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		maxWidth: '160px',
		paddingLeft: '5px',
	},
}));

const PackageIntegration = () => {
	let intervalId = null;

	const translate = useTranslate();
	const dataProvider = useDataProvider();
	const classes = useStyles();
	const isMounted = useRef(true);

	const orgConfig = loadObject('project');
	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const bulkEditActivePollDurationSeconds = Number(
		runwayOrgConfig?.config.bulkEditActivePollDurationSeconds
	);

	const [generatingExport, setGeneratingExport] = useState(false);
	const [downloadingExport, setDownloadingExport] = useState(false);
	const [verifying, setVerifying] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [usersVerified, setUsersVerified] = useState(false);
	const [updatingUsers, setUpdatingUsers] = useState(false);
	const [
		isBackGroundBulkOpInProgress,
		setIsBackGroundBulkOpInProgress,
	] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	const [verificatioError, setVerificationError] = useState(null);
	const [fileReadError, setFileReadError] = useState(null);
	const [userLMSExportStatus, setUserLMSExportStatus] = useState(null);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [parsedFileData, setParsedFileData] = useState(null);
	const [verifiedUsersDataToUpdate, setVerifiedUsersDataToUpdate] = useState(null);
	const [noOfBulkUsersUpdating, setNoOfBulkUsersUpdating] = useState(null);
	const [verifiedUsersDataToDelete, setVerifiedUsersDataToDelete] = useState(null);

	useEffect(() => {
		getUserLMSExportStatus(true);
	}, []);

	const getUserLMSExportStatus = async initialLoad => {
		try {
			const res = await dataProvider(GET_LIST, 'getUserLMSExportStatus', {});
			if (res && res.data) {
				setUserLMSExportStatus(res.data);
			}
			setGeneratingExport(false);
		} catch (e) {
			console.error(e);
			setGeneratingExport(false);
		}
	};

	const generateExport = async () => {
		setGeneratingExport(true);
		try {
			await dataProvider(
				CREATE_GENERATE_USERS,
				'createGeneratedUserLMSExport',
				{}
			);
			await getUserLMSExportStatus();
		} catch (e) {
			console.error(e);
			setGeneratingExport(false);
		}
	};

	const handleFileChosen = file => {
		setFileReadError(null);
		let fileReader;
		const name = file.name;
		const lastDot = name.lastIndexOf('.');
		const ext = name.substring(lastDot + 1);
		if (ext === 'csv') {
			setUploadedFile(file);
			const handleFileRead = e => {
				const content = fileReader.result;
				const trimmedContnet = content?.trim();
				try {
					const fileData = convertCSVToJSON(trimmedContnet);
					if (Array.isArray(fileData)) {
						if (fileData.length > 0 && Object.keys(fileData[0]).length > 0 && Object.keys(fileData[0]).includes('profileId_ReadOnly')) {
							const filteredArr = fileData.filter(obj => {
								for (let key in obj) {
									if (obj[key] != null && ((typeof obj[key] !== 'string') || obj[key].trim().length > 0)) {
										return true;
									}
								}
								return false;
							});
							setParsedFileData(filteredArr);
						} else {
						   setFileReadError('Cannot use this file. This file does not match the column format for LMSID Import.');
						   closeDialog();
						}
					} else {
						setFileReadError('Unable to read File');
						closeDialog();
					}
				} catch(e) {
					console.log(e)
					setFileReadError(e);
					closeDialog();
				}
			};
			fileReader = new FileReader();
			fileReader.onloadend = handleFileRead;
			fileReader.readAsText(file);
		} else {
			setFileReadError(
				'File not verified. File must be of type CSV. Select a CSV file to import.'
			);
			setUploadedFile(null);
			setParsedFileData(null);
		}
		document.getElementById('selectFile_LMS_id').value = '';
	};

	const verifyIntegrity = () => {
		setVerifying(true);
		dataProvider(CREATE, 'users_verify', {
			data: {
				authType: orgConfig?.config?.authType,
				skipTeamUpdate: true,
				skipCreatePlaceholderLessons: true,
				users: parsedFileData,
				isLMS: true
			},
		})
			.then(res => {
				if (!res.errors) {
					setUsersVerified(true);
					if (res.data.existingUsers) setVerifiedUsersDataToUpdate(res.data.existingUsers);
					if (res.data.deletingUsers && Array.isArray(res.data.deletingUsers)) setVerifiedUsersDataToDelete(res.data.deletingUsers);
				} else {
					setVerificationError(res.errors);
					setUsersVerified(false);
				}
			})
			.catch(error => {
				if (error && error.message && typeof error.message === "string" && parseJson(error.message)) {
					const parsedError = JSON.parse(error.message);
					setVerificationError(parsedError);
				} else {
					setVerificationError(error?.message);
				}
				setUsersVerified(false);
			})
			.finally(() => {
				setVerifying(false);
			});
	};

	const updateUsers = async () => {
		setUpdatingUsers(true);
		const usersToUpdate = [];

		verifiedUsersDataToUpdate.forEach(vu => {
			const user = parsedFileData.find(u => u.profileId_ReadOnly === vu.id);
			if (user != null) {
				usersToUpdate.push(user);
			}
		});

		verifiedUsersDataToDelete.forEach(vu => {
			const user = parsedFileData.find(u => u.profileId_ReadOnly === vu.id);
			if (user != null) {
				usersToUpdate.push(user);
			}
		});

		await dataProvider(CREATE, 'usersAdd', {
			data: {
				authType: orgConfig?.config?.authType,
				skipTeamUpdate: true,
				skipCreatePlaceholderLessons: true,
				users: usersToUpdate,
			},
		})
			.then(response => {
				getBulkEditUserOperationStatus();
				setNoOfBulkUsersUpdating(usersToUpdate.length);
			})
			.catch(error => setUpdatingUsers(false));
	};

	const convertCSVToJSON = content => {
		setFileReadError(null);
		const csv2json = require('csvjson-csv2json');
		const file = csv2json(content, {parseNumbers: true, parseJSON: true});
		return file;
	};

	useEffect(() => {
		return () => {
			isMounted.current = false;
			clearInterval(intervalId);
		};
	}, []);

	const startInterval = (timer, inProgress) => {
		intervalId = setInterval(() => {
			getBulkEditUserOperationStatus(inProgress);
		}, timer);
	};

	const getBulkEditUserOperationStatus = async inProgress => {
		await dataProvider(GET, 'getBulkEditUserOperationStatus')
			.then(response => {
				if (response.data.response.data.inProgress) {
					clearInterval(intervalId);
					if (isMounted.current) {
						setIsBackGroundBulkOpInProgress(true);
						setUpdatingUsers(false);
						startInterval(
							bulkEditActivePollDurationSeconds !== 'NaN'
								? bulkEditActivePollDurationSeconds * 1000
								: 10000,
							true
						);
					}
				} else {
					closeDialog();
					setIsBackGroundBulkOpInProgress(false);
					setShowSuccessMessage(true);
					setUpdatingUsers(false);
					clearInterval(intervalId);
				}
			})
			.catch(error => {
				clearInterval(intervalId);
				setIsBackGroundBulkOpInProgress(false);
				setUpdatingUsers(false);
			});
	};

	const closeDialog = () => {
		setShowDialog(false);
		setVerifying(false);
		setUsersVerified(false);
		setVerificationError(null);
		setUploadedFile(null);
		setParsedFileData(null);
	};

	const clearPreviousOperationData = () => {
		setShowSuccessMessage(false);
		setNoOfBulkUsersUpdating(null);
	};

	const clearFileSelection = () => {
		setUploadedFile(null); 
		setFileReadError(null);
	}

	const downloadExportred = () => {
		setDownloadingExport(true);
		dataProvider(GET_LIST, 'downloadGeneratedUserLMSExport', {})
			.then(response => {
				const val = response.data;
				const data = val.map(row => row);
				const csvRows = [];

				const headers = Object.keys(data[0]);
				csvRows.push(headers.join(','));
				for (const row of data) {
					const values = headers.map(header => {
						const escaped = row[header];
						return `"${escaped}"`;
					});
					csvRows.push(values.join(','));
				}
				const resp = csvRows.join('\n');
				const file = new File([resp], {type: 'text'});
				const url = window.URL.createObjectURL(file);
				const a = document.createElement('a');
				a.setAttribute('hidden', '');
				a.setAttribute('href', url);
				const uploadedFile = 'UserExportLMS.csv';
				a.setAttribute('download', uploadedFile);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				setDownloadingExport(false);
			})
			.catch(error => {
				console.log(error);
				setDownloadingExport(false);
			});
	};

	return (
		<div style={{marginTop: '24px'}}>
			<EmbeddedShow height={'auto'} title={translate('Package Integration')}>
				{userLMSExportStatus ? (
					<div>
						<div className={classes.fieldColumn}>
							<div className={classes.label}>
								{translate(
									'Use this process to attach an ID from your LMS system to existing Talespin Platform Learners.'
								)}
							</div>
							<div className={classes.fieldMultipleColumn}>
								<div className={classes.row}>
									<MUIButton
										variant='contained'
										color='primary'
										onClick={() => generateExport()}
										style={{height: '32px', width: '172px'}}
										disabled={generatingExport}
									>
										{translate('Generate Export')}
									</MUIButton>
									{!generatingExport &&
										userLMSExportStatus?.lastCreatedTime && (
											<div className={classes.lastUpdatedText}>
												Last Created:{' '}
												{moment
													.unix(userLMSExportStatus?.lastCreatedTime / 1000)
													.format('MM-DD-YYYY hh:mm A')}
											</div>
										)}

									<div className={classes.info}>
										{!generatingExport &&
											translate(`Note: Passwords are never exported.`)}
										{generatingExport && (
											<>
												<CircularProgress
													size={15}
													className={classes.spinnerStyles}
												/>
												{translate('Generating...')}
											</>
										)}
									</div>
								</div>
								<hr className={classes.divider} />
								<div className={classes.row}>
									<MUIButton
										variant='outlined'
										color='primary'
										onClick={() => downloadExportred()}
										style={{width: '172px'}}
										startIcon={<FileDownload style={{ fontSize: "20px" }}/>}
										disabled={downloadingExport || generatingExport || !userLMSExportStatus?.canDownload}
									>
										{translate('Download Export')}
									</MUIButton>
									<div className={classes.info}>
										{downloadingExport && (
											<>
												<CircularProgress
													size={15}
													className={classes.spinnerStyles}
												/>
												{translate('Downloading...')}
											</>
										)}

										{!downloadingExport &&
											translate(
												`Add your LMSID to this CSV and then import it below.`
											)}
									</div>
								</div>
							</div>
						</div>

						<div className={classes.fieldColumn} style={{paddingTop: '5px'}}>
							<div
								className={classes.label}
								style={{fontFamily: 'Montserrat Regular'}}
							>
								{translate('Import LMS IDs')}
							</div>
							<div className={classes.fieldMultipleColumn}>
								<div className={classes.row}>
									{!uploadedFile && (
										<MUIButton
											variant='contained'
											color='primary'
											onClick={() => {
												document.getElementById('selectFile_LMS_id').click();
												clearPreviousOperationData();
											}}
											startIcon={<InsertDriveFileOutlined />}
											style={{height: '32px', width: '172px'}}
										>
											{translate('Select File...')}
										</MUIButton>
									)}

									{uploadedFile && (
										<div className={classes.selectedFile}>
											<span
												className={classes.selectedFileName}
												title={uploadedFile?.name}
											>
												{uploadedFile?.name}
											</span>
											<IconButton onClick={() => clearFileSelection()}>
												<CloseIcon color='primary' />
											</IconButton>
										</div>
									)}

									<input
										type='file'
										id='selectFile_LMS_id'
										className='input-file'
										accept='.csv'
										hidden={true}
										onChange={e => handleFileChosen(e.target.files[0])}
									/>
									{!uploadedFile && !fileReadError && (
										<div className={classes.info}>
											{translate(`Select a CSV file to import`)}
										</div>
									)}
									{fileReadError && (
										<Typography style={{ paddingLeft: '10px', paddingTop: '5px' }} color='error'>
											{fileReadError}
										</Typography>
									)}
								</div>
								<hr className={classes.divider} />
								<div className={classes.row}>
									<MUIButton
										variant='outlined'
										color='primary'
										style={{width: '160px'}}
										disabled={!uploadedFile}
										onClick={() => setShowDialog(true)}
									>
										{translate('Update Users')}
									</MUIButton>
									<div className={classes.info}>
										{(isBackGroundBulkOpInProgress || updatingUsers) && (
											<>
												<CircularProgress
													size={15}
													className={classes.spinnerStyles}
												/>
												{translate('Updating...')}
											</>
										)}
										{!isBackGroundBulkOpInProgress &&
											noOfBulkUsersUpdating != null &&
											showSuccessMessage && (
												<span>
													Success! {noOfBulkUsersUpdating} Learner IDs updated.
												</span>
											)}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<LoadingInfo />
				)}
			</EmbeddedShow>

			{showDialog && (
				<Dialog open={true} maxWidth={'sm'}>
					<DialogTitle onClose={() => closeDialog()}>
						<Text
							style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
							label={'Import Learner LMS Identities'}
						/>
					</DialogTitle>
					<DialogContent
						style={{
							width: '500px',
							height: 'auto',
							display: 'flex',
							fontFamily: 'Montserrat Medium',
							fontSize: '16px',
							justifyContent: 'center',
						}}
					>
						{!updatingUsers && !isBackGroundBulkOpInProgress && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									margin: '16px 0px',
								}}
							>
								{!verificatioError && !usersVerified && !verifying && (
									<>
										<span style={{ fontFamily: 'Montserrat Bold' }}>
										Note: UserID (Read Only), ProfileID (Read Only) & LMSID (Writable) are used by the import system. Other columns are for reference only.
										</span>
										<br />
										<span>File: {uploadedFile?.name}</span>
									</>
								)}

								{!verificatioError && usersVerified && !verifying && (
									<>
										<span
											style={{
												color: '#4DAE51',
												display: 'flex',
												alignItems: 'flex-end',
											}}
										>
											<CheckCircleOutlineIcon />
											<Typography
												style={{
													paddingLeft: '10px',
													fontFamily: 'Montserrat Bold',
												}}
											>
												Verified
											</Typography>
										</span>
										<br />
										<span style={{fontFamily: 'Montserrat Bold'}}>
											Ready to Import LMS IDs
										</span>
										<br />
										<span>Total records in file: {parsedFileData.length}</span>
										<span>
											Total records with both LMSID and Username:{' '}
											{verifiedUsersDataToUpdate.length}
										</span>
										<span>Total records with LMSID deletion requests: {verifiedUsersDataToDelete.length}</span>
										<br />
										<span>
											Update {verifiedUsersDataToUpdate.length + verifiedUsersDataToDelete.length} Learner accounts
										</span>
									</>
								)}

								{verifying && (
									<>
										<div>
											<CircularProgress
												size={15}
												className={classes.spinnerStyles}
											/>
											<span style={{fontFamily: 'Montserrat Bold'}}>
												Verifying
											</span>
										</div>
									</>
								)}

								{verificatioError && !usersVerified && (
									<>
										<span
											style={{
												display: 'flex',
												alignItems: 'flex-end',
											}}
										>
											<HighlightOffIcon color='error' />
											<Typography
												style={{
													paddingLeft: '10px',
													fontFamily: 'Montserrat Bold',
												}}
												color='error'
											>
												Failed
											</Typography>
										</span>
										<br />
										<span style={{fontFamily: 'Montserrat Bold'}}>
											Unable to Import file
										</span>
										{Array.isArray(verificatioError) ? (
											<>
												{verificatioError.map((e, i) => {
													return (
														<span id={`error - ${i + 1}`}>
															<br />
															<span>{e?.errorMessage}</span>
														</span>
													);
												})}
											</>
										) : (
											<>
												<br />
												<span>{verificatioError}</span>
											</>
										)}
									</>
								)}
							</div>
						)}
						{(updatingUsers || isBackGroundBulkOpInProgress) && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									margin: '16px 0px',
								}}
							>
								<span style={{fontFamily: 'Montserrat Bold'}}>
									<CircularProgress
										size={20}
										className={classes.spinnerStyles}
									/>
									<b>Updating {verifiedUsersDataToUpdate?.length + verifiedUsersDataToDelete?.length} Learner IDs...</b>
								</span>
								<span style={{marginTop: '16px'}}>
									It is safe to close the window.
								</span>
							</div>
						)}
					</DialogContent>

					<DialogActions>
						{!updatingUsers && !isBackGroundBulkOpInProgress && (
							<>
								{((!verificatioError && !usersVerified) || verifying) && (
									<MUIButton
										variant={'contained'}
										color={'primary'}
										onClick={() => verifyIntegrity()}
										disabled={verifying}
									>
										{translate('Verify Integrity')}
									</MUIButton>
								)}

								{!verificatioError && usersVerified && (
									<>
										<MUIButton
											variant={'contained'}
											color={'secondary'}
											onClick={() => closeDialog()}
										>
											{translate('Cancel')}
										</MUIButton>
										<MUIButton
											variant={'contained'}
											color={'primary'}
											onClick={() => updateUsers()}
										>
											{translate('Yes')}
										</MUIButton>
									</>
								)}

								{verificatioError && !usersVerified && (
									<MUIButton
										variant={'contained'}
										color={'primary'}
										onClick={() => closeDialog()}
									>
										{translate('Close')}
									</MUIButton>
								)}
							</>
						)}

						{(updatingUsers || isBackGroundBulkOpInProgress) && (
							<MUIButton
								variant={'contained'}
								color={'primary'}
								onClick={() => closeDialog()}
							>
								{translate('Close')}
							</MUIButton>
						)}
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default PackageIntegration;
