import React, {Component} from 'react';
import {CREATE, Create, GET_LIST, withDataProvider} from 'react-admin';
import compose from 'recompose/compose';
import {refreshView, translate} from 'ra-core';
import {connect} from 'react-redux';
import {Form} from 'react-final-form';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {DialogTitle, EmbeddedList, Text} from 'components';
import {DialogListTheme} from 'components/Theme';
import FakeSelectInput from 'components/FakeSelectInput';
import LoadingInfo from 'components/loading/LoadingInfo';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	MenuItem,
	Typography,
	withStyles,
} from '@material-ui/core';
import {DELETE_USER} from 'services/customActions';

const styles = theme => ({
	name: {
		color: theme.palette.primary.main,
	},
});

const footerStyle = {
	backgroundColor: '#fff',
	padding: '8px 0px',
};

class DeleteUserButton extends Component {
	newTeams = [];
	state = {
		showDeleteUserDialog: false,
		showSpinner: false,
		assignMgrModal: false,
		teams: [],
		teamsLength: null,
		formValid: false,
		showDialog: false,
		newTeams: [],
		newManagerTeams: [],
		selectedManagers: [],
		selectedTeamId: '',
		nameData: [],
		savedNameData: [],
		isDisableManager: false,
		currentUserId: [],
	};

	async fetchTeams() {
		const {dataProvider, record} = this.props;
		if (
			record.role.indexOf('manager') >= 0 ||
			record.role.indexOf('admin') >= 0
		) {
			const {data: teams} = await dataProvider(GET_LIST, 'teamsforuser', {
				filter: {userId: record.id},
				sort: {field: 'name', order: 'ASC'},
				pagination: {page: 1, perPage: 50},
			});
			const managerOfTeams = teams.filter(
				team => team.role.indexOf('manager') >= 0
			);
			console.debug(
				`DisabledButton: team list will be: ${JSON.stringify(teams)}`
			);
			if (managerOfTeams && managerOfTeams.length > 0) {
				this.setState({teams: managerOfTeams, currentUserId: record.id});
			}
		}
	}

	componentDidMount() {
		this.fetchTeams();
	}

	openAssignManagerDailog = () => {
		this.setState({assignMgrModal: true});
	};

	onSelectManager = value => {
		this.setState({showDialog: true, selectedTeamId: value});
	};

	closeAssignManagrDialog = () => {
		this.setState({showDialog: false});
	};

	openDeleteUserDialog = e => {
		this.setState({showDeleteUserDialog: true});
	};

	closeDeleteUserDialog = e => {
		this.props.onClick && this.props.onClick(e);
	};

	onContinue = e => {
		e.stopPropagation();
		this.setState({showDeleteUserDialog: true, assignMgrModal: false});
	};

	handleSubmit = e => {
		e && e.stopPropagation();
		// this.setState({ showDeleteUserDialog: false });
		if (this.state.newManagerTeams && this.state.newManagerTeams.length) {
			this.setState({showSpinner: true});
			this.props
				.dataProvider(CREATE, 'teamsManager', {
					data: {teams: this.state.newManagerTeams},
				})
				.then(() => {
					this.newTeams = [];
					this.handleDelete();
				})
				.catch(e => {
					this.setState({showSpinner: false});
				});
		}
	};

	handleManagerSelectionToggled = record => {
		this.setState({selectedManagers: record.ownerId});

		const targetManger = this.newTeams.find(
			item => item != null && record != null && item.id === record.id
		);

		if (!!targetManger) {
			const idx = this.newTeams.indexOf(targetManger);

			if (idx >= 0) {
				this.newTeams.splice(idx, 1);
			}
		}

		this.newTeams.push(record);

		this.setState({newTeams: record});
	};

	handleManagerClick = () => {
		const temp1 = this.state.newTeams;
		const temp2 = this.state.newManagerTeams;
		const targetManger = temp2.find(
			item => item != null && temp1 != null && item.id === temp1.id
		);
		if (!!targetManger) {
			const idx = temp2.indexOf(targetManger);

			if (idx >= 0) {
				temp2.splice(idx, 1);
			}
		}

		temp2.push(temp1);

		this.setState({newManagerTeams: temp2}, () => {
			this.closeAssignManagrDialog();
			this.setState({selectedManagers: []});
		});
	};

	handleCloseClick = e => {
		e && e.stopPropagation();
		this.props.onClick && this.props.onClick(e);
		this.setState({assignMgrModal: false});
	};

	handleDelete = async e => {
		const {dataProvider, refreshView} = this.props;

		this.setState({showSpinner: true});

		await dataProvider(
			DELETE_USER,
			'users',
			{
				data: {id: this.props.record.id},
			},
			{
				onSuccess: {
					refresh: true,
					unselectAll: true,
				},
				onFailure: {
					unselectAll: true,
					notification: {level: 'warning'},
				},
			}
		)
			.then(() => {
				this.setState({showDeleteUserDialog: false});
			})
			.finally(() => {
				this.setState({showSpinner: false});
			});
		this.props.onClick && this.props.onClick(e);
		refreshView();
	};

	render() {
		const {translate, record} = this.props;
		const {showDeleteUserDialog, showSpinner} = this.state;

		const isManager =
			(record && record.role.indexOf('manager') >= 0) ||
			(record && record.role.indexOf('admin') >= 0);

		const mockCreateProps = {
			hasCreate: false,
			hasEdit: false,
			hasList: true,
			hasShow: false,
			match: {
				isExact: true,
				params: {},
				path: '/teamsforuser',
				url: '/teamsforuser',
			},
			basePath: this.props.basePath,
			location: this.props.location,
			resource: 'trainings',
			sort: {field: 'name', order: 'ASC'},
			selectedIds: [],
			exporter: null,
		};

		const teamsList = this.state.teams.map(value => {
			var valueId = value.id;

			const targetManger = this.newTeams.find(
				item => item != null && valueId != null && item.id === valueId
			);

			return (
				<div
					key={value.id}
					data-dataId={value.id}
					style={{paddingBottom: '5px'}}
				>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							marginTop: '5px',
							padding: '10px 0px',
							borderTop: '1px solid #ccc',
							// borderRadius: '5px',
						}}
					>
						<div
							style={{
								width: '40%',
								paddingTop: '5px',
							}}
						>
							<Typography variant='subheading'>{value.name}</Typography>
						</div>

						<div style={{width: '60%'}}>
							<FakeSelectInput
								value={!!targetManger && targetManger.ownerName}
								placeholder={'Select a Manager'}
								onClick={() => {
									this.onSelectManager(valueId);
								}}
							></FakeSelectInput>
						</div>

						<br />
						<MuiThemeProvider theme={DialogListTheme}>
							<Dialog
								maxWidth='md'
								open={
									this.state.showDialog && valueId === this.state.selectedTeamId
								}
								aria-label={translate('Select Manager')}
							>
								<DialogTitle onClose={this.closeAssignManagrDialog}>
									<Text
										style={{fontSize: '20px'}}
										label={translate('Select Manager')}
									/>
								</DialogTitle>
								<DialogContent>
									<div
										style={{
											flex: '1 1 auto',
											display: 'flex',
											flexDirection: 'column',
											overflow: 'auto',
										}}
									>
										<EmbeddedList
											embedded={true}
											cached={false}
											title={translate('Available Users')}
											resource={'users'}
											columns={[
												{
													id: 'userId',
													name: translate('User ID'),
													sortable: true,
												},
												{
													id: 'name',
													name: translate('User Name'),
													sortable: true,
												},
												{
													id: 'teams',
													name: translate('Teams'),
													sortable: true,
													width: '125px',
													align: 'center',
												},
												{
													id: 'trainings',
													name: translate('Module'),
													sortable: true,
													width: '125px',
													align: 'center',
												},
											]}
											sort={{field: 'name', order: 'ASC'}}
											staticFilters={[
												{id: 'id', value: {$nin: [this.state.currentUserId]}},
												{id: 'status', value: 'enabled'},
											]}
											dynamicFilters={[
												{id: 'name', name: translate('Search'), type: 'regex'},
												{
													id: 'role',
													name: translate('Role'),
													type: 'exact',
													choices: [
														{value: '*', label: translate('All Roles')},
														{value: 'admin', label: translate('Admin')},
														{value: 'manager', label: translate('Manager')},
														{value: 'user', label: translate('User')},
													],
												},
											]}
											bulkActions={null}
											actions={null}
											rowClicked={null}
											selectedIds={!!targetManger ? [targetManger.ownerId] : []}
											selectionToggled={record => {
												this.handleManagerSelectionToggled({
													ownerId: record.id,
													id: this.state.selectedTeamId,
													ownerName: record.name ? record.name : record.userId,
												});
											}}
											stripped={false}
										/>
									</div>
								</DialogContent>
								<DialogActions>
									<Button
										color={'primary'}
										variant={'contained'}
										onClick={this.handleManagerClick}
										disabled={this.state.newTeams.length === 0}
									>
										{translate('Select Manager')}
									</Button>
								</DialogActions>
							</Dialog>
						</MuiThemeProvider>
					</div>
				</div>
			);
		});

		return (
			<div>
				{isManager &&
					this.state.teams.length > 0 &&
					!this.state.showDeleteUserDialog && (
						<MenuItem onClick={this.openAssignManagerDailog}>
							{'Delete User'}
						</MenuItem>
					)}

				{this.state.teams.length === 0 && (
					<MenuItem onClick={this.openDeleteUserDialog}>
						{translate('Delete User')}
					</MenuItem>
				)}

				<Dialog
					open={showDeleteUserDialog}
					aria-label={translate('Delete User')}
					maxWidth={false}
				>
					<DialogTitle onClose={this.closeDeleteUserDialog}>
						<Text
							style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
							label={
								this.state.showSpinner
									? translate('Deleting User')
									: translate('Delete User?')
							}
						/>
					</DialogTitle>

					<DialogContent style={{ width: "750px", padding: "24px", minHeight: "100px" }}>
						{!this.state.showSpinner ? (
							<>
								<div>
									<Typography>You are about to permanently delete<Typography color='primary' style={{ margin: "0 5px"}} component="span">{' '}{this.props.record.userId}.</Typography>Are you sure you wish to delete this user?</Typography>

									<div style={{ backgroundColor: "#ecf4f6", padding: "24px", marginTop: "10px" }}>
										<Typography style={{ fontFamily: "Montserrat Bold" }}>Important information to understand when deleting a user:</Typography>

										<ol style={{ paddingLeft: "20px" }}>
											<li><Typography>All performance data for this user will be permanently deleted and can no longer be viewed or exported within the Talespin Dashboard.</Typography></li>
											<li><Typography>The license associated with this user does not return to the current license term to be used by another user.</Typography></li>
											<li><Typography>If the same user is created again after being deleted, the newly created user will be considered a new user in the platform and will consume a new license from your current license term.</Typography></li>
											<li><Typography>If the user is a manager of a team or teams, you will be prompted to select a new manager for each team the user is currently a manager of.</Typography></li>
										</ol>
									</div>
								</div>
							</>
						) : (
							<div style={{display: 'flex', flexDirection: 'column'}}>
								<LoadingInfo text={'Deleting...'} />{' '}
								<Typography>
									This operation can take up to 40 seconds. This window can be
									closed and the operation will continue in the background.
								</Typography>
							</div>
						)}
					</DialogContent>

					<DialogActions>
						<Button
							color={'secondary'}
							variant={'contained'}
							onClick={this.closeDeleteUserDialog}
						>
							{showSpinner ? translate('Close') : translate('Cancel')}
						</Button>
						<Button
							color={'primary'}
							variant={'contained'}
							disabled={showSpinner}
							onClick={
								this.newTeams.length > 0 ? this.handleSubmit : this.handleDelete
							}
						>
							{translate('Delete')}
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					maxWidth='lg'
					onClick={e => e.stopPropagation()}
					open={this.state.assignMgrModal}
					onClose={this.handleCloseClick}
					aria-label='Deleting a Manager'
				>
					<DialogTitle onClose={this.handleCloseClick}>
						<Text style={{fontSize: '20px'}} label={translate('Delete User')} />
					</DialogTitle>
					<DialogContent>
						<Text
							style={{paddingTop: '10px'}}
							label='user_manager_for_teams'
							params={{team_count: teamsList.length}}
						/>
						<div style={{display: 'flex', flexDirection: 'row'}}>
							<div
								style={{
									width: '100%',
									alignSelf: 'flex-end',
									padding: '5px 0px 5px 0px',
									marginTop: '24px',
									backgroundColor: '#ecf4f6',
									fontWeight: 'bold',
									display: 'flex',
								}}
							>
								<Text label='disableUser.teams' variant='body2' />
								<Text
									style={{paddingLeft: '30%'}}
									label='disableUser.managers'
									variant='body2'
								/>
							</div>
						</div>
						<Create
							{...mockCreateProps}
							actions={null}
							resource='teamManagerForm'
						>
							<Form
								toolbar={null}
								onSubmit={this.onContinue}
								resource={mockCreateProps.resource}
								render={() => teamsList}
							></Form>
						</Create>
					</DialogContent>
					<DialogActions style={footerStyle}>
						<Button
							color={'primary'}
							variant={'contained'}
							onClick={this.onContinue}
							disabled={
								this.state.teams.length !== this.state.newManagerTeams.length
							}
						>
							{translate('Continue')}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withDataProvider,
	translate,
	withStyles(styles)
);

export default enhance(DeleteUserButton);
