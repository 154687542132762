import React from 'react';
import SkillPoint from './SkillPoint';
import EmbeddedShow from '../components/EmbeddedShow';

const SkillPointPanel = props => {
	const {data} = props;

	const renderSkillPoints = data => {
		var list = [];

		for (var i = 0; i < data.length; i++) {
			list.push(<SkillPoint data={data[i]} />);
		}

		return list;
	};

	return (
		<EmbeddedShow
			title={'Skills'}
			style={{flex: '1 1 1 1 auto', display: 'flex', flexDirection: 'column'}}
		>
			<div
				style={{
					flex: '1 1 auto',
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
				}}
			>
				{renderSkillPoints(data)}
			</div>
		</EmbeddedShow>
	);
};

export default SkillPointPanel;
