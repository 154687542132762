import {GET_LIST, useQueryWithStore} from 'react-admin';
import {SkillBarPanel, SkillGraphPanel} from '../..';
import React, {useEffect, useState} from 'react';

import LoadingList from '../../loading/LoadingList';
import {Typography} from '@material-ui/core';

const Skills = props => {
	const {userId, lessonId, trainingId, attemptId, attemptChangeHandler} = props;

	const [selectedAttemptData, setSelectedAttemptData] = useState(null);

	const {data: userLessonSkillStats, error, loading} = useQueryWithStore({
		type: GET_LIST,
		resource: 'UserLessonSkillStatsWithLessonAttempts',
		payload: {
			filter: {
				userId: userId,
				trainingId: trainingId,
				labId: lessonId,
			},
			pagination: {},
			sort: {},
		},
	});

	useEffect(() => {
		if (attemptId && userLessonSkillStats) {
			const targetAttemptData = userLessonSkillStats.find(
				item => item.sId === attemptId
			);

			if (targetAttemptData) {
				setSelectedAttemptData(targetAttemptData);
				return;
			}
		}
		setSelectedAttemptData(
			userLessonSkillStats && userLessonSkillStats.length > 0
				? userLessonSkillStats[0]
				: null
		);
	}, [attemptId, userLessonSkillStats]);

	return (
		<div>
			{(!userLessonSkillStats || loading) && <LoadingList />}
			{error && <Typography>No Skills Found</Typography>}
			{(userLessonSkillStats && !loading) && (
				<div>
					<SkillBarPanel data={selectedAttemptData} />

					{userLessonSkillStats.length > 1 && selectedAttemptData && (
						<SkillGraphPanel
							unprocessedData={userLessonSkillStats}
							selectedAttemptValue={selectedAttemptData.createdAt}
							fromComponentFlag={'SkillsTabView'}
							attemptChangeHandler={attemptChangeHandler}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default Skills;
