import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { createStyles, InputLabel, Typography, withStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import TSTheme from '../components/Theme';
import Button from '@material-ui/core/Button';
import CalenderIcon from './Icons/CalenderIcon';
import calenderIcon from '../assets/CalenderIcon.svg';
import clockBackground from '../assets/ClockBackground.png';
import moment from "moment";

const styles = () =>
	createStyles({
		pickerBar: {
			display: 'flex',
			flexDirection: 'row',
		},
		dateicker: {
			display: 'flex',
			width: '50%',
			marginTop: '16px',
			flexDirection: 'column',
		},
		timePicker: {
			display: 'flex',
			width: '50%',
			marginTop: '16px',
			flexDirection: 'column',
			marginLeft: '15px',
		},
		input: {
			'&:-webkit-autofill': {
				'-webkit-box-shadow': '0 0 0 100px white inset',
			},
			padding: '5px'
		},
		errorMessage: {
			margin: 0,
			fontSize: "0.75rem",
			textAlign: "left",
			fontFamily: "Montserrat Regular",
			fontWeight: 400,
			lineHeight: 1.66,
			marginLeft: '14px',
			marginRight: '14px',
			marginTop: '-8px'
		}
	});

const DatePicker = props => {
	const {
		onTimeChange,
		onDateChange,
		sendWelcomeType,
		handleWelcomeTypeChange,
		dateValue,
		timeValue,
		classes,
		onDateTimeValidityChange
	} = props;

	const [isDateTimeFuture, setIsDateTimeFuture] = useState({ isTimeFuture: true, isDateFuture: true });

	const isValidDate = (value) => {
		let isValid = true;
		if (Object.prototype.toString.call(value) === "[object Date]") {
			if (isNaN(value)) {
				isValid = false;
			} else {
				isValid = true;
			}
		} else {
			isValid = false;
		}
		return isValid;
	}

	useEffect(() => {
		const intervalID = setInterval(() => {
			const dateTimeValidity = isFutureDate(dateValue, timeValue);
			if ((dateTimeValidity.isDateFuture !== isDateTimeFuture.isDateFuture) || (dateTimeValidity.isTimeFuture !== isDateTimeFuture.isTimeFuture)) {
				setIsDateTimeFuture({ isDateFuture: dateTimeValidity.isDateFuture, isTimeFuture: dateTimeValidity.isTimeFuture });
				onDateTimeValidityChange(!(dateTimeValidity.isDateFuture && dateTimeValidity.isTimeFuture));
			}
		}, 500);

		return () => clearInterval(intervalID);
	}, [timeValue, dateValue, isDateTimeFuture]);

	const isFutureDate = (date, time) => {
		let dateIsTodayorFuture = false;

		const d_now = new Date();
		const d_inp = new Date(date);
		dateIsTodayorFuture = d_now.setHours(0, 0, 0, 0) <= d_inp.setHours(0, 0, 0, 0);

		if (dateIsTodayorFuture) {
			const timeString = time.toString();
			const timeSliced = timeString.slice(15, timeString.length);
			const dateSliced = new Date().toString().slice(0, 15);
			const value = new Date(dateSliced + timeSliced);
			const timeNow = new Date();
			const isTimeFuture = (timeNow.getTime() > value.getTime()) && (moment(dateValue).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) ? false : true
			return {
				isDateFuture: true,
				isTimeFuture: isTimeFuture
			}
		} else {
			return {
				isDateFuture: false,
				isTimeFuture: true
			}
		}
	}

	const renderText = () => {
		return (
			<div>
				<div>
					<Button
						color={sendWelcomeType === 'sendNow' ? 'primary' : 'secondary'}
						variant={'contained'}
						onClick={() => handleWelcomeTypeChange('sendNow')}
						style={{ height: '28px' }}
					>
						Send Now
					</Button>

					<Button
						variant={'contained'}
						color={sendWelcomeType === 'schedule' ? 'primary' : 'secondary'}
						onClick={() => handleWelcomeTypeChange('schedule')}
						style={{ height: '28px' }}
					>
						Schedule a Time
					</Button>
				</div>
				{sendWelcomeType === 'schedule' && (
					<div className={classes.pickerBar}>
						<div className={classes.dateicker}>
							<InputLabel>Date</InputLabel>
							<ThemeProvider theme={CustomTheme}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant='inline'
										format='MM/dd/yyyy'
										margin='normal'
										id='date-picker-inline'
										value={dateValue}
										onChange={onDateChange}
										inputVariant={'outlined'}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										keyboardIcon={<CalenderIcon />}
										error={!isValidDate(dateValue) || !isDateTimeFuture.isDateFuture}
										inputProps={{ className: classes.input }}
										FormHelperTextProps
									/>
									{isValidDate(dateValue) && !isDateTimeFuture.isDateFuture && <Typography color="error" className={classes.errorMessage}>Date must not be in the past</Typography>}
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</div>
						<div className={classes.timePicker}>
							<InputLabel>Time</InputLabel>
							<ThemeProvider theme={CustomTheme}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardTimePicker
										margin='normal'
										id='time-picker-inline'
										variant='inline'
										style={{ marginLeft: '4px' }}
										value={timeValue}
										onChange={onTimeChange}
										inputVariant={'outlined'}
										KeyboardButtonProps={{
											'aria-label': 'change time',
										}}
										error={!isValidDate(timeValue) || !isDateTimeFuture.isTimeFuture}
										keyboardIcon={<CalenderIcon />}
										inputProps={{ className: classes.input }}
									/>
									{isValidDate(timeValue) && !isDateTimeFuture.isTimeFuture && <Typography color="error" className={classes.errorMessage}>Time must not be in the past</Typography>}
								</MuiPickersUtilsProvider>
							</ThemeProvider>
						</div>
					</div>
				)}
			</div>
		);
	};

	return renderText();
};

export const CustomTheme = createTheme({
	...TSTheme,
	overrides: {
		MuiOutlinedInput: {
			adornedEnd: {
				paddingRight: '0px',
			},
			root: {
				height: '28px',
			},
		},
		MuiFormControl: {
			root: {
				margin: '16p',
			},
		},
		MuiSvgIcon: {
			root: {
				color: TSTheme.palette.primary.main,
			},
		},
		MuiPickersCalendar: {
			transitionContainer: {
				marginTop: '10px',
				minHeight: '218px',
			},
		},
		MuiPickersCalendarHeader: {
			position: 'absolute',
			daysHeader: {
				paddingTop: '40px',
			},
			dayLabel: {
				color: TSTheme.palette.primary.main,
			},
			switchHeader: {
				justifyContent: 'flex-end',
				margin: '4px 10px !important',
				borderBottom: '2px solid #ccc',
			},
			transitionContainer: {
				width: '100%',
				height: '55px',
				overflow: 'unset',
				position: 'absolute',
				'&:before': {
					content: "'Calendar'",
					position: 'absolute',
					left: '20px',
					top: '17px',
					fontWeight: 'bold',
					fontSize: '18px'
				},
				'&:after': {
					content: "''",
					position: 'absolute',
					right: '8px',
					top: '53px',
					zIndex: '50',
					width: '32px',
					height: '26px',
					backgroundImage: `url(${calenderIcon})`,
					backgroundSize: '100% auto',
					backgroundRepeat: 'no-repeat',
				},
			},
		},
		MuiPickersDay: {
			daySelected: {
				borderRadius: '2px'
			}
		},
		MuiTypography: {
			alignCenter: {
				textAlign: 'left',
				paddingLeft: '20px',
				top: '60px !important',
				fontWeight: 'bold',
			},
			h2: {
				fontSize: '24px !important',
				fontWeight: 'bold',
				padding: '0.5px 8px',
			},
		},
		MuiPickersClockPointer: {
			animateTransform: {
				height: '38% !important',
			},
			pointer: {
				height: '38% !important',
			},
		},
		MuiPickersClockNumber: {
			clockNumber: {
				fontWeight: 'bolder',
			},
		},
		MuiPickersClock: {
			clock: {
				backgroundImage: `url(${clockBackground})`,
				backgroundSize: '100% auto',
				backgroundRepeat: 'no-repeat',
				backgroundColor: 'none !important',
				width: '275px',
				height: '275px',
				paddingTop: '8px',
			},
		},
		MuiPickersTimePickerToolbar: {
			ampmLabel: {
				fontSize: '12px',
				fontWeight: 'bold',
				padding: '4px 15px',
			},
			ampmSelection: {
				flexDirection: 'row',
			},
			hourMinuteLabel: {
				marginLeft: '-20px',
				fontSize: '16px',
			},
			toolbarAmpmLeftPadding: {
				paddingLeft: '!important',
			},
			separator: {
				margin: '!important',
				padding: '0.5px 2px !important',
			},
		},
		MuiPickersToolbar: {
			toolbar: {
				height: '!important',
				backgroundColor: '!important',
				borderBottom: '2px solid #ccc',
				margin: '4px 10px !important',
				justifyContent: 'space-between',
			},
		},
		MuiPickersToolbarText: {
			toolbarTxt: {
				color: '!important',
				borderRadius: '2px',
				backgroundColor: TSTheme.palette.secondary.main,
			},
			toolbarBtnSelected: {
				color: '#fff !important',
				borderRadius: '2px',
				backgroundColor: TSTheme.palette.primary.main,
			},
		},
	},
});

export default withStyles(styles)(DatePicker);
