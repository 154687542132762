import React from 'react';
import {EmbeddedList} from 'components';
import {NumberField, useTranslate} from 'react-admin';

import {MenuItem} from '@material-ui/core';
import PercentField from 'components/PercentField';
import SkillFilter from 'components/SkillFilter';

const LabList = props => {
	const {
		selectedTrainingRecord,
		labSelected,
		selectedSkills,
		skillsSelected,
		teamData,
	} = props;

	const translate = useTranslate();

	return (
		<EmbeddedList
			embedded={false}
			title={translate('Labs')}
			resource={'listLabsByTeamTrainingRunway'}
			columns={[
				{
					id: 'name',
					name: translate('Lab Name'),
					sortable: true,
				},
				{
					id: 'attempts',
					name: translate('Attempts'),
					sortable: true,
					align: 'center',
					render: record => <NumberField record={record} source='attempts' />,
					isInverted: true,
				},
				{
					id: 'completion',
					name: translate('Avg Completion Rate'),
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='completion' />
					),
				},
				{
					id: 'score',
					name: translate('Avg Score'),
					sortable: true,
					align: 'center',
					render: record => <PercentField record={record} source='score' />,
					isInverted: true,
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[
				{id: 'trainingId', value: selectedTrainingRecord.id},
				{id: 'teamId', value: teamData.id},
			]}
			dynamicFilters={[
				{
					id: 'namedSkills',
					value:
						selectedSkills.length > 0
							? {$in: selectedSkills.map(skill => skill.name)}
							: undefined,
					render: () => (
						<SkillFilter
							onChange={skills => {
								skillsSelected(skills);
							}}
							preSelectedSkills={
								selectedSkills.length ? selectedSkills : undefined
							}
							skillListContextData={{
								trainingId: selectedTrainingRecord.id,
								teamId: teamData.id,
							}}
						/>
					),
				},
			]}
			bulkActions={[
				{
					id: 'addTraining',
				},
			]}
			actions={[
				{
					render: record => (
						<MenuItem key={'View Lab Detail'}>
							<div onClick={() => labSelected(record)}>
								{translate('View Lab Detail')}
							</div>
						</MenuItem>
					),
				},
			]}
			rowClicked={record => labSelected(record)}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default LabList;
