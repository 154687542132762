import React from 'react';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';

import classnames from "classnames";

import { Button, Checkbox, MenuItem, Select, Typography } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
// import HelpIcon from '@material-ui/icons/Help';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { cloneDataOptions, getFilterOption, getDetailOption, getGroupOption, getHeatmapOption } from '../utils/options';

export interface DataOptions {
    filter: Array<FilterOption> | null,
    group: GroupOption | null,
    sort: SortOption | null,
    heatmap: HeatmapOption | null,
    detail: Array<DetailOption> | null,
    other: OtherOptions | null
}

export interface FilterOption {
    id: string,
    label: string,
    value?: boolean | null,
    children?: Array<FilterOption> | null
}

export interface GroupOption {
    id: string,
    options: Array<{ value: string, label: string }>,
    value: string,
    children?: Array<GroupOption> | null
}

export interface SortOption {
    id: string,
    options: Array<{ value: string, label: string, hidden?: (options: DataOptions) => boolean }>,
    value: string
}

export interface HeatmapOption {
    id: string,
    options: Array<{ value: string, label: string }>,
    value: string,
    children?: Array<HeatmapOption> | null
}

export interface DetailOption {
    id: string,
    label: string,
    value?: boolean | null,
    children?: Array<DetailOption> | null
}

export interface OtherOptions {
    showOtherOptions: boolean,
    hideEmptyGroups: boolean,
    groupsPerRow: number,
    groupsPerRowOptions: Array<number>,
    itemsPerRow: number,
    itemsPerRowOptions: Array<number>
}

export interface DataParams {
    collapsed: boolean,
    expandedFilter: boolean,
    expandedFilterOptions: Array<string>,
    expandedGroup: boolean,
    expandedSort: boolean,
    expandedHeatmap: boolean,
    expandedDetail: boolean,
    expandedDetailOptions: Array<string>,
    expandedOther: boolean
}

interface PROPS {
    defaultOptions: DataOptions,
    options?: DataOptions | null,
    params: DataParams,
    onOptionsChanged: (options: DataOptions) => void,
    onParamsChanged: (params: DataParams) => void,
    classes?: any
}

interface STATE {
    options: DataOptions
}

class OptionsBlade extends React.Component<PROPS, STATE> {
    state = {
        options: {
            filter: null,
            group: null,
            sort: null,
            heatmap: null,
            detail: null,
            other: null
        } as DataOptions
    };

    componentDidMount() {
        const { defaultOptions, options } = this.props;

        this.setState({ options: options ? cloneDataOptions(options) : cloneDataOptions(defaultOptions) });
    }

    componentDidUpdate(prevProps: PROPS) {
        const { defaultOptions, options } = this.props;

        if (prevProps.defaultOptions !== defaultOptions || prevProps.options !== options) {
            this.setState({ options: options ? cloneDataOptions(options) : cloneDataOptions(defaultOptions) });
        }
    }

    handleToggleCollapsed = () => {
        const { params, onParamsChanged } = this.props;

        const updatedParams = Object.assign({} as DataParams, params);

        updatedParams.collapsed = !updatedParams.collapsed;

        onParamsChanged(updatedParams);
    }

    handleHelpClicked = () => {
        console.log("Help Clicked");
    }

    handleDefaultsClicked = () => {
        const { defaultOptions } = this.props;

        this.setState({ options: cloneDataOptions(defaultOptions) }, () => {
            this.handleOptionsChanged();
        });
    }

    handleOptionsChanged = () => {
        const { options } = this.state;

        this.props.onOptionsChanged(cloneDataOptions(options));
    }

    handleToggleFilterExpanded = () => {
        const { params, onParamsChanged } = this.props;

        const updatedParams = Object.assign({} as DataParams, params);

        updatedParams.expandedFilter = !updatedParams.expandedFilter;

        onParamsChanged(updatedParams);
    }

    handleToggleFilterOptionExpanded = (id: string) => {
        const { params, onParamsChanged } = this.props;

        const updatedParams = Object.assign({} as DataParams, params);

        if (updatedParams.expandedFilterOptions.includes(id)) {
            updatedParams.expandedFilterOptions = updatedParams.expandedFilterOptions.filter(item => item !== id);
        } else {
            updatedParams.expandedFilterOptions = [...updatedParams.expandedFilterOptions, id];
        }

        onParamsChanged(updatedParams);
    }

    expandFilterOption = (id: string) => {
        const { params, onParamsChanged } = this.props;

        const ids = id.split('.');

        if (ids.length > 0) {
            const updatedParams = Object.assign({} as DataParams, params);

            updatedParams.expandedFilter = true;
            updatedParams.expandedFilterOptions = [...updatedParams.expandedFilterOptions];

            let currentId = null;

            for (let x = 0; x < ids.length; x++) {
                if (currentId != null) currentId = currentId + '.' + ids[x];
                else currentId = ids[0];

                if (!updatedParams.expandedFilterOptions.includes(currentId)) updatedParams.expandedFilterOptions.push(currentId);
            }

            onParamsChanged(updatedParams);
        }
    }

    handleToggleGroupExpanded = () => {
        const { params, onParamsChanged } = this.props;

        const updatedParams = Object.assign({} as DataParams, params);

        updatedParams.expandedGroup = !updatedParams.expandedGroup;

        onParamsChanged(updatedParams);
    }

    handleToggleSortExpanded = () => {
        const { params, onParamsChanged } = this.props;

        const updatedParams = Object.assign({} as DataParams, params);

        updatedParams.expandedSort = !updatedParams.expandedSort;

        onParamsChanged(updatedParams);
    }

    handleToggleHeatmapExpanded = () => {
        const { params, onParamsChanged } = this.props;

        const updatedParams = Object.assign({} as DataParams, params);

        updatedParams.expandedHeatmap = !updatedParams.expandedHeatmap;

        onParamsChanged(updatedParams);
    }

    handleToggleDetailExpanded = () => {
        const { params, onParamsChanged } = this.props;

        const updatedParams = Object.assign({} as DataParams, params);

        updatedParams.expandedDetail = !updatedParams.expandedDetail;

        onParamsChanged(updatedParams);
    }

    handleToggleDetailOptionExpanded = (id: string) => {
        const { params, onParamsChanged } = this.props;

        const updatedParams = Object.assign({} as DataParams, params);

        if (updatedParams.expandedDetailOptions.includes(id)) {
            updatedParams.expandedDetailOptions = updatedParams.expandedDetailOptions.filter(item => item !== id);
        } else {
            updatedParams.expandedDetailOptions = [...updatedParams.expandedDetailOptions, id];
        }

        onParamsChanged(updatedParams);
    }

    handleToggleOtherExpanded = () => {
        const { params, onParamsChanged } = this.props;

        const updatedParams = Object.assign({} as DataParams, params);

        updatedParams.expandedOther = !updatedParams.expandedOther;

        onParamsChanged(updatedParams);
    }

    handleFilterOptionValueChanged = (id: string, value: boolean) => {
        const updatedOptions = cloneDataOptions(this.state.options);

        const targetFilterOption = updatedOptions.filter ? getFilterOption(updatedOptions.filter, id) : null;

        if (targetFilterOption) {
            targetFilterOption.value = value;
        }

        this.setState({ options: updatedOptions }, () => {
            this.handleOptionsChanged();
        });
    }

    handleFilterOptionToggleAll = (id: string) => {
        const updatedOptions = cloneDataOptions(this.state.options);

        const targetFilterOption = updatedOptions.filter ? getFilterOption(updatedOptions.filter, id) : null;

        let areAllChildrenChecked = true;

        if (targetFilterOption && targetFilterOption.children && targetFilterOption.children.length > 0) {
            targetFilterOption.children.forEach(child => {
                if (child.value === false) areAllChildrenChecked = false;
            });

            targetFilterOption.children.forEach(child => {
                child.value = !areAllChildrenChecked;
            });

            this.setState({ options: updatedOptions }, () => {
                this.handleOptionsChanged();
            });
        }
    }

    handleGroupOptionValueChanged = (id: string, value: string) => {
        const updatedOptions = cloneDataOptions(this.state.options);

        const targetGroupOption = updatedOptions.group ? getGroupOption([updatedOptions.group], id) : null;

        if (targetGroupOption) {
            targetGroupOption.value = value;
        }

        this.setState({ options: updatedOptions }, () => {
            this.handleOptionsChanged();
        });
    }

    handleSortOptionValueChanged = (value: string) => {
        const updatedOptions = cloneDataOptions(this.state.options);

        if (updatedOptions.sort) {
            updatedOptions.sort.value = value;
        }

        this.setState({ options: updatedOptions }, () => {
            this.handleOptionsChanged();
        })
    }

    handleHeatmapOptionValueChanged = (id: string, value: string) => {
        const updatedOptions = cloneDataOptions(this.state.options);

        const targetHeatmapOption = updatedOptions.heatmap ? getHeatmapOption([updatedOptions.heatmap], id) : null;

        if (targetHeatmapOption) {
            targetHeatmapOption.value = value;
        }

        this.setState({ options: updatedOptions }, () => {
            this.handleOptionsChanged();
        });
    }

    handleDetailOptionValueChanged = (id: string, value: boolean) => {
        const updatedOptions = cloneDataOptions(this.state.options);

        const targetDetailOption = updatedOptions.detail ? getDetailOption(updatedOptions.detail, id) : null;

        if (targetDetailOption) {
            targetDetailOption.value = value;
        }

        this.setState({ options: updatedOptions }, () => {
            this.handleOptionsChanged();
        })
    }

    handleOtherOptionValueChanged = (id: string, value: number | boolean) => {
        const updatedOptions = cloneDataOptions(this.state.options);

        if (updatedOptions.other) {
            updatedOptions.other[id] = value;
        }

        this.setState({ options: updatedOptions }, () => {
            this.handleOptionsChanged();
        });
    }

    renderFilterOption(parentId: string | null, option: FilterOption) {
        const { classes , params } = this.props;

        const { expandedFilterOptions } = params;

        const isExpandable = option.children != null;
        const isCheckable = option.value != null;
        const isExpanded = isExpandable && expandedFilterOptions.includes(parentId ? parentId + '.' + option.id : option.id);

        let areChildrenCheckable = false;
        if (option.children && option.children.length > 0) {
            option.children.forEach(child => {
                if (child.value != null) areChildrenCheckable = true;
            });
        }

        let areChildrenAllChecked = areChildrenCheckable ? true : false;
        if (option.children && option.children.length > 0) {
            option.children.forEach(child => {
                if (child.value != null && child.value === false) areChildrenAllChecked = false;
            });
        }


        return (
            <div key={parentId ? parentId + '.' + option.id : option.id} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
                <div
                    className={classes.option}
                    style={{ cursor: isExpandable || isCheckable ? 'pointer' : 'default' }}
                    onClick={() => {
                        if (isExpandable) this.handleToggleFilterOptionExpanded(parentId ? parentId + '.' + option.id : option.id);
                        else {
                            if (isCheckable) this.handleFilterOptionValueChanged(parentId ? parentId + '.' + option.id : option.id, !option.value);
                        }
                    }}
                >
                    {isCheckable &&
                        <Checkbox
                            className={classes.checkbox}
                            size={'small'}
                            checked={option.value as boolean}
                            onChange={(e) => this.handleFilterOptionValueChanged(parentId ? parentId + '.' + option.id : option.id, e.target.checked)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }

                    <Typography noWrap={true} style={{ fontSize: 14, fontWeight: 'bold', color: isCheckable && !option.value ? 'lightgrey' : undefined }}>{option.label}</Typography>

                    {isExpandable && !isExpanded &&
                        <ArrowRightIcon style={{ width: 32, height: 32 }} />
                    }

                    {isExpandable && isExpanded &&
                        <ArrowDropDownIcon style={{ width: 32, height: 32 }} />
                    }
                </div>

                {isExpanded && option.children &&
                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                        {areChildrenCheckable && option.children.length > 1 &&
                            <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 9 }}>
                                <Button
                                    color={'secondary'}
                                    variant={'outlined'}
                                    style={{ height: 20, width: '100%' }}
                                    onClick={(e) => { e.stopPropagation(); this.handleFilterOptionToggleAll(parentId ? parentId + '.' + option.id : option.id); }}
                                >{areChildrenAllChecked ? 'Deselect All' : 'Select All'}</Button>
                            </div>
                        }

                        {option.children.map(child => this.renderFilterOption(parentId ? parentId + '.' + option.id : option.id, child))}
                    </div>
                }
            </div>
        );
    }

    renderGroupOption(parentId: string | null, option: GroupOption) {
        const { classes } = this.props;

        return (
            <div key={parentId ? parentId + '.' + option.id : option.id} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none', marginLeft: 16, marginRight: !parentId ? 16 : 0 }}>
                <Select className={classes.select} disabled={option.options.length < 2} value={option.value} displayEmpty={true} onChange={(e) => this.handleGroupOptionValueChanged(parentId ? parentId + '.' + option.id : option.id, e.target.value as string)}>
                    {option.options.map((item, idx) => <MenuItem key={idx} className={classes.selectOption} value={item.value}>{item.label}</MenuItem>)}
                </Select>

                {option.value && option.value !== "" && option.children &&
                    <div id={parentId ? parentId + '.' + option.id : option.id} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none', marginTop: 4 }}>
                        {option.children.filter(item => item.id === option.value).map(item => this.renderGroupOption(parentId ? parentId + '.' + option.id : option.id, item))}
                    </div>
                }
            </div>
        );
    }

    renderSortOption(option: SortOption) {
        const { classes } = this.props;
        const { options } = this.state;

        return (
            <div key={option.id} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none', marginLeft: 16, marginRight: 16 }}>
                <Select className={classes.select} disabled={option.options.length < 2} value={option.value} displayEmpty={true} onChange={(e) => this.handleSortOptionValueChanged(e.target.value as string)}>
                    {option.options.map((item, idx) => item && (item.hidden == null || !item.hidden(options)) ? <MenuItem key={idx} className={classes.selectOption} value={item.value}>{item.label}</MenuItem> : null)}
                </Select>
            </div>
        );
    }

    renderHeatmapOption(parentId: string | null, option: HeatmapOption) {
        const { classes } = this.props;

        return (
            <div key={parentId ? parentId + '.' + option.id : option.id} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none', marginLeft: 16, marginRight: !parentId ? 16 : 0 }}>
                <Select className={classes.select} disabled={option.options.length < 2} value={option.value} displayEmpty={true} onChange={(e) => this.handleHeatmapOptionValueChanged(parentId ? parentId + '.' + option.id : option.id, e.target.value as string)}>
                    {option.options.map((item, idx) => <MenuItem key={idx} className={classes.selectOption} value={item.value}>{item.label}</MenuItem>)}
                </Select>

                {option.value && option.children &&
                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none', marginTop: 4 }}>
                        {option.children.filter(item => item.id === option.value).map(item => this.renderHeatmapOption(parentId ? parentId + '.' + option.id : option.id, item))}
                    </div>
                }
            </div>
        );
    }

    renderDetailOption(parentId: string | null, option: DetailOption) {
        const { classes, params } = this.props;

        const { expandedDetailOptions } = params;

        const isExpandable = option.children != null;
        const isCheckable = option.value != null;
        const isExpanded = isExpandable && expandedDetailOptions.includes(parentId ? parentId + '.' + option.id : option.id);

        return (
            <div key={parentId ? parentId + '.' + option.id : option.id} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
                <div className={classes.option} style={{ cursor: isExpandable ? 'pointer' : 'default' }} onClick={() => { if (isExpandable) this.handleToggleDetailOptionExpanded(parentId ? parentId + '.' + option.id : option.id); }}>
                    {isCheckable &&
                        <Checkbox
                            className={classes.checkbox}
                            style={{ marginLeft: 8 }}
                            size={'small'}
                            checked={option.value as boolean}
                            onChange={(e) => this.handleDetailOptionValueChanged(parentId ? parentId + '.' + option.id : option.id, e.target.checked)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    }

                    <Typography noWrap={true} style={{ fontSize: 14, fontWeight: 'bold', color: isCheckable && !option.value ? 'lightgrey' : undefined }}>{option.label}</Typography>

                    {isExpandable && !isExpanded &&
                        <ArrowRightIcon style={{ width: 32, height: 32 }} />
                    }

                    {isExpandable && isExpanded &&
                        <ArrowDropDownIcon style={{ width: 32, height: 32 }} />
                    }
                </div>

                {isExpanded && option.children &&
                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                        {option.children.map(child => this.renderDetailOption(parentId ? parentId + '.' + option.id : option.id, child))}
                    </div>
                }
            </div>
        );
    }

    renderOtherOptions = (option: OtherOptions) => {
        const { classes } = this.props;

        return (
            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
                {/* Hide Empty Groups */}
                <div className={classes.option} style={{ cursor: 'default', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography noWrap={true} style={{ fontSize: 14, fontWeight: 'bold' }}>Hide Empty Groups</Typography>

                    <Checkbox
                        className={classes.checkbox}
                        style={{ marginLeft: 8 }}
                        size={'small'}
                        checked={option.hideEmptyGroups as boolean}
                        onChange={(e) => this.handleOtherOptionValueChanged('hideEmptyGroups', e.target.checked)}
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>

                {/* Groups Per Row */}
                {option.groupsPerRowOptions && option.groupsPerRowOptions.length > 0 &&
                    < div className={classes.option} style={{ cursor: 'default', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography noWrap={true} style={{ fontSize: 14, fontWeight: 'bold' }}>Max Groups Per Row</Typography>

                        <Select className={classes.select} style={{ minWidth: 64, maxWidth: 64 }} disabled={option.groupsPerRowOptions.length < 2} value={option.groupsPerRow} displayEmpty={false} onChange={(e) => this.handleOtherOptionValueChanged('groupsPerRow', Number.parseInt(e.target.value as string))}>
                            {option.groupsPerRowOptions.map((item, idx) => <MenuItem key={idx} className={classes.selectOption} value={'' + item}>{item}</MenuItem>)}
                        </Select>
                    </div>
                }

                {/* Items Per Row */}
                {option.itemsPerRowOptions && option.itemsPerRowOptions.length > 0 &&
                    <div className={classes.option} style={{ cursor: 'default', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography noWrap={true} style={{ fontSize: 14, fontWeight: 'bold' }}>Max Items Per Row</Typography>

                        <Select className={classes.select} style={{ minWidth: 64, maxWidth: 64 }} disabled={option.itemsPerRowOptions.length < 2} value={option.itemsPerRow} displayEmpty={false} onChange={(e) => this.handleOtherOptionValueChanged('itemsPerRow', Number.parseInt(e.target.value as string))}>
                            {option.itemsPerRowOptions.map((item, idx) => <MenuItem key={idx} className={classes.selectOption} value={'' + item}>{item}</MenuItem>)}
                        </Select>
                    </div>
                }
            </div>
        );
    }

    render() {
        const { classes, params } = this.props;
        const { options } = this.state;

        const { collapsed, expandedFilter, expandedGroup, expandedSort, expandedHeatmap, expandedDetail, expandedOther } = params;

        return (
            <div className={classnames({ [classes.root]: true, [classes.collapsed]: collapsed })}>
                <div className={classes.header}>
                    <MenuIcon style={{ width: 28, height: 28, marginRight: 10, cursor: 'pointer', color: '#346dec' }} onClick={this.handleToggleCollapsed} />

                    {!collapsed &&
                        <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {/* <Typography style={{ flex: '1 1 auto', textAlign: 'left', fontSize: '18px', fontWeight: 'bold' }}>Data</Typography> */}

                            <Button className={classes.resetDefaultsButton} onClick={this.handleDefaultsClicked}>Defaults</Button>

                            <Typography style={{ width: 20, height: 20, marginLeft: 10, textAlign: 'center', borderRadius: 10, border: '1px solid #FFFFFF', overflow: 'hidden', cursor: 'pointer', backgroundColor: '#252734', color: '#FFFFFF', marginRight: '5px' }} onClick={this.handleHelpClicked}>?</Typography>

                            {/* <HelpIcon style={{ width: 24, height: 24, marginLeft: 10, borderRadius: 12, overflow: 'hidden', cursor: 'pointer', backgroundColor: '#252734', color: '#FFFFFF', strokeWidth: '10px' }} onClick={this.handleHelpClicked} /> */}
                        </div>
                    }
                </div>

                {!collapsed &&
                    <div className={classes.content}>
                        {/* Render the 'filter' section. */}
                        {options.filter &&
                            <div id={'filter'} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
                                <div className={classes.option} style={{ cursor: 'pointer' }} onClick={() => this.handleToggleFilterExpanded()}>
                                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>Filters</Typography>
                                    {!expandedFilter && <ArrowRightIcon style={{ width: 32, height: 32 }} />}
                                    {expandedFilter && <ArrowDropDownIcon style={{ width: 32, height: 32 }} />}
                                </div>

                                {expandedFilter &&
                                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                                        {options.filter.map(child => this.renderFilterOption(null, child))}
                                    </div>
                                }
                            </div>
                        }

                        {/* Render the 'group' section. */}
                        {options.group &&
                            <div id={'group'} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
                                <div className={classes.option} style={{ cursor: 'pointer' }} onClick={() => this.handleToggleGroupExpanded()}>
                                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>Group By</Typography>
                                    {!expandedGroup && <ArrowRightIcon style={{ width: 32, height: 32 }} />}
                                    {expandedGroup && <ArrowDropDownIcon style={{ width: 32, height: 32 }} />}
                                </div>

                                {expandedGroup &&
                                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
                                        {this.renderGroupOption(null, options.group)}
                                    </div>
                                }
                            </div>
                        }

                        {/* Render the 'sort' section. */}
                        {options.sort &&
                            <div id={'sort'} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
                                <div className={classes.option} style={{ cursor: 'pointer' }} onClick={() => this.handleToggleSortExpanded()}>
                                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>Sort By</Typography>
                                    {!expandedSort && <ArrowRightIcon style={{ width: 32, height: 32 }} />}
                                    {expandedSort && <ArrowDropDownIcon style={{ width: 32, height: 32 }} />}
                                </div>

                                {expandedSort &&
                                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
                                        {this.renderSortOption(options.sort)}
                                    </div>
                                }
                            </div>
                        }

                        {/* Render the 'heatmap' section. */}
                        {options.heatmap &&
                            <div id={'heatmap'} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
                                <div className={classes.option} style={{ cursor: 'pointer' }} onClick={() => this.handleToggleHeatmapExpanded()}>
                                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>Heatmap</Typography>
                                    {!expandedHeatmap && <ArrowRightIcon style={{ width: 32, height: 32 }} />}
                                    {expandedHeatmap && <ArrowDropDownIcon style={{ width: 32, height: 32 }} />}
                                </div>

                                {expandedHeatmap &&
                                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
                                        {this.renderHeatmapOption(null, options.heatmap)}
                                    </div>
                                }
                            </div>
                        }

                        {/* Render the 'detail' section. */}
                        {options.detail &&
                            <div id={'detail'} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
                                <div className={classes.option} style={{ cursor: 'pointer' }} onClick={() => this.handleToggleDetailExpanded()}>
                                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>Details</Typography>
                                    {!expandedDetail && <ArrowRightIcon style={{ width: 32, height: 32 }} />}
                                    {expandedDetail && <ArrowDropDownIcon style={{ width: 32, height: 32 }} />}
                                </div>

                                {expandedDetail &&
                                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                                        {options.detail.map(child => this.renderDetailOption(null, child))}
                                    </div>
                                }
                            </div>
                        }

                        {/* Render the 'other' section. */}
                        {options.other && options.other.showOtherOptions &&
                            <div id={'general'} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
                                <div className={classes.option} style={{ cursor: 'pointer' }} onClick={() => this.handleToggleOtherExpanded()}>
                                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>Other</Typography>
                                    {!expandedOther && <ArrowRightIcon style={{ width: 32, height: 32 }} />}
                                    {expandedOther && <ArrowDropDownIcon style={{ width: 32, height: 32 }} />}
                                </div>

                                {expandedOther &&
                                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                                        {this.renderOtherOptions(options.other)}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

const myStyles = (theme) => createStyles({
    root: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        borderLeft: '1px solid #252734',
        backgroundColor: '#252734',
        color: '#FFFFFF',
        minWidth: 320,
        width: 320,
        maxWidth: 320,
        padding: 5,
        overflow: 'hidden'
    },
    collapsed: {
        minWidth: 42,
        width: 42,
        maxWidth: 42
    },
    header: {
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10
    },
    content: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 20,
        overflow: 'auto'
    },
    resetDefaultsButton: {
        width: 100,
        height: 32,
        backgroundColor: '#252734',
        color: '#FFFFFF',
        border: '1px solid #FFFFFF',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: '#252734',
            color: '#FFFFFF',
        }
    },
    option: {
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 38
    },
    checkbox: {
        '& svg': {
            color: '#FFFFFF'
        }
    },
    select: {
        backgroundColor: '#FFFFFF',
        color: '#4b5162',
        '& > div:focus': {
            backgroundColor: '#FFFFFF',
            color: '#4b5162'
        },
        '& > svg': {
            backgroundColor: '#FFFFFF',
            color: '#4b5162'
        }
    },
    selectOption: {
        color: '#4b5162'
    }
});

const enhance = compose(withStyles(myStyles));

export default enhance(OptionsBlade);
