import * as React from 'react';

import {createTheme, createStyles, MuiThemeProvider, withStyles} from '@material-ui/core/styles';

import SideMenu from './SideMenu';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {setSidebarVisibility} from 'react-admin';
import {withRouter} from 'react-router';

const myStyles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'row',
			height: '100vh',
			width: '100vw',
			// backgroundColor: theme.palette.background.default,
			color: theme.palette.color.default,
		},
		sideMenu: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: 'red',
			color: theme.palette.color.sideMenu,
		},
		content: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'auto',
			// backgroundImage: theme.palette.backgroundImage.content,
			backgroundColor: '#ECF4F6',
			// color: theme.palette.color.sideMenu.content,
			paddingLeft: '15px',
			paddingRight: '15px',
			paddingBottom: '30px',
		},
	});

class CustomLayout extends React.Component {
	componentDidMount() {
		this.props.setSidebarVisibility(true);
	}

	render() {
		const {children, classes, open, logout} = this.props;

		return (
			<div className={classes.root}>
				<SideMenu open={open} className={classes.sideMenu} logout={logout} />

				<main id={'main'} className={classes.content}>{children}</main>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	open: state.admin.ui.sidebarOpen,
});

const EnhancedCustomLayout = compose(
	connect(
		mapStateToProps,
		{setSidebarVisibility} // Avoid connect passing dispatch in props
	),
	withRouter,
	withStyles(myStyles)
)(CustomLayout);

class CustomLayoutWithTheme extends React.Component {
	constructor(props) {
		super(props);
		this.theme = createTheme(props.theme);
	}

	render() {
		const {theme, ...rest} = this.props;

		//TODO[Jon]. Add debug border
		//	<div style={{borderTop: isDev ? '2px red solid' : 'none'}}>

		return (
			<div>
				<MuiThemeProvider theme={this.theme}>
					<EnhancedCustomLayout {...rest} />
				</MuiThemeProvider>
			</div>
		);
	}
}

export default CustomLayoutWithTheme;
