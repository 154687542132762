import React, {} from 'react';
import {useTranslate} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import compose from 'recompose/compose';
import style from './style';
import {withStyles} from '@material-ui/core/styles';
import PanelInfo from '../../components/Panels/PanelInfo';
import LoadingInfo from '../../components/loading/LoadingInfo';
import PanelInfoPlaceholder from '../../components/Panels/PanelInfoPlaceholder';
import ResourceRedirect from '../../components/ResourceRedirect';

const TopRecentGraduationsPanel = (props) => {
	const translate = useTranslate();

	const { topRecentGraduations, classes, error } = props;

	const hasItems = topRecentGraduations && topRecentGraduations.length > 0;

	const title = translate('Top Recent Completions');

	if (hasItems) {
		return (
			<PanelInfo title={title}>
				<Table stickyHeader size='small' className={classes.table}>
					<TableHead>
						<TableRow className={classes.tableHeaderRow}>
							<TableCell
								key={'userId'}
								align={'left'}
								className={classes.tableHeadCell}
							>
								{translate('User ID')}
							</TableCell>
							<TableCell
								key={'trainingName'}
								align={'left'}
								className={classes.tableHeadCell}
							>
								{translate('Training')}
							</TableCell>
							<TableCell
								key={'finalScore'}
								align={'right'}
								className={classes.tableHeadCell}
							>
								{translate('Final Score')}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{topRecentGraduations.map((row, i) => {
							return (
								<TableRow
									hover
									role='checkbox'
									tabIndex={-1}
									key={`${row.trainingName} - ${i+1}`}
									className={classes.tableBodyRow}
								>
									<TableCell
										key={'userId'}
										title={row.userId}
										align={'left'}
										className={classes.tableBodyCellOverview}
									>
										<ResourceRedirect
											id={row.id}
											value={row.userId}
											resource={'users'}
										/>
									</TableCell>
									<TableCell
										key={'trainingName'}
										title={row.trainingName}
										align={'left'}
										className={classes.tableBodyCellOverview}
									>
										{row.trainingName}
									</TableCell>
									<TableCell
										key={'finalScore'}
										align={'right'}
										className={classes.tableBodyCell}
									>
										{Math.round(row.finalScore * 100) + '%'}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</PanelInfo>
		);
	}

	if (topRecentGraduations && topRecentGraduations.length === 0) {
		return (
			<PanelInfoPlaceholder title={title}>
				You users haven't finished any training modules yet.
				<br />
				<br />
				This panel will display the top three scores by user over the last 14
				days.
			</PanelInfoPlaceholder>
		);
	}

	if (error) {
		return <PanelInfoPlaceholder title={title}>{error}</PanelInfoPlaceholder>
	}

	return (
		<PanelInfo>
			<LoadingInfo />
		</PanelInfo>
	);
};
const enhance = compose(withStyles(style));

export default enhance(TopRecentGraduationsPanel);
