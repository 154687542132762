import {loadObject, saveObject} from './Util';

export const vectorMapUserEnabled = (enabled) => {
    if (enabled != null && (typeof enabled) === 'boolean') {
        saveObject('useVectorMap', enabled);

        return enabled;
    } else {
        const useVectorMap = loadObject('useVectorMap');

        if (useVectorMap != null && (typeof useVectorMap) === 'boolean') {
            return useVectorMap;
        } else {
            return false;
        }
    }
}

export const vectorMapSystemEnabled = (context, type) => {
    const runwayOrgConfig = loadObject('runwayOrgConfig');

    const config = runwayOrgConfig && runwayOrgConfig.config ? runwayOrgConfig.config : null;

    let enabled = false;

    if (config) {
        if (config.enableVectorMapDisplays === 'true') {
            switch (type) {
                case 'catalog-map':
                    if (config.enableCatalogMapView === 'true') {
                        switch (context) {
                            case 'org':
                                enabled = true;

                                break;
                            case 'team':
                                enabled = true;

                                break;
                            case 'user':
                                enabled = true;

                                break;
                            default:
                                // Do nothing.
                        }
                    }

                    break;
                case 'training-map':
                    if (config.enableTrainingMapView === 'true') {
                        switch (context) {
                            case 'org':
                                enabled = false;

                                break;
                            case 'team':
                                enabled = false;

                                break;
                            case 'user':
                                enabled = false;

                                break;
                            default:
                                // Do nothing
                        }
                    }

                    break;
                case 'lesson-map':
                    if (config.enableLessonMapView === 'true') {
                        switch (context) {
                            case 'org':
                                enabled = false;

                                break;
                            case 'team':
                                enabled = false;

                                break;
                            case 'user':
                                enabled = false;

                                break;
                            default:
                                // Do nothing.
                        }
                    }

                    break;
                case 'skill-map':
                    if (config.enableSkillsMapView === 'true') {
                        switch (context) {
                            case 'org':
                                enabled = true;

                                break;
                            case 'team':
                                enabled = true;

                                break;
                            case 'user':
                                enabled = true;

                                break;
                            default:
                                // Do nothing.
                        }
                    }

                    break;
                default:
                // Do nothing.
            }
        }
    }

    return enabled;
}
