import React from 'react';
import TSTheme from '../Theme';

const Footer = ({children}) => {
	return (
		<div
			style={{
				alignSelf: 'center',
				textAlign: 'center',
				background: TSTheme.palette.background.contained,
				minHeight: '16px',
				padding: '8px',
				width: '100%',
				borderBottomRightRadius: '4px',
				borderBottomLeftRadius: '4px',
			}}
		>
			{children}
		</div>
	);
};

export default Footer;
