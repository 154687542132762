import {EmbeddedList, StatusChip, TimeSpanField} from 'components';

import {MenuItem} from '@material-ui/core';
import PercentField from 'components/PercentField';
import React from 'react';
import {useTranslate} from 'react-admin';
import SkillFilter from '../../../SkillFilter';

const StepList = props => {
	const {
		userData,
		selectedTrainingRecord,
		selectedLab,
		stepSelected,
		selectedSkills,
		skillsSelected,
		attemptId,
	} = props;

	const translate = useTranslate();

	return (
		<EmbeddedList
			embedded={false}
			subTitle
			title={translate('Step Progress')}
			resource='listLessonsForUserLabsRunway'
			columns={[
				{
					id: 'name',
					name: translate('Step Name'),
					sortable: true,
				},
				{
					id: 'status',
					name: translate('Status'),
					sortable: true,
					align: 'center',
					render: record => <StatusChip record={record} source='status' />,
					isInverted: true,
				},
				{
					id: 'totalTimeSec',
					name: translate('time'),
					sortable: true,
					align: 'center',
					render: record => (
						<TimeSpanField record={record} source='totalTimeSec' />
					),
					isInverted: true,
				},
				{
					id: 'completion',
					name: 'Completion',
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='completion' />
					),
				},
				{
					id: 'score',
					name: translate('Score'),
					sortable: true,
					align: 'center',
					render: record => <PercentField record={record} source='score' />,
					isInverted: true,
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[
				{id: 'trainingId', value: selectedTrainingRecord.id},
				{id: 'userId', value: userData.id},
				{id: 'labId', value: selectedLab.id},
				{id: 'sId', value: attemptId},
			]}
			dynamicFilters={[
				{
					id: 'status',
					name: translate('Status'),
					type: 'exact',
					choices: [
						{value: '*', label: translate('All Statuses')},
						{value: 'not started', label: translate('Not Started')},
						{value: 'aced', label: translate('Aced')},
						{value: 'passed', label: translate('Passed')},
						{value: 'incomplete', label: translate('Incomplete')},
					],
				},
				{
					id: 'namedSkills',
					value:
						selectedSkills.length > 0
							? {$in: selectedSkills.map(skill => skill.name)}
							: undefined,
					render: () => (
						<SkillFilter
							onChange={skills => {
								skillsSelected(skills);
							}}
							preSelectedSkills={
								selectedSkills.length ? selectedSkills : undefined
							}
							skillListContextData={{
								trainingId: selectedTrainingRecord.id,
								labId: selectedLab.id,
								userId: userData.id,
							}}
						/>
					),
				},
			]}
			bulkActions={[]}
			actions={[
				{
					render: record => (
						<MenuItem key={'View Step Detail'}>
							<div onClick={() => stepSelected(record.id, null, record)}>
								{translate('View Step Detail')}
							</div>
						</MenuItem>
					),
				},
			]}
			rowClicked={record => stepSelected(record.id, null, record)}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default StepList;
