import React from 'react';
import {useTheme} from '@material-ui/core/styles';

// import userHMD from 'assets/MiniCardIcons/UserHeadset.svg';

const PanelInfoPlaceholder = ({children = null, title = null}) => {
	const theme = useTheme();

	return (
		<div
			style={{
				width: '100%',
				minHeight: '220px',
				height: '100%',
				margin: 'auto',
				backgroundColor: theme.palette.background.contained,
				border: 'none',
				borderRadius: '0px',
				padding: '16px',
				position: 'relative',
				boxShadow: '0px 5px 20px #00000026',
			}}
		>
			{title && (
				<div
					style={{
						flex: '0 0 auto',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						marginBottom: '26px',
						fontSize: '20px',
						fontFamily: 'Archivo Bold',
					}}
				>
					{title}
				</div>
			)}

			{/* <img
				style={{
					width: 'auto',
					height: '220px',
					position: 'absolute',
					right: 0,
					top: 0,
					opacity: '0.3',
				}}
				alt={'placeholder'}
				src={userHMD}
			/> */}

			<div style={{position: 'relative'}}>{children}</div>
		</div>
	);
};

export default PanelInfoPlaceholder;
