import React, {Component} from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {DialogTitle, Text} from 'components';
import {withDataProvider} from 'react-admin';
import {refreshView, translate} from 'ra-core';
import {connect} from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import LoadingStat from '../loading/LoadingStat';
import {CLEAR_USER_DATA_FOR_TRAINING} from 'services/customActions';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	withStyles,
} from '@material-ui/core';

const styles = theme => ({
	name: {
		color: theme.palette.primary.main,
	},
});

class ResetPerformanceDataButton extends Component {
	state = {
		showDialog: false,
		showSpinner: false,
	};

	handleClick = e => {
		this.setState({
			showDialog: true,
		});
	};

	handleClose = e => {
		this.setState({
			showDialog: false,
		});
	};

	handleClickReset = e => {
		const {dataProvider, id, trainingId = undefined, refreshView} = this.props;
		this.setState({
			showSpinner: true,
		});

		let payload;

		if (trainingId) {
			payload = {
				userId: id,
				trainingIds: [trainingId],
			};
		} else {
			payload = {
				userId: id,
			};
		}
		dataProvider(
			CLEAR_USER_DATA_FOR_TRAINING,
			'clearUserDataForTrainingRunway',
			payload,
			{
				onSuccess: {
					refresh: true,
					unselectAll: true,
				},
				onFailure: {
					unselectAll: true,
					notification: {level: 'warning'},
				},
			}
		).then(() => {
			this.setState({
				showDialog: false,
				showSpinner: false,
			});
			refreshView();
		});
	};

	getDialogMessage = () => {
		const {trainingName, classes, fromComponentFlag, userId} = this.props;

		switch (fromComponentFlag) {
			case 'UserList':
				return (
					<Typography>
						Reset all performance data for
						<span className={classes.name}> {userId}</span> ?
					</Typography>
				);
			case 'Traininglist':
				return (
					<Typography>
						Reset <span className={classes.name}> {trainingName} </span>
						performance data for
						<span className={classes.name}> {userId}</span> ?
					</Typography>
				);
			default:
			// do nothing
		}
	};

	render() {
		const {translate} = this.props;
		const {showDialog, showSpinner} = this.state;

		return (
			<div>
				<MenuItem onClick={this.handleClick}>
					{translate('Reset Performance Data')}
				</MenuItem>
				<Dialog
					open={showDialog}
					aria-label={translate('Reset Performance Data')}
				>
					<DialogTitle onClose={this.handleClose}>
						<Text
							style={{fontSize: '20px'}}
							label={translate('Reset Performance Data ?')}
						/>
					</DialogTitle>

					<DialogContent>
						<div style={{paddingTop: '10px', minWidth: '500px'}}>
							{this.getDialogMessage()}
							<br />
							<br />
							<Typography>This action cannot be undone.</Typography>
							{showSpinner && <LoadingStat />}
						</div>
					</DialogContent>

					<DialogActions>
						<Button
							color={'primary'}
							variant={'contained'}
							disabled={showSpinner}
							onClick={this.handleClickReset}
						>
							{translate('Reset')}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

ResetPerformanceDataButton.propTypes = {
	dataProvider: PropTypes.func.isRequired,
	variant: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	trainingName: PropTypes.string,
	UserId: PropTypes.string,
	trainingId: PropTypes.string,
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withDataProvider,
	translate,
	withStyles(styles)
);

export default enhance(ResetPerformanceDataButton);
