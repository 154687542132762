import React, {Component} from 'react';
import {createStyles, withStyles} from '@material-ui/core/styles';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MUIButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {translate} from 'react-admin';

const myStyles = theme =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		beforeIndicators: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
			minWidth: '30%',
		},
		indicators: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.color.stepIndicator,
			minWidth: '40%',
			'&:hover': {
				cursor: 'pointer',
			},
		},
		activeIndicator: {
			color: theme.palette.color.stepIndicatorActive,
		},
		afterIndicators: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-end',
			minWidth: '30%',
		},
		previousButton: {
			marginRight: '15px',
		},
	});

class WizardStepper extends Component {
	state = {};

	renderBeforeIndicators() {
		const {classes} = this.props;

		return (
			<div className={classes.beforeIndicators}>
				{/* {activeStep > 1 &&
                    <MUIButton className={classes.prevButton} size='small' onClick={() => this.props.onActiveStepChange(this.props.activeStep - 1)} variant='raised' disabled={this.state.activeStep <= 1}>
                        <label>{this.props.prevLabel ? this.props.prevLabel : 'Prev'}</label>
                    </MUIButton>
                } */}
			</div>
		);
	}

	renderIndicators() {
		const {classes, maxSteps, activeStep} = this.props;

		const steps = [];

		if (maxSteps > 1) {
			for (let step = 1; step <= maxSteps; step++) {
				if (step === activeStep) {
					steps.push(
						<FiberManualRecordIcon
							key={step}
							className={classes.activeIndicator}
							size='small'
						/>
					);
				} else {
					if ((activeStep > 1 && step !== 1) || activeStep === 1) {
						steps.push(
							<FiberManualRecordIcon
								key={step}
								size='small'
								onClick={() => {
									if (!this.props.disabled) {
										this.props.onActiveStepChange(step);
									}
								}}
							/>
						);
					}
				}
			}
		}

		return (
			<div className={classes.indicators}>
				{activeStep === 1 ? null : steps}
			</div>
		);
	}

	renderAfterIndicators() {
		const {classes, maxSteps, activeStep} = this.props;

		return (
			<div className={classes.afterIndicators}>
				{activeStep > 1 && (
					<MUIButton
						size='small'
						color='secondary'
						onClick={() => {
							if (!this.props.disabled)
								this.props.onActiveStepChange(this.props.activeStep - 1);
						}}
						variant='contained'
						disabled={this.props.disabled}
						className={classes.previousButton}
					>
						<label>
							{this.props.prevLabel ? this.props.prevLabel : 'Previous'}
						</label>
					</MUIButton>
				)}
				{activeStep < maxSteps && (
					<MUIButton
						size='small'
						color='primary'
						onClick={() => {
							if (!this.props.disabled)
								this.props.onActiveStepChange(this.props.activeStep + 1);
						}}
						variant='contained'
						disabled={this.props.disabled}
					>
						<label>
							{this.props.nextLabel ? this.props.nextLabel : 'Next'}
						</label>
					</MUIButton>
				)}
				{activeStep === maxSteps && (
					<MUIButton
						size='small'
						onClick={() => {
							if (!this.props.disabled) this.props.onFinish();
						}}
						variant='contained'
						color='primary'
						disabled={this.props.disabled}
						startIcon={this.props.finishButtonStartIcon}
					>
						<label>
							{this.props.finishLabel ? this.props.finishLabel : 'Finish'}
						</label>
					</MUIButton>
				)}
			</div>
		);
	}

	render() {
		const {classes} = this.props;

		return (
			<div className={classes.root}>
				{this.renderBeforeIndicators()}
				{this.renderIndicators()}
				{this.renderAfterIndicators()}
			</div>
		);
	}
}

WizardStepper.propTypes = {
	maxSteps: PropTypes.number.isRequired,
	activeStep: PropTypes.number.isRequired,
	onActiveStepChange: PropTypes.func.isRequired,
	onFinish: PropTypes.func.isRequired,
	prevLabel: PropTypes.string,
	nextLabel: PropTypes.string,
	finishLabel: PropTypes.string,
	disabled: PropTypes.bool,
};

const enhance = compose(translate, withStyles(myStyles));

export default enhance(WizardStepper);
