import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import NavigatingDialog from '../../components/dialogs/NavigatingDialog';

const ViewTeamDetailButton = props => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleClose = e => {
    setIsNavOpen(false);
    if (e) e.stopPropagation();
    props.onClick && props.onClick(e);
  };
  return (
    <div>
      <NavigatingDialog
        title={'Navigating Away'}
        navigateTo={`/teams/${props.record.id}/show`}
        showOnceKey={'navigateToTeams'}
        isOpen={isNavOpen}
        setIsOpen={setIsNavOpen}
        onClose={handleClose}
      >
        This will take you to another section.
				<br />
        <br />
				Do you want to navigate away from this section?
			</NavigatingDialog>
      <div>
        <MenuItem key={'View Team Detail'} onClick={() => setIsNavOpen(true)}>View Team Detail</MenuItem>
      </div>
    </div>
  );
};

export default ViewTeamDetailButton;
