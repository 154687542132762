import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {fetchEnd, fetchStart, GET_LIST, useVersion} from 'react-admin';
import {SkillBarPanel} from '..';
import LoadingList from '../loading/LoadingList';
import { useDispatch } from 'react-redux';

const SkillsTab = props => {
	const {id, dataProvider, selectedTrainingRecord} = props;

	const [state, setState] = useState({userskillstats: null, isError: false, loading: false});

	const version = useVersion();

	const dispatch = useDispatch();

	useEffect(() => {
		const fetch = async () => {
			dispatch(fetchStart());
			setState({ ...state, loading: true });
			await dataProvider(GET_LIST, 'userskillstats', {
				filter: {userId: id, trainingId: selectedTrainingRecord.id},
				pagination: {},
				sort: {},
			})
				.then(response =>
					setState({userskillstats: response.data, isError: false, loading: false})
				)
				.catch(() => setState({userskillstats: null, isError: true, loading: false}))
				.finally(() => dispatch(fetchEnd()));
		};
		fetch();
	}, [id, version]);

	return (
		<div>
			{state.isError && (
				<Typography style={{textAlign: 'center', fontSize: '28px'}}>
					No Skills Found
				</Typography>
			)}
			{((!state.userskillstats && !state.isError) || state.loading) && <LoadingList />}
			{state.userskillstats && !state.loading &&(
				<SkillBarPanel
					data={state.userskillstats}
					vectorMapContextData={{
						teamId: undefined,
						userId: id,
						trainingId: selectedTrainingRecord
							? selectedTrainingRecord.id
							: undefined,
						lessonId: undefined,
					}}
					showLastProcessedTime={props.showLastProcessedTime !== undefined ? props.showLastProcessedTime : true}
				/>
			)}
		</div>
	);
};

export default SkillsTab;
