import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';
import {ClickableArea} from './index';

const FakeSelectInput = props => {

	const {value, placeholder, onClick, width= '100%'} = props;

	return (
		<div>
			<ClickableArea onClick={onClick} width="100%">
			<div
				style={{
					color: 'rgb(141, 141, 141)',
				}}
			></div>
			<div
				style={{
					backgroundColor: '#ecf4f6',
					color: 'inherit',
					fontSize: 'inherit',
					borderRadius: '4px',
					height: '28px',
					display: 'block',
					appearance: 'none',
					MozAppearance: 'none',
					WebkitAppearance: 'none',

					'&:-ms-expand ': {
						display: 'none',
						position: 'absolute'
					}
				}}
			>
				<div
					style={{
						backgroundColor: 'transparent',
						color: 'black',
						fontSize: 'inherit',
						border: 'none',
						borderRadius: '4px',
						paddingTop: '4px',
						paddingBottom: '4px',
						paddingLeft: '8px',
						paddingRight: '8px',
						height: '28px',
						width: width,
						appearance: 'none',
						MozAppearance: 'none',
						WebkitAppearance: 'none',

						display: 'inline-block',

						'&:-ms-expand ': {
							display: 'none'
						}
					}}
				>
					{value ? value : placeholder}
				</div>

				<KeyboardArrowDownIcon
					style={{
						marginLeft: '-20px',
						marginTop: '-20px',
						color: '#fff',
						height: '20px',
						width: '20px',
						display: 'inline-block',
						position: 'relative',
						top: '6px',
						right: '2px',
						overflow: 'visible',
						pointerEvents: 'none',
						backgroundColor: '#346dec',
					}}
				/>
			</div>
			</ClickableArea>
		</div>
	);
};


export default FakeSelectInput;
