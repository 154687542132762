import React from 'react';
import {Typography} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import {loadObject} from '../utils/Util';
import get from 'lodash/get';

const showDefaultTeam = props => {
	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const {record} = props;

	const id = get(runwayOrgConfig, 'config.defaultTeamId');

	if (id === record.id)
		return (
			<div
				style={{
					whiteSpace: 'nowrap',
					flex: '1',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					minWidth: '0',
				}}
			>
				<Typography
					color='primary'
					style={{
						fontFamily: 'Montserrat Regular',
						fontSize: '14px',
					}}
					component={'div'}
				>
					<div
						title={record.name}
						style={{overflow: 'hidden', textOverflow: 'ellipsis', display: 'flex', alignItems: 'center'}}
					>
						<CheckCircle color='primary' style={{marginRight: 10}} />
						{record.name}
					</div>
				</Typography>
			</div>
		);
	if (id !== record.id)
		return (
			<div
				style={{
					whiteSpace: 'nowrap',
					flex: '1',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					minWidth: '0',
				}}
			>
				<Typography
					style={{
						fontFamily: 'Montserrat Regular',
						fontSize: '14px',
					}}
					component={'div'}
				>
					<div
						title={record.name}
						style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
					>
						{record.name}
					</div>
				</Typography>
			</div>
		);
};

export default showDefaultTeam;
