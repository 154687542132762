import * as moment from 'moment';

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

const TrainingExpiry = ({source, record = {}}) => {

	if (!get(record, source)) {
		return <span>---</span>;
	}

	if (get(record, source) && get(record, source) < 0) {
		return <span style={{fontSize: '24px'}}>&infin;</span>;
	}

	if (moment.utc(get(record, source)).isBefore()) {
		return <span>Expired</span>;
	}

	if (get(record, source) === 'NaN' || !record.accessibility.assigned) {
		return <span>---</span>;
	}

	return <span>{moment.utc(get(record, source)).fromNow(true)}</span>;
};

TrainingExpiry.propTypes = {
	record: PropTypes.object.isRequired,
	source: PropTypes.string.isRequired,
};

export default TrainingExpiry;
