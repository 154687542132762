import React, {useState} from 'react';
import {
	GET_LIST,
	GET_ONE,
	useTranslate,
	useDataProvider,
	withDataProvider,
} from 'react-admin';
import {refreshView} from 'ra-core';
import {connect} from 'react-redux';
import compose from 'recompose/compose';

import {createStyles, withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import {CircularProgress} from '@material-ui/core';
import MUIButton from '@material-ui/core/Button';

import {isGreaterThanZeroAndInteger, loadObject, saveObject} from 'utils/Util';
import {EmbeddedShow, TextInput} from 'components';
import {CREATE_GENERATE_USERS, GET} from 'services/customActions';
import ConfigureCreateUsersDialog from './ConfigureCreateUsersDialog';
import SoftCapPopUp from './SoftCapPopUp';
import PALUnavailableDialog from "components/PALUnavailableDialog";
import FileDownload from 'assets/FileDownload';
import EditIcon from 'assets/EditIcon';
import { AddOutlined } from '@material-ui/icons';

const myStyles = theme =>
	createStyles({
		row: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			columnGap: '10px',
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
		spinnerStyles: {
			position: 'relative',
			top: '2px',
			color: theme.palette.primary.main,
		},
		info: {
			fontFamily: 'Montserrat Italic',
			fontSize: '12px',
		},
		successInfo: {
			fontFamily: 'Montserrat Italic',
			fontSize: '12px',
			paddingTop: '20px',
			color: theme.palette.primary.main,
		},
		errorInfo: {
			fontFamily: 'Montserrat Italic',
			fontSize: '12px',
			color: 'red',
		},
		lastUpdatedText: {
			fontFamily: 'Montserrat Bold',
			display: 'inline',
		},
		button: {
			height: '32px',
			width: '160px',
		},
	});

const CreateMultipleUsers = props => {
	const translate = useTranslate();

	const dataProvider = useDataProvider();

	const cachedRunwayOrgConfig = loadObject('runwayOrgConfig');

	// Platform Access Licenses
	const [palUnavailableErrorMessage, setPalUnavailableErrorMessage] = useState(null);
	
	const [runwayOrgConfig, setRunwayOrgConfig] = useState(cachedRunwayOrgConfig);

	const usersToDownload = runwayOrgConfig.config?.numGeneratedUsersToDownload;
	const usersToDownloadDisplayText =
		runwayOrgConfig.config?.numGeneratedUsersToDownloadDisplayText;
	const lastGenerateUserTime = runwayOrgConfig.config?.lastGenerateUserTime;
	const formattedTime =
		lastGenerateUserTime !== '' &&
		lastGenerateUserTime !== null &&
		lastGenerateUserTime !== undefined &&
		lastGenerateUserTime !== 0
			? new Date(lastGenerateUserTime)
					.toLocaleString()
					.replace(',', '')
					.replace(/:\d{2}\s/, ' ')
					.replace(/\//g, '-')
			: 'Never';

	const [showConfigureDialog, setShowConfigureDialog] = useState(false);

	const [isCreating, setIsCreating] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [numToCreate, setNumToCreate] = useState();
	const [generateUsersResponse, setGenerateUsersResponse] = useState(undefined);
	const [errorOccuredOnCreate, setErrorOccuredOnCreate] = useState(false);
	const [accountsCreating, setAccoutsCreating] = useState();
	const [isBackendErrorOccured, setIsBackendErrorOccured] = useState(false);
	const [backendErrorMessage, setBackendErrorMessage] = useState('');
	const [isSoftCapExceeded, setIsSoftCapExceeded] = useState(false);
	const [preparingUserToCreate, setPreparingUserToCreate] = useState(false);

	const {classes} = props;

	const fetchData = async () => {
		const {data: runwayOrgConfig} = await dataProvider(
			GET_ONE,
			'runwayOrgConfig'
		);
		saveObject('runwayOrgConfig', runwayOrgConfig);
		setRunwayOrgConfig(runwayOrgConfig);
	};

	const processCreateUsersResponse = response => {
		if (response.data.numToCreate !== response.data.numCreated) {
			setErrorOccuredOnCreate(true);
		}
	};

	const isNewPALLicenseAvailable = async () => {
		let newLicenseAvailable = true;

		await dataProvider(GET, 'isNewPALLicenseAvailable', {
			numToCreate: parseInt(numToCreate),
		}).catch(error => {
			newLicenseAvailable = false;
			setPalUnavailableErrorMessage(error.message);
			setIsCreating(false);
			setNumToCreate('');
		    setPreparingUserToCreate(false);
		});

		return newLicenseAvailable;
	};

	const confirmCreateAccounts = async() => {
		setIsCreating(true);
		setAccoutsCreating(numToCreate);
		dataProvider(CREATE_GENERATE_USERS, 'createGenerateUsersRunway', {
			numToCreate: parseInt(numToCreate),
		})
			.then(response => {
				setGenerateUsersResponse(response.data);
				setIsCreating(false);
				processCreateUsersResponse(response);
			})
			.catch(error => {
				console.log(error);
				setIsCreating(false);
				setIsBackendErrorOccured(true);
				setBackendErrorMessage(error.message);
			});
		setNumToCreate('');
		refreshView();
		fetchData();
	};

	const createAccounts = async() => {
		setPreparingUserToCreate(true);
		setGenerateUsersResponse(undefined);
		setErrorOccuredOnCreate(false);
		setIsBackendErrorOccured(false);

		const newLicenseAvailable = await isNewPALLicenseAvailable();

		if (parseInt(numToCreate) > 0 && newLicenseAvailable) {
			dataProvider(GET_LIST, 'getGenerateUserConfigRunway', {})
				.then(response => {
		            setPreparingUserToCreate(false);
					if (
						response.data.softCapValue < numToCreate &&
						response.data.hardCapValue > numToCreate
					) {
						setIsSoftCapExceeded(true);
					} else {
						confirmCreateAccounts();
					}
				})
				.catch(error => {
		            setPreparingUserToCreate(false);
					setIsBackendErrorOccured(true);
					console.log(error);
				});
		}
	};

	const confirmCreateFromSoftCap = () => {
		confirmCreateAccounts();
		setIsSoftCapExceeded(false);
	};

	const cancelCreateFromSoftCap = () => {
		setIsSoftCapExceeded(false);
	};

	const DownloadUserList = () => {
		setIsDownloading(true);
		setGenerateUsersResponse(undefined);
		dataProvider(GET_LIST, 'downloadUserGeneratedInfoRunway', {})
			.then(response => {
				const val = response.data.userData;
				const data = val.map(row => row);
				const csvRows = [];

				const headers = Object.keys(data[0]);
				csvRows.push(headers.join(','));
				for (const row of data) {
					const values = headers.map(header => {
						const escaped = row[header];
						return `"${escaped}"`;
					});
					csvRows.push(values.join(','));
				}
				const resp = csvRows.join('\n');
				const file = new File([resp], {type: 'text'});
				const url = window.URL.createObjectURL(file);
				const a = document.createElement('a');
				a.setAttribute('hidden', '');
				a.setAttribute('href', url);
				const fileName = 'CreateMultipleUsers.csv';
				a.setAttribute('download', fileName);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				setIsDownloading(false);
			})
			.catch(error => {
				console.log(error);
			});
		refreshView();
		fetchData();
	};

	const onConfigureClicked = () => {
		setShowConfigureDialog(true);
	};
	const closeConfigureDialog = () => {
		setShowConfigureDialog(false);
		setNumToCreate('');
	};

	return (
		<EmbeddedShow title={'Create Multiple Users'} height={'auto'}>
			<div>
				{translate(
					'Rapidly create ready-to-use accounts. Accounts will be listed in a comma separated text file.'
				)}
			</div>
			<hr className={classes.divider} />
			<div style={{display: 'flex', flexDirection: 'row'}}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<div
						style={{
							display: 'flex',
							paddingRight: '20px',
							paddingTop: '2px',
							alignItems: 'baseline',
							columnGap: '10px',
							width: '310px'
						}}
					>
						<InputLabel style={{fontWeight: 'bold'}}>
							{translate('Amount to create:')}
						</InputLabel>
						<div style={{marginTop: '-16px'}}>
							<TextInput
								width='130px'
								value={numToCreate}
								placeholder='Enter a number'
								onChange={e => setNumToCreate(e.target.value)}
								error={numToCreate != '' && numToCreate != null && !isGreaterThanZeroAndInteger(numToCreate)}
								errorMessage={'Enter a valid number'}
							/>
						</div>
					</div>
					{!isCreating && generateUsersResponse && (
						<div className={classes.successInfo}>
							{translate(
								`${!errorOccuredOnCreate ? 'Successfully ' : ''}Created ${
									generateUsersResponse.numCreated
								} ${
									generateUsersResponse.numCreated > 1 ? 'Accounts' : 'Account'
								}`
							)}
						</div>
					)}
				</div>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<div className={classes.row} style={{marginBottom: '10px'}}>
						<div>
							<MUIButton
								variant='contained'
								color='primary'
								className={classes.button}
								onClick={createAccounts}
								startIcon={<AddOutlined />}
								disabled={isCreating || numToCreate == null || !isGreaterThanZeroAndInteger(numToCreate) || preparingUserToCreate}
							>
								{translate('Create Now!')}
							</MUIButton>
						</div>
						{(isCreating || preparingUserToCreate) && (
							<div className={classes.row}>
								<CircularProgress size={15} className={classes.spinnerStyles} />
								{isCreating && <span className={classes.info}>
									{translate(
										`Generating ${accountsCreating} ${
											accountsCreating > 1 ? 'Accounts' : 'Account'
										}`
									)}
								</span>}
							</div>
						)}
						{!isCreating && errorOccuredOnCreate && (
							<div className={classes.errorInfo}>
								{translate(
									`Alert! System was unable to create all of the accounts requested. Requested: ${generateUsersResponse.numToCreate} Created: ${generateUsersResponse.numCreated}. Please add more name variety in Username configuration.`
								)}
							</div>
						)}
						{!isCreating && isBackendErrorOccured && (
							<div className={classes.errorInfo}>{backendErrorMessage}</div>
						)}
					</div>
					<div className={classes.row}>
						<div>
							<MUIButton
								variant='contained'
								color='primary'
								className={classes.button}
								onClick={DownloadUserList}
								disabled={isCreating || isDownloading || usersToDownload === 0 || preparingUserToCreate}
								startIcon={<FileDownload style={{ fontSize: "20px" }}/>}
							>
								{translate('Download')}
							</MUIButton>
						</div>
						{!isDownloading && (
							<div className={classes.lastUpdatedText}>
								{translate(`Last Updated: ${formattedTime}`)}
							</div>
						)}
						{usersToDownload > 0 && !isDownloading && (
							<div className={classes.info}>
								{translate(`${usersToDownloadDisplayText}`)}
							</div>
						)}
						{isDownloading && (
							<div className={classes.row}>
								<CircularProgress size={15} className={classes.spinnerStyles} />
								<div className={classes.info}>
									{translate('Downloading...')}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<hr className={classes.divider} />

			<div className={classes.row}>
				<MUIButton
					variant='outlined'
					color='primary'
					className={classes.button}
					onClick={onConfigureClicked}
					startIcon= {<EditIcon style={{ fontSize: '16px', marginBottom: '3px'}}/>}
				>
					{translate('Configure')}
				</MUIButton>
				<div className={classes.info}>
					{translate('Control what kinds of Names and Passwords are created.')}
				</div>
			</div>

			{showConfigureDialog && (
				<ConfigureCreateUsersDialog
					isOpen={showConfigureDialog}
					errorOccuredOnCreate={errorOccuredOnCreate}
					onClose={closeConfigureDialog}
				/>
			)}

			{isSoftCapExceeded && (
				<SoftCapPopUp
					isPopoverOpen={isSoftCapExceeded}
					numToCreate={numToCreate}
					confirmCreateAccounts={confirmCreateFromSoftCap}
					cancelCreateAccounts={cancelCreateFromSoftCap}
				/>
			)}

			{palUnavailableErrorMessage && <PALUnavailableDialog onClose={() => setPalUnavailableErrorMessage(false)} errorMessage={palUnavailableErrorMessage} title={'Cannot Create Users'}/>}
		</EmbeddedShow>
	);
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withDataProvider,
	withStyles(myStyles)
);

export default enhance(CreateMultipleUsers);
