import React from 'react';
import {NumberField, useTranslate} from 'react-admin';

import AddUserToTeamsDialog from '../../Dialogs/AddUserToTeamsDialog';
import {EmbeddedList} from 'components';
import SendEmail from 'components/SendEmail';
import RemoveUserFromTeamButton from 'sections/User/Buttons/RemoveUserFromTeamButton';
import ViewTeamDetailButton from 'sections/Training/ViewTeamDetailButton';
import {getUserInfo} from 'utils/Data';

const TeamsList = props => {
	const translate = useTranslate();

	const ownerId = getUserInfo().id;

	const currentUserIsAdmin = getUserInfo().isAdmin || getUserInfo().isSuper;

	return (
		<EmbeddedList
			embedded={false}
			subTitle
			title={translate('Teams for this User')}
			resource='teamsforuser'
			columns={[
				{
					id: 'name',
					name: translate('Team'),
					sortable: true,
				},
				{
					id: 'role',
					name: translate('Role'),
					sortable: true,
					render: record => {
						switch (record.role) {
							case 'admin':
								return translate('Admin');
							case 'manager':
								return translate('Manager');
							default:
								return translate('User');
						}
					},
				},
				{
					id: 'training',
					name: translate('Module'),
					sortable: true,
					align: 'center',
					render: record => <NumberField record={record} source='training' />,
					isInverted: true,
				},
				{
					id: 'managerName',
					name: translate('Manager'),
					sortable: true,
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[{id: 'userId', value: props.id}]}
			dynamicFilters={null}
			bulkActions={[
				{
					id: 'addUser',
					render: () => <div></div>,
				},
				{
					id: 'emailTeam',
					render: () => (
						<AddUserToTeamsDialog
							label='Add to Team'
							ownerId={ownerId}
							record={props.userData}
							{...props}
							key={'AddUserToTeamsDialog'}
						/>
					),
				},
			]}
			actions={[
				{
					id: 'viewDetail',
					render: record => (
						<ViewTeamDetailButton
							variant='link'
							record={record}
							basePath={props.basePath}
							location={props.location}
							key={'viewDetail'}
						/>
					),
				},
				{
					id: 'emailManager',
					render: record => (
						<SendEmail
							key={'emailManager'}
							id={record.managerId}
							title={'Email Manager'}
						/>
					),
				},
				{
					id: 'removeTeam',
					render: record => {
						if (!currentUserIsAdmin && props.userData.id === record.managerId) {
							return null;
						} else {
							return (
								<RemoveUserFromTeamButton
									key={'removeTeam'}
									variant='link'
									userData={props.userData}
									teamData={record}
									basePath={props.basePath}
									location={props.location}
								/>
							);
						}
					},
				},
			]}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default TeamsList;
