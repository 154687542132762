import React from 'react';

const Row = props => {
	const {
		width = '100%',
		flexDirection = 'row',
		height = '100%',
		padding = 0,
		children,
	} = props;

	return (
		<div
			style={{
				width: width,
				padding: padding,
				height: height,
				display: 'flex',
				flexDirection: flexDirection,
				flex: 1,
				justifyContent: 'center',
				flexGrow: 1,
				alignItems: 'center',
			}}
		>
			{children}
		</div>
	);
};

export default Row;
