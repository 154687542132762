import React, {useState} from 'react';

import {Tab} from 'react-admin';
import UnassignedTrainingDetail from '../../../../components/UnassignedTrainingDetail.jsx';
import {loadObject} from '../../../../utils/Util.jsx';
import TrainingDetail from './TrainingDetail';
import TrainingList from './TrainingList';

const Training = props => {
	const {teamData} = props;
	const [selectedTrainingRecord, setSelectedTrainingRecord] = useState(null);
	const [selectedSkills, setSelectedSkills] = useState([]);

	const trainingSelected = (id, basePath, record) => {
		setSelectedTrainingRecord(record);
	};

	const cancelTrainingSelection = () => {
		setSelectedTrainingRecord(null);
	};

	const skillsSelected = skills => {
		setSelectedSkills(skills);
	};

	return (
		<Tab path='training' label='Module'>
			{!selectedTrainingRecord && (
				<TrainingList
					{...props}
					trainingSelected={trainingSelected}
					skillsSelected={skillsSelected}
					selectedSkills={selectedSkills}
				/>
			)}
			{selectedTrainingRecord &&
				(selectedTrainingRecord.accessibility.assigned.value ? (
					<TrainingDetail
						{...props}
						backTrainingAction={cancelTrainingSelection}
						selectedId={selectedTrainingRecord.id}
						trainingSelected={trainingSelected}
						selectedTrainingRecord={selectedTrainingRecord}
						selectedSkills={selectedSkills}
						skillsSelected={skillsSelected}
					/>
				) : (
					<UnassignedTrainingDetail
						backActions={[cancelTrainingSelection]}
						titleBreadCrumbs={[teamData.name]}
						selectedTrainingRecord={selectedTrainingRecord}
						isExpandMore={
							loadObject('expandTeamTrainingcatalogue') === null
								? false
								: loadObject('expandTeamTrainingcatalogue')
						}
						toggleKey={'expandTeamTrainingcatalogue'}
					/>
				))}
		</Tab>
	);
};

export default Training;
