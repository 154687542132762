import 'date-fns';

import React, {useEffect, useState} from 'react';
import {useDataProvider, useTranslate} from 'react-admin';
import Button from '@material-ui/core/Button';

import {GET_USER_ROLE_LIST} from 'services/customActions';

import Box from '../layout/Box';
import {CustomDialog} from '../index';
import EmbeddedList from '../EmbeddedList';
import LoadingInfo from 'components/loading/LoadingInfo';

const SelectManagerDialog = props => {
	const {selectedManager, setSelectedManager, isOpen, setIsOpen} = props;

	const dataProvider = useDataProvider();

	const [userRoles, setUserRoles] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	const fetchUserRoleList = async () => {
		setIsLoading(true);
		await dataProvider(GET_USER_ROLE_LIST, 'getUserRoleListRunway', {})
			.then(res => {
				setUserRoles(res.data);
			})
			.catch(e => console.error(e))
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		fetchUserRoleList();
	}, []);

	const translate = useTranslate();

	const handleManagerSelectionToggled = record => {
		setSelectedManager(record);
	};

	const renderScreen = () => {
		const deafultUserRoleFilter = [];

		userRoles.forEach(item => {
			if (item.id.indexOf('user') < 0) deafultUserRoleFilter.push(item.id);
		});

		return (
			<CustomDialog
				title={'Available Users'}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				width={'960px'}
				actions={[
					{
						render: () => {
							return (
								<Button
									color='primary'
									variant='contained'
									onClick={() => {
										setIsOpen(false);
									}}
								>
									Select Manager
								</Button>
							);
						},
					},
				]}
			>
				{isLoading ? (
					<LoadingInfo />
				) : (
					<Box>
						<EmbeddedList
							embedded={true}
							cached={false}
							resource={'users'}
							columns={[
								{id: 'userId', name: translate('User ID'), sortable: true},
								{id: 'name', name: translate('User Name'), sortable: true},
								{
									id: 'teams',
									name: translate('Teams'),
									sortable: true,
									width: '125px',
									align: 'center',
									isInverted: true,
								},
								{
									id: 'trainings',
									name: translate('Modules'),
									sortable: true,
									width: '125px',
									align: 'center',
									isInverted: true,
								},
							]}
							sort={{field: 'name', order: 'ASC'}}
							staticFilters={[{id: 'status', value: 'enabled'}]}
							dynamicFilters={[
								{id: 'name', name: translate('Search'), type: 'safe_regex'},
								{
									id: 'role',
									name: translate('Role'),
									type: 'exact',
									choices: [
										{
											value: {$in: [...deafultUserRoleFilter]},
											label: translate('All Roles'),
										},
										...userRoles
											.filter(item => item.id.indexOf('user') < 0)
											.map(item => ({value: item.id, label: item.name})),
									],
								},
							]}
							bulkActions={null}
							actions={null}
							rowClicked={null}
							selectedIds={[selectedManager.id]}
							selectionToggled={handleManagerSelectionToggled}
							stripped={false}
						/>
					</Box>
				)}
			</CustomDialog>
		);
	};

	return <div>{renderScreen()}</div>;
};

export default SelectManagerDialog;
