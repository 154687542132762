import {EmbeddedShow, ScreenTitle, TextValue, TimeDateField} from 'components';
import {fetchEnd, fetchStart, GET_LIST, useVersion, withDataProvider} from 'react-admin';
import React, {useEffect, useState} from 'react';

import LessonDetailView from '../../../../../components/LessonDetailView';
import compose from 'recompose/compose';
import { useDispatch } from "react-redux";

const LessonDetail = props => {
	const {
		selectedLessonRecord,
		backLessonAction,
		selectedTrainingRecord,
		backTrainingAction,
		dataProvider,
		teamData,
	} = props;

	const [state, setState] = useState({});

	const [loading, setLoading] = useState(false);

	const version = useVersion();

	const dispatch = useDispatch();

	useEffect(() => {
		fetch();
	}, [version]);

	const fetch = async () => {
		setLoading(true);
		dispatch(fetchStart());
		try {
			const data = await dataProvider(GET_LIST, 'lessonteamtrainingstats', {
				filter: {
					teamId: props.id,
					trainingId: selectedTrainingRecord.id,
					lessonId: selectedLessonRecord.id,
				},
				pagination: {},
				sort: {},
			});

			setState({
				...state,
				lessonteamtrainingstats: data.data,
			});

			const lessonSkillStats = await dataProvider(
				GET_LIST,
				'LessonSkillStatsOverallRunway',
				{
					filter: {
						trainingId: selectedTrainingRecord.id,
						lessonId: selectedLessonRecord.id,
						teamId: teamData.id,
					},
					pagination: {},
					sort: {},
				}
			);

			setState({
				...state,
				lessonteamtrainingstats: data.data,
				lessonSkillStats: lessonSkillStats.data,
			});
		} finally {
			setLoading(false);
		    dispatch(fetchEnd());
		}
	};

	return (
		<div>
			<ScreenTitle
				title={selectedLessonRecord.name}
				refresh={false}
				subTitle={true}
				coloredCrumb={true}
				titleCrumbs={[
					teamData.name.trim().length > 0 ? teamData.name : teamData.id,
					selectedTrainingRecord.name,
				]}
				backActionCrumbs={[backTrainingAction, backLessonAction]}
			/>
			<LessonDetailView
			    showLastProcessedTime={false}
			    loading={loading}
				lessonData={selectedLessonRecord}
				lessonTrainingStats={state.lessonteamtrainingstats}
				lessonSkillStats={state.lessonSkillStats}
				fromComponent={'teams'}
				vectorMapContextData={{
					teamId: teamData ? teamData.id : undefined,
					userId: undefined,
					trainingId: selectedTrainingRecord
						? selectedTrainingRecord.id
						: undefined,
					lessonId: selectedLessonRecord ? selectedLessonRecord.id : undefined,
				}}
				information={
					<EmbeddedShow
						headerless
						embedded
						details={[
							{
								label: 'Name',
								render: record => (
									<TextValue
										record={selectedLessonRecord}
										source='name'
										maxWidth={'300px'}
									/>
								),
							},
							{
								label: 'Added to Team',
								render: record => (
									<TimeDateField
										record={selectedLessonRecord}
										source='teamAdded'
									/>
								),
							},
							{
								label: 'Most Recent Change',
								render: record => (
									<TimeDateField
										record={selectedLessonRecord}
										source='lastChangeDate'
									/>
								),
							},
						]}
					/>
				}
			/>
		</div>
	);
};

export default compose(withDataProvider)(LessonDetail);
