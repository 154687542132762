import React from 'react';
import TestBeaker from 'assets/TestBeaker.svg';
const TestBeakerIcon = () => {
    return(
        <>
        <img src={TestBeaker} alt={'testbeaker'} width='18' style={{marginRight: '10px'}} />
        </>
    )
}

export default TestBeakerIcon;