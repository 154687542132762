import {
	Typography,
	CircularProgress,
	createStyles,
	withStyles,
	InputAdornment,
	TextareaAutosize,
} from '@material-ui/core';
import {useState} from 'react';
import MUIButton from '@material-ui/core/Button';
import MuiTextField from '@material-ui/core/TextField';
import {EmbeddedShow, StatusChip, TimeDateField} from 'components';
import compose from 'recompose/compose';
import {CREATE, useDataProvider} from 'react-admin';

const EditLessonContent = props => {
	const {lessonData, classes, trainingId, onUpdate} = props;

	const dataProvider = useDataProvider();

	const [isUpdating, setIsUpdating] = useState(false);

	const [modifiedLessonName, setModifiedLessonName] = useState(lessonData.name);

	const [modifiedDescription, setModifiedDescription] = useState(
		lessonData.description
	);

	const [modifiedLearningObjectives, setModifiedLearningObjectives] = useState(
		lessonData.learningObjectives && lessonData.learningObjectives.length > 0
			? lessonData.learningObjectives[0]
			: ''
	);

	const handleCancelEditing = () => {
		props.onCancelEditing();
	};

	const handleSave = async () => {
		setIsUpdating(true);

		const params = {
			trainingId: trainingId,
			lessonId: lessonData.id,
			lessonInfo: {
				title: modifiedLessonName,
				description:
					modifiedDescription && modifiedDescription.trim().length > 0
						? modifiedDescription
						: null,
				learningObjectives:
					modifiedLearningObjectives &&
					modifiedLearningObjectives.trim().length > 0
						? [modifiedLearningObjectives]
						: [],
			},
			updateAll: true,
		};

		try {
			await dataProvider(CREATE, 'editTrainingModuleLessonInfo', {
				data: params,
			});
			onUpdate();
		} catch (error) {
			console.error(error);
			setIsUpdating(false);
		}
	};

	const isFormValid = () => {
		if (
			!modifiedLessonName ||
			modifiedLessonName.trim().length === 0 ||
			modifiedLessonName.length > 64
		)
			return false;

		if (
			modifiedDescription &&
			modifiedDescription != null &&
			modifiedDescription.trim().length &&
			modifiedDescription.length > 1000
		)
			return false;

		if (
			modifiedLearningObjectives &&
			modifiedLearningObjectives != null &&
			modifiedLearningObjectives.trim().length &&
			modifiedLearningObjectives.length > 1000
		)
			return false;

		return true;
	};

	const renderMaxCharactersError = text => {
		if (text && text.length && text.length > 1000) {
			return (
				<Typography color={'error'}>Must not exceed 1000 characters</Typography>
			);
		}

		return null;
	};

	return (
		<div>
			<div
				style={{
					display: 'flex',
					padding: '5px',
					justifyContent: 'space-between',
					alignItems: 'baseline',
					boxShadow: '0px 0px 10px #00000033',
					backgroundColor: '#FFC440',
				}}
			>
				<span></span>

				<span>
					<Typography
						color={'primary'}
						style={{letterSpacing: '3.2px', fontFamily: 'Archivo Bold'}}
					>
						---------- EDITING ----------
					</Typography>
				</span>

				{!isUpdating && (
					<span style={{display: 'flex', gap: '5px'}}>
						<MUIButton
							variant={'outlined'}
							color={'primary'}
							style={{height: '35px'}}
							onClick={() => handleCancelEditing()}
						>
							Cancel
						</MUIButton>
						<MUIButton
							variant={'contained'}
							color={'primary'}
							style={{height: '35px'}}
							disabled={!isFormValid()}
							onClick={() => handleSave()}
						>
							Save
						</MUIButton>
					</span>
				)}

				{isUpdating && (
					<div style={{display: 'flex', alignItems: 'baseline'}}>
						<CircularProgress
							size={20}
							style={{top: '5px', position: 'relative', marginRight: '5px'}}
						/>
						<span style={{color: '#000'}}>{`Updating...`}</span>
					</div>
				)}
			</div>

			<div
				style={{
					padding: '12px',
					border: '3px solid #ffc440',
					backgroundColor: 'white',
				}}
			>
				<EmbeddedShow
					headerless
					embedded
					title={'Information'}
					details={[
						{
							label: 'Name',
							render: record => (
								<div style={{flex: '1 1 auto', height: '40px'}}>
									<MuiTextField
										id='lesson-name'
										variant='outlined'
										color='secondary'
										className={classes.textInput}
										value={modifiedLessonName}
										onChange={e => setModifiedLessonName(e.target.value)}
										InputProps={{
											endAdornment: (
												<InputAdornment
													position='start'
													style={{padding: 0}}
													className={classes.customText}
												>{`${
													modifiedLessonName.length
														? modifiedLessonName.length
														: 0
												}/64`}</InputAdornment>
											),
										}}
									/>

									{modifiedLessonName &&
										modifiedLessonName.trim().length > 64 && (
											<Typography color={'error'}>
												Must not Exceed 64 characters
											</Typography>
										)}

									{(!modifiedLessonName ||
										modifiedLessonName.trim().length === 0) && (
										<Typography color={'error'}>Required</Typography>
									)}
								</div>
							),
						},
						{
							label: 'Created At',
							render: record => (
								<div style={{height: '40px', paddingTop: '5px'}}>
									<TimeDateField record={lessonData} source='createdAt' />
								</div>
							),
						},
						{
							label: 'Modified',
							render: record => (
								<div style={{height: '40px', paddingTop: '5px'}}>
									<TimeDateField record={lessonData} source='updatedAt' />
								</div>
							),
						},
						{
							label: 'Type',
							render: record => (
								<div style={{height: '40px', paddingTop: '5px'}}>
									<StatusChip record={lessonData} source='lessonType' />
								</div>
							),
						},
					]}
				/>

				<div style={{display: 'flex', columnGap: '30px'}}>
					<div className={classes.left}>
						{/* Description */}

						<div className={classes.header} style={{marginBottom: '12px'}}>
							<Typography
								style={{
									fontFamily: 'Montserrat Semi Bold',
									fontSize: '16px',
								}}
							>
								Description
							</Typography>
							<Typography className={classes.customText}>{`(${
								modifiedDescription.length ? modifiedDescription.length : 0
							}/1000):`}</Typography>
						</div>

						<TextareaAutosize
							style={{
								minWidth: '100%',
								maxWidth: '100%',
							}}
							className={classes.textArea}
							id={'description'}
							value={modifiedDescription}
							onChange={e => setModifiedDescription(e.target.value)}
							color={'secondary'}
							variant={'contained'}
							inputprops={{
								className: classes.textarea,
							}}
						/>
						{renderMaxCharactersError(modifiedDescription)}
					</div>

					<div className={classes.right}>
						{/* Learning Objectives */}

						<div className={classes.header} style={{marginBottom: '12px'}}>
							<Typography
								style={{
									fontFamily: 'Montserrat Semi Bold',
									fontSize: '16px',
								}}
							>
								Learning Objectives
							</Typography>

							<Typography className={classes.customText}>{`(${
								modifiedLearningObjectives.length
									? modifiedLearningObjectives.length
									: 0
							}/1000):`}</Typography>
						</div>

						<TextareaAutosize
							style={{
								minWidth: '100%',
								maxWidth: '100%',
							}}
							className={classes.textArea}
							id={'learningObjectives'}
							value={modifiedLearningObjectives}
							onChange={e => setModifiedLearningObjectives(e.target.value)}
							color={'secondary'}
							variant={'contained'}
							inputprops={{
								className: classes.textarea,
							}}
						/>

						{renderMaxCharactersError(modifiedLearningObjectives)}
					</div>
				</div>
			</div>
		</div>
	);
};

const myStyles = theme =>
	createStyles({
		textInput: {
			'& input': {
				padding: '5px',
			},
			width: 'auto',
		},
		textarea: {
			resize: 'both',
		},
		header: {
			backgroundColor: theme.palette.background.default,
			padding: '8px 16px',
			display: 'flex',
			alignItems: 'baseline',
			gap: '5px',
			'& p:first-of-type': {
				fontFamily: 'Montserrat Semi Bold',
				fontSize: '16px',
			},
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
		section: {
			padding: '12px 0px',
		},
		normalText: {
			fontFamily: 'Montserrat Regular',
		},
		textArea: {
			fontFamily: 'Montserrat Regular',
			fontSize: '16px',
			wordSpacing: '5px',
			lineHeight: '20px',
			border: `1px solid ${theme.palette.background.default}`,
			padding: '12px',
			'&:focus': {
				border: `2px solid ${theme.palette.background.default}`,
				outline: ' none !important',
			},
			minHeight: '200px',
			maxHeight: 'auto',
		},
		left: {
			width: ' calc(50% - 15px)',
			float: 'left',
		},
		right: {
			width: ' calc(50% - 15px)',
			float: 'right',
		},
		customText: {
			color: '#85A0AE !important',
			'& p': {
				color: '#85A0AE !important',
			},
		},
	});

const enhance = compose(withStyles(myStyles));

export default enhance(EditLessonContent);
