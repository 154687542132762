
import { DiagramModel } from '@projectstorm/react-diagrams';

import { DataOptions } from 'components/VectorMap/OptionsBlade';

import { LabelNodeModel } from 'components/VectorMap/custom/Common/LabelNode/LabelNodeModel';
import { SkillNodeModel } from 'components/VectorMap/custom/SkillMap/SkillNode/SkillNodeModel';

import { getFilterOption, getGroupOption, getHeatmapOption } from '../options';
import { extractValue, sortByString, sortByNumber, capitalizeString } from '../helpers';
import { GraphNode, DEFAULT_NODE_BACKGROUND_COLOR, DEFAULT_NODE_FONT_COLOR, LABEL_NODE_WIDTH, LABEL_NODE_HEIGHT, SKILL_NODE_SIZE, VectorMapContext, VectorMapContextData } from '../model';
import { determineHeatmapNodeColorByBoolean, determineHeatmapNodeColorByPercentage } from './helpers';

const DEFAULT_SPACING = 10;
const OUTER_SPACING = 50;
const INNER_SPACING = 3;

/**
 * Apply any filters from the supplied data options.
 * 
 * @param data 
 * @param options 
 */
const applyFilters = (context: VectorMapContext, data: Array<any>, options?: DataOptions | null, roles?: Array<string> | null, contextData?: VectorMapContextData | null): Array<any> => {
    let results = data.map(item => Object.assign({}, item));

    if (options && options.filter) {
        console.log("Applying Filter", options.filter);

        // Collect the distinct skill types.
        const skillTypes: Array<string> = extractValue(data, 'type');
        skillTypes.sort(sortByString(null));

        // Determine the "visible" roles based on the current 'linked-roles' filter (if applicable).
        const linkedRolesFilter = getFilterOption(options.filter, "role-info.linked-roles");
        const visibleLinkedRoles: Array<string> = [];
        if (linkedRolesFilter && linkedRolesFilter.children && linkedRolesFilter.children.length > 0) {
            linkedRolesFilter.children.forEach(item => {
                if (item.value === true && !visibleLinkedRoles.includes(item.id)) visibleLinkedRoles.push(item.id);
            });

            // Handle to "No Job Role Assigned" scenario.
            // Basically, in this case all "other" roles get displayed.
            if (visibleLinkedRoles.length === 1 && visibleLinkedRoles[0] === '__no_job_role_assigned__') {
                visibleLinkedRoles.length = 0;

                const otherRolesFilter = getFilterOption(options.filter, "role-info.other-roles");
                if (otherRolesFilter && otherRolesFilter.children && otherRolesFilter.children.length > 0) {
                    otherRolesFilter.children.forEach(item => {
                        if (!visibleLinkedRoles.includes(item.id)) visibleLinkedRoles.push(item.id);
                    });
                }
            }
        }

        // Determine the "visible" roles based on the current 'other-roles' filter (if applicable).
        const otherRolesFilter = getFilterOption(options.filter, "role-info.other-roles");
        const visibleOtherRoles: Array<string> = [];
        if (otherRolesFilter && otherRolesFilter.children && otherRolesFilter.children.length > 0) {
            otherRolesFilter.children.forEach(item => {
                if (item.value === true && !visibleOtherRoles.includes(item.id)) visibleOtherRoles.push(item.id);
            });
        }

        // Determine the "hidden" skills based on the current 'related-skills-only' filter (if applicable).
        const relatedSKillsOnlyFilter = getFilterOption(options.filter, "skill-info.related.related-skills-only");
        const hiddenUnrelatedSkills: Array<any> = [];
        if (relatedSKillsOnlyFilter != null && relatedSKillsOnlyFilter.value) {
            console.log("Applying Related Skills Only Filter...", contextData);

            if (context.subContext === 'lesson') {
                // Filter out every skill that is not related to the indicated training/lesson.
                results.forEach(skill => {
                    if (skill.associatedLessons.find(item => item.trainingId === contextData.trainingId && item.id === contextData.lessonId) == null) {
                        hiddenUnrelatedSkills.push(skill);
                    } else {
                        console.log("Found Skill Related To LESSON", skill);
                    }
                });
            } else if (context.subContext === 'training') {
                // Filter out every skill that is not related to the indicated training.
                results.forEach(skill => {
                    if (skill.associatedTraining.find(item => item.id === contextData.trainingId) == null) {
                        hiddenUnrelatedSkills.push(skill);
                    } else {
                        console.log("Found Skill Related To TRAINING", skill);
                    }
                });
            } else {
                // USER Context, related skills are those that are possessed by the user.
                // TEAM Context, related skills are those that are possessed by the team.
                // ORG Context, related skills are those that are possessed by the org.
                //
                // These are all filtered based on the userValue of the skill being greater than 0 (and non-null of course).
                results.forEach(skill => {
                    if (skill.userValue == null || skill.userValue === 0) {
                        hiddenUnrelatedSkills.push(skill);
                    }
                });
            }
        }
        const visibleRelatedSkills = results.filter(item => {
            return !hiddenUnrelatedSkills.includes(item);
        });

        console.log("Hidden Skills (by show related only)", hiddenUnrelatedSkills);
        console.log("Visible Skills (by show related only)", visibleRelatedSkills);

        // Determine the "hidden" types based on the current 'type' filters (if applicable).
        const typeFilter = getFilterOption(options.filter, "skill-info.type");
        const hiddenTypes: Array<string> = [];
        if (typeFilter && typeFilter.children && typeFilter.children.length > 0) {
            typeFilter.children.forEach(item => {
                if (item.value === false && !hiddenTypes.includes(item.id)) hiddenTypes.push(item.id);
            });
        }

        // Determine the "hidden" categories based on the current 'category' filters (if applicable).
        const hiddenCategories: Array<{ type: string, categories: Array<string> }> = [];
        skillTypes.forEach(type => {
            hiddenCategories.push({ type: type, categories: [] });
            const categoryFilter = getFilterOption(options.filter, "skill-info." + type + "-categories");
            if (categoryFilter && categoryFilter.children && categoryFilter.children.length > 0) {
                categoryFilter.children.forEach(item => {
                    if (item.value === false && !hiddenCategories[hiddenCategories.length - 1].categories.includes(item.id)) hiddenCategories[hiddenCategories.length - 1].categories.push(item.id);
                });
            }
        });

        // Filter the data using all computed "visible" and "hidden" collections.
        results = results.filter(item => {
            let visible = false;
            let hidden = false;

            // Process "visible" filters (those indicating a skill should be visible).
            // This is limited to the 'linked-roles' and 'other-roles' filters. All other filters indicate if a skill is hidden
            if (visibleLinkedRoles.find(role => item.associatedRoles && (item.associatedRoles as Array<string>).includes(role)) != null) visible = true;
            if (visibleOtherRoles.find(role => item.associatedRoles && (item.associatedRoles as Array<string>).includes(role)) != null) visible = true;

            if (visible) {
                // Process "hidden" filters (those indicating a skill should be hidden).
                if (hiddenUnrelatedSkills.find(skill => skill === item) != null) hidden = true;
                if (hiddenTypes.find(type => item.type === type) != null) hidden = true;
                hiddenCategories.forEach(value => {
                    if (item.type === value.type && value.categories.find(category => item.skillGroup === category) != null) hidden = true;
                });
            }

            return visible && !hidden;
        });
    }

    return results;
}

/**
 * Apply any sort from the supplied data options.
 * 
 * @param nodes 
 * @param options 
 * @param sortByGroupSize 
 */
const applySort = (_context: VectorMapContext, nodes: Array<GraphNode>, options?: DataOptions | null) => {
    if (options) {
        // Get the selected sorting option/value.
        const sortByOption = options.sort && options.sort.options ? options.sort.options.find(item => item.value === options.sort.value) : null;
        const sortByValue = sortByOption ? sortByOption.value : null;

        // Get the selected grouping option/value
        const groupByOption = options.group && options.group.options ? options.group.options.find(item => item.value === options.group.value) : null;
        const groupByValue = groupByOption ? groupByOption.value : null

        // Get the selected training/lesson grouping selections (if applicable).
        const trainingGroupOption = groupByValue === 'related-training' ? getGroupOption(options.group.children, 'related-training') : null;
        const trainingGroupOptionValue = trainingGroupOption ? trainingGroupOption.value : null;
        const lessonGroupOption = trainingGroupOptionValue ? getGroupOption(options.group.children, 'related-training.' + trainingGroupOptionValue) : null;
        const lessonGroupOptionValue = lessonGroupOption ? lessonGroupOption.value : null;

        console.log("Applying Sort (GroupBy: " + groupByValue + ")", sortByValue);

        nodes.forEach(node => {
            if (node.nodes) {
                switch (sortByValue) {
                    case 'ontology':
                        node.nodes.sort(sortByNumber('sortOrder', false, true));

                        break;
                    case 'name-asc':
                        node.nodes.sort(sortByString('displayTitle', false, true));

                        break;
                    case 'name-desc':
                        node.nodes.sort(sortByString('displayTitle', true, true));

                        break;
                    case 'skill-points-acquired-asc':
                        node.nodes.sort(sortByNumber('userValue', false, true));

                        break;
                    case 'skill-points-acquired-desc':
                        node.nodes.sort(sortByNumber('userValue', true, true));

                        break;
                    case 'skill-points-earnable-training-asc':
                        if (trainingGroupOptionValue) {
                            node.nodes.sort((a: any, b: any) => {
                                const aTraining = a && a.record && a.record.associatedTraining ? a.record.associatedTraining.find((item: any) => item.id === trainingGroupOptionValue) : null;
                                const bTraining = b && b.record && b.record.associatedTraining ? b.record.associatedTraining.find((item: any) => item.id === trainingGroupOptionValue) : null;

                                return sortByNumber('maxValue', false, false)(aTraining, bTraining);
                            });
                        }

                        break;
                    case 'skill-points-earnable-training-desc':
                        if (trainingGroupOptionValue) {
                            node.nodes.sort((a: any, b: any) => {
                                const aTraining = a && a.record && a.record.associatedTraining ? a.record.associatedTraining.find((item: any) => item.id === trainingGroupOptionValue) : null;
                                const bTraining = b && b.record && b.record.associatedTraining ? b.record.associatedTraining.find((item: any) => item.id === trainingGroupOptionValue) : null;

                                return sortByNumber('maxValue', true, false)(aTraining, bTraining);
                            });
                        }

                        break;
                    case 'skill-points-earnable-lesson-asc':
                        if (lessonGroupOptionValue) {
                            node.nodes.sort((a: any, b: any) => {
                                const aLesson = a && a.record && a.record.associatedLessons ? a.record.associatedLessons.find((item: any) => item.id === lessonGroupOptionValue) : null;
                                const bLesson = b && b.record && b.record.associatedLessons ? b.record.associatedLessons.find((item: any) => item.id === lessonGroupOptionValue) : null;

                                return sortByNumber('maxValue', false, false)(aLesson, bLesson);
                            });
                        }

                        break;
                    case 'skill-points-earnable-lesson-desc':
                        if (lessonGroupOptionValue) {
                            node.nodes.sort((a: any, b: any) => {
                                const aLesson = a && a.record && a.record.associatedLessons ? a.record.associatedLessons.find((item: any) => item.id === lessonGroupOptionValue) : null;
                                const bLesson = b && b.record && b.record.associatedLessons ? b.record.associatedLessons.find((item: any) => item.id === lessonGroupOptionValue) : null;

                                return sortByNumber('maxValue', true, false)(aLesson, bLesson);
                            });
                        }

                        break;
                    default:
                        // Do nothing.
                }
            }

            console.log("Sorted Group", node.id, node.nodes);
        });
    }
}

/**
 * Determine the groups/clusters based on the supplied options.
 * 
 * @param data 
 * @param options 
 */
const determineGroups = (_context: VectorMapContext, data: Array<any>, options?: DataOptions | null): Array<GraphNode> => {
    const results: Array<GraphNode> = [];

    const rootGroupOption = options && options.group ? options.group : null;
    const rootGroupOptionValue = rootGroupOption && rootGroupOption.value !== '' ? rootGroupOption.value : null;

    if (rootGroupOptionValue) {
        switch (rootGroupOptionValue) {
            case 'type':
                const distinctTypes: Array<string> = extractValue(data, 'type');

                distinctTypes.forEach(item => {
                    results.push({ id: item, type: 'label', record: { name: capitalizeString(item) }, nodes: [] });
                });

                // Sort the "type" groups such that they are in alphabetical order except that the HARD type always comes last.
                results.sort(sortByString('id'));
                results.sort((a: any, b: any) => (a.id ? a.id : '').toLowerCase() !== 'hard' && (b.id ? b.id : '').toLowerCase() === 'hard' ? -1 : 0);

                break;
            case 'category':
                const distinctCategories: Array<string> = extractValue(data, 'skillGroup');

                distinctCategories.forEach(item => {
                    results.push({ id: item, type: 'label', record: { name: item }, nodes: [] });
                });

                // Sort the "skillGroup" groups such that they are in alphabetical order except that the HARD skillGroup always comes last.
                results.sort(sortByString('id'));
                results.sort((a: any, b: any) => (a.id ? a.id : '').toLowerCase() !== 'hard' && (b.id ? b.id : '').toLowerCase() === 'hard' ? -1 : 0);

                break;
            case 'linked-roles':
                const linkedRolesGroupOption = getGroupOption(rootGroupOption.children, 'linked-roles');
                const linkedRolesGroupOptionValue = linkedRolesGroupOption && linkedRolesGroupOption.value !== '' ? linkedRolesGroupOption.value : null;

                if (linkedRolesGroupOptionValue != null) {
                    results.push({ id: linkedRolesGroupOptionValue, type: 'label', record: { name: linkedRolesGroupOptionValue }, nodes: [] });
                    results.push({ id: null, type: 'label', record: { name: 'Other Skills' }, nodes: [] });
                } else {
                    results.push({ id: '', type: 'label', record: { name: 'All Linked Roles' }, nodes: [] });
                    results.push({ id: null, type: 'label', record: { name: 'Other Skills' }, nodes: [] });
                }

                break;
            case 'other-roles':
                const otherRolesGroupOption = getGroupOption(rootGroupOption.children, 'other-roles');
                const otherRolesGroupOptionValue = otherRolesGroupOption && otherRolesGroupOption.value !== '' ? otherRolesGroupOption.value : null;

                if (otherRolesGroupOptionValue != null) {
                    results.push({ id: otherRolesGroupOptionValue, type: 'label', record: { name: otherRolesGroupOptionValue }, nodes: [] });
                    results.push({ id: null, type: 'label', record: { name: 'Other Skills' }, nodes: [] });
                } else {
                    results.push({ id: '', type: 'label', record: { name: 'All Other Roles' }, nodes: [] });
                    results.push({ id: null, type: 'label', record: { name: 'Other Skills' }, nodes: [] });
                }

                break;
            case 'related-training':
                const trainingGroupOption = getGroupOption(rootGroupOption.children, 'related-training');
                const trainingGroupOptionValue = trainingGroupOption && trainingGroupOption.value !== '' ? trainingGroupOption.value : null;
                const trainingGroupValueOption = trainingGroupOptionValue && trainingGroupOption.options ? trainingGroupOption.options.find(item => item.value === trainingGroupOptionValue) : null;
                const lessonGroupOption = trainingGroupOptionValue ? getGroupOption(trainingGroupOption.children, trainingGroupOptionValue) : null;
                const lessonGroupOptionValue = lessonGroupOption && lessonGroupOption.value !== '' ? lessonGroupOption.value : null;
                const lessonGroupValueOption = lessonGroupOptionValue && lessonGroupOption.options ? lessonGroupOption.options.find(item => item.value === lessonGroupOptionValue) : null;

                if (trainingGroupOptionValue != null) {
                    if (lessonGroupOptionValue != null) {
                        results.push({ id: lessonGroupOptionValue, type: 'label', record: { name: trainingGroupValueOption.label + ' : ' + lessonGroupValueOption.label }, nodes: [] });
                        results.push({ id: null, type: 'label', record: { name: 'Other Skills' }, nodes: [] });
                    } else {
                        results.push({ id: trainingGroupOptionValue, type: 'label', record: { name: trainingGroupValueOption.label + ' : All Lessons' }, nodes: [] });
                        results.push({ id: null, type: 'label', record: { name: 'Other Skills' }, nodes: [] });
                    }
                } else {
                    results.push({ id: '', type: 'label', record: { name: 'All Training' }, nodes: [] });
                    results.push({ id: null, type: 'label', record: { name: 'Other Skills' }, nodes: [] });
                }

                break;
            default:
                // Do nothing.
        }
    }

    // If no groups can be determined then we fallback to "All Skills".
    if (results.length === 0) {
        results.push({ id: null, type: 'label', record: { name: 'All Skills' }, nodes: [] });
    }

    console.log("Determined Groups", results.map(item => item.record.name));

    return results;
}

/**
 * Populate the supplied groups/clusters based on the supplied options.
 * 
 * @param groups 
 * @param data 
 * @param options 
 */
const populateGroups = (_context: VectorMapContext, groups: Array<GraphNode>, data: Array<any>, options?: DataOptions | null): void => {
    const rootGroupOption = options && options.group ? options.group : null;
    const rootGroupOptionValue = rootGroupOption && rootGroupOption.value !== '' ? rootGroupOption.value : null;

    groups.forEach(group => {
        let groupData: Array<any> | null = null;

        switch (rootGroupOptionValue) {
            case 'type':
                groupData = data.filter(item => item.type === group.id);

                break;
            case 'category':
                groupData = data.filter(item => item.skillGroup === group.id);

                break;
            case 'linked-roles':
                const linkedRolesGroupOption = getGroupOption(rootGroupOption.children, 'linked-roles');
                const linkedRolesGroupOptionValue = linkedRolesGroupOption ? linkedRolesGroupOption.value : null;

                const groupingBySpecificLinkedRole = linkedRolesGroupOptionValue != null && linkedRolesGroupOptionValue !== '';

                if (groupingBySpecificLinkedRole) {
                    if (group.id != null) {
                        groupData = data.filter(item => {
                            return item.associatedRoles && item.associatedRoles.includes(linkedRolesGroupOptionValue) === true;
                        });
                    } else {
                        groupData = data.filter(item => {
                            return item.associatedRoles && item.associatedRoles.includes(linkedRolesGroupOptionValue) === false;
                        });
                    }
                } else {
                    const allLinkedRoles = linkedRolesGroupOption ? linkedRolesGroupOption.options.filter(item => item.value !== '').map(item => item.value) : [];

                    if (group.id != null) {
                        groupData = data.filter(item => {
                            return item.associatedRoles && item.associatedRoles.find((item: any) => {
                                return allLinkedRoles.includes(item);
                            }) != null;
                        });
                    } else {
                        groupData = data.filter(item => {
                            return item.associatedRoles && item.associatedRoles.find((item: any) => {
                                return allLinkedRoles.includes(item);
                            }) == null;
                        });
                    }
                }

                break;
            case 'other-roles':
                const otherRolesGroupOption = getGroupOption(rootGroupOption.children, 'other-roles');
                const otherRolesGroupOptionValue = otherRolesGroupOption ? otherRolesGroupOption.value : null;

                const groupingBySpecificOtherRole = otherRolesGroupOptionValue != null && otherRolesGroupOptionValue !== '';

                if (groupingBySpecificOtherRole) {
                    if (group.id != null) {
                        groupData = data.filter(item => {
                            return item.associatedRoles && item.associatedRoles.includes(otherRolesGroupOptionValue) === true;
                        });
                    } else {
                        groupData = data.filter(item => {
                            return item.associatedRoles && item.associatedRoles.includes(otherRolesGroupOptionValue) === false;
                        });
                    }
                } else {
                    const allOtherRoles = otherRolesGroupOption ? otherRolesGroupOption.options.filter(item => item.value !== '').map(item => item.value) : [];

                    if (group.id != null) {
                        groupData = data.filter(item => {
                            return item.associatedRoles && item.associatedRoles.find((item: any) => {
                                return allOtherRoles.includes(item);
                            }) != null;
                        });
                    } else {
                        groupData = data.filter(item => {
                            return item.associatedRoles && item.associatedRoles.find((item: any) => {
                                return allOtherRoles.includes(item);
                            }) == null;
                        });
                    }
                }

                break;
            case 'related-training':
                const trainingGroupOption = getGroupOption(rootGroupOption.children, rootGroupOption.value);
                const trainingGroupOptionValue = trainingGroupOption && trainingGroupOption.value != null && trainingGroupOption.value !== '' ? trainingGroupOption.value : null;
                const lessonGroupOption = trainingGroupOptionValue != null ? getGroupOption(trainingGroupOption.children, trainingGroupOptionValue) : null;
                const lessonGroupOptionValue = lessonGroupOption && lessonGroupOption.value != null && lessonGroupOption.value !== '' ? lessonGroupOption.value : null;

                const groupingBySpecificTraining = trainingGroupOptionValue != null && trainingGroupOptionValue !== '' && (lessonGroupOptionValue == null || lessonGroupOptionValue === '');
                const groupingBySpecificLesson = trainingGroupOptionValue != null && trainingGroupOptionValue !== '' && lessonGroupOptionValue != null && lessonGroupOptionValue !== '';

                if (groupingBySpecificLesson) {
                    if (group.id != null) {
                        groupData = data.filter(item => {
                            return item.associatedLessons && item.associatedLessons.find((item: any) => item.trainingId === trainingGroupOptionValue && item.id === lessonGroupOptionValue) != null;
                        });
                    } else {
                        groupData = data.filter(item => {
                            return item.associatedLessons && item.associatedLessons.find((item: any) => item.trainingId === trainingGroupOptionValue && item.id === lessonGroupOptionValue) == null;
                        });
                    }
                } else if (groupingBySpecificTraining) {
                    if (group.id != null) {
                        groupData = data.filter(item => {
                            return item.associatedTraining && item.associatedTraining.find((item: any) => item.id === trainingGroupOptionValue) != null;
                        });
                    } else {
                        groupData = data.filter(item => {
                            return item.associatedTraining && item.associatedTraining.find((item: any) => item.id === trainingGroupOptionValue) == null;
                        });
                    }
                } else {
                    const allTraining = trainingGroupOption && trainingGroupOption.options ? trainingGroupOption.options.filter(item => item.value !== '').map(item => item.value) : [];

                    if (group.id != null) {
                        groupData = data.filter(item => {
                            return item.associatedTraining && item.associatedTraining.find((item: any) => allTraining.includes(item.id)) != null;
                        });
                    } else {
                        groupData = data.filter(item => {
                            return item.associatedTraining && item.associatedTraining.find((item: any) => allTraining.includes(item.id)) == null;
                        });
                    }
                }

                break;
            default:
                groupData = data;
        }

        if (groupData) {
            groupData.forEach(item => {
                group.nodes.push({ id: item.id, type: 'skill', record: item, nodes: null });
            });
        }
    });

    console.log("Populated Groups", groups.map(group => {
        return {
            group: group.id,
            nodes: group.nodes.map(node => {
                if (node.record) {
                    if (node.record.name != null) return node.record.name;
                    else if (node.record.displayTitle != null) return node.record.displayTitle;
                    else if (node.record.is != null) return node.record.id;
                    else return node.record;
                } else {
                    return null;
                }
            })
        };
    }));
}

const convertToGraph = (context: VectorMapContext, data: Array<any>, options?: DataOptions | null, roles?: Array<string> | null, contextData?: VectorMapContextData | null): Array<GraphNode> => {
    // First, we apply any supplied filters.
    const filteredData = applyFilters(context, data, options, roles, contextData);

    // Next we determine the actual groups/clusters.
    const groups = determineGroups(context, filteredData, options);

    // Next we populate the actual groups/clusters.
    populateGroups(context, groups, filteredData, options);

    // Finally, we apply sorting.
    applySort(context, groups, options);

    // Optionally remove any group that has no sub-nodes (if the corresponding "other" option is checked).
    if (options && options.other && options.other.hideEmptyGroups) {
        return groups.filter(group => group != null && group.nodes && group.nodes.length > 0);
    } else {
        return groups;
    }
}

const generateLabelNode = (_context: VectorMapContext, record: { [prop: string]: any }, x: number, y: number, width: number, height: number) => {
    const labelNode = new LabelNodeModel();

    labelNode.setPosition(x, y);
    labelNode.setWidth(width > LABEL_NODE_WIDTH ? width : LABEL_NODE_WIDTH);
    labelNode.setHeight(height > LABEL_NODE_HEIGHT ? height : LABEL_NODE_HEIGHT);
    labelNode.setRecord({ label: record.name ? record.name : '' });
    labelNode.setFontColor('#000000');

    return labelNode;
}

const generateSkillNode = (context: VectorMapContext, record: { [prop: string]: any }, x: number, y: number, width: number, height: number, onViewRecordDetails?: (record: any) => void, options?: DataOptions) => {
    const skillNode = new SkillNodeModel();

    skillNode.setPosition(x, y);
    skillNode.setWidth(width);
    skillNode.setHeight(height);
    skillNode.setContext(context.context);
    skillNode.setSubContext(context.subContext ? context.subContext : null);
    skillNode.setRecord(record);
    skillNode.setDataOptions(options);
    skillNode.setBackgroundColor(record.mainColour ? record.mainColour : DEFAULT_NODE_BACKGROUND_COLOR);
    skillNode.setFontColor(record.fontColour ? record.fontColour : DEFAULT_NODE_FONT_COLOR);
    skillNode.setViewRecordDetailsHandler(onViewRecordDetails);

    return skillNode;
}

const generateHeatmapSkillNode = (context: VectorMapContext, record: { [prop: string]: any }, x: number, y: number, width: number, height: number, onViewRecordDetails?: (record: any) => void, options?: DataOptions) => {
    const extra = record ? record.extra : null;
    const heatmapValue = extra ? extra.heatmapValue : null;

    const skillNode = new SkillNodeModel();

    skillNode.setPosition(x, y);
    skillNode.setWidth(width);
    skillNode.setHeight(height);
    skillNode.setContext(context.context);
    skillNode.setSubContext(context.subContext ? context.subContext : null);
    skillNode.setRecord(record);
    skillNode.setDataOptions(options);
    skillNode.setBackgroundColor(record.mainColour ? record.mainColour : DEFAULT_NODE_BACKGROUND_COLOR);
    skillNode.setFontColor(record.fontColour ? record.fontColour : DEFAULT_NODE_FONT_COLOR);
    skillNode.setViewRecordDetailsHandler(onViewRecordDetails);

    // Based on the selected heatmap determine the appropiate background color.
    if (heatmapValue) {
        const heatmapOption = options.heatmap;

        switch (heatmapValue) {
            case 'linked-roles':
                const linkedRolesHeatmapOption = getHeatmapOption(heatmapOption.children, 'linked-roles');
                const linkedRolesHeatmapOptionValue = linkedRolesHeatmapOption ? linkedRolesHeatmapOption.value : null;

                const relatedLinkedRole = record && record.associatedRoles ? record.associatedRoles.find((item: any) => item === linkedRolesHeatmapOptionValue) : null;

                if (linkedRolesHeatmapOptionValue) {
                    if (relatedLinkedRole != null) skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(true));
                    else skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(false));
                } else {
                    skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(true));
                }

                break;
            case 'other-roles':
                const otherRolesHeatmapOption = getHeatmapOption(heatmapOption.children, 'other-roles');
                const otherRolesHeatmapOptionValue = otherRolesHeatmapOption ? otherRolesHeatmapOption.value : null;

                const relatedOtherRole = record && record.associatedRoles ? record.associatedRoles.find((item: any) => item === otherRolesHeatmapOptionValue) : null;

                if (otherRolesHeatmapOptionValue) {
                    if (relatedOtherRole != null) skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(true));
                    else skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(false));
                } else {
                    skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(true));
                }

                break;
            case 'related-training':
                const relatedTrainingHeatmapOption = getHeatmapOption(heatmapOption.children, 'related-training');
                const relatedTrainingHeatmapOptionValue = relatedTrainingHeatmapOption ? relatedTrainingHeatmapOption.value : null;
                const relatedLessonHeatmapOption = getHeatmapOption(relatedTrainingHeatmapOption.children, relatedTrainingHeatmapOptionValue);
                const relatedLessonHeatmapOptionValue = relatedLessonHeatmapOption ? relatedLessonHeatmapOption.value : null;

                const relatedTraining = record && record.associatedTraining ? record.associatedTraining.find((item: any) => item.id === relatedTrainingHeatmapOptionValue) : null;
                const relatedLesson = record && record.associatedLessons ? record.associatedLessons.find((item: any) => item.trainingId === relatedTrainingHeatmapOptionValue && item.id === relatedLessonHeatmapOptionValue) : null;

                if (relatedTrainingHeatmapOptionValue && relatedLessonHeatmapOptionValue) {
                    if (relatedLesson != null) skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(true));
                    else skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(false));
                } else if (relatedTrainingHeatmapOptionValue) {
                    if (relatedTraining != null) skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(true));
                    else skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(false));
                } else {
                    skillNode.setBackgroundColor(determineHeatmapNodeColorByBoolean(true));
                }

                break;
            case 'skill-points-acquired':
                const skillPointsAcquiredTrainingHeatmapOption = getHeatmapOption(heatmapOption.children, 'skill-points-acquired');
                const skillPointsAcquiredTrainingHeatmapOptionValue = skillPointsAcquiredTrainingHeatmapOption ? skillPointsAcquiredTrainingHeatmapOption.value : null;
                const skillPointsAcquiredLessonHeatmapOption = getHeatmapOption(skillPointsAcquiredTrainingHeatmapOption.children, skillPointsAcquiredTrainingHeatmapOptionValue);
                const skillPointsAcquiredLessonHeatmapOptionValue = skillPointsAcquiredLessonHeatmapOption ? skillPointsAcquiredLessonHeatmapOption.value : null;

                const skillPointsTraining = record && record.associatedTraining ? record.associatedTraining.find((item: any) => item.id === skillPointsAcquiredTrainingHeatmapOptionValue) : null;
                const skillPointsLesson = record && record.associatedLessons ? record.associatedLessons.find((item: any) => item.trainingId === skillPointsAcquiredTrainingHeatmapOptionValue && item.id === skillPointsAcquiredLessonHeatmapOptionValue) : null;

                if (skillPointsAcquiredTrainingHeatmapOptionValue && skillPointsAcquiredLessonHeatmapOptionValue) {
                    if (skillPointsLesson != null) skillNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(skillPointsLesson.userValue, skillPointsLesson.maxValue));
                    else skillNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(null, null));
                } else if (skillPointsAcquiredTrainingHeatmapOptionValue) {
                    if (skillPointsTraining != null) skillNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(skillPointsTraining.userValue, skillPointsTraining.maxValue));
                    else skillNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(null, null));
                } else {
                    skillNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(record.userValue, record.maxValue));
                }

                break;
            default:
                // Do nothing.
        }
    }

    return skillNode;
}

const generateModel = (context: VectorMapContext, data: Array<any>, onViewRecordDetails?: (record: any) => void, options?: DataOptions | null, roles?: Array<string> | null, contextData?: VectorMapContextData | null): DiagramModel => {
    console.log("Generating model for Skill Map (Context = " + JSON.stringify(context) + ") ...");

    const rootGroupOption = options && options.group ? options.group : null;
    const rootGroupOptionValue = rootGroupOption && rootGroupOption.value != null ? rootGroupOption.value : '';

    const rootHeatmapOption = options && options.heatmap ? options.heatmap : null;
    const rootHeatmapOptionValue = rootHeatmapOption && rootHeatmapOption.value != null ? rootHeatmapOption.value : '';

    // Fist we convert the supplied data collection into a node graph.
    const graph = convertToGraph(context, data, options, roles, contextData);

    // Next we dynamically determine a preferred "MAX_GROUPS_PER_ROW" and "MAX_CHILDREN_PER_ROW".
    const maxGroupsPerRow = options && options.other && options.other.groupsPerRow != null ? options.other.groupsPerRow : 4;
    const maxItemsPerRow = options && options.other && options.other.itemsPerRow != null ? options.other.itemsPerRow : 5;

    const nodes = [];
    const links = [];

    // Determine if the resulting graph is empty (meaning there are no groups with actual nodes).
    let isGraphEmpty = true;
    if (graph) {
        graph.forEach(group => {
            if (group && group.nodes && group.nodes.length > 0) isGraphEmpty = false;
        });
    }

    // Proceed with laying out the graph nodes.
    if (!isGraphEmpty) {
        let groupX = DEFAULT_SPACING;
        let groupY = DEFAULT_SPACING;

        let tallestGroupHeight = 0;

        for (let x = 0; x < graph.length; x++) {
            const group = graph[x];

            if (!group) continue;

            // Determine the "real" size of the entire group.
            const groupWidth = determineGroupWidth(group.nodes, maxItemsPerRow);
            const groupHeight = determineGroupHeight(group.nodes, maxItemsPerRow);

            // Track the "tallest" group for the current group row.
            if (groupHeight > tallestGroupHeight) tallestGroupHeight = groupHeight;

            // Construct the label node for the group.
            nodes.push(generateLabelNode(context, group.record, groupX, groupY, groupWidth, LABEL_NODE_HEIGHT));

            if (group.nodes && group.nodes.length > 0) {
                let childX = 0;
                let childY = LABEL_NODE_HEIGHT;

                for (let y = 0; y < group.nodes.length; y++) {
                    const item = group.nodes[y];

                    if (!item) continue;

                    // Construct the skill node for the record.
                    if (options && options.heatmap && options.heatmap.value) {
                        nodes.push(generateHeatmapSkillNode(
                            context,
                            Object.assign({
                                extra: {
                                    groupId: group.id,
                                    heatmapValue: rootHeatmapOptionValue,
                                }
                            }, item.record),
                            groupX + childX,
                            groupY + childY,
                            SKILL_NODE_SIZE,
                            SKILL_NODE_SIZE,
                            onViewRecordDetails,
                            options
                        ));
                    } else {
                        nodes.push(generateSkillNode(
                            context,
                            Object.assign({
                                extra: {
                                    groupId: group.id,
                                    groupByValue: rootGroupOptionValue,
                                }
                            }, item.record),
                            groupX + childX,
                            groupY + childY,
                            SKILL_NODE_SIZE,
                            SKILL_NODE_SIZE,
                            onViewRecordDetails,
                            options
                        ));
                    }

                    if ((y + 1) % maxItemsPerRow === 0) {
                        childX = 0;
                        childY += SKILL_NODE_SIZE + INNER_SPACING;
                    } else {
                        childX += SKILL_NODE_SIZE + INNER_SPACING;
                    }
                }
            }

            if ((x + 1) % maxGroupsPerRow === 0) {
                groupX = DEFAULT_SPACING;
                groupY += tallestGroupHeight + OUTER_SPACING;
                tallestGroupHeight = 0;
            } else {
                groupX += groupWidth + OUTER_SPACING;
            }
        }
    }

    const model = new DiagramModel();

    model.addAll(...[...nodes, ...links]);
    model.setLocked(true);

    return model;
}

/**
 * Determines the "real" width of a group.
 * 
 * @param nodes 
 * @param maxGroupsPerRow 
 */
const determineGroupWidth = (nodes: Array<GraphNode>, maxGroupsPerRow: number): number => {
    let width = 0;

    if (nodes == null || nodes.length === 0) {
        width = SKILL_NODE_SIZE;
    } else if (nodes.length <= maxGroupsPerRow) {
        width = (SKILL_NODE_SIZE * nodes.length) + (INNER_SPACING * (nodes.length - 1));
    } else {
        width = (SKILL_NODE_SIZE * maxGroupsPerRow) + (INNER_SPACING * (maxGroupsPerRow - 1));
    }

    if (width < LABEL_NODE_WIDTH) {
        width = LABEL_NODE_WIDTH;
    }

    return width;
}

/**
 * Determines the "real" height of a group.
 * 
 * @param nodes 
 * @param maxItemsPerRow 
 */
const determineGroupHeight = (nodes: Array<GraphNode>, maxItemsPerRow: number): number => {
    let height = 0;

    if (nodes == null || nodes.length === 0) {
        height = LABEL_NODE_HEIGHT;
    } else {
        const rowCount = Math.ceil(nodes.length / maxItemsPerRow);

        height = LABEL_NODE_HEIGHT + (SKILL_NODE_SIZE * rowCount) + (INNER_SPACING * (rowCount - 1));
    }

    return height;
}

export default generateModel;
