import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RefreshIconButton } from 'react-admin';
import RefreshIcon from '@material-ui/icons/Refresh';
import { IconButton, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(
    {
        loader: {
            margin: 14,
        },
        loadedIcon: {},
    },
    { name: 'RaLoadingIndicator' }
);

const LoadingIndicator = props => {
    const { classes: classesOverride, className, disabled, onClick, ...rest } = props;
    const loading = useSelector(state => state.admin.loading > 0);
    const classes = useStyles(props);

    return (loading) ? ( disabled ?
        <Tooltip title={<div>Refresh is disabled until the <br/> current process is completed</div>} style={{ }}><IconButton disableRipple style={{ cursor: 'auto' }}><RefreshIcon color='disabled'/></IconButton></Tooltip> : 
        <CircularProgress
            className={classNames('app-loader', classes.loader, className)}
            color="inherit"
            size={18}
            thickness={5}
            {...rest}
        />
    ) : (
        <RefreshIconButton className={classes.loadedIcon} />
    );
};

LoadingIndicator.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    width: PropTypes.string,
};

export default LoadingIndicator
