import { DiagramModel } from '@projectstorm/react-diagrams';

import { DataOptions } from 'components/VectorMap/OptionsBlade';

import { StartNodeModel } from 'components/VectorMap/custom/LessonMap/StartNode/StartNodeModel';
import { connectPorts, GraphNode, VectorMapContext } from '../model';
import { CustomLinkModel } from 'components/VectorMap/custom/Common/CustomLink/CustomLinkModel';
// import { DecisionNodeModel } from 'components/VectorMap/custom/LessonMap/DecisionNode/DecisionNodeModel';
// import { OptionNodeModel } from 'components/VectorMap/custom/LessonMap/OptionNode/OptionNodeModel';
// import { BadOptionNodeModel } from 'components/VectorMap/custom/LessonMap/BadOptionNode/BadOptionNodeModel';
// import { EndNodeModel } from 'components/VectorMap/custom/LessonMap/EndNode/EndNodeModel';


/**
 * Apply any filters from the supplied data options.
 * 
 * @param data 
 * @param options 
 */
const applyFilters = (data: Array<any>, options?: DataOptions | null): Array<any> => {
    let results = data.map(item => Object.assign({}, item));

    if (options && options.filter) {
        console.log("Applying Filter", options.filter);

        // TODO: Implement this when we get around to Lesson Map.
    }

    return results;
}

/**
 * Apply any sort from the supplied data options.
 * 
 * @param nodes 
 * @param options 
 * @param sortByGroupSize 
 */
const applySort = (_nodes: Array<GraphNode>, options?: DataOptions | null) => {
    if (options && options.sort) {
        console.log("Applying Sort", options.sort);

        // TODO: Implement this when we get around to Lesson Map.
    }
}

const convertToGraph = (data: Array<any>, options?: DataOptions | null): Array<GraphNode> => {
    const result: Array<GraphNode> = [];

    // First, we apply any supplied filters.
    const filteredData = applyFilters(data, options);

    // Next, we generate a graph representing the Lesson Map.
    filteredData.forEach(item => {
        if (item == null) return;

        // TODO: Implement this when we get around to Lesson Map.
    });

    // Finally, we apply sorting.
    applySort(result, options);

    return result;
}

const generateTestModel = (): DiagramModel => {
    // Starting Node
    const startNode = new StartNodeModel();
    startNode.setPosition(0, 150);

    // Ending Node
    const endNode = new StartNodeModel();
    endNode.setPosition(200, 150);

    const nodes = [startNode, endNode];
    const links: Array<CustomLinkModel> = [];

    nodes.push(new StartNodeModel());
    nodes[nodes.length - 1].setPosition(100, 50);
    links.push(connectPorts(
        startNode.getPort('top-end'),
        nodes[nodes.length - 1].getPort('left-middle')
    ));
    links.push(connectPorts(
        nodes[nodes.length - 1].getPort('right-middle'),
        endNode.getPort('top-start'),
    ));

    nodes.push(new StartNodeModel());
    nodes[nodes.length - 1].setPosition(100, 150);
    links.push(connectPorts(
        startNode.getPort('right-middle'),
        nodes[nodes.length - 1].getPort('left-middle')
    ));
    links.push(connectPorts(
        nodes[nodes.length - 1].getPort('right-middle'),
        endNode.getPort('left-middle'),
    ));

    nodes.push(new StartNodeModel());
    nodes[nodes.length - 1].setPosition(100, 250);
    links.push(connectPorts(
        startNode.getPort('bottom-end'),
        nodes[nodes.length - 1].getPort('left-middle')
    ));
    links.push(connectPorts(
        nodes[nodes.length - 1].getPort('right-middle'),
        endNode.getPort('bottom-start'),
    ));

    nodes.push(new StartNodeModel());
    nodes[nodes.length - 1].setPosition(100, 350);
    links.push(connectPorts(
        startNode.getPort('bottom-end'),
        nodes[nodes.length - 1].getPort('left-middle')
    ));
    links.push(connectPorts(
        nodes[nodes.length - 1].getPort('right-middle'),
        endNode.getPort('bottom-start'),
    ));

    const model = new DiagramModel();

    // Sort the links such that links with a higher opacity are drawn last.
    links.sort((a: CustomLinkModel, b: CustomLinkModel) => {
        if (a.getOptions().opacity > b.getOptions().opacity) return 1;
        else if (a.getOptions().opacity < b.getOptions().opacity) return -1;
        else return 0;
    });

    model.addAll(...[...nodes, ...links]);
    model.setLocked(true);

    return model;
}

const generateModel = (context: VectorMapContext, data: Array<any>, _onViewRecordDetails?: (record: any) => void, options?: DataOptions | null): DiagramModel => {
    console.log("Generating model for Lesson Map (Context = " + JSON.stringify(context) + ") ...");

    // Fist we convert the supplied data collection into a node graph.
    const graph = convertToGraph(data, options);

    const nodes = [];
    const links = [];

    // Determine if the resulting graph is empty.
    let isGraphEmpty = true;
    if (graph && graph.length > 0) isGraphEmpty = false;

    // Proceed with laying out the graph nodes.
    if (!isGraphEmpty) {
        // TODO: Implement this when we get around to Lesson Map.
    }

    const model = new DiagramModel();

    model.addAll(...[...nodes, ...links]);
    model.setLocked(true);

    // TEMPORARY - Generate a sample/text model until we get things further along with respect to the Lesson Map.
    return generateTestModel(); //model;
}

export default generateModel;
