import React from 'react';

interface PROPS {
    label?: string | null,
    color?: string | null,
    fontScale?: number
}

class LabelIcon extends React.Component<PROPS> {
    render() {
        const { label, color, fontScale } = this.props;

        const realColor = color ? color : 'currentColor';
        const realFontScale = fontScale ? fontScale : 0.8;

        return (
            <svg width={'100%'} height={'100%'} viewBox={"0 0 40 40"} xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ pointerEvents: 'none' }}>
                <text textAnchor="middle" alignmentBaseline="middle" x={40 * 0.5} y={40 * 0.55} style={{ fontFamily: 'Arial', fontWeight: 'bold', fill: realColor, stroke: realColor, fontSize: 40 * realFontScale }}>{label}</text>
            </svg>
        );
    }
}

export default LabelIcon;
