import React, {useState} from 'react';
import {SkillBarPanel, StatsTilePanel, SkillGraphPanel} from 'components';

import Collapsible from './Collapsible';
import Indicators from '../components/TrainingCatalogue/Indicators';
import LoadingStat from '../components/loading/LoadingStat';
import PanelStat from '../components/Panels/PanelStat';
import {useTheme} from '@material-ui/core';
import EditLessonContent from 'sections/Training/Show/Lessons/EditLessonContent';
import LoadingList from 'components/loading/LoadingList';

const LessonDetailView = props => {
	const {
		lessonData,
		information,
		lessonTrainingStats,
		lessonSkillStats,
		vectorMapContextData,
		skillBarPanelActions,
		userLessonSkillStats,
		selectedAttemptValue,
		changeSelectChoiceFromGraph,
		fromComponent,
		isEditing,
		onCancelEditing,
		trainingId,
		onUpdate,
		loading
	} = props;

	const theme = useTheme();

	const [
		activeLearningObjectiveIndex,
		setActiveLearningObjectiveIndex,
	] = useState(0);

	return (
		<div>
			<div>
				{!isEditing &&
					lessonData && !loading && (
						<div
							style={{
								backgroundColor: theme.palette.background.contained,
								padding: '16px',
								boxShadow: '0px 5px 20px #00000026',
							}}
						>
							{information}
							<div
								style={{
									display: 'flex',
									width: '100%',
									columnGap: '30px',
									paddingBottom: '8px',
								}}
							>
								{lessonData.description && (
									<div
										style={{
											width:
												lessonData?.learningObjectives?.length > 0
													? '50%'
													: '100%',
										}}
									>
										<Collapsible title={'Description'}>
											<p
												style={{paddingLeft: '15px'}}
												dangerouslySetInnerHTML={{
													__html: lessonData.description,
												}}
											></p>
										</Collapsible>
									</div>
								)}
								{lessonData?.learningObjectives?.length > 0 && (
									<div style={{width: lessonData.description ? '50%' : '100%'}}>
										<Collapsible title={'Learning Objectives'}>
											<div style={{paddingLeft: '15px'}}>
												<p
													dangerouslySetInnerHTML={{
														__html:
															lessonData.learningObjectives[
																activeLearningObjectiveIndex
															],
													}}
												></p>
												<Indicators
													length={lessonData.learningObjectives.length}
													active={activeLearningObjectiveIndex}
													press={index =>
														setActiveLearningObjectiveIndex(index)
													}
												/>
											</div>
										</Collapsible>
									</div>
								)}
							</div>
						</div>
					)}

				{((!isEditing && !lessonData) || loading) && <LoadingList />}

				{isEditing && (
					<EditLessonContent
						lessonData={lessonData}
						onCancelEditing={onCancelEditing}
						trainingId={trainingId}
						onUpdate={onUpdate}
					/>
				)}
			</div>

			<div>
				<div style={{margin: '24px 0px'}}>
					{(lessonTrainingStats && !loading) ? (
						<StatsTilePanel stats={lessonTrainingStats} />
					) : (
						<PanelStat>
							<LoadingStat />
						</PanelStat>
					)}
				</div>

				{(lessonSkillStats && !loading) && (
					<SkillBarPanel
						data={lessonSkillStats}
						vectorMapContextData={vectorMapContextData}
						fromComponent={fromComponent}
						actions={skillBarPanelActions ? skillBarPanelActions : []}
						showLastProcessedTime={props.showLastProcessedTime !== undefined ? props.showLastProcessedTime : true}
					/>
				)}

				{(userLessonSkillStats?.length > 1 && !loading) && (
					<SkillGraphPanel
						unprocessedData={userLessonSkillStats}
						selectedAttemptValue={selectedAttemptValue}
						fromComponentFlag={'LessonDetailsView'}
						attemptChangeHandler={changeSelectChoiceFromGraph}
						showLastProcessedTime={props.showLastProcessedTime !== undefined ? props.showLastProcessedTime : true}
					/>
				)}
			</div>
		</div>
	);
};

export default LessonDetailView;
