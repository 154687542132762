import 'date-fns';

import React, {useState} from 'react';

import Box from '../../components/layout/Box';
import {CustomDialog} from '../../components';
import {Redirect} from 'react-router';
import useProduction from 'hooks/useProduction';

const Debug = () => {
	const {isProduction, loading: prodLoading} = useProduction();

	const [isOpen, setIsOpen] = useState(true);

	if (isProduction && !prodLoading) {
		return <Redirect to='/' />;
	}

	const renderScreen = () => {
		return (
			<Box width={'300px'}>
				<CustomDialog
					title={'Edit User Information'}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					width={'646px'}
				>
					<Box padding={'16px'}></Box>
				</CustomDialog>
			</Box>
		);
	};

	return <div>{renderScreen()}</div>;
};

export default Debug;
