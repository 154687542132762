import {
	EmbeddedList,
	StatusChip,
	TimeSinceField,
	TimeSpanField,
} from 'components';
import {MenuItem, Typography} from '@material-ui/core';
import {Query, translate} from 'react-admin';

import AddUsersToTeamButton from 'sections/Team/AddUsersToTeamButton';
import MUIButton from '@material-ui/core/Button';
import PercentField from 'components/PercentField';
import React from 'react';
import RemoveUserFromTeamButton from '../../RemoveUserFromTeamButton';
import SendEmail from 'components/SendEmail';
import TestBeakerIcon from 'components/Icons/TestBeakerIcon';
import EmailIcon from 'assets/EmailIcon';

const UserList = props => {
	const {userSelected, translate} = props;

	return (
		<div>
			<EmbeddedList
				embedded={false}
				subTitle
				title={'Users for this Team'}
				resource='listuserforteam'
				columns={[
					{
						id: 'userId',
						name: 'User',
						sortable: true,
						render: record => {
							if (record.tester) {
								return (
									<div
										style={{
											whiteSpace: 'nowrap',
											flex: '1',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											minWidth: '0',
										}}
									>
										<Typography
											color='primary'
											style={{
												fontFamily: 'Montserrat Regular',
												fontSize: '14px',
											}}
											component={'div'}
										>
											<div
												title={record.userId}
												style={{
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													display: 'flex',
												}}
											>
												<TestBeakerIcon />
												{record.userId}
											</div>
										</Typography>
									</div>
								);
							} else {
								return (
									<div
										style={{
											whiteSpace: 'nowrap',
											flex: ' 1',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											minWidth: '0',
										}}
									>
										<Typography
											style={{
												fontFamily: 'Montserrat Regular',
												fontSize: '14px',
											}}
											component={'div'}
										>
											<div
												title={record.userId}
												style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
											>
												{record.userId}
											</div>
										</Typography>
									</div>
								);
							}
						},
					},
					{
						id: 'name',
						name: translate('Name'),
						sortable: true,
					},
					{
						id: 'lastLogin',
						name: translate('Last Login'),
						sortable: true,
						width: '115px',
						render: record => (
							<TimeSinceField
								record={record}
								source='lastLogin'
								fontFamily={'Montserrat Regular'}
							/>
						),
						isInverted: true,
					},
					{
						id: 'completionRate',
						name: translate('Completion'),
						sortable: true,
						align: 'right',
						width: '125px',
						render: record => (
							<PercentField record={record} source='completionRate' />
						),
						isInverted: true,
					},
					{
						id: 'status',
						name: translate('Status'),
						sortable: true,
						align: 'center',
						width: '120px',
						render: record => <StatusChip record={record} source='status' />,
						isInverted: true,
					},
					{
						id: 'scoreRatio',
						name: translate('Score'),
						sortable: true,
						align: 'right',
						width: '90px',
						render: record => (
							<PercentField record={record} source='scoreRatio' />
						),
						isInverted: true,
					},
					{
						id: 'trainingTimeSec',
						name: translate('Module Time'),
						sortable: true,
						align: 'right',
						width: '135px',
						render: record => (
							<TimeSpanField record={record} source='trainingTimeSec' />
						),
						isInverted: true,
					},
					{
						id: 'firstPassSec',
						width: '125px',
						name: <div>1st Pass</div>,
						sortable: true,
						align: 'center',
						render: record => (
							<TimeSpanField source='firstPassSec' record={record} />
						),
					},
				]}
				sort={{field: 'name', order: 'ASC'}}
				staticFilters={[{id: 'teamId', value: props.id}]}
				dynamicFilters={null}
				bulkActions={[
					{
						id: 'emailTeam',
						render: selectedIds => (
							<Query
								type='GET_LIST'
								resource='listuserforteam'
								payload={{
									pagination: {page: 1, perPage: 9999},
									filter: {teamId: props.id},
									sort: {field: 'id', order: 'ASC'},
								}}
								key={'email team'}
							>
								{({data}) => {
									let toEmailAddresses = '';

									if (data && data.length > 0) {
										data.forEach(member => {
											if (
												member &&
												member.contactEmail &&
												member.contactEmail.trim().length > 0
											) {
												if (toEmailAddresses.length > 0) {
													toEmailAddresses += ',';
												}

												toEmailAddresses += member.contactEmail;
											}
										});
									}

									if (toEmailAddresses.length > 0) {
										return (
											<MUIButton
												variant='outlined'
												color='primary'
												href={'mailto:' + toEmailAddresses}
												startIcon={<EmailIcon style={{fontSize: '18px', marginBottom: '3px'}}/>}
											>
												{'Email Team'}
											</MUIButton>
										);
									} else {
										return <div></div>;
									}
								}}
							</Query>
						),
					},
					{
						id: 'addUser',
						render: () => (
							<AddUsersToTeamButton
								{...props}
								key={'add user'}
								label='Add User'
								basePath={props.basePath}
								location={props.location}
							/>
						),
					},
				]}
				actions={[
					{
						render: record => (
							<MenuItem key={'view perfomance'}>
								<div onClick={() => userSelected(record.id, null, record)}>
									View Performance
								</div>
							</MenuItem>
						),
					},

					{
						render: record => (
							<SendEmail
								key={'send reminder email'}
								id={record.id}
								title={'Send Reminder Email'}
							/>
						),
					},
					{
						render: record => (
							<RemoveUserFromTeamButton
							    {...props}
								key={'Remove user from team'}
								variant='link'
								label='Remove From Team'
								userData={record}
								teamData={props.teamData}
								basePath={props.basePath}
								location={props.location}
							/>
						),
					},
				]}
				rowClicked={record => userSelected(record.id, null, record)}
				selectedIds={null}
				selectionToggled={null}
				stripped={false}
			/>
		</div>
	);
};

export default translate(UserList);
