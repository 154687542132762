import PropTypes from 'prop-types';
import React, {Children, cloneElement, Component, isValidElement} from 'react';

/**
 * Allow showing multitple ShowForm on the same page, usefule to allow showing thombstone data with Tabs blelow
 */
export class MultiShow extends Component {
	render() {
		const {
			children,
			className,
			// eslint-disable-next-line no-unused-vars
			...rest
		} = this.props;

		return (
			<div className={className}>
				{Children.map(children, (tab, index) =>
					tab && isValidElement(tab)
						? cloneElement(tab, {
								...rest,
						  })
						: null
				)}
			</div>
		);
	}
}

MultiShow.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	location: PropTypes.object,
	match: PropTypes.object,
	record: PropTypes.object,
	resource: PropTypes.string,
	basePath: PropTypes.string,
	value: PropTypes.number,
	version: PropTypes.number,
	translate: PropTypes.func,
};

export default MultiShow;
