import { useTranslate } from 'react-admin';
import React, { useEffect, useState } from 'react';

import BackIcon from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ClickableArea from './ClickableArea';
import TextValue from './TextValue';
import get from 'lodash/get';
import { loadObject } from '../utils/Util';
import { useSelector } from 'react-redux';
import LoadingIndicator from "components/loading/LoadingIndicator";

const ScreenTitle = props => {

	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const breadcrumbMaxCharacters = get(runwayOrgConfig, 'config.breadcrumbMaxCharacters');

	const breadcrumbMinCharacters = get(runwayOrgConfig, 'config.breadcrumbMinCharacters');

	const breadcrumbMaxWidth = 3 + (+ breadcrumbMaxCharacters);

	const breadcrumbMinWidth = 3 + (+ breadcrumbMinCharacters);

	const [TitleCrumb, setTitleCrumb] = useState([]);

	// Testing loading refresh
	const [wasLoading, setWasLoading] = useState(true);

	const isLoading = useSelector(state => get(state, 'admin.loading')) === 0;

	if (isLoading !== wasLoading) {
		setWasLoading(isLoading);
	}

	const translate = useTranslate();

	const {
		title,
		titleCrumbs,
		refresh = true,
		backActionCrumbs = [],
		backAction,
		subTitle = false,
		coloredCrumb = false,
		list = null,
		actions,
		customTitle = null,
		disableRefreshButton,
		customRefresh
	} = props;

	const titleComponent = () => {
		if (!list && backAction) {
			return (
				<ClickableArea onClick={backAction}>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						padding: '0px',
					}}>
						{titleCrumbs?.length === 0 && (
							<ChevronRight
								color={'primary'}
								style={{
									fontSize: subTitle ? '32px' : '44px',
									paddingTop: '3px',
									marginRight: '-5px',
								}}
							/>
						)}
						{customTitle && (
							<div
								style={{
									whiteSpace: 'nowrap',
									paddingRight: '5px',
									fontFamily: 'Archivo Bold',
									fontSize: '28px',
								}}
							>
								{customTitle}
							</div>
						)}
						<div
							style={{
								whiteSpace: 'nowrap',
								fontFamily: 'Archivo Bold',
								fontSize: '20px',
							}}
						>
							{typeof title === 'string' ? translate(title) : title}
						</div>
					</div>
				</ClickableArea>
			);
		}

		return (
			<div
				style={{
					whiteSpace: 'nowrap',
					fontFamily: 'Archivo Bold',
					fontSize: coloredCrumb ? '20px' : '28px',
					position: 'relative',
					top: coloredCrumb ? '5px' : '0px',
					color: coloredCrumb ? '#346dec' : '',
				}}
			>
				{typeof title === 'string' ? translate(title) : title}
			</div>
		);
	};

	const setProperCrumbs = async (titleCrumbs) => {
		let newList = []
		if (titleCrumbs && titleCrumbs.length > 0) {
			titleCrumbs.forEach((each) => {
				let obj = {}
				obj.title = each
				obj.width = breadcrumbMinWidth
				newList.push(obj)
			})
		}
		await setTitleCrumb(newList)
	}

	useEffect(() => {
		setProperCrumbs(titleCrumbs)
	}, [])

	const onBreadCrumb = (e) => {
		let list = [...TitleCrumb]
		list[e.target.id].width = breadcrumbMaxWidth
		setTitleCrumb(list);
	}

	const onBreadCrumbLeave = (e) => {
		let list = [...TitleCrumb]
		list[e.target.id].width = breadcrumbMinWidth
		setTitleCrumb(list);
	}

	const renderBreadCrumbs = () => {
		if (TitleCrumb && backActionCrumbs) {
			var list = [];

			for (let index = 0; index < TitleCrumb.length; index++) {
				const titleCrumb = TitleCrumb[index];
				const backAction = backActionCrumbs[index];

				list.push(
					<ClickableArea onClick={() => {backAction()}} key={index}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								fontFamily: 'Archivo Bold',
								fontSize: '20px',
								marginRight: '-10px',
							}}
							key={index}
						>
							<div
								style={{
									overflow: 'hidden',
									position: 'relative',
									paddingRight: '4px',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									maxWidth: `${titleCrumb.width}ch`,
									transition: 'max-width .24s ease-in-out',
								}}
								title={titleCrumb.title}
								id={index}
								onMouseEnter={onBreadCrumb}
								onMouseLeave={onBreadCrumbLeave}
							>
								{typeof titleCrumb.title === 'string'
									? translate(titleCrumb.title)
									: titleCrumb.title}
							</div>
							<div>
								{(title || index !== TitleCrumb.length - 1) && (
									<ChevronRight
										color={'primary'}
										style={{
											fontSize: '34px',
											paddingTop: '4px',
											margin: '0px -5px 0px -10px',
										}}
									/>
								)}
							</div>
						</div>
					</ClickableArea>
				);
			}

			return list;
		}

		return null;
	};

	const renderActions = () => {
		var items = [];

		if (!actions) {
			return items;
		}

		for (var i = 0; i < actions.length; i++) {
			var action = actions[i];

			if (action.render) {
				items.push(
					<div
						key={i}
						style={{ display: 'flex', marginLeft: '10px', alignItems: 'center', marginBottom: '6px' }}
					>
						{action.render()}
					</div>
				);
			} else {
				items.push(
					<ClickableArea
						variant='outlined'
						color='secondary'
						style={{
							height: '32px',
							margin: 'auto',
							width: '100px',
						}}
						onClick={action.onClick}
					>
						<TextValue
							style={{ color: '#ffc100' }}
							record={action}
							source={'label'}
						/>
					</ClickableArea>
				);
			}
		}

		if (items.length === 0) {
			return <div></div>;
		}

		return (
			<div
				style={{
					//width: '100%',
					height: '100%',
					display: 'flex',
				}}
			>
				<span style={{ flex: 1 }} />
				{items}

				<span style={{ width: '16px' }} />
			</div>
		);
	};

	const listButtonComponent = (
		<div
			onClick={() => {
				// clearRequestQueue();
				if (backAction) {
					backAction();
				} 
				else {
					window.location = '#' + list;
				}
			}}
			style={{ cursor: 'pointer' }}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<BackIcon
					style={{
						fontSize: '50px',
						marginLeft: '-14px',
						marginRight: '-6px',
					}}
					color='primary'
				/>

				{titleComponent()}
			</div>
		</div>
	);

	return (
		<div
			style={{
				height: subTitle ? '51px' : '72px',
				display: 'flex',
			}}
		>
			<div
				style={{
					display: 'flex',
					fontSize: subTitle ? '20px' : '28px',
					height: '48px',
					width: '100%',
					justifyContent: 'left',
					justifyItems: 'center',
					alignItems: 'center',
					paddingTop: subTitle ? '0px' : '24px',
				}}
			>
				{list && listButtonComponent}

				<div
					style={
						subTitle && actions?.length > 0
							? {width: '80%', overflow: 'hidden', display: 'inherit'}
							: {width: 'auto', display: 'inherit', overflow: 'hidden'}
					}
				>
					{!list && renderBreadCrumbs()}

					{!list && titleComponent()}
				</div>

				<div style={{flex: 1}}></div>

				{renderActions()}

				{refresh && (
					<div
						style={{
							width: 30,
							marginRight: 10,
							height: '100%',
							paddingBottom: '48px',
						}}
					>
						<LoadingIndicator
							disabled={disableRefreshButton}
						/>
					</div>
				)}

				{customRefresh && (
					<div
						style={{
							width: 30,
							marginRight: 10,
							height: '100%',
							paddingBottom: '48px',
						}}
					>
						{customRefresh}
					</div>
				)}
			</div>
		</div>
	);
};

export default ScreenTitle;

