import {
	CreateButton,
	fetchEnd,
	fetchStart,
	MenuItemLink,
	translate,
	useDataProvider,
	useTranslate,
	useVersion,
	withDataProvider,
} from 'react-admin';
import { Redirect } from 'react-router-dom';
import {EmbeddedList, ScreenTitle, StatsTilePanel} from 'components';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import DeleteTeamButton from './Buttons/DeleteTeamButton_deprecated';
import Grid from '../../components/layout/Grid';
import LoadingStat from '../../components/loading/LoadingStat';
import MakeDefaultTeamButton from './MakeDefaultTeamButton';
import UnsetDefaultTeamButton from './UnsetDefaultTeamButton';
import PanelStat from '../../components/Panels/PanelStat';
import PercentField from 'components/PercentField';
import {Route} from 'react-router';
import SendEmail from 'components/SendEmail';
import TeamCreate from './Create';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {getUserInfo} from 'utils/Data';
import {push} from 'connected-react-router';
import {safeRequestData} from '../../utils/Data';
import {withRouter} from 'react-router-dom';
import DefaultTeam from '../../components/DefaultTeam';
import {loadObject} from 'utils/Util';
import {useDispatch} from "react-redux";
import { AddOutlined } from '@material-ui/icons';

const TeamList = props => {
	const translate = useTranslate();
	const version = useVersion();
	const dispatch = useDispatch();

	const [state, setState] = useState({
		teamstats: loadObject('teamstats'),
		runwayOrgConfig: loadObject('runwayOrgConfig'),
	});

	const isMounted = useRef(true);
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const dataProvider = useDataProvider();
	const fetchData = useCallback(async () => {
		const requestList = [{resource: 'teamstats'}];
		setState({ ...state, teamstats: null });

		for (let i = 0; i < requestList.length; i++) {
			const request = requestList[i];

			try {
				dispatch(fetchStart())
				await safeRequestData({
					dataRequest: request,
					dataProvider: dataProvider,
					isMounted: isMounted,
					setState: setState,
				});
			} finally {
			  dispatch(fetchEnd())
			}
		}
	}, [dataProvider]);

	useEffect(() => {
		fetchData();
	}, [version]);

	if (!getUserInfo()?.isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: '/login',
					state: {from: props.location},
				}}
			/>
		);
	}

	if (getUserInfo().isUser) {
		return (
			<div>
				Access denied. Contact your administrator to raise your permissions
			</div>
		);
	}

	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to='/termsOfService' />;
	// }

	const {teamstats} = state;

	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const defaultTeamId = get(runwayOrgConfig, 'config.defaultTeamId');

	return (
		<div>
			<ScreenTitle
				basePath={props.basePath}
				resource={props.resource}
				id='teams-list'
				title='Teams'
			/>

			<Grid perRow={1}>
				{teamstats !== null ? (
					<StatsTilePanel record={{array: {data: teamstats}}} />
				) : (
					<PanelStat>
						<LoadingStat />
					</PanelStat>
				)}

				<EmbeddedList
					embedded={false}
					title={null}
					resource='teams'
					columns={[
						{
							id: 'name',
							name: translate('Name'),
							sortable: true,
							render: record => <DefaultTeam record={record} />,
						},
						{
							id: 'training',
							name: translate('Modules'),
							sortable: true,
							align: 'center',
							width: '125px',
							isInverted: true,
						},
						{
							id: 'users',
							name: translate('Users'),
							sortable: true,
							align: 'center',
							width: '125px',
							isInverted: true,
						},
						{
							id: 'completionRate',
							name: translate('Completion'),
							sortable: true,
							align: 'center',
							width: '125px',
							render: record => (
								<PercentField record={record} source='completionRate' />
							),
							isInverted: true,
						},
						{
							id: 'avgScore',
							name: translate('Avg Score'),
							sortable: true,
							align: 'center',
							width: '125px',
							render: record => (
								<PercentField record={record} source='avgScore' />
							),
							isInverted: true,
						},
					]}
					sort={{field: 'name', order: 'ASC'}}
					staticFilters={null}
					dynamicFilters={[
						{id: 'name', name: translate('Search Teams'), type: 'safe_regex'},
					]}
					bulkActions={[
						{
							id: 'createTeam',
							render: selectedIds => (
								<CreateButton
									size='medium'
									variant='outlined'
									color='primary'
									label={translate('Create Team')}
									basePath={props.basePath}
									icon={<AddOutlined style={{ marginBottom: '2px'}}/>}
								/>
							),
						},
					]}
					actions={[
						{
							id: 'viewTeamDetail',
							render: record => (
								<MenuItemLink
									to={`teams/${record.id}/show`}
									primaryText={translate('View Team Detail')}
									key={'viewTeamDetail'}
								/>
							),
						},
						{
							id: 'makeDefaultTeam',
							render: record =>
								getUserInfo().isSuper &&
								record.id !== defaultTeamId && (
									<MakeDefaultTeamButton
										location={props.location}
										record={record}
										key={'makeDefaultTeam'}
									/>
								),
						},
						{
							id: 'unsetDefaultTeam',
							render: record =>
								getUserInfo().isSuper &&
								record.id === defaultTeamId && (
									<UnsetDefaultTeamButton
										location={props.location}
										record={record}
										key={'unsetDefaultTeam'}
									/>
								),
						},
						{
							id: 'emailManager',
							render: record => (
								<SendEmail
									title={'Email Manager'}
									id={record.ownerId}
									key={'emailManager'}
								/>
							),
						},
						{
							id: 'deleteTeam',
							render: record => (
								<DeleteTeamButton
									variant='link'
									record={record}
									basePath={props.basePath}
									location={props.location}
									key={'deleteTeam'}
								/>
							),
						},
					]}
					rowClicked={record => props.push('/teams/' + record.id + '/show')}
					selectedIds={null}
					selectionToggled={null}
					stripped={false}
				/>
			</Grid>

			<Route
				exact={false}
				path='/teams/create'
				render={() => <TeamCreate {...props} />}
			/>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		orgconfig: get(state, 'admin.resources.orgconfig'),
		teamstats: get(state, 'admin.resources.teamstats.data'),
		version: get(state, 'admin.ui.viewVersion'),
	};
};

const enhance = compose(
	connect(mapStateToProps, {push}),
	translate,
	withRouter,
	withDataProvider
);

export default enhance(TeamList);
