
var authErrors = [];
export const AddAuthError = error => {
	authErrors.push(error);
};

export const RemoveAuthError = () => {
	if (authErrors.length > 0) {
		authErrors.shift();
	}
};

export const GetAuthError = () => {
	if (authErrors.length > 0) {
		return authErrors[0];
	}

	return null;
};

export const authErrorCode = () =>
	authErrors.length > 0 ? authErrors[0].reason_code : 0;

export const hasAuthError = () => {
	return authErrors.length > 0;
};

export const hasMissingProfileError = () => {
	if (authErrorCode() === 40206 || authErrorCode() === 40208) {
		return true;
	}

	return false;
};

export const hasBadPasswordError = () => {
	if (authErrorCode() === 40307) {
		return true;
	}

	return false;
};

export const hasPlatformSupportError = () => {
	if (authErrorCode() === 40320) {
		return true;
	}

	return false;
};

export const hasAppVersionError = () => {
	if (authErrorCode() === 40322) {
		return true;
	}

	return false;
};

export const hasDisabledAppError = () => {
	if (authErrorCode() === 40330) {
		return true;
	}

	return false;
};

export const hasNetworkWorkError = () => {
	if (authErrorCode() === 90001) {
		return true;
	}

	return false;
};

export const hasTooManyErrors = () => {
	if (authErrorCode() === 90200) {
		return true;
	}

	return false;
};


export const hasSessionError = () => {
	if (authErrorCode() === 403) {
		return true;
	}

	return false;
};


export const hasUserEntryError = () => {
	if (authErrorCode() === 1) {
		return true;
	}
	return false;
};
