import {GET_LIST, translate, withDataProvider} from 'react-admin';
import {DialogTitle, EmbeddedList, Text} from 'components';
import {
	createStyles,
	MuiThemeProvider,
	withStyles,
} from '@material-ui/core/styles';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {refreshView} from 'ra-core';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import {
	CircularProgress,
	Button,
	MenuItem,
	Dialog,
	DialogActions,
	DialogContent,
} from '@material-ui/core';
import get from 'lodash/get';
import {ADD_TRAININGS_TO_TEAM} from 'services/customActions';
import TrainingExpiry from 'components/TrainingExpiry';
import {DialogListTheme} from 'components/Theme';
import PercentField from 'components/PercentField';
import LoadingList from 'components/loading/LoadingList';
import { AddOutlined } from '@material-ui/icons';

const myStyles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		normalText: {
			color: theme.palette.color.default,
		},
		importantText: {
			color: theme.palette.color.important,
		},
		divider: {
			width: '100%',
			borderColor: theme.palette.borderColor.default,
		},
		spinnerStyles: {
			position: 'relative',
			marginRight: '5px',
			top: '2px',
			color: theme.palette.primary.main,
		},
	});

class AddTrainingButton extends Component {
	state = {
		error: false,
		showDialog: false,
		ready: false,
		selectedTraining: [],
		currentTrainings: [],
		isAdding: false,
	};

	async fetchTeam() {
		const {dataProvider, id} = this.props;

		const {data: currentTrainings} = await dataProvider(
			GET_LIST,
			'listtrainingforteam',
			{filter: {teamId: id}, pagination: {page: 1, perPage: 9999}}
		);
		this.setState({currentTrainings, ready: true});
	}

	handleClick = e => {
		if (e) e.stopPropagation();

		this.setState({
			showDialog: true,
			selectedTraining: [],
			currentTrainings: [],
			ready: false,
		});

		this.fetchTeam();
	};

	handleCloseClick = e => {
		if (e) e.stopPropagation();

		if (this.props.onClick) this.props.onClick(e);

		this.setState({
			showDialog: false,
			selectedTraining: [],
			currentTrainings: [],
			ready: false,
			isAdding: false,
		});
	};

	handleSaveClick = e => {
		if (e) e.stopPropagation();

		const {selectedTraining} = this.state;

		const selectedTrainingIds = [];

		selectedTraining.forEach(item => {
			if (item && item.id) {
				selectedTrainingIds.push(item.id);
			}
		});
		this.setState({isAdding: true});
		this.handleAddToTeam({selectedIds: selectedTrainingIds});
	};

	handleAddToTeam = async values => {
		const {dataProvider, refreshView} = this.props;

		await dataProvider(
			ADD_TRAININGS_TO_TEAM,
			'teams',
			{
				data: {id: this.props.id, selectedIds: values.selectedIds},
			},
			{
				onSuccess: {
					refresh: true,
					unselectAll: true,
				},
				onFailure: {
					unselectAll: true,
					notification: {level: 'warning'},
				},
			}
		).then(() => {
			if (this.props.startPolling) this.props.startPolling();
			refreshView();
			this.handleCloseClick();
		});
	};

	handleTrainingSelectionToggled = record => {
		const selectedTraining = [...this.state.selectedTraining];

		const targetTraining = selectedTraining.find(
			item => item != null && record != null && item.id === record.id
		);

		if (targetTraining == null) {
			selectedTraining.push(record);
		} else {
			const idx = selectedTraining.indexOf(targetTraining);

			if (idx >= 0) {
				selectedTraining.splice(idx, 1);
			}
		}

		this.setState({selectedTraining});
	};

	isTrainingSelected = key => {
		const {selectedTraining} = this.state;

		return (
			selectedTraining.find(item => item != null && item.id === key) != null
		);
	};

	render() {
		const {classes, translate} = this.props;
		const {selectedTraining, showDialog, currentTrainings, ready} = this.state;

		const selectedTrainingIds = [];
		selectedTraining.forEach(item => {
			if (item && item.id) {
				selectedTrainingIds.push(item.id);
			}
		});

		const currentTrainingIds = [];
		currentTrainings.forEach(item => {
			if (item && item.id) {
				currentTrainingIds.push(item.id);
			}
		});

		const button =
			this.props.variant === 'link' ? (
				<MenuItem onClick={this.handleClick}>{this.props.label}</MenuItem>
			) : (
				<Button onClick={this.handleClick} color='primary' variant='outlined' startIcon={<AddOutlined style={{ marginBottom: '2px' }}/>}>
					{this.props.label}
				</Button>
			);

		return (
			<div>
				{button}
				<MuiThemeProvider theme={DialogListTheme}>
					{showDialog && (
						<Dialog
							fullWidth
							maxWidth='md'
							open={true}
							onClose={this.handleCloseClick}
							aria-label='Add Module'
							onClick={e => {
								if (e) e.stopPropagation();
							}}
						>
							<DialogTitle onClose={this.handleCloseClick}>
								<Text
									style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
									label={translate('Add Module to Team')}
								/>
							</DialogTitle>

							<DialogContent>
								{ready && (
									<div>
										<EmbeddedList
											embedded={true}
											title={null}
											resource='trainings'
											columns={[
												{id: 'name', name: translate('Name'), sortable: true},
												{
													id: 'lessons',
													name: translate('Lessons'),
													sortable: true,
													align: 'right',
													width: '125px',
												},
												{
													id: 'users',
													name: translate('Users'),
													sortable: true,
													align: 'right',
													width: '125px',
												},
												{
													id: 'completionRate',
													name: translate('Completion'),
													sortable: true,
													align: 'right',
													width: '125px',
													render: record => {
														return (
															<PercentField
																record={record}
																source='completionRate'
															/>
														);
													},
												},
												{
													id: 'avgScore',
													name: translate('Avg Score'),
													sortable: true,
													align: 'right',
													width: '125px',
													render: record => {
														return (
															<PercentField record={record} source='avgScore' />
														);
													},
												},
												{
													id: 'expiry',
													name: 'Expiration',
													sortable: true,
													align: 'center',
													width: '85px',
													render: record => (
														<TrainingExpiry record={record} source='expiry' />
													),
												},
											]}
											sort={{field: 'name', order: 'ASC'}}
											staticFilters={[
												{id: 'id', value: {$nin: currentTrainingIds}},
												{id: 'expiry', value: {$ne: 0}},
											]}
											dynamicFilters={[
												{
													id: 'name',
													name: translate('Search Training'),
													type: 'safe_regex',
												},
											]}
											bulkActions={null}
											actions={null}
											rowClicked={null}
											selectedIds={selectedTrainingIds}
											selectionToggled={this.handleTrainingSelectionToggled}
											stripped={false}
										/>

										{selectedTraining.length > 0 && (
											<hr className={classes.divider} />
										)}

										{selectedTraining.length > 0 && (
											<div
												style={{
													flex: '1 1 auto',
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													padding: '10px',
												}}
											>
												<label className={classes.importantText}>
													{selectedTraining.length}{' '}
													{selectedTraining.length > 1
														? translate('Training Modules')
														: translate('Training Module')}
												</label>
												<label className={classes.normalText}>
													&nbsp;{translate('will be added to this team')}
												</label>
											</div>
										)}
									</div>
								)}
								{!ready && <LoadingList />}
							</DialogContent>

							<DialogActions>
								{this.state.isAdding ? (
									<div style={{display: 'flex'}}>
										<CircularProgress
											size={20}
											className={classes.spinnerStyles}
										/>
										<span>{translate('Adding Training...')}</span>
									</div>
								) : (
									<div>
										<Button
											variant={'contained'}
											color={'primary'}
											onClick={this.handleSaveClick}
											disabled={selectedTraining.length === 0}
					                        startIcon={<AddOutlined />}
										>
											{translate('Add to Team')}
										</Button>
									</div>
								)}
							</DialogActions>
						</Dialog>
					)}
				</MuiThemeProvider>
			</div>
		);
	}
}

AddTrainingButton.propTypes = {
	dataProvider: PropTypes.func.isRequired,
	dispatch: PropTypes.func,
	record: PropTypes.object,
	variant: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		version: get(state, 'admin.ui.viewVersion'),
	};
};

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withStyles(myStyles),
	withDataProvider
);

export default enhance(AddTrainingButton);
