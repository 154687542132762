import * as React from 'react';
import compose from 'recompose/compose';
import { createStyles, Typography, withStyles } from '@material-ui/core';

import { SkillNodeModel } from './SkillNodeModel';

import LabelIcon from 'components/VectorMap/LabelIcon';
import StatLabel from 'components/VectorMap/StatLabel';

import { capitalizeString } from 'components/VectorMap/utils/helpers';
import { getGroupOption } from 'components/VectorMap/utils/options';
import { MenuItemLink } from 'react-admin';
import { vectorMapUserEnabled } from 'utils/vectorMap';
import { loadObject } from 'utils/Util';

export interface SkillNodePopoverProps {
    node: SkillNodeModel;
    classes?: any
}

class SkillNodePopover extends React.Component<SkillNodePopoverProps> {
    render() {
        const { classes, node } = this.props;

        const record = node.getRecord();

        if (!record) return null;

        const runwayOrgConfig = loadObject('runwayOrgConfig');

        const config = runwayOrgConfig && runwayOrgConfig.config ? runwayOrgConfig.config : null;
        
        const options = node.getDataOptions();

        // Get the root group option.
        const rootGroupOption = options && options.group ? options.group : null;
        const rootGroupOptionValue = rootGroupOption ? rootGroupOption.value : null;

        // Determine the target training/lesson being grouped by (if applicable).
        const trainingGroupOption = rootGroupOptionValue === 'related-training' ? getGroupOption(rootGroupOption.children, 'related-training') : null;
        const trainingGroupOptionValue = trainingGroupOption ? trainingGroupOption.value : null;
        const lessonGroupOption = trainingGroupOptionValue ? getGroupOption(rootGroupOption.children, 'related-training.' + trainingGroupOptionValue) : null;
        const lessonGroupOptionValue = lessonGroupOption ? lessonGroupOption.value : null;
        
        // Get the associated training/lesson for the record (again, if applicable).
        const training = record.associatedTraining ? record.associatedTraining.find((item: any) => item.id === trainingGroupOptionValue) : null;
        const lesson = record.associatedLessons ? record.associatedLessons.find((item: any) => item.trainingId === trainingGroupOptionValue && item.id === lessonGroupOptionValue) : null;

        return (
            <div
                className={classes.root}
                onClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
                onMouseDown={(event) => { event.preventDefault(); event.stopPropagation(); }}
                onMouseUp={(event) => { event.preventDefault(); event.stopPropagation(); }}
                onDrag={(event) => { event.preventDefault(); event.stopPropagation(); }}
            >
                <div className={classes.header}>
                    <div className={classes.icon} style={{ backgroundColor: node.getBackgroundColor(), color: node.getFontColor(), borderColor: node.getFontColor() }}>
                        {record.iconURL && record.iconURL.trim().length > 0 &&
                            <img src={record.iconURL} alt={'skillicon'}/>
                        }

                        {(!record.iconURL || record.iconURL.trim().length === 0) && record.displayShortName &&
                            <LabelIcon label={record.displayShortName} fontScale={0.5} />
                        }
                    </div>

                    <Typography className={classes.title}>{record.displayTitle}</Typography>
                </div>

                <div className={classes.body}>
                    <div className={classes.description}>
                        <Typography className={classes.descriptionLabel}>Description</Typography>
                        <Typography className={classes.descriptionValue}>{record.displayDescription}</Typography>
                    </div>

                    <div className={classes.stats}>
                        <div className={classes.stat}>
                            <Typography className={classes.statLabel}>Type</Typography>
                            <StatLabel className={classes.statValue} type={'text'} value={capitalizeString(record.type)} />
                        </div>

                        <div className={classes.stat}>
                            <Typography className={classes.statLabel}>Category</Typography>
                            <StatLabel className={classes.statValue} type={'text'} value={capitalizeString(record.skillGroup)} />
                        </div>

                        <div className={classes.stat}>
                            <Typography className={classes.statLabel}>Skill Points</Typography>
                            <div style={{ flex: '0 0 auto', display: 'flex' }}>
                                {/* Labels */}
                                <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    {record && <Typography>User Total:</Typography>}
                                    {training && <Typography>Training Total:</Typography>}
                                    {lesson && <Typography>Lesson Total:</Typography>}
                                </div>

                                {/* User Score */}
                                <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: 10 }}>
                                    {record && <Typography>{record.userValue}</Typography>}
                                    {training && <Typography>{training.userValue}</Typography>}
                                    {lesson && <Typography>{lesson.userValue}</Typography>}
                                </div>

                                {/* Divider */}
                                <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                                    {record && <Typography>/</Typography>}
                                    {training && <Typography>/</Typography>}
                                    {lesson && <Typography>/</Typography>}
                                </div>

                                {/* Max Score */}
                                <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    {record && <Typography>{record.maxValue}</Typography>}
                                    {training && <Typography>{training.maxValue}</Typography>}
                                    {lesson && <Typography>{lesson.maxValue}</Typography>}
                                </div>
                            </div>
                        </div>
                    </div>

                    {config && config.enableSkillsMapRelatedTrainingButton === 'true' &&
                        // @ts-ignore
                        <MenuItemLink
                            className={classes.button}
                            to={{
                                pathname: `/trainings`,
                                state: {
                                    namedSkill: {name: record.namedSkill, displayName: record.displayTitle}
                                }
                            }}
                            primaryText={<Typography>View Related Training</Typography>}
                            key={'viewRelatedTraining'}
                            onClick={() => vectorMapUserEnabled(false)}
                        />
                    }
                </div>
            </div>
        );
    }
}

const myStyles = theme =>
    createStyles({
        root: {
            minWidth: 500,
            maxWidth: 500,
            padding: 10,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            backgroundColor: '#FFFFFF',
            color: '#233863'
        },
        header: {
            flex: '0 0 auto',
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10
        },
        icon: {
            flex: '0 0 auto',
            display: 'flex',
            width: 48,
            height: 48,
            borderRadius: '10%',
            overflow: 'hidden',
            marginRight: 10
        },
        title: {
            flex: '1 1 auto',
            fontWeight: 'bold',
            fontSize: 18
        },
        button: {
            width: '100%',
            backgroundColor: '#EEEEEE',
            color: '#000000',
            borderRadius: 5,
            overflow: 'hidden',
            fontWeight: 'bold',
            justifyContent: 'center',
            textTransform: 'uppercase',
            '&:hover': {
                backgroundColor: '#EFEFEF',
                color: '#00000',
            }
        },
        body: {
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            overflow: 'auto'
        },
        description: {
            flex: '0 0 auto'
        },
        descriptionLabel: {
            fontWeight: 'bold',
            fontSize: 16,
            marginBottom: 5
        },
        descriptionValue: {
            fontWeight: 'normal',
            fontSize: 14,
            fontStyle: "italic",
            marginBottom: 10
        },
        stats: {
            flex: '0 0 auto',
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateRows: 'repeat(1, auto)',
            marginBottom: 10
        },
        stat: {
            flex: '0 0 auto',
            display: 'flex',
            flexDirection: 'column',
            padding: 5
        },
        statLabel: {
            fontWeight: 'bold',
            fontSize: 16,
            marginBottom: 5
        },
        statValue: {
            fontWeight: 'normal',
            fontSize: 14,
            fontStyle: "italic"
        }
    });

const enhance = compose(withStyles(myStyles));

export default enhance(SkillNodePopover);
