import {EmbeddedList, StatusChip, TimeSpanField} from 'components';
import {
	NumberField,
	useDataProvider,
	useTranslate,
	useVersion,
} from 'react-admin';
import SendEmail from 'components/SendEmail';
import {Avatar, MenuItem, Button} from '@material-ui/core';
import PercentField from 'components/PercentField';
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {getUserInfo, safeRequestData} from 'utils/Data';
import trainingModuleIcon from 'assets/MiniCardIcons/TrainingModule.svg';
import TrainingExpiry from '../TrainingExpiry';
import {loadObject} from 'utils/Util';

import {vectorMapSystemEnabled, vectorMapUserEnabled} from 'utils/vectorMap';
import VectorMap from 'components/VectorMap';
import SkillFilter from '../SkillFilter';
import ResetPerformanceDataButton from '../Button/ResetPerformanceDataButton.jsx';
import ExportTrainingDataButton from '../../sections/User/Buttons/ExportTrainingDataButton';

const TrainingList = props => {
	const {trainingSelected, skillsSelected, selectedSkills, teamData} = props;

	const version = useVersion();

	const [state, setState] = useState({
		runwayOrgConfig: loadObject('runwayOrgConfig'),
		version: version,
		firstLoad: true,
	});

	const [useVectorMap, setUseVectorMap] = useState(vectorMapUserEnabled());

	const isMounted = useRef(true);
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const dataProvider = useDataProvider();

	const translate = useTranslate();

	const fetchData = useCallback(async () => {
		const requestList = [{resource: 'runwayOrgConfig'}];

		for (let i = 0; i < requestList.length; i++) {
			const request = requestList[i];

			await safeRequestData({
				dataRequest: request,
				dataProvider: dataProvider,
				isMounted: isMounted,
				setState: setState,
			});
		}
	}, [dataProvider]);

	if (version !== state.version || state.firstLoad) {
		setState({...state, version: version, firstLoad: false});
		fetchData();
	}

	if (vectorMapSystemEnabled('user', 'catalog-map') && useVectorMap) {
		return (
			<VectorMap
				type={'catalog-map'}
				contextData={{
					teamId: teamData ? teamData.id : undefined,
					userId: props.id,
					trainingId: undefined,
					lessonId: undefined,
				}}
				onTableViewClicked={() => {
					vectorMapUserEnabled(false);
					setUseVectorMap(false);
				}}
				onViewRecordDetails={record => {
					if (record && record.id) trainingSelected(record.id, null, record);
				}}
			/>
		);
	}
	return (
		<EmbeddedList
			embedded={false}
			subTitle
			showSpinnerOnFilter={true}
			title={translate('Availability')}
			resource='trainingsforuser'
			columns={[
				{
					id: 'name',
					name: translate('Module Name'),
					sortable: true,
					width: '150px',
					render: record => (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								columnGap: '5px',
								alignItems: 'center',
								padding: '10px 0px',
								whiteSpace: 'nowrap',
								flex: ' 1',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								minWidth: '0',
							}}
						>
							<Avatar
								variant={'square'}
								alt='Remy Sharp'
								src={
									record.trainingDetail.appIcons &&
									record.trainingDetail.appIcons.length > 0
										? record.trainingDetail.appIcons[0]
										: trainingModuleIcon
								}
							/>
							<span
								title={record.name}
								style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
							>
								{record.name}
							</span>
						</div>
					),
				},
				{
					id: 'seriesName',
					name: translate('Series Name'),
					sortable: true,
					width: '125px',
				},
				{
					id: 'status',
					name: translate('Status'),
					sortable: true,
					align: 'center',
					render: record => <StatusChip record={record} source='status' />,
					isInverted: true,
					width: '80px',
				},
				{
					id: 'lessons',
					name: translate('Lessons'),
					sortable: true,
					align: 'center',
					render: record => <NumberField record={record} source='lessons' />,
					isInverted: true,
					width: '65px',
				},
				{
					id: 'attempts',
					name: translate('Attempts'),
					sortable: true,
					width: '65px',
					align: 'center',
				},
				{
					id: 'timeSpent',
					name: translate('Time Spent'),
					sortable: true,
					align: 'center',
					width: '80px',
					render: record => (
						<TimeSpanField record={record} source='timeSpent' />
					),
					isInverted: true,
				},
				{
					id: 'completionRate',
					name: translate('Completion'),
					sortable: true,
					width: '80px',
					align: 'center',
					render: record => (
						<PercentField record={record} source='completionRate' />
					),
				},
				{
					id: 'avgScore',
					name: translate('Module Score'),
					sortable: true,
					align: 'center',
					width: '75px',
					render: record => <PercentField record={record} source='avgScore' />,
					isInverted: true,
				},
				{
					id: 'performanceChange',
					name: translate('Perf.Change'),
					sortable: true,
					align: 'center',
					width: '85px',
					render: record => (
						<PercentField record={record} source='performanceChange' />
					),
					isInverted: true,
				},
				{
					id: 'expiry',
					name: 'Expiration',
					sortable: true,
					width: '80px',
					align: 'center',
					render: record => <TrainingExpiry record={record} source='expiry' />,
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[
				{id: 'userId', value: props.id},
				{id: 'teamId', value: teamData ? teamData.id : undefined},
			]}
			dynamicFilters={[
				{
					id: 'accessibility',
					name: translate('Accessibility'),
					type: 'exact',
					choices: [
						{value: true, label: translate('Assigned')},
						{value: false, label: translate('Unassigned')},
					],
					value: true,
				},
				{
					id: 'namedSkills',
					value:
						selectedSkills.length > 0
							? {$in: selectedSkills.map(skill => skill.name)}
							: undefined,
					render: () => (
						<SkillFilter
							onChange={skills => {
								skillsSelected(skills);
							}}
							preSelectedSkills={
								selectedSkills.length ? selectedSkills : undefined
							}
							skillListContextData={{
								userId: props.id,
								teamId: teamData ? teamData.id : undefined,
							}}
						/>
					),
				},
			]}
			bulkActions={[
				{
					id: 'addTraining',
				},
				vectorMapSystemEnabled('user', 'catalog-map')
					? {
							id: 'switchToVectorMap',
							render: () => (
								<Button
									variant={'outlined'}
									color={'primary'}
									onClick={() => {
										vectorMapUserEnabled(true);
										setUseVectorMap(true);
									}}
								>
									{translate('Map View')}
								</Button>
							),
					  }
					: null,
			]}
			actions={[
				{
					render: record => (
						<MenuItem key={'View Module Details'}>
							<div onClick={() => trainingSelected(record.id, null, record)}>
								{translate('View Module Details')}
							</div>
						</MenuItem>
					),
				},
				{
					render: record =>
						record.accessibility.assigned.value ? (
							<SendEmail
								key={'Send Reminder Email'}
								id={props.id}
								title={'Send Reminder Email'}
							/>
						) : (
							<div key={'Send Reminder Email'}></div>
						),
				},
				{
					render: record =>
						(getUserInfo().role === 'TS-super' ||
							getUserInfo().role === 'TS-admin') && (
							<ResetPerformanceDataButton
								variant='link'
								trainingName={record.name}
								id={props.userData.id}
								userId={props.userData.userId}
								trainingId={record.id}
								basePath={props.basePath}
								location={props.location}
								fromComponentFlag={'Traininglist'}
								key={'ResetPerformanceData'}
							/>
						),
				},
				{
					id: 'ExportTrainingDataButton',
					render: record =>
						getUserInfo().role === 'TS-super' && (
							<ExportTrainingDataButton
								variant='link'
								id={props.userData.id}
								appKeyName={record.appKeyName}
								basePath={props.basePath}
								location={props.location}
								key={'ExportTrainingDataButton'}
							/>
						),
				},
			]}
			rowClicked={record => trainingSelected(record.id, null, record)}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default TrainingList;
