import React, {useEffect, useRef, useState} from 'react';
import {CircularProgress} from '@material-ui/core';
import MUIButton from '@material-ui/core/Button';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {
	fetchEnd,
	fetchStart,
	GET_LIST,
	LinearProgress,
	useDataProvider,
	useTranslate,
	useVersion,
} from 'react-admin';

import {
	DOWNLOAD_RAW_PERFOMANCE_LOGS,
	RAW_WATCHDOG_DOWNLOAD_RUNWAY,
	UPDATE_RAW_PERFOMANCE_LOGS_RUNWAY,
} from 'services/customActions';
import {EmbeddedShow} from '../../components';
import LoadingInfo from 'components/loading/LoadingInfo';
import { useDispatch } from 'react-redux';
import FileDownload from 'assets/FileDownload';
import { parseJson } from 'utils/Util';

const myStyles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
		spinnerStyles: {
			position: 'relative',
			marginRight: '5px',
			top: '2px',
			color: theme.palette.primary.main,
		},
		info: {
			fontFamily: 'Montserrat Italic',
		},
		lastUpdatedText: {
			fontFamily: 'Montserrat Bold',
			display: 'inline',
		},
		noLogsLabel: {
			marginLeft: '10px',
			display: 'inline-flex',
			verticalAlign: 'middle',
			marginBottom: '15px',
		},
	});

const RawperfomanceLog = props => {
	const translate = useTranslate();

	const dataProvider = useDataProvider();

	const {teamId, teamData} = props;

	const [state, setState] = useState({
		isUpdating: false,
		processedCount: '',
		expectedCount: '',
		logId: '',
		json: '',
	});

	const [lastUpdated, setLastUpdated] = useState('');

	const [isLoading, setIsLoading] = useState(true);

	const [isDownloading, setIsDownloading] = useState(false);

	const [logExists, setLogExists] = useState(true);

	const [hasTrainingsAndUsers, setHasTrainingsAndUsers] = useState(false);

	const {classes} = props;

    let timeOut = null;

	const isMounted = useRef(true);

	const version = useVersion();

	const dispatch = useDispatch();

	useEffect(async () => {
		if (!teamId || (teamData && teamData?.users > 0 && teamData.training > 0)) {
			setHasTrainingsAndUsers(true);
			await listLogs();	
		} else {
			setLogExists(false);
			setIsLoading(false);
			setHasTrainingsAndUsers(false)
		}
		return () => {
			isMounted.current = false;
			clearInterval(timeOut);
		}; // use effect cleanup to set flag false, if unmounted
	}, [version, teamData]);

	const getWatchDog = async watchDogID => {
		if (!isMounted.current) return;

		await dataProvider(RAW_WATCHDOG_DOWNLOAD_RUNWAY, 'getWatchDog', {
			id: watchDogID,
		})
			.then(response => {
				const data = response.data.report;

				if (data.status === 1 && isMounted.current) {
					setIsLoading(false);
					setState(state => ({
						...state,
						isUpdating: true,
						processedCount: data.totalProcessedCount,
						expectedCount: data.totalExpectedCount,
					}));

					if (!timeOut && isMounted.current) {
						timeOut = setInterval(() => {
								getWatchDog(watchDogID);
							}, 20000)
					}
				} else {
					clearInterval(timeOut);
					timeOut = null;
					setState(state => ({
						...state,
						processedCount: '',
						expectedCount: '',
					}));
					listLogs(() => {
						setState(state => ({...state, isUpdating: false}));
					});
				}}
			)
			.catch(error => {
				clearInterval(timeOut);
				timeOut = null;
			})
			.finally(() => {});
	};

	const listLogs = async callback => {
		if (!isMounted.current) return;

		const params = {
			pagination: {page: 1, perPage: 5},
			sort: {field: 'serverTimeStarted', order: -1},
			filter: {},
		};

		if (teamId) {
			params.filter.teamId = teamId;
		}
		if (!state.isUpdating) {
			setIsLoading(true);
		}

		try {
			if (!state.isUpdating) {
				dispatch(fetchStart())
			}
			const {data: logs} = await dataProvider(
				GET_LIST,
				'listRawPerformanceLogsRunway',
				params
			);
			const logInProgress = logs.find(log => log.status !== 0);

			if (logInProgress) getWatchDog(logInProgress.id);
	
			if (logs && isMounted.current) {
				if (
					(logInProgress && logs.length > 1) ||
					(!logInProgress && logs.length > 0)
				) {
					const key = logInProgress ? logs[1].id : logs[0].id;
					const time = logInProgress
						? logs[1].serverTimeFinished
						: logs[0].serverTimeFinished;
	
					if (time) {
						const formattedTime = new Date(time)
							.toLocaleString()
							.replace(',', '')
							.replace(/:\d{2}\s/, ' ')
							.replace(/\//g, '-');
	
						setState(state => ({...state, logId: key}));
						setLastUpdated(formattedTime);
						setLogExists(true);
					}
				} else {
					setLastUpdated('No reports yet');
					setLogExists(false);
				}
			}
	
			setIsLoading(false);
	
			if (callback) {
				callback();
			}
		} finally {
			dispatch(fetchEnd())
		}
	};

	const getLog = () => {
		setIsDownloading(true);

		dataProvider(
			DOWNLOAD_RAW_PERFOMANCE_LOGS,
			'downloadRawPerformanceLogsRunway',
			{id: state.logId}
		)
			.then(response => {
				//converts json to csv
				const val = response.data.userData;
				const data = val.map(row => row);
				const csvRows = [];

				const headers = Object.keys(data[0]);
				csvRows.push(headers.join(','));
				for (const row of data) {
					const values = headers.map(header => {
						const escaped = row[header];
						return `"${escaped}"`;
					});
					csvRows.push(values.join(','));
				}
				const resp = csvRows.join('\n');
				const file = new File([resp], {type: 'text'});
				const url = window.URL.createObjectURL(file);
				const a = document.createElement('a');
				a.setAttribute('hidden', '');
				a.setAttribute('href', url);
				const fileName = 'RawPerformanceLog-' + state.logId + '.csv';
				a.setAttribute('download', fileName);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				setIsDownloading(false);
			})
			.catch(error => setIsDownloading(false));
	};

	const updateLog = async () => {
		setState({...state, isUpdating: true});

		const filter = teamId ? {teamId} : {};

		await dataProvider(UPDATE_RAW_PERFOMANCE_LOGS_RUNWAY, 'updateLog', filter)
			.then(response => {
				const watchDogID = response.data.report.id;
				getWatchDog(watchDogID);
			})
			.catch(error => {
				if (error && error.message && typeof error.message === "string" && parseJson(error.message)?.data?.id) {
					getWatchDog(parseJson(error.message)?.data?.id);
				} else {
					setState({...state, isUpdating: false});
					console.log(error);
				}
			});
	};

	const title = teamId ? 'Team Raw Performance Log' : 'Raw Performance Log';
	const description = teamId
		? 'Download a log tracking the performance of this team'
		: 'Download a log tracking the performance of all users in this organization.';

	return (
		<EmbeddedShow title={title} height={'auto'}>
			{!isLoading && (
				<div>
					<div className={classes.info}>{translate(description)}</div>
					<hr className={classes.divider} />
					<MUIButton
						variant='contained'
						color='primary'
						style={{marginBottom: '10px', height: '32px', width: '172px'}}
						onClick={getLog}
						disabled={isDownloading || !logExists || !hasTrainingsAndUsers}
						startIcon={<FileDownload style={{fontSize: '20px'}} />}
					>
						{translate('Download Log')}
					</MUIButton>
					{logExists && (
						<div className={classes.lastUpdatedText}>
							<span style={{marginLeft: '10px'}}>{'Last Updated'}</span>

							<span style={{marginLeft: '10px', display: 'inline-flex'}}>
								{lastUpdated ? (
									<span>{lastUpdated}</span>
								) : (
									<span>
										<LinearProgress />
									</span>
								)}
							</span>
						</div>
					)}
					{!logExists && hasTrainingsAndUsers && (
						<div className={classes.lastUpdatedText}>
							<span className={classes.noLogsLabel}>
								Log not generated yet. Please click the 'Update Log' button to
								create.
							</span>
						</div>
					)}
					{!hasTrainingsAndUsers && (
						<div className={classes.lastUpdatedText}>
							<span className={classes.noLogsLabel}>
								You must assign at least one user and one training module to the
								team before you can update and download Team Raw Performance Log
								data.
							</span>
						</div>
					)}
					{isDownloading && (
						<div
							style={{
								display: 'inline-flex',
								marginBottom: '10px',
								height: '32px',
								width: '160px',
								marginLeft: '10px',
							}}
						>
							<span>
								<CircularProgress size={15} className={classes.spinnerStyles} />
								<span className={classes.info}>{translate('Downloading')}</span>
							</span>
						</div>
					)}
					<div style={{display: 'block'}}>
						<MUIButton
							variant='outlined'
							color='primary'
							style={{height: '32px', width: '172px'}}
							onClick={updateLog}
							disabled={state.isUpdating || !hasTrainingsAndUsers}
						>
							{translate('Update Log')}
						</MUIButton>
						{state.isUpdating && (
							<div style={{marginLeft: '10px', display: 'inline'}}>
								<span>
									<CircularProgress
										size={15}
										className={classes.spinnerStyles}
									/>
									<span className={classes.info}>
										{state.processedCount != '' && state.expectedCount != ''
											? translate(
													`Analyzing... ${state.processedCount} of ${state.expectedCount}`
											  )
											: 'Analyzing...'}
									</span>
								</span>
							</div>
						)}
					</div>
				</div>
			)}

			{isLoading && <LoadingInfo />}
		</EmbeddedShow>
	);
};

export default withStyles(myStyles)(RawperfomanceLog);
