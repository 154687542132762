import React, {useState, useEffect} from 'react';
import {useTranslate} from 'react-admin';
import Label from 'components/Label';
import TextField from '@material-ui/core/TextField';

function SamePasswordTab({data, onSamePasswordChange, ...props}) {
	const translate = useTranslate();
	const [passwordText, setPasswordText] = useState('');

	useEffect(() => {
		setPasswordText(data.useSameValue);
	}, [data]);

	const onChange = event => {
		setPasswordText(event.target.value);
		onSamePasswordChange(event.target.value);
	};
	return (
		<div
			style={{
				height: '350px',
				display: ' flex',
				flexDirection: 'column',
			}}
		>
			<div style={{fontFamily: 'Montserrat Italic', paddingLeft: '20px'}}>
				{translate('Every account wil receive this password.')} <br />
				{translate(
					'Warning: Reduced security. Change tabs to secure passwords for each account.'
				)}
			</div>
			<div style={{padding: '50px '}}>
				<Label style={{paddingLeft: '20px'}} label={'Password:'} />
				<TextField
					id='standard-basic'
					placeholder={'Welcome123'}
					value={passwordText}
					onChange={event => onChange(event)}
				/>
			</div>
		</div>
	);
}

export default SamePasswordTab;
