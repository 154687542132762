import React, {useState} from 'react';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {translate, withDataProvider} from 'react-admin';

import classnames from 'classnames';
import compose from 'recompose/compose';

const myStyles = theme =>
	createStyles({
		tab: {
			border: `2px solid ${theme.palette.primary.main}`,
			fontFamily: 'Archivo Bold',
			fontSize: '14px',
			textTransform: 'uppercase',
			textAlign: 'center',
			height: '36px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			padding: '0px 26px',
			letterSpacing: '1.4px',
		},
		activeTab: {
			backgroundColor: theme.palette.primary.main,
			color: '#ffffff !important',
		},
	});

const EmbeddedTabs = props => {
	const {data, classes, setSelectedTab} = props;

	const [selected, setSelected] = useState(
		props.activeTab ? props.activeTab : data.tabs[0].name
	);

	const renderTabs = data => {
		var list = [];

		const {tabs} = data;

		const onClick = e => {
			// clearRequestQueue();
			setSelected(e.name);
			if (props.setSelectedTab) {
				setSelectedTab(e?.name);
			}
		};

		for (var i = 0; i < tabs.length; i++) {
			if (tabs[i].hidden) continue;

			const name = tabs[i].name;

			list.push(
				<div
					onClick={() => {
						onClick({name: name});
					}}
					className={classnames({
						[classes.tab]: true,
						[classes.activeTab]: selected === tabs[i].name,
					})}
					key={tabs[i].name}
				>
					{tabs[i].name}
				</div>
			);
		}

		return list;
	};

	const renderContent = data => {
		var list = [];

		const {tabs} = data;

		for (var i = 0; i < tabs.length; i++) {
			const name = tabs[i].name;

			if (name !== selected) {
				continue;
			}

			list.push(<div key={name}>{tabs[i].content}</div>);
		}

		return list;
	};

	return (
		<div>
			<div
				style={{
					display: 'flex',
					alignContent: 'center',
					paddingTop: '48px',
					paddingBottom: '24px',
				}}
			>
				<div
					style={{
						height: '30px',
						marginTop: 'auto',
						marginBottom: 'auto',
						display: 'inline-grid',
						gridTemplateColumns: `repeat(${data.tabs.length}, 1fr)`,
						gap: '10px',
					}}
				>
					{renderTabs(data)}
				</div>
			</div>

			<div style={{}}>{renderContent(data)}</div>
		</div>
	);
};

const enhance = compose(withDataProvider, translate, withStyles(myStyles));

export default enhance(EmbeddedTabs);
