import {Checkbox, FormControlLabel, Typography} from '@material-ui/core';
import {DialogTitle, Text} from '..';
import React, {useState} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MUIButton from '@material-ui/core/Button';
import StandardButton from '../StandardButton';
import {withDataProvider} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';

const styles = {};

const DialogButtonCombo_deprecated = props => {
	const {
		dialogTitle = 'Set {props.dialogTitle}',
		buttonConfirmLabel = 'Set {props.buttonConfirmLabel}',
		dialogBody,
		dialogConfirmAction,
		once,
		titleIcon,
	} = props;

	const [state, setState] = React.useState({
		showOnce: false,
		forceContinue: once
			? sessionStorage.getItem(once) === 'true'
				? true
				: false
			: false,
	});

	const {showOnce, forceContinue} = state;

	const [isOpen, setIsOpen] = useState(false);

	const handleClick = e => {
		e.stopPropagation();

		if (forceContinue) {
			handleConfirmationClick();
			return;
		}

		if (!isOpen) setIsOpen(true);
	};

	const handleCloseClick = e => {
		if (e) e.stopPropagation();
		props.onClick && props.onClick(e);
		if (isOpen) setIsOpen(false);
	};

	const handleConfirmationClick = e => {
		if (e) e.stopPropagation();

		props.onClick && props.onClick(e);

		if (isOpen) setIsOpen(!true);

		if (dialogConfirmAction) {
			dialogConfirmAction(e, handleCloseClick);
		} else {
			alert('set {props.dialogConfirmAction}');
		}

		if (once && showOnce) sessionStorage.setItem(once, true);
	};

	const placeholderBody = (
		<div>
			<br />{' '}
			<Typography>
				You need to pass in a custom{' '}
				<span style={{color: '#FFC100'}}>props.dialogBody</span> React Props?
				<br />
				<br />
				Example:
				<pre style={{color: '#FFC100'}}>
					{'<Typography>\n' +
						'\tYou need to pass in a custom\n' +
						"\t<span style={{color: '#FFC100'}}>props.dialogBody</span> React Props?\n" +
						'\t<br />\n' +
						'\t<br />\n' +
						'\tExample: {Recursion}\n' +
						'</Typography>'}
				</pre>
			</Typography>
		</div>
	);

	const handleChange = name => event => {
		setState({...state, [name]: event.target.checked});
	};

	return (
		<div>
			<StandardButton
				onClick={handleClick}
				variant={props.variant}
				label={props.label}
				color={props.color}
				width={props.width}
			/>
			<Dialog
				open={isOpen}
				onClose={handleCloseClick}
				aria-label={dialogTitle}
				fullWidth
			>
				<DialogTitle onClose={handleCloseClick}>
					{titleIcon? titleIcon : null}
					<Text style={{fontSize: '20px', fontFamily: 'Archivo Bold'}} label={dialogTitle} />
				</DialogTitle>
				<DialogContent>
					{dialogBody ? dialogBody : placeholderBody}
				</DialogContent>
				<DialogActions>
					<div style={{alignItems: 'center', display: 'flex', width: '100%'}}>
						{once ? (
							<span
								style={{alignItems: 'center', display: 'flex', width: '100%'}}
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={showOnce}
											onChange={handleChange('showOnce')}
											value='showOnce'
											label="Don't show again"
										/>
									}
									label="Don't show again"
								></FormControlLabel>
							</span>
						) : null}

						<div style={{flex: 1}} />
						<MUIButton
							onClick={handleConfirmationClick}
							variant='contained'
							color='primary'
							label={buttonConfirmLabel}
							style={{textTransform: 'none'}}
						>
							{buttonConfirmLabel}
						</MUIButton>
					</div>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default withStyles(styles)(
	withDataProvider(DialogButtonCombo_deprecated)
);
