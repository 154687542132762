import React, {useEffect, useState} from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const Indicators = props => {
	const [state, setState] = useState({
		prevPage: 0,
		nextPage: props.length < 6 ? props.length : 6,
	});

	useEffect(
		() =>
			setState({
				prevPage: 0,
				nextPage: props.length < 6 ? props.length : 6,
			}),
		[props.length]
	);

	if (props.length < 2) return null;

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<IconButton
				aria-label='Close'
				onClick={() => {
					props.press(props.active - 1);
					setState({
						prevPage:
							props.active === state.prevPage
								? state.prevPage - 1
								: state.prevPage,
						nextPage:
							props.active === state.prevPage
								? state.nextPage - 1
								: state.nextPage,
					});
				}}
				disableFocusRipple
				disabled={props.active === 0}
				edge={'start'}
				style={{padding: 0}}
			>
				<KeyboardArrowLeftIcon
					color={props.active === 0 ? 'secondary' : 'primary'}
				/>
			</IconButton>
			{Array.from(Array(props.length).keys()).map(i => (
				<FiberManualRecordIcon
					key={i}
					color={i === props.active ? 'primary' : 'secondary'}
					onClick={() => {
						props.press(i);
					}}
					style={{
						cursor: 'pointer',
						display: i <= state.nextPage && i >= state.prevPage ? '' : 'none',
					}}
				/>
			))}
			<IconButton
				aria-label='Close'
				style={{padding: 0}}
				onClick={() => {
					setState({
						prevPage:
							props.active === state.nextPage
								? state.prevPage + 1
								: state.prevPage,
						nextPage:
							props.active === state.nextPage
								? state.nextPage + 1
								: state.nextPage,
					});
					props.press(props.active + 1);
				}}
				disabled={props.active === props.length - 1}
			>
				<KeyboardArrowRightIcon
					color={props.active === props.length - 1 ? 'secondary' : 'primary'}
				/>
			</IconButton>
		</div>
	);
};

export default Indicators;
