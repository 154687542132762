import {
	Checkbox,
	FormControl,
	IconButton,
	MenuItem,
	Radio,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {makeStyles, useTheme, MuiThemeProvider} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import get from 'lodash/get';
import {CREATE, fetchEnd, fetchStart, NumberField, useDataProvider, useVersion} from 'react-admin';
import mailIcon from 'assets/MailIcon.svg';
import {getUserInfo} from 'utils/Data';
import {EmbeddedShow} from 'components';
import {loadObject} from 'utils/Util';
import {useEffect, useState} from 'react';
import LoadingInfo from 'components/loading/LoadingInfo';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {FilterBarTheme} from 'components/Theme';
import LoadingSpinner from 'components/loading/LoadingText';
import UsedLicenses from 'sections/Organization/Licenses/UsedLicenses';
import {LicensesServices} from 'sections/Organization/Licenses/LisensesServices';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
	table: {
		lineHeight: '5px',
		'& th, td': {
			lineHeight: '15px',
		},
		'& th': {
			fontFamily: 'Montserrat Medium',
		},
	},
	iconButton: {
		'&.Mui-disabled': {
			color: '#0E4258',
		},
		padding: 0,
	},
	actions: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		fontFamily: 'Montserrat Medium',
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		width: '48px',
	},
});

const PALLicenses = props => {
	const {
		superAdminEmail,
		showUsersPALLicenseView,
		trainingModuleUsageView,
		cancelPALUsersLicenseView,
		orgId,
		onToggleChildAppDetailUserLicenseView,
	} = props;

	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const planContactEmail = get(runwayOrgConfig, 'config.planContactEmail');

	const nearingEndDate = get(
		runwayOrgConfig,
		'config.LicenseNearingEndDateHighlight'
	);

	const unpaidPALEnabled =
		get(runwayOrgConfig, 'config.unpaidPALEnabled') === 'true';

	const theme = useTheme();

	const classes = useStyles();

	const version = useVersion();

	const dataProvider = useDataProvider();

	const dispatch = useDispatch();

	const currentYear = new Date().getFullYear();

	const [palTermsyYear, setPalTermsYear] = useState(currentYear);
	const [detailYear, setDetailYear] = useState(currentYear);
	const [detailPalTerm, setDetailPalTerm] = useState();
	const [allPalTermsYears, setAllPalTermYears] = useState([]);
	const [detailPalTermSpanYears, setDetailPalTermYears] = useState([]);
	const [palLicenseUsage, setPalLicenseUsage] = useState();
	const [palLicenseConfigs, setPalLicenseConfigs] = useState();
	const [currentTerm, setCurrentTerm] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [palLicenseUsageFilterId, setPalLicenseUsageFilterId] = useState();
	const [
		palLicenseUsageFilterMonth,
		setPalLicenseUsageFilterMonth,
	] = useState();
	const [palUsersLicenseView, setPALUsersLicenseView] = useState(false);
	const [showAllYearsUsersPALUsage, setShowAllYearsUsersPALUsage] = useState(
		false
	);
	const [showSpinner, setShowSpinner] = useState(false);

	const fetchPALDetailLicense = async (
		id,
		palYear,
		detailPalYear,
		_detailTerm
	) => {
		setPalTermsYear(palYear);

		setPalLicenseUsage();

		if (!detailPalYear && !_detailTerm) setPalLicenseConfigs();

		if (_detailTerm) setDetailPalTerm(_detailTerm);

		if (id && palYear && detailPalYear && !_detailTerm)
			setDetailYear(detailPalYear);

		setIsLoading(true);

		// const response = await dataProvider(
		// 	GET_LIST,
		// 	'listPALLicenseUsageOverview',
		// 	{
		// 		filter: {
		// 			year: palYear,
		// 			id: id,
		// 			detailYear: detailPalYear ? detailPalYear : undefined,
		// 		},
		// 	}
		// );

		const filter = {
			year: palYear,
			id: id,
			detailYear: detailPalYear ? detailPalYear : undefined,
		};

		try {
			dispatch(fetchStart());
			const response = await LicensesServices.fetchPALLicenseUsageOverview(
				dataProvider,
				filter,
				orgId
			);
	
			const detailTerm = response.palLicenseConfigs.find(item => item.isDetail);
	
			const currentActiveTerm = response.palLicenseConfigs.find(
				item => item.isCurrent
			);
	
			if (currentActiveTerm) setCurrentTerm(currentActiveTerm);
	
			if (detailTerm) {
				const detialPalYears =
					detailTerm.endDate > -1
						? Array(detailTerm.endYear - detailTerm.startYear + 1)
								.fill()
								.map((_, idx) => detailTerm.startYear + idx)
						: detailTerm.startYear < currentYear
						? Array(currentYear - detailTerm.startYear + 1)
								.fill()
								.map((_, idx) => detailTerm.startYear + idx)
						: [detailTerm.startYear];
	
				setDetailYear(
					detailPalYear
						? detailPalYear
						: palYear && palYear !== 'All'
						? palYear
						: currentActiveTerm
						? currentYear
						: detailTerm.startYear
				);
	
				setDetailPalTermYears(detialPalYears);
	
				setDetailPalTerm(detailTerm);
			}
	
			setAllPalTermYears(response.palYears);
	
			setPalLicenseUsage(response.data);
	
			setPalLicenseConfigs(response.palLicenseConfigs);
	
		} finally {
			setIsLoading(false);
			dispatch(fetchEnd());
		}

	};

	useEffect(() => {
		fetchPALDetailLicense(undefined, currentYear);
	}, [orgId, version]);

	const onDetailTermChange = async (
		id,
		palTermsyYear,
		detailYear,
		detailTerm
	) => {
		await fetchPALDetailLicense(id, palTermsyYear, detailYear, detailTerm);
	};

	const onDetailPalYearChange = async (id, palTermsyYear, detailYear) =>
		await fetchPALDetailLicense(id, palTermsyYear, detailYear);

	const updateCurrentTeramAutoEnableConfig = async (
		autoEnableMore,
		autoEnableMoreEnabled
	) => {
		setShowSpinner(true);
		await dataProvider(CREATE, 'editPALLicenseTerm', {
			data: {
				...currentTerm,
				autoEnableMore: autoEnableMore,
				autoEnableMoreEnabled: autoEnableMoreEnabled,
			},
		});
		await fetchPALDetailLicense(undefined, currentYear);
		setShowSpinner(false);
	};

	const onShowUsersLicenseView = (filterId, filterMonth, allYearsPalUsage) => {
		setPalLicenseUsageFilterId(filterId);
		setPalLicenseUsageFilterMonth(filterMonth);
		setShowAllYearsUsersPALUsage(allYearsPalUsage);
		showUsersPALLicenseView();
		setPALUsersLicenseView(true);
		if (onToggleChildAppDetailUserLicenseView)
			onToggleChildAppDetailUserLicenseView();
	};

	const onCancelPALUsersLicenseView = () => {
		setPALUsersLicenseView(false);
		cancelPALUsersLicenseView();
	};

	return (
		<div>
			{!palUsersLicenseView && !trainingModuleUsageView && (
				<div style={{paddingBottom: '24px'}}>
					<EmbeddedShow
						title={
							<div
								style={{display: 'flex', alignItems: 'center', width: '360px'}}
							>
								<span style={{font: '20px Archivo Bold'}}>
									Platform Access License
								</span>
								{allPalTermsYears.length > 0 && (
									<MuiThemeProvider theme={FilterBarTheme({isLicensed: true})}>
										<FormControl
											style={{
												marginLeft: '10px',
												width: '100%',
												maxWidth: '100px',
											}}
										>
											<Select
												onChange={e => {
													fetchPALDetailLicense(undefined, e.target.value);
												}}
												IconComponent={ExpandMoreIcon}
												value={palTermsyYear}
											>
												{allPalTermsYears.map(year => (
													<MenuItem value={year}>{year}</MenuItem>
												))}
											</Select>
										</FormControl>
									</MuiThemeProvider>
								)}
							</div>
						}
						actions={
							currentTerm && !orgId
								? [
										{
											render: () => (
												<div className={classes.actions}>
													<span style={{flex: 1}} />
													{getUserInfo().isSuper && !orgId && (
														<div
															style={{display: 'flex', alignItems: 'center'}}
														>
															{'Lock Auto-Add Licenses'}
															{showSpinner ? (
																<div className={classes.spinner}>
																	<LoadingSpinner />
																</div>
															) : (
																<IconButton
																	onClick={() =>
																		updateCurrentTeramAutoEnableConfig(
																			currentTerm.autoEnableMore,
																			!currentTerm.autoEnableMoreEnabled
																		)
																	}
																	disabled={!unpaidPALEnabled}
																	style={{
																		opacity: !unpaidPALEnabled ? '0.3' : '1',
																	}}
																>
																	{!currentTerm.autoEnableMoreEnabled ? (
																		<LockIcon color={'primary'} />
																	) : (
																		<LockOpenIcon color={'primary'} />
																	)}
																</IconButton>
															)}
														</div>
													)}
													{
														<Checkbox
															color={'primary'}
															checked={currentTerm.autoEnableMore}
															onChange={() =>
																updateCurrentTeramAutoEnableConfig(
																	!currentTerm.autoEnableMore,
																	currentTerm.autoEnableMoreEnabled
																)
															}
															size='small'
															disabled={
																!unpaidPALEnabled ||
																!currentTerm.autoEnableMoreEnabled ||
																showSpinner
															}
															style={{
																opacity:
																	!unpaidPALEnabled ||
																	!currentTerm.autoEnableMoreEnabled ||
																	showSpinner
																		? '0.3'
																		: '1',
															}}
														/>
													}
													<span style={{paddingRight: '10px'}}>
														{'Auto-Add PAL Licenses'}
													</span>
													<div>
														<Tooltip
															title={
																!unpaidPALEnabled
																	? 'Auto Add is disabled for this environment.'
																	: 'Check this box to automatically purchase one license when a learner attempts an activation and none are available'
															}
															placement='top-start'
														>
															<InfoIcon
																style={{fontSize: '20px', color: 'gray'}}
															/>
														</Tooltip>
													</div>
													<div>
														{getUserInfo().isAdmin && (
															<Tooltip
																title={
																	'Ability to Auto-Add License is locked. Please contact a Super Admin to unlock this feature.'
																}
																placement='top-start'
															>
																<a
																	href={
																		'mailto:' +
																		(superAdminEmail
																			? superAdminEmail
																			: planContactEmail) +
																		'?subject=Request to unlock Auto Add Licenses'
																	}
																>
																	<img
																		style={{
																			width: '22px',
																			marginLeft: '10px',
																			display: !currentTerm.autoEnableMoreEnabled
																				? ''
																				: 'none',
																		}}
																		src={mailIcon}
																		alt='mail icon'
																	/>
																</a>
															</Tooltip>
														)}
													</div>
													<span style={{width: '16px'}} />
												</div>
											),
										},
								  ]
								: []
						}
					>
						<Table
							paddding={'none'}
							stickyHeader
							size='medium'
							className={classes.table}
						>
							<TableHead>
								<TableRow style={{height: '5px'}}>
									<TableCell key={'term'}>{'Term'}</TableCell>
									<TableCell key={'startDate'}>{'Start Date'}</TableCell>
									<TableCell key={'endDate'}>{'End Date'}</TableCell>
									<TableCell key={'purchased'} align={'center'}>
										{'Purchased'}
									</TableCell>
									<TableCell key={'totalUsed'} align={'center'}>
										{'Total Used'}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{palLicenseConfigs &&
									palLicenseConfigs.map((config, index) => {
										return (
											<TableRow
												onClick={() =>
													onDetailTermChange(
														config.id,
														palTermsyYear,
														config.startYear,
														config
													)
												}
												key={config.id}
												style={{
													backgroundColor:
														detailPalTerm?.id === config.id
															? '#EBF1FD'
															: 'inherit',
													cursor: 'pointer',
												}}
											>
												<TableCell align={'left'}>
													<Radio
														style={{padding: 0, paddingRight: '9px'}}
														checked={detailPalTerm?.id === config.id}
														value={config.id}
													/>
													{config.term}
												</TableCell>
												<TableCell align={'left'}>
													{config.startDate && config.startDate !== -1
														? moment(config.startDate)
																.utc()
																.local()
																.format('MMM Do, YYYY h:mm A z')
														: '---'}
												</TableCell>
												<TableCell align={'left'}>
													{config.endDate && config.endDate !== -1 ? (
														moment(config.endDate)
															.utc()
															.local()
															.format('MMM Do, YYYY h:mm A z')
													) : (
														<span style={{fontSize: '24px'}}>&infin;</span>
													)}
												</TableCell>
												<TableCell align={'center'}>
													{config.licenseCount === -1 ? (
														<span style={{fontSize: '24px'}}>&infin;</span>
													) : (
														<NumberField
															record={config}
															source={'licenseCount'}
														/>
													)}
												</TableCell>
												<TableCell align={'center'}>
													{config.licenseUsed + config.unpaidLicenseUsed}
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
						{palLicenseConfigs &&
							Array.isArray(palLicenseConfigs) &&
							palLicenseConfigs.length === 0 && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										height: '250px',
									}}
								>
									<Typography color={'primary'}>
										No current term to show and no terms for the year selected
									</Typography>
								</div>
							)}
						{!palLicenseConfigs && (
							<div>
								<LoadingInfo />
							</div>
						)}
						<br />
						<Table
							paddding={'none'}
							stickyHeader
							size='medium'
							className={classes.table}
						>
							<TableHead>
								<TableRow style={{height: '5px'}}>
									<TableCell
										key={'id'}
										style={{
											borderRight: `1px solid ${theme.palette.borderColor.secondary}`,
										}}
									>
										{detailPalTermSpanYears.length > 0 && (
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between',
												}}
											>
												{
													<IconButton
														size={'medium'}
														className={classes.iconButton}
														onClick={() =>
															onDetailPalYearChange(
																detailPalTerm.id,
																palTermsyYear,
																detailYear - 1
															)
														}
														disabled={detailPalTermSpanYears[0] === detailYear}
													>
														<ArrowLeftIcon
															color={
																detailPalTermSpanYears[0] === detailYear
																	? 'disabled'
																	: 'primary'
															}
														/>
													</IconButton>
												}
												{detailYear}
												{
													<IconButton
														size={'medium'}
														className={classes.iconButton}
														onClick={() =>
															onDetailPalYearChange(
																detailPalTerm.id,
																palTermsyYear,
																detailYear + 1
															)
														}
														disabled={
															detailPalTermSpanYears[
																detailPalTermSpanYears.length - 1
															] === detailYear
														}
													>
														<ArrowRightIcon
															color={
																detailPalTermSpanYears[
																	detailPalTermSpanYears.length - 1
																] === detailYear
																	? 'disabled'
																	: 'primary'
															}
														/>
													</IconButton>
												}
											</div>
										)}
									</TableCell>
									{[
										'Jan',
										'Feb',
										'Mar',
										'Apr',
										'May',
										'Jun',
										'Jul',
										'Aug',
										'Sep',
										'Oct',
										'Nov',
										'Dec',
									].map((month, i) => (
										<TableCell
											key={month}
											align={'center'}
											style={{
												borderRight:
													i === 11
														? `1px solid ${theme.palette.borderColor.secondary}`
														: 'none',
											}}
										>
											{month}
										</TableCell>
									))}
									<TableCell key={'allTimeTotal'} align={'center'}>
										Selected Term All Years Total
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{palLicenseUsage &&
									!isLoading &&
									palLicenseUsage.map(item => {
										return (
											<TableRow key={item.id}>
												<TableCell
													key={'term'}
													align={'left'}
													style={{
														borderRight: `1px solid ${theme.palette.borderColor.secondary}`,
														fontFamily:
															item.name === 'Total Enabled Users'
																? 'Montserrat Semi Bold'
																: 'inherit',
													}}
												>
													{item.name}
												</TableCell>
												{item.monthlyTotals.map((monthTotal, i) => (
													<TableCell
														align={'center'}
														key={'month' + (i + 1)}
														style={{
															borderRight:
																i === 11
																	? `1px solid ${theme.palette.borderColor.secondary}`
																	: 'none',
															fontFamily:
																item.name === 'Total Enabled Users'
																	? 'Montserrat Semi Bold'
																	: 'inherit',
														}}
													>
														<Typography
															color={
																monthTotal === 0 || monthTotal === '---'
																	? 'inherit'
																	: 'primary'
															}
															onClick={() =>
																monthTotal === 0 || monthTotal === '---'
																	? {}
																	: onShowUsersLicenseView(
																			item.licenseType,
																			i + 1,
																			false
																	  )
															}
															style={{
																cursor:
																	monthTotal === 0 || monthTotal === '---'
																		? 'inherit'
																		: 'pointer',
																fontFamily:
																	item.name === 'Total Enabled Users'
																		? 'Montserrat Semi Bold'
																		: 'inherit',
															}}
														>
															<NumberField
																record={{monthTotal}}
																source={'monthTotal'}
																style={{
																	cursor:
																		monthTotal === 0 || monthTotal === '---'
																			? 'inherit'
																			: 'pointer',
																	fontFamily:
																		item.name === 'Total Enabled Users'
																			? 'Montserrat Semi Bold'
																			: 'inherit',
																}}
															/>
														</Typography>
													</TableCell>
												))}
												<TableCell key={'totalLicenses'} align={'center'}>
													<Typography
														color={
															item.totalLicenses === 0 ||
															item.totalLicenses === '---'
																? 'inherit'
																: 'primary'
														}
														onClick={() =>
															item.totalLicenses === 0 ||
															item.totalLicenses === '---'
																? {}
																: onShowUsersLicenseView(
																		item.licenseType,
																		null,
																		true
																  )
														}
													>
														<NumberField
															record={item}
															source={'totalLicenses'}
															style={{
																cursor:
																	item.totalLicenses === 0 ||
																	item.totalLicenses === '---'
																		? 'inherit'
																		: 'pointer',
																fontFamily:
																	item.name === 'Total Enabled Users'
																		? 'Montserrat Semi Bold'
																		: 'inherit',
															}}
														/>
													</Typography>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
						{(!palLicenseUsage || isLoading) && <LoadingInfo />}
					</EmbeddedShow>
				</div>
			)}
			{palUsersLicenseView && (
				<UsedLicenses
					orgId={orgId}
					palLicenseUsageFilterId={palLicenseUsageFilterId}
					palLicenseId={detailPalTerm.id}
					context={'PAL'}
					action={onCancelPALUsersLicenseView}
					planContactEmail={planContactEmail}
					month={palLicenseUsageFilterMonth}
					year={detailYear}
					nearingEndDate={nearingEndDate}
					palSpanYears={detailPalTermSpanYears}
					showAllYearsUsersPALUsage={showAllYearsUsersPALUsage}
				/>
			)}
		</div>
	);
};

export default PALLicenses;
