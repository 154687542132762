import React from 'react';

import loadingIcon from 'assets/loadingIcon.svg';

const LoadingText = ({style}) => {
	return (
		<div
			style={{
				display: 'flex',
				...style,
			}}
		>
			<img
				style={{
					margin: 'auto',
					width: '16px',
					height: '16px',
				}}
				src={loadingIcon}
				alt='logo'
			/>
		</div>
	);
};

export default LoadingText;
