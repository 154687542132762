
import { DiagramModel } from '@projectstorm/react-diagrams';

import { DataOptions } from 'components/VectorMap/OptionsBlade';

import { LabelNodeModel } from 'components/VectorMap/custom/Common/LabelNode/LabelNodeModel';
import { TrainingNodeModel } from 'components/VectorMap/custom/CatalogMap/TrainingNode/TrainingNodeModel';

import { getFilterOption } from '../options';
import { extractValue, sortByString, sortByNumber, capitalizeString } from '../helpers';
import { GraphNode, DEFAULT_NODE_BACKGROUND_COLOR, DEFAULT_NODE_FONT_COLOR, LABEL_NODE_WIDTH, LABEL_NODE_HEIGHT, TRAINING_NODE_SIZE, VectorMapContext } from '../model';
import { Availability, determineAvailability, determineHeatmapNodeColorByBoolean, determineHeatmapNodeColorByPercentage, determineMaxAttempts, determineMaxLessons, determineMaxTimeSpent, determineMaxTotalSkillPoints, determineMaxTotalTimeSec, determineProgressStatus, humanizeAvailability, humanizeProgressStatus, ProgressStatus, sortByAvailability, sortByProgressStatus } from './helpers';

const DEFAULT_SPACING = 10;
const OUTER_SPACING = 50;
const INNER_SPACING = 20;

/**
 * Apply any filters from the supplied data options.
 * 
 * @param data 
 * @param options 
 */
const applyFilters = (_context: VectorMapContext, data: Array<any>, options?: DataOptions | null): Array<any> => {
    let results = data.map(item => Object.assign({}, item));

    if (options && options.filter) {
        console.log("Applying Filter", options.filter);

        // Determine the "hidden" series based on the current 'series' filters (if applicable).
        const seriesFilter = getFilterOption(options.filter, "training-info.series");
        let hiddenSeries: Array<string> = [];
        if (seriesFilter && seriesFilter.children && seriesFilter.children.length > 0) {
            seriesFilter.children.forEach(item => {
                if (item.value === false && !hiddenSeries.includes(item.id)) hiddenSeries.push(item.id);
            });
        }

        // Determine the "hidden" categories based on the current 'category' filters (if applicable).
        const categoryFilter = getFilterOption(options.filter, "training-info.category");
        let hiddenCategories: Array<string> = [];
        if (categoryFilter && categoryFilter.children && categoryFilter.children.length > 0) {
            categoryFilter.children.forEach(item => {
                if (item.value === false && !hiddenCategories.includes(item.id)) hiddenCategories.push(item.id);
            });
        }

        // Determine the "hidden" availabilities based on the current 'availability' filters (if applicable).
        const availabilityFilter = getFilterOption(options.filter, "training-info.availability");
        let hiddenAvailabilities: Array<string> = [];
        if (availabilityFilter && availabilityFilter.children && availabilityFilter.children.length > 0) {
            availabilityFilter.children.forEach(item => {
                if (item.value === false && !hiddenAvailabilities.includes(item.id)) hiddenAvailabilities.push(item.id);
            });
        }

        // Determine the "hidden" progress statuses based on the current 'status' filters (if applicable).
        const statusFilter = getFilterOption(options.filter, "user-info.status");
        let hiddenStatuses: Array<string> = [];
        if (statusFilter && statusFilter.children && statusFilter.children.length > 0) {
            statusFilter.children.forEach(item => {
                if (item.value === false && !hiddenStatuses.includes(item.id)) hiddenStatuses.push(item.id);
            });
        }

        // Filter the data using all computed "visible" and "hidden" collections.
        results = results.filter(item => {
            let visible = true;
            let hidden = false;

            // Process "visible" filters (those indicating a training should be visible).
            // - N/A

            if (visible) {
                // Process "hidden" filters (those indicating a training should be hidden).
                if (hiddenSeries.find(series => item.trainingSeries && item.trainingSeries.find((item: any) => item.value === series)) != null) hidden = true;
                if (hiddenCategories.find(category => item.trainingType && item.trainingType.find((item: any) => item.value === category)) != null) hidden = true;
                if (hiddenAvailabilities.find(availability => determineAvailability(item) === availability) != null) hidden = true;
                if (hiddenStatuses.find(status => determineProgressStatus(item) === status) != null) hidden = true;
            }

            return visible && !hidden;
        });
    }

    return results;
}

/**
 * Apply any sort from the supplied data options.
 * 
 * @param nodes 
 * @param options 
 * @param sortByGroupSize 
 */
const applySort = (context: VectorMapContext, nodes: Array<GraphNode>, options?: DataOptions | null, _groupSort?: (a: any, b: any) => number) => {
    if (options) {
        // Get the selected sorting option/value.
        const sortByOption = options.sort && options.sort.options ? options.sort.options.find(item => item.value === options.sort.value) : null;
        const sortByValue = sortByOption ? sortByOption.value : null;

        console.log("Applying Sort", sortByValue);

        nodes.forEach(node => {
            if (node.nodes) {
                switch (sortByValue) {
                    case 'name-asc':
                        node.nodes.sort(sortByString('name', false, true));

                        break;
                    case 'name-desc':
                        node.nodes.sort(sortByString('name', true, true));

                        break;
                    case 'availability-asc':
                        node.nodes.sort(sortByAvailability(false, true));

                        break;
                    case 'availability-desc':
                        node.nodes.sort(sortByAvailability(true, true));

                        break;
                    case 'status-asc':
                        node.nodes.sort(sortByProgressStatus(false, true));

                        break;
                    case 'status-desc':
                        node.nodes.sort(sortByProgressStatus(true, true));

                        break;
                    case 'score-asc':
                        node.nodes.sort(sortByNumber('avgScore', false, true));

                        break;
                    case 'score-desc':
                        node.nodes.sort(sortByNumber('avgScore', true, true));

                        break;
                    case 'time-spent-asc':
                        node.nodes.sort(sortByNumber(['user'].includes(context.context) ? 'timeSpent' : 'totalTimeSec', false, true));

                        break;
                    case 'time-spent-desc':
                        node.nodes.sort(sortByNumber(['user'].includes(context.context) ? 'timeSpent' : 'totalTimeSec', true, true));

                        break;
                    default:
                        // Do nothing.
                }
            }

            console.log("Sorted Group", node.id, node.nodes);
        });
    }
}

/**
 * Determine the groups/clusters based on the supplied options.
 * 
 * @param data 
 * @param options 
 */
const determineGroups = (context: VectorMapContext, data: Array<any>, options?: DataOptions | null): Array<GraphNode> => {
    const results: Array<GraphNode> = [];

    const rootGroupOption = options && options.group ? options.group : null;
    const rootGroupOptionValue = rootGroupOption && rootGroupOption.value !== '' ? rootGroupOption.value : null;

    if (rootGroupOptionValue) {
        switch (rootGroupOptionValue) {
            case 'series':
                const distinctSeries: Array<string> = extractValue(data, 'trainingSeries');

                distinctSeries.forEach(item => {
                    results.push({ id: item, type: 'label', record: { name: capitalizeString(item) }, mainColour: null, fontColour: null, nodes: [] });
                });

                // Sort the "trainingSeries" groups such that they are in alphabetical order.
                results.sort(sortByString('id'));

                break;
            case 'category':
                const distinctCategories: Array<string> = extractValue(data, 'trainingType');

                distinctCategories.forEach(item => {
                    results.push({ id: item, type: 'label', record: { name: item }, mainColour: null, fontColour: null, nodes: [] });
                });

                // Sort the "trainingType" groups such that they are in alphabetical order.
                results.sort(sortByString('id'));

                break;
            case 'availability':
                results.push({ id: 'assigned', type: 'label', record: { name: humanizeAvailability(Availability.ASSIGNED) }, mainColour: null, fontColour: null, nodes: [] });
                results.push({ id: 'licensed', type: 'label', record: { name: humanizeAvailability(Availability.LICENSED) }, mainColour: null, fontColour: null, nodes: [] });
                results.push({ id: 'available', type: 'label', record: { name: humanizeAvailability(Availability.AVAILABLE) }, mainColour: null, fontColour: null, nodes: [] });
                results.push({ id: 'future', type: 'label', record: { name: humanizeAvailability(Availability.FUTURE) }, mainColour: null, fontColour: null, nodes: [] });

                break;
            case 'status':
                results.push({ id: 'not started', type: 'label', record: { name: humanizeProgressStatus(ProgressStatus.NOT_STARTED) }, mainColour: null, fontColour: null, nodes: [] });
                results.push({ id: 'incomplete', type: 'label', record: { name: humanizeProgressStatus(ProgressStatus.INCOMPLETE) }, mainColour: null, fontColour: null, nodes: [] });
                results.push({ id: 'completed', type: 'label', record: { name: humanizeProgressStatus(ProgressStatus.COMPLETED) }, mainColour: null, fontColour: null, nodes: [] });
                results.push({ id: 'failed', type: 'label', record: { name: humanizeProgressStatus(ProgressStatus.FAILED) }, mainColour: null, fontColour: null, nodes: [] });
                results.push({ id: 'passed', type: 'label', record: { name: humanizeProgressStatus(ProgressStatus.PASSED) }, mainColour: null, fontColour: null, nodes: [] });
                results.push({ id: 'aced', type: 'label', record: { name: humanizeProgressStatus(ProgressStatus.ACED) }, mainColour: null, fontColour: null, nodes: [] });

                break;
            case 'score':
                const maxScore = 100;

                const incrementGroupByScoreRange = 10;

                let scoreMin = 0;
                let scoreMax = incrementGroupByScoreRange;

                for (let x = 0; x < Math.ceil(maxScore / incrementGroupByScoreRange); x++) {
                    results.push({ id: '' + scoreMin + '-' + scoreMax, type: 'label', record: { name: '' + scoreMin + ' - ' + scoreMax + ' %' }, mainColour: null, fontColour: null, nodes: [] });

                    scoreMin += scoreMin === 0 ? incrementGroupByScoreRange + 1 : incrementGroupByScoreRange;
                    scoreMax += incrementGroupByScoreRange;
                }

                break;
            case 'time-spent':
                const maxTimeSpentInSeconds = ['user'].includes(context.context) ? determineMaxTimeSpent(data) : determineMaxTotalTimeSec(data);
                const maxTimeSpent = !Number.isNaN(maxTimeSpentInSeconds) ? Math.ceil(maxTimeSpentInSeconds / 60) : 0;

                const incrementGroupByTimeSpentRange = 10;

                let timeSpentMin = 0;
                let timeSpentMax = incrementGroupByTimeSpentRange;

                for (let x = 0; x < Math.ceil(maxTimeSpent / incrementGroupByTimeSpentRange); x++) {
                    results.push({ id: '' + timeSpentMin + '-' + timeSpentMax, type: 'label', record: { name: '' + timeSpentMin + ' - ' + timeSpentMax + ' Minutes' }, mainColour: null, fontColour: null, nodes: [] });

                    timeSpentMin += timeSpentMin === 0 ? incrementGroupByTimeSpentRange + 1 : incrementGroupByTimeSpentRange;
                    timeSpentMax += incrementGroupByTimeSpentRange;
                }

                break;
            default:
                // Do nothing.
        }
    }

    // If no groups can be determined then we fallback to "All Training".
    if (results.length === 0) {
        results.push({ id: null, type: 'label', record: { name: 'All Training' }, nodes: [] });
    }

    console.log("Determined Groups", results.map(item => item.record.name));

    return results;
}

/**
 * Populate the supplied groups/clusters based on the supplied options.
 * 
 * @param groups 
 * @param data 
 * @param options 
 */
const populateGroups = (context: VectorMapContext, groups: Array<GraphNode>, data: Array<any>, options?: DataOptions | null): void => {
    const rootGroupOption = options && options.group ? options.group : null;
    const rootGroupOptionValue = rootGroupOption && rootGroupOption.value != null ? rootGroupOption.value : '';

    groups.forEach(group => {
        let groupData: Array<any> | null = null;

        switch (rootGroupOptionValue) {
            case 'series':
                groupData = data.filter(item => item.trainingSeries && item.trainingSeries.find((item: any) => item.value === group.id) != null);

                break;
            case 'category':
                groupData = data.filter(item => item.trainingType && item.trainingType.find((item: any) => item.value === group.id) != null);

                break;
            case 'availability':
                groupData = data.filter(item => determineAvailability(item) === group.id as Availability);

                break;
            case 'status':
                groupData = data.filter(item => determineProgressStatus(item) === group.id as ProgressStatus);

                break;
            case 'score':
                groupData = data.filter(item => {
                    let score = item ? Number.parseFloat(item.avgScore) : null;
                    if (score != null && !Number.isNaN(score)) score = Math.round(score * 100);
                    else score = 0;

                    const groupIdParts = group.id.split('-');
                    const scoreMin = groupIdParts.length > 0 ? Number.parseInt(groupIdParts[0]) : null;
                    const scoreMax = groupIdParts.length > 1 ? Number.parseInt(groupIdParts[1]) : null;

                    if (!Number.isNaN(scoreMin) && !Number.isNaN(scoreMax) && !Number.isNaN(score)) {
                        if (score >= scoreMin && score <= scoreMax) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                });

                break;
            case 'time-spent':
                groupData = data.filter(item => {
                    let timeSpent = item ? ['user'].includes(context.context) ? Number.parseFloat(item.timeSpent) : Number.parseFloat(item.totalTimeSec) : null;
                    if (timeSpent != null && !Number.isNaN(timeSpent)) timeSpent = Math.round(timeSpent / 60);
                    else timeSpent = 0;

                    const groupIdParts = group.id.split('-');
                    const timeSpentMin = groupIdParts.length > 0 ? Number.parseInt(groupIdParts[0]) : null;
                    const timeSpentMax = groupIdParts.length > 1 ? Number.parseInt(groupIdParts[1]) : null;

                    if (!Number.isNaN(timeSpentMin) && !Number.isNaN(timeSpentMax) && !Number.isNaN(timeSpent)) {
                        if (timeSpent >= timeSpentMin && timeSpent <= timeSpentMax) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                });

                break;
            default:
                groupData = data;
        }

        if (groupData) {
            groupData.forEach(item => {
                group.nodes.push({ id: item.id, type: 'training', record: item, nodes: null });
            });
        }

        console.log("Populated Groups", groups.map(group => {
            return {
                group: group.id,
                nodes: group.nodes.map(node => {
                    if (node.record) {
                        if (node.record.name != null) return node.record.name;
                        else if (node.record.displayTitle != null) return node.record.displayTitle;
                        else if (node.record.is != null) return node.record.id;
                        else return node.record;
                    } else {
                        return null;
                    }
                })
            };
        }));
    });
}

const convertToGraph = (context: VectorMapContext, data: Array<any>, options?: DataOptions | null): Array<GraphNode> => {
    // First, we apply any supplied filters.
    const filteredData = applyFilters(context, data, options);

    // Next we determine the actual groups/clusters.
    const groups = determineGroups(context, filteredData, options);

    // Next we populate the actual groups/clusters.
    populateGroups(context, groups, filteredData, options);

    // Finally, we apply sorting.
    applySort(context, groups, options);

    // Optionally remove any group that has no sub-nodes (if the corresponding "other" option is checked).
    if (options && options.other && options.other.hideEmptyGroups) {
        return groups.filter(group => group != null && group.nodes && group.nodes.length > 0);
    } else {
        return groups;
    }
}

const generateLabelNode = (_context: VectorMapContext, record: { [prop: string]: any }, x: number, y: number, width: number, height: number) => {
    const labelNode = new LabelNodeModel();

    labelNode.setPosition(x, y);
    labelNode.setWidth(width > LABEL_NODE_WIDTH ? width : LABEL_NODE_WIDTH);
    labelNode.setHeight(height > LABEL_NODE_HEIGHT ? height : LABEL_NODE_HEIGHT);
    labelNode.setRecord({ label: record.name ? record.name : '' });
    labelNode.setFontColor('#000000');

    return labelNode;
}

const generateTrainingNode = (context: VectorMapContext, record: { [prop: string]: any }, x: number, y: number, width: number, height: number, onViewRecordDetails?: (record: any) => void, options?: DataOptions) => {
    const trainingNode = new TrainingNodeModel();

    trainingNode.setPosition(x, y);
    trainingNode.setWidth(width);
    trainingNode.setHeight(height);
    trainingNode.setContext(context.context);
    trainingNode.setRecord(record);
    trainingNode.setDataOptions(options);
    trainingNode.setBackgroundColor(record.mainColour ? record.mainColour : DEFAULT_NODE_BACKGROUND_COLOR);
    trainingNode.setFontColor(record.fontColour ? record.fontColour : DEFAULT_NODE_FONT_COLOR);
    trainingNode.setViewRecordDetailsHandler(onViewRecordDetails);

    return trainingNode;
}

const generateHeatmapTrainingNode = (context: VectorMapContext, record: { [prop: string]: any }, x: number, y: number, width: number, height: number, onViewRecordDetails?: (record: any) => void, options?: DataOptions) => {
    const extra = record ? record.extra : null;
    const heatmapValue = extra ? extra.heatmapValue : null;
    const maxNumberOfLessons = extra ? extra.maxNumberOfLessons : null;
    const maxTotalSkillPoints = extra ? extra.maxTotalSkillPoints : null;
    const maxNumberOfAttempts = extra ? extra.maxNumberOfAttempts : null;
    const maxTimeSpent = extra ? extra.maxTimeSpent : null;

    const trainingNode = new TrainingNodeModel();

    trainingNode.setPosition(x, y);
    trainingNode.setWidth(width);
    trainingNode.setHeight(height);
    trainingNode.setContext(context.context);
    trainingNode.setRecord(record);
    trainingNode.setDataOptions(options);
    trainingNode.setBackgroundColor(record.mainColour ? record.mainColour : DEFAULT_NODE_BACKGROUND_COLOR);
    trainingNode.setFontColor(record.fontColour ? record.fontColour : DEFAULT_NODE_FONT_COLOR);
    trainingNode.setViewRecordDetailsHandler(onViewRecordDetails);

    // Based on the selected heatmap determine the appropiate background color.
    if (heatmapValue) {
        switch (heatmapValue) {
            case 'lessons':
                trainingNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(record.lessons, maxNumberOfLessons));

                break;
            case 'total-skill-points':
                trainingNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(record.totalSkillPoints, maxTotalSkillPoints));

                break;
            case 'attempts':
                trainingNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(record.attempts, maxNumberOfAttempts));

                break;
            case 'score':
                trainingNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(record.avgScore, 1));

                break;
            case 'time-spent':
                trainingNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(['user'].includes(context.context) ? record.timeSpent : record.totalTimeSec, maxTimeSpent));

                break;
            case 'completion-rate':
                trainingNode.setBackgroundColor(determineHeatmapNodeColorByPercentage(record.completionRate, 1));

                break;
            case 'training-complete':
                trainingNode.setBackgroundColor(determineHeatmapNodeColorByBoolean([ProgressStatus.COMPLETED, ProgressStatus.FAILED, ProgressStatus.PASSED, ProgressStatus.ACED].includes(record.status)));

                break;
            case 'training-passed':
                trainingNode.setBackgroundColor(determineHeatmapNodeColorByBoolean([ProgressStatus.PASSED, ProgressStatus.ACED].includes(record.status)));

                break;
            default:
                // Do nothing.
        }
    }

    return trainingNode;
}

const generateModel = (context: VectorMapContext, data: Array<any>, onViewRecordDetails?: (record: any) => void, options?: DataOptions | null): DiagramModel => {
    console.log("Generating model for Catalog Map (Context = " + JSON.stringify(context) + ") ...");

    const rootGroupOption = options && options.group ? options.group : null;
    const rootGroupOptionValue = rootGroupOption && rootGroupOption.value != null ? rootGroupOption.value : '';

    const rootHeatmapOption = options && options.heatmap ? options.heatmap : null;
    const rootHeatmapOptionValue = rootHeatmapOption && rootHeatmapOption.value != null ? rootHeatmapOption.value : '';

    // Fist we convert the supplied data collection into a node graph.
    const graph = convertToGraph(context, data, options);

    // Next we dynamically determine a preferred "MAX_GROUPS_PER_ROW" and "MAX_CHILDREN_PER_ROW".
    const maxGroupsPerRow = options && options.other && options.other.groupsPerRow != null ? options.other.groupsPerRow : 4;
    const maxItemsPerRow = options && options.other && options.other.itemsPerRow != null ? options.other.itemsPerRow : 4;

    const nodes = [];
    const links = [];

    // Determine if the resulting graph is empty (meaning there are no groups with actual nodes).
    let isGraphEmpty = true;
    if (graph) {
        graph.forEach(group => {
            if (group && group.nodes && group.nodes.length > 0) isGraphEmpty = false;
        });
    }

    // Determine the maximum number of lessons provided by any training.
    const maxNumberOfLessons = determineMaxLessons(data);
    const maxTotalSkillPoints = determineMaxTotalSkillPoints(data);
    const maxNumberOfAttempts = determineMaxAttempts(data);
    const maxTimeSpent = ['user'].includes(context.context) ? determineMaxTimeSpent(data) : determineMaxTotalTimeSec(data);

    // Proceed with laying out the graph nodes.
    if (!isGraphEmpty) {
        let groupX = DEFAULT_SPACING;
        let groupY = DEFAULT_SPACING;

        let tallestGroupHeight = 0;

        for (let x = 0; x < graph.length; x++) {
            const group = graph[x];

            if (!group) continue;

            // Determine the "real" size of the entire group.
            const groupWidth = determineGroupWidth(group.nodes, maxItemsPerRow);
            const groupHeight = determineGroupHeight(group.nodes, maxItemsPerRow);

            // Track the "tallest" group for the current group row.
            if (groupHeight > tallestGroupHeight) tallestGroupHeight = groupHeight;

            // Construct the label node for the group.
            nodes.push(generateLabelNode(context, group.record, groupX, groupY, groupWidth, LABEL_NODE_HEIGHT));

            if (group.nodes && group.nodes.length > 0) {
                let childX = 0;
                let childY = LABEL_NODE_HEIGHT;

                for (let y = 0; y < group.nodes.length; y++) {
                    const item = group.nodes[y];

                    if (!item) continue;

                    // Construct the training node for the record.
                    if (options && options.heatmap && options.heatmap.value) {
                        nodes.push(generateHeatmapTrainingNode(
                            context,
                            Object.assign({
                                extra: {
                                    groupId: group.id,
                                    heatmapValue: rootHeatmapOptionValue,
                                    maxNumberOfLessons: maxNumberOfLessons,
                                    maxTotalSkillPoints: maxTotalSkillPoints,
                                    maxNumberOfAttempts: maxNumberOfAttempts,
                                    maxTimeSpent: maxTimeSpent
                                }
                            }, item.record),
                            groupX + childX,
                            groupY + childY,
                            TRAINING_NODE_SIZE,
                            TRAINING_NODE_SIZE,
                            onViewRecordDetails,
                            options
                        ));
                    } else {
                        nodes.push(generateTrainingNode(
                            context,
                            Object.assign({
                                extra: {
                                    groupId: group.id,
                                    groupByValue: rootGroupOptionValue,
                                }
                            }, item.record),
                            groupX + childX,
                            groupY + childY,
                            TRAINING_NODE_SIZE,
                            TRAINING_NODE_SIZE,
                            onViewRecordDetails,
                            options
                        ));
                    }

                    if ((y + 1) % maxItemsPerRow === 0) {
                        childX = 0;
                        childY += TRAINING_NODE_SIZE + INNER_SPACING;
                    } else {
                        childX += TRAINING_NODE_SIZE + INNER_SPACING;
                    }
                }
            }

            if ((x + 1) % maxGroupsPerRow === 0) {
                groupX = DEFAULT_SPACING;
                groupY += tallestGroupHeight + OUTER_SPACING;
                tallestGroupHeight = 0;
            } else {
                groupX += groupWidth + OUTER_SPACING;
            }
        }
    }

    const model = new DiagramModel();

    model.addAll(...[...nodes, ...links]);
    model.setLocked(true);

    return model;
}

/**
 * Determines the "real" width of a group.
 * 
 * @param nodes 
 * @param maxGroupsPerRow 
 */
const determineGroupWidth = (nodes: Array<GraphNode>, maxGroupsPerRow: number): number => {
    let width = 0;

    if (nodes == null || nodes.length === 0) {
        width = TRAINING_NODE_SIZE;
    } else if (nodes.length <= maxGroupsPerRow) {
        width = (TRAINING_NODE_SIZE * nodes.length) + (INNER_SPACING * (nodes.length - 1));
    } else {
        width = (TRAINING_NODE_SIZE * maxGroupsPerRow) + (INNER_SPACING * (maxGroupsPerRow - 1));
    }

    if (width < LABEL_NODE_WIDTH) {
        width = LABEL_NODE_WIDTH;
    }

    return width;
}

/**
 * Determines the "real" height of a group.
 * 
 * @param nodes 
 * @param maxItemsPerRow 
 */
const determineGroupHeight = (nodes: Array<GraphNode>, maxItemsPerRow: number): number => {
    let height = 0;

    if (nodes == null || nodes.length === 0) {
        height = LABEL_NODE_HEIGHT;
    } else {
        const rowCount = Math.ceil(nodes.length / maxItemsPerRow);

        height = LABEL_NODE_HEIGHT + (TRAINING_NODE_SIZE * rowCount) + (INNER_SPACING * (rowCount - 1));
    }

    return height;
}

export default generateModel;
