import '../index.css';

import {createTheme, createStyles} from '@material-ui/core/styles';

import red from '@material-ui/core/colors/red';

const MontSerratRegular = 'Montserrat Regular';

export const TSTheme = createTheme({
	palette: {
		primary: {main: '#346dec'},
		secondary: {main: '#ecf4f6'},
		info: {main: '#ffffff'},
		text: {
			primary: '#000000',
			secondary: '#C0C0C0',
			disabled: '#000000',
			hint: '#ffffff',
			info: '#85A0AE',
		},
		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.2,
		background: {
			important: '#346dec',
			default: '#ecf4f6',
			sideMenu: '#252734',
			sideMenuActiveItem: '#1b1e2b',
			sideMenuFooter: '#1b1e2b',
			contained: '#ffffff',
			containedContent: '#ffffff',
			primaryButton: '#346dec',
			alternateButton: '#ffffff',
		},
		padding: {
			pageContent: '2em',
		},
		color: {
			default: '#000000',
			informational: '#fff',
			important: '#346dec',
			sideMenu: '#fff',
			sideMenuToggleIcon: '#1c769d',
			sideMenuItemIcon: '#7a7a7a',
			sideMenuActiveItem: '#fff',
			sideMenuUserProfileIcon: '#1c769d',
			primaryButton: '#00122F',
			alternateButton: '#85A0AE',
			stepIndicator: '#ecf4f6',
			stepIndicatorActive: '#346dec',
		},
		borderColor: {
			default: '#ffffff',
			secondary: '#CADADF',
			sideMenu: '#4b5162',
			primaryNutton: 'transparent',
			alternateButton: '#85A0AE',
			sideMenuActiveItem: '#346dec',
			dropdownPopover: '#85A0AE',
			divider: '#85A0AE',
		},
		fontWeight: {
			default: 'normal',
			sideMenuActiveItem: 'bold',
		},
	},

	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: MontSerratRegular,
		color: '#000000',
	},
	overrides: {
		root: {
			color: 'primary',
		},
		RaMenuItemLink: {
			root: {
				color: 'primary',
			},
		},
		MuiMenuItem: {
			root: {
				color: '#346dec',
				'&': {
					color: '#346dec',
				},
			},
		},
		MuiButton: {
			// override the styles of all instances of this component
			startIcon: {
				marginRight: '6px'
			},
			root: {
				// Name of the rule
				textTransform: 'capitalize',
				borderRadius: 0,
				'&:hover': {
					color: 'inherit',
				},
			},
			containedSecondary: {
				backgroundColor: '#ecf4f6',
				fontFamily: 'Montserrat Semi Bold',
				fontSize: '15px',
			},
			outlinedPrimary: {
				border: '2px solid #346dec',
				color: '#346dec',
				fontFamily: 'Archivo Bold',
				fontSize: '12px',
				borderRadius: '3px',
				'&:hover': {
					border: '2px solid #346dec',
				}
			},
			containedPrimary: {
				backgroundColor: '#346dec',
				fontFamily: 'Montserrat Semi Bold',
				fontSize: '15px',
				'&:hover': {
					color: '#ffffff'
				}
			},
			textPrimary: {
				backgroundColor: '#ffffff',
				color: 'inherit',
			},
			textSecondary: {
				color: '#85A0AE',
				fontFamily: 'Archivo Bold',
				fontSize: '12px',
			},
		},
		MuiIconButton: {
			root: {
				color: '#346dec',
				fontSize: '16px',
			},
			label: {
				color: '#346dec',
			},
		},

		MuiToolbar: {
			root: {
				backgroundColor: '#0f4358',
				color: 'primary',

				'&.Pagination': {
					backgroundColor: 'rgba(0, 18, 47, 1)',

					borderRadius: '8px',
				},
			},
			regular: {
				primary: 'primary',
			},
			gutters: {
				backgroundColor: '#ffffff',
				'&&': {
					backgroundColor: '#ffffff',
				},
			},
		},
		MuiButtonBase: {
			root: {
				backgroundColor: 'transparent',
			},
		},

		MuiPickersCalendarHeader: {
			iconButton: {
				backgroundColor: 'transparent',
			},
		},

		//MuiPickersToolbarButton-toolbarBtn

		MuiPickersToolbarButton: {
			toolbarBtn: {
				backgroundColor: 'transparent!important',
			},
		},

		MuiPickersToolbarText: {
			toolbarTxt: {
				backgroundColor: 'transparent',
				color: 'white',
			},
			toolbarBtnSelected: {
				backgroundColor: 'transparent',
				color: '#ffc100',
			},
		},

		MuiCard: {
			root: {
				boxShadow: 'none',
				backgroundColor: 'rgba(0, 0, 0, 0)',
			},
		},
		MuiTabs: {
			root: {
				color: '#ffffff',
			},
		},
		MuiTable: {
			root: {
				'& > thead > tr > th:last-of-type': {
					'& > span': {
						float: 'right',
					},
				},
				'& > tbody > tr > td:last-of-type': {
					'& > span': {
						display: 'flex',
						justifyContent: 'flex-end',
						'& > li': {
							justifyContent: 'flex-end',
							'& > svg': {
								flex: '0 0 auto',
							},
						},
					},
				},
			},
		},

		MuiInputBase: {
			input: {
				paddingTop: '8px',
			},

			inputMarginDense: {
				paddingTop: '8px',
			},
			root: {
				borderRadius: '4px',
			},
		},
		MuiFilledInput: {
			input: {
				paddingTop: '8px',
			},

			inputMarginDense: {
				paddingTop: '8px',
			},
		},
		MuiOutlinedInput: {
			root: {
				'& $notchedOutline': {
					border: '1px solid #ecf4f6',
				},
				'&:hover:not($focused):not($error):not($disabled) $notchedOutline': {
					borderColor: '#ecf4f6',
				},
			},
		},
		MuiTablePagination: {
			caption: {
				color: 'primary',
			},
			select: {
				color: 'primary',
			},
		},
		MuiActionMenu: {
			root: {
				backgroundColor: 'rgba(0, 18, 47, 1)',
			},
		},
		MuiPaper: {
			root: {
				boxShadow: 'none',
				backgroundColor: '#ffffff',
				'&[role="listbox"]': {
					backgroundColor: 'rgba(0, 18, 47, 1)',
				},
				'&[variant="contained"]': {
					backgroundColor: '#ffffff',

					borderRadius: '4px',
					border: 'solid 1px #0f4358',
				},
			},
			rounded: {
				borderRadius: '4px',
				'[variant="empty"] &': {},
			},
		},
		MuiAutocomplete: {
			input: {
				backgroundColor: '#ecf4f6 !important',
				color: 'inherit',
				fontSize: 'inherit',
				borderRadius: '4px',
				paddingTop: '4px',
				paddingBottom: '4px',
				paddingLeft: '8px',
				paddingRight: '8px',
				height: '28px',
				display: 'block',
				width: '100%',
				outline: 'none',
				fontFamily: 'inherit',
				'&:-webkit-autofill': {
					transitionDelay: 'none',
					transitionProperty: 'background-color, color',
					'-webkit-text-fill-color': 'white',
					'-webkit-box-shadow': '0 0 0px 100px #ecf4f6 inset',
					transition: 'background-color rgb(0, 18, 47, 1) ease-in-out 0s',
					caretColor: 'white',
					fontSize: 'inherit',
				},
			},
			paper: {
				backgroundColor: '#ffffff',
				border: 'solid 1px #ffffff',
				fontSize: '14px',
			},
		},
		MuiList: {
			root: {
				backgroundColor: '#ffffff',
				outline: 'solid 10px #FF0000',

				paddingBottom: '4px',
				paddingTop: '4px',
			},
		},
		MuiPopover: {
			paper: {
				backgroundColor: '#ecf4f6',
				border: '1px',
				marginTop: '4px',
			},
		},
		MuiListItem: {
			root: {},
		},

		MuiCardActions: {
			root: {
				//				border: 'solid 2px rgba(15, 67, 88, 1)',
				padding: 0,
				'&': {
					padding: 0,
				},
			},
		},
		MuiCardContent: {
			root: {
				//			border: 'solid 2px rgba(15, 67, 88, 1)',
				padding: 0,
				'&': {
					padding: 0,
				},
				'&&:first-child': {
					padding: 0,
				},
			},
		},
		MuiDialog: {
			paper: {
				margin: '10px',
				// border: 'solid 1px rgba(15, 67, 88, 1)',
				display: 'flex',
				outline: 'none',
				overflowY: 'auto',
				borderRadius: 0,
				flexDirection: 'column',
				position: 'absolute',
				top: '0px',
			},
		},
		MuiDialogTitle: {
			root: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				backgroundColor: '#ffffff',
				padding: '12px 0px !important',
				fontFamily: 'Archivo Bold',
				fontSize: '20px',
				borderBottom: '1px solid #85A0AE',
				margin: '0px 24px',
			},
		},
		MuiDialogContent: {
			root: {
				backgroundColor: '#ffffff',
				flex: '1 1 auto',
				display: 'flex',
				flexDirection: 'column',
				padding: '0px 24px 0px',
				'&::-webkit-scrollbar': {
					width: '0.4em',
					backgroundColor: '#ecf4f6',
				},
				'&::-webkit-scrollbar-track': {
					boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
					webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
					width: '0.4em',
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#346dec',
					width: '0.4em',
					outline: 'none',
					borderRadius: '20px',
				},
			},
		},
		MuiDialogActions: {
			root: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				margin: '0px',
				paddingTop: '12px',
				paddingBottom: '12px',
				paddingRight: '0px',
				marginLeft: '24px',
				marginRight: '24px',
				backgroundColor: '#ffffff',
				borderTop: '1px solid #85A0AE',
			},
		},
		MuiTypography: {
			h4: {
				fontSize: '20px',
				fontWeight: '600',
			},
			h1: {
				fontFamily: 'Archivo Bold',
				fontSize: '36px',
			},
			body1: {
				fontSize: '14px',
				fontWeight: '100',
				fontFamily: 'Montserrat Medium',
			},
		},
		MuiInputLabel: {
			root: {
				fontFamily: 'Montserrat Medium',
				color: 'inherit',
				fontSize: 'inherit',
			},
		},
		MuiInput: {
			root: {
				flex: '1 1 auto',
				display: 'flex',
				alignItems: 'center',
				padding: '5px 0px 0px 4px',
				'& :focus': {
					outlineWidth: 0,
					borderWidth: 0,
				},
				'& :hover': {
					outlineWidth: 0,
					borderWidth: 0,
				},
			},
			underline: {
				border: 'none',
				position: 'relative',
				transition: 'none',
				transform: 'none',
				marginTop: '0 !important',
				'&::before': {
					border: 0,
					outline: 0,
					transform: 'scale(0)',
				},
				'&::after': {
					border: 0,
					outline: 0,
					transform: 'scale(0)',
				},
				backgroundColor: '#ecf4f6',
				height: '28px',
			},
			input: {
				paddingTop: '0px',
				paddingBottom: '0px',
				'&:hover': {
					outline: 0,
				},
				'&:-webkit-autofill': {
					transitionDelay: '9999s',
					transitionProperty: 'background-color, color',
				},
				'&::placeholder': {
					fontSize: '14px',
					color: '#000000',
				},
			},
		},
		MuiSelect: {
			selectMenu: {
				paddingTop: '0px',
				paddingBottom: '0px',
				height: '100%',
				fontFamily: 'Montserrat Medium',
				fontSize: '14px',
			},
			icon: {
				borderRadius: '2px',
				backgroundColor: '#346dec',
				color: '#ffffff',
				padding: '5px',
			},
		},
		MuiFormControlLabel: {
			label: {
				'&$disabled': {
					color: '#000000',
				},
			},
		},
	},
});

export const HideTabs = createTheme({
	...TSTheme,
	overrides: {
		...TSTheme.overrides,
		MuiTab: {
			root: {
				display: 'none',
				height: 0,
			},
		},
		MuiDivider: {
			root: {
				display: 'none',
				height: 0,
			},
		},
		MuiTabs: {
			root: {
				display: 'none',
				height: 0,
			},
		},
	},
});

export const SearchBarTheme = createTheme({
	...TSTheme,
	overrides: {
		...TSTheme.overrides,

		MuiInputLabel: {
			animated: {
				marginLeft: 2,
				height: 0,
				marginBottom: 0,
				top: 8,
				transition: 'none',
				transform: 'none',
			},
			shrink: {
				transform: 'scale(0) !important',
				color: 'transparent',

				'&[class*="Labeled"]': {
					transform: 'scale(1) !important',
					color: 'white !important',
					transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
					transformOrigin: 'top left',
					marginLeft: 0,
					height: 12,
					marginBottom: 0,
					top: 0,
				},
			},
		},
	},
});

export const DialogListTheme = createTheme({
	...TSTheme,
	overrides: {
		...TSTheme.overrides,
		MuiToolbar: {
			gutters: {
				backgroundColor: 'transparent',
			},
			root: {
				backgroundColor: 'transparent',
			},
		},

		MuiTab: {
			root: {
				display: 'none',
				height: 0,
			},
		},
		MuiDivider: {
			root: {
				display: 'none',
				height: 0,
			},
		},
		MuiTabs: {
			root: {
				display: 'none',
				height: 0,
			},
		},
	},
});

export const InputFieldTheme = createTheme({
	...TSTheme,
	overrides: {
		MuiFormControl: {
			root: {
				minHeight: '34px',
				maxHeight: '34px',
				height: '34px',
				overflow: 'hidden',
				'&:focus': {
					outlineWidth: 0,
					borderWidth: 0,
				},
				'&:hover': {
					outlineWidth: 0,
					backgroundColor: '#00132F',
					padding: 2,
					borderRadius: 4,
					borderStyle: 'solid',
					borderWidth: '1px',
					borderColor: '#0f4358',
				},
				backgroundColor: '#00132F',
				padding: 2,
				borderRadius: 4,
				borderStyle: 'solid',
				borderWidth: '1px',
				borderColor: '#0f4358',
			},
		},
		MuiSelect: {
			selectMenu: {
				paddingTop: '0px',
				paddingBottom: '0px',
				height: '100%',
			},
		},
		MuiInput: {
			root: {
				flex: '1 1 auto',
				display: 'flex',
				alignItems: 'center',
				height: '100%',
				padding: '0px 0px 0px 4px',
				'& :hover': {
					outlineWidth: 0,
					borderWidth: 0,
				},
			},
			underline: {
				border: 'none',
				position: 'relative',
				transition: 'none',
				transform: 'none',
				marginTop: '0 !important',
				'&::before': {
					border: 0,
					outline: 0,
					transform: 'scale(0)',
				},
				'&::after': {
					border: 0,
					outline: 0,
					transform: 'scale(0)',
				},
			},
			input: {
				paddingTop: '0px',
				paddingBottom: '0px',
				'&:hover': {
					border: 0,
					outline: 0,
				},
				'&::placeholder': {
					fontFamily: 'Helvetica Now Text',
					color: '#999999',
					fontSize: '4px',
				},
				border: 0,
				margin: 2,
				'&:-internal-autofill-selected': {
					transitionDelay: 'none',
					transitionProperty: 'background-color, color',
					'-webkit-text-fill-color': 'white',
					'-webkit-box-shadow': '0 0 0px 100px #00122f inset',
					transition: 'background-color rgb(0, 18, 47, 1) ease-in-out 0s',
					caretColor: 'white',
					backgroundColor: 'unset !important',
				},
			},
		},
		MuiPopover: {
			paper: {
				backgroundColor: 'rgba(0, 18, 47, 1)',
				border: 'solid 1px #1C769D',
				paddingBottom: '8px',
			},
		},
		MuiInputLabel: {
			root: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				paddingTop: '15px',
				fontSize: '15px',
				fontFamily: 'Helvetica Now Text',
			},
		},
		MuiFilledInput: {
			inputMarginDense: {
				paddingBottom: '6px',
				paddingTop: '8px',
			},
		},

		MuiFormHelperText: {
			root: {
				marginTop: '1px',
			},
		},
	},
});

export default TSTheme;

export const tabStyles = theme =>
	createStyles({
		flexHeader: {display: 'flex', marginBottom: '2em'},
		flex: {display: 'flex'},
		flexColumn: {display: 'flex', flexDirection: 'column'},
		leftCol: {flex: 1, marginRight: '1em', marginLeft: '1em'},
		singleCol: {
			marginTop: '2em',
			marginBottom: '2em',
			justifyContent: 'center',
			display: 'flex',
		},
		content: {padding: theme.palette.padding.pageContent},
		spacer: {flex: 1},
		test: {
			border: '4px solid green',
		},
		cardHeader: {
			background: theme.palette.background.contained,
		},
		cardBody: {
			background: theme.palette.background.default,
			margin: 24,
		},
		cardFooter: {
			background: theme.palette.background.contained,
		},
		tabHeader: {
			marginTop: '0px',
			marginLeft: '-24px',
			marginRight: '-24px',
			paddingTop: '6px',
			paddingLeft: '24px',
			paddingRight: '24px',
			background: theme.palette.background.contained,
		},
		tab: {
			marginBottom: '-32px',
			marginLeft: '-24px',
			marginRight: '-24px',
			paddingBottom: '4px',
			paddingLeft: '24px',
			paddingRight: '24px',
			alignItems: 'center',
			display: 'flex',
			background: theme.palette.background.default,
		},
		grow: {
			flex: 1,
		},
		show: {
			display: 'block',
		},
		email: {
			display: 'inline-block',
			minwidth: '200px',
			maxWidth: '350px',
			paddingInlineEnd: '20px',
		},
		name: {
			display: 'inline-block',
			minwidth: '150px',
			maxWidth: '350px',
			paddingInlineEnd: '20px',
		},

		created: {display: 'inline-block', width: 140, paddingInlineEnd: '20px'},
		status: {display: 'inline-block', width: 140, paddingInlineEnd: '20px'},
		actionButon: {marginTop: 0},
		title: {},
		emailLink: {
			'& a': {
				color: theme.palette.primary.main,
			},
		},
	});

export const sharedStyles = {
	topBorder: {
		border: '1px solid #0C4458',
	},
	border: {
		radius: '4px',
		border: '1px solid #0C4458',
	},
	shadow: {
		MozBoxShadow: '0px 5px 20px #00000026',
		boxShadow: '0px 5px 20px #00000026',
	},
};

export const FilterBarTheme = props =>
	createTheme({
		...TSTheme,
		overrides: {
			MuiFormControl: {
				root: {
					minHeight: '34px',
					maxHeight: '34px',
					height: '34px',
					overflow: 'hidden',
					backgroundColor: props.isLicensed
						? TSTheme.palette.background.contained
						: TSTheme.palette.background.default,
					padding: 2,
					borderRadius: 4,
					border: props.isLicensed
						? `1px solid ${TSTheme.palette.borderColor.secondary}`
						: 'none',
				},
			},
			MuiSelect: {
				selectMenu: {
					paddingTop: '0px',
					paddingBottom: '0px',
					height: '100%',
					fontFamily: 'Montserrat Medium',
					fontSize: '14px',
				},
				icon: {
					borderRadius: '2px',
					backgroundColor: TSTheme.palette.background.important,
					color: TSTheme.palette.info.main,
					padding: '5px',
				},
			},
			MuiList: {
				root: {
					backgroundColor: TSTheme.palette.background.contained,
				},
			},
			MuiInput: {
				root: {
					flex: '1 1 auto',
					display: 'flex',
					alignItems: 'center',
					padding: '5px 0px 0px 4px',
					'& :focus': {
						outlineWidth: 0,
						borderWidth: 0,
					},
					'& :hover': {
						outlineWidth: 0,
						borderWidth: 0,
					},
				},
				underline: {
					border: 'none',
					position: 'relative',
					transition: 'none',
					transform: 'none',
					marginTop: '0 !important',
					'&::before': {
						border: 0,
						outline: 0,
						transform: 'scale(0)',
					},
					'&::after': {
						border: 0,
						outline: 0,
						transform: 'scale(0)',
					},
				},
				input: {
					paddingTop: '0px',
					paddingBottom: '0px',
					'&:hover': {
						outline: 0,
					},
					'&:-webkit-autofill': {
						transitionDelay: '9999s',
						transitionProperty: 'background-color, color',
					},
					'&::placeholder': {
						fontSize: '14px',
						color: '#000000',
					},
				},
			},
			MuiInputLabel: {
				root: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				},
				animated: {
					width: '100%',
					height: '100%',
					top: 0,
					left: 0,
					marginLeft: 2,
					marginBottom: 0,
					transition: 'none',
					transform: 'none',
				},
				shrink: {
					transform: 'scale(0) !important',
					color: 'transparent',

					'&[class*="Labeled"]': {
						transform: 'scale(1) !important',
						color: 'white !important',
						transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
						transformOrigin: 'top left',
						marginLeft: 0,
						height: 12,
						marginBottom: 0,
						top: 0,
					},
				},
			},
		},
	});
