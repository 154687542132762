import {CircularProgress, createStyles, withStyles} from '@material-ui/core';
import React, {Component} from 'react';

/**
 * Props:
 *   - title
 *     - The title/label displayed in the middle of the component (just below the value/delta).
 *   - value
 *     - The value representing the progress (should be a value between 0 and 100).
 *     - This value drives the circular progress bar.
 *   - delta
 *     - The delta of the current progress (should be a value between 0 and 100).
 *     - This delta is displayed in the middle of the ciruclar progress bar instead of the value.
 *     - If omitted, the normal value is displayed in the middle of the circular progress bar.
 */
class CircularProgressBar extends Component {
	render() {
		const {classes, title, value, delta} = this.props;

		return (
			<div className={classes.root}>
				<div className={classes.progressOverlay}>
					<CircularProgress
						className={classes.backgroundProgress}
						variant='determinate'
						size={128}
						value={100}
						thickness={2}
					/>
					<CircularProgress
						className={classes.foregroundProgress}
						variant='determinate'
						size={128}
						value={value}
						thickness={2}
					/>

					<div className={classes.progressContent}>
						{delta != null && !isNaN(delta) && (
							<label className={classes.value}>
								{delta > 0 ? '+' : ''}
								{delta + '%'}
							</label>
						)}
						{delta == null ||
							(isNaN(delta) && (
								<label className={classes.value}>{value + '%'}</label>
							))}
						{title && <label className={classes.title}>{title}</label>}
					</div>
				</div>
			</div>
		);
	}
}

const myStyles = theme =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			maxWidth: '128px',
			maxHeight: '128px',
		},
		progressOverlay: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			height: '128px',
			width: '128px',
			position: 'relative',
		},
		backgroundProgress: {
			position: 'absolute',
			top: 0,
			left: 0,
			color: theme.palette.background.default,
			height: '5px',
		},
		foregroundProgress: {
			position: 'absolute',
			top: 0,
			left: 0,
			color: theme.palette.primary.main,
			height: '5px',
		},
		progressContent: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			zIndex: 99,
		},
		value: {
			color: theme.palette.color.default,
			fontSize: '22px',
			fontFamily: 'Montserrat Bold'
		},
		title: {
			fontSize: '14px',
			fontFamily: 'Montserrat Bold'
		},
	});

export default withStyles(myStyles)(CircularProgressBar);
