import {withDataProvider} from 'react-admin';
import React, {Component} from 'react';
import {disableUser, enableUser, dormantUser, GET} from 'services/customActions';
import {refreshView, translate} from 'ra-core';
import DialogButtonCombo from 'components/dialogs/DialogButtonCombo_deprecated';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {getUserInfo} from 'utils/Data';
import PALUnavailableDialog from 'components/PALUnavailableDialog';

class DisableUserButton extends Component {
	state = {
		isPALLicenseAvailable: true,
		showPALLicenseUnavailableDialog: false,
		palUnavailableErrorMessage: null
	};

	isNewPALLicenseAvailable = async () => {
		const {dataProvider, record} = this.props;

		let newLicenseAvailable = true;

		if (record.role.indexOf('TS') > -1) {
			return newLicenseAvailable;
		}

        const params = {
			numToCreate: 1,
			profileIds: [record.id]
		}; 

		await dataProvider(GET, 'isNewPALLicenseAvailable', params)
			.then(res => {
				if (res?.data?.numAvailable > 0) {
					newLicenseAvailable = true;
				} else if(Array.isArray(res?.errors) && res.errors.length > 0) {
					newLicenseAvailable = false;
					this.setState({
						showPALLicenseUnavailableDialog: true,
						isPALLicenseAvailable: false,
						palUnavailableErrorMessage: res.errors[0]?.message,
					});
				}
			})
			.catch(error => {
				newLicenseAvailable = false;
				this.setState({
					showPALLicenseUnavailableDialog: true,
					isPALLicenseAvailable: false,
					palUnavailableErrorMessage: error.message,
				});
			});

		return newLicenseAvailable;
	};

	onClosePALUnavailableDialog = () => {
		if (this.props.onClick) this.props.onClick();
		this.setState({showPALLicenseUnavailableDialog: false});
	};

	handleClick = async (e, action) => {
		const {refreshView} = this.props;

		e.stopPropagation();
		const {disableUser, enableUser, dormantUser, record} = this.props;
		if (action === 'disable') {
			disableUser(record.id, record);
			refreshView();
			this.props.onClick && this.props.onClick(e);
		} else if (action === 'enable') {
			if (await this.isNewPALLicenseAvailable()) {
				enableUser(record.id, record);
				this.props.onClick && this.props.onClick(e);
				refreshView();
			}
		} else if (action === 'dormant') {
			dormantUser(record.id, record);
			refreshView();
			this.props.onClick && this.props.onClick(e);
		}
	};

	handleCloseClick = e => {
		e && e.stopPropagation();
		this.props.onClick && this.props.onClick(e);
	};

	render() {
		const {record, label, isDormancyEnabled} = this.props;
		const enableLabel = label ? label : 'Enable User';
		const isEnabled = record && record.status === 'enabled';
		const isDormant = record && record.status === 'dormant';

		// Non Admins to not get the disable button
		if (!getUserInfo().isAdmin && !getUserInfo().isSuper) {
			return <div></div>;
		}

		return (
			<div onClick={e => e.stopPropagation()}>
				{
					(isDormant) && <>
						<MenuItem onClick={(e) => this.handleClick(e, 'enable')}>{enableLabel}</MenuItem>
					</>
				}

				{(isEnabled || isDormant) && (
					<DialogButtonCombo
						{...this.props}
						error
						label={'Disable User'}
						dialogTitle={'Disable User?'}
						record={this.props.record}
						dialogBody={
							<div>
								<br />
								<Typography>
									<span style={{color: '#FFC100'}}>
										{this.props.record.name &&
										this.props.record.name.trim().length > 0
											? this.props.record.name
											: this.props.record.userId}
									</span>{' '}
									will lose access to your organization’s module.
									<br />
									<br />
									Do you want to disable this user?
									<br />
								</Typography>
							</div>
						}
						buttonConfirmLabel={'Disable User'}
						dialogConfirmAction={(e) => this.handleClick(e, 'disable')}
					/>
				)}

				{!isEnabled && !isDormant && (
					<>
						<MenuItem onClick={(e) => this.handleClick(e, 'enable')}>{'Enable User'}</MenuItem>
						{isDormancyEnabled && <MenuItem onClick={(e) => this.handleClick(e, 'dormant')}>{'Dormant User'}</MenuItem>}
					</>
				)}

				{this.state.showPALLicenseUnavailableDialog && (
					<PALUnavailableDialog
						onClose={() => this.onClosePALUnavailableDialog()}
						title={'Cannot Enable User'}
						errorMessage={this.state.palUnavailableErrorMessage}
					/>
				)}
			</div>
		);
	}
}

DisableUserButton.propTypes = {
	disableUser: PropTypes.func.isRequired,
	dataProvider: PropTypes.func.isRequired,
	enableUser: PropTypes.func.isRequired,
	dispatch: PropTypes.func,
	record: PropTypes.object,
};

function mapStateToProps(state) {
	return {
		form: state.form,
	};
}

const mapDispatchToProps = {
	disableUser,
	enableUser,
	refreshView,
	dormantUser
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withDataProvider
);

export default enhance(DisableUserButton);
