import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

const SearchText = props => (
	<div style={{display: 'flex', alignItems: 'center'}}>
		<SearchIcon style={{height: 18,  color: '#000000'}} />
		<Typography style={{marginLeft: 0, fontSize:'14px', color: '#000000'}}>
			{props.label ? props.label : 'Use props.label with SearchText'}
		</Typography>
	</div>
);

export default SearchText;
