import {DebugContainer, ScreenTitle} from 'components';
import {LinearProgress, Loading} from 'react-admin';
import React from 'react';
import {Redirect} from 'react-router';
import useDevelop from 'hooks/useDevelop';
import useProduction from 'hooks/useProduction';
import {CircularProgress} from '@material-ui/core';

const DebugArtAudit = props => {
	const {isProduction, loading: prodLoading} = useProduction();

	const {isDebug, loading: devLoading} = useDevelop();

	if (prodLoading || devLoading) {
		return <></>;
	}
	if (isProduction && !isDebug) {
		return <Redirect to='/' />;
	}

	const renderScreen = (
		<ScreenTitle
			basePath={props.basePath}
			resource={props.resource}
			title={'Art Audit'}
		/>
	);

	return (
		<div>
			{renderScreen}
			<div style={{display: 'flex', flexWrap: 'wrap'}}>
				<DebugContainer label={'Loading'}>
					Loading a panel
					<Loading />
					Loading text
					<LinearProgress />
					<CircularProgress
						size={15}
						style={{
							position: 'relative',
							marginRight: '5px',
							top: '2px',
							color: '#fff',
						}}
					/>
				</DebugContainer>
			</div>
		</div>
	);
};

export default DebugArtAudit;
