import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { secToWeeksFull, secToWeeksSecs } from 'services/formatters';
import { translate, withDataProvider } from 'react-admin';

import { Card, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import TSTheme from './Theme';
import skillsIcon from '../assets/MiniCardIcons/Skills.svg';
import DraftIcon from '../assets/MiniCardIcons/DraftIcon.svg';
import DraftHoverIcon from '../assets/MiniCardIcons/DraftIconHover.svg';
import LicencedIcon from '../assets/MiniCardIcons/licensed.svg';
import LicencedHoverIcon from '../assets/MiniCardIcons/LicensedHover.svg';
import ModuleIcon from '../assets/MiniCardIcons/Modules.svg';
import ModulesHoverIcon from '../assets/MiniCardIcons/ModulesHover.svg';
import inReviewIcon from '../assets/MiniCardIcons/InReview.svg';
import inReviewHoverIcon from '../assets/MiniCardIcons/InReviewHover.svg';
import publishedIcon from '../assets/MiniCardIcons/Published.svg';
import publishedHoverIcon from '../assets/MiniCardIcons/PublishedHover.svg';

const getFitlerTileIcon = (iconName, hoverOrSelected) => {
	switch (iconName) {
		case 'inDevelopment':
			return hoverOrSelected ? DraftHoverIcon : DraftIcon;
		case 'inReview':
			return hoverOrSelected ? inReviewHoverIcon :  inReviewIcon;
		case 'published':
			return hoverOrSelected ? publishedHoverIcon :  publishedIcon;
		case 'allModules':
			return hoverOrSelected ? DraftHoverIcon : DraftIcon;
		case 'licensedModules':
			return hoverOrSelected ? LicencedHoverIcon : LicencedIcon;
		case 'unlicensedModules':
			return hoverOrSelected ? ModulesHoverIcon : ModuleIcon;
		default:
			return skillsIcon;
	}
}

const useStyles = makeStyles(theme => ({
	root: {
		flex: '0 0 auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.background.contained,
		width: '167px',
		height: '86px',
		padding: '10px',
		border: 'none',
		borderRadius: '5px',
		position: 'relative',
		alignItems: 'flex-start',
		overflow: 'hidden',
		MozBoxShadow: '0px 5px 20px #00000027',
		boxShadow: '0px 5px 20px #00000027',
		zIndex: 2,
		fontFamily: 'Montserrat Bold',
		textTransform: 'uppercase',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			borderRadius: '5px',
			color: '#FFF',
			cursor: 'pointer',
			'& > img': {
				fill: '#FFF',
			},
			'& $icon': {
				background: ({displayName}) => displayName !== 'allModules' 
				  ? `transparent url(${getFitlerTileIcon(displayName, true)}) 0% 0% no-repeat padding-box`
				  : 'none',
				backgroundSize: "35px 30px !important",
		        opacity: ({displayName}) => displayName === "licensedModules" ? 2 : '50%'
			}
		},
	},
	title: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		alignItems: 'start',
		fontSize: '14px',
	},
	icon: {
		width: '35px',
		height: '30px',
		opacity: ({selectedTile, title, displayName}) => title === selectedTile ? (displayName === "licensedModules"?  2 : '50%') : 0.5,
		color: '#FFF',
		background: ({displayName, selectedTile, title})  => displayName !== 'allModules' 
		  ? `transparent url(${getFitlerTileIcon(displayName, title === selectedTile)}) 0% 0% no-repeat padding-box`
		  : 'none',
		  backgroundSize: "35px 30px !important",
		  marginTop: '3px',
	},
	value: {
		fontSize: '30px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		columnGap: '10px',
	},
	chipStyle: {
		borderRadius: '16px',
		height: '22px',
		alignItems: 'center',
		textAlign: 'center',
		display: 'flex',
		padding: '0 10px',
		fontSize: '10px',
		textTransform: 'uppercase',
		fontWeight: 'bolder',
		color: '#FFFFFF',
		letterSpacing: '1px',
		whiteSpace: 'nowrap'
	},
	preload: {
		background: ({displayName}) => displayName !== 'allModules' 
		? `url(${getFitlerTileIcon(displayName, true)})`
		: 'none',
	}
}));

const StatFilterTile = (props) => {
	const {
		translate,
		title,
		titleType,
		value,
		valueType,
		valueColor,
		onClick,
		selectedTile,
		displayName
	} = props;

	const getTitleChip = displayName => {
		let value = '';
		let chipColors = {};
		switch (displayName) {
			case 'inDevelopment':
				value = 'DRAFT';
				chipColors = {
					background: '#89C32D',
					borderColor: '#89C32D',
				};
				break;

			case 'inReview':
				value = 'TALESPIN REVIEW';
				chipColors = {
					background: '#F0A033',
					borderColor: '#F0A033',
				};
				break;
			case 'published':
				value = 'PUBLISHED';
				chipColors = {
					background: '#32B0FB',
					borderColor: '#32B0FB',
				};
				break;
			default:
			//do nothing
		}

		return (
			<div className={classes.chipStyle} style={chipColors}>
				{value}
			</div>
		);
	};

	const getFormattedValue = (val, style, validate) => {
		// We format everything, unless it's a string. Then we assume its already formatted.
		if (val && typeof val !== 'string') {
			switch (valueType) {
				case 'time':
					return (
						<Tooltip title={secToWeeksFull(val)} placement='right-start'>
							<span
								className={style}
								style={validate ? { color: valueColor } : {}}
							>
								{secToWeeksSecs(val)}
							</span>
						</Tooltip>
					);
				case 'percent':
					return (
						<span
							className={style}
							style={validate ? { color: valueColor } : {}}
						>
							{(val = `${Math.round(val * 100)}%`)}
						</span>
					);
				case 'number':
					return (
						<span
							className={style}
							style={validate ? { color: valueColor } : {}}
						>
							{
								(val = val
									.toFixed(2)
									.replace('.00', '')
									.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
							}
						</span>
					);
				default:
					return (
						<span
							className={style}
							style={validate ? { color: valueColor } : {}}
						>
							{val}
						</span>
					);
			}
		}

		// We only want to enforce the exisitence of the main value
		if (validate) {
			const validateValue = value => {
				if (value === 'null' || value === 'undefined' || value === null) {
					return '---';
				} else {
					return value;
				}
			};

			val = validateValue(val);
		}

		return (
			<span className={style} style={validate ? { color: valueColor } : {}}>
				{val}
			</span>
		);
	};

    const classes = useStyles(props);

	let formattedValue = getFormattedValue(value, classes.value, true);

	return (
		<Card
			className={classes.root}
			onClick={() => onClick(title)}
			style={
				selectedTile === title
					? {
						backgroundColor: TSTheme.palette.primary.main,
						color: '#FFF',
						cursor: 'pointer',
					}
					: {}
			}
		>
			{titleType === 'chip' ? (
				getTitleChip(title)
			) : (
				<div className={classes.title}>{translate(`stats.${title}`)}</div>
			)}

			<div className={classes.value}>
				{displayName !== 'allModules' && <div className={classes.icon}></div>}
				{ <div className={classes.preload}></div>}
				{formattedValue}
			</div>
		</Card>
	);
}


StatFilterTile.propTypes = {
	translate: PropTypes.func,
	value: PropTypes.any,
	icon: PropTypes.any,
	title: PropTypes.string,
	valueType: PropTypes.string,
	displayName: PropTypes.string
};

const enhance = compose(
	translate,
	withRouter,
	withDataProvider
);

export default enhance(StatFilterTile);
