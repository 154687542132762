import {
	EmbeddedShow,
	EmbeddedTabs,
	ScreenTitle,
	SelectInput,
	StatsTilePanel,
	StatusChip,
	TimeDateField,
} from '../..';
import {fetchEnd, fetchStart, GET_LIST, useDataProvider, useVersion} from 'react-admin';
import React, {useEffect, useState} from 'react';

import Collapsible from '../../Collapsible';
import Indicators from '../../TrainingCatalogue/Indicators';
import LoadingStat from '../../loading/LoadingStat';
import Skills from './Skills';
import StepList from './Step/StepList';
import {useTheme} from '@material-ui/core';
import LoadingList from 'components/loading/LoadingList';
import StepDetail from 'components/UserTraining/Lab/Step/StepDetail';
import { useDispatch } from 'react-redux';

const LabDetail = props => {
	const {
		selectedLab,
		selectedTrainingRecord,
		teamBackAction,
		backTrainingAction,
		backLabAction,
		userData,
		selectedStep,
		teamData,
	} = props;

	const theme = useTheme();

	const dataProvider = useDataProvider();

	const [attemptChoices, setAttemptChoices] = useState(null);

	const [selectedAttempt, setSelectedAttempt] = useState(null);

	const [loading, setLoading] = useState(false);

	const version = useVersion();

	const dispatch = useDispatch();

	const [
		lessonUserTrainingLabStatsRunway,
		setLessonUserTrainingLabStatsRunway,
	] = useState(null);

	const [
		activeLearningObjectiveIndex,
		setActiveLearningObjectiveIndex,
	] = useState(0);

		const fetchUserLessonStatsAttempts = async () => {
			dispatch(fetchStart());
			try {
				setLoading(true);
				const response = await dataProvider(
					GET_LIST,
					'UserLessonSkillStatsWithLessonAttempts',
					{
						filter: {
							userId: userData.id,
							trainingId: selectedTrainingRecord.id,
							labId: selectedLab.id,
						},
					}
				);
		
				const attemptData = getAttemptData(response.data);
		
				setAttemptChoices(attemptData.choices);
				setSelectedAttempt(attemptData.selectedAttempt);
			} finally {
				setLoading(false);
			    dispatch(fetchEnd());
			}   
        }

	
	const fetchUserLessonStats = async () => {
		setLoading(true);
		dispatch(fetchStart());
        try {
			const response = await dataProvider(
				GET_LIST,
				'lessonUserTrainingLabStatsRunway',
				{
					filter: {
						userId: userData.id,
						trainingId: selectedTrainingRecord.id,
						labId: selectedLab.id,
						sId:
							selectedAttempt !== 'noAttempts'
								? selectedAttempt.sId
								: undefined,
					},
				}
			);
	
			setLessonUserTrainingLabStatsRunway(response.data);
		}finally {
			setLoading(false);
		    dispatch(fetchEnd());
		}
	};

	useEffect(() => {
		fetchUserLessonStatsAttempts();
	}, [version]);

	useEffect(() => {
		if (selectedAttempt) fetchUserLessonStats();
	}, [selectedAttempt, version]);

	const getAttemptData = data => {
		data.sort(
			(a, b) =>
				new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
		);

		const element = data.find(ele => ele.bestAttempt === true);
		const selectedElem = element ? element : data[0];

		let choices = [];
		let selected = {
			id: 'noAttempts',
			name: 'No Attempts',
		};

		if (selectedElem.totalAttempts === 0) {
			choices.push({
				id: 'noAttempts',
				name: 'No Attempts',
			});
		} else {
			data.forEach((elem, index) => {
				let formattedTime = new Date(elem.createdAt)
					.toLocaleString()
					.replace(',', '')
					.replace(/:\d{2}\s/, ' ');

				let prefix = 'Lab Attempt ' + ++index;

				let value = elem.bestAttempt
					? prefix.concat(' (Best)- ', formattedTime)
					: prefix.concat(' - ', formattedTime);

				choices.push({
					id: elem.id,
					name: value,
					sId: elem.sId,
					createdAt: elem.createdAt,
				});

				if (elem.bestAttempt) {
					selected = elem;
				}
			});
		}

		return {
			choices: choices,
			selectedAttempt: selected,
		};
	};

	const handleAttemptChange = id => {
		attemptChoices.forEach((item, idx) => {
			if (attemptChoices[idx].id === id) {
				setSelectedAttempt(attemptChoices[idx]);
			}
		});
	};

	const handleAttemptChangeFromGraph = (attempts, value) => {
		attemptChoices.forEach((item, idx) => {
			if (attemptChoices[idx].createdAt === value) {
				setSelectedAttempt(attemptChoices[idx]);
			}
		});
	};

	return (
		<React.Fragment>
			{!selectedStep && (
				<div>
					<ScreenTitle
						title={selectedLab.name}
						refresh={false}
						subTitle={true}
						coloredCrumb={true}
						titleCrumbs={
							teamData
								? [
										teamData.name,
										(userData.name &&
											userData.name.trim().length > 0 &&
											userData.name) ||
											userData.userId,
										selectedTrainingRecord.name,
								  ]
								: [
										(userData.name &&
											userData.name.trim().length > 0 &&
											userData.name) ||
											userData.userId,
										selectedTrainingRecord.name,
								  ]
						}
						backActionCrumbs={
							teamBackAction
								? [teamBackAction, backTrainingAction, backLabAction]
								: [backTrainingAction, backLabAction]
						}
					/>
					{attemptChoices && selectedAttempt && (
						<div
							style={{
								backgroundColor: theme.palette.background.contained,
								padding: '0px 16px 16px 16px',
								boxShadow: '0px 5px 20px #00000026',
							}}
						>
							{
								<EmbeddedShow
									embedded
									title={selectedLab.name}
									details={[
										{
											label: 'Status',
											render: record => (
												<StatusChip record={selectedLab} source='status' />
											),
										},
										{
											label: 'User Added',
											render: record => (
												<TimeDateField
													record={selectedLab}
													source='createdAt'
												/>
											),
										},
										{
											label: 'Completed',
											render: () => (
												<TimeDateField
													record={selectedLab}
													source='completedAt'
												/>
											),
										},
									]}
									actions={[
										{
											render: () => {
												return (
													<SelectInput
														choices={attemptChoices}
														value={selectedAttempt.id}
														disabled={
															!selectedAttempt ||
															selectedAttempt.id === 'noAttempts'
														}
														onChange={e => handleAttemptChange(e.target.value)}
														width='100%'
													/>
												);
											},
										},
									]}
								/>
							}
							<div
								style={{
									display: 'flex',
									width: '100%',
									columnGap: '30px',
									paddingBottom: '8px',
								}}
							>
								{selectedLab.description && (
									<div
										style={{
											width:
												selectedLab?.learningObjectives?.length > 0
													? '50%'
													: '100%',
										}}
									>
										<Collapsible title={'Description'}>
											<p style={{paddingLeft: '15px'}}>
												{selectedLab.description}
											</p>
										</Collapsible>
									</div>
								)}
								{selectedLab?.learningObjectives?.length > 0 && (
									<div
										style={{width: selectedLab.description ? '50%' : '100%'}}
									>
										<Collapsible title={'Learning Objectives'}>
											<div style={{paddingLeft: '15px'}}>
												<p
													dangerouslySetInnerHTML={{
														__html:
															selectedLab.learningObjectives[
																activeLearningObjectiveIndex
															],
													}}
												></p>
												<Indicators
													length={selectedLab.learningObjectives.length}
													active={activeLearningObjectiveIndex}
													press={index =>
														setActiveLearningObjectiveIndex(index)
													}
												/>
											</div>
										</Collapsible>
									</div>
								)}
							</div>
						</div>
					)}

					{((!attemptChoices && !selectedAttempt) || loading) && <LoadingList />}

					<div style={{marginTop: '24px'}}>
						{(lessonUserTrainingLabStatsRunway && !loading) ? (
							<StatsTilePanel stats={lessonUserTrainingLabStatsRunway} />
						) : (
							<LoadingStat />
						)}
					</div>
					{attemptChoices && selectedAttempt && (
						<EmbeddedTabs
							data={{
								tabs: [
									{
										name: 'Steps',
										content: (
											<StepList
												{...props}
												attemptId={
													selectedAttempt !== 'noAttempts'
														? selectedAttempt.sId
														: undefined
												}
											/>
										),
									},
									{
										name: 'Skills',
										content: (
											<Skills
												userId={userData.id}
												lessonId={selectedLab.id}
												trainingId={selectedTrainingRecord.id}
												attemptId={
													selectedAttempt !== 'noAttempts'
														? selectedAttempt.sId
														: undefined
												}
												attemptChangeHandler={handleAttemptChangeFromGraph}
											/>
										),
									},
								],
							}}
						/>
					)}
				</div>
			)}

			{selectedStep && (
				<StepDetail
					{...props}
					attemptId={
						selectedAttempt !== 'noAttempts'
							? selectedAttempt.sId
							: undefined
					}
				/>
			)}
		</React.Fragment>
	);
};

export default LabDetail;
