import React from 'react';
import {
	EmbeddedList,
	StatusChip,
	TimeSinceField,
	TimeSpanField,
} from 'components';

import PercentField from 'components/PercentField';
import ViewUserDetailButton from 'sections/Training/ViewUserDetailButton';

const UsersTab = props => {
	return (
		<EmbeddedList
			embedded={false}
			subTitle
			title={'Users'}
			resource='usersfortraining'
			columns={[
				{
					id: 'userId',
					name: 'User ID',
					sortable: true,
					//width: '200px'
				},
				{
					id: 'name',
					name: 'Name',
					sortable: true,
					//width: '185px',
				},
				{
					id: 'lastLogin',
					name: 'Last Login',
					sortable: true,
					align: 'center',
					width: '120px',
					render: record => (
						<TimeSinceField
							record={record}
							source='lastLogin'
							fontFamily={'Montserrat Regular'}
						/>
					),
					isInverted: true,
				},
				{
					id: 'completionRate',
					name: 'Completion',
					sortable: true,
					align: 'center',
					width: '100px',
					render: record => (
						<PercentField
							record={record}
							source='completionRate'
							label='completionRate'
						/>
					),
					isInverted: true,
				},

				{
					id: 'status',
					name: 'Status',
					sortable: true,
					align: 'center',
					width: '120px',
					render: record => <StatusChip record={record} source='status' />,
					isInverted: true,
				},
				{
					id: 'scoreRatio',
					name: 'Module Score',
					sortable: true,
					align: 'center',
					width: '120px',
					render: record => (
						<PercentField
							record={record}
							source='scoreRatio'
							label='completionRate'
						/>
					),
					isInverted: true,
				},
				{
					id: 'trainingTimeSec',
					name: 'Module Time',
					sortable: true,
					align: 'center',
					width: '140px',
					render: record => (
						<TimeSpanField
							record={record}
							source='trainingTimeSec'
							label='Module Time'
						/>
					),
					isInverted: true,
				},
				{
					id: 'firstPassSec',
					name: (
						<div>
							1st <span style={{color: '#99FF66'}}>Pass</span>
						</div>
					),
					sortable: true,
					align: 'center',
					width: '110px',
					render: record => (
						<TimeSpanField record={record} source='firstPassSec' />
					),
					isInverted: true,
				},
			]}
			sort={{field: 'userId', order: 'ASC'}}
			staticFilters={[{id: 'id', value: props.id}]}
			dynamicFilters={[
				{
					id: 'name',
					name: 'Search Users',
					type: 'safe_regex',
				},
			]}
			actions={[
				{
					render: record => (
						<ViewUserDetailButton
							key={'view user detail'}
							variant='link'
							record={record}
							basePath={props.basePath}
							location={props.location}
						/>
					),
				},
			]}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default UsersTab;
