import { DialogTitle, EmbeddedList, Text } from 'components';
import { GET_LIST, translate, withDataProvider } from 'react-admin';
import { refreshView } from 'ra-core';
import {
	MuiThemeProvider,
	createStyles,
	withStyles,
} from '@material-ui/core/styles';
import React, { Component } from 'react';

import { ADD_USERS_TO_TEAM } from 'services/customActions';
import { CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogListTheme } from 'components/Theme';
import LoadingList from '../../components/loading/LoadingList';
import MUIButton from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { AddOutlined } from '@material-ui/icons';

const myStyles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		normalText: {
			color: theme.palette.color.default,
		},
		importantText: {
			color: theme.palette.color.important,
		},
		divider: {
			width: '100%',
			borderColor: theme.palette.borderColor.default,
		},
		spinnerStyles: {
			position: 'relative',
			marginRight: '5px',
			top: '2px',
			color: theme.palette.primary.main,
		},
	});

class AddUsersToTeamButton extends Component {
	state = {
		error: false,
		showDialog: false,
		currentUsers: [],
		selectedUsers: [],
		isLoading: false,
	};

	async fetchTeam() {
		const {dataProvider, id} = this.props;

		const {data: currentUsers} = await dataProvider(
			GET_LIST,
			'listuserforteam',
			{filter: {teamId: id}, pagination: {page: 1, perPage: 9999}}
		);

		this.setState({currentUsers, isLoading: false});
	}

	handleClick = e => {
		if (e) e.stopPropagation();

		this.setState({
			showDialog: true,
			currentUsers: [],
			selectedUsers: [],
			isLoading: true,
		});

		this.fetchTeam();
	};

	handleCloseClick = (e, addedUsers) => {
		if (e) e.stopPropagation();

		if (this.props.onClick) this.props.onClick(e);
		if (addedUsers) {
			if (this.props.startPolling) this.props.startPolling();
			this.props.refreshView();
		}

		this.setState({
			showDialog: false,
			currentUsers: [],
			selectedUsers: [],
		});
	};

	handleSaveClick = e => {
		if (e) e.stopPropagation();

		const {selectedUsers} = this.state;

		const selectedUserIds = [];

		selectedUsers.forEach(item => {
			if (item && item.id) {
				selectedUserIds.push(item.id);
			}
		});
		this.setState({isAdding: true});
		this.handleAddToTeam({selectedIds: selectedUserIds});
	};

	handleAddToTeam = async values => {
		const {dataProvider} = this.props;

		await dataProvider(
			ADD_USERS_TO_TEAM,
			'teams',
			{
				data: {id: this.props.id, selectedIds: values.selectedIds},
			},
			{
				onSuccess: {
					refresh: true,
					unselectAll: true,
				},
				onFailure: {
					unselectAll: true,
					notification: {level: 'warning'},
				},
			}
		).then(() => {
			this.setState({isAdding: false});
			this.handleCloseClick(null, true);
		});
	};

	handleUserSelectionToggled = record => {
		const selectedUsers = [...this.state.selectedUsers];

		const targetUser = selectedUsers.find(
			item => item != null && record != null && item.id === record.id
		);

		if (targetUser == null) {
			selectedUsers.push(record);
		} else {
			const idx = selectedUsers.indexOf(targetUser);

			if (idx >= 0) {
				selectedUsers.splice(idx, 1);
			}
		}

		this.setState({selectedUsers});
	};

	isUserSelected = key => {
		const {selectedUsers} = this.state;

		return selectedUsers.find(item => item != null && item.id === key) != null;
	};

	render() {
		const {classes, translate, teamData} = this.props;
		const {selectedUsers, currentUsers, showDialog, isLoading} = this.state;

		const selectedUserIds = [];
		selectedUsers.forEach(item => {
			if (item && item.id) {
				selectedUserIds.push(item.id);
			}
		});

		const currentUserIds = [];
		// Filter out the manager by default.
		if (teamData && teamData.ownerId) currentUserIds.push(teamData.ownerId);
		// Filter out user's who are already members of this team.
		currentUsers.forEach(item => {
			if (item && item.id) {
				currentUserIds.push(item.id);
			}
		});

		const button =
			this.props.variant === 'link' ? (
				<MenuItem onClick={this.handleClick}>{this.props.label}</MenuItem>
			) : (
				<MUIButton
					onClick={this.handleClick}
					variant='outlined'
					color='primary'
					startIcon={<AddOutlined style={{marginBottom: '2px'}} />}
				>
					{this.props.label}
				</MUIButton>
			);

		return (
			<div>
				{button}
				<MuiThemeProvider theme={DialogListTheme}>
					{showDialog && (
						<Dialog
							fullWidth
							maxWidth='md'
							open={true}
							onClose={e => this.handleCloseClick(e)}
							aria-label='Add User'
						>
							<DialogTitle onClose={e => this.handleCloseClick(e)}>
								<Text
									style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
									label={translate('Add User to Team')}
								/>
							</DialogTitle>

							<DialogContent>
								{!isLoading && (
									<>
										<div
											style={{
												flex: '1 1 auto',
												display: 'flex',
												flexDirection: 'column',
												overflow: 'auto',
											}}
										>
											<EmbeddedList
												embedded={true}
												title={null}
												resource='usersbypass'
												columns={[
													{
														id: 'userId',
														name: translate('User ID'),
														sortable: true,
													},
													{
														id: 'teams',
														name: translate('Teams'),
														sortable: true,
														align: 'center',
														width: '125px',
													},
													{
														id: 'trainings',
														name: translate('Training'),
														sortable: true,
														align: 'center',
														width: '125px',
													},
													{
														id: 'lessons',
														name: translate('Lessons'),
														sortable: true,
														align: 'center',
														width: '125px',
													},
												]}
												sort={{field: 'name', order: 'ASC'}}
												staticFilters={[
													{id: 'id', value: {$nin: currentUserIds}},
													{id: 'status', value: 'enabled'},
												]}
												dynamicFilters={[
													{
														id: 'name',
														name: translate('Search Users'),
														type: 'safe_regex',
													},
												]}
												bulkActions={null}
												actions={null}
												rowClicked={null}
												selectedIds={selectedUserIds}
												selectionToggled={this.handleUserSelectionToggled}
												stripped={false}
											/>
										</div>
										<div style={{flex: '0 0 auto'}}>
											{teamData && selectedUsers.length > 0 && (
												<hr className={classes.divider} />
											)}
											{teamData && selectedUsers.length > 0 && (
												<div
													style={{
														flex: '1 1 auto',
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														padding: '10px',
													}}
												>
													<label className={classes.importantText}>
														{selectedUsers.length}{' '}
														{selectedUsers.length > 1
															? translate('users')
															: translate('user')}
													</label>
													<label className={classes.normalText}>
														&nbsp;{translate('will be added to')}&nbsp;
													</label>
													<label className={classes.importantText}>
														{teamData.name}
													</label>
												</div>
											)}
										</div>
									</>
								)}
								{isLoading && <LoadingList />}
							</DialogContent>

							<DialogActions>
								{this.state.isAdding ? (
									<div style={{display: 'flex'}}>
										<CircularProgress
											size={20}
											className={classes.spinnerStyles}
										/>
										<span>
											{selectedUsers.length > 1
												? 'Adding Users...'
												: 'Adding User...'}
										</span>
									</div>
								) : (
									<div>
										<MUIButton
											onClick={this.handleSaveClick}
											variant={'contained'}
											color={'primary'}
											disabled={selectedUsers.length < 1}
											startIcon={<AddOutlined />}
										>
											{translate('Add to Team')}
										</MUIButton>
									</div>
								)}
							</DialogActions>
						</Dialog>
					)}
				</MuiThemeProvider>
			</div>
		);
	}
}

AddUsersToTeamButton.propTypes = {
	dataProvider: PropTypes.func.isRequired,
	dispatch: PropTypes.func,
	record: PropTypes.object,
	variant: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		version: get(state, 'admin.ui.viewVersion'),
	};
};

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withStyles(myStyles),
	withDataProvider
);

export default enhance(AddUsersToTeamButton);
