import {DefaultNodeModelOptions, NodeModel, NodeModelGenerics} from '@projectstorm/react-diagrams';

export interface LabelNodeModelOptions extends DefaultNodeModelOptions {
    width?: number;
    height?: number;
    fontColor?: string;
    record: { label: string };
}

export interface LabelNodeModelGenerics extends NodeModelGenerics {
    OPTIONS: LabelNodeModelOptions
}

export class LabelNodeModel extends NodeModel<LabelNodeModelGenerics> {
    constructor(options?: LabelNodeModelOptions) {
        super({
            type: 'label',
            ...options
        });
    }

    setWidth(width?: number) {
        this.options.width = width;
    }
    getWidth() {
        return this.options.width;
    }

    setHeight(height?: number) {
        this.options.height = height;
    }
    getHeight() {
        return this.options.height;
    }

    setFontColor(fontColor?: string | null) {
        this.options.fontColor = fontColor;
    }
    getFontColor() {
        return this.options.fontColor;
    }

    setRecord(record: { label: string }) {
        this.options.record = record;
    }
    getRecord() {
        return this.options.record;
    }
}
