import React, {useEffect, useRef, useState} from 'react';

import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Toolbar from '@material-ui/core/Toolbar';
import classnames from 'classnames';
import compose from 'recompose/compose';
import style from './style';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const Pagination = props => {
	const {
		total,
		perPage = 10,
		classes,
		setPage,
		page,
		nbPageButtons,
		setPerPage,
		isPerPageControl = true,
		isPageChooser = true,
	} = props;

	const [state, setState] = useState({
		nbPages: 1,
		currentPage: 1,
	});

	const numValue = useRef(null);

	useEffect(() => {
		const calculateNumberOfPages = () => {
			try {
				const nbPages = Math.ceil(total / perPage) || 1;

				setState(prevState => ({...prevState, nbPages: nbPages}));
				if(numValue && numValue.current && numValue.current.value !== page){
					numValue.current.value = page;
				}
				
			} catch (error) {
				console.log('Unable to calculate number of pages', error);
			}
		};

		calculateNumberOfPages();
	}, [perPage, page, total]);

	const handlePageChooserKeyUp = e => {
		const {nbPages, currentPage} = state;

		if (
			e &&
			e.key === 'Enter' &&
			parseInt(e.target.value) > 0 &&
			parseInt(e.target.value) <= nbPages
		) {
			setPage(parseInt(e.target.value));
		} else if (e &&
			parseInt(e.target.value) > 0 &&
			parseInt(e.target.value) <= nbPages) {
				setPage(parseInt(e.target.value));
		}  else if ( (e && e.key === "Enter" && parseInt(e.target.value) < 1) || parseInt(e.target.value) > nbPages ){
			setPage(parseInt(currentPage));
			if(numValue && numValue.current){
				numValue.current.value = currentPage;
			}
		} else if ( (e && parseInt(e.target.value) < 1) || parseInt(e.target.value) > nbPages ){
			setPage(parseInt(currentPage));
			if(numValue && numValue.current){
				numValue.current.value = currentPage;
			}
		}
	};

	const renderPageNums = index => {
		setPage(parseInt(index));
		if(numValue && numValue.current){
			numValue.current.value = index;
		}
	}

	const renderPageButtons = () => {
		const {nbPages} = state;

		const buttons = [];

		const buttonSliderWindowSize =
			nbPageButtons != null && nbPageButtons > 0 ? nbPageButtons : 10;

		// Calculate the list of page number buttons to display (attempting to keep the current page in the middle).
		// This allows for a sliding window of page buttons based on the current page.
		let firstPage = 1;
		let lastPage = nbPages;

		firstPage = page - Math.floor(buttonSliderWindowSize / 2);
		if (firstPage < 1) firstPage = 1;

		lastPage = firstPage + (buttonSliderWindowSize - 1);
		if (lastPage > nbPages) lastPage = nbPages;

		if (lastPage - firstPage + 1 < buttonSliderWindowSize) {
			let done = false;

			while (!done) {
				if (firstPage === 1) {
					done = true;
				} else {
					if (lastPage - (firstPage - 1) < buttonSliderWindowSize) {
						firstPage = firstPage - 1;
					} else {
						done = true;
					}
				}
			}
		}

		for (let index = firstPage; index <= lastPage; index++) {
			buttons.push(
				<Button
					key={index}
					className={classnames({
						[classes.pageButton]: true,
						[classes.pageButtonActive]: index === page,
					})}
					color={index === page ? 'primary' : 'secondary'}
					onClick={() => renderPageNums(index)}
				>
					{index}
				</Button>
			);
		}

		return (
			<div className={classes.pageButtons}>
				<IconButton
					key='prev'
					onClick={() => setPage(page - 1)}
					disabled={page === 1}
					color='secondary'
					style={{padding: '7px'}}					
				>
					<ChevronLeft />
				</IconButton>

				<div className={classes.pageButtonWrapper}>{buttons}</div>

				<IconButton
					key='next'
					color='secondary'
					onClick={() => setPage(page + 1)}
					disabled={page === nbPages}
					style={{padding: '7px'}}
				>
					<ChevronRight />
				</IconButton>
			</div>
		);
	};

	const renderPageChooser = () => {
		const {nbPages, currentPage} = state;

		return (
			<div className={classes.pageChooser}>
				<label className={classes.informationalText}>Page</label>

				<input
					type='number'
					className={classes.pageField}
					defaultValue={currentPage}
					onKeyUp={handlePageChooserKeyUp}
					onInput={handlePageChooserKeyUp}
					ref={numValue}
				/>

				<label className={classes.informationalText}>of {nbPages}</label>
			</div>
		);
	};

	const renderPerPageControl = () => {
		return (
			<div className={classes.perPageControl}>
				<label className={classes.informationalText}>Results per page</label>

				<div>
					<Button
						key='10pp'
						className={classes.perPageButton}
						onClick={() => setPerPage(10)}
						color={perPage === 10 ? 'primary' : 'secondary'}
						variant={'contained'}
					>
						10
					</Button>
					<Button
						key='20pp'
						className={classes.perPageButton}
						onClick={() => setPerPage(20)}
						color={perPage === 20 ? 'primary' : 'secondary'}
						variant={'contained'}
					>
						20
					</Button>
					<Button
						key='50pp'
						className={classes.perPageButton}
						onClick={() => setPerPage(50)}
						color={perPage === 50 ? 'primary' : 'secondary'}
						variant={'contained'}
					>
						50
					</Button>
					<Button
						key='100pp'
						className={classes.perPageButton}
						onClick={() => setPerPage(100)}
						color={perPage === 100 ? 'primary' : 'secondary'}
						variant={'contained'}
					>
						100
					</Button>
				</div>
			</div>
		);
	};

	return (
		<Toolbar className={classes.root}>
			{renderPageButtons()}
			{isPageChooser && renderPageChooser()}
			{isPerPageControl && renderPerPageControl()}
		</Toolbar>
	);
};

const enhance = compose(withStyles(style));

export default enhance(Pagination);
