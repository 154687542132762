import ClickableArea from '../ClickableArea';
import React, {useState} from 'react';
import MUIButton from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import {Redirect} from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from './DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const NavigatingDialog = ({
	showOnceKey = '',
	navigateTo,
	isOpen,
	setIsOpen,
	title,
	children,
	onClose,
}) => {
	const handleClose = e => {
		if (onClose) {
			onClose(e);
		} else {
			setIsOpen(false);
		}
	};

	const autoNavigate =
		showOnceKey.trim().length !== 0 &&
		localStorage.getItem(showOnceKey) === 'true';

	const history = useHistory();

	const [isAutoNavigateChecked, setIsAutoNavigateChecked] = useState(false);

	const navigate = () => {
		if (isAutoNavigateChecked) {
			localStorage.setItem(showOnceKey, 'true');
		}
		history.push(navigateTo);
	};

	const toggleChecked = () => {
		setIsAutoNavigateChecked(!isAutoNavigateChecked);
	};

	if (isOpen && autoNavigate) {
		return <Redirect to={navigateTo} />;
	}

	return (
		<Dialog
			aria-labelledby='nav-dilaog'
			width={'330px'}
			open={isOpen}
			fullWidth={false}
			maxWidth={'xs'}
		>
			<DialogTitle onClose={handleClose}>
				{title}
			</DialogTitle>
			<DialogContent style={{padding: '12px 24px'}}>{children}</DialogContent>
			<DialogActions style={{paddingLeft: '0px'}}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						alignItems: 'baseline',
						justifyContent: 'space-between',
					}}
				>
					<ClickableArea onClick={toggleChecked}>
						<input
							style={{marginRight: '8px', cursor: 'pointer'}}
							type='checkbox'
							checked={isAutoNavigateChecked}
							onChange={toggleChecked}
						/>
						<span>Don't show again</span>
					</ClickableArea>
					<MUIButton onClick={navigate} color={'primary'} variant={'contained'} size={'small'}>
						Continue
					</MUIButton>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default NavigatingDialog;
