import React, {Component} from 'react';
import {
	CREATE,
	Create,
	fetchEnd,
	fetchStart,
	GET_ONE,
	minLength,
	required,
	showNotification,
	SimpleForm,
	translate,
	withDataProvider,
} from 'react-admin';
import TSTheme from 'components/Theme';
import {
	DialogTitle,
	EmbeddedList,
	SelectInput,
	Text,
	TextInput,
	WizardStepper,
} from 'components';
import {
	createStyles,
	MuiThemeProvider,
	withStyles,
} from '@material-ui/core/styles';
import {
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	InputLabel,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import {Route} from 'react-router';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {push} from 'connected-react-router';
import {refreshView} from 'ra-core';
import LoadingInfo from 'components/loading/LoadingInfo';
import TrainingExpiry from 'components/TrainingExpiry';
import {GET_PROFILE_INFO, GET_USER_ROLE_LIST} from 'services/customActions';
import { AddOutlined } from '@material-ui/icons';

const myStyles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		fieldRow: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
		},
		fieldColumn: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		basicField: {
			flexDirection: 'column',
			width: 'auto',
			display: 'flex',
			marginTop: '12px',
			marginBottom: '12px',
			'& > div': {
				flex: '1 1 0',
			},
			'& span': {
				fontSize: '13px',
				color: '#CCCCCC',
			},
			'& label': {
				fontSize: '13px',
				color: '#CCCCCC',
			},
			minWidth: '100px',
		},
		simpleList: {
			width: '100%',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: theme.palette.borderColor.default,
		},
		simpleListHeader: {
			paddingTop: '24px',
			paddingBottom: '24px',
		},
		normalText: {
			fontFamily: 'Montserrat Medium',
		},
		importantText: {
			color: theme.palette.color.important,
		},
		divider: {
			width: '100%',
			borderColor: theme.palette.borderColor.default,
		},
		spinnerStyles: {
			position: 'relative',
			marginRight: '5px',
			top: '2px',
			color: theme.palette.color.default,
		},
		teamInfo: {
			display: 'flex',
			flexDirection: 'row',
			width: '50%',
			'& >label': {
				paddingTop: '16px',
			},
		},
	});

class TeamCreate extends Component {
	state = {
		profileInfo: {
			information: {},
			authentication: {},
			settings: {},
		},
		activeStep: 1,
		form: {
			advanced: true,
			name: null,
			ownerId: null,
			ownerName: null,
		},
		sortSelectionTop: false,
		selectedUsers: [],
		selectedTrainings: [],
		selectedManagers: [],
		showDialog: false,
		isCreating: false,
		isLoading: true,
		userRoles: [],
		isFetchingUserRoles: false,
	};

	componentDidMount() {
		this.fetchData();
		this.applyActiveStepRoute();
	}

	componentDidUpdate() {
		if (this.state.pastLocation !== this.props.history.location.pathname) {
			setTimeout(() => {
				this.setState(state => ({
					pastLocation: this.props.history.location.pathname,
				}));
			}, 1000);
		}
	}

	async fetchData() {
		let {profileInfo, form} = this.state;

		const record = {...form};

		const response = await this.props.dataProvider(
			GET_PROFILE_INFO,
			'profileInfo'
		);

		if (response && response.data) {
			profileInfo = response.data;
			record.ownerId =
				profileInfo && profileInfo.information
					? profileInfo.information.id
					: null;
			record.ownerName =
				profileInfo && profileInfo.information
					? profileInfo.information.name
					: null;
			record.ownerRole =
				profileInfo && profileInfo.information
					? profileInfo.information.role
					: null;
		} else {
			profileInfo = {};
		}
		this.setState({profileInfo, form: record, isLoading: false});
	}

	fetchUserRoles = async () => {
		this.setState({isFetchingUserRoles: true});
		const {dataProvider} = this.props;

		await dataProvider(GET_USER_ROLE_LIST, 'getUserRoleListRunway', {})
			.then(response => {
				if (response.data) {
					this.setState({userRoles: response.data, isFetchingUserRoles: false});
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => this.setState({isFetchingUserRoles: false}));
	};

	async fetchManagerName() {
		const {dataProvider} = this.props;
		const record = {...this.state.form};

		dataProvider(GET_ONE, {id: record.ownerId})
			.then(response => {
				record.ownerName = response.data.name;
				this.setState({form: record});
			})
			.catch(error => {
				record.ownerName = null;
				this.setState({form: record});
			});
	}

	applyActiveStepRoute() {
		const {activeStep} = this.state;
		switch (activeStep) {
			case 1:
				this.props.push('/teams/create');
				break;
			case 2:
				this.props.push('/teams/create/users');
				break;
			case 3:
				this.props.push('/teams/create/training');
				break;
			case 4:
				this.props.push('/teams/create/summary');
				break;
			default:
				console.log('Unknown team creation step', activeStep);
		}
	}

	getMaxSteps() {
		const record = this.state.form;

		if (record.advanced) {
			return 4;
		} else {
			return 1;
		}
	}

	handleActiveStepChange = activeStep => {
		this.setState({activeStep: activeStep}, () => {
			this.applyActiveStepRoute();
		});
	};

	handleChecked = (name, value) => {
		const record = {...this.state.form};
		record[name] = value;
	};

	handleChange = (name, value) => {
		const record = {...this.state.form};
		const selectedUsers = [...this.state.selectedUsers];

		if (record[name] !== undefined) {
			record[name] = value;

			if (name === 'ownerId') {
				// If the owner has changed, we need to automatically remove the new owner from the list of selected users (in case they were previously selected).
				const targetUser = selectedUsers.find(
					item => item != null && record != null && item.id === record.ownerId
				);
				if (targetUser) {
					const idx = selectedUsers.indexOf(targetUser);

					if (idx >= 0) {
						selectedUsers.splice(idx, 1);
					}
				}

				this.setState({selectedUsers, form: record}, () => {
					this.fetchManagerName();
				});
			} else {
				this.setState({form: record});
			}
		} else {
			console.error('handleChange - Unknown field', name, record[name]);
		}
	};
	handleClick = () => {
		const record = {...this.state.form};
		if (record?.ownerRole?.indexOf('manager') >= 0) {
			this.setState({showDialog: false});
		} else {
			this.setState({showDialog: true}, this.fetchUserRoles);
		}
	};

	handleUserSelectionToggled = record => {
		const selectedUsers = [...this.state.selectedUsers];

		const targetUser = selectedUsers.find(
			item => item != null && record != null && item.id === record.id
		);

		if (targetUser == null) {
			selectedUsers.push(record);
		} else {
			const idx = selectedUsers.indexOf(targetUser);

			if (idx >= 0) {
				selectedUsers.splice(idx, 1);
			}
		}

		this.setState({selectedUsers, sortSelectionTop: false});
	};

	handleTrainingSelectionToggled = record => {
		const selectedTrainings = [...this.state.selectedTrainings];

		const targetTraining = selectedTrainings.find(
			item => item != null && record != null && item.id === record.id
		);

		if (targetTraining == null) {
			selectedTrainings.push(record);
		} else {
			const idx = selectedTrainings.indexOf(targetTraining);

			if (idx >= 0) {
				selectedTrainings.splice(idx, 1);
			}
		}
		this.setState({selectedTrainings});
	};

	handleManagerSelectionToggled = record => {
		const selectedManagers = [...this.state.selectedManagers];

		const targetManger = selectedManagers.find(
			item => item != null && record != null && item.id === record.id
		);

		if (targetManger == null) {
			selectedManagers.forEach(function() {
				selectedManagers.splice(0, 1);
			});
			selectedManagers.push(record);
		} else {
			const idx = selectedManagers.indexOf(targetManger);

			if (idx >= 0) {
				selectedManagers.splice(idx, 1);
			}
		}
		this.setState({selectedManagers});
	};

	handleManagerClick = () => {
		const record = {...this.state.form};
		const selectedManagers = [...this.state.selectedManagers];
		record.ownerName =
			selectedManagers[0].name.trim().length > 0
				? selectedManagers[0].name
				: selectedManagers[0].userId;
		record.ownerId = selectedManagers[0].id;
		this.setState({form: record});
		this.setState({showDialog: false});
	};

	handleClose = () => {
		this.props.push('/teams');
	};

	refreshPage = () => {
		this.props.push('/teams');
	};

	handleCloseDialog = () => {
		this.setState({showDialog: false});
	};

	handleSave = () => {
		const {dataProvider, showNotification, refreshView} = this.props;
		const {form, selectedUsers, selectedTrainings} = this.state;

		const record = {...form};
		this.setState({isCreating: true});

		if (record.name) record.name = record.name.trim();
		if (record.advanced) {
			record.users = [];

			selectedUsers.forEach(user => {
				if (user && user.id) {
					record.users.push(user.id);
				}
			});

			record.training = [];

			selectedTrainings.forEach(training => {
				if (training && training.id) {
					record.training.push(training.id);
				}
			});
		} else {
			record.users = [];
			record.training = [];
		}
		delete record.advanced;
		delete record.ownerName;

		// Dispatch an action letting react-admin know a API call is ongoing
		fetchStart();

		dataProvider(CREATE, 'teams', {data: record})
			.then(({data}) => {
				refreshView();

				this.props.push('/teams');
			})
			.catch(error => {
				console.error(`Create failed for team ${JSON.stringify(error)}`);

				showNotification(error.message, 'error');
			})
			.finally(() => {
				// Dispatch an action letting react-admin know a API call has ended
				this.setState({isCreating: false});
				fetchEnd();
			});
	};

	isFormValid = () => {
		if (
			this.state.form.name == null ||
			this.state.form.name.trim().length === 0
		)
			return false;
		if (
			this.state.form.ownerId == null ||
			this.state.form.ownerId.trim().length === 0
		)
			return false;

		return true;
	};

	sortSelectedUsersToTop = () => {
		this.setState({sortSelectionTop: true});
		if (
			document.getElementById('CreateEmbeddedListTable-embeddedTableWrapper')
		) {
			document.getElementById(
				'CreateEmbeddedListTable-embeddedTableWrapper'
			).scrollTop = 0;
		}
	};

	render() {
		const {translate, classes, basePath, location, resource} = this.props;
		const {
			form,
			selectedUsers,
			selectedTrainings,
			activeStep,
			showDialog,
			selectedManagers,
		} = this.state;

		const record = form;

		const validateTeamName = [required(), minLength(1)];

		const selectedUserIds = [];
		selectedUsers.forEach(item => {
			if (item && item.id) {
				selectedUserIds.push(item.id);
			}
		});
		const selectedTrainingIds = [];
		selectedTrainings.forEach(item => {
			if (item && item.id) {
				selectedTrainingIds.push(item.id);
			}
		});
		const selectedmanagerIds = [];

		selectedManagers.forEach(item => {
			if (item && item.id) {
				selectedmanagerIds.push(item.id);
			}
		});

		return (
			<Create basePath={basePath} location={location} resource={resource}>
				<MuiThemeProvider theme={TSTheme}>
					<Route
						exact={true}
						path='/teams/create'
						render={() => (
							<Dialog
								maxWidth='md'
								open={true}
								aria-label={translate('Create Team')}
							>
								<DialogTitle onClose={this.handleClose}>
									<Text
										style={{fontSize: '20px'}}
										label={translate('Create Team')}
									/>
								</DialogTitle>

								{this.state.isLoading ? (
									<DialogContent style={{height: '180px', width: '300px'}}>
										{' '}
										<LoadingInfo />{' '}
									</DialogContent>
								) : (
									<DialogContent style={{width: '300px', padding: '16px 24px'}}>
										<InputLabel>Team Name*</InputLabel>
										<TextInput
											value={record.name}
											onChange={e => this.handleChange('name', e.target.value)}
											validate={validateTeamName}
											placeholder='Required'
										/>
										<InputLabel style={{padding: '16px 0px'}}>
											Select Manager*
										</InputLabel>
										<SelectInput
											value={record.ownerName}
											onClick={this.handleClick}
											disabled={record?.ownerRole?.indexOf('manager') >= 0}
										/>
										<FormControlLabel
											style={{marginTop: '5px'}}
											control={
												<Checkbox
													checked={record.advanced}
													onChange={(e, value) =>
														this.handleChange('advanced', value)
													}
													value='enabled'
												/>
											}
											label={
												<span style={{fontSize: '14px'}}>
													Add Users and Training now
												</span>
											}
										/>
									</DialogContent>
								)}

								<DialogActions>
									{this.state.isCreating ? (
										<div style={{display: 'flex'}}>
											<CircularProgress
												size={20}
												className={classes.spinnerStyles}
											/>
											<span style={{color: 'white'}}>{`Creating Team...`}</span>
										</div>
									) : (
										<WizardStepper
											maxSteps={this.getMaxSteps()}
											activeStep={this.state.activeStep}
											onActiveStepChange={this.handleActiveStepChange}
											onFinish={this.handleSave}
											disabled={!this.isFormValid()}
											finishLabel={translate('Create')}
										/>
									)}
								</DialogActions>
							</Dialog>
						)}
					/>

					<Route
						exact={true}
						path='/teams/create/users'
						render={() => (
							<Dialog
								maxWidth='md'
								open={true}
								aria-label={translate('Create Team')}
							>
								<DialogTitle onClose={this.handleClose}>
									<Text
										style={{fontSize: '20px'}}
										label={translate('Add Users to this Team')}
									/>
								</DialogTitle>

								<DialogContent>
									<div
										style={{
											flex: '1 1 auto',
											display: 'flex',
											flexDirection: 'column',
											paddingTop: '16px',
										}}
									>
										<div style={{flex: '0 0 auto'}}>
											<SimpleForm record={record} toolbar={null}>
												<div className={classes.teamInfo}>
													<div style={{'margin-right': '15px', width: '100%'}}>
														<InputLabel>Team Name*</InputLabel>
														<TextInput
															value={record.name}
															onChange={e =>
																this.handleChange('name', e.target.value)
															}
															validate={validateTeamName}
															placeholder='Required'
														/>
													</div>
													<div style={{width: '100%'}}>
														<InputLabel style={{paddingBottom: '16px'}}>
															Select Manager*
														</InputLabel>
														<SelectInput
															value={record.ownerName}
															onClick={this.handleClick}
															disabled={
																record?.ownerRole?.indexOf('manager') >= 0
															}
														/>
													</div>
												</div>
											</SimpleForm>
											{/* <hr className={classes.divider} /> */}
										</div>
										<div style={{display: 'flex'}}>
											<div
												style={{
													flex: '1 1 auto',
													display: 'flex',
													flexDirection: 'column',
													width: '55%',
												}}
											>
												<EmbeddedList
													id={'CreateEmbeddedListTable'}
													embedded={true}
													cached={false}
													title={null}
													resource={'usersbypass'}
													sortSelectionTop={this.state.sortSelectionTop}
													columns={[
														{
															id: 'userId',
															name: translate('User ID'),
															sortable: true,
														},
														{
															id: 'teams',
															name: translate('Teams'),
															sortable: true,
															width: '125px',
															align: 'center',
														},
														{
															id: 'trainings',
															name: translate('Training'),
															sortable: true,
															width: '125px',
															align: 'center',
														},
													]}
													sort={
														({field: 'userId', order: 'ASC'},
														{
															id: 'userId',
															value: selectedUserIds,
															order: 'ASC',
														})
													}
													staticFilters={[
														{id: 'id', value: {$nin: [record.ownerId]}},
														{id: 'status', value: 'enabled'},
													]}
													dynamicFilters={[
														{
															id: 'name',
															name: translate('Search Users'),
															type: 'safe_regex',
														},
													]}
													bulkActions={null}
													actions={null}
													rowClicked={null}
													selectedIds={selectedUserIds}
													selectionToggled={this.handleUserSelectionToggled}
													stripped={false}
													isPerPageControl={false}
													isPageChooser={false}
												/>
											</div>

											<div style={{width: '45%', paddingLeft: '10px'}}>
												<div
													style={{
														paddingBottom: '20px',
														paddingTop: '25px',
														fontFamily: 'Montserrat Medium',
													}}
												>
													{'Users added to this Team'}
												</div>
												<div
													style={{paddingRight: '5px', paddingBottom: '5px'}}
												>
													<EmbeddedList
														embedded={true}
														subTitle
														title={'Users added to this Team'}
														resource={selectedUsers}
														columns={[
															{
																id: 'userId',
																name: translate('User ID'),
															},
														]}
														selectionToggled={this.handleUserSelectionToggled}
														stripped={false}
														splitSelection={true}
													/>
												</div>
											</div>
										</div>
									</div>
								</DialogContent>

								<DialogActions>
									{this.state.isCreating ? (
										<div style={{display: 'flex'}}>
											<CircularProgress
												size={20}
												className={classes.spinnerStyles}
											/>
											<span style={{color: 'white'}}>{`Creating Team...`}</span>
										</div>
									) : (
										<WizardStepper
											maxSteps={this.getMaxSteps()}
											activeStep={this.state.activeStep}
											onActiveStepChange={this.handleActiveStepChange}
											onFinish={this.handleSave}
											disabled={!this.isFormValid()}
											finishLabel={translate('Create')}
										/>
									)}
								</DialogActions>
							</Dialog>
						)}
					/>

					<Route
						exact={true}
						path='/teams/create/training'
						render={() => (
							<Dialog
								maxWidth='md'
								open={true}
								aria-label={translate('Create Team')}
							>
								<DialogTitle onClose={this.handleClose}>
									<Text
										style={{fontSize: '20px'}}
										label={translate('Add Training to this Team')}
									/>
								</DialogTitle>

								<DialogContent>
									<div
										style={{
											flex: '1 1 auto',
											display: 'flex',
											flexDirection: 'column',
											paddingTop: '16px',
										}}
									>
										<div style={{flex: '0 0 auto'}}>
											<SimpleForm record={record} toolbar={null}>
												<div className={classes.teamInfo}>
													<div style={{'margin-right': '15px', width: '100%'}}>
														<InputLabel>Team Name*</InputLabel>
														<TextInput
															value={record.name}
															onChange={e =>
																this.handleChange('name', e.target.value)
															}
															validate={validateTeamName}
															placeholder='Required'
														/>
													</div>
													<div style={{width: '100%'}}>
														<InputLabel style={{paddingBottom: '16px'}}>
															Select Manager*
														</InputLabel>
														<SelectInput
															value={record.ownerName}
															onClick={this.handleClick}
															disabled={
																record?.ownerRole?.indexOf('manager') >= 0
															}
														/>
													</div>
												</div>
											</SimpleForm>
											{/* <hr className={classes.divider} /> */}
										</div>
										<div style={{display: 'flex'}}>
											<div
												style={{
													flex: '1 1 auto',
													display: 'flex',
													flexDirection: 'column',
													width: '55%',
												}}
											>
												<EmbeddedList
													embedded={true}
													cached={false}
													title={null}
													resource={'trainings'}
													columns={[
														{
															id: 'name',
															name: translate('Name'),
															sortable: true,
														},
														{
															id: 'teams',
															name: translate('Teams'),
															sortable: true,
															width: '70px',
															align: 'center',
														},
														{
															id: 'lessons',
															name: translate('Lessons'),
															sortable: true,
															width: '70px',
															align: 'center',
														},
														{
															id: 'expiry',
															name: 'Expiration',
															sortable: true,
															align: 'center',
															width: '85px',
															render: record => (
																<TrainingExpiry
																	record={record}
																	source='expiry'
																/>
															),
														},
													]}
													sort={{field: 'name', order: 'ASC'}}
													staticFilters={[{id: 'expiry', value: {$ne: 0}}]}
													dynamicFilters={[
														{
															id: 'name',
															name: translate('Search Modules'),
															type: 'safe_regex',
														},
													]}
													bulkActions={null}
													actions={null}
													rowClicked={null}
													selectedIds={selectedTrainingIds}
													selectionToggled={this.handleTrainingSelectionToggled}
													stripped={false}
													isPageChooser={false}
													isPerPageControl={false}
												/>
											</div>

											<div style={{width: '45%', paddingLeft: '10px'}}>
												<div
													style={{
														paddingBottom: '20px',
														paddingTop: '25px',
														fontFamily: 'Montserrat Medium',
													}}
												>
													{'Module added to this Team'}
												</div>
												<div
													style={{paddingRight: '5px', paddingBottom: '5px'}}
												>
													<EmbeddedList
														embedded={true}
														subTitle
														title={'Module added to this Team'}
														resource={selectedTrainings}
														columns={[
															{
																id: 'name',
																name: translate('Name'),
															},
														]}
														selectionToggled={
															this.handleTrainingSelectionToggled
														}
														stripped={false}
														splitSelection={true}
													/>
												</div>
											</div>
										</div>
									</div>
								</DialogContent>

								<DialogActions>
									{this.state.isCreating ? (
										<div style={{display: 'flex'}}>
											<CircularProgress
												size={20}
												className={classes.spinnerStyles}
											/>
											<span style={{color: 'white'}}>{`Creating Team...`}</span>
										</div>
									) : (
										<WizardStepper
											maxSteps={this.getMaxSteps()}
											activeStep={this.state.activeStep}
											onActiveStepChange={this.handleActiveStepChange}
											onFinish={this.handleSave}
											disabled={!this.isFormValid()}
											finishLabel={translate('Create')}
										/>
									)}
								</DialogActions>
							</Dialog>
						)}
					/>

					<Route
						exact={true}
						path='/teams/create/summary'
						render={() => (
							<Dialog
								maxWidth='md'
								open={true}
								aria-label={translate('Create Team')}
							>
								<DialogTitle onClose={this.handleClose}>
									<Text
										style={{fontSize: '20px'}}
										label={translate('Summary')}
									/>
								</DialogTitle>

								<DialogContent style={{paddingTop: '16px'}}>
									<div>
										<SimpleForm record={record} toolbar={null}>
											<div className={classes.teamInfo}>
												<div style={{'margin-right': '15px', width: '100%'}}>
													<InputLabel>Team Name*</InputLabel>
													<TextInput
														value={record.name}
														onChange={e =>
															this.handleChange('name', e.target.value)
														}
														validate={validateTeamName}
														placeholder='Required'
													/>
												</div>
												<div style={{width: '100%'}}>
													<InputLabel style={{paddingBottom: '16px'}}>
														Select Manager*
													</InputLabel>
													<SelectInput
														value={record.ownerName}
														onClick={this.handleClick}
														disabled={
															record?.ownerRole?.indexOf('manager') >= 0
														}
													/>
												</div>
											</div>
										</SimpleForm>

										{/* <hr className={classes.divider} /> */}

										<div className={classes.fieldRow}>
											<div
												className={classes.fieldColumn}
												style={{marginRight: '5px'}}
											>
												<div className={classes.simpleListHeader}>
													<span className={classes.normalText}>
														<label className={classes.importantText}>
															{selectedUsers.length}{' '}
															{selectedUsers.length > 1
																? translate('Users')
																: translate('User')}
														</label>{' '}
														{translate('on this Team')}
													</span>
												</div>

												<div>
													<EmbeddedList
														embedded={true}
														subTitle
														title={false}
														resource={selectedUsers}
														columns={[
															{
																id: 'name',
																name: translate('Name'),
																render: record => {
																	return (
																		<div
																			style={{
																				whiteSpace: 'nowrap',
																				flex: ' 1',
																				overflow: 'hidden',
																				textOverflow: 'ellipsis',
																				minWidth: '0',
																			}}
																		>
																			{record.name &&
																			record.name.trim().length > 0
																				? record.name
																				: record.userId}
																		</div>
																	);
																},
															},
														]}
														staticFilters={null}
														dynamicFilters={null}
														bulkActions={null}
														actions={null}
														selectedIds={null}
														selectionToggled={null}
														stripped={false}
													/>
												</div>
												<br />
											</div>
											<div
												className={classes.fieldColumn}
												style={{marginLeft: '5px'}}
											>
												<div className={classes.simpleListHeader}>
													<span className={classes.normalText}>
														<label className={classes.importantText}>
															{selectedTrainings.length}{' '}
															{selectedTrainings.length > 1
																? translate('Training Modules')
																: translate('Training Module')}
														</label>{' '}
														{translate('for this Team')}
													</span>
												</div>
												<div>
													<EmbeddedList
														embedded={true}
														subTitle
														title={false}
														resource={selectedTrainings}
														columns={[
															{
																id: 'name',
																name: translate('Name'),
															},
														]}
														staticFilters={null}
														dynamicFilters={null}
														bulkActions={null}
														actions={null}
														selectedIds={null}
														selectionToggled={null}
														stripped={false}
													/>
												</div>
												<br />
											</div>
										</div>
									</div>
								</DialogContent>

								<DialogActions>
									{this.state.isCreating ? (
										<div style={{display: 'flex'}}>
											<CircularProgress
												size={20}
												className={classes.spinnerStyles}
											/>
											<span style={{color: 'white'}}>{`Creating Team...`}</span>
										</div>
									) : (
										<WizardStepper
											maxSteps={this.getMaxSteps()}
											activeStep={this.state.activeStep}
											onActiveStepChange={this.handleActiveStepChange}
											onFinish={this.handleSave}
											disabled={!this.isFormValid()}
											finishLabel={translate('Create')}
											finishButtonStartIcon={<AddOutlined style={{ marginBottom: '1px' }}/>}
										/>
									)}
								</DialogActions>
							</Dialog>
						)}
					/>

					{this.selectManagerDialog(
						showDialog,
						translate,
						activeStep,
						selectedmanagerIds,
						selectedManagers,
						classes
					)}
				</MuiThemeProvider>
			</Create>
		);
	}

	selectManagerDialog(
		showDialog,
		translate,
		activeStep,
		selectedmanagerIds,
		selectedManagers,
		classes
	) {
		const {userRoles} = this.state;
		const deafultUserRoleFilter = [];

		userRoles.forEach(item => {
			if (item.id.indexOf('user') < 0) deafultUserRoleFilter.push(item.id);
		});
		return (
			<Dialog
				maxWidth='md'
				open={showDialog}
				aria-label={translate('Select Manager')}
			>
				<DialogTitle onClose={this.handleCloseDialog}>
					{activeStep === 1 && (
						<Text
							style={{fontSize: '20px'}}
							label={translate('Select Manager')}
						/>
					)}
				</DialogTitle>
				<DialogContent
					style={this.state.isFetchingUserRoles ? {width: '600px'} : {}}
				>
					<div
						style={{
							flex: '1 1 auto',
							display: 'flex',
							flexDirection: 'column',
							overflow: 'auto',
							padding: '16px 24px',
						}}
					>
						{this.state.isFetchingUserRoles ? (
							<LoadingInfo />
						) : (
							<EmbeddedList
								embedded={true}
								cached={false}
								title={translate('Available Users')}
								resource={'users'}
								columns={[
									{id: 'userId', name: translate('User ID'), sortable: true},
									{id: 'name', name: translate('User Name'), sortable: true},
									{
										id: 'teams',
										name: translate('Teams'),
										sortable: true,
										width: '125px',
										align: 'center',
									},
									{
										id: 'trainings',
										name: translate('Modules'),
										sortable: true,
										width: '125px',
										align: 'center',
									},
								]}
								sort={{field: 'name', order: 'ASC'}}
								staticFilters={null}
								dynamicFilters={[
									{id: 'name', name: translate('Search'), type: 'safe_regex'},
									{
										id: 'role',
										name: translate('Role'),
										type: 'exact',
										choices: [
											{
												value: {$in: [...deafultUserRoleFilter]},
												label: translate('All Roles'),
											},
											...userRoles
												.filter(item => item.id.indexOf('user') < 0)
												.map(item => ({value: item.id, label: item.name})),
										],
									},
								]}
								bulkActions={null}
								actions={null}
								rowClicked={null}
								selectedIds={selectedmanagerIds}
								selectionToggled={this.handleManagerSelectionToggled}
								stripped={false}
							/>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						size='small'
						color='primary'
						variant='contained'
						onClick={this.handleManagerClick}
						disabled={selectedManagers.length < 1}
					>
						<label>{'Select Manager'}</label>
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

TeamCreate.propTypes = {
	dataProvider: PropTypes.func.isRequired,
	record: PropTypes.object,
	push: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		users: get(state, 'admin.resources.usersbypass'),
		trainings: get(state, 'admin.resources.trainings'),
	};
};

const mapDispatchToProps = {
	fetchEnd,
	fetchStart,
	push,
	refreshView,
	showNotification,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withStyles(myStyles),
	withDataProvider
);

export default enhance(TeamCreate);
