import React, { useEffect, useState } from 'react';
import {
	fetchEnd,
	fetchStart,
	GET_ONE,
	useDataProvider,
	useVersion,
	withDataProvider,
} from 'react-admin';

import { EmbeddedTabs } from 'components';
import Information from 'sections/Training/Show/Information';
import LessonsTab from './Lessons/Lesson';
import TeamsTab from './TeamsTab';
import UsersTab from './UsersTab';
import compose from 'recompose/compose';
import { tabStyles } from 'components/Theme';
import { withStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import SkillsTab from 'sections/Training/Show/SkillsTab';
import LabsTab from 'sections/Training/Show/Labs';
import { GET, GET_FUTURE_TRAINING } from "services/customActions";
import { getOrgId, loadObject } from "utils/Util";
import { useDispatch } from "react-redux";
import { getUserInfo } from "utils/Data";

const TrainingShow = props => {
	const { id } = props;

	const dataProvider = useDataProvider();
	const location = useLocation();
	const version = useVersion();

	const dispatch = useDispatch();

	const [trainingData, setTrainingData] = useState(null);
	const [trainingStatus, setTrainingStatus] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isPlayable, setIsPlayable] = useState(false);

	const summaryFriendData = loadObject(loadObject('project').id + '-summaryFriendData');

	const isTester = summaryFriendData?.isTester ? true : false;

	const [selectedSkills, setSelectedSkills] = useState(
		location.state?.selectedSkills ? location.state.selectedSkills : []
	);

	useEffect(() => {
		if (document.body.style.overflow === 'hidden') {
			document.body.style.overflow = 'unset!important';
		}

		const element = document.getElementById('main');
		if (element) element.style.overflowY = 'scroll';

		fetchData();
	}, [version]);

	const fetchData = async () => {
		setLoading(true);
		dispatch(fetchStart());
		try {
			const { data } = await dataProvider(GET_ONE, 'trainings', { id, filter: {orgId: getOrgId()} });

			if (
				data && data.accessibility.licensed.value &&
				data.isCPD &&
				(data.trainingDetail.moduleStatus === 'Published' ||
					getUserInfo().trainingModuleEditor ||
					isTester)
			) {
				await dataProvider(GET, 'isTSAPlayable', { appKeyName: data?.appKeyName }).then((res) => {
					if (res.success) {
						setIsPlayable(true);
					} else {
						setIsPlayable(false);
					}
				}).catch(e => {
					console.error(e);
					setIsPlayable(false);
				})
			} else {
				setIsPlayable(false);
			}
			let status = null;
			if (data && data.appKeyName) {
				const response = await dataProvider(GET_FUTURE_TRAINING, 'ProjectStatus', { appKeyName: data.appKeyName });
				status = response.data;
			} 
			setTrainingStatus(status);
			setTrainingData(data);
		}finally{ 
			setLoading(false);
		    dispatch(fetchEnd());
		}
	};

	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to='/termsOfService' />;
	// }

	return (
		<div>
			<Information
				{...props}
				trainingData={trainingData}
				trainingStatus={trainingStatus}
				isTrainingDataLoading={loading}
				isTSAPlayable={isPlayable}
				onUpdateTraining={() => fetchData()}
			/>

			{trainingData && (
				<EmbeddedTabs
					data={{
						tabs: [
							{
								name:
									trainingData && trainingData.labDisplayEnabled
										? 'Labs'
										: 'Lessons',
								content:
									trainingData && trainingData.labDisplayEnabled ? (
										<LabsTab
											trainingData={trainingData}
											selectedSkills={selectedSkills}
											skillSelected={setSelectedSkills}
										/>
									) : (
										<LessonsTab
											{...props}
											trainingName={trainingData.name}
											selectedSkills={selectedSkills}
											skillSelected={setSelectedSkills}
										/>
									),
							},
							{ name: 'Teams', content: <TeamsTab {...props} /> },
							{ name: 'Users', content: <UsersTab {...props} /> },
							{ name: 'Skills', content: <SkillsTab {...props} /> },
						],
					}}
				/>
			)}
		</div>
	);
};
export default compose(withDataProvider, withStyles(tabStyles))(TrainingShow);
