import {DELETE_TEAM} from 'services/customActions';
import React from 'react';
import {getUserInfo} from 'utils/Data';
import {withDataProvider} from 'react-admin';
import IconError from 'components/Icons/IconError';
import Row from 'components/layout/Row';
import DisplayTextValue from 'components/DisplayTextValue';
import {Button, Typography} from '@material-ui/core';
import CustomDialog from 'components/dialogs/CustomDialog';

const DeleteTeamDialog = ({isOpen, setIsOpen, dataProvider, record}) => {
	if (!getUserInfo().isAdmin && !getUserInfo().isSuper) {
		return null;
	}

	const handleClickDelete = e => {
		if (e) e.stopPropagation();
		handleDelete();

		setIsOpen(false);
	};

	const refreshPage = () => {
		window.location = `#/teams`;
		window.location.reload();
	};

	const handleDelete = async () => {
		await dataProvider(
			DELETE_TEAM,
			'teams',
			{
				data: {id: record.id},
			},
			{
				onSuccess: {
					refresh: true,
					unselectAll: true,
				},
				onFailure: {
					unselectAll: true,
					notification: {level: 'warning'},
				},
			}
		);
		refreshPage();
	};

	return (
		<CustomDialog
			isOpen={isOpen}
			small={true}
			setIsOpen={setIsOpen}
			title={
				<Row>
					<IconError style={{marginRight: '8px'}} />
					Delete Team?
				</Row>
			}
			actions={[
				{
					render: () => {
						return (
							<Button
								onClick={handleClickDelete}
								variant='contained'
								color='primary'
								style={{textTransform: 'none'}}
							>
								Delete Team
							</Button>
						);
					},
				},
			]}
			perRow={1}
			details={[
				{
					label: 'Email Address',
					render: () => (
						<DisplayTextValue
							record={{email: 'example@test.test'}}
							disabled
							forceBorder
							source='email'
						/>
					),
				},
			]}
		>
			<Typography>
				<br />
				Users and module will be removed from this team, and this team's
				performance statistics will no longer be tracked in Runway.
				<br />
				<br />
				Do you want to delete this team?
			</Typography>
		</CustomDialog>
	);
};

export default withDataProvider(DeleteTeamDialog);
