import {
	BooleanValue,
	DebugContainer,
	DebugText,
	Divider,
	EmbeddedShow,
	InfoTextDeprecated,
	Label,
	ScreenTitle,
} from 'components';
import {GET_ONE, useDataProvider, useVersion} from 'react-admin';
import React, { useEffect, useState} from 'react';
import {loadObject, saveObject} from 'utils/Util';
import {Redirect} from 'react-router';
import useDevelop from 'hooks/useDevelop';
import useProduction from 'hooks/useProduction';

const Debug = props => {
	const [state, setState] = useState({
		profileInfo: loadObject('profileInfo'),
		runwayOrgConfig: loadObject('runwayOrgConfig'),
	});

	const dataProvider = useDataProvider();

	const version = useVersion();
	useEffect(() => {
		const fetchData = async() => {
			try {
				const {data: profileInfo} = await dataProvider(GET_ONE,'profileInfo');
				setState(state => ({
					...state,
					profileInfo: profileInfo,
				}));
				saveObject('profileInfo', profileInfo);
	
				const {data: runwayOrgConfig} = await dataProvider(GET_ONE,
					'runwayOrgConfig'
				);
				saveObject('runwayOrgConfig', runwayOrgConfig);
				setState(state => ({
					...state,
					runwayOrgConfig: runwayOrgConfig,
				}));
			} catch {}
		};
		fetchData();
	}, [dataProvider,version]);
	

	const {isProduction, loading: prodLoading} = useProduction();

	const {
		isDebug,
		isLicense,
		isDialog,
		isTools,
		loading: devLoading,
	} = useDevelop();

	if (prodLoading || devLoading) {
		return <></>;
	}
	if (isProduction && !isDebug) {
		return <Redirect to='/' />;
	}

	const {profileInfo, runwayOrgConfig} = state;

	const devModes = (
		<div style={{width: '360px', margin: 'auto'}}>
			<EmbeddedShow
				title={'Dev Modes'}
				perRow={2}
				details={[
					{
						label: 'isDebug',
						render: () => (
							<BooleanValue record={{info: isDebug}} source='info' />
						),
					},
					{
						render: () => (
							<InfoTextDeprecated
								record={{
									info: 'Controls if the debug screen is visible.',
								}}
								source='info'
							/>
						),
					},
					{
						label: 'isLicense',
						render: () => (
							<BooleanValue record={{info: isLicense}} source='info' />
						),
					},
					{
						render: () => (
							<InfoTextDeprecated
								record={{
									info: 'Controls if the license feature is visible.',
								}}
								source='info'
							/>
						),
					},
					{
						label: 'isDialog',
						render: () => (
							<BooleanValue record={{info: isDialog}} source='info' />
						),
					},
					{
						render: () => (
							<InfoTextDeprecated
								record={{
									info: 'Controls if the new dialogs are visible.',
								}}
								source='info'
							/>
						),
					},
					{
						label: 'isTools',
						render: () => (
							<BooleanValue record={{info: isTools}} source='info' />
						),
					},
					{
						render: () => (
							<InfoTextDeprecated
								record={{
									info: 'Controls if the new tools are visible.',
								}}
								source='info'
							/>
						),
					},
				]}
				content={
					<div>
						<Divider />
						<div>
							<Label label={'isDevelop:'}>
								<BooleanValue record={{info: true}} source='info' />
								<InfoTextDeprecated
									record={{
										info: 'Indicates that one of the above values are true.',
									}}
									source='info'
								/>
							</Label>{' '}
						</div>
						<br />
						<div>
							These values can be adjusted on the brainCloud dashboard. On the{' '}
							<b>
								Custom Config |{' '}
								<a
									style={{color: 'cyan'}}
									href='https://portal.bc.talespin.io/admin/dashboard?custom=null#/development/global-properties'
								>
									Global Properties
								</a>
							</b>{' '}
							page.
							<br />
							<br />
							<br />
							<InfoTextDeprecated
								record={{
									info: (
										<div>
											Values must be set to <b>'true'</b> to be enabled. Any
											other value,{' '}
											<b>including the Global Property not existing,</b> will
											default to false.
										</div>
									),
								}}
								source='info'
							/>
						</div>
						<br />
					</div>
				}
			/>
		</div>
	);

	const serverData = (
		<DebugContainer large label={'Server Data'}>
			<DebugText
				publicProjectData={JSON.parse(sessionStorage.getItem('project'))}
				profileInfo={profileInfo}
				runwayOrgConfig={runwayOrgConfig}
			/>
		</DebugContainer>
	);

	const environmentVariables = (
		<DebugContainer large label={'Environment Variables'}>
			<DebugText variables={process.env} />
		</DebugContainer>
	);
	/*

	
*/

	const renderScreen = (
		<ScreenTitle
			basePath={props.basePath}
			resource={props.resource}
			title={'Debug'}
		/>
	);

	return (
		<div>
			{renderScreen}
			<div style={{display: 'flex', flexWrap: 'wrap'}}>
				{devModes}
				{environmentVariables}
				{serverData}
			</div>
		</div>
	);
};

export default Debug;
