import React from 'react';

const Actions = ({children}) => {
	if (!children) {
		return <div></div>;
	}

	if (!children.length) {
		return (
			<ActionsContainer>
				<Action action={children} />
			</ActionsContainer>
		);
	}

	var items = children.map((key, index) => (
		<Action key={index} action={children[index]} id={index} />
	));

	return <ActionsContainer>{items}</ActionsContainer>;
};

const ActionsContainer = ({children}) => {
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
			}}
		>
			<span style={{flex: 1}} />
			{children}
			<span style={{width: '16px'}} />
		</div>
	);
};

const Action = ({action, id}) => {
	return (
		<div
			id={id}
			style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}
		>
			{action}
		</div>
	);
};

export default Actions;
