import {
	EmbeddedShow,
	ScreenTitle,
	SkillBarPanel,
	StatsTilePanel,
	TextValue,
	TimeDateField,
} from 'components';
import {fetchEnd, fetchStart, GET_LIST, useVersion, withDataProvider} from 'react-admin';
import React, {useEffect, useState} from 'react';

import compose from 'recompose/compose';
import PanelStat from 'components/Panels/PanelStat';
import LoadingStat from 'components/loading/LoadingStat';
import {useTheme} from '@material-ui/core';
import Collapsible from 'components/Collapsible';
import { useDispatch } from "react-redux";

const LessonDetail = props => {
	const {selectedLabRecord, dataProvider, backLabAction, trainingData} = props;

	const theme = useTheme();

	const [lessonStats, setLessonStats] = useState(null);

	const [lessonSkillStats, setLessonSkillStats] = useState(null);

	const [loading, setLoading] = useState(false);

	const version = useVersion();

	const dispatch = useDispatch();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			await fetchLessonStats();
			await fetchLessonSkillStats();
			setLoading(false);
		}
		fetchData();
	}, [version]);

	const fetchLessonStats = async () => {
		dispatch(fetchStart());
		try {
			const response = await dataProvider(GET_LIST, 'lessonstats', {
				filter: {
					trainingId: trainingData.id,
					labId: selectedLabRecord.id,
				},
				pagination: {},
				sort: {},
			});
	
			setLessonStats(response.data);
		} finally {
          dispatch(fetchEnd());
		}
	};

	const fetchLessonSkillStats = async () => {
		dispatch(fetchStart());
		try {
			const response = await dataProvider(
				GET_LIST,
				'LessonSkillStatsOverallRunway',
				{
					filter: {trainingId: trainingData.id, labId: selectedLabRecord.id},
					pagination: {},
					sort: {},
				}
			);
	
			setLessonSkillStats(response.data);
		} finally {
          dispatch(fetchEnd());
		}
	};

	return (
		<div>
			<ScreenTitle
				title={selectedLabRecord.name}
				refresh={false}
				subTitle={true}
				coloredCrumb={true}
				backActionCrumbs={[backLabAction]}
				titleCrumbs={[trainingData.name]}
			/>

			<div
				style={{
					backgroundColor: theme.palette.background.contained,
					padding: '16px',
					boxShadow: '0px 5px 20px #00000026',
				}}
			>
				<EmbeddedShow
					headerless
					embedded
					title={'Information'}
					details={[
						{
							label: 'Name',
							render: record => (
								<TextValue
									record={selectedLabRecord}
									source='name'
									maxWidth={'300px'}
								/>
							),
						},
						{
							label: 'Created At',
							render: record => (
								<TimeDateField record={selectedLabRecord} source='createdAt' />
							),
						},
					]}
				/>
				<div
					style={{
						display: 'flex',
						width: '100%',
						columnGap: '30px',
						paddingBottom: '8px',
					}}
				>
					{selectedLabRecord.description && (
						<div
							style={{
								width:
									selectedLabRecord?.learningObjectives?.length > 0
										? '50%'
										: '100%',
							}}
						>
							<Collapsible title={'Description'}>
								<p
									style={{paddingLeft: '15px'}}
									dangerouslySetInnerHTML={{
										__html: selectedLabRecord.description,
									}}
								></p>
							</Collapsible>
						</div>
					)}
					{selectedLabRecord?.learningObjectives?.length > 0 && (
						<div
							style={{width: selectedLabRecord.description ? '50%' : '100%'}}
						>
							<Collapsible title={'Learning Objectives'}>
								<div style={{paddingLeft: '15px'}}>
									<p
										dangerouslySetInnerHTML={{
											__html: selectedLabRecord.learningObjectives[0],
										}}
									></p>
								</div>
							</Collapsible>
						</div>
					)}
				</div>
			</div>

			<div style={{margin: '24px 0px'}}>
				{(lessonStats && !loading) ? (
					<StatsTilePanel stats={lessonStats} />
				) : (
					<PanelStat>
						<LoadingStat />
					</PanelStat>
				)}
			</div>

			{(lessonSkillStats && !loading) && <SkillBarPanel data={lessonSkillStats} />}
		</div>
	);
};

export default compose(withDataProvider)(LessonDetail);
