import { DiagramModel } from '@projectstorm/react-diagrams';

import { DataOptions } from 'components/VectorMap/OptionsBlade';
import { GraphNode, VectorMapContext } from '../model';

// import { PreAssessmentNodeModel } from 'components/VectorMap/custom/TrainingMap/PreAssessmentNode/PreAssessmentNodeModel';
// import { LessonNodeModel } from 'components/VectorMap/custom/TrainingMap/LessonNode/LessonNodeModel';
// import { ScenarioNodeModel } from 'components/VectorMap/custom/TrainingMap/ScenarioNode/ScenarioNodeModel';
// import { PostAssessmentNodeModel } from 'components/VectorMap/custom/TrainingMap/PostAssessmentNode/PostAssessmentNodeModel';


/**
 * Apply any filters from the supplied data options.
 * 
 * @param data 
 * @param options 
 */
const applyFilters = (data: Array<any>, options?: DataOptions | null): Array<any> => {
    let results = data.map(item => Object.assign({}, item));

    if (options && options.filter) {
        console.log("Applying Filter", options.filter);

        // TODO: Implement this when we get around to Training Map.
    }

    return results;
}

/**
 * Apply any sort from the supplied data options.
 * 
 * @param nodes 
 * @param options 
 * @param sortByGroupSize 
 */
const applySort = (nodes: Array<GraphNode>, options?: DataOptions | null) => {
    if (options && options.sort) {
        console.log("Applying Sort", options.sort);

        // TODO: Implement this when we get around to Training Map.
    }
}

const convertToGraph = (data: Array<any>, options?: DataOptions | null): Array<GraphNode> => {
    const result: Array<GraphNode> = [];

    // First, we apply any supplied filters.
    const filteredData = applyFilters(data, options);

    // Next, we generate a graph representing the Training Map.
    filteredData.forEach(item => {
        if (item == null) return;

        // TODO: Implement this when we get around to Training Map.
    });

    // Finally, we apply sorting.
    applySort(result, options);

    return result;
}


const generateModel = (context: VectorMapContext, data: Array<any>, onViewRecordDetails?: (record: any) => void, options?: DataOptions | null): DiagramModel => {
    console.log("Generating model for Training Map (Context = " + JSON.stringify(context) + ") ...");

    // Fist we convert the supplied data collection into a node graph.
    const graph = convertToGraph(data, options);

    const nodes = [];
    const links = [];

    // Determine if the resulting graph is empty.
    let isGraphEmpty = true;
    if (graph && graph.length > 0) isGraphEmpty = false;

    // Proceed with laying out the graph nodes.
    if (!isGraphEmpty) {
        // TODO: Implement this when we get around to Training Map.
    }

    const model = new DiagramModel();

    model.addAll(...[...nodes, ...links]);
    model.setLocked(true);

    return model;
}

export default generateModel;
