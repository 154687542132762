import {CircularProgressBar, LinearProgressBar} from 'components';
import React, {} from 'react';
import {useTheme} from '@material-ui/core/styles';
import {useTranslate} from 'react-admin';
import PanelInfo from '../../components/Panels/PanelInfo';
import LoadingInfo from '../../components/loading/LoadingInfo';
import get from 'lodash/get';
import PanelInfoPlaceholder from '../../components/Panels/PanelInfoPlaceholder';
import ResourceRedirect from 'components/ResourceRedirect';

const LifetimeOrgAveragesPanel = props => {
	const translate = useTranslate();

	const theme = useTheme();

	const { lifetimeOrgAverages, error } = props;

	const items = get(lifetimeOrgAverages, 'items');

	const hasItems = items && items.length > 0;

	const title = translate('Lifetime Org Averages');

	if (hasItems) {
		return (
			<PanelInfo title={title}>
				<div
					style={{
						flex: '1 1 auto',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'flex-start',
					}}
				>
					<div style={{marginRight: '50px'}}>
						<CircularProgressBar
							title={lifetimeOrgAverages.label}
							value={Math.round(lifetimeOrgAverages.avg * 100)}
							delta={Math.round(lifetimeOrgAverages.avgDelta * 100)}
						/>
					</div>
					<div
						style={{
							display: 'grid',
							width: '100%',
							gridGap: '10px',
						}}
					>
						{lifetimeOrgAverages.items &&
							Array.isArray(lifetimeOrgAverages.items) &&
							lifetimeOrgAverages.items.map(function(item, i) {
								return (
									<LinearProgressBar
									  key={item.id}
										title={
											<div
												style={{
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													whiteSpace: 'nowrap',
													maxWidth: '175px',
												}}
												title={item.title}
											>
												<ResourceRedirect
													id={item.id}
													value={item.title}
													resource={'trainings'}
												/>
											</div>
										}
										value={Math.round(item.scoreRatio * 100)}
										backgroundColor={theme.palette.background.default}
									/>
								);
							})}
					</div>
				</div>
			</PanelInfo>
		);
	}

	if (items && items.length === 0) {
		return (
			<PanelInfoPlaceholder title={title}>
				Your users haven't finished any training modules yet.
				<br />
				<br />
				This panel will track your organization's performance averaging across
				all training modules and list the top three training modules in terms of
				user performance.
			</PanelInfoPlaceholder>
		);
	}

	if (error) {
		return <PanelInfoPlaceholder title={title}>{error}</PanelInfoPlaceholder>
	}

	return (
		<PanelInfo>
			<LoadingInfo />
		</PanelInfo>
	);
};

export default LifetimeOrgAveragesPanel;
