import React, {Component} from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {DialogTitle, Text} from 'components';
import {withDataProvider} from 'react-admin';
import {refreshView, translate} from 'ra-core';
import {connect} from 'react-redux';
import TSTheme from 'components/Theme';
import LoadingInfo from 'components/loading/LoadingInfo';
import {DropzoneArea} from 'material-ui-dropzone';
import {IMPORT_USER_TRAINING_DATA} from 'services/customActions';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	withStyles,
	createStyles,
	Typography,
	MenuItem,
	createTheme,
	ThemeProvider,
} from '@material-ui/core';

const styles = theme =>
	createStyles({
		dropzone: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
			backgroundColor: theme.palette.background.default,
			borderColor: '#d1dee3',
			'& svg': {
				color: '#d1dee3',
			},
		},
		responseInfo: {
			fontSize: '12px',
			display: 'flex',
			justifyContent: 'center',
		},
	});

class ApplySampleDataButton extends Component {
	state = {
		showDialog: false,
		isProcessing: false,
		showResponseDialogue: false,
		isSuccess: false,
		fileContent: null,
	};

	handleClick = e => {
		this.setState({
			showDialog: true,
		});
	};

	handleClose = e => {
		this.setState({
			showDialog: false,
			isProcessing: false,
			showResponseDialogue: false,
		});
	};

	handleFileChoosen = file => {
		let fileReader = new FileReader();

		if (file[0] !== undefined) {
			fileReader.readAsText(file[0]);
			fileReader.onload = e => {
				this.setState({
					fileContent: JSON.parse(e.target.result),
				});
			};
		}
	};

	handleClickApply = e => {
		const {dataProvider, id, refreshView} = this.props;
		const {fileContent} = this.state;
		this.setState({
			isProcessing: true,
		});

		const payload = {
			userId: id,
			playerData: fileContent.playerData,
			exportedData: fileContent.exportedData,
		};

		dataProvider(
			IMPORT_USER_TRAINING_DATA,
			'importUserTrainingDataRunway',
			payload,
			{
				onSuccess: {
					refresh: true,
					unselectAll: true,
				},
				onFailure: {
					unselectAll: true,
					notification: {level: 'warning'},
				},
			}
		).then(response => {
			this.setState({
				isProcessing: false,
				showResponseDialogue: true,
				isSuccess: response.data.success,
			});
			refreshView();
		});
	};

	handleClickOk = e => {
		const {isSuccess} = this.state;
		this.setState({
			showDialog: isSuccess ? false : true,
			showResponseDialogue: false,
		});
	};

	render() {
		const {translate, classes} = this.props;
		const {
			showDialog,
			isProcessing,
			isSuccess,
			showResponseDialogue,
		} = this.state;

		return (
			<div>
				<MenuItem onClick={this.handleClick}>
					{translate('Apply Sample Data')}
				</MenuItem>
				<Dialog open={showDialog} aria-label={translate('Add Module Data')}>
					<DialogTitle onClose={this.handleClose}>
						<Text
							style={{fontSize: '20px'}}
							label={translate('Add Module Data')}
						/>
					</DialogTitle>

					<DialogContent>
						<div
							style={{
								paddingTop: '10px',
								width: '500px',
								minHeight: '220px',
							}}
						>
							{!isProcessing && !showResponseDialogue && (
								<div>
									<Typography>Add a Module JSON file.</Typography>
									<br />
									<ThemeProvider theme={CustomTheme}>
										<DropzoneArea
											dropzoneClass={classes.dropzone}
											dropzoneText={
												<span
													style={{
														display: 'flex',
														flexDirection: 'column',
														gap: '5px',
													}}
												>
													<Typography component={'span'}>
														Drag and Drop One File Here or Click
													</Typography>
													<span style={{fontSize: '12px'}}>
														<i>JSON Format</i>
													</span>
												</span>
											}
											filesLimit={1}
											maxFileSize={5000000}
											showFileNames
											acceptedFiles={['application/json']}
											onChange={file => this.handleFileChoosen(file)}
										/>
									</ThemeProvider>
									<br />
								</div>
							)}
							{isProcessing && !showResponseDialogue && (
								<div>
									<Typography>Processing Module Data.</Typography>
									<br />
									<LoadingInfo />
									<br />
								</div>
							)}
							{isSuccess && showResponseDialogue && (
								<div>
									<Typography>Data Applied.</Typography>
									<br />
									<br />
									<br />
									<span className={classes.responseInfo}>
										<i>The module data was applied successfully</i>
									</span>
								</div>
							)}
							{!isSuccess && showResponseDialogue && (
								<div>
									<Typography>Data Not Applied.</Typography>
									<br />
									<br />
									<br />
									<span className={classes.responseInfo} style={{color: 'red'}}>
										<i>
											The module data was NOT applied. Please check the data
											file for issues and try again.
										</i>
									</span>
								</div>
							)}
						</div>
					</DialogContent>

					<DialogActions>
						{!showResponseDialogue ? (
							<Button
								color={'primary'}
								variant={'contained'}
								disabled={isProcessing}
								onClick={this.handleClickApply}
							>
								{translate('Apply')}
							</Button>
						) : (
							<Button
								color={'primary'}
								variant={'contained'}
								onClick={this.handleClickOk}
							>
								{translate('OK')}
							</Button>
						)}
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

ApplySampleDataButton.propTypes = {
	dataProvider: PropTypes.func.isRequired,
	variant: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export const CustomTheme = createTheme({
	...TSTheme,
	overrides: {
		MuiDropzoneArea: {
			root: {
				minHeight: 'none !important',
			},
		},
		MuiDropzonePreviewList: {
			image: {
				height: 'none !important',
			},
		},
		MuiGrid: {
			item: {
				display: 'flex',
				minWidth: 'max-content',
				alignItems: 'center',
				columnGap: '5px',
			},
			container: {
				justifyContent: 'center',
			},
		},
		MuiDropzoneSnackbar: {
			infoAlert: {
				color: 'black',
			},
		},
		MuiSnackbar: {
			anchorOriginBottomLeft: {
				left: '0 !important',
				right: '0 !important',
				bottom: '50% !important',
				justifyContent: 'none !important',
			},
		},
	},
});

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withDataProvider,
	translate,
	withStyles(styles)
);

export default enhance(ApplySampleDataButton);
