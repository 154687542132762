import {LinkModel, LinkModelGenerics} from '@projectstorm/react-diagrams-core';
import {BaseModelOptions, DeserializeEvent} from '@projectstorm/react-canvas-core';

export interface CustomLinkModelOptions extends BaseModelOptions {
    width?: number;
    color?: string;
    opacity?: number;
}

export interface CustomLinkModelGenerics extends LinkModelGenerics {
    OPTIONS: CustomLinkModelOptions;
}

export class CustomLinkModel extends LinkModel<CustomLinkModelGenerics> {
    constructor(options: CustomLinkModelOptions = {}) {
        super({
            type: 'custom',
            width: options.width || 3,
            color: options.color || 'black',
            opacity: options.opacity || 1.0,
            ...options
        });
    }

    getSVGPath(): string {
        if (this.points.length === 2) {
            const width = this.options.width;
            const offset = width * 2;

            const sourcePort = this.getSourcePort();
            const targetPort = this.getTargetPort();

            let path = "M " + sourcePort.getX() + " " + sourcePort.getY();

            let midPointX = null;
            let midPointY = null;
            let curveDirection = null;

            switch (sourcePort.getName()) {
                case 'top-start':
                case 'top-middle':
                case 'top-end':
                    midPointX = sourcePort.getX();
                    midPointY = targetPort.getY();
                    curveDirection = sourcePort.getX() < targetPort.getX() ? 'right' : sourcePort.getX() > targetPort.getX() ? 'left' : null;

                    path += " L " + midPointX + "," + (midPointY + offset);

                    if (curveDirection === 'left') path += " S " + midPointX + "," + midPointY + " " + (midPointX - offset) + "," + midPointY;
                    if (curveDirection === 'right') path += " S " + midPointX + "," + midPointY + " " + (midPointX + offset) + "," + midPointY;

                    path += " L " + targetPort.getX() + "," + targetPort.getY();

                    break;
                case 'bottom-start':
                case 'bottom-middle':
                case 'bottom-end':
                    midPointX = sourcePort.getX();
                    midPointY = targetPort.getY();
                    curveDirection = sourcePort.getX() < targetPort.getX() ? 'left' : sourcePort.getX() > targetPort.getX() ? 'right' : null;

                    path += " L " + midPointX + "," + (midPointY - offset);

                    if (curveDirection === 'left') path += " S " + midPointX + "," + midPointY + " " + (midPointX + offset) + "," + midPointY;
                    if (curveDirection === 'right') path += " S " + midPointX + "," + midPointY + " " + (midPointX - offset) + "," + midPointY;

                    path += " L " + targetPort.getX() + "," + targetPort.getY();

                    break;
                case 'left-start':
                case 'left-middle':
                case 'left-end':
                    midPointX = targetPort.getX();
                    midPointY = sourcePort.getY();
                    curveDirection = sourcePort.getY() < targetPort.getY() ? 'down' : sourcePort.getY() > targetPort.getY() ? 'up' : null;

                    path += " L " + (midPointX + offset) + "," + midPointY;

                    if (curveDirection === 'up') path += " S " + midPointX + "," + midPointY + " " + midPointX + "," + (midPointY + offset);
                    if (curveDirection === 'down') path += " S " + midPointX + "," + midPointY + " " + midPointX + "," + (midPointY - offset);

                    path += " L " + targetPort.getX() + "," + targetPort.getY();

                    break;
                case 'right-start':
                case 'right-middle':
                case 'right-end':
                    midPointX = targetPort.getX();
                    midPointY = sourcePort.getY();
                    curveDirection = sourcePort.getY() < targetPort.getY() ? 'down' : sourcePort.getY() > targetPort.getY() ? 'up' : null;

                    path += " L " + (midPointX - offset) + "," + midPointY;

                    if (curveDirection === 'up') path += " S " + midPointX + "," + midPointY + " " + midPointX + "," + (midPointY - offset);
                    if (curveDirection === 'down') path += " S " + midPointX + "," + midPointY + " " + midPointX + "," + (midPointY + offset);

                    path += " L " + targetPort.getX() + "," + targetPort.getY();

                    break;
                default:
                    path += " L " + targetPort.getX() + "," + targetPort.getY();
            }

            return path;
        } else {
            return null;
        }
    }

    serialize() {
        return {
            ...super.serialize(),
            width: this.options.width,
            color: this.options.color,
            opacity: this.options.opacity
        };
    }

    deserialize(event: DeserializeEvent<this>) {
        super.deserialize(event);
        this.options.color = event.data.color;
        this.options.width = event.data.width;
        this.options.opacity = event.data.opacity;
    }

    setWidth(width: number) {
        this.options.width = width;
    }

    setColor(color: string) {
        this.options.color = color;
    }

    setOpacity(opacity: number) {
        this.options.opacity = opacity;
    }
}
