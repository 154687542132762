import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	MuiThemeProvider,
	Typography,
	withStyles,
} from '@material-ui/core';
import React, {useState} from 'react';
import {DialogTitle, EmbeddedList} from 'components';
import {useTranslate} from 'react-admin';
import DialogListTheme from 'components/Theme';

const styles = theme =>
	createStyles({
		dialogTitle: {
			fontSize: '20px',
			fontFamily: 'Archivo Bold',
		},
		dialogContent: {
			display: 'flex',
		},
		header: {
			display: 'flex',
			flexDirection: 'column',
			margin: '12px 0px 12px 0px',
			'& span:first-of-type': {
				backgroundColor: theme.palette.background.default,
				padding: '5px',
				display: 'flex',
				flexDirection: 'row',
				'& p:first-of-type': {
					width: '50%',
				},
			},
			'& span:last-of-type': {
				padding: '5px',
				display: 'flex',
				flexDirection: 'row',
				'& p:first-of-type': {
					width: '50%',
				},
			},
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
	});

const EditSkillsDialog = props => {
	const {
		appKeyName,
		onClose,
		classes,
		onUpdate,
		modifiedSkills,
		trainingName,
		trainingId,
		numberOfAvailableSkills,
	} = props;

	const translate = useTranslate();

	const [selectedSkills, setSelectedSkills] = useState(modifiedSkills);

	const skillSelectionToggled = record => {
		let namedSkills = [...selectedSkills];

		const targetSkill = namedSkills.find(item => item.name === record.name);

		if (!targetSkill) {
			namedSkills.push(record);
		} else {
			const idx = namedSkills.indexOf(targetSkill);
			if (idx >= 0) {
				namedSkills.splice(idx, 1);
			}
		}

		setSelectedSkills(namedSkills);
	};

	const handleUpdate = () => {
		onUpdate(selectedSkills);
	};

	const handleClearAll = () => {
		setSelectedSkills([]);
	};

	const sortCompareFn = (a, b) => {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	};

	return (
		<div>
			<MuiThemeProvider theme={DialogListTheme}>
				<Dialog
					id={'skill-filter'}
					maxWidth='lg'
					open={true}
					onClose={() => onClose()}
				>
					<DialogTitle onClose={() => onClose()}>
						<Typography className={classes.dialogTitle}>
							Edit Featured Skills
						</Typography>
					</DialogTitle>
					<DialogContent>
						<div className={classes.header}>
							<span>
								<Typography>Module Name</Typography>
								<Typography>Skills</Typography>
							</span>

							<span>
								<Typography>{trainingName}</Typography>
								<Typography>{numberOfAvailableSkills}</Typography>
							</span>
						</div>

						<hr className={classes.divider} />

						<div className={classes.dialogContent}>
							<div style={{width: '60%'}}>
								<EmbeddedList
									embedded={true}
									title={null}
									resource='listNamedSkills'
									columns={[
										{
											id: 'displayName',
											name: translate('Name'),
											sortable: true,
										},
										{
											id: 'type',
											name: 'Type',
										},
									]}
									sort={{field: 'displayName', order: 'ASC'}}
									dynamicFilters={[
										{
											id: 'displayName',
											name: translate('Search Skills'),
											type: 'safe_regex',
										},
										{
											id: 'type',
											name: translate('Type'),
											choices: [
												{value: '*', label: translate('All Types')},
												{value: 'hard', label: translate('Hard')},
												{value: 'soft', label: translate('Soft')},
											],
										},
									]}
									staticFilters={[
										{
											id: trainingId ? 'trainingId' : 'appKeyName',
											value: trainingId ? trainingId : appKeyName,
										},
									]}
									defaultPageSize={100}
									bulkActions={null}
									actions={null}
									rowClicked={null}
									selectedIds={selectedSkills.map(skill => skill.name)}
									selectionToggled={record => skillSelectionToggled(record)}
									maxHeight={'400px'}
									isPageChooser={false}
								/>
							</div>
							<div
								style={{width: '40%', paddingLeft: '10px', paddingTop: '64px'}}
							>
								<EmbeddedList
									embedded={true}
									resource={selectedSkills.sort(sortCompareFn)}
									columns={[
										{
											id: 'displayName',
											name: (
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														flex: '1 1 auto',
														alignItems: 'center',
														paddingLeft: '12px',
													}}
												>
													<span>
														Skills to Feature ({selectedSkills.length})
													</span>

													<Button
														color={'primary'}
														variant={'outlined'}
														style={{height: '24px'}}
														onClick={() => handleClearAll()}
													>
														Clear All
													</Button>
												</div>
											),
										},
									]}
									selectionToggled={record => skillSelectionToggled(record)}
									splitSelection={true}
									maxHeight={'400px'}
								/>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => handleUpdate()}
							variant={'contained'}
							color={'primary'}
						>
							Update
						</Button>
					</DialogActions>
				</Dialog>
			</MuiThemeProvider>
		</div>
	);
};

export default withStyles(styles)(EditSkillsDialog);
