import {
	BooleanValue,
	Button,
	CircularProgressBar,
	DebugContainer,
	DebugText,
	EmailValue,
	EmbeddedShow,
	EmbeddedTabs,
	Label,
	LabeledTextField,
	LinearProgressBar,
	NumberValue,
	ScreenTitle,
	SkillBar,
	SkillBarPanel,
	SkillMatrix,
	SkillPoint,
	SkillPointPanel,
	StatusChip,
	TextInput,
	TextValue,
	TimeDateField,
	TimeSinceField,
	TimeSpanField,
} from 'components';
import {useTranslate} from 'react-admin';
import React, {useState} from 'react';
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';

import AppVersion from 'components/AppVersion';
import DisplayTextValue from '../../components/DisplayTextValue';
import FakeSelectInput from '../../components/FakeSelectInput';
import Grid from '../../components/layout/Grid';
import {Redirect} from 'react-router';
import SelectInput from '../../components/SelectInput';
import tsLogo from 'assets/ts-logo.png';
import useDevelop from 'hooks/useDevelop';
import useProduction from 'hooks/useProduction';
import LoadingList from '../../components/loading/LoadingList';
import PanelInfo from '../../components/Panels/PanelInfo';
import PanelStat from '../../components/Panels/PanelStat';
import LoadingStat from '../../components/loading/LoadingStat';
import LoadingInfo from '../../components/loading/LoadingInfo';
import CustomDialog from '../../components/dialogs/CustomDialog';
import IconError from '../../components/Icons/IconError';
import Row from '../../components/layout/Row';
import DisabledButton from '../../components/Button/DisabledButton';
import LoadingButton from '../../components/Button/LoadingButton';
import Box from '../../components/layout/Box';
import PanelInfoPlaceholder from '../../components/Panels/PanelInfoPlaceholder';
import PanelInfoPlaceholderB from '../../components/Panels/PanelInfoPlaceholderB';
import NavigatingDialog from '../../components/dialogs/NavigatingDialog';
import Divider from '../../components/Divider';

const myStyles = theme => ({
	importantText: {
		color: theme.palette.color.important,
	},
	linkButton: {
		backgroundColor: 'transparent',
		border: 'none',
		cursor: 'pointer',
		textDecoration: 'underline',
		display: 'inline',
		margin: 0,
		padding: 0,
		'&:hover': {
			textDecoration: 'none',
		},
	},
});
const Debug = props => {
	const {isProduction, loading: prodLoading} = useProduction();

	const {isDebug, loading: devLoading} = useDevelop();

	if (prodLoading || devLoading) {
		return <div />;
	}
	if (isProduction && !isDebug) {
		return <Redirect to='/' />;
	}

	return (
		<div>
			<ShowRenderScreen {...props} />

			<Grid gridGap={'16px 16px'} padding={'16px'} perRow={1}>
				<ShowPanels />

				<ShowLoading {...props} />

				<Grid gridGap={'16px 16px'} perRow={4}>
					<ShowSelectInput />
					<ShowFakeSelectInput />
					<ShowTimeDateField />
					<ShowTimeSinceField />
					<ShowTimeSpanField />
					<ShowButton />
					<ShowDisabledButton />
					<ShowLoadingButton />
					<ShowDialog />
					<ShowAppVersion />
					<ShowCircularProgressBar />
					<ShowDebugText />
					<ShowTextValue />
					<ShowTextInput />
					<ShowNumberValue />
					<ShowBooleanValue />
					<ShowStatusChip />
					<ShowLabeledTextField {...props} />
				</Grid>

				<Grid gridGap={'16px 16px'} perRow={2}>
					<ShowLinearProgressBar />
					<ShowScreenTitle />
					<ShowSkillPoints />
				</Grid>

				<ShowSkillMatrix />
				<ShowSkillBar />
				<ShowEmbeddedTabs />
				<ShowEmbeddedShow />
			</Grid>
		</div>
	);
};

const ShowPanels = props => {
	return (
		<DebugContainer
			large
			label={
				'PanelInfo.js, PanelInfoPlaceholder.js, PanelInfoPlaceholderB.js, PlanelStat.js'
			}
		>
			<Grid perRow={2}>
				<PanelInfo title={'Panel Info Title'}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non
					ipsum purus. Nulla eget tellus nec tortor pretium scelerisque ut ac
					libero. In hac habitasse platea dictumst. Sed posuere mauris non est
					venenatis semper. Vestibulum porta rutrum ullamcorper. Integer
					pulvinar erat sit amet leo ultrices, ac tristique sapien laoreet.
				</PanelInfo>

				<PanelInfoPlaceholder title={'Panel Info Title'}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non
					ipsum purus. Nulla eget tellus nec tortor pretium scelerisque ut ac
					libero. In hac habitasse platea dictumst. Sed posuere mauris non est
					venenatis semper. Vestibulum porta rutrum ullamcorper. Integer
					pulvinar erat sit amet leo ultrices, ac tristique sapien laoreet.
				</PanelInfoPlaceholder>

				<PanelInfoPlaceholderB title={'Panel Info Title'}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non
					ipsum purus. Nulla eget tellus nec tortor pretium scelerisque ut ac
					libero. In hac habitasse platea dictumst.
				</PanelInfoPlaceholderB>

				<PanelStat>
					<Box>Stat Info</Box>
				</PanelStat>
			</Grid>
		</DebugContainer>
	);
};

const ShowRenderScreen = props => {
	return (
		<ScreenTitle
			basePath={props.basePath}
			resource={props.resource}
			title={'Debug'}
		/>
	);
};

const ShowLoading = props => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<DebugContainer large label={'Loading'}>
			<CustomDialog
				isOpen={isOpen}
				small={true}
				setIsOpen={setIsOpen}
				title={
					<Row>
						<IconError style={{marginRight: '8px'}} />
						Delete Team?
					</Row>
				}
				actions={[
					{
						render: handleClose => {
							return (
								<Button
									small
									variant={'error'}
									// onClick={handleClose}
									onClick={() => {
										alert('Primary');
									}}
								>
									Delete Team
								</Button>
							);
						},
					},
				]}
			>
				<Grid perRow={1}>
					<div>
						Users and module will be removed from this team, and this team's
						performance statistics will no longer be tracked in Runway.
					</div>
					<div>Do you want to delete this team?</div>
				</Grid>
			</CustomDialog>
			<Grid gridGap={'16px 16px'} padding={'16px'} perRow={1}>
				<PanelStat title={'Loading'}>
					<LoadingStat />
				</PanelStat>
				<PanelInfo title={'Loading'}>
					<LoadingInfo />
				</PanelInfo>
				<EmbeddedShow title={'Loading'}>
					<LoadingList size={'large'} />
				</EmbeddedShow>
			</Grid>
		</DebugContainer>
	);
};

const ShowEmbeddedTabs = props => {
	return (
		<DebugContainer large label={'EmbeddedTabs.jsx'}>
			<EmbeddedTabs
				data={{
					tabs: [
						{
							name: 'Teams',
							content: (
								<EmbeddedShow
									title={'Team Information'}
									content={
										<Label label='Team Time'>
											<TimeDateField
												record={{createdAt: 123123123}}
												source='createdAt'
											/>
										</Label>
									}
								/>
							),
						},
						{
							name: 'Module',
							content: (
								<EmbeddedShow
									title={'Module Information'}
									content={
										<Label label='Module Time'>
											<TimeDateField
												record={{createdAt: 12312233123}}
												source='createdAt'
											/>
										</Label>
									}
								/>
							),
						},
						{
							name: 'Stats',
							content: (
								<EmbeddedShow
									title={'Stats Information'}
									content={
										<Label label='Stats Time'>
											<TimeDateField
												record={{createdAt: 123123}}
												source='createdAt'
											/>
										</Label>
									}
								/>
							),
						},
					],
				}}
			/>
		</DebugContainer>
	);
};

const ShowEmbeddedShow = props => {
	const record = {
		validProjects: ['30026'],
		id: '026a32da-e788-44a8-8cd6-2f28f8e1ad7a',
		name: 'AA',
		ownerId: '1980d70b-ae57-4b3c-bde0-8b5bcf99ea94',
		training: 1,
		createdAt: 1575646994455,
		updatedAt: 1575646994455,
		users: 77,
		completionTotal: 0,
		scoreTotal: 2,
		taskTotal: 0,
		lessonTotal: 0,
		completionRate: 0.012987012987012988,
		avgScore: 0.008658008658008658,
	};

	const managerRecord = {
		id: '1980d70b-ae57-4b3c-bde0-8b5bcf99ea94',
		name: '1 null',
		contactEmail: 'test9@mail.com',
		firstName: '1',
		lastName: null,
		status: 'enabled',
		role: 'admin',
		teams: 1,
		note: '',
		trainings: 1,
		lessons: 1,
		userId: 'test9@mail.com',
		createdAt: 1579745546012,
		tester: false,
	};

	return (
		<DebugContainer large label={'EmbeddedShow.jsx'}>
			<EmbeddedShow
				title={'Information'}
				actions={[
					{
						label: 'Name',
						onClick: () => {
							alert('Name');
						},
					},
					{
						label: 'Email',
						onClick: () => {
							alert('Email');
						},
					},
				]}
				details={[
					{
						label: 'Name',
						render: () => <TextValue record={record} source='name' />,
					},
					{
						label: 'Manager',
						render: () => (
							<EmailValue record={managerRecord} source='contactEmail' />
						),
					},
					{
						label: 'Created',
						render: () => <TimeDateField record={record} source='createdAt' />,
					},
					{
						label: 'Modified',
						render: () => <TimeDateField record={record} source='updatedAt' />,
					},
				]}
			/>
			<br />

			<EmbeddedShow
				title={'Plan'}
				details={[
					{
						label: 'Name',
						render: record => (
							<TimeDateField
								record={{createdAt: 123123123}}
								source='createdAt'
							/>
						),
					},
				]}
				content={
					<Label label='Talespin Facilitator'>
						<TimeDateField record={{createdAt: 123123123}} source='createdAt' />
					</Label>
				}
			/>

			<br />
			<EmbeddedShow
				title={'Plan'}
				perRow={3}
				embedded={true}
				details={[
					{
						label: 'Name',
						render: record => (
							<TimeDateField
								record={{createdAt: 123123123}}
								source='createdAt'
							/>
						),
					},
					{
						label: 'Name',
						render: record => (
							<TimeDateField
								record={{createdAt: 123123123}}
								source='createdAt'
							/>
						),
					},
					{
						label: 'Name',
						render: record => (
							<TimeDateField
								record={{createdAt: 123123123}}
								source='createdAt'
							/>
						),
					},
					{
						label: 'Name',
						render: record => (
							<TimeDateField
								record={{createdAt: 123123123}}
								source='createdAt'
							/>
						),
					},
					{
						label: 'Name',
						render: record => (
							<TimeDateField
								record={{createdAt: 123123123}}
								source='createdAt'
							/>
						),
					},
					{
						label: 'Name',
						render: record => (
							<TimeDateField
								record={{createdAt: 123123123}}
								source='createdAt'
							/>
						),
					},
				]}
				content={
					<Label label='Talespin Facilitator'>
						<TimeDateField record={{createdAt: 123123123}} source='createdAt' />
					</Label>
				}
			/>
		</DebugContainer>
	);
};

const ShowButton = props => {
	return (
		<DebugContainer label={'Button.js'}>
			<Button
				width={'100%'}
				onClick={() => {
					alert('Primary');
				}}
			>
				Primary
			</Button>
			<div style={{width: '10px', height: '10px'}} />
			<Button
				width={'100%'}
				onClick={() => {
					alert('Secondary');
				}}
				variant='outlined'
			>
				Secondary
			</Button>
			<div style={{width: '10px', height: '10px'}} />
			<Button
				small
				onClick={() => {
					alert('Primary');
				}}
			>
				Primary
			</Button>
			<div style={{width: '10px', height: '10px'}} />
			<Button
				small
				onClick={() => {
					alert('Secondary');
				}}
				variant='outlined'
			>
				Secondary
			</Button>
		</DebugContainer>
	);
};

const ShowDisabledButton = props => {
	return (
		<DebugContainer label={'DisabledButton.js'}>
			<DisabledButton
				width={'100%'}
				onClick={() => {
					alert('Primary');
				}}
			>
				Primary
			</DisabledButton>
			<div style={{width: '10px', height: '10px'}} />
			<DisabledButton
				width={'100%'}
				onClick={() => {
					alert('Secondary');
				}}
				variant='outlined'
			>
				Secondary
			</DisabledButton>
			<div style={{width: '10px', height: '10px'}} />
			<DisabledButton
				small
				onClick={() => {
					alert('Primary');
				}}
			>
				Primary
			</DisabledButton>
			<div style={{width: '10px', height: '10px'}} />
			<DisabledButton
				small
				onClick={() => {
					alert('Secondary');
				}}
				variant='outlined'
			>
				Secondary
			</DisabledButton>
		</DebugContainer>
	);
};

const ShowLoadingButton = props => {
	return (
		<DebugContainer label={'LoadingButton.js'}>
			<LoadingButton
				onClick={() => {
					alert('Primary');
				}}
			>
				Primary
			</LoadingButton>
			<div style={{width: '10px', height: '10px'}} />
			<LoadingButton
				onClick={() => {
					alert('Secondary');
				}}
				variant='outlined'
			>
				Secondary
			</LoadingButton>
			<div style={{width: '10px', height: '10px'}} />
			<LoadingButton
				small
				onClick={() => {
					alert('Primary');
				}}
			>
				Primary
			</LoadingButton>
			<div style={{width: '10px', height: '10px'}} />
			<LoadingButton
				small
				onClick={() => {
					alert('Secondary');
				}}
				variant='outlined'
			>
				Secondary
			</LoadingButton>
		</DebugContainer>
	);
};

const ShowDialog = props => {
	const [isOpen, setIsOpen] = useState(false);
	const [isNavOpen, setIsNavOpen] = useState(false);

	return (
		<DebugContainer label={'CustomDialog.jsx, NavigatingDialog.jsx'}>
			<CustomDialog
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={'Send Welcome Email'}
				actions={[
					{
						render: handleClose => {
							return (
								<Button small variant={'outlined'} onClick={handleClose}>
									Cancel
								</Button>
							);
						},
					},
					{
						render: () => {
							return (
								<Button
									small
									onClick={() => {
										alert('Primary');
									}}
								>
									Confirm
								</Button>
							);
						},
					},
				]}
				perRow={1}
				details={[
					{
						label: 'Email Address',
						render: () => (
							<DisplayTextValue
								record={{email: 'example@test.test'}}
								disabled
								forceBorder
								source='email'
							/>
						),
					},
				]}
			>
				<Grid perRow={1}>
					<div>
						<Label label={'Test'} />
						<DisplayTextValue
							record={{email: 'example@test.test'}}
							disabled
							forceBorder
							source='email'
						/>
					</div>
				</Grid>
			</CustomDialog>

			<Button
				width={'100%'}
				onClick={() => {
					setIsOpen(true);
				}}
			>
				Open Custom Dialog
			</Button>

			<Divider />

			<NavigatingDialog
				title={'Navigating Away'}
				navigateTo={'/forgot-password'}
				showOnceKey={'debugExampleasd'}
				actions={[
					{
						render: handleClose => {
							return (
								<Button small onClick={handleClose}>
									Continue
								</Button>
							);
						},
					},
				]}
				isOpen={isNavOpen}
				setIsOpen={setIsNavOpen}
			>
				This will take you another section of Runway.
				<br />
				<br />
				Do you want to navigate away from this section?
			</NavigatingDialog>

			<Button
				width={'100%'}
				onClick={() => {
					setIsNavOpen(true);
				}}
			>
				Open Navigating Dialog
			</Button>
		</DebugContainer>
	);
};

const ShowAppVersion = props => {
	return (
		<DebugContainer label={'AppVersion.jsx'}>
			<AppVersion icon={tsLogo} />
		</DebugContainer>
	);
};

const ShowCircularProgressBar = props => {
	return (
		<DebugContainer label={'CircularProgressBar.jsx'}>
			<CircularProgressBar title={'Pie'} value={40} delta={60} />
		</DebugContainer>
	);
};

const ShowDebugText = props => {
	return (
		<DebugContainer label={'DebugText.jsx'}>
			<DebugText label={'Show'} />
		</DebugContainer>
	);
};

const ShowSelectInput = props => {
	const translate = useTranslate();

	return (
		<DebugContainer label={'SelectInput.jsx'}>
			<SelectInput
				choices={[
					{id: 'en', name: translate('English')},
					{id: 'fr', name: translate('French')},
				]}
				selected={'fr'}
				onChange={event => {}}
				width='100%'
			/>
		</DebugContainer>
	);
};

const ShowFakeSelectInput = props => {
	return (
		<DebugContainer label={'SelectInput.jsx'}>
			<FakeSelectInput
				value={'Fake Select'}
				onClick={event => {
					alert('Not real');
				}}
				width='100%'
			/>
		</DebugContainer>
	);
};

const ShowTimeDateField = props => {
	return (
		<DebugContainer label={'TimeDateField.jsx'}>
			<TimeDateField record={{userAdded: 1579296665000}} source='userAdded' />
			<br />
			<TimeDateField
				record={{userAdded: 1579296665000000}}
				source='userAdded'
			/>
		</DebugContainer>
	);
};

const ShowTimeSinceField = props => {
	return (
		<DebugContainer label={'TimeSinceField.jsx'}>
			<TimeSinceField record={{userAdded: 1579296665000}} source='userAdded' />
			<br />
			<TimeSinceField
				record={{userAdded: 1579296665000000}}
				source='userAdded'
			/>
		</DebugContainer>
	);
};

const ShowTimeSpanField = props => {
	return (
		<DebugContainer label={'TimeSpanField.jsx'}>
			<TimeSpanField record={{userAdded: 1579}} source='userAdded' />
			<br />

			<TimeSpanField
				record={{userAdded: 1579296665000000}}
				source='userAdded'
			/>
		</DebugContainer>
	);
};

const ShowTextValue = props => {
	return (
		<DebugContainer label={'TextValue.jsx'}>
			<TextValue record={{userAdded: 1579}} source='userAdded' />
		</DebugContainer>
	);
};

const ShowTextInput = props => {
	return (
		<DebugContainer label={'TextInput.jsx'}>
			<handleChange>
				<TextInput />
			</handleChange>
		</DebugContainer>
	);
};

const ShowNumberValue = props => (
	<DebugContainer label={'NumberValue.jsx'}>
		<NumberValue record={{userAdded: 1579}} source='userAdded' />
	</DebugContainer>
);

const ShowBooleanValue = props => (
	<DebugContainer label={'BooleanValue.jsx'}>
		<BooleanValue record={{isTester: true}} source='isTester' />
	</DebugContainer>
);

const ShowLinearProgressBar = props => (
	<DebugContainer label={'LinearProgressBar.jsx'}>
		<LinearProgressBar title={'Progress Bar'} value={80} />
		<LinearProgressBar value={40} hideValue />
	</DebugContainer>
);

const ShowSkillBar = props => (
	<DebugContainer large label={'SkillBarPanel.jsx, SkillBar.jsx'}>
		<SkillBarPanel
			data={[
				{
					id: 'OverallProgress',
					displayName: 'Overall Progress',
					value: 0.6666666666666666,
					subValue: '2/3',
					subLabel: 'skill points earned',
					passedPercent: 0.5,
					acedPercent: 0.8,
				},
				{
					id: 'AnchoringBasic',
					displayName: 'Anchoring Basic',
					value: 1,
					subValue: '1/1',
					subLabel: 'skill points earned',
				},
				{
					id: 'FramingBasic',
					displayName: 'Framing Basic',
					value: 1,
					subValue: '1/1',
					subLabel: 'skill points earned',
				},
				{
					id: 'FramingBasic',
					displayName: 'Framing Basic',
					value: 1,
					subValue: '1/1',
					subLabel: 'skill points earned',
				},
			]}
		/>

		<SkillBar
			data={{
				id: 'OverallProgress',
				displayName: 'Overall Progress',
				value: 0.6666666666666666,
				subValue: '2/3',
				subLabel: 'skill points earned',
				passedPercent: 0.5,
				acedPercent: 0.8,
			}}
			large
		/>

		<SkillBar
			data={{
				id: 'FramingBasic',
				displayName: 'Framing Basic',
				value: 1,
				subValue: '1/1',
				subLabel: 'skill points earned',
			}}
		/>
	</DebugContainer>
);

const ShowSkillPoints = props => {
	return (
		<DebugContainer large label={'SkillPointPanel.jsx, SkillPoint.jsx'}>
			<SkillPointPanel
				data={[
					{
						id: 'AnchoringBasic',
						displayName: 'AnchoringBasic',
						value: 1,
						valueLabel: 'skill point',
						subLabel: 'available',
					},
					{
						id: 'FramingBasic',
						displayName: 'FramingBasic',
						value: 1,
						valueLabel: 'skill point',
						subLabel: 'available',
					},
				]}
			/>
			<SkillPoint
				data={{
					id: 'AnchoringBasic',
					displayName: 'AnchoringBasic',
					value: 1,
					valueLabel: 'skill point',
					subLabel: 'available',
				}}
			/>
		</DebugContainer>
	);
};

const ShowScreenTitle = props => (
	<DebugContainer large label={'ScreenTitle.jsx'}>
		<ScreenTitle title={'Title'} refresh={true} />
		<ScreenTitle title={'Title'} refresh={true} list={true} />

		<ScreenTitle
			title={'Title'}
			backAction={() => {
				alert('test');
			}}
			refresh={false}
			embedded={true}
		/>

		<ScreenTitle
			title={'Title'}
			titleCrumbs={['Crumb']}
			backActionCrumbs={[
				() => {
					alert('test');
				},
			]}
			refresh={false}
			embedded={true}
		/>

		<ScreenTitle
			title={'Title'}
			titleCrumbs={['Crumb', 'Crumb 2']}
			backActionCrumbs={[
				() => {
					alert('test');
				},
				() => {
					alert('test');
				},
			]}
			refresh={false}
			embedded={true}
		/>
	</DebugContainer>
);

const ShowSkillMatrix = props => (
	<DebugContainer large label={'SkillMatrix.jsx'}>
		<SkillMatrix
			data={{
				Anchoring: {
					id: 'Anchoring',
					displayName: 'Anchoring',
					beginner: {
						userValue: 30,
						fakeDenominator: 50,
						value: 1,
						valueLabel: '30/50',
						subLabel: 'skill points earned',
					},
					intermediate: {
						userValue: 80,
						fakeDenominator: 80,
						value: 1,
						valueLabel: '80/80',
						subLabel: 'skill points earned',
					},
					advanced: {
						userValue: 140,
						fakeDenominator: 140,
						value: 1,
						valueLabel: '140/140',
						subLabel: 'skill points earned',
					},
				},
				Framing: {
					id: 'Framing',
					displayName: 'Framing',
					beginner: {
						userValue: 0,
						fakeDenominator: 50,
						value: 0,
						valueLabel: '0/50',
						subLabel: 'skill points earned',
					},
					intermediate: {
						userValue: 0,
						fakeDenominator: 80,
						value: 0,
						valueLabel: '0/80',
						subLabel: 'skill points earned',
					},
					advanced: {
						userValue: 0,
						fakeDenominator: 140,
						value: 0,
						valueLabel: '0/140',
						subLabel: 'skill points earned',
					},
				},
				EmpatheticParaphrase: {
					id: 'EmpatheticParaphrase',
					displayName: 'Empathetic Paraphrase',
					beginner: {
						userValue: 0,
						fakeDenominator: 50,
						value: 0,
						valueLabel: '0/50',
						subLabel: 'skill points earned',
					},
					intermediate: {
						userValue: 0,
						fakeDenominator: 80,
						value: 0,
						valueLabel: '0/80',
						subLabel: 'skill points earned',
					},
					advanced: {
						userValue: 0,
						fakeDenominator: 140,
						value: 0,
						valueLabel: '0/140',
						subLabel: 'skill points earned',
					},
				},
				NaturalParaphrase: {
					id: 'NaturalParaphrase',
					displayName: 'Natural Paraphrase',
					beginner: {
						userValue: 0,
						fakeDenominator: 50,
						value: 0,
						valueLabel: '0/50',
						subLabel: 'skill points earned',
					},
					intermediate: {
						userValue: 0,
						fakeDenominator: 80,
						value: 0,
						valueLabel: '0/80',
						subLabel: 'skill points earned',
					},
					advanced: {
						userValue: 0,
						fakeDenominator: 140,
						value: 0,
						valueLabel: '0/140',
						subLabel: 'skill points earned',
					},
				},
				EvokingQuestion: {
					id: 'EvokingQuestion',
					displayName: 'Evoking Question',
					beginner: {
						userValue: 0,
						fakeDenominator: 50,
						value: 0,
						valueLabel: '0/50',
						subLabel: 'skill points earned',
					},
					intermediate: {
						userValue: 0,
						fakeDenominator: 80,
						value: 0,
						valueLabel: '0/80',
						subLabel: 'skill points earned',
					},
					advanced: {
						userValue: 0,
						fakeDenominator: 140,
						value: 0,
						valueLabel: '0/140',
						subLabel: 'skill points earned',
					},
				},
				ConstructionFraming: {
					id: 'ConstructionFraming',
					displayName: 'Construction Framing',
					beginner: {
						userValue: 0,
						fakeDenominator: 50,
						value: 0,
						valueLabel: '0/50',
						subLabel: 'skill points earned',
					},
					intermediate: {
						userValue: 0,
						fakeDenominator: 80,
						value: 0,
						valueLabel: '0/80',
						subLabel: 'skill points earned',
					},
					advanced: {
						userValue: 0,
						fakeDenominator: 140,
						value: 0,
						valueLabel: '0/140',
						subLabel: 'skill points earned',
					},
				},
				ConstructionRoofing: {
					id: 'ConstructionRoofing',
					displayName: 'Construction Roofing',
					beginner: {
						userValue: 0,
						fakeDenominator: 50,
						value: 0,
						valueLabel: '0/50',
						subLabel: 'skill points earned',
					},
					intermediate: {
						userValue: 0,
						fakeDenominator: 80,
						value: 0,
						valueLabel: '0/80',
						subLabel: 'skill points earned',
					},
					advanced: {
						userValue: 0,
						fakeDenominator: 140,
						value: 0,
						valueLabel: '0/140',
						subLabel: 'skill points earned',
					},
				},
			}}
		/>
	</DebugContainer>
);

const ShowLabeledTextField = props => {
	const {classes} = props;
	const translate = useTranslate();
	return (
		<DebugContainer label={'LabeledTextField.jsx'}>
			<LabeledTextField source='name' altRecord={{name: 'test'}} label='Name' />
			<button
				type='button'
				className={`${classes.importantText} ${classes.linkButton}`}
				onClick={() => alert('button clicked')}
			>
				{translate(`Send Welcome Email`)}
			</button>
		</DebugContainer>
	);
};

const ShowStatusChip = props => (
	<DebugContainer label={'StatusChip.jsx'}>
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: 'auto auto',
				justifyContent: 'spaceBetween',
				gridGap: '10px',
				gridRowGap: '10px',
				justifyItems: 'center',
				alignItems: 'center',
			}}
		>
			<StatusChip record={{userAdded: 'enabled'}} source='userAdded' />
			<StatusChip record={{userAdded: 'disabled'}} source='userAdded' />
			<StatusChip record={{userAdded: 'passed'}} source='userAdded' />
			<StatusChip record={{userAdded: 'aced'}} source='userAdded' />
			<StatusChip record={{userAdded: 'incomplete'}} source='userAdded' />
			<StatusChip record={{userAdded: 'not started'}} source='userAdded' />
		</div>
	</DebugContainer>
);

export default compose(withStyles(myStyles))(Debug);
