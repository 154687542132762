import React, {useState} from 'react';

import {Button, IconButton, Typography} from '@material-ui/core';
import Indicators from './Indicators';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileUpload from './ImageUpload';
import { AddOutlined } from '@material-ui/icons';

const Carousel = props => {
	const {sources, onDelete, onChange} = props;

	const [activeIndex, setactiveIndex] = useState(0);

	const [showImageUploadDialog, setShowImageUploadDialog] = useState(false);

	const handleImageChange = files => {
		if (files) onChange(files);

		setShowImageUploadDialog(false);

		if (files) setactiveIndex(sources.length);
	};

	const handleDelete = index => {
		onDelete(index);
		setactiveIndex(0);
	};

	return (
		<div
			style={{
				width: '50%',
				display: 'flex',
				flexDirection: 'column',
				position: 'relative',
			}}
		>
			<img
				src={sources[activeIndex]}
				style={{maxWidth: '100%', height: 'auto'}}
				alt=''
			></img>
			{onDelete && sources && sources.length > 0 && (
				<IconButton
					onClick={() => handleDelete(activeIndex)}
					style={{
						position: 'absolute',
						right: '5px',
						marginTop: '8px',
						backgroundColor: '#ecf4f6',
					}}
				>
					<DeleteOutlineIcon />
				</IconButton>
			)}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: onChange ? 'space-between' : 'center',
					marginTop: '12px',
				}}
			>
				{onChange && <span></span>}

				<Indicators
					length={sources.length}
					active={activeIndex}
					press={index => setactiveIndex(index)}
				/>

				{onChange && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: '5px',
						}}
					>
						<Typography style={{color: '#85A0AE'}}>
							{sources.length}/5
						</Typography>

						<Button
							color={'primary'}
							variant={'outlined'}
							onClick={() => setShowImageUploadDialog(true)}
							disabled={sources.length === 5}
							startIcon={<AddOutlined style={{ marginBottom: '2px' }}/>}
						>
							Add Images
						</Button>
					</div>
				)}
			</div>

			{showImageUploadDialog && (
				<FileUpload
					name={'dropzone'}
					acceptedFiles={['image/jpeg']}
					onChange={files => handleImageChange(files)}
					maxFileSize={1024000}
					minHeight={1080}
					minWidth={1920}
					placeholder={
						'16:9 Aspect Ratio, Image Size 2560x1440, File Size <800kb'
					}
				/>
			)}
		</div>
	);
};

export default Carousel;
