import React, {CSSProperties} from 'react';
import compose from 'recompose/compose';
import {createStyles, withStyles} from '@material-ui/core/styles';

import { GET_LIST, withDataProvider } from 'react-admin';
import { connect } from 'react-redux';

import { DefaultLabelFactory, DefaultLinkFactory, DefaultNodeFactory, DefaultPortFactory, DiagramEngine, DiagramModel, LinkLayerFactory, NodeLayerFactory, PathFindingLinkFactory } from '@projectstorm/react-diagrams';
import { CanvasWidget, InputType, CanvasEngineOptions, SelectionBoxLayerFactory } from '@projectstorm/react-canvas-core';

import LoadingInfo from 'components/loading/LoadingInfo';

import {CustomPortModel} from './custom/Common/CustomPort/CustomPortModel';

// Custom Diagram State.
import { CustomDiagramState } from './custom/CustomDiagramState';

// Common Factories.
import {LabelNodeFactory} from './custom/Common/LabelNode/LabelNodeFactory';
import { CustomLinkFactory } from './custom/Common/CustomLink/CustomLinkFactory';
import { SimplePortFactory } from './custom/SimplePortFactory';

// Catalog Map Factories.,
import { TrainingNodeFactory } from './custom/CatalogMap/TrainingNode/TrainingNodeFactory';

// Training Map Factories.
// import { StartNodeFactory } from './custom/LessonMap/StartNode/StartNodeFactory';

// Skill Map Factories.,
import { SkillNodeFactory } from './custom/SkillMap/SkillNode/SkillNodeFactory';
import { SkillNodeModel } from './custom/SkillMap/SkillNode/SkillNodeModel';

import { Typography, Button, Dialog } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import OptionsBlade, { DataOptions, DataParams } from './OptionsBlade';

import VectorMapUtils from  './utils';
import { cloneDataOptions } from './utils/options';

import Legend from './Legend';
import { VectorMapContext, VectorMapContextData } from './utils/model';

// Lesson Map Factories.
// import { DecisionNodeFactory } from './custom/DecisionNode/DecisionNodeFactory';
// import { SkillPointNodeFactory } from '../custom/SkillPointNode/SkillPointNodeFactory';
// import { EndNodeFactory } from '../custom/EndNode/EndNodeFactory';

enum VectorMapType {
    CATALOG_MAP = "catalog-map",
    TRAINING_MAP = "training-map",
    LESSON_MAP = "lesson-map",
    SKILL_MAP = "skill-map"
}

interface PROPS {
    style?: CSSProperties | null,
    title?: string | null,
    type: VectorMapType,
    contextData?: VectorMapContextData,
    dataProvider: any,
    onTableViewClicked: () => void,
    onViewRecordDetails: (record: any) => void,
    classes?: any,
    users: any
}

interface STATE {
    engine: DiagramEngine,
    isReady: boolean,
    isFullscreen: boolean,
    defaultOptions: DataOptions | null,
    options: DataOptions | null,
    params: DataParams,
    legendExpanded?: boolean,
    context?: VectorMapContext | null,
    linkedRoles: string[] | null,
    vectorData: any[] | null,
}

const createCustomEngine = (options?: CanvasEngineOptions) => {
    const engine = new DiagramEngine(options);

    // Register default out-of-the-box model factories
    engine.getLayerFactories().registerFactory(new NodeLayerFactory());
    engine.getLayerFactories().registerFactory(new LinkLayerFactory());
    engine.getLayerFactories().registerFactory(new SelectionBoxLayerFactory());

    engine.getLabelFactories().registerFactory(new DefaultLabelFactory());
    engine.getNodeFactories().registerFactory(new DefaultNodeFactory());
    engine.getLinkFactories().registerFactory(new DefaultLinkFactory());
    engine.getLinkFactories().registerFactory(new PathFindingLinkFactory());
    engine.getPortFactories().registerFactory(new DefaultPortFactory());

    // Register custom factories.
    engine.getNodeFactories().registerFactory(new LabelNodeFactory());
    engine.getNodeFactories().registerFactory(new TrainingNodeFactory());
    engine.getNodeFactories().registerFactory(new SkillNodeFactory());

    engine.getLinkFactories().registerFactory(new CustomLinkFactory());
    engine.getPortFactories().registerFactory(new SimplePortFactory('custom', (_config) => new CustomPortModel()));

    // Override the getActionsForEvent function of the ActionEventBus to ignore KEY_UP/KEY_DOWN events.
    // This effectively disables the ability to select multiple nodes when holding the SHIFT key in combination
    // with the mouse click and drag.
    engine.getActionEventBus().getActionsForEvent = (actionEvent) => {
        const { event } = actionEvent;

        if (event.type === 'mousedown') {
            return engine.getActionEventBus().getActionsForType(InputType.MOUSE_DOWN);
        } else if (event.type === 'mouseup') {
            return engine.getActionEventBus().getActionsForType(InputType.MOUSE_UP);
        } else if (event.type === 'mousemove') {
            return engine.getActionEventBus().getActionsForType(InputType.MOUSE_MOVE);
        } else if (event.type === 'wheel') {
            return engine.getActionEventBus().getActionsForType(InputType.MOUSE_WHEEL);
        }

        return [];
    };

    // register the default interaction behaviours
    engine.getStateMachine().pushState(new CustomDiagramState());

    return engine;
}

class VectorMap extends React.Component<PROPS, STATE> {
    private _isMounted = false;
    optionsEl: any = null;

    state = {
        engine: createCustomEngine({
            registerDefaultZoomCanvasAction: false,
            registerDefaultDeleteItemsAction: false
        }),
        isReady: false,
        isFullscreen: false,
        defaultOptions: null,
        options: null,
        params: {
            collapsed: false,
            expandedFilter: false,
            expandedFilterOptions: [],
            expandedGroup: false,
            expandedSort: false,
            expandedHeatmap: false,
            expandedDetail: false,
            expandedDetailOptions: [],
            expandedOther: false
        },
        legendExpanded: false,
        context: {
            context: null,
            subContext: null
        },
        linkedRoles: null,
        vectorData: null
    };

    constructor(props: PROPS) {
        super(props);

        this.optionsEl = React.createRef();
    }

    componentDidMount = async () => {
        this._isMounted = true;

        await this.loadVectorMapData();
        await this.refreshDefaultOptions();
        await this.refreshModel();

        if (this._isMounted) this.setState({ isReady: true });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate = (prevProps: PROPS) => {
        if (this.props.type !== prevProps.type) {
            if (this._isMounted) this.setState({ isReady: false }, async () => {
                await this.loadVectorMapData();
                await this.refreshDefaultOptions();
                await this.refreshModel();

                if (this._isMounted) this.setState({ isReady: true });
            });
        }
    }

    loadVectorMapData = async () => {
        const { type, contextData, dataProvider, users } = this.props;

        // Determine the context based on the supplied contextData.
        const context: VectorMapContext = { context: 'org' };
        if (contextData) {
            // Determine the primary context (org, team or user).
            if (contextData.teamId && contextData.teamId.trim().length > 0) context.context = 'team';
            if (contextData.userId && contextData.userId.trim().length > 0) context.context = 'user';

            // Determine the secondary context (training or lesson if applicable)
            if (contextData.trainingId && contextData.trainingId.trim().length > 0) context.subContext = 'training';
            if (contextData.lessonId && contextData.lessonId.trim().length > 0) context.subContext = 'lesson';
        }

        // If the Vector Map type is 'skill-map' then we need to fetch the "Linked Roles" based on the supplied contextData.
        let linkedRoles: string[] = [];
        if ([VectorMapType.SKILL_MAP].includes(type)) {
            try {
                const linkedRolesResponse = await dataProvider(GET_LIST,
                    'linkedrolesvectormap',
                    {
                        filter: {
                            teamId: contextData ? contextData.teamId : undefined,
                            userId: contextData ? contextData.userId : undefined,
                            trainingId: contextData ? contextData.trainingId : undefined,
                            lessonId: contextData ? contextData.lessonId : undefined,
                        }
                    }
                );

                if (linkedRolesResponse && linkedRolesResponse.data && Array.isArray(linkedRolesResponse.data) && linkedRolesResponse.data.length > 0) {
                    linkedRoles = linkedRolesResponse.data;
                }
            } catch (error) {
                console.log("Falling back to user job role(s)...");

                const userData = contextData && contextData.userId && users && users[contextData.userId] ? Object.assign({}, users[contextData.userId]) : null;

                if (userData) {
                    if (userData.jobRole) {
                        if (Array.isArray(userData.jobRole)) {
                            linkedRoles.push(...userData.jobRole);
                        } else {
                            if ((userData.jobRole as string).includes(',')) {
                                const parts = (userData.jobRole as string).split(',');

                                parts.forEach(part => {
                                    const trimmedPart = part.trim();

                                    if (trimmedPart.length > 0) linkedRoles.push(trimmedPart);
                                });
                            } else {
                                const trimmedRole = (userData.jobRole as string).trim();

                                linkedRoles.push(trimmedRole);
                            }
                        }
                    }
                }
            }
        }

        // Determine the appropriate Cloud-Code script to invoke based on the supplied type property.
        // This is the target script used to fetch the actual vector map data.
        let targetScript = null;
        switch (type) {
            case 'catalog-map':
                targetScript = 'catalogvectormap';
                break;
            case 'training-map':
                targetScript = 'trainingvectormap';
                break;
            case 'lesson-map':
                targetScript = 'lessonvectormap';
                break;
            case 'skill-map':
                targetScript = 'skillvectormap';
                break;
            default:
                // Unknown Vector Map Type.
        }

        // Execute the target Cloud-Code script to retrieve the Vector Map data response.
        let vectorData = null;
        if (targetScript) {
            const vectorDataResponse = await dataProvider(GET_LIST,
                targetScript,
                {
                    filter: {
                        teamId: contextData ? contextData.teamId : undefined,
                        userId: contextData ? contextData.userId : undefined,
                        trainingId: contextData ? contextData.trainingId : undefined,
                        lessonId: contextData ? contextData.lessonId : undefined,
                    }
                }
            );

            if (vectorDataResponse && vectorDataResponse.data && Array.isArray(vectorDataResponse.data) && vectorDataResponse.data.length > 0) {
                vectorData = vectorDataResponse.data;
            }
        }

        // Log some information about the results of loading data for the Vector Map.
        if (type) console.log("Vector Map - Type: " + type.toUpperCase());
        if (context.context) console.log("Vector Map - Context: " + context.context.toUpperCase());
        if (context.subContext) console.log("Vector Map - Sub-Context: " + context.subContext.toUpperCase());
        if (contextData) console.log("Vector Map - Context Data", contextData);
        if (linkedRoles) console.log("Vector Map - Linked Roles", linkedRoles);
        if (vectorData) console.log("Vector Map - Vector Data", vectorData);

        // Update the local component state with the results.
        if (this._isMounted) this.setState({ context, linkedRoles, vectorData });
    }

    handleZoomIn = () => {
        const { engine } = this.state;

        const zoomLevel = engine.getModel().getZoomLevel() + 10;

        engine.getModel().setZoomLevel(zoomLevel);
        engine.repaintCanvas();
    }

    handleZoomOut = () => {
        const { engine } = this.state;

        const zoomLevel = engine.getModel().getZoomLevel() - 10;

        if (zoomLevel > 10) {
            engine.getModel().setZoomLevel(zoomLevel);
            engine.repaintCanvas();
        }
    }

    handleZoomReset = () => {
        const { engine } = this.state;

        engine.getModel().setZoomLevel(80);
        engine.getModel().setOffsetX(0);
        engine.getModel().setOffsetY(0);
        engine.repaintCanvas();
    }

    handleToggleFullscreen = () => {
        const { isFullscreen } = this.state;
        
        if (this._isMounted) this.setState({ isFullscreen: !isFullscreen }, () => {
            const element = document.getElementById('main');

            if (element) element.style.overflow = 'auto';

            document.body.style.overflow = 'auto';
        });
    }

    handleExpandFilterOption = (id: string) => {
        if (this.optionsEl && this.optionsEl.current) {
            this.optionsEl.current.expandFilterOption(id);
        }
    }

    handleOptionsChanged = (options: DataOptions) => {
        const { type } = this.props;
        const context = this.state.context;
        const prevOptions = this.state.options;

        let validatedOptions: DataOptions | null = null;

        switch (type) {
            case 'catalog-map':
                validatedOptions = VectorMapUtils.CatalogMap.optionsChanged(context, prevOptions, options);
                break;
            case 'training-map':
                validatedOptions = VectorMapUtils.TrainingMap.optionsChanged(context, prevOptions, options);
                break;
            case 'lesson-map':
                validatedOptions = VectorMapUtils.LessonMap.optionsChanged(context, prevOptions, options);
                break;
            case 'skill-map':
                validatedOptions = VectorMapUtils.SkillMap.optionsChanged(context, prevOptions, options);
                break;
            default:
                validatedOptions = options;
                break;
        }

        if (this._isMounted) this.setState({ options: validatedOptions }, () => {
            this.refreshModel();
        });
    }

    handleParamsChanged = (params: DataParams) => {
        if (this._isMounted) this.setState({ params });
    }

    handleToggleLegendExpanded = () => {
        const { legendExpanded } = this.state;

        if (this._isMounted) this.setState({ legendExpanded: !legendExpanded });
    }

    refreshDefaultOptions = async () => {
        const { type, contextData } = this.props;
        const { context, linkedRoles, vectorData } = this.state;

        if (!vectorData) return;

        let defaultOptions: DataOptions | null = null;

        switch (type) {
            case 'catalog-map':
                defaultOptions = VectorMapUtils.CatalogMap.generateOptions(context, vectorData);
                break;
            case 'training-map':
                defaultOptions = VectorMapUtils.TrainingMap.generateOptions(context, vectorData);
                break;
            case 'lesson-map':
                defaultOptions = VectorMapUtils.LessonMap.generateOptions(context, vectorData);
                break;
            case 'skill-map':
                defaultOptions = VectorMapUtils.SkillMap.generateOptions(context, vectorData, linkedRoles, contextData);
                break;
            default:
                defaultOptions = { filter: null, group: null, sort: null, heatmap: null, detail: null, other: null };
                break;
        }

        if (this._isMounted) this.setState({ defaultOptions: defaultOptions, options: cloneDataOptions(defaultOptions) }, () => {
            if (type === 'skill-map') {
                this.handleExpandFilterOption('role-info.linked-roles');
            }
        });
    }

    refreshModel = async () => {
        const { type, contextData } = this.props;
        const { engine, options, context, linkedRoles, vectorData } = this.state;

        if (!vectorData) return;

        const currentOffsetX = engine.getModel() ? engine.getModel().getOffsetX() : 0;
        const currentOffsetY = engine.getModel() ? engine.getModel().getOffsetY() : 0;
        const currentZoomLevel = engine.getModel() ? engine.getModel().getZoomLevel() : 80;

        let model: DiagramModel | null = null;
        
        if (vectorData.length > 0) {
            switch (type) {
                case 'catalog-map':
                    model = VectorMapUtils.CatalogMap.generateModel(context, vectorData, this.props.onViewRecordDetails, options);
                    break;
                case 'training-map':
                    model = VectorMapUtils.TrainingMap.generateModel(context, vectorData, this.props.onViewRecordDetails, options);
                    break;
                case 'lesson-map':
                    model = VectorMapUtils.LessonMap.generateModel(context, vectorData, this.props.onViewRecordDetails, options);
                    break;
                case 'skill-map':
                    model = VectorMapUtils.SkillMap.generateModel(context, vectorData, this.props.onViewRecordDetails, options, linkedRoles, contextData);
                    break;
                default:
                    // Do nothing.
            }
        }

        if (model) {
            // Register a listener so that whenever node/link selection changes, we clear any pre-existing selections.
            // This ensures that only a single node/link may be selected at any given time.
            model.getModels().forEach(item => {
                item.registerListener({
                    selectionChanged: () => {
                        if (engine.getModel().getSelectedEntities().length > 0) {
                            engine.getModel().clearSelection();
                        }
                    }
                });
            });
        } else {
            model = new DiagramModel();
            model.setLocked(true);
        }


        // Apply the previous models offset and zoom values.
        model.setOffset(currentOffsetX, currentOffsetY);
        model.setZoomLevel(currentZoomLevel);

        engine.setModel(model);

        this.forceUpdate();
    }

    render() {
        const { style, classes, type, onTableViewClicked } = this.props;
        const { engine, isReady, isFullscreen, defaultOptions, options, params, context, linkedRoles, vectorData, legendExpanded} = this.state;

        console.debug("Rendering Vector Map (Type: '" + type + "', Context: '" + context + "')");

        const appliedStyle = Object.assign({}, style);
        if (isFullscreen) {
            appliedStyle.minHeight = '100%';
            appliedStyle.maxHeight = '100%';
            appliedStyle.minWidth = '100%';
            appliedStyle.maxWidth = '100%';
        }

        // Determine is there are any (at least one) linked job roles.
        const hasLinkedRoles = linkedRoles && linkedRoles.length > 0;

        const renderedVectorMap = (
            <div className={classes.root} style={appliedStyle}>
                {!isFullscreen &&
                    <div className={classes.header}>
                        {this.props.title &&
                            <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center' }}>
                                <Typography className={classes.title}>{this.props.title}</Typography>

                                {engine && engine.getModel() && vectorData && type === VectorMapType.SKILL_MAP &&
                                    <Typography className={classes.titleExtra}>({engine.getModel().getNodes().filter(node => node instanceof SkillNodeModel).length} / {vectorData.length} Displayed)</Typography>
                                }
                            </div>
                        }

                        <div style={{ flex: '1 1 auto' }}></div>

                        <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center' }}>
                            <Button variant={'outlined'} color={'primary'} onClick={onTableViewClicked}>Table View</Button>
                        </div>
                    </div>
                }

                <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
                    <div
                        className={classes.diagramWrapper}
                        // onMouseEnter={(e) => {
                        //     const element = document.getElementById('main');
                        //     if (element) element.style.overflow = 'hidden';
                        //     document.body.style.overflow = 'hidden';
                        // }}
                        // onMouseLeave={(e) => {
                        //     const element = document.getElementById('main');
                        //     if (element) element.style.overflow = 'auto';
                        //     document.body.style.overflow = 'auto';
                        // }}
                        // onMouseOver={(e) => {
                        //     const element = document.getElementById('main');
                        //     if (element) element.style.overflow = 'hidden';
                        //     document.body.style.overflow = 'hidden';
                        // }}
                    >
                        <div style={{ flex: '1 1 auto', display: 'flex', position: 'relative', border: '1px solid #4b5162' }}>
                            {!isReady &&
                                <div className={classes.loadingControl}>
                                    <LoadingInfo />
                                </div>
                            }

                            {isReady && (!vectorData || vectorData.length === 0) &&
                                <div className={classes.noDataControl}>
                                    <Typography>No Data Found</Typography>
                                </div>
                            }

                            {isReady && vectorData && vectorData.length > 0 && engine.getModel() && engine.getModel().getNodes().length === 0 &&
                                <div className={classes.noDataControl}>
                                    {type === 'catalog-map' &&
                                        <Typography>All Training Hidden</Typography>
                                    }

                                    {type === 'training-map' &&
                                        <Typography>All Lessons Hidden</Typography>
                                    }

                                    {type === 'lesson-map' &&
                                        <Typography>All Events Hidden</Typography>
                                    }

                                    {type === 'skill-map' && hasLinkedRoles &&
                                        <Typography>All Skills Hidden</Typography>
                                    }
                                    {type === 'skill-map' && !hasLinkedRoles && context.context === 'user' &&
                                        <Typography style={{ textAlign: 'center' }}>No job role identified for selected user.<br/>Select one from the filter menu Or Assign this user a job role.</Typography>
                                    }
                                    {type === 'skill-map' && !hasLinkedRoles && context.context === 'team' &&
                                        <Typography style={{ textAlign: 'center' }}>No job roles identified for selected team.<br/>Select one from the filter menu Or Assign a user of this team a job role.</Typography>
                                    }
                                    {type === 'skill-map' && !hasLinkedRoles && context.context === 'org' &&
                                        <Typography style={{ textAlign: 'center' }}>No job roles identified for this organization.<br/>Select one from the filter menu Or Assign a user of this organization a job role.</Typography>
                                    }

                                    <Button color={'primary'} variant={'outlined'} style={{ textTransform: 'none', marginTop: 10 }} onClick={() => {
                                        if (type === 'skill-map' && !hasLinkedRoles) this.handleExpandFilterOption('role-info.other-roles');
                                        else this.handleOptionsChanged(defaultOptions);
                                    }}>
                                        {type === 'skill-map' && !hasLinkedRoles &&
                                            'Show Other Roles'
                                        }
                                        {(type !== 'skill-map' || hasLinkedRoles) &&
                                            'Reset To Defaults'
                                        }
                                    </Button>
                                </div>
                            }

                            {isReady && engine.getModel() && engine.getModel().getNodes().length > 0 &&
                                <CanvasWidget className={classes.diagramCanvas} engine={engine} />
                            }

                            {isReady && (vectorData && vectorData.length > 0) &&
                                <Legend type={type} context={context} options={options} data={vectorData} expanded={legendExpanded} onToggleExpanded={this.handleToggleLegendExpanded} />
                            }

                            <div className={classes.fullscreenControls}>
                                <Button onClick={this.handleToggleFullscreen}>
                                    {isFullscreen &&
                                        <FullscreenExitIcon />
                                    }
                                    {!isFullscreen &&
                                        <FullscreenIcon />
                                    }
                                </Button>
                            </div>

                            <div className={classes.zoomControls}>
                                <Button onClick={this.handleZoomIn}>+</Button>
                                <Button onClick={this.handleZoomOut}>-</Button>
                                <Button onClick={this.handleZoomReset}>R</Button>
                            </div>
                        </div>
                    </div>

                    {defaultOptions &&
                        <OptionsBlade
                            ref={this.optionsEl}
                            defaultOptions={defaultOptions}
                            options={options}
                            params={params}
                            onOptionsChanged={this.handleOptionsChanged}
                            onParamsChanged={this.handleParamsChanged}
                        />
                    }
                </div>
            </div>
        );

        if (isFullscreen) {
            return (
                <Dialog open={true} fullScreen={true}>
                    {renderedVectorMap}
                </Dialog>
            );
        } else {
            return renderedVectorMap;
        }
    }
}

const myStyles = (theme) => createStyles({
    root: {
        minHeight: 600,
        maxHeight: 600,
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.containedContent,
        boxShadow: 'rgba(0, 0, 0, 0.498) 0px 3px 3px'
    },
    header: {
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.background.contained,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.borderColor.default,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        height: 51,
        paddingLeft: 20,
        paddingRight: 20
    },
    title: {
        fontFamily: "Archivo Bold",
        fontSize: 20
    },
    titleExtra: {
        fontSize: 16,
        fontStyle: 'italic',
        marginLeft: '10px'
    },
    diagramWrapper: {
        flex: '1 1 auto',
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column'
    },
    diagramCanvas: {
        flex: '1 1 auto'
    },
    loadingControl: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    noDataControl: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    fullscreenControls: {
        position: 'absolute',
        top: 10,
        right: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > button': {
            minHeight: 32,
            maxHeight: 32,
            minWidth: 32,
            maxWidth: 32,
            backgroundColor: '#4b5162',
            color: '#FFFFFF',
            border: '1px solid #000000',
            borderRadius: 10,
            '&:hover': {
                backgroundColor: '#4b5162',
                color: '#FFFFFF',
            },
        }
    },
    zoomControls: {
        position: 'absolute',
        bottom: 10,
        right: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > button': {
            minHeight: 32,
            maxHeight: 32,
            minWidth: 32,
            maxWidth: 32,
            backgroundColor: '#4b5162',
            color: '#FFFFFF',
            border: '1px solid #000000',
            '&:hover': {
                backgroundColor: '#4b5162',
                color: '#FFFFFF',
            },
            fontWeight: 'bold',
            fontSize: '20px',
            '&:nth-child(1)': {
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
            },
            '&:nth-child(2)': {
                marginTop: 5,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
            },
            '&:nth-child(3)': {
                marginTop: 10,
                borderRadius: 10
            }
        }
    }
});

const mapStateToProps = (state: any) => {
    return {
        users: state && state.admin && state.admin.resources && state.admin.resources.users && state.admin.resources.users.data ? state.admin.resources.users.data : {}
    };
};

const enhance = compose(
    connect(mapStateToProps, null),
    withStyles(myStyles),
    withDataProvider
);

export default enhance(VectorMap);
