import * as moment from 'moment';

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

const TimeDateField = ({source, record = {}, altRecord,nearingEndDate = 1, fontFamily = 'Montserrat Medium'}) => {

	if (altRecord) {
		record = altRecord;
	}

	if (!get(record, source)) {
		return <div>---</div>;
	}

	if (source === 'endDate') {
		let color = null;
		const endDate = get(record, source);

		if (endDate === -1) return <span style={{fontSize: '24px'}}>&infin;</span>;
		
		const today = Date.parse(new Date().toUTCString());
		const warningDate = today + nearingEndDate * 345600000;
		if (endDate < today) {
			color = '#666666';
		} else if (endDate > today && endDate < warningDate) {
			color = '#ffc100';
		}
		return (
			<div style={{color: color, fontFamily: fontFamily}}>
				{moment(get(record, source)).format('L')}
			</div>
		);
	}

	return (
		<div style={{fontFamily: fontFamily}}>
			{moment(get(record, source)).format('L')}
		</div>
	);
};

TimeDateField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
	fontFamily: PropTypes.string,
};

export default TimeDateField;
