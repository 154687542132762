import React, {useState} from 'react';

import LessonList from './LessonList';
import LessonsTabDetail from './LessonDetail';
import {MultiShow} from 'components';

const Lesson = props => {
	const {classes, id, trainingName} = props;
	const [
		selectedTrainingLessonRecord,
		setSelectedTrainingLessonRecord,
	] = useState(null);

	const lessonRowClick = (id, basePath, record) => {
		setSelectedTrainingLessonRecord(record);
	};

	const cancelSelection = () => {
		setSelectedTrainingLessonRecord(null);
	};

	return (
		<MultiShow {...props}>
			{!selectedTrainingLessonRecord && (
				<LessonList
					classes={classes}
					rowClick={lessonRowClick}
					trainingId={id}
				/>
			)}
			{selectedTrainingLessonRecord && (
				<LessonsTabDetail
					classes={classes}
					backLessonAction={cancelSelection}
					selectedLessonRecord={selectedTrainingLessonRecord}
					trainingName={trainingName}
				/>
			)}
		</MultiShow>
	);
};

export default Lesson;
