import * as React from 'react';
import compose from 'recompose/compose';
import {createStyles, Popover, withStyles} from '@material-ui/core';
import {DiagramEngine} from '@projectstorm/react-diagrams';

import {SkillNodeModel} from './SkillNodeModel';
import SkillNodePopover from './SkillNodePopover';

import LabelIcon from 'components/VectorMap/LabelIcon';

export interface SkillNodeWidgetProps {
    node: SkillNodeModel;
    engine: DiagramEngine;
    classes?: any
}

export interface SkillNodeWidgetState {
    popoverOpen: boolean,
    dragXCount: number | null,
    dragYCount: number | null
}

class SkillNodeWidget extends React.Component<SkillNodeWidgetProps, SkillNodeWidgetState> {
    state = {
        popoverOpen: false,
        dragXCount: null,
        dragYCount: null
    };
    anchorEl: any = null;

    constructor(props: SkillNodeWidgetProps) {
        super(props);

        this.anchorEl = React.createRef();
    }

    openPopover = () => {
        this.setState({ popoverOpen: true });
    }

    closePopover = () => {
        this.setState({ popoverOpen: false });
    }

    onMouseDown = (_e: React.MouseEvent) => {
        this.setState({ dragXCount: 0, dragYCount: 0 });
    }

    onMouseMove = (e: React.MouseEvent) => {
        const { dragXCount, dragYCount } = this.state;

        this.setState({ dragXCount: dragXCount + Math.abs(e.movementX), dragYCount: dragYCount + Math.abs(e.movementY) });
    }
    
    onMouseUp = (_e: React.MouseEvent) => {
        const{ dragXCount, dragYCount } = this.state;

        if (dragXCount === 0 && dragYCount === 0) {
            this.openPopover();
        }

        this.setState({ dragXCount: null, dragYCount: null });
    }

    render() {
        const { classes, node } = this.props;
        const { popoverOpen } = this.state;

        const width = node.getWidth() ? node.getWidth() : 64;
        const height = node.getHeight() ? node.getHeight() : 64;

        // const context = node.getContext();
        // const subContext = node.getSubContext();
        const record = node.getRecord();
        // const options = node.getDataOptions();

        // If there is no record, just render the basic node shape (such as when this widget is used in the legend).
        if (!record) return (
            <div
                className={classes.root}
                style={{
                    cursor: 'default',
                    width: width,
                    height: height,
                    backgroundColor: node.getBackgroundColor(),
                    color: node.getFontColor(),
                }}
            ></div>
        );

        return (
            <div
                ref={this.anchorEl}
                className={classes.root}
                style={{
                    width: width,
                    height: height,
                    transform: popoverOpen ? 'scale(1.05)' : null
                }}
                onMouseDown={this.onMouseDown}
                onMouseMove={this.onMouseMove}
                onMouseUp={this.onMouseUp}
            >
                <div className={classes.icon} style={{ backgroundColor: node.getBackgroundColor(), color: node.getFontColor(), borderColor: node.getFontColor() }}>
                    {record.iconURL && record.iconURL.trim().length > 0 &&
                        <img src={record.iconURL} alt={'skillIcon'} onMouseDown={(e) => { e.preventDefault(); }} />
                    }

                    {(!record.iconURL || record.iconURL.trim().length === 0) && record.displayShortName &&
                        <LabelIcon label={record.displayShortName} fontScale={0.5} />
                    }
                </div>

                {/* Popup Component. */}
                {this.anchorEl.current &&
                    <Popover
                        className={classes.popover}
                        open={popoverOpen}
                        onClose={this.closePopover}
                        anchorEl={this.anchorEl.current}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        onWheel={e => { e.stopPropagation(); }}
                        onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                        onMouseDown={(e) => { e.stopPropagation(); e.preventDefault(); }}
                    >
                        <SkillNodePopover node={node} />
                    </Popover>
                }
            </div>
        );
    }
}

const myStyles = () =>
    createStyles({
        root: {
            '&:hover': {
                cursor: 'pointer'
            },
            position: 'relative',
            display: 'flex',
            borderRadius: '10%',
            overflow: 'hidden'
        },
        icon: {
            width: '100%',
            height: '100%'
        },
        popover: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'center',
            '& div[tabindex="-1"]': {
                borderStyle: 'solid',
                borderColor: (props: SkillNodeWidgetProps) => props.node.getBackgroundColor(),
                borderWidth: 5,
                borderRadius: 10,
                overflow: 'hidden',
                boxShadow: '10px 10px 0px 0px rgba(35, 56, 99, 0.3)'
            }
        }
    });

const enhance = compose(withStyles(myStyles));

export default enhance(SkillNodeWidget);