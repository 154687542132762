import React, {CSSProperties, ReactElement} from 'react';
import {duration} from 'moment';

import {Typography} from '@material-ui/core';

interface PROPS {
    type: 'text' | 'number' | 'duration' | 'percentage',
    icon?: ReactElement,
    label?: string | null,
    value?: string | number | null,
    color?: string | null,
    className?: string,
    style?: CSSProperties
}

class StatLabel extends React.Component<PROPS> {
    renderText() {
        const { value } = this.props;

        return '' + value;
    }

    renderNumber() {
        const { value } = this.props;

        if (typeof value === 'number') return Math.round(value);
        else return value;
    }

    renderDuration() {
        const { value } = this.props;

        if (typeof value === 'number') {
            const d = duration(value, 'seconds');
            const h = Math.floor(d.asHours());
            const m = Math.floor(d.asMinutes()) - (h * 60);
            const s = Math.floor(d.asSeconds()) - (h * 60 * 60) - (m * 60);

            return h + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);
        } else {
            return value;
        }
    }

    renderPercentage() {
        const { value } = this.props;

        if (typeof value === 'number') return Math.round((value as number) * 100) + '%';
        else return value;
    }

    renderValue() {
        const { type, value } = this.props;

        if (value == null || value === '---') {
            return '---';
        }

        switch (type) {
            case 'text':
                return this.renderText();
            case 'number':
                return this.renderNumber();
            case 'duration':
                return this.renderDuration();
            case 'percentage':
                return this.renderPercentage();
            default:
                return value;
        }
    }

    render() {
        const { icon, label, color, className, style } = this.props;

        const realColor = color ? color : 'currentColor';

        const appliedStyle: CSSProperties = Object.assign({
            flex: '0 0 auto',
            display: 'flex',
            alignItems: 'center',
            // height: 24,
            // padding: 1,
            color: realColor
        }, style);

        return (
            <div className={className} style={appliedStyle}>
                {icon &&
                    <div style={{ width: 20, height: 20, display: 'flex' }}>
                        {icon}
                    </div>
                }

                {label &&
                    <Typography style={{ marginLeft: icon ? 5 : 0, fontWeight: 'bold', overflow: 'hidden' }} noWrap>{label}</Typography>
                }

                <Typography style={{ marginLeft: icon || label ? 10 : 0, fontWeight: 'normal', fontStyle: 'italics' }}>{this.renderValue()}</Typography>
            </div>
        );
    }
}

export default StatLabel;
