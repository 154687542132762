import React, {useState} from 'react';
import NavigatingDialog from '../../components/dialogs/NavigatingDialog';
import MenuItem from '@material-ui/core/MenuItem';

const ViewUserDetailButton = props => {
	const [isNavOpen, setIsNavOpen] = useState(false);

	const openDialog = () => {
		setIsNavOpen(true);
	};
	const handleClose = e => {
		setIsNavOpen(false);
		if (e) e.stopPropagation();
		props.onClick && props.onClick(e);
	};

	return (
		<div>
			<NavigatingDialog
				title={'Navigating Away'}
				navigateTo={`/users/${props.record.id}/show`}
				showOnceKey={'navigateToUsers'}
				isOpen={isNavOpen}
				setIsOpen={setIsNavOpen}
				onClose={handleClose}
			>
				This will take you to another section.
				<br />
				<br />
				Do you want to navigate away from this section?
			</NavigatingDialog>
			<div>
				<MenuItem onClick={openDialog}>View User Detail</MenuItem>
			</div>
		</div>
	);
};

export default ViewUserDetailButton;
