import React, {useState, useEffect, useMemo} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {useDataProvider, GET_LIST, useTranslate} from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {DialogTitle, EmbeddedList, Text} from 'components';
import Button from '@material-ui/core/Button';
import {createStyles, withStyles} from '@material-ui/core/styles';
import { AddOutlined } from '@material-ui/icons';

const myStyles = theme =>
	createStyles({
		divider: {
			backgroundColor: theme.palette.borderColor.divider,
			marginBottom: '12px',
		},
		normalText: {
			color: theme.palette.color.default,
		},
		importantText: {
			color: theme.palette.color.important,
		},
		noTeamSelected: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'nowrap',
			paddingLeft: '5px',
			paddingRight: '5px',
			margin: '3px 3px 3px 0px',
			backgroundColor: theme.palette.background.default,
			color: theme.palette.color.default,
			height: '28px',
		},
		selectedTeamChip: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'nowrap',
			borderRadius: '2px',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: theme.palette.borderColor.alternateButton, //theme.palette.color.default,
			paddingLeft: '5px',
			paddingRight: '5px',
			margin: '3px 3px 3px 0px',
			height: '28px',
			backgroundColor: theme.palette.background.alternateButton, //theme.palette.background.default,
			color: theme.palette.color.alternateButton,
			'& > svg': {
				marginLeft: '5px',
				color: 'inherit',
				cursor: 'pointer',
			},
			'& label': {
				maxWidth: '173px',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
			},
		},
	});

function TeamsInput({
	classes,
	defaultTeamId,
	handleDefaultTeamsChanged,
	...props
}) {
	const translate = useTranslate();

	const [form, setForm] = useState({});
	const [availableTeams, setAvailableTeams] = useState([]);
	const [selectedTeams, setSelectedTeams] = useState([]);
	const [showTeamsList, setShowTeamsList] = useState(false);

	const dataProvider = useDataProvider();

	useMemo(() => {
		if (defaultTeamId) {
			form.teamIds = [...defaultTeamId];
			setSelectedTeams([...defaultTeamId]);
		}
	}, [defaultTeamId]);

	useEffect(() => {
		dataProvider(GET_LIST, 'teams', {
			sort: {field: 'name', order: 'ASC'},
			pagination: {page: 1, perPage: 9999},
		}).then(response => {
			setAvailableTeams(response.data);
		});
	}, []);

	const handleTeamAddition = teamId => {
		const selectedTeamsCopy = [...selectedTeams];

		if (!selectedTeamsCopy.includes(teamId)) {
			selectedTeamsCopy.push(teamId);
		} else {
			const index = selectedTeamsCopy.indexOf(teamId);
			if (index > -1) {
				selectedTeamsCopy.splice(index, 1);
			}
		}

		setSelectedTeams(selectedTeamsCopy);
	};

	const handleTeamRemoval = teamId => {
		const record = {...form};
		const selectedTeamsCopy = [...selectedTeams];
		if (record.teamIds) {
			const idx = record.teamIds.indexOf(teamId);

			if (idx >= 0) {
				record.teamIds.splice(idx, 1);
				selectedTeamsCopy.splice(idx, 1);
			}
		}

		setForm(record);
		setSelectedTeams(selectedTeamsCopy);
	};

	useEffect(() => {
		handleDefaultTeamsChanged(selectedTeams);
	}, [selectedTeams]);

	const renderTeams = () => {
		const record = form;
		const teams =
			availableTeams && Array.isArray(availableTeams) ? availableTeams : [];

		const result = [];

		if (
			record.teamIds &&
			Array.isArray(record.teamIds) &&
			record.teamIds.length > 0
		) {
			record.teamIds.forEach(teamId => {
				const team = teams.find(item => item.id === teamId);

				if (team) {
					result.push(
						<div key={teamId} className={classes.selectedTeamChip}>
							<label>{team.name}</label>
							<CloseIcon onClick={() => handleTeamRemoval(teamId)} />
						</div>
					);
				}
			});
		} else {
			result.push(
				<div className={classes.noTeamSelected}>
					<label>{translate('No teams selected')}</label>
				</div>
			);
		}

		return result;
	};

	const handleTeamsListClose = () => {
		setShowTeamsList(false);
		setSelectedTeams(form.teamIds);
	};

	const handleTeams = () => {
		const record = {...form};

		record.teamIds = [];

		selectedTeams.forEach(teamId => {
			if (!record.teamIds.includes(teamId)) {
				record.teamIds.push(teamId);
			}
		});
		setForm(record);
		setShowTeamsList(false);
	};

	return (
		<div style={{width: 'fit-content'}}>
			{renderTeams()}
			<Button
				color='primary'
				variant='contained'
				onClick={() => setShowTeamsList(true)}
				startIcon={<AddOutlined />}
			>
				{translate('Add Team')}
			</Button>
			{showTeamsList ? (
				<Dialog maxWidth='md' open={true} aria-label={'Team list'}>
					<DialogTitle onClose={handleTeamsListClose}>
						<Text style={{fontSize: '20px'}} label={'Team list'} />
					</DialogTitle>
					<DialogContent>
						<EmbeddedList
							embedded={true}
							title={null}
							resource='teams'
							cached={false}
							columns={[
								{
									id: 'name',
									name: 'Name',
									sortable: true,
								},
								{
									id: 'users',
									name: 'Users',
									sortable: true,
									align: 'center',
									width: '150px',
								},
								{
									id: 'training',
									name: 'Module',
									sortable: true,
									align: 'center',
									width: '150px',
								},
							]}
							sort={{field: 'name', order: 'ASC'}}
							staticFilters={null}
							dynamicFilters={[
								{
									id: 'name',
									name: 'Search Teams',
									type: 'safe_regex',
								},
							]}
							bulkActions={null}
							actions={null}
							rowClicked={null}
							selectedIds={selectedTeams}
							selectionToggled={record => {
								handleTeamAddition(record.id);
							}}
							stripped={false}
						/>
						<div style={{flex: '0 0 auto'}}>
							{selectedTeams.length > 0 && <hr className={classes.divider} />}

							{selectedTeams.length > 0 && (
								<div
									style={{
										flex: '1 1 auto',
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										padding: '10px',
									}}
								>
									<label className={classes.normalText}>
										&nbsp;
										{translate('The User will be Added to ')}
									</label>
									<label className={classes.importantText}>
										&nbsp;
										{selectedTeams.length}{' '}
										{selectedTeams.length > 1 ? 'Teams' : 'Team'}
									</label>
								</div>
							)}
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							style={{width: 'auto', height: '32px'}}
							variant={'contained'}
							color={'primary'}
							onClick={handleTeams}
						>
							{translate('Select')}
						</Button>
					</DialogActions>
				</Dialog>
			) : null}
		</div>
	);
}

export default withStyles(myStyles)(TeamsInput);
