import React, {Component} from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

export default class SelectBox extends Component {
	state = {checked: false};

	handleChange = name => e => {
		e.stopPropagation();
		// console.debug(`SelectBox: Checkbox changed for ${this.props.record.id} to ${event.target.checked}    ${name}`);
		this.setState({checked: e.target.checked});
		this.props.onChange({key: name, checked: e.target.checked});
	};

	render() {
		const {record, checked, isSelected, source, className} = this.props;
		const key = record[source];
		const selected = isSelected ? isSelected(key) : checked;
		// console.debug(`SelectBox: Currently has a record of ${key} selected:${selected}`);
		return (
			<Checkbox
				className={className}
				onChange={this.handleChange(key)}
				value={key}
				checked={selected}
			/>
		);
	}
}

SelectBox.propsType = {
	onChange: PropTypes.func,
	source: PropTypes.string.isRequired,
	record: PropTypes.object,
	checked: PropTypes.bool,
	isSelected: PropTypes.func,
	className: PropTypes.string,
};
