import {
	Bar,
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import React, {} from 'react';
import {useTranslate} from 'react-admin';
import {useTheme} from '@material-ui/core/styles';
import PanelInfo from '../../components/Panels/PanelInfo';
import LoadingInfo from '../../components/loading/LoadingInfo';
import PanelInfoPlaceholder from '../../components/Panels/PanelInfoPlaceholder';

/* eslint-disable no-loop-func */

const LessonsCompletedByDayPanel = (props) => {
	const translate = useTranslate();
	
	const theme = useTheme();

	const { lessonsCompletedByDay, error } = props;

	const title = translate('Lessons Attempted per Day');

	const hasItems = lessonsCompletedByDay && lessonsCompletedByDay.length > 0;

	if (hasItems) {
		return (
			<PanelInfo title={title}>
				<div>
					<div
						style={{
							flex: '1 1 auto',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'flex-start',
							height: '128px',
						}}
					>
						<ResponsiveContainer width={'100%'} height={'100%'}>
							<BarChart data={lessonsCompletedByDay}>
								<CartesianGrid
									vertical={false}
									stroke={theme.palette.borderColor.divider}
								/>
								<XAxis
									axisLine={false}
									dataKey='label'
									tickLine={{stroke: 'transparent'}}
									tick={({
										payload: {value},
										verticalAnchor,
										visibleTicksCount,
										...rest
									}) => {
										const restProps = {...rest};
										if ('tickFormatter' in restProps)
											delete restProps.tickFormatter;
										return (
											<text
												{...restProps}
												dy={12}
												fill='inherit'
												fontSize='12px'
											>
												{value}
											</text>
										);
									}}
								/>
								<YAxis
									axisLine={false}
									allowDecimals={false}
									type='number'
									width={40}
									tickLine={{stroke: 'transparent'}}
									tick={{fill: 'inherit', fontSize: 12}}
								/>
								<Tooltip
									cursor={{fill: 'rgba(0,0,0,0.1)'}}
									content={({active, payload, label}) => {
										if (active && payload && payload[0]) {
											const options = {
												weekday: 'short',
												year: 'numeric',
												month: 'short',
												day: 'numeric',
											};
											const timestamp = new Date(
												payload[0].payload.labelValue
											).toLocaleDateString('en', options);
											const text =
												payload[0].value === 1
													? translate('Lesson')
													: translate('Lessons');
											return (
												<div>
													<p>{`${payload[0].value} ${text} - ${timestamp}`}</p>
												</div>
											);
										}
										return null;
									}}
								/>
								<Bar
									dataKey='value'
									fill={theme.palette.primary.main}
									radius={5}
									barSize={5}
								/>
							</BarChart>
						</ResponsiveContainer>
					</div>
				</div>
			</PanelInfo>
		);
	}

	if (lessonsCompletedByDay && lessonsCompletedByDay.length === 0) {
		return (
			<PanelInfoPlaceholder title={title}>
				Your users haven't finished any training modules yet. <br />
				<br />
				This panel will display a timeline of lessons completed each day over
				the last 10 days.
			</PanelInfoPlaceholder>
		);
	}

	if (error) {
		return <PanelInfoPlaceholder title={title}>{error}</PanelInfoPlaceholder>
	}

	return (
		<PanelInfo>
			<LoadingInfo />
		</PanelInfo>
	);
};
export default LessonsCompletedByDayPanel;
