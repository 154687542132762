import React, {useEffect, useRef, useState} from 'react';
import {useDataProvider, useVersion, GET_LIST} from 'react-admin';
import {Redirect} from 'react-router-dom';
import {EmbeddedTabs} from 'components';
import Information from './Information';
import Stats from './Stats/StatsTab';
import Team from './Team/Team';
import {getUserInfo, safeRequestData} from 'utils/Data';
import UserTraining from 'components/UserTraining';

const Show = props => {
	const {id} = props;

	let activeTrainingInfo = window.localStorage.getItem('activeTrainingInfo');

	if (activeTrainingInfo) {
		activeTrainingInfo = JSON.parse(activeTrainingInfo);
	}

	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const [state, setState] = useState({
		userData: null,
		selectedTrainingRecord: null,
		authType: '',
		pwd: '',
		isUserDataLoading: false
	});

	const dataProvider = useDataProvider();

	const fetchTrainigsForUser = async () => {
		const {data: trainingsForUser} = await dataProvider(
			GET_LIST,
			'trainingsforuser',
			{
				filter: {accessibility: {assigned: true}, userId: id},
				pagination: {page: 1, perPage: 9999},
			}
		);

		if (trainingsForUser) {
			let selectedTrainig = null;
			for (let i = 0; i < trainingsForUser.length; i++) {
				if (trainingsForUser[i].id === activeTrainingInfo.id) {
					selectedTrainig = trainingsForUser[i];
				}
			}
			if (selectedTrainig) {
				setState(prevState => ({
					...prevState,
					selectedTrainingRecord: selectedTrainig,
				}));
			}
			window.localStorage.removeItem('activeTrainingInfo');
		}
	};

	const fetchData = async () => {
		let projectData = sessionStorage.getItem('project');
		if (projectData) {
			const orgConfig = JSON.parse(projectData);
			if (orgConfig && orgConfig.config) {
				if (orgConfig.config.authType) {
					state.authType = orgConfig.config.authType;
				}
			}
		}

		const requestList = [{resource: 'users', id: id, dataAlias: 'userData'}];
		
		setState({ ...state, isUserDataLoading: true });

		for (let i = 0; i < requestList.length; i++) {
			const request = requestList[i];
			await safeRequestData({
				dataRequest: request,
				dataProvider: dataProvider,
				isMounted: isMounted,
				setState: setState,
			});
		}

		setState(state => ({
			...state,
			isUserDataLoading: false		
		}));
	};

	const version = useVersion();

	useEffect(() => {
		fetchData();
		if (activeTrainingInfo && activeTrainingInfo.id) {
			fetchTrainigsForUser();
		}
	}, [version]);

	
	if (!getUserInfo()?.isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: '/login',
					state: {from: props.location},
				}}
			/>
		);
	}

	return (
		<div>
			<Information state={state} setState={setState} {...props} isUserDataLoading={state.isUserDataLoading}/>
			<EmbeddedTabs
				activeTab={activeTrainingInfo && activeTrainingInfo.name}
				data={{
					tabs: [
						{
							name: 'Team',
							content: <Team {...props} userData={state.userData} />,
						},
						{
							name: 'Modules',
							content: (
								<UserTraining
									userData={state.userData}
									{...props}
									selectedRecord={
										activeTrainingInfo && state.selectedTrainingRecord
									}
								/>
							),
						},
						{
							name: 'Stats',
							content: <Stats userData={state.userData} {...props} />,
						},
					],
				}}
			/>
		</div>
	);
};

export default Show;
