import React from 'react';
import {EmbeddedList} from 'components';
import {NumberField, useTranslate} from 'react-admin';

import {MenuItem} from '@material-ui/core';
import PercentField from 'components/PercentField';
import {useLocation} from 'react-router-dom';
import SkillFilter from "components/SkillFilter";

const LabList = props => {
	const {
		trainingData,
		labSelected,
		selectedSkills,
		skillSelected,
	} = props;

	const translate = useTranslate();

	const location = useLocation();

	return (
		<EmbeddedList
			embedded={false}
			title={translate('Labs')}
			resource='listLabsForTrainingRunway'
			columns={[
				{
					id: 'name',
					name: translate('Lab Name'),
					sortable: true,
				},
				{
					id: 'attempts',
					name: translate('Attempts'),
					sortable: true,
					align: 'center',
					render: record => <NumberField record={record} source='attempts' />,
					isInverted: true,
				},
				{
					id: 'completion',
					name: translate('Avg Completion Rate'),
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='completion' />
					),
				},
				{
					id: 'score',
					name: translate('Org Avg Score'),
					sortable: true,
					align: 'center',
					render: record => <PercentField record={record} source='score' />,
					isInverted: true,
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[
				{id: 'trainingId', value: trainingData.id},
			]}
			dynamicFilters={[
				{
					id: 'namedSkills',
					value: selectedSkills.length > 0 ? {$in: selectedSkills.map(skill => skill.name)} : undefined,
					render: () => (
						<SkillFilter
							onChange={skills => {
								skillSelected(skills);
								//appplies the selected skill filters(filters applied at the lesson level) at trainings when navigating back to /trainings.
								location.state.selectedSkills = skills;
							}}
							preSelectedSkills={
								selectedSkills.length ? selectedSkills : undefined
							}
							skillListContextData={{trainingId: trainingData.id}}
						/>
					),
				},
			]}
			bulkActions={[
				{
					id: 'addTraining',
				},
			]}
			actions={[
				{
					render: record => (
						<MenuItem key={'View Lab Detail'}>
							<div onClick={() => labSelected(record)}>
								{translate('View Lab Detail')}
							</div>
						</MenuItem>
					),
				},
			]}
			rowClicked={record => labSelected(record)}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default LabList;
