import { useState, useEffect } from 'react';

function FadeOut({ id, onHidden, children }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [id]);

  useEffect(() => {
    let opacity = 1;
    let opacityInterval;

    if (!visible) {
      opacityInterval = setInterval(() => {
        opacity -= 0.1;

        if (opacity <= 0) {
          clearInterval(opacityInterval);
          onHidden && onHidden();
        }
      }, 100);
    }

    return () => clearInterval(opacityInterval);
  }, [visible, onHidden]);

  return (
    <>
      <style>
        {`
          @keyframes fadeIn_${id} {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          @keyframes fadeOut_${id} {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
        `}
      </style>

      <div
        style={{
          animation: `${visible ? `fadeIn_${id}` : `fadeOut_${id}`} 1s forwards`,
          opacity: visible ? 1 : 0,
        }}
      >
        {children}
      </div>
    </>
  );
}

export default FadeOut;
