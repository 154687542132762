import React from 'react';

const Grid = ({
	width = '100%',
	padding = 0,
	gridGap = '20px 20px',
	perRow = 8,
	children,
	props,
	stretch = false,
	backgroundColor = 'inherit',
}) => {
	return (
		<div
			style={{
				width: width,
				padding: padding,
				display: 'grid',
				gridTemplateColumns: `repeat(${perRow}, 1fr)`,
				gridGap: gridGap,
				overflow: 'none',
				...props,
				alignContent: stretch ? 'stretch' : 'none',
				gridTemplateRows: 'min-content',
				alignItems: stretch ? 'stretch' : 'none',
				backgroundColor: backgroundColor,
			}}
		>
			{children}
		</div>
	);
};

export default Grid;
