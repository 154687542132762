import {GET_LIST, useQueryWithStore} from 'react-admin';

import LoadingList from '../../../loading/LoadingList';
import React from 'react';
import {SkillBarPanel} from '../../..';
import {Typography} from '@material-ui/core';

const Skills = props => {
	const {userId, trainingId, labId, lessonId, attemptId} = props;

	const {data: userskillstats, error, loading} = useQueryWithStore({
		type: GET_LIST,
		resource: 'userskillstats',
		payload: {
			filter: {
				userId: userId,
				labId: labId,
				trainingId: trainingId,
				lessonId: lessonId,
				sId: attemptId,
			},
			pagination: {},
			sort: {},
		},
	});

	return (
		<div>
			{error && (
				<Typography style={{textAlign: 'center', fontSize: '28px'}}>
					No Skills Found
				</Typography>
			)}
			{loading && <LoadingList />}
			{(userskillstats && !loading) && <SkillBarPanel data={userskillstats} />}
		</div>
	);
};

export default Skills;
