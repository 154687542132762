import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	MuiThemeProvider,
	Typography,
	withStyles,
} from '@material-ui/core';
import React from 'react';
import {DropzoneArea} from 'material-ui-dropzone';
import {DialogTitle} from 'components';
import DialogListTheme from 'components/Theme';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { AddOutlined } from '@material-ui/icons';

class ImageUpload extends React.Component {
	state = {
		files: [],
		dataUri: null,
		errorMeaage: null,
		minHeight: this.props.minHeight || 1080,
		minWidth: this.props.minWidth || 1920,
		maxFileSize: this.props.maxFileSize || 5000000, // in bytes
	};

	handleClose = () => {
		this.setState({files: [], dataUri: null});
		this.props.onChange();
	};

	handleFilesChanged = async files => {
		const {maxFileSize, minHeight, minWidth} = this.state;

		if (files && files.length > 0) {
			if (files[0].size > maxFileSize) {
				this.setState({
					error: `File Size Must not exceed ${maxFileSize / 1000}kb`,
					files: [],
				});
				return;
			}

			const imgUrl = URL.createObjectURL(files[0]);

			const imageDimensions = await this.getImageDimensions(imgUrl);

			if (
				imageDimensions.width >= minWidth &&
				imageDimensions.height >= minHeight
			) {
				this.setState({dataUri: URL.createObjectURL(files[0]), error: false});
				this.setState({files: files});
			} else {
				this.setState({error: `Must be ${minWidth} X ${minHeight} or above`});
			}
		} else {
			this.setState({dataUri: null, files: []});
		}
	};

	handleDeleteImage = () => {
		this.handleFilesChanged([]);
	};

	handleConfirm = () => {
		this.props.onChange(this.state.files);
		this.setState({files: [], dataUri: null});
	};

	getImageDimensions = url => {
		const img = new Image();

		const promise = new Promise((resolve, reject) => {
			img.onload = () => {
				// Natural size is the actual image size regardless of rendering.
				// The 'normal' `width`/`height` are for the **rendered** size.
				const width = img.naturalWidth;
				const height = img.naturalHeight;

				// Resolve promise with the width and height
				resolve({width, height});
			};

			// Reject promise on error
			img.onerror = reject;
		});

		// Setting the source makes it start downloading and eventually call `onload`
		img.src = url;

		return promise;
	};

	convertImageToDataUri = file =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});

	render() {
		const {classes, acceptedFiles, filesLimit, placeholder} = this.props;

		const {minHeight, minWidth} = this.state;

		return (
			<div className={classes.root}>
				<MuiThemeProvider theme={DialogListTheme}>
					<Dialog
						id={'skill-filter'}
						maxWidth='lg'
						open={true}
						onClose={this.handleClose}
					>
						<DialogTitle onClose={this.handleClose}>
							<Typography
								style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
							>
								Add Image
							</Typography>
						</DialogTitle>

						<DialogContent className={classes.dialogContent}>
							{this.state.files.length === 0 && (
								<DropzoneArea
									dropzoneClass={classes.dropzone}
									dropzoneText={
										<span
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: '5px',
											}}
										>
											<Typography component={'span'}>
												Drag and Drop files here
											</Typography>
											<span style={{fontSize: '10px'}}>
												<b>Required: </b>
												<i>
													Color JPG, Minimum Image Size {minWidth}x{minHeight}
												</i>
											</span>
											{placeholder && (
												<span style={{fontSize: '10px'}}>
													<b>Recommended: </b>
													<i>{placeholder}</i>
												</span>
											)}
										</span>
									}
									acceptedFiles={acceptedFiles ? acceptedFiles : ['image/*']}
									showAlerts={false}
									showPreviews={false}
									showPreviewsInDropzone={false}
									showFileNamesInPreview={false}
									showFileNames={true}
									filesLimit={filesLimit != null ? filesLimit : 1}
									onChange={files => this.handleFilesChanged(files)}
									previewGridClasses={{
										container: classes.previewGridContainer,
										item: classes.previewGridItem,
										image: classes.previewGridImage,
									}}
								/>
							)}
							{this.state.files.length > 0 && (
								<div style={{maxWidth: '400px'}}>
									<img
										alt={'upload'}
										src={this.state.dataUri}
										edit
										style={{height: 'auto', width: '100%'}}
									></img>
									<IconButton
										onClick={() => this.handleDeleteImage([])}
										style={{
											position: 'absolute',
											right: '32px',
											marginTop: '8px',
											backgroundColor: '#ecf4f6',
										}}
									>
										<DeleteOutlineIcon />
									</IconButton>
								</div>
							)}
						</DialogContent>
						<DialogActions>
							{this.state.error && (
								<Typography color={'error'}>{this.state.error}</Typography>
							)}

							<Button
								onClick={() => this.handleConfirm()}
								variant={'contained'}
								color={'primary'}
								disabled={this.state.dataUri == null}
								startIcon={<AddOutlined />}
							>
								Add Image
							</Button>
						</DialogActions>
					</Dialog>
				</MuiThemeProvider>
			</div>
		);
	}
}

const styles = theme =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
			'& .MuiDropzoneArea-icon': {
				color: '#d1dee3',
			},
		},
		dropzone: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
			backgroundColor: theme.palette.background.default,
			borderTopColor: '#d1dee3',
			borderBottomColor: '#d1dee3',
			borderLeftColor: '#d1dee3',
			borderRightColor: '#d1dee3',
			'& svg': {
				color: '#d1dee3',
			},
		},
		previewGridContainer: {
			flex: '1 1 auto',
			width: '100%',
			margin: '0px',
			justifyContent: 'center',
			overflowY: 'auto',
			overflowX: 'hidden',
		},
		previewGridItem: {
			width: '200px',
			height: '200px',
			'& .MuiDropzonePreviewList-removeButton': {
				top: 0,
				right: 0,
			},
		},
		header: {
			flex: '0 0 auto',
		},
		dialogTitle: {
			fontSize: '20px',
			fontFamily: 'Archivo Bold',
		},
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
			minWidth: '450px',
			height: 'auto',
			padding: '16px 24px',
			'& .MuiDropzoneArea-icon': {
				color: '#d1dee3',
			},
		},
		actions: {
			flex: '0 0 auto',
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
	});

export default withStyles(styles)(ImageUpload);
