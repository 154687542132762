import React, {useState, useEffect} from 'react';
import {CREATE, withDataProvider, useDataProvider} from 'react-admin';
import {refreshView, translate} from 'ra-core';
import compose from 'recompose/compose';
import classnames from 'classnames';
import {connect} from 'react-redux';

import {Text, TextInput} from 'components';
import TSTheme from 'components/Theme';
import DialogTitle from 'components/dialogs/DialogTitle';
import LoadingInfo from 'components/loading/LoadingInfo';
import {GET_TRAINING_PACKAGE_INTEGRATION_STATUS} from 'services/customActions';
import {getUserInfo} from 'utils/Data';

import {
	Card,
	Checkbox,
	Tooltip,
	Typography,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	MenuItem,
	InputLabel,
	IconButton,
} from '@material-ui/core';
import {CheckCircle, Info, Link} from '@material-ui/icons';
import {createStyles, withStyles} from '@material-ui/core/styles';

import BrowserIcon from 'assets/MiniCardIcons/BrowserIcon.svg';
import UserHeadset from 'assets/MiniCardIcons/UserHeadset.svg';
import FadeOut from 'components/FadeOut';
import { copyToClipboard, loadObject } from 'utils/Util';

const styles = () =>
	createStyles({
		disabledDiv: {
			pointerEvents: 'none',
			opacity: '0.3',
		},
		iconText: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			fontFamily: 'Montserrat Bold',
		},
		infoIcon: {
			padding: '9px',
			fontSize: '40px',
			color: 'gray',
		},
		fields: {
			display: 'flex',
			alignItems: 'center',
			paddingBottom: '5px',
			'& > *:first-child': {
				'& > *:first-child': {
					marginBottom: '0 !important',
				},
			},
		},
	});

const ConfigurePackage = ({record, classes}) => {
	const tsaWebGLEnabled = loadObject('runwayOrgConfig')?.config?.tsaWebGLEnabled;

	const streamingEnabled = loadObject('runwayOrgConfig')?.config?.tsaStreamingEnabled;

	const dataProvider = useDataProvider();

	const [selectedOptions, setSelectedOptions] = useState([]);

	const [contentContext, setContentContext] = useState('selectMethod');

	const [isOpen, setIsOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [rxdApiPath, setRXDApiPath] = useState('');

	const [webPath, setWebPath] = useState('');

	const [rXDApiPathCopied, setRXDApiPathCopied] = useState(false);

	const [webPathCopied, setWebPathCopied] = useState(false);

		const options = [
		{
			displayName: 'VR Application',
			id: 'vr',
			icon: UserHeadset,
			isEnabled: true,
		},
		{
			displayName: 'Web Application',
			id: 'browser',
			icon: BrowserIcon,
			isEnabled:
				(tsaWebGLEnabled && !streamingEnabled) ||
				(streamingEnabled && !tsaWebGLEnabled) ||
				(tsaWebGLEnabled && streamingEnabled),
		},
	];

	useEffect(() => {
		if (isOpen) {
			fetchData();
		    setContentContext('selectMethod');
			setRXDApiPathCopied(false);
			setWebPathCopied(false);
		}
	}, [isOpen]);

	const fetchData = async () => {
		setIsLoading(true);
		await dataProvider(
			GET_TRAINING_PACKAGE_INTEGRATION_STATUS,
			'getPackageIntegrationStatus',
			{id: record.id}
		)
			.then(resp => {
				if (resp[0]) {
					const prevSelectedOptions =
						resp[0].deliveryOption === 'none'
							? ['vr']
							: Array.isArray(resp[0]?.deliveryOption)
							? resp[0].deliveryOption.filter(
									item => options.find(option => option.id === item)?.isEnabled
							  )
							: ['vr'];
					setSelectedOptions(prevSelectedOptions.length > 0 ? prevSelectedOptions : ['vr']);
				}
			})
			.catch(error => console.error(error))
			.finally(() => setIsLoading(false));
	};

	const handleGenerate = async () => {
		setIsLoading(true);

		await dataProvider(CREATE, 'updatePackageIntegrationStatus', {
			data: {
				appIds: [{appId: record.id, deliveryOption: selectedOptions}],
			},
		})
			.then(res => {
				if (res.data[0]) {
					setWebPath(res.data[0].webPath);
					setRXDApiPath(res.data[0].rxdApiPath);
				}
				setContentContext('showLinks');
			})
			.catch(error => console.error(error))
			.finally(() => setIsLoading(false));
	};

	const handleClick = e => {
		e.stopPropagation();
		setIsOpen(!isOpen);
	};

	const copy = url => {
		copyToClipboard(url);
	};

	const handleCheckboxChange = id => {
		let selected = [...selectedOptions];

		if (selected.includes(id)) selected = selected.filter(item => item !== id);
		else selected.push(id);

		setSelectedOptions(selected);
	};

	const copyWepPathLink = () => {
		copy(webPath);
		setWebPathCopied(true);
	}

	const copyRXDPathLink = () => {
		copy(rxdApiPath);
		setRXDApiPathCopied(true);
	}

	if (!getUserInfo().isSuper) {
		return null;
	}
	return (
		<div>
			<MenuItem onClick={handleClick}>{'Configure Package'}</MenuItem>
			<Dialog
				open={isOpen}
				onClose={handleClick}
				aria-label={record.name}
                maxWidth={"md"}
			>
				<DialogTitle onClose={handleClick}>
					<Text
						style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
						label={
							contentContext === 'selectMethod'
								? record.name
								: 'Package Configuration'
						}
					/>
				</DialogTitle>
				<DialogContent style={{ width: '750px'}}>
					{isLoading ? (
						<LoadingInfo />
					) : contentContext === 'selectMethod' ? (
						<>
							<Typography style={{padding: '20px 0'}}>
								<b>
									Select which delivery methods are available for the exported
									package
								</b>
								<br />
								This will configure the package with the select delivery methods
								available to any learner that launches the package from their
								LMS
							</Typography>

							<div
								style={{display: 'flex', paddingBottom: '20px', columnGap: '10px', justifyContent: 'center'}}
							>
								{options.map(option => {
									return (
										<Card
											key={option.id}
											style={{
												width: '180px',
												height: '150px',
												border: `2px ${
													selectedOptions.includes(option.id)
														? TSTheme.palette.primary.main
														: TSTheme.palette.secondary.main
												} solid`,
											}}
										>
											<div
												style={{
													display: 'flex',
												}}
											>
												{option.isEnabled && (
													<Checkbox
														size='small'
														checked={selectedOptions.includes(option.id)}
														onChange={() => handleCheckboxChange(option.id)}
														className={
															!option.isEnabled ? classes.disabledDiv : ''
														}
													/>
												)}

												{!option.isEnabled && option.id === 'browser' && (
													<Tooltip
														title={
															'Contact your administrator to enable Web Streaming for your Organization.'
														}
														placement='top'
													>
														<Info
															className={classes.infoIcon}
															style={{marginLeft: 'auto'}}
														/>
													</Tooltip>
												)}
											</div>
											<div
												className={classnames({
													[classes.iconText]: true,
													[classes.disabledDiv]: !option.isEnabled,
												})}
											>
												<img
													style={{width: '40px', height: '40px'}}
													src={option.icon}
													alt={`${option.name}-icon`}
												/>
												{option.displayName}
											</div>
											{!option.isEnabled && (
												<Typography
													style={{
														marginTop: '-46px',
														fontFamily: 'Montserrat Bold',
														justifyContent: 'center',
														display: 'flex',
													}}
												>
													Not Enabled
												</Typography>
											)}
										</Card>
									);
								})}
							</div>
						</>
					) : (
						<>
							<Typography style={{padding: '20px 0'}}>
								These are the paths to be copied over to the form in Content
								Controller
							</Typography>

							<InputLabel>Web Path</InputLabel>
							<div className={classes.fields}>
								<TextInput defaultValue={webPath} readOnly={false} placeholder='---' width={'525px'}/>
								<IconButton onClick={() => copyWepPathLink()}>
									<Link />
								</IconButton>
								{webPathCopied && 
									<FadeOut onHidden={() => setWebPathCopied(false)} id={'web-path'}>
										<div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
											<CheckCircle style={{ color: "#89c32d", marginRight: "5px" }} />
											<i style={{ fontFamily: "Montserrat Medium", paddingTop: "1px" }}>Link Copied</i>
										</div>
									</FadeOut>
                                }
							</div>
							<InputLabel>API Path</InputLabel>
							<div className={classes.fields}>
								<TextInput
									defaultValue={rxdApiPath}
									readOnly={false}
									placeholder='---'
									width={'525px'}
								/>
								<IconButton onClick={() => copyRXDPathLink()}>
									<Link />
								</IconButton>
								{rXDApiPathCopied && 
									<FadeOut onHidden={() => setRXDApiPathCopied(false)} id={'rxd-path'}>
										<div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
											<CheckCircle style={{ color: "#89c32d", marginRight: "5px" }} />
											<i style={{ fontFamily: "Montserrat Medium", paddingTop: "1px" }}>Link Copied</i>
										</div>
									</FadeOut>
								}
							</div>
						</>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClick}
						variant='contained'
						color={contentContext === 'selectMethod' ? 'secondary' : 'primary'}
						label={'Close'}
						style={{textTransform: 'none'}}
					>
						Close
					</Button>

					{contentContext === 'selectMethod' && (
						<Button
							onClick={handleGenerate}
							variant='contained'
							color='primary'
							label={'Generate Package'}
							style={{textTransform: 'none'}}
							disabled={selectedOptions.length < 1 || selectedOptions.includes('none') || isLoading}
						>
							Generate Package
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		form: state.form,
	};
}

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withStyles(styles),
	withDataProvider
);

export default enhance(ConfigurePackage);
