import React, {useState} from 'react';
import TSTheme from '../../components/Theme';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';

const SystemMessageComponent = () => {
	const systemMessage = JSON.parse(sessionStorage.getItem('project'))?.config
		?.systemMessage;
	const {isSystemMessageDismissed} =
		JSON.parse(localStorage.getItem('userData')) || false;
	const [showAlert, setShowAlert] = useState(
		systemMessage?.enabled &&
			(systemMessage?.target === 'all' || systemMessage?.target === 'runway')
	);

	const getSystemMessageSeverity = () => {
		const severityMatcherObj = {
			general: 'success',
			warning: 'warning',
			critical: 'error',
		};
		return severityMatcherObj[systemMessage?.type];
	};

	if (systemMessage != null && !isSystemMessageDismissed) {
		return (
			<Collapse in={showAlert} style={{width: '100%'}}>
				<ThemeProvider theme={CustomTheme}>
					<Alert
						severity={getSystemMessageSeverity()}
						variant='filled'
						icon={false}
						action={
							<IconButton
								aria-label='close'
								size='small'
								onClick={() => {
									setShowAlert(false);
									localStorage.setItem(
										'userData',
										JSON.stringify({
											isSystemMessageDismissed: true,
										})
									);
								}}
							>
								<CloseIcon />
							</IconButton>
						}
					>
						<div
							dangerouslySetInnerHTML={{
								__html: systemMessage?.message,
							}}
						/>
					</Alert>
				</ThemeProvider>
			</Collapse>
		);
	} else return <></>;
};

export const CustomTheme = createTheme({
	...TSTheme,
	overrides: {
		MuiAlert: {
			root: {
				borderRadius: 'none !important',
				padding: '0 24px !important',
			},
			filledSuccess: {
				justifyContent: 'center',
			},
			message: {
				padding: 'none !important',
				color: 'black',
				width: '100%',
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center',
			},
			action: {
				paddingLeft: 'none !important',
			},
		},
		MuiIconButton: {
			root: {
				borderRadius: 'none !important',
			},
			sizeSmall: {
				padding: '1px !important',
			},
		},
	},
});

export default SystemMessageComponent;
