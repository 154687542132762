import {
	EmbeddedShow,
	EmbeddedTabs,
	PerformanceDetail,
	ScreenTitle,
	SelectInput,
	StatsTilePanel,
	StatusChip,
	TextValue,
	TimeSinceField,
	SkillGraphPanel,
} from 'components';
import {GET_LIST, useQueryWithStore, withDataProvider} from 'react-admin';
import React, {useEffect, useState} from 'react';

import LabDetail from './Lab/LabDetail';
import LabList from './Lab/LabList';
import LessonDetail from './Lesson/LessonDetail';
import LessonList from './Lesson/LessonList';
import LoadingList from '../loading/LoadingList';
import LoadingStat from '../loading/LoadingStat';
import MUIButton from '@material-ui/core/Button';
import Skills from './Skills';
import TrainingCatalogue from '../TrainingCatalogue';
import compose from 'recompose/compose';
import {getOrgId, loadObject} from '../../utils/Util';
import EmailIcon from 'assets/EmailIcon';
import { getUserInfo } from "utils/Data";
import { GET } from "services/customActions";

const TrainingDetail = props => {
	const {
		backTrainingAction,
		selectedTrainingRecord,
		teamBackAction,
		selectedLessonRecord,
		dataProvider,
		userData,
		selectedLab,
		selectedStep,
		teamData,
	} = props;

	const [state, setState] = useState({});

	const [isPlayable, setIsPlayable] = useState(false);

	const summaryFriendData = loadObject(loadObject('project').id + '-summaryFriendData');

	const isTester = summaryFriendData?.isTester ? true : false;

	useEffect(() => {
		fetch();
		isTSAPlayable();
	}, []);

	const isTSAPlayable = async () => {
		if (
			selectedTrainingRecord && selectedTrainingRecord.accessibility.licensed.value &&
			selectedTrainingRecord.isCPD &&
			(selectedTrainingRecord.trainingDetail.moduleStatus === 'Published' ||
				getUserInfo().trainingModuleEditor ||
				isTester)
		) {
			await dataProvider(GET, 'isTSAPlayable', { appKeyName: selectedTrainingRecord?.appKeyName }).then((res) => {
				if (res.success) {
					setIsPlayable(true);
				} else {
					setIsPlayable(false);
				}
			}).catch(e => {
				console.error(e);
				setIsPlayable(false);
			})
		} else {
			setIsPlayable(false);
		}
	}

	const {data: trainingData, loading: trainingDataLoading} = useQueryWithStore({
		type: 'getOne',
		resource: 'trainingsforuser',
		payload: {
			id: selectedTrainingRecord.id,
			filter: {
				userId: props.id,
				pagination: {page: 1, perPage: 1},
				sort: {},
				orgId: getOrgId()
			},
		},
	});

	const {data: usertrainingstats, loading: userStatsLoading} = useQueryWithStore({
		type: GET_LIST,
		resource: selectedTrainingRecord.labDisplayEnabled
			? 'usertrainingLabstats'
			: 'usertrainingstats',
		payload: {
			filter: {
				id: props.id,
				userId: props.id,
				trainingId: selectedTrainingRecord.id,
			},
			pagination: {},
			sort: {},
		},
	});

	const getPerformanceComp = data => {
		if (!data) return [];
		let assessments = data.map(elem => {
			let choices, selectedKey, selectedAttemptData, createdAt;

			if (elem.lessonAttempts.length > 0) {
				choices = elem.lessonAttempts.map((attempt, index) => {
					let formattedTime = new Date(attempt.createdAt)
						.toLocaleString()
						.replace(',', '')
						.replace(/:\d{2}\s/, ' ');
					let prefix = 'Attempt ' + ++index;
					let value = attempt.bestAttempt
						? prefix.concat(' (Best)- ', formattedTime)
						: prefix.concat(' - ', formattedTime);
					let result = {id: attempt.createdAt, name: value};
					if (attempt.bestAttempt) {
						result.selected = true;
						selectedKey = attempt.createdAt;
					}
					return result;
				});
				selectedAttemptData = elem.lessonAttempts.find(
					attempt => attempt.bestAttempt === true
				);
			} else {
				choices = [
					{
						id: 'No attempts',
						name: 'No attempts',
						selected: true,
					},
				];
				selectedAttemptData = elem;
				selectedKey = 'No attempts';
			}
			let lessonAttempts = elem.lessonAttempts;
			createdAt = elem.createdAt;
			let title = elem.title;
			return {
				choices,
				selectedKey,
				selectedAttemptData,
				lessonAttempts,
				createdAt,
				title,
			};
		});
		return assessments;
	};

	const fetch = async () => {
		const data = await dataProvider(GET_LIST, 'userassessmentperformance', {
			filter: {
				id: props.id,
				userId: props.id,
				trainingId: selectedTrainingRecord.id,
			},
			pagination: {},
			sort: {},
		});
		const performanceAttempts = getPerformanceComp(data.data);
		setState({...state, performanceAttempts});
	};

	const getPerformanceAttempts = () =>
		state.performanceAttempts ? state.performanceAttempts : [];

	const handleChange = (event, index) => {
		let attempts = [...state.performanceAttempts];
		let selectedAttempt = {...attempts[index]};
		selectedAttempt.selectedKey = event.target.value;

		const lessonAttempt = selectedAttempt.lessonAttempts.find(
			attempt => attempt.createdAt === event.target.value
		);
		selectedAttempt.selectedAttemptData = lessonAttempt;
		attempts[index] = selectedAttempt;
		setState({...state, performanceAttempts: attempts});
	};

	const changeSelectChoiceFromGraph = (lessonAttempts, value) => {
		let attempts = [...state.performanceAttempts];
		let attemptIdx = attempts.findIndex(
			item =>
				JSON.stringify(item.lessonAttempts) === JSON.stringify(lessonAttempts)
		);

		let selectedAttempt = {...attempts[attemptIdx]};
		selectedAttempt.selectedKey = value;

		const lessonAttempt = selectedAttempt.lessonAttempts.find(
			attempt => attempt.createdAt === value
		);
		selectedAttempt.selectedAttemptData = lessonAttempt;
		attempts[attemptIdx] = selectedAttempt;
		setState({...state, performanceAttempts: attempts});
	};

	return (
		<div>
			{!(selectedLessonRecord || selectedLab || selectedStep) && (
				<div>
					<ScreenTitle
						title={selectedTrainingRecord.name}
						refresh={false}
						subTitle={true}
						coloredCrumb={true}
						backActionCrumbs={
							teamBackAction
								? [teamBackAction, backTrainingAction]
								: [backTrainingAction]
						}
						titleCrumbs={
							teamData
								? [
									teamData.name,
										(userData.name &&
											userData.name.trim().length > 0 &&
											userData.name) ||
											userData.userId,
								  ]
								: [
										(userData.name &&
											userData.name.trim().length > 0 &&
											userData.name) ||
											userData.userId,
								  ]
						}
						actions={[
							{
								render: () => {
									return (
										<MUIButton
											width={'148px'}
											variant={'outlined'}
											color={'primary'}
											style={{backgroundColor: '#ffffff'}}
											onClick={() =>
												(window.location.href = `mailto:${userData.contactEmail}`)
											}
											startIcon={<EmailIcon style={{ fontSize: '18px', marginBottom: '2px' }}/>}
										>
											Send Reminder Email
										</MUIButton>
									);
								},
							},
						]}
					/>
					{(trainingData && !trainingDataLoading) ? (
						<TrainingCatalogue
							trainingData={trainingData}
							isExpandMore={
								loadObject('expandUserTrainingcatalogue') === null
									? false
									: loadObject('expandUserTrainingcatalogue')
							}
							toggleKey={'expandUserTrainingcatalogue'}
							isTSAPlayable={isPlayable}
							information={
								<EmbeddedShow
									embedded
									headerless
									title={'Information'}
									details={[
										{
											label: 'Name',
											render: record => (
												<TextValue
													record={trainingData}
													source='name'
													maxWidth={'300px'}
												/>
											),
										},
										{
											label: 'Status',
											render: record => (
												<StatusChip record={trainingData} source='status' />
											),
										},
										{
											label: 'Last Login',
											render: record => (
												<TimeSinceField
													record={trainingData}
													source='lastLogin'
												/>
											),
										},
										{
											label: 'Id',
											render: () => (
												<TextValue record={trainingData} source='id' />
											),
										},
									]}
								/>
							}
						></TrainingCatalogue>
					) : (
						<LoadingList />
					)}
					<div style={{paddingTop: '24px'}}>
						{(usertrainingstats && !userStatsLoading) ? (
							<StatsTilePanel stats={usertrainingstats} />
						) : (
							<LoadingStat />
						)}
					</div>

					<EmbeddedTabs
						data={{
							tabs: [
								{
									name:
										selectedTrainingRecord &&
										selectedTrainingRecord.labDisplayEnabled
											? 'Labs'
											: 'Lessons',
									content:
										selectedTrainingRecord &&
										selectedTrainingRecord.labDisplayEnabled ? (
											<LabList {...props} />
										) : (
											<LessonList {...props} />
										),
								},
								{
									name: 'Skills',
									content: <Skills {...props} />,
								},
								{
									name: 'Performance',
									hidden:
										getPerformanceAttempts() == null ||
										getPerformanceAttempts().length === 0,
									content: (
										<div>
											<div
												style={{backgroundColor: 'white', marginBottom: '12px'}}
											>
												<div
													id='tabHeader'
													style={{
														padding: '16px 16px 16px 12px',
														fontSize: '20px',
														fontFamily: 'Archivo Bold',
													}}
												>
													{selectedTrainingRecord.name} - Performance
												</div>
											</div>
											{getPerformanceAttempts().map(
												(performanceComp, index) => (
													<div style={{marginTop: '24px'}} key={index}>
														<PerformanceDetail
															key={performanceComp.selectedAttemptData.title}
															header={
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'row',
																		justifyContent: 'space-between',
																	}}
																>
																	<span style={{fontFamily: 'Archivo Bold'}}>
																		{performanceComp.selectedAttemptData.title}
																	</span>
																	<div>
																		<SelectInput
																			choices={performanceComp.choices}
																			value={
																				performanceComp.selectedKey
																					? performanceComp.selectedKey
																					: ''
																			}
																			disabled={
																				performanceComp.selectedKey ===
																				'No Attempts'
																			}
																			onChange={event => {
																				handleChange(event, index);
																			}}
																			width='100%'
																		/>
																	</div>
																</div>
															}
															assessment={performanceComp.selectedAttemptData}
															{...props}
														/>
														{performanceComp?.lessonAttempts?.length > 0 && (
															<SkillGraphPanel
																unprocessedData={performanceComp.lessonAttempts}
																selectedAttemptValue={
																	performanceComp.selectedKey
																}
																fromComponentFlag={'PerformanceDetailView'}
																attemptChangeHandler={
																	changeSelectChoiceFromGraph
																}
																preAssessmentDate={performanceComp.createdAt}
																title={performanceComp.title}
															/>
														)}
													</div>
												)
											)}
										</div>
									),
								},
							],
						}}
					/>
				</div>
			)}

			{selectedLessonRecord && <LessonDetail {...props} />}

			{selectedLab && <LabDetail {...props} />}
		</div>
	);
};

export default compose(withDataProvider)(TrainingDetail);
