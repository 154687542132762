import React, {useCallback, useEffect, useRef, useState} from 'react';
import {fetchEnd, fetchStart, useDataProvider, useVersion, CREATE} from 'react-admin';
import {Redirect} from 'react-router-dom';
import {EmbeddedTabs} from 'components';
import Information from './Information';
import Tools from './Tools/Tools';
import Training from './Training/Training';
import Users from './Users/User';
import compose from 'recompose/compose';
import {getUserInfo} from 'utils/Data';
import {safeRequestData} from '../../../utils/Data';
import { useDispatch } from 'react-redux';
import { refreshView, withDataProvider } from 'ra-core';
import {connect} from 'react-redux';
import { useSelector } from 'react-redux';
import { GET } from 'services/customActions';

const TeamShow = props => {
	const {id} = props;

	const [state, setState] = useState({
		teamData: null,
		isLoading: true,
	});

	const [isPolling, setIsPoling] = useState(false);

	const [isVisible, setIsVisible] = useState(false);

	const [refreshClicked, setRefreshClicked] = useState(false);

	const isMounted = useRef(true);

	const isLoading = useSelector(state => state.admin.loading > 0);

	const dataProvider = useDataProvider();
	
	const dispatch = useDispatch();

	useEffect(() => {
	  let timeoutId;
  
	  if (isVisible) {
		timeoutId = setTimeout(() => {
		  setIsVisible(false);
		}, 6000);
	  }
  
	  return () => {
		clearTimeout(timeoutId);
	  };
	}, [isVisible]);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (!isLoading && refreshClicked) {
			updateGroupSummaryStartBatch();
		}
	  }, [isLoading, refreshClicked]);

	useEffect(() => {
		if (isPolling) {
		  const intervalId = setInterval(() => {
			  dataProvider(GET,'getTeamOperationStatus', { teamId: id })
			  .then(res => {
				  if (!res.data.response.data.inProgress) {
					  dispatch(fetchEnd());
					  setIsVisible(true);
					  props.refreshView();
					  setIsPoling(false);
					  clearInterval(intervalId);
				}
			  });
		  }, 10000);
	
		  return () => {
			  dispatch(fetchEnd());
			  clearInterval(intervalId);
		  }
		}
	  }, [isPolling]);
  
	  useEffect(() => {
		  startPolling(false);
	  }, []);

	  
	const updateGroupSummaryStartBatch = async () => {
		setRefreshClicked(false);
		if (await startPolling(true)) return;
        setIsPoling(true);
		await dataProvider(CREATE, 'updateGroupSummaryStartBatch', {
			data: {
				teamId: id,
				deleteAllEntities: false,
				isLastTeam: true,
			},
		})
			.then(() => {
				startPolling();
			})
			.catch(e => {
				console.log(e);
				setIsPoling(false);
			});
	};

	const startPolling = async returnPollingStatus => {
		let isPolling = false;
		const res = await dataProvider(GET, 'getTeamOperationStatus', { teamId: id });
		if (!res.data.response.data.inProgress) {
			setIsPoling(false);
			dispatch(fetchEnd());
			return false;
		} else {
			setIsPoling(true);
			isPolling = true;
		}
		if (returnPollingStatus) return isPolling;
	};

	const fetchData = useCallback(async () => {
		setState(state => ({ ...state, isLoading: true }));
		const requestList = [{resource: 'teams', id: id, dataAlias: 'teamData'}];

		try {
			dispatch(fetchStart());
			for (let i = 0; i < requestList.length; i++) {
				const request = requestList[i];
				await safeRequestData({
					dataRequest: request,
					dataProvider: dataProvider,
					isMounted: isMounted,
					setState: setState,
				});
			}
	
			setState(state => ({
				...state,
				isLoading: false,
			}));
		} finally {
			dispatch(fetchEnd());
		}
	}, [dataProvider]);

	const version = useVersion();

	const onUpdateTeamDetails = async () => {
		setState({...state, isLoading: true});
		await fetchData();
	};

	useEffect(() => fetchData(), [version]);

	if (!getUserInfo()?.isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: '/login',
					state: {from: props.location},
				}}
			/>
		);
	}

	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to='/termsOfService' />;
	// }

	return (
		<div>
			<Information
				{...props}
				teamData={state.teamData}
				isPolling={isPolling}
				isTeamDataLoading={state.isLoading}
				onUpdateTeamDetails={onUpdateTeamDetails}
				showProcessCompleted={isVisible}
				updateTeamData={() => {
					setRefreshClicked(true);
		            dispatch(refreshView());
				}}
			/>

			<EmbeddedTabs
				data={{
					tabs:
						getUserInfo().isAdmin || getUserInfo().isSuper
							? [
									{
										name: 'Users',
										content: <Users {...props} teamData={state.teamData} startPolling={() => startPolling()}/>,
									},
									{
										name: 'Modules',
										content: <Training {...props} teamData={state.teamData} startPolling={() => startPolling()}/>,
									},
									{name: 'Tools', content: <Tools {...props} teamData={state.teamData} />},
							  ]
							: [
									{
										name: 'Users',
										content: <Users {...props} teamData={state.teamData} startPolling={() => startPolling()}/>,
									},
									{
										name: 'Modules',
										content: <Training {...props} teamData={state.teamData} startPolling={() => startPolling()}/>,
									},
							  ],
				}}
			/>
		</div>
	);
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	withDataProvider, connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(TeamShow);
