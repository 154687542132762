import * as React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {DiagramEngine} from '@projectstorm/react-diagrams-core';

import {CustomLinkModel} from './CustomLinkModel';
import CustomLinkWidget from './CustomLinkWidget';

export class CustomLinkFactory<Link extends CustomLinkModel = CustomLinkModel> extends AbstractReactFactory<Link, DiagramEngine> {
    constructor(type = 'custom') {
        super(type);
    }

    generateReactWidget(event): JSX.Element {
        return <CustomLinkWidget link={event.model} diagramEngine={this.engine} />;
    }

    generateModel(event): Link {
        return new CustomLinkModel() as Link;
    }

    generateLinkSegment(model: Link, path: string) {
        return <g>
            <path stroke={'white'} strokeWidth={model.getOptions().width} strokeOpacity={1.0} fill={'none'} d={path} />
            <path stroke={model.getOptions().color} strokeWidth={model.getOptions().width} strokeOpacity={model.getOptions().opacity} fill={'none'} d={path} />
        </g>;
    }
}
