import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import {withStyles} from '@material-ui/core/styles';

export const DialogTitle = withStyles(theme => ({
	root: {
		display: 'inline',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(),
	},
	close: {
		fontWeight: 900,
	},
	warning: {
		color: '#FFC100',
		marginRight: '12px',
	},
	error: {
		color: '#F14242',
		marginRight: '12px',
	},
	info: {
		color: '#99CCFF',
		marginRight: '12px',
	},
	divider: {
		backgroundColor: theme.palette.borderColor.divider,
		marginTop: '12px',
	},
	mainTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	subTitle: {
		fontSize: '14px',
	},
}))(props => {
	const {children, classes, warning, error, info, onClose, subTitle} = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
			<div className={classes.mainTitle}>
				{warning ? <WarningIcon className={classes.warning} /> : null}
				{error ? <ErrorIcon className={classes.error} /> : null}
				{info ? <InfoIcon className={classes.info} /> : null}
				{children}
				{onClose ? (
					<IconButton
						aria-label='Close'
						className={classes.closeButton}
						onClick={onClose}
					>
						<CloseIcon className={classes.close} />
					</IconButton>
				) : null}
			</div>
			<div className={classes.subTitle}>{subTitle}</div>
		</MuiDialogTitle>
	);
});

export default DialogTitle;
