import { DataOptions } from "components/VectorMap/OptionsBlade";

/**
 * Define an enum to represent the various levels of "Availability".
 */
export enum Availability {
    FUTURE = 'future',
    AVAILABLE = 'available',
    LICENSED = 'licensed',
    ASSIGNED = 'assigned'
}

/**
 * Determines the "real" availability based on the state of the various accessibility flags.
 * Different accessibility flags are prioritized in a specific order to determine the appropriate "Availability".
 * 
 * @param data 
 */
export const determineAvailability = (data: any): Availability | null => {
    let result: Availability | null = null;

    if (data && data.accessibility) {
        if (data.accessibility.future && data.accessibility.future.value === true) result = Availability.FUTURE;
        if (data.accessibility.available && data.accessibility.available.value === true) result = Availability.AVAILABLE;
        if (data.accessibility.licensed && data.accessibility.licensed.value === true) result = Availability.LICENSED;
        if (data.accessibility.assigned && data.accessibility.assigned.value === true) result = Availability.ASSIGNED;
    }

    return result;
}

/**
 * Generates a human readable representation of the supplied "Availability".
 * 
 * @param availability 
 */
export const humanizeAvailability = (availability?: Availability | null) => {
    switch (availability) {
        case Availability.FUTURE:
            return "Coming Soon";
        case Availability.AVAILABLE:
            return "Available";
        case Availability.LICENSED:
            return "Licensed";
        case Availability.ASSIGNED:
            return "Assigned";
        default:
            return null;
    }
}

/**
 * Generates a sort function for sorting by "Availability".
 * 
 * @param reverse 
 * @param isGraphNode 
 */
export const sortByAvailability = (reverse?: boolean, isGraphNode?: boolean) => {
    return (a: any, b: any) => {
        const aRecord = isGraphNode && a ? a.record : a;
        const bRecord = isGraphNode && b ? b.record : b;

        const aAvailability = determineAvailability(aRecord);
        const bAvailability = determineAvailability(bRecord);

        if (aAvailability != null && bAvailability == null) return -1;
        else if (aAvailability == null && bAvailability != null) return 1;
        else if (aAvailability == null && bAvailability == null) return 0;
        else {
            const aIdx = Object.keys(Availability).indexOf(aAvailability.toUpperCase());
            const bIdx = Object.keys(Availability).indexOf(bAvailability.toUpperCase());

            if (aIdx > bIdx) return reverse ? -1 : 1;
            else if (aIdx < bIdx) return reverse ? 1 : -1;
            else return 0;
        }
    };
}

/**
 * Define an enum to represent the various levels of "ProgressStatus".
 */
export enum ProgressStatus {
    NOT_STARTED = 'not started',
    INCOMPLETE = 'incomplete',
    COMPLETED = 'completed',
    FAILED = 'failed',
    PASSED = 'passed',
    ACED = 'aced'
}

/**
 * Determines the "real" progress status.
 * 
 * @param data 
 */
export const determineProgressStatus = (data: any): ProgressStatus | null => {
    let result: ProgressStatus | null = null;

    if (data && data.status) {
        if (data.status === 'not started') result = ProgressStatus.NOT_STARTED;
        if (data.status === 'incomplete') result = ProgressStatus.INCOMPLETE;
        if (data.status === 'completed') result = ProgressStatus.COMPLETED;
        if (data.status === 'failed') result = ProgressStatus.FAILED;
        if (data.status === 'passed') result = ProgressStatus.PASSED;
        if (data.status === 'aced') result = ProgressStatus.ACED;
    }

    return result;
}

/**
 * Generates a human readable representation of the supplied "ProgressStatus".
 * 
 * @param availability 
 */
export const humanizeProgressStatus = (status?: ProgressStatus | null) => {
    switch (status) {
        case ProgressStatus.NOT_STARTED:
            return "Not Started";
        case ProgressStatus.INCOMPLETE:
            return "Incomplete";
        case ProgressStatus.COMPLETED:
            return "Completed";
        case ProgressStatus.FAILED:
            return "Failed";
        case ProgressStatus.PASSED:
            return "Passed";
        case ProgressStatus.ACED:
            return "Aced";
        default:
            return null;
    }
}

/**
 * Generates a sort function for sorting by "ProgressStatus".
 * 
 * @param reverse 
 * @param isGraphNode 
 */
export const sortByProgressStatus = (reverse?: boolean, isGraphNode?: boolean) => {
    return (a: any, b: any) => {
        const aRecord = isGraphNode && a ? a.record : a;
        const bRecord = isGraphNode && b ? b.record : b;

        const aProgressStatus = determineProgressStatus(aRecord);
        const bProgressStatus = determineProgressStatus(bRecord);

        if (aProgressStatus != null && bProgressStatus == null) return -1;
        else if (aProgressStatus == null && bProgressStatus != null) return 1;
        else if (aProgressStatus == null && bProgressStatus == null) return 0;
        else {
            const aIdx = Object.keys(ProgressStatus).indexOf(aProgressStatus.toUpperCase());
            const bIdx = Object.keys(ProgressStatus).indexOf(bProgressStatus.toUpperCase());

            if (aIdx > bIdx) return reverse ? -1 : 1;
            else if (aIdx < bIdx) return reverse ? 1 : -1;
            else return 0;
        }
    };
}

/**
 * Determine the maximum number of lessons provided by any training.
 * 
 * @param data 
 */
export const determineMaxLessons = (data: Array<any>) => {
    let result = 0;

    data.forEach(item => {
        const lessons = Number.parseInt(item.lessons);

        if (!Number.isNaN(lessons) && lessons > result) {
            result = lessons;
        }
    });

    return result;
}

/**
 * Determine the maximum number of attempts provided by any training.
 * 
 * @param data 
 */
export const determineMaxAttempts = (data: Array<any>) => {
    let result = 0;

    data.forEach(item => {
        const attempts = Number.parseInt(item.attempts);

        if (!Number.isNaN(attempts) && attempts > result) {
            result = attempts;
        }
    });

    return result;
}

/**
 * Determine the maximum total skill points provided by any training.
 * 
 * @param data 
 */
export const determineMaxTotalSkillPoints = (data: Array<any>) => {
    let result = 0;

    data.forEach(item => {
        const totalSkillPoints = Number.parseInt(item.totalSkillPoints);

        if (!Number.isNaN(totalSkillPoints) && totalSkillPoints > result) {
            result = totalSkillPoints;
        }
    });

    return result;
}

/**
 * Determine the maximum time spent provided by any training.
 * 
 * @param data 
 */
export const determineMaxTimeSpent = (data: Array<any>) => {
    let result = 0;

    data.forEach(item => {
        const timeSpent = Number.parseInt(item.timeSpent);

        if (!Number.isNaN(timeSpent) && timeSpent > result) {
            result = timeSpent;
        }
    });

    return result;
}

/**
 * Determine the maximum avg time spent provided by any training.
 * 
 * @param data 
 */
export const determineMaxTotalTimeSec = (data: Array<any>) => {
    let result = 0;

    data.forEach(item => {
        const totalTimeSec = Number.parseInt(item.totalTimeSec);

        if (!Number.isNaN(totalTimeSec) && totalTimeSec > result) {
            result = totalTimeSec;
        }
    });

    return result;
}

/**
 * Determine if the current grouping is via Availability.
 * 
 * @param options 
 */
export const isGroupingByAvailability = (options: DataOptions) => {
    const groupOption = options.group;

    if (groupOption && 'availability' === groupOption.value) return true;

    return false;
};

/**
 * Determine if the current grouping is via Progress Status.
 * 
 * @param options 
 */
export const isGroupingByProgressStatus = (options: DataOptions) => {
    const groupOption = options.group;

    if (groupOption && 'status' === groupOption.value) return true;

    return false;
};

/**
 * Determine if the current grouping is via Score / Avg Score.
 * 
 * @param options 
 */
export const isGroupingByScore = (options: DataOptions) => {
    const groupOption = options.group;

    if (groupOption && 'score' === groupOption.value) return true;

    return false;
};

/**
 * Determine if the current grouping is via Time Spent / Avg Time Spent.
 * 
 * @param options 
 */
export const isGroupingByTimeSpent = (options: DataOptions) => {
    const groupOption = options.group;

    if (groupOption && 'time-spent' === groupOption.value) return true;

    return false;
};

/**
 * Determine the appropriate heatmap colors for the supplied value.
 * 
 * @param userValue 
 * @param maxValue 
 */
export const determineHeatmapNodeColorByPercentage = (userValue: number | null, maxValue: number | null) => {
    const percentage = userValue != null && maxValue != null && !isNaN(userValue) && !isNaN(maxValue)
        ? userValue < 0
            ? -1
            : maxValue === 0
                ? 0
                : (userValue / maxValue) * 100
        : 0;

    if (percentage < 0) return '#EE0000';
    else if (percentage === 0) return '#878787';
    else if (percentage <= 10) return '#ABD537';
    else if (percentage > 10 && percentage <= 20) return '#97C521';
    else if (percentage > 20 && percentage <= 30) return '#92BC1F';
    else if (percentage > 30 && percentage <= 40) return '#76AC1F';
    else if (percentage > 40 && percentage <= 50) return '#54911C';
    else if (percentage > 50 && percentage <= 60) return '#4A7E19';
    else if (percentage > 60 && percentage <= 70) return '#37670F';
    else if (percentage > 70 && percentage <= 80) return '#29540A';
    else if (percentage > 80 && percentage <= 90) return '#234707';
    else return '#203E04';
}

/**
 * Determine the appropriate heatmap colors for the supplied value.
 * 
 * @param value 
 */
export const determineHeatmapNodeColorByBoolean = (value: boolean) => {
    if (value) return '#B91C06';
    else return '#878787';
}
