import {createStyles, withStyles} from '@material-ui/core';

import {Filter} from 'react-admin';
import React from 'react';

const myStyles = theme =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			margin: '0px',
		},
		title: {
			flex: '0 0 auto',
			marginRight: '1em',
		},
		filter: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			margin: '0px',
		},
	});

class TableFilters extends Filter {
	render() {
		const {classes, filterStyles, children, ...props} = this.props;

		return (
			<div className={classes.root}>
				{props.title && (
					<label className={classes.title}>
						<b>{props.title}</b>
					</label>
				)}

				<Filter className={classes.filter} style={filterStyles} {...props}>
					{children}
				</Filter>
			</div>
		);
	}
}

export default withStyles(myStyles)(TableFilters);
