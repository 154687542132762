import React, { Children, cloneElement, isValidElement } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

/**
 * Dropdown action menu found in lists.
 */
class ActionMenu extends React.Component {
	state = {
		open: false,
		anchorEl: null,
	};

	handleOpen = e => {
		if (!e) return;

		e.stopPropagation();

		this.setState({ open: true, anchorEl: e.target });
	};

	handleClose = e => {
		if (!e) return;

		e.stopPropagation();

		this.setState({ open: false, anchorEl: null });
	};

	render() {
		const { children, classes, label } = this.props;
		const { open, anchorEl } = this.state;

		return (
			<div className={classes.root}>
				<Button className={open ? classes.menuButtonActive : classes.menuButton} onClick={this.handleOpen}>
					{label && label !== '...' ? label : <MoreHorizIcon color={'primary'} />}
				</Button>

				<Popover
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					onClose={this.handleClose}
				>
					<List className={classes.menuList}>
						{Children.map(children, menuItem => {
							if (isValidElement(menuItem)) {
								return cloneElement(menuItem, {
									onClick: e => {
										this.handleClose(e);
										if (
											menuItem.onClick != null &&
											typeof menuItem.onClick === 'function'
										)
											menuItem.onClick();
									},
								});
							} else {
								return null;
							}
						})}
					</List>
				</Popover>
			</div>
		);
	}
}

ActionMenu.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
};

const myStyles = theme =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-end',
		},
		menuButton: {
			minWidth: '34px',
			maxWidth: '34px',
			width: '34px',
			minHeight: '34px',
			maxHeight: '34px',
			height: '34px',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderRadius: '5px',
			borderColor: theme.palette.borderColor.default,
			backgroundColor: theme.palette.background.default,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			'& > span': {
				flex: '1 1 auto',
				minWidth: '34px',
				maxWidth: '34px',
				width: '24px',
				minHeight: '34px',
				maxHeight: '34px',
				height: '34px',
				padding: '5px',
				'&:hover': {
					backgroundColor: theme.palette.primary.main,
					borderRadius: '5px',
					'& > svg': {
						color: '#ccc',
					}
				},
			},
		},
		menuButtonActive: {
			minWidth: '34px',
			maxWidth: '34px',
			width: '34px',
			minHeight: '34px',
			maxHeight: '34px',
			height: '34px',
			borderRadius: '5px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: theme.palette.primary.main,
			border: 'none',
			'& > span': {
				flex: '1 1 auto',
				minWidth: '34px',
				maxWidth: '34px',
				width: '24px',
				minHeight: '34px',
				maxHeight: '34px',
				height: '34px',
				padding: '5px',
				'& > svg': {
					color: '#fff',
				}
			},
		},
		menuList: {
			padding: '0px',
		},
	});

const enhance = compose(withStyles(myStyles));

export default enhance(ActionMenu);
