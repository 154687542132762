import {EmbeddedList} from 'components';
import {NumberField, useTranslate} from 'react-admin';
import PercentField from 'components/PercentField';
import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import SkillIcon from 'assets/skillIcons';

const PerformanceDetail = props => {
	const theme = useTheme();
	const {assessment, header} = props;

	const translate = useTranslate();

	if (assessment) {
		return (
			<div
				style={{
					marginBottom: '20px',
				}}
			>
				<EmbeddedList
					key={assessment.title}
					embedded={false}
					resource={assessment.namedSkills}
					headerNode={header}
					columns={[
						{
							id: 'displayName',
							name: (
								<span style={{fontWeight: 'bold'}}>{translate('Skill')}</span>
							),
							sortable: false,
							align: 'left',
							render: record => {
								return (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										{record.id !== 'Overall' && (
											<SkillIcon record={record} height={40} width={40}/>
										)}
										<span
											style={{
												fontWeight: record.id === 'Overall' ? 'bold' : 'normal',
												marginLeft: record.id === 'Overall' ? '0px' : '10px',
											}}
										>
											{record.displayName}
										</span>
									</div>
								);
							},
						},
						{
							id: 'preAssessment',
							name: translate('Initial Performance Score'),
							sortable: false,
							align: 'center',
							render: record => (
								<span>
									<span
										style={{
											color:
												record.id === 'Overall'
													? theme.palette.primary.main
													: 'inherit',
											fontSize: '1.2em',
											fontWeight: 'bolder',
										}}
									>
										{record.preScoreNumerator}
									</span>{' '}
									/{' '}
									<span style={{color: 'inherit'}}>
										{record.scoreDenominator}
									</span>
								</span>
							),
						},
						{
							id: 'postAssessment',
							name: translate('Final Performance Score'),
							sortable: false,
							align: 'center',
							render: record => (
								<span>
									<span
										style={{
											color:
												record.id === 'Overall'
													? theme.palette.primary.main
													: 'inherit',
											fontSize: '1.2em',
											fontWeight: 'bolder',
										}}
									>
										{record.postScoreNumerator}
									</span>{' '}
									/{' '}
									<span style={{color: 'inherit'}}>
										{record.scoreDenominator}
									</span>
								</span>
							),
						},
						{
							id: 'change',
							name: translate('Change'),
							sortable: false,
							align: 'right',
							render: record => (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'flex-end',
									}}
								>
									<div
										style={{
											textAlign: 'right',
											flex: '0 0 auto',
											minWidth: '40px',
											maxWidth: '40px',
										}}
									>
										<NumberField
											style={{
												color: record.changeColour,
												fontSize: '1.2em',
												fontWeight: 'bolder',
											}}
											record={record}
											source='changeValue'
										/>
									</div>
									&nbsp;
									<div
										style={{
											textAlign: 'right',
											flex: '0 0 auto',
											minWidth: '40px',
											maxWidth: '40px',
										}}
									>
										<PercentField
											style={{
												color: record.changeColourPercent
													? record.changeColourPercent
													: record.changeColour,
											}}
											record={record}
											source='changePercent'
										/>
									</div>
								</div>
							),
							isInverted: true,
						},
					]}
					sort={{field: 'name', order: 'ASC'}}
					stripped={false}
				/>
			</div>
		);
	} else {
		return null;
	}
};

export default PerformanceDetail;
