import { MouseEvent } from 'react';
import { SelectingState, State, Action, InputType, ActionEvent, DragCanvasState } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams';

export class CustomDiagramState extends State<DiagramEngine> {
	dragCanvas: DragCanvasState;

	constructor() {
		super({
			name: 'custom-diagram-state'
		});
		this.childStates = [new SelectingState()];
		this.dragCanvas = new DragCanvasState();

		// determine what was clicked on
		this.registerAction(
			new Action({
				type: InputType.MOUSE_DOWN,
				fire: (event: ActionEvent<MouseEvent>) => {
                    this.transitionWithEvent(this.dragCanvas, event);
				}
			})
		);
	}
}
