import {createStyles} from '@material-ui/core/styles';

const style = theme =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			padding: '1em',
			position: 'relative',
		},
		pageButtons: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'nowrap',
			alignItems: 'center',
			marginTop: '0.5em',
			zIndex: '1',
		},
		prevPageButton: {
			minHeight: '28px',
			minWidth: '48px',
			maxWidth: '48px',
			alignSelf: 'stretch',
			padding: '0px',
			margin: '0px',
			'&:disabled': {
				color: theme.palette.color.informational,
			},
		},
		nextPageButton: {
			minHeight: '28px',
			minWidth: '48px',
			maxWidth: '48px',
			alignSelf: 'stretch',
			padding: '0px',
			margin: '0px',
			'&:disabled': {
				color: theme.palette.color.informational,
			},
		},
		pageButtonWrapper: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignSelf: 'stretch',
			alignItems: 'center',
		},
		pageButton: {
			minHeight: '28px',
			minWidth: '28px',
			maxWidth: '28px',
			borderRadius: 0,
			borderWidth: '1px',
			borderStyle: 'solid',
			padding: '0px',
			margin: '0px',
			borderColor: theme.palette.background.contained,
			backgroundColor: theme.palette.background.alternateButton,
			fontFamily: 'Montserrat Bold',
			color: 'inherit',
			fontSize: '14px',
		},
		pageButtonActive: {
			color: '#ffffff',
			backgroundColor: theme.palette.background.primaryButton,
			fontSize: '14px',
		},
		pageChooser: {
			margin: '0 1em',
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			flexWrap: 'nowrap',
			'& > *': {
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'nowrap',
			},
			marginTop: '0.5em',
			position: 'absolute',
			left: '0px',
			right : '0px',
		},
		pageField: {
			margin: '0 0.1em',
			minHeight: '28px',
			maxHeight: '28px',
			width: '60px',
			display: 'inline',
			outline: 'none',
			border: 'none',
			borderWidth: '1px',
			borderStyle: 'solid',
			paddingLeft: '15px',
			borderColor: theme.palette.background.contained,
			backgroundColor: theme.palette.background.default,
			textAlign: 'center',
			fontFamily: 'Montserrat Bold',
		},
		perPageControl: {
			minHeight: '28px',
			maxHeight: '28px',
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-end',
			flexWrap: 'nowrap',
			'& > label': {
				marginRight: '1em',
			},
			'& > *': {
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'nowrap',
			},
			marginTop: '0.5em',
		},
		perPageButton: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			alignSelf: 'stretch',
			minHeight: '28px',
			minWidth: '42px',
			maxWidth: '42px',
			borderRadius: 0,
			padding: '0px',
			margin: '0px',
			fontFamily: 'Montserrat Bold'
		},
		informationalText: {
			fontSize: '12px',
		}
	});

export default style;
