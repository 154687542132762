import React, {useEffect, useState} from 'react';
import {CREATE, fetchEnd, fetchStart, useDataProvider, useVersion} from 'react-admin';
import {GET, GET_FUTURE_TRAINING} from 'services/customActions';
import {
	DialogTitle,
	EmbeddedShow,
	ScreenTitle,
	StatusChip,
	Text,
	TextValue,
	TimeDateField,
} from 'components';
import {getOrgId, loadObject} from 'utils/Util';
import {Redirect} from 'react-router-dom';
import {
	createStyles,
	Dialog,
	DialogContent,
	Typography,
	withStyles,
	DialogActions,
	Button,
} from '@material-ui/core';
import {getUserInfo} from 'utils/Data';
import LoadingInfo from 'components/loading/LoadingInfo';
import CancelSubmission from 'sections/InDevelopment/Publish/CancelSubmission';
import TrainingCatalogue from 'components/TrainingCatalogue';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import { useDispatch } from 'react-redux';

const SUBMIT_FOR_REVIEW_MS = 'Submitted For Review';

const IN_DEVELOPMENT_MS = 'In Development';

const PUBLISHED_MS = 'Published';

const styles = theme =>
	createStyles({
		paper: {
			top: '30%',
		},
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
		},
	});

const PublishTraining = props => {
	const {classes} = props;

	const appKeyName = props.match?.params?.appKeyName;

	const dataProvider = useDataProvider();

	const history = useHistory();

	const version = useVersion();

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const enableInDevelopment =
		runwayOrgConfig && runwayOrgConfig.config
			? runwayOrgConfig.config.enableInDevelopment
			: 'false';

	//gloabal property to publish training modules with/without a review
	const reviewConfig =
		runwayOrgConfig && runwayOrgConfig.config
			? runwayOrgConfig.config.reviewConfig
			: 'false';

	const contentCompanyTeamId =
		runwayOrgConfig &&
		runwayOrgConfig.config &&
		runwayOrgConfig.config.contentCompanyTeamId
			? runwayOrgConfig.config.contentCompanyTeamId
			: '';

	const [projectStatus, setProjectStatus] = useState(null);

	const [trainingData, setTrainingData] = useState(null);

	//property to enable/disable Submit For Review Button
	const [isEditingInProgress, setIsEditingInProgress] = useState(false);

	const [isCancelling, setIsCancelling] = useState(false);

	const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);

	const [isPublisingTrainingModule, setIsPublisingTrainingModule] = useState(
		false
	);

	const [openSubmitPanel, setOpenSubmitPanel] = useState(false);

	const [openPublishPanel, setOpenPublishPanel] = useState(false);

	const [showCancelSubmissionDialog, setShowCancelSubmissionDialog] = useState(
		false
	);

	const [
		showSubmitForReviewConfiramtion,
		setShowSubmitForReviewConfirmation,
	] = useState(false);

	const [showPublishConfiramtion, setShowPublishConfiramtion] = useState(false);

	const [showStatusChangedDialog, setShowStatusChangedDialog] = useState(false);

	const [showContentNotEditable, setShowContentNotEditable] = useState(false);

	const [errorMessage, setErrorMessage] = useState(null);

	const [isPlayable, setIsPlayable] = useState(false);

	const summaryFriendData = loadObject(loadObject('project').id + '-summaryFriendData');

	const isTester = summaryFriendData?.isTester ? true : false;

	const isTSAPlayable = async (data) => {
		if (
			data && data.accessibility && data.accessibility.licensed.value &&
			data.isCPD &&
			(data.trainingDetail.moduleStatus === 'Published' ||
				getUserInfo().trainingModuleEditor ||
				isTester)
		) {
			dispatch(fetchStart());
			await dataProvider(GET, 'isTSAPlayable', { appKeyName: data?.appKeyName })
				.then(res => {
					if (res.success) {
						setIsPlayable(true);
					}
					setLoading(false);
				})
				.catch(e => {
					console.error(e);
					setIsPlayable(false);
				})
				.finally(() => {
					dispatch(fetchEnd());
			        setLoading(false);
				});
		} else {
			dispatch(fetchEnd());
			setLoading(false);
		}
	}
	

	const fetchProjectStatus = async returnData => {
		let projectStatus;
		dispatch(fetchStart());
		await dataProvider(GET_FUTURE_TRAINING, 'ProjectStatus', {
			appKeyName,
		}).then(res => {
			projectStatus = res.data;
			if (!returnData) setProjectStatus(projectStatus);
		}).finally(() => {
			dispatch(fetchEnd());
		});

		if (returnData) return projectStatus;
	};

	const fetchTrainingDetail = async () => {
		dispatch(fetchStart());
		await dataProvider(GET_FUTURE_TRAINING, 'futureTrainings', {
			appKeyName, filter: {orgId: getOrgId()}
		}).then(async res => {
			setTrainingData(res.data);
			await isTSAPlayable(res.data);
		}).finally(() => {
			dispatch(fetchEnd());
		})
	};

	const submitForReview = async () => {
		setIsSubmittingForReview(true);
		await dataProvider(CREATE, 'updateProjectModuleStatus', {
			data: {
				appKeyName: appKeyName,
				moduleStatus: SUBMIT_FOR_REVIEW_MS,
			},
		})
			.then(res => {
				setProjectStatus(res.data[0]);
				setOpenSubmitPanel(false);
				setShowSubmitForReviewConfirmation(true);
			})
			.catch(error => console.error(error))
			.finally(() => setIsSubmittingForReview(false));
	};

	const cancelSubmission = async () => {
		setIsCancelling(true);

		await dataProvider(CREATE, 'updateProjectModuleStatus', {
			data: {
				appKeyName: appKeyName,
				moduleStatus: IN_DEVELOPMENT_MS,
			},
		})
			.then(res => {
				setProjectStatus(res.data[0]);
				setShowCancelSubmissionDialog(false);
			})
			.catch(error => console.error(error))
			.finally(() => setIsCancelling(false));
	};

	const publishTrainingModule = async () => {
		setIsPublisingTrainingModule(true);

		const moduleStatusResponse = await dataProvider(
			CREATE,
			'updateProjectModuleStatus',
			{
				data: {
					appKeyName: appKeyName,
					moduleStatus: PUBLISHED_MS,
				},
			}
		).catch(error => setErrorMessage(error.message));

		if (!moduleStatusResponse) return;

		await dataProvider(CREATE, 'deployAppToLive', {
			data: {
				appId: trainingData.id,
			},
		})
			.then(() => {
				setShowPublishConfiramtion(true);
			})
			.catch(error => {
				setErrorMessage(error.message);
			});
		setProjectStatus(moduleStatusResponse.data[0]);
		setOpenPublishPanel(false);
		setIsPublisingTrainingModule(false);
	};

	const onUpdatingTrainingContent = async () => {
		await fetchTrainingDetail();
		setIsEditingInProgress(false);
	};

	const isTrainingStillEditable = async () => {
		if (getUserInfo().role === 'TS-super' || getUserInfo().role === 'TS-admin')
			return true;

		let editable = true;

		const response = await fetchProjectStatus(true);

		if (response && response.publishedStatus.reviewInProgress) {
			editable = false;
			setProjectStatus(response);
			setShowContentNotEditable(true);
		}

		return editable;
	};

	const onEditTrainingError = async errorMessage => {
		await fetchProjectStatus();
		setIsEditingInProgress(false);
		setErrorMessage(errorMessage);
		setShowContentNotEditable(true);
	};

	const isTrainingModuleEditor = () => {
		if (!trainingData) return false;
		if (
			trainingData != null &&
			contentCompanyTeamId === trainingData.trainingDetail.contentOwnerTeamId
		) {
			return getUserInfo().trainingModuleEditor;
		}

		return false;
	};

	useEffect(() => {
		const fetchData = async () => {
            setLoading(true);
			await fetchProjectStatus();
			await fetchTrainingDetail();
            setLoading(false);
		}
		fetchData();
	}, [version]);

	useEffect(() => {
		if (
			projectStatus &&
			trainingData &&
			projectStatus.moduleStatus === PUBLISHED_MS
		) {
			setShowStatusChangedDialog(true);
		}
	}, [projectStatus]);


	if (!appKeyName) {
		return <Redirect to='/inDevelopment' />;
	}


	if (
		(!getUserInfo().isAdmin && !getUserInfo().isSuper) || !(enableInDevelopment === 'true')
	) {
		return <Redirect to='/' />;
	}

	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to='/termsOfService' />;
	// }

	if (getUserInfo().isUser) {
		return (
			<div>
				Access denied. Contact your administrator to raise your permissions
			</div>
		);
	}

	return (
		<div>
			<ScreenTitle
				{...props}
				list={'/inDevelopment'}
				basePath={props.basePath}
				resource={props.resource}
				title='Module Details'
				listButton
			/>

			{projectStatus && !loading && (
				<EmbeddedShow
					title={
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '10px',
							}}
						>
							<Typography
								style={{
									fontFamily: 'Archivo Bold',
									fontSize: '20px',
								}}
							>
								Publication Status:
							</Typography>
							<StatusChip
								source={'customerProjectStatus'}
								record={projectStatus}
							/>
							{/*https://talespin.atlassian.net/browse/RP-1515/*/}
							{/* {projectStatus &&
								projectStatus.customerProjectStatus === 'In Review' && (
									<Typography>
										<i>
											{moment
												.unix(
													projectStatus.reviewStatus.dateSubmittedForReview /
														1000
												)
												.format('MM/DD/YYYY hh:mm:ss A')}
										</i>
									</Typography>
								)} */}
						</div>
					}
					actions={[
						{
							render: () => {
								if (
									!trainingData &&
									!isTrainingModuleEditor() &&
									getUserInfo().role === 'TS-super' &&
									getUserInfo().role === 'TS-admin'
								)
									return;

								if (reviewConfig === 'true') {
									if (projectStatus.moduleStatus === IN_DEVELOPMENT_MS) {
										return (
											<Button
												color={'primary'}
												variant={'contained'}
												onClick={() => setOpenSubmitPanel(true)}
												disabled={
													projectStatus.createdAt === projectStatus.updatedAt ||
													openSubmitPanel ||
													isEditingInProgress
												}
											>
												Submit for Review
											</Button>
										);
									}

									if (projectStatus.moduleStatus !== PUBLISHED_MS) {
										return (
											<Button
												color={'primary'}
												variant={'contained'}
												onClick={() => setShowCancelSubmissionDialog(true)}
												disabled={
													projectStatus.moduleStatus !== SUBMIT_FOR_REVIEW_MS
												}
											>
												Cancel Submission
											</Button>
										);
									}
								}

								if (
									reviewConfig !== 'true' &&
									projectStatus.moduleStatus !== PUBLISHED_MS
								) {
									return (
										<Button
											color={'primary'}
											variant={'contained'}
											onClick={() => setOpenPublishPanel(true)}
											disabled={
												openPublishPanel ||
												projectStatus.createdAt === projectStatus.updatedAt
											}
										>
											Publish
										</Button>
									);
								}
							},
						},
					]}
					details={[
						{
							label: 'Pre-Publish Status',
							render: () => (
								// {depends on the CPD project file URl, if the URL exists means the file is alredy Pre-Published(staged)}
								<Typography>
									{projectStatus.cpdAProjectFile &&
									projectStatus.cpdAProjectFile.trim().length > 0
										? 'Pre-Published'
										: 'Not Started'}
								</Typography>
							),
						},
						{
							label: 'Pre-Publish Date',
							render: () => (
								<Typography>
									{projectStatus.stagingStatus.lastStagedDate &&
									projectStatus.stagingStatus.lastStagedDate !== '---'
										? moment
												.unix(projectStatus.stagingStatus.lastStagedDate / 1000)
												.format('MM/DD/YYYY hh:mm:ss A')
										: '---'}
								</Typography>
							),
						},
						{
							label: 'CoPilot Designer Project File',
							render: () =>
								projectStatus.cpdAProjectFile &&
								projectStatus.cpdAProjectFile.trim().length > 0 ? (
									<a
										href={projectStatus.cpdAProjectFile}
										target='_blank'
										rel='noreferrer'
									>
										Download Project File
									</a>
								) : (
									'---'
								),
						},
						{
							label: 'Pre-Publish By',
							render: () => (
								<TextValue
									record={projectStatus}
									source='stagingStatus.userName'
								/>
							),
						},
					]}
				/>
			)}
			{(!projectStatus || loading) && (
				<EmbeddedShow title={'Publication Status'}>
					<LoadingInfo />
				</EmbeddedShow>
			)}

			<div style={{margin: '24px 0px'}}></div>

			{(trainingData && !loading) ? (
				<TrainingCatalogue
					trainingData={trainingData}
					isExpandMore={
						loadObject('expandTrainingcatalogue') === null
							? true
							: loadObject('expandTrainingcatalogue')
					}
					toggleKey={'expandTrainingcatalogue'}
					context={'In Development'}
					projectStatus={projectStatus}
					allowEditing={
						(isTrainingModuleEditor() &&
							!projectStatus.publishedStatus.reviewInProgress) ||
						getUserInfo().role === 'TS-super' ||
						getUserInfo().role === 'TS-admin'
					}
					isTSAPlayable={isPlayable}
					isTrainingStillEditable={async () => await isTrainingStillEditable()}
					isSubmittingForReview={isSubmittingForReview}
					isPublishing={isPublisingTrainingModule}
					openSubmitPanel={openSubmitPanel}
					openPublishPanel={openPublishPanel}
					onSubmittingForReview={submitForReview}
					onPublishTrainingModule={publishTrainingModule}
					onToggleEditing={isEditing => setIsEditingInProgress(isEditing)}
					onCancelSubmitting={() => setOpenSubmitPanel(false)}
					onCancelPublishing={() => setOpenPublishPanel(false)}
					onUpdate={() => onUpdatingTrainingContent()}
					onEditTrainingError={errorMessage =>
						onEditTrainingError(errorMessage)
					}
					information={
						<EmbeddedShow
							headerless
							embedded
							actions={[]}
							details={[
								{
									label: 'Name',
									render: () => (
										<TextValue
											record={trainingData}
											source='name'
											maxWidth={'300px'}
										/>
									),
								},
								{
									label: 'Series',
									render: () => (
										<TextValue
											record={trainingData}
											source='seriesName'
											maxWidth={'300px'}
										/>
									),
								},
								{
									label: 'Created',
									render: () => (
										<TimeDateField record={trainingData} source='createdAt' />
									),
								},
								{
									label: 'Modified',
									render: () => (
										<TimeDateField record={trainingData} source='updatedAt' />
									),
								},
								{
									label: 'Id',
									render: () => <TextValue record={trainingData} source='id' />,
								},
							]}
						/>
					}
				></TrainingCatalogue>
			) : (
				<LoadingInfo />
			)}

			{showCancelSubmissionDialog && (
				<CancelSubmission
					onCancelSubmission={() => cancelSubmission()}
					onClose={() => setShowCancelSubmissionDialog(false)}
					isCancelling={isCancelling}
					projectStatus={projectStatus}
				/>
			)}

			{(showSubmitForReviewConfiramtion || showPublishConfiramtion) && (
				<Dialog
					id={'cancel-submission'}
					open={true}
					fullWidth={true}
					maxWidth={'xs'}
					classes={{
						paper: classes.paper,
					}}
				>
					<DialogContent>
						<div className={classes.dialogContent}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '24px',
									gap: '12px',
								}}
							>
								<Typography style={{fontSize: '14px', textAlign: 'center'}}>
									Your Project is successfully{' '}
									{showPublishConfiramtion
										? 'published'
										: 'submitted for review!'}
								</Typography>

								<Button
									color={'primary'}
									variant={'contained'}
									style={{width: '100px', alignSelf: 'center', height: '32px'}}
									onClick={() => {
										if (showPublishConfiramtion)
											history.push({pathname: '/inDevelopment'});

										if (showSubmitForReviewConfiramtion)
											setShowSubmitForReviewConfirmation(false);
									}}
								>
									Close
								</Button>
							</div>
						</div>
					</DialogContent>
				</Dialog>
			)}
			{showStatusChangedDialog && reviewConfig === 'true' && (
				<Dialog
					id={'redirect'}
					open={true}
					fullWidth={true}
					maxWidth={'xs'}
					classes={{
						paper: classes.paper,
					}}
				>
					<DialogTitle
						onClose={() => history.push({pathname: '/inDevelopment'})}
					>
						<Text
							style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
							label={'Content Status has Changed'}
						/>
					</DialogTitle>
					<DialogContent>
						<div>
							<Typography style={{fontSize: '14px', padding: '12px'}}>
								This content has been Published and is no longer available in
								the In Development list. Press OK to go to the Published
								Training Module or Cancel to return to the In Development
								Content List
							</Typography>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							color={'secondary'}
							variant={'contained'}
							onClick={() => history.push({pathname: '/inDevelopment'})}
						>
							Close
						</Button>
						<Button
							color={'primary'}
							variant={'contained'}
							onClick={() =>
								history.push({pathname: `/trainings/${trainingData.id}/show`})
							}
						>
							OK
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{showContentNotEditable && (
				<Dialog
					id={'cancel-submission'}
					open={true}
					classes={{
						paper: classes.paper,
					}}
					maxWidth={'sm'}
				>
					<DialogContent>
						<div className={classes.dialogContent}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '24px',
									gap: '12px',
								}}
							>
								<Typography style={{fontSize: '14px', textAlign: 'center'}}>
									{errorMessage
										? errorMessage
										: 'The Training Module Stautus has changed and no longer editable'}
								</Typography>

								<Button
									color={'primary'}
									variant={'contained'}
									style={{width: '100px', alignSelf: 'center', height: '32px'}}
									onClick={() => {
										setErrorMessage(false);
										setShowContentNotEditable(false);
									}}
								>
									Close
								</Button>
							</div>
						</div>
					</DialogContent>
				</Dialog>
			)}
		</div>
	);
};

export default withStyles(styles)(PublishTraining);
