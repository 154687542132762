import UserTraining from 'components/UserTraining';
import React, {useState} from 'react';

import {Tab} from 'react-admin';
import UsersList from './UserList';

const User = props => {
	const {teamData} = props;

	const [selectedUserRecord, setSelectedUserRecord] = useState(null);
	const [selectedTrainingRecord, setSelectedTrainingRecord] = useState(null);
	const [selectedLessonRecord, setSelectedLessonRecord] = useState(null);

	const userSelected = (id, basePath, record) => {
		setSelectedUserRecord(record);
		return null;
	};
	const trainingSelected = (id, basePath, record) => {
		setSelectedTrainingRecord(record);
		return null;
	};
	const lessonSelected = (id, basePath, record) => {
		setSelectedLessonRecord(record);
		return null;
	};
	const cancelUserSelection = () => {
		setSelectedUserRecord(null);
		setSelectedTrainingRecord(null);
		setSelectedLessonRecord(null);
	};
	const cancelTrainingSelection = () => {
		setSelectedTrainingRecord(null);
		setSelectedLessonRecord(null);
	};
	const cancelLessonSelection = () => {
		setSelectedLessonRecord(null);
	};

	return (
		<Tab label={'users'}>
			{!selectedUserRecord && (
				<UsersList
					{...props}
					userSelected={userSelected}
					backUserAction={cancelUserSelection}
					backLessonAction={cancelLessonSelection}
					backTrainingAction={cancelTrainingSelection}
					trainingSelected={trainingSelected}
					lessonSelected={lessonSelected}
					selectedUserRecord={selectedUserRecord}
					selectedTrainingRecord={selectedTrainingRecord}
					selectedLessonRecord={selectedLessonRecord}
				/>
			)}
			{selectedUserRecord && (
				<div>
					<UserTraining
						id={selectedUserRecord.id}
						teamData={teamData}
						userData={selectedUserRecord}
						teamBackAction={cancelUserSelection}
						showLastProcessedTime={false}
					/>
				</div>
			)}
		</Tab>
	);
};

export default User;
