import { Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, MuiThemeProvider, Typography, withStyles } from "@material-ui/core";
import { DialogTitle } from "components";
import { DialogListTheme } from "components/Theme";
import moment from "moment"

const styles = theme =>
	createStyles({
        paper: {
			top: '30%',
			minWidth: '600px',
		},
		dialogTitle: {
			fontSize: '20px',
			fontFamily: 'Archivo Bold',
		},
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
	});

const CancelSubmissionDialog = (props) => {

	const { classes, isCancelling, projectStatus} = props;

	const onClose = () => {
		props.onClose();
	}

	const onCancelSubmission = () => {
		props.onCancelSubmission();
	}

    return (
			<Dialog
				id={'cancel-submission'}
				open={true}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => onClose()}
				classes={{
					paper: classes.paper,
				}}
			>
				<MuiThemeProvider theme={DialogListTheme}>
					<DialogTitle onClose={() => onClose()}>
						<Typography className={classes.dialogTitle}>
							Cancel Submission
						</Typography>
					</DialogTitle>

					<DialogContent style={{minWidth: '400px'}}>
						<div className={classes.dialogContent}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									padding: '12px',
								}}
							>
								<Typography style={{fontSize: '14px', textAlign: 'left'}}>
									Your project was submitted for review on: <b>{moment.unix(projectStatus.reviewStatus.dateSubmittedForReview/1000).format('MM/DD/YYYY hh:mm:ss A')}</b>.
									<br /> <br /> Canceling your submission will stop the
									publication of your project.
									<br /> <br /> Would you like to continue?
								</Typography>
							</div>
						</div>
					</DialogContent>

					<DialogActions>
						{!isCancelling && (
							<>
								<Button
									onClick={() => onClose()}
									variant={'contained'}
									color={'secondary'}
								>
									No
								</Button>
								<Button
									onClick={() => onCancelSubmission()}
									variant={'contained'}
									color={'primary'}
								>
									Yes
								</Button>
							</>
						)}
						{isCancelling && (
							<div style={{display: 'flex', alignItems: 'baseline'}}>
								<CircularProgress
									size={20}
									style={{top: '5px', position: 'relative', marginRight: '5px'}}
								/>
								<span style={{color: '#000'}}>{`Cancelling...`}</span>
							</div>
						)}
					</DialogActions>
				</MuiThemeProvider>
			</Dialog>
		);

}

export default withStyles(styles)(CancelSubmissionDialog);
