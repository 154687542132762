import React, {useState} from 'react';
import {useTheme} from '@material-ui/core/styles';

import {LinearProgressBar} from 'components';
import SkillIcon from 'assets/skillIcons';
import SkillPopover from './SkillPopover';

const SkillMatrix = props => {
	const theme = useTheme();

	const {data} = props;

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const [activeSkill, setActiveSKill] = useState();

	const generateLeft = data => {
		var elements = [];

		for (const itemRow in data) {
			for (const itemColumn in data[itemRow]) {
				if (itemColumn !== 'displayName') {
					continue;
				}

				elements.push(
					<div
						style={{
							fontStretch: 'bold',
							width: 'auto',
							height: '52px',
							textTransform: 'capitalize',
							justifyContent: 'left',
							justifyItems: 'left',
							flexGrow: 1,
							minWidth: '33%',
							marginBottom: '32px',
						}}
						key={data[itemRow].id + '-' + itemColumn}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginTop: '10px',
								cursor: 'pointer',
							}}
							onClick={() => {
								setActiveSKill(data[itemRow]);
								setIsPopoverOpen(true);
							}}
						>
							<span
								style={{
									marginTop: 'auto',
									marginBottom: 'auto',
									marginLeft: '8px',
									width: '40px',
									height: '40px',
								}}
							>
								<SkillIcon record={data[itemRow]} width={40} height={40} />
							</span>
							<span
								style={{
									paddingRight: '6px',
									paddingLeft: '6px',
									fontSize: '12px',
									textAlign: 'left',
									fontFamily: 'Montserrat Medium',
									maxWidth: '200px',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
								}}
								title={data[itemRow][itemColumn]}
							>
								{data[itemRow][itemColumn]}
							</span>
						</div>
					</div>
				);
			}
		}

		return elements;
	};

	const generateMain = data => {
		var elements = [];

		for (const itemRow in data) {
			for (const itemColumn in data[itemRow]) {
				if (!['skillsValueMap'].includes(itemColumn)) {
					continue;
				}

				elements.push(
					<div
						style={{
							fontStretch: 'bold',
							width: '100%',
							height: '52px',
							textTransform: 'capitalize',
							flexGrow: 1,
							minWidth: '100%',
							paddingBottom: '20px',
							marginBottom: '32px',
						}}
						key={data[itemRow].id + '-' + itemColumn}
					>
						<div
							style={{
								backgroundColor: theme.palette.background.default,
								padding: '10px',
								justifyContent: 'space-around',
								justifyItems: 'space-around',
								flexGrow: 1,
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<LinearProgressBar
								hideValue
								value={Math.ceil(data[itemRow][itemColumn].value * 100)}
							/>

							<div
								style={{
									fontSize: '12px',
									textAlign: 'center',
								}}
							>
								<span style={{color: theme.palette.primary.main}}>
									{data[itemRow][itemColumn].valueLabel}{' '}
								</span>
								<span>{data[itemRow][itemColumn].subLabel}</span>
							</div>
						</div>
					</div>
				);
			}
		}

		return elements;
	};

	return (
			<div
  style={{
    display: 'grid',
    gridTemplateColumns: 'auto 1fr', // auto for 'left' and flexible for 'main'
    gridTemplateRows: 'auto',
    columnGap: '20px', 
    rowGap: '10px',
    gridTemplateAreas: `
      'left main'
    `,
  }}
	>

			<div
				style={{
					gridArea: 'left',
				}}
			>
				{generateLeft(data)}
			</div>
			<div
				style={{
					display: 'flex',
					gridArea: 'main',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
				}}
			>
				{generateMain(data)}
			</div>
			<div>
				<SkillPopover
					isPopoverOpen={isPopoverOpen}
					setIsPopoverOpen={setIsPopoverOpen}
					skillData={activeSkill}
				/>
			</div>
		</div>
	);
};

export default SkillMatrix;
