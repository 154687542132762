import {Labeled} from 'react-admin';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import get from 'lodash/get';

export const LabeledTextField = props => {
	const {className, altRecord, label, source, style, rawText} = props;
	const value = rawText ? rawText : get(altRecord, source);
	return (
		<div
			key={source}
			className={classnames(`ra-field ra-field-${source}`, className)}
		>
			{' '}
			<Labeled label={label} style={style}>
				<Typography component='span' variant='body1' className={className}>
					{value && typeof value !== 'string' ? JSON.stringify(value) : value}
				</Typography>
			</Labeled>
		</div>
	);
};

export default LabeledTextField;
