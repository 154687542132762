import {createStyles, withStyles} from '@material-ui/core/styles';

import {LinearProgressBar} from 'components';
import PercentField from 'components/PercentField';
import React from 'react';
import SkillIcon from 'assets/skillIcons';
import {Typography} from '@material-ui/core';

const myStyles = theme =>
	createStyles({
		skillBar: {
			backgroundColor: theme.palette.background.default,
			borderRadius: '4px',
			padding: '10px',
		},
		scoreSubValue: {
			color: theme.palette.primary.main,
		},
	});

const SkillBar = props => {
	const {data, large, classes, progressData} = props;

	const getTimeSpent = timeInSeconds => {
		timeInSeconds = timeInSeconds.toFixed();
		let timeSpent = '';
		let hours = Math.floor(timeInSeconds / 3600);
		if (hours) {
			timeSpent = hours + 'h ';
		}
		timeInSeconds %= 3600;
		let minutes = Math.floor(timeInSeconds / 60);
		let seconds = timeInSeconds % 60;
		timeSpent = timeSpent.concat(minutes, 'm ', seconds, 's');
		return timeSpent;
	};

	return (
		<div
			style={{
				width: 'auto',
				marginBottom: '8px',
			}}
		>
			<div className={classes.skillBar}>
				<div
					style={{
						display: 'grid',

						gridTemplateColumns: large ? 'auto' : '52px auto',

						gridTemplateRows: 'auto',
						gridGap: '8px',
						rowGap: '8px',

						gridTemplateAreas: large
							? `'title'
							'bar'
							'text'`
							: `'logo title'
							'logo bar'
							'logo text'`,
					}}
				>
					{!large && (
						<span
							style={{
								gridArea: 'logo',
								marginTop: 'auto',
								marginLeft: '8px',
								width: '35px',

								marginBottom: 'auto',
								height: '35px',
							}}
						>
							<SkillIcon record={data} width={40} height={40} />
						</span>
					)}

					<div
						style={{
							fontSize: '14px',
							gridArea: 'title',
							fontFamily: 'Montserrat Medium',
						}}
					>
						{data.displayName}{' '}
					</div>

					<div style={{gridArea: 'bar'}}>
						<LinearProgressBar
							value={Math.ceil(data.value * 100)}
							hideValue
							marks={[
								data.passedPercent
									? {value: data.passedPercent * 100, color: '#99FF66'}
									: {},
								data.acedPercent
									? {value: data.acedPercent * 100, color: '#9FCEFD'}
									: {},
							]}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<div style={{display: 'flex', gap: '15px'}}>
							<div style={{display: 'flex', gap: '5px'}}>
								<Typography style={{fontSize: '12px'}}>
									{data.subLabel}:
								</Typography>
								<Typography color={'primary'} style={{fontSize: '12px'}}>
									{data.subValue}
								</Typography>
							</div>
							{data.orgLabel && data.orgDisplayValue && (
								<React.Fragment>
									<span style={{borderLeft: '2px solid'}}></span>
									<div style={{display: 'flex', gap: '5px'}}>
										<Typography style={{fontSize: '12px'}}>
											{data.orgLabel}:
										</Typography>
										<Typography color={'primary'} style={{fontSize: '12px'}}>
											{data.orgDisplayValue}
										</Typography>
									</div>
								</React.Fragment>
							)}
						</div>
						{progressData && (
							<div style={{display: 'flex', columnGap: '20px'}}>
								<span>
									Time Spent: {' ' + getTimeSpent(progressData.timeSpent)}
								</span>
								<span>
									Score: <PercentField record={progressData} source='score' />
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const enhance = withStyles(myStyles);

export default enhance(SkillBar);
