import SvgIcon from '@material-ui/core/SvgIcon';

export default function OpenNewTabIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 17">
      <path
        d="M12 12.5V13.7C12 14.8046 11.1046 15.7 10 15.7H2.80005C1.69548 15.7 0.800049 14.8046 0.800049 13.7V6.5C0.800049 5.39543 1.69548 4.5 2.80005 4.5H4.00005"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
      />
      <rect
        x="4"
        y="1.3"
        width="11.2"
        height="11.2"
        rx="2"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
      />
      <path
        d="M8 4.5H12M12 4.5V8.5M12 4.5L8 8.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
