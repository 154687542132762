import {LinearProgress} from 'react-admin';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

const TextValue = ({
	source,
	record = {},
	altRecord,
	maxWidth,
	padding,
	disableTooltip = false,
}) => {
	const renderText = value => {
		return (
			<div>
				{disableTooltip ? (
					<Typography
						style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							maxWidth: maxWidth ? maxWidth : '120px',
							padding: padding ? padding : '',
						}}
					>
						{value}
					</Typography>
				) : (
					<Tooltip title={value} placement='top'>
						<Typography
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								maxWidth: maxWidth ? maxWidth : '120px',
								padding: padding ? padding : '',
							}}
						>
							{value}
						</Typography>
					</Tooltip>
				)}
			</div>
		);
	};

	if (altRecord) {
		record = altRecord;
	}

	if (!record) {
		return <LinearProgress />;
	}
	if (!get(record, source)) {
		return <div>---</div>;
	}

	return renderText(get(record, source));
};

export default TextValue;
