import LessonDetail from './LessonDetail';
import React from 'react';

const Lesson = props => {
	const {selectedLessonRecord} = props;

	return <div>{selectedLessonRecord && <LessonDetail {...props} />}</div>;
};

export default Lesson;
