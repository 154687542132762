import {secToWeeksFull, secToWeeksSecs} from 'services/formatters';

import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import get from 'lodash/get';

const TimeSpanField = ({source, record = {}, altRecord}) => {
	if (altRecord) {
		record = altRecord;
	}

	if (!get(record,source) || get(record,source) === '---') {
		return <span>---</span>;
	}
	return (
		<Tooltip title={secToWeeksFull(get(record,source))} placement='right-start'>
			<span>{secToWeeksSecs(get(record,source))}</span>
		</Tooltip>
	);
};

TimeSpanField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
};

export default TimeSpanField;
