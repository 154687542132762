import React, {useState} from 'react';
import {
	Typography,
	createStyles,
	withStyles,
	InputAdornment,
	Button,
	TextareaAutosize,
	IconButton,
	Avatar,
	CircularProgress,
	TextField,
} from '@material-ui/core';

import classnames from 'classnames';
import Carousel from '../Carousel';
import compose from 'recompose/compose';
import {useDataProvider} from 'ra-core';
import {GET_ONE, CREATE} from 'react-admin';

import CloseIcon from '@material-ui/icons/Close';
import TrainingModuleIcon from 'assets/MiniCardIcons/TrainingModule.svg';
import EmbeddedShow from 'components/EmbeddedShow';
import TimeDateField from 'components/TimeDateField';
import TextValue from 'components/TextValue';
import ImageUpload from 'components/TrainingCatalogue/ImageUpload';
import FeaturedSkills from 'components/TrainingCatalogue/FeaturedSkills';
import AddTrainingCategoriesDailog from '../AddTrainingCategoriesDialog';
import EditSkillsDialog from '../EditSkillsDialog';
import EditIcon from 'assets/EditIcon';
import { AddOutlined } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

const GLOBAL_APP_FOLDER = 'app_icons';

const GLOBAL_APP_SCREENSHOTS_FOLDER = 'app_screenshots';

const GLOBAL_HERO_ICONS_FOLDER = 'hero_icons';

const getStreamingButtonOneMarkup = streamingUrl =>
	`<style>#copy-streaming-player-link:hover {background: #244ca5 radial-gradient(circle, transparent 1%, #244ca5 1%) center/15000%;} #copy-streaming-player-link:active {background-color: transparent;background-size: 100%;transition: background 0s;}#copy-streaming-player-link { border: none; font-size: 12px; text-transform: uppercase;cursor: pointer;color: #ffffff;background-color: #346dec;box-shadow: 0 0 4px #999;outline: none;font-family: Archivo Bold;background-position: center;transition: background 0.6s;margin-left: 10px;padding: 4px 10px;}</style><li><a href="${streamingUrl}" target="_blank">Access this Module in Browser</a><button id="copy-streaming-player-link" >COPY LINK</button></li>`

const getStreamingButtonOneMarkup1 = streamingUrl =>
    `<style>#copy-streaming-player-link:hover {background: #244ca5 radial-gradient(circle, transparent 1%, #244ca5 1%) center/15000%;} #copy-streaming-player-link:active {background-color: transparent;background-size: 100%;transition: background 0s;}#copy-streaming-player-link { border: none; font-size: 12px; text-transform: uppercase;cursor: pointer;color: #ffffff;background-color: #346dec;box-shadow: 0 0 4px #999;outline: none;font-family: Archivo Bold;background-position: center;transition: background 0.6s;margin-left: 10px;padding: 4px 10px;}</style><li><a href="${streamingUrl}" target="_blank">Access all Assigned Modules in Browser</a><button id="copy-streaming-player-link" >COPY LINK</button></li>`

const getStreamingButtonTwoMarkup = streamingUrl =>
    `<style>#copy-streaming-player-link-two:hover {background: #244ca5 radial-gradient(circle, transparent 1%, #244ca5 1%) center/15000%;} #copy-streaming-player-link-two:active {background-color: transparent;background-size: 100%;transition: background 0s;}#copy-streaming-player-link-two { border: none; font-size: 12px; text-transform: uppercase;cursor: pointer;color: #ffffff;background-color: #346dec;box-shadow: 0 0 4px #999;outline: none;font-family: Archivo Bold;background-position: center;transition: background 0.6s;margin-left: 10px;padding: 4px 10px;}</style><li><a href="${streamingUrl}" target="_blank">Access all Assigned Modules in Browser</a><button id="copy-streaming-player-link-two" >COPY LINK</button></li>`

const getStreamingButtonTwoMarkup1 = streamingUrl =>
    `<style>#copy-streaming-player-link-two:hover {background: #244ca5 radial-gradient(circle, transparent 1%, #244ca5 1%) center/15000%;} #copy-streaming-player-link-two:active {background-color: transparent;background-size: 100%;transition: background 0s;}#copy-streaming-player-link-two { border: none; font-size: 12px; text-transform: uppercase;cursor: pointer;color: #ffffff;background-color: #346dec;box-shadow: 0 0 4px #999;outline: none;font-family: Archivo Bold;background-position: center;transition: background 0.6s;margin-left: 10px;padding: 4px 10px;}</style><li><a href="${streamingUrl}" target="_blank">Access this Module in Browser</a><button id="copy-streaming-player-link-two" >COPY LINK</button></li>`

const EditTrainingContent = props => {
	const {
		trainingData,
		processedSkills,
		processedCategories,
		classes,
		onCancelEdit,
		onEditTrainingError,
		onUpdate,
	} = props;

	const trainingDetail = trainingData.trainingDetail;

	const constructScreenShots = () => {
		const screenShots = [];
		const keys = trainingData.trainingDetail.screenShotKeys
			? trainingData.trainingDetail.screenShotKeys
			: [];
		const urls = trainingData.trainingDetail.screenShots;

		keys.forEach(key => screenShots.push({key: key}));
		urls.forEach((url, i) => (screenShots[i].url = url));

		return screenShots;
	};

	const formatAdditionalInfo = info => {
		if (info) {
			let modifiedInfo = info;
				if (trainingDetail.streamingUrl && info.includes(getStreamingButtonOneMarkup(trainingDetail.streamingUrl))) {
					modifiedInfo = modifiedInfo.replace(
						getStreamingButtonOneMarkup(trainingDetail.streamingUrl),
						''
					);
				}
				if (trainingDetail.streamingUrl && info.includes(getStreamingButtonOneMarkup1(trainingDetail.streamingUrl))) {
					modifiedInfo = modifiedInfo.replace(
						getStreamingButtonOneMarkup1(trainingDetail.streamingUrl),
						''
					);
				}
				if (trainingDetail.streamingUrl && info.includes(getStreamingButtonTwoMarkup(trainingDetail.streamingUrl))) {
					modifiedInfo = modifiedInfo.replace(
						getStreamingButtonTwoMarkup(trainingDetail.streamingUrl),
						''
					);
				}
				if (trainingDetail.streamingUrl && info.includes(getStreamingButtonTwoMarkup1(trainingDetail.streamingUrl))) {
					modifiedInfo = modifiedInfo.replace(
						getStreamingButtonTwoMarkup1(trainingDetail.streamingUrl),
						''
					);
				}
				if (trainingDetail.streamingUrlTwo && info.includes(getStreamingButtonOneMarkup(trainingDetail.streamingUrlTwo))) {
					modifiedInfo = modifiedInfo.replace(
						getStreamingButtonOneMarkup(trainingDetail.streamingUrlTwo),
						''
					);
				}
				if (trainingDetail.streamingUrlTwo && info.includes(getStreamingButtonOneMarkup1(trainingDetail.streamingUrlTwo))) {
					modifiedInfo = modifiedInfo.replace(
						getStreamingButtonOneMarkup1(trainingDetail.streamingUrlTwo),
						''
					);
				}
				if (trainingDetail.streamingUrlTwo && info.includes(getStreamingButtonTwoMarkup(trainingDetail.streamingUrlTwo))) {
					modifiedInfo = modifiedInfo.replace(
						getStreamingButtonTwoMarkup(trainingDetail.streamingUrlTwo),
						''
					);
				}
				if (trainingDetail.streamingUrlTwo && info.includes(getStreamingButtonTwoMarkup1(trainingDetail.streamingUrlTwo))) {
					modifiedInfo = modifiedInfo.replace(
						getStreamingButtonTwoMarkup1(trainingDetail.streamingUrlTwo),
						''
					);
				}
			return modifiedInfo;
		} else {
			return '';
		}
	};

	const numberOfAvailableSkills = trainingData.namedSkills.includes('Bonus')
		? trainingData.namedSkills.length - 1
		: trainingData.namedSkills.length;

	const [showAddCategoriesDailog, setShowAddCategoriesDailog] = useState(false);

	const [showEditSkillsDailog, setShowEditSkillsDailog] = useState(false);

	const [showImageUploadDialog, setShowImageUploadDialog] = useState(false);

	const [modifiedTrainingName, setModifiedTrainingName] = useState(
		trainingDetail.name ? trainingDetail.name : ''
	);

	const [modifiedSeries, setModifiedSeries] = useState(
		trainingData.seriesName ? trainingData.seriesName : ''
	);

	const [modifiedDescription, setModifiedDescription] = useState(
		trainingData.trainingDetail.description
			? trainingData.trainingDetail.description
			: ''
	);

	const [modifiedAboutSeries, setModifiedAboutSeries] = useState(
		trainingDetail.aboutSeries ? trainingDetail.aboutSeries : ''
	);

	const [modifiedAdditionalInfo, setModifiedAdditionalInfo] = useState(
		formatAdditionalInfo(trainingDetail.additionalInformation, trainingDetail)
	);

	const [modifiedLearningGoals, setModifiedLearningGoals] = useState(
		trainingDetail.learningGoals && trainingDetail.learningGoals.length > 0
			? trainingDetail.learningGoals[0].text
			: ''
	);

	const [modifiedLearningObjectives, setModifiedLearningObjectives] = useState(
		trainingDetail.learningObjectives &&
			trainingDetail.learningObjectives.length > 0
			? trainingDetail.learningObjectives[0]
			: ''
	);

	const [modifiedAppIcon, setModifiedAppIcon] = useState(null);

	const [modifiedHeroIcon, setModifiedHeroIcon] = useState(null);

	const [modifiedTrainingCategories, setModifiedTrainingCategories] = useState(
		processedCategories
	);

	const [activeImageChange, setActiveImageChange] = useState(null);

	const [modifiedScreenShots, setModifiedScreenShots] = useState(
		constructScreenShots()
	);

	const [modifiedSkills, setModifiedSkills] = useState(processedSkills);

	const includeStreamingUrl = trainingDetail.streamingEnabled;

	const streamingContentId = trainingDetail.streamingContentId;

	const [isUpdating, setIsUpdating] = useState(false);

	const [duplicateModuleName, setDuplicateModulename] = useState(false);

	const dataProvider = useDataProvider();

	const handleCancelEditing = () => {
		onCancelEdit();
	};

	const handleChangeImageClick = activeImageChange => {
		setShowImageUploadDialog(true);
		setActiveImageChange(activeImageChange);
	};

	const addTrainingCategories = result => {
		if (result) {
			setModifiedTrainingCategories(result.map(category => category.name));
		}

		setShowAddCategoriesDailog(false);
	};

	const removeTrainingCategories = index => {
		const caterories = [...modifiedTrainingCategories];
		caterories.splice(index, 1);

		setModifiedTrainingCategories(caterories);
	};

	const updateSkills = result => {
		if (result) {
			setModifiedSkills(result);
		}

		setShowEditSkillsDailog(false);
	};

	const handleImageChange = files => {
		if (files && files.length > 0) {
			if (activeImageChange === 'appIcon') {
				setModifiedAppIcon(files[0]);
			} else {
				setModifiedHeroIcon(files[0]);
			}
		}

		setShowImageUploadDialog(false);
		setActiveImageChange(null);
	};

	const handleScreenShotImageChange = files => {
		const screenShots = [...modifiedScreenShots];

		screenShots.push({
			key: null,
			file: files[0],
			url: URL.createObjectURL(files[0]),
		});

		setModifiedScreenShots(screenShots);
	};

	const handleScreenShotImageDelete = index => {
		const screenShots = [...modifiedScreenShots];

		screenShots.splice(index, 1);

		setModifiedScreenShots([...screenShots]);
	};

	const hasScreenShotsModified = () => {
		for (const screenshot of modifiedScreenShots) {
			if (screenshot.file != null) return true;
		}
	};

	const renderMaxCharactersError = (text, maxLength) => {
		if (text && text.length && text.length > maxLength) {
			return (
				<Typography color={'error'}>
					Must not exceed {maxLength} characters
				</Typography>
			);
		}

		return null;
	};

	const getTrainingDetailsGlobalFileFolderIds = async () => {
		return await dataProvider(GET_ONE, 'getTrainingDetailsGlobalFileFolderIds');
	};

	const toBase64 = file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	};

	const fileUpload = async (
		file,
		fileName,
		cloudPath,
		appId,
		appSecret,
		peerSessionId
	) => {
		const encodedString = await toBase64(file);

		const params = {
			cloudPath: cloudPath,
			cloudFilename: fileName,
			fileSize: file.size,
			encodedString: encodedString.split(',').pop(),
			appId: appId,
			appSecret: appSecret,
			peerSessionId: peerSessionId,
		};

		return await dataProvider(CREATE, 'uploadFile', {data: params});
	};

	const generateScreenShotKey = exisitngKeys => {
		for (const item of ['01', '02', '03', '04', '05']) {
			const key = `${trainingData.appKeyName}-Screenshot-${item}.jpg`;

			if (
				!exisitngKeys.includes(
					`${trainingData.appKeyName}-Screenshot-${item}.jpg`
				)
			) {
				return key;
			}
		}
	};

	const uploadScreenShots = async (peerAppId, peerAppSecret, peerSessionId) => {
		const originalScreenShotsKeys = [];
		const modifiedScreenShotKeys = [];
		const screenShotsToUpload = [];

		modifiedScreenShots.forEach(scrennShot => {
			if (scrennShot.key != null) originalScreenShotsKeys.push(scrennShot.key);

			if (scrennShot.file) screenShotsToUpload.push(scrennShot.file);
		});

		screenShotsToUpload.forEach((_screenShot, i) => {
			modifiedScreenShotKeys[i] = generateScreenShotKey([
				...originalScreenShotsKeys,
				...modifiedScreenShotKeys,
			]);
		});

		for (const [i, screenShot] of screenShotsToUpload.entries()) {
			await fileUpload(
				screenShot,
				modifiedScreenShotKeys[i],
				GLOBAL_APP_SCREENSHOTS_FOLDER,
				peerAppId,
				peerAppSecret,
				peerSessionId
			);
		}
	};

	const transferUserFilesToGlobalFiles = async (
		globalFolder,
		globalFileId,
		appId,
		appSecret,
		peerSessionId
	) => {
		const params = {
			userCloudPath: globalFolder,
			globalFileId: globalFileId,
			appId: appId,
			appSecret: appSecret,
			peerSessionId: peerSessionId,
		};

		return await dataProvider(CREATE, 'transferUserFilesToGlobalFiles', {
			data: params,
		});
	};

	const isFormValid = () => {
		if (modifiedSeries.length > 64) return false;

		if (
			modifiedDescription != null &&
			modifiedDescription.length > 500
		)
			return false;

		if (
			!modifiedTrainingName ||
			modifiedTrainingName.trim().length === 0 ||
			modifiedTrainingName.length > 50
		)
			return false;

		if (
			includeStreamingUrl &&
			(!streamingContentId || streamingContentId.trim().length === 0)
		)
			return false;

		for (const value of [
			modifiedLearningGoals,
			modifiedLearningObjectives,
			modifiedAboutSeries,
			modifiedAdditionalInfo,
		]) {
			if (value && value != null && value.trim().length && value.length > 2000)
				return false;
		}

		return true;
	};

	const handleSubmit = async () => {
		setIsUpdating(true);
		try {
			let uploadedAppIconKey = null;

			let uploadedHeroIconKey = null;

			let uploadedScreenShotKeys = [];

			const originalScreenShotsKeys = [];

			if (
				modifiedAppIcon != null ||
				modifiedHeroIcon != null ||
				hasScreenShotsModified()
			) {
				const {
					data: globalFileFolderIds,
				} = await getTrainingDetailsGlobalFileFolderIds();

				const peerSessionId = globalFileFolderIds.peerSessionId;

				const peerAppId = globalFileFolderIds.sessionData.gameId;

				const peerAppSecret = globalFileFolderIds.sessionData.secret;

				const gloabalAppIconFileId = globalFileFolderIds.folders.app_icons;

				const gloabaHeroIconFileId = globalFileFolderIds.folders.hero_icons;

				const gloabalScreenShotsFileId =
					globalFileFolderIds.folders.app_screenshots;

				modifiedScreenShots.forEach(scrennShot => {
					if (scrennShot.key != null)
						originalScreenShotsKeys.push(scrennShot.key);
				});

				if (modifiedAppIcon != null) {
					await fileUpload(
						modifiedAppIcon,
						`${trainingDetail.appKeyName}-Icon.jpg`,
						GLOBAL_APP_FOLDER,
						peerAppId,
						peerAppSecret,
						peerSessionId
					);

					const transferredFileData = await transferUserFilesToGlobalFiles(
						GLOBAL_APP_FOLDER,
						gloabalAppIconFileId,
						peerAppId,
						peerAppSecret,
						peerSessionId
					);

					uploadedAppIconKey =
						transferredFileData.data[0].data.fileDetails.fileName;
				}

				if (modifiedHeroIcon != null) {
					await fileUpload(
						modifiedHeroIcon,
						`${trainingDetail.appKeyName}-Hero.jpg`,
						GLOBAL_HERO_ICONS_FOLDER,
						peerAppId,
						peerAppSecret,
						peerSessionId
					);

					const transferredFileData = await transferUserFilesToGlobalFiles(
						GLOBAL_HERO_ICONS_FOLDER,
						gloabaHeroIconFileId,
						peerAppId,
						peerAppSecret,
						peerSessionId
					);

					uploadedHeroIconKey =
						transferredFileData.data[0].data.fileDetails.fileName;
				}

				if (hasScreenShotsModified()) {
					await uploadScreenShots(peerAppId, peerAppSecret, peerSessionId);

					const transferredData = await transferUserFilesToGlobalFiles(
						GLOBAL_APP_SCREENSHOTS_FOLDER,
						gloabalScreenShotsFileId,
						peerAppId,
						peerAppSecret,
						peerSessionId
					);

					transferredData.data.forEach(item =>
						uploadedScreenShotKeys.push(item.data.fileDetails.fileName)
					);
				}
			}

			const editiedContent = {
				appKeyName: trainingDetail.appKeyName,
				name: modifiedTrainingName?.trim(),
				series: modifiedSeries,
				appIconKeys:
					modifiedAppIcon && uploadedAppIconKey
						? [uploadedAppIconKey]
						: trainingDetail.appIconKeys &&
						  trainingDetail.appIconKeys.length > 0
						? trainingDetail.appIconKeys
						: [],
				heroIconKeys:
					modifiedHeroIcon && uploadedHeroIconKey
						? [uploadedHeroIconKey]
						: trainingDetail.heroIconKeys &&
						  trainingDetail.heroIconKeys.length > 0
						? trainingDetail.heroIconKeys
						: [],
				description: modifiedDescription,
				learningGoals:
					modifiedLearningGoals.trim().length > 0
						? modifiedLearningGoals
						: null,
				learningObjectives:
					modifiedLearningObjectives.trim().length > 0
						? modifiedLearningObjectives
						: null,
				trainingCategories: modifiedTrainingCategories,
				// namedSkills: modifiedSkills.map(skill => skill.name),
				aboutSeries:
					modifiedAboutSeries.trim().length > 0 ? modifiedAboutSeries : null,
				additionalInformation:
					modifiedAdditionalInfo.trim().length > 0
						? modifiedAdditionalInfo
						: '',
				screenShotKeys:
					hasScreenShotsModified() && uploadedScreenShotKeys
						? [...originalScreenShotsKeys, ...uploadedScreenShotKeys]
						: [
								...modifiedScreenShots
									.filter(item => item.key)
									.map(item => item.key),
						  ],
				streamingEnabled: includeStreamingUrl,
				streamingContentId: streamingContentId,
			};

			return await dataProvider(CREATE, 'editTrainingInfo', {
				data: editiedContent,
			});
		} catch (error) {
			console.error(error);
			if (onEditTrainingError) await onEditTrainingError(error.message);
			handleCancelEditing();
		}
	};

	const handleSave = async () => {
		if (hasModuleNameChanged()) {
			setIsUpdating(true);
			await dataProvider(CREATE, 'editTrainingInfo', {
				data: {
					appKeyName: trainingDetail.appKeyName,
					name: modifiedTrainingName?.trim(),
				},
			}).then(async res => {
				if (await handleSubmit()) {
					await onUpdate();
				}
				setIsUpdating(false);
			}).catch(e => {
				console.error(e);
				setIsUpdating(false);
				setDuplicateModulename(true);
			});
		} else {
			if (await handleSubmit()) {
				await onUpdate();
			}
			setIsUpdating(false);
		}
	};

	const hasModuleNameChanged = () => {
		return modifiedTrainingName?.trim() !== trainingDetail?.name
	}

	return (
		<div style={{backgroundColor: 'white'}}>
			<div
				style={{
					display: 'flex',
					padding: '5px',
					justifyContent: 'space-between',
					alignItems: 'baseline',
					boxShadow: '0px 0px 10px #00000033',
					backgroundColor: '#FFC440',
				}}
			>
				<span></span>

				<span>
					<Typography
						color={'primary'}
						style={{letterSpacing: '3.2px', fontFamily: 'Archivo Bold'}}
					>
						EDIT MODULE DETAILS
					</Typography>
				</span>

				{!isUpdating && (
					<span style={{display: 'flex', gap: '5px'}}>
						<Button
							variant={'outlined'}
							color={'primary'}
							style={{height: '35px'}}
							onClick={() => handleCancelEditing()}
						>
							Cancel
						</Button>
						<Button
							variant={'contained'}
							color={'primary'}
							style={{height: '35px'}}
							disabled={!isFormValid()}
							onClick={() => handleSave()}
						>
							Save
						</Button>
					</span>
				)}

				{isUpdating && (
					<div style={{display: 'flex', alignItems: 'baseline'}}>
						<CircularProgress
							size={20}
							style={{top: '5px', position: 'relative', marginRight: '5px'}}
						/>
						<span style={{color: '#000'}}>{`Updating...`}</span>
					</div>
				)}
			</div>

			<div style={{padding: '12px', border: '3px solid #ffc440'}}>
				<div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
					{/* Icon */}

					<div
						style={{display: 'flex', columnGap: '20px', alignItems: 'center'}}
					>
						<Typography
							style={{
								fontSize: '16px',
								fontFamily: 'Montserrat Bold',
								width: '35px',
							}}
						>
							Icon
						</Typography>

						<Avatar
							variant='square'
							alt='Remy Sharp'
							src={
								modifiedAppIcon != null
									? URL.createObjectURL(modifiedAppIcon)
									: trainingDetail.appIcons &&
									  trainingDetail.appIcons.length > 0
									? trainingDetail.appIcons[0]
									: TrainingModuleIcon
							}
						/>

						<Button
							color={'primary'}
							variant={'outlined'}
							onClick={() => handleChangeImageClick('appIcon')}
							startIcon={
								<EditIcon style={{fontSize: '16px', marginBottom: '3px'}} />
							}
						>
							Change Image
						</Button>
					</div>

					{/* Hero Icon */}

					<div
						style={{
							display: 'flex',
							columnGap: '20px',
							paddingBottom: '12px',
							alignItems: 'center',
						}}
					>
						<Typography
							style={{
								fontSize: '16px',
								fontFamily: 'Montserrat Bold',
								width: '35px',
							}}
						>
							Hero
						</Typography>

						<Avatar
							variant='square'
							alt='Remy Sharp'
							src={
								modifiedHeroIcon != null
									? URL.createObjectURL(modifiedHeroIcon)
									: trainingDetail.heroIcons &&
									  trainingDetail.heroIcons.length > 0
									? trainingDetail.heroIcons[0]
									: TrainingModuleIcon
							}
						/>

						<Button
							color={'primary'}
							variant={'outlined'}
							onClick={() => handleChangeImageClick('heroIcon')}
							startIcon={
								<EditIcon style={{fontSize: '16px', marginBottom: '3px'}} />
							}
						>
							Change Image
						</Button>
					</div>
				</div>

				{showImageUploadDialog && (
					<ImageUpload
						name={'dropzone'}
						acceptedFiles={['image/jpeg']}
						minHeight={activeImageChange === 'appIcon' ? 512 : 1080}
						minWidth={activeImageChange === 'appIcon' ? 512 : 1920}
						maxFileSize={1024000}
						placeholder={
							activeImageChange === 'appIcon'
								? 'Square Image, Image Size 1024x1024, File Size <800kb'
								: '16:9 Aspect Ratio, Image Size 2560x1440, File Size <800kb'
						}
						onChange={files => handleImageChange(files)}
					/>
				)}

				{/* Inforamtion */}

				<EmbeddedShow
					headerless
					embedded
					actions={[]}
					details={[
						{
							label: 'Name',
							render: () => (
								<div style={{flex: '1 1 auto', height: duplicateModuleName ? '80px' : '40px', maxWidth: '605px', minWidth: '366px', display: 'flex', flexDirection: 'column', marginTop: duplicateModuleName ? '-25px' : 0 }}>
									<TextField
										id='training-name'
										variant='outlined'
										color='secondary'
										className={classes.textInput}
										value={modifiedTrainingName}
										onChange={e => {
											setModifiedTrainingName(e.target.value);
											if (duplicateModuleName) setDuplicateModulename(false);
										}}
										error={
											renderMaxCharactersError(modifiedTrainingName, 50) ||
											duplicateModuleName ||
											!modifiedTrainingName ||
											modifiedTrainingName.trim().length === 0
												? true
												: false
										}
										InputProps={{
											endAdornment: (
												<InputAdornment
													position='start'
													style={{padding: 0}}
													className={classes.customText}
												>{`${
													modifiedTrainingName.length
														? modifiedTrainingName.length
														: 0
												}/50`}</InputAdornment>
											),
										}}
										style={{ paddingTop: duplicateModuleName ? '12px' : 0}}
									/>

									{renderMaxCharactersError(modifiedTrainingName, 50)}

									{(!modifiedTrainingName ||
										modifiedTrainingName.trim().length === 0) && (
										<Typography color={'error'}>Required</Typography>
									)}
									{modifiedTrainingName &&
										modifiedTrainingName.trim().length > 0 &&
										duplicateModuleName && (
											<Typography color={'error'}>
												This module name is already in use by another module
												created by your organization. Please enter a unique
												module name to save your changes
											</Typography>
										)}
								</div>
							),
						},
						{
							label: 'Series',
							render: () => (
								<div style={{flex: '1 1 auto', height: duplicateModuleName ? '80px' : '40px', width: '300px'}}>
									<TextField
										id='trainings-series'
										variant='outlined'
										color='secondary'
										className={classes.textInput}
										value={modifiedSeries}
										onChange={e => setModifiedSeries(e.target.value)}
										InputProps={{
											endAdornment: (
												<InputAdornment
													position='start'
													style={{padding: 0}}
													className={classes.customText}
												>{`${
													modifiedSeries.length ? modifiedSeries.length : 0
												}/64`}</InputAdornment>
											),
										}}
										error={renderMaxCharactersError(modifiedSeries, 64) ? true : false}
									/>

									{renderMaxCharactersError(modifiedSeries, 64)}
								</div>
							),
						},
						{
							label: 'Created',
							render: () => (
								<div style={{height: duplicateModuleName ? '80px' : '40px', paddingTop: '5px'}}>
									<TimeDateField record={trainingData} source='createdAt' />
								</div>
							),
						},
						{
							label: 'Modified',
							render: () => (
								<div style={{height: duplicateModuleName ? '80px' : '40px', paddingTop: '5px'}}>
									<TimeDateField record={trainingData} source='updatedAt' />
								</div>
							),
						},
						{
							label: 'Id',
							render: () => (
								<div style={{height: duplicateModuleName ? '80px' : '40px', paddingTop: '5px'}}>
									<TextValue record={trainingData} source='id' />
								</div>
							),
						},
					]}
				/>

				<div
					style={{
						display: 'flex',
						width: '100%',
						columnGap: '30px',
						paddingTop: '12px',
					}}
				>
					<div
						style={{
							width: '50%',
							padding: '0px 12px 12px 0px',
							height: 'fit-content',
						}}
						className={classes.section}
					>
						{/* Description */}

						<div className={classes.header} style={{marginBottom: '12px'}}>
							<Typography
								style={{
									fontFamily: 'Montserrat Semi Bold',
									fontSize: '16px',
								}}
							>
								Description
							</Typography>
							<Typography className={classes.customText}>
								{`(${
									modifiedDescription.length ? modifiedDescription.length : 0
								}/500):`}
							</Typography>
						</div>

						<TextareaAutosize
							style={{
								minWidth: '100%',
								maxWidth: '100%',
							}}
							className={`${classes.textArea} ${renderMaxCharactersError(modifiedDescription, 500) != null  ? classes.textAreaError : ''}`}
							id={'description'}
							value={modifiedDescription}
							onChange={e => setModifiedDescription(e.target.value)}
							color={'secondary'}
							variant={'contained'}
							inputprops={{
								className: classes.textarea,
							}}
						/>
						{renderMaxCharactersError(modifiedDescription, 500)}
					</div>

					{/* Images */}

					<Carousel
						sources={[...modifiedScreenShots.map(screenShot => screenShot.url)]}
						onChange={files => handleScreenShotImageChange(files)}
						onDelete={index => handleScreenShotImageDelete(index)}
					></Carousel>
				</div>
				<div style={{display: 'flex', columnGap: '30px'}}>
					<div
						className={classnames({
							[classes.section]: true,
							[classes.left]: true,
						})}
					>
						{/* Learning Goals */}

						<div className={classes.header} style={{marginBottom: '12px'}}>
							<Typography
								style={{
									fontFamily: 'Montserrat Semi Bold',
									fontSize: '16px',
								}}
							>
								Learning Goals
							</Typography>
							<Typography className={classes.customText}>{`(${
								modifiedLearningGoals.length ? modifiedLearningGoals.length : 0
							}/2000):`}</Typography>
						</div>

						<TextareaAutosize
							style={{
								minWidth: '100%',
								maxWidth: '100%',
							}}
							className={`${classes.textArea} ${renderMaxCharactersError(modifiedLearningGoals, 2000) != null  ? classes.textAreaError : ''}`}
							id={'learningGoals'}
							value={modifiedLearningGoals}
							onChange={e => setModifiedLearningGoals(e.target.value)}
							color={'secondary'}
							variant={'contained'}
							inputprops={{
								className: classes.textarea,
							}}
						/>
						{renderMaxCharactersError(modifiedLearningGoals, 2000)}
					</div>

					<div
						className={classnames({
							[classes.section]: true,
							[classes.right]: true,
						})}
					>
						{/* Learning Objectives */}

						<div className={classes.header} style={{marginBottom: '12px'}}>
							<Typography
								style={{
									fontFamily: 'Montserrat Semi Bold',
									fontSize: '16px',
								}}
							>
								Learning Objectives
							</Typography>

							<Typography className={classes.customText}>{`(${
								modifiedLearningObjectives.length
									? modifiedLearningObjectives.length
									: 0
							}/2000):`}</Typography>
						</div>

						<TextareaAutosize
							style={{
								minWidth: '100%',
								maxWidth: '100%',
							}}
							className={`${classes.textArea} ${renderMaxCharactersError(modifiedLearningObjectives, 2000) != null  ? classes.textAreaError : ''}`}
							id={'learningObjectives'}
							value={modifiedLearningObjectives}
							onChange={e => setModifiedLearningObjectives(e.target.value)}
							color={'secondary'}
							variant={'contained'}
							inputprops={{
								className: classes.textarea,
							}}
						/>

						{renderMaxCharactersError(modifiedLearningObjectives, 2000)}
					</div>
				</div>

				{/* Module Categories*/}

				<div className={classes.section}>
					<div className={classes.header}>
						<Typography>Module Categories</Typography>
					</div>

					<div
						style={{
							padding: '16px 0px 0px 16px',
							display: 'flex',
							flexDirection: 'row',
							gap: '10px',
							flexWrap: 'wrap',
						}}
					>
						{modifiedTrainingCategories.map((category, i) => {
							return (
								<span
									key={category}
									style={{
										padding: '5px 10px',
										margin: '0px 5px 0px 0px',
										border: '1.5px solid #85A0AE',
										borderRadius: '20px',
										fontWeight: 'bold',
										color: '#292C3C',
										display: 'flex',
										gap: '5px',
										alignItems: 'center',
									}}
								>
									<span>{category}</span>
									<IconButton
										style={{padding: 0}}
										onClick={() => removeTrainingCategories(i)}
									>
										<CloseIcon style={{color: '#BDBDBD'}} />
									</IconButton>
								</span>
							);
						})}

						<Button
							color={'primary'}
							variant={'outlined'}
							onClick={() => setShowAddCategoriesDailog(true)}
							startIcon={<AddOutlined style={{marginBottom: '2px'}} />}
						>
							Add Categories
						</Button>
					</div>

					{/* Skills */}

					<div className={classes.section}>
						<div style={{}} className={classes.header}>
							<Typography>Skills</Typography>
						</div>

						<FeaturedSkills
							numberOfAvailableSkills={numberOfAvailableSkills}
							skills={modifiedSkills}
							onEdit={() => setShowEditSkillsDailog(true)}
						/>
					</div>

					{/* About the Series */}

					<div className={classes.section}>
						<div className={classes.header}>
							<Typography>About the Series</Typography>

							<Typography className={classes.customText}>{`(${
								modifiedAboutSeries.length ? modifiedAboutSeries.length : 0
							}/2000):`}</Typography>
						</div>

						<div style={{paddingTop: '16px'}}>
							<TextareaAutosize
								style={{
									minWidth: '100%',
									maxWidth: '100%',
								}}
								className={`${classes.textArea} ${renderMaxCharactersError(modifiedAboutSeries, 2000) != null  ? classes.textAreaError : ''}`}
								id={'aboutSeries'}
								value={modifiedAboutSeries}
								onChange={e => setModifiedAboutSeries(e.target.value)}
								color={'secondary'}
								variant={'contained'}
								inputprops={{
									className: classes.textarea,
								}}
							/>

							{renderMaxCharactersError(modifiedAboutSeries, 2000)}
						</div>
					</div>

					{/* Additional Inforamtion */}
					<div className={classes.section}>
						<div className={classes.header}>
							<Typography>Additional Information</Typography>

							<Typography className={classes.customText}>{`(${
								modifiedAdditionalInfo.length
									? modifiedAdditionalInfo.length
									: 0
							}/2000):`}</Typography>
						</div>

						<div style={{paddingTop: '16px'}}>
							<TextareaAutosize
								style={{
									minWidth: '100%',
									maxWidth: '100%',
								}}
								className={`${classes.textArea} ${renderMaxCharactersError(modifiedAdditionalInfo, 2000) != null  ? classes.textAreaError : ''}`}
								id={'additionalInformation'}
								value={modifiedAdditionalInfo}
								onChange={e => setModifiedAdditionalInfo(e.target.value)}
								color={'secondary'}
								variant={'contained'}
								inputprops={{
									className: classes.textarea,
								}}
							/>

							{renderMaxCharactersError(modifiedAdditionalInfo, 2000)}
						</div>
					</div>
					{/* https://talespin.atlassian.net/browse/RP-1535 */}

					{/* <div
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<FormControlLabel
							control={<Checkbox name='streaming-url' />}
							label='Include Streaming URL'
							checked={includeStreamingUrl}
							onChange={e => setIncludeStreamingUrl(e.target.checked)}
						/>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								paddingTop: '5px',
							}}
						>
							<TextField
								id='streaming-content-id'
								variant='outlined'
								color='secondary'
								className={classes.textInput}
								value={streamingContentId}
								placeholder={'Content Id'}
								onChange={e => setStreamingContentId(e.target.value)}
								error={
									includeStreamingUrl &&
									(!streamingContentId ||
										streamingContentId.trim().length === 0)
								}
							/>

							{includeStreamingUrl &&
								(!streamingContentId ||
									streamingContentId.trim().length === 0) && (
									<Typography color={'error'}>Required</Typography>
								)}
						</div>
					</div> */}
				</div>
			</div>

			{showAddCategoriesDailog && (
				<AddTrainingCategoriesDailog
					availableCategories={trainingDetail.availableCategories}
					onClose={() => setShowAddCategoriesDailog(false)}
					onUpdate={addTrainingCategories}
					selectedCategories={modifiedTrainingCategories}
					trainingName={trainingDetail.name}
				/>
			)}

			{showEditSkillsDailog && (
				<EditSkillsDialog
					onClose={() => setShowEditSkillsDailog(false)}
					onUpdate={updateSkills}
					appKeyName={trainingData.appKeyName}
					modifiedSkills={modifiedSkills}
					numberOfAvailableSkills={numberOfAvailableSkills}
					trainingId={trainingData.id}
					trainingName={trainingDetail.name}
				/>
			)}
		</div>
	);
};

const myStyles = theme =>
	createStyles({
		textInput: {
			'& input': {
				padding: '5px',
			},
			width: '100%',
		},
		textarea: {
			resize: 'both',
		},
		header: {
			backgroundColor: theme.palette.background.default,
			padding: '8px 16px',
			display: 'flex',
			alignItems: 'baseline',
			gap: '5px',
			'& p:first-of-type': {
				fontFamily: 'Montserrat Semi Bold',
				fontSize: '16px',
			},
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
		section: {
			padding: '12px 0px',
		},
		normalText: {
			fontFamily: 'Montserrat Regular',
		},
		textArea: {
			fontFamily: 'Montserrat Regular',
			fontSize: '16px',
			wordSpacing: '5px',
			lineHeight: '20px',
			border: `1px solid ${theme.palette.background.default}`,
			padding: '12px',
			'&:focus': {
				border: `2px solid ${theme.palette.background.default}`,
				outline: ' none !important',
			},
			minHeight: '200px',
			maxHeight: 'auto',
		},
		textAreaError: {
			borderColor: '#f44336',
		},
		left: {
			width: ' calc(50% - 15px)',
			float: 'left',
		},
		right: {
			width: ' calc(50% - 15px)',
			float: 'right',
		},
		customText: {
			color: '#85A0AE !important',
			'& p': {
				color: '#85A0AE !important',
			},
		},
	});

const enhance = compose(withStyles(myStyles));

export default enhance(EditTrainingContent);
