import React from 'react';

/**
 * Renders any parameters passed in. Useful for quick testing a records contents.
 *
 * @param {*} params
 */
const DebugText = params => {
	return <div style={{overflow: 'none',
    width: '100%',
    whiteSpace: 'pre',
    wordBreak: 'normal',
    wordWrap: 'normal'
	}}>
		{JSON.stringify(params, null, 2)}
	</div>;
};

export default DebugText;
