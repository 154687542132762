import {loadObject, saveObject} from 'utils/Util';
import { useEffect, useState } from 'react';

import {GET_ONE, useDataProvider} from 'react-admin';

function useDevelop() {
	const cachedRunwayOrgConfig = loadObject('runwayOrgConfig');
	const [state, setState] = useState({
		loading: true,
		runwayOrgConfig: cachedRunwayOrgConfig,
	});

	const dataProvider = useDataProvider();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const {data: runwayOrgConfig} = await dataProvider(GET_ONE,
					'runwayOrgConfig'
				);
				saveObject('runwayOrgConfig', runwayOrgConfig);
				setState(state => ({
					...state,
					runwayOrgConfig: runwayOrgConfig,
					loading: false,
				}));
			} catch {}
		}
		fetchData();
	}, [dataProvider]);

	const config =
		state.runwayOrgConfig && state.runwayOrgConfig.config
			? state.runwayOrgConfig.config
			: {};

	if (process.env.REACT_APP_DEBUG_MODE_JONATHANM) {
		return {
			...state,
			isDevelop: true,
			isDebug: true,
			isLicense: true,
			isTools: true,
			isDialog: true,
		};
	}

	const dataReturned = {
		...state,
		isDevelop:
			config.isDevelop === 'true' ||
			config.isDebug === 'true' ||
			config.isLicense === 'true' ||
			config.isTools === 'true' ||
			config.isDialog === 'true',
		isDebug: config.isDebug === 'true',
		isLicense: config.isLicense === 'true',
		isTools: config.isTools === 'true',
		isDialog: config.isDialog === 'true',
	};

	return dataReturned;
}

export default useDevelop;
