import React, { useEffect, useState, useRef } from 'react';
import { CREATE, CreateButton, MenuItemLink, useDataProvider, useRefresh, useTranslate } from 'react-admin';
import { Redirect } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import List from '@material-ui/core/List';
import AddUserToTeamsDialog from './Dialogs/AddUserToTeamsDialog';
import DisableUserButton from './Buttons/DisableUserButton';
import ExportTrainingDataButton from './Buttons/ExportTrainingDataButton';
import ApplySampleDataButton from './Buttons/ApplySampleDataButton';
import pageIcon from 'assets/PageIcon.svg';
import TestBeakerIcon from '../../components/Icons/TestBeakerIcon';
import { Route } from 'react-router';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, LinearProgress, MenuItem, Popover, Tooltip, Typography } from '@material-ui/core';
import UserCreate from './Create';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getUserInfo } from 'utils/Data';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { loadObject } from 'utils/Util';
import ResetPerformanceDataButton from '../../components/Button/ResetPerformanceDataButton';
import {
	DialogTitle,
	EmbeddedList,
	ScreenTitle,
	StatusChip,
	Text,
	TimeSinceField,
} from 'components';
import { GET } from "services/customActions";
import DeleteUserButton from "sections/User/Buttons/DeleteUserButton";
import { AddOutlined } from '@material-ui/icons';

const UserList = props => {
	const { classes } = props;

	const translate = useTranslate();
	const dataProvider = useDataProvider();
	const refresh = useRefresh();

	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const isDormancyEnabled = runwayOrgConfig?.config?.newPALTermDormantEnabled === 'true';

	const bulkEditActivePollDurationSeconds = Number(runwayOrgConfig?.config.bulkEditActivePollDurationSeconds);
	const bulkEditInactivePollDurationSeconds = Number(runwayOrgConfig?.config.bulkEditInactivePollDurationSeconds);

	const [expandBulkActionButton, setExpandBulkActionButton] = useState(false);
	const [bulkActionMenuOpen, setBulkActionMenuOpen] = useState(false);
	const [bulkActionMenuAnchorEl, setBulkActionMenuAnchorEl] = useState(null);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [error, setError] = useState(null);

	const [bulkAction, setBulkAction] = useState(null);
	const [actionInProgres, setActionInProgress] = useState(false);
	const [isBackGroundBulkOpInProgress, setIsBackGroundBulkOpInProgress] = useState(false);

	const isMounted = useRef(true);

	let intervalId = null;

	const handleSelection = (record) => {
		const tempSelectedUsers = [...selectedUsers];

		const targetUser = tempSelectedUsers.find(item => item.id === record.id);

		if (!targetUser) {
			tempSelectedUsers.push(record);
		} else {
			const idx = tempSelectedUsers.indexOf(targetUser);
			if (idx >= 0) {
				tempSelectedUsers.splice(idx, 1);
			}
		}

		setSelectedUsers(tempSelectedUsers);
	}

	const openBulkActionMenu = (e) => {
		if (!e) return;

		e.stopPropagation();

		setBulkActionMenuOpen(true);
		setBulkActionMenuAnchorEl(e.target);
	}

	const closeBulkActionMenu = (e) => {
		if (!e) return;

		e.stopPropagation();

		setBulkActionMenuOpen(false);
		setBulkActionMenuAnchorEl(null);
	}

	const collapseBulkActionButton = () => {
		setExpandBulkActionButton(false);
		setSelectedUsers([]);
	}

	const closeConfirmDialog = (keepUsersSelected) => {
		setActionInProgress(false);
		setBulkAction(null);
		if (!keepUsersSelected) setSelectedUsers([]);
		setError(null);
	}

	const handleBulkSelection = (records, action) => {
		const tempSelectedUsers = [...selectedUsers];

		if (action === "add") {
			records.forEach(record => {
				if (!tempSelectedUsers.includes(record)) tempSelectedUsers.push(record);
			})
		} else if (action === "remove") {
			records.forEach(record => {
				const targetItemIndex = tempSelectedUsers.findIndex(item => item.id === record.id);

				if (targetItemIndex !== -1) tempSelectedUsers.splice(targetItemIndex, 1);
			})
		} else {
			console.log('reached')
			setSelectedUsers([]);
			return;
		}
		setSelectedUsers(tempSelectedUsers);
	}

	const isNewPALLicenseAvailable = async () => {
		let newLicenseAvailable = true;
		const nonTsUsers = selectedUsers.filter(user => user.role.indexOf('TS') === -1);
		if (nonTsUsers.length === 0) {
			return newLicenseAvailable;
		}
        
        const params = {
			numToCreate: 1,
			profileIds: nonTsUsers.map(user => user.id)
		};

		await dataProvider(GET, 'isNewPALLicenseAvailable', params)
			.then(res => {
				const availalbleLicenses = res?.data?.numAvailable || 0;

				if (availalbleLicenses >= nonTsUsers.length) {
					newLicenseAvailable = true;
				} else if (Array.isArray(res?.errors) && res.errors.length > 0) {
					newLicenseAvailable = false;
					setActionInProgress(false);
					setError(res.errors[0]?.message);
				} else {
					newLicenseAvailable = false;
				}
			})
			.catch(error => {
				newLicenseAvailable = false;
				setActionInProgress(false);
				setError(error?.message);
			});

		return newLicenseAvailable;
	};

	const performBulkAction = async () => {
		setActionInProgress(true);
		if (bulkAction === "enabled" && !await isNewPALLicenseAvailable()) return;
		setIsBackGroundBulkOpInProgress(true);
		await dataProvider(CREATE, 'bulkEditProfileStatus', {
			data: { profileIds: selectedUsers.map(user => user.id), status: bulkAction },
		})
			.then(() => {
				collapseBulkActionButton();
			})
			.catch(error => {
				setError(error.message);
				setActionInProgress(false);
			})
	}

	const getBulkEditUserOperationStatus = async (inProgress) => {
		await dataProvider(GET, 'getBulkEditUserOperationStatus')
			.then(response => {
				if (response.data.response.data.inProgress) {
					setIsBackGroundBulkOpInProgress(true);
					clearInterval(intervalId);
					startInterval(bulkEditActivePollDurationSeconds !== "NaN" ? bulkEditActivePollDurationSeconds * 1000 : 10000, true);
				} else {
					if (inProgress) {
						closeConfirmDialog();
						refresh();
					}
					setIsBackGroundBulkOpInProgress(false);
					clearInterval(intervalId);
					startInterval(bulkEditInactivePollDurationSeconds !== "NaN" ? bulkEditInactivePollDurationSeconds * 1000 : 30000, false);
				}
			})
			.catch(error => {
				clearInterval(intervalId);
				setIsBackGroundBulkOpInProgress(false);
			});
	};

	useEffect(() => {
		if (getUserInfo().isAdmin || getUserInfo().isSuper) {
			getBulkEditUserOperationStatus()
		}
	}, []);

	useEffect(() => {
		return () => {
			isMounted.current = false;
			clearInterval(intervalId);
		};
	}, [])

	const startInterval = (timer, inProgress) => {
		intervalId = setInterval(() => {
			getBulkEditUserOperationStatus(inProgress);
		}, timer);
	}

	const bulkActionMenu = () => {
		const menuItems = [{
			label: "Enable",
			onClick: () => {
				setBulkAction("enabled");
			},
			disabled: isDormancyEnabled ? !selectedUsers.every(item => item.status === "disabled") && !selectedUsers.every(item => item.status === "dormant") : !selectedUsers.every(item => item.status === "disabled"),
		},
		...(isDormancyEnabled ? [
			{
				label: "Dormant",
				onClick: () => {
					setBulkAction("dormant");
				},
				disabled: !selectedUsers.every(item => item.status === "disabled"),
			}
		]: []),
		{
			label: "Disable",
			onClick: () => {
				setBulkAction("disabled");
			},
			disabled: isDormancyEnabled ? !selectedUsers.every(item => item.status === "enabled") && !selectedUsers.every(item => item.status === "dormant") : !selectedUsers.every(item => item.status === "enabled"),
		}, {
			label: "Delete",
			onClick: () => {
				setBulkAction("deleted");
			},
			disabled: !selectedUsers.every(item => (item.status === "disabled" || item.status === "enabled")),
		}];

		return (
			<Popover
				open={bulkActionMenuOpen}
				anchorEl={bulkActionMenuAnchorEl}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				onClose={closeBulkActionMenu}
			>
				<List className={classes.menuList}>
					{menuItems.map(item => {
						return (
							<MenuItem
								key={item.label}
								onClick={(e) => {
									item.onClick();
									closeBulkActionMenu(e);
								}}
								disabled={item.disabled}
							>
								{item.label}
							</MenuItem>
						)
					})}
				</List>
			</Popover>)
	}

	if (!getUserInfo()?.isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: '/login',
					state: {from: props.location},
				}}
			/>
		);
	}

	if (getUserInfo().isUser) {
		return (
			<div>
				Access denied. Contact your administrator to raise your permissions
			</div>
		);
	}

	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to='/termsOfService' />;
	// }

	return (
		<div>
			<ScreenTitle
				basePath={props.basePath}
				resource={props.resource}
				id='users-list'
				title='Users'
			/>

			<EmbeddedList
				embedded={false}
				title={null}
				resource='users'
				columns={[
					{
						id: 'userId',
						name: translate('User ID'),
						sortable: true,
						render: record => {
							if (record.tester) {
								return (
									<div
										style={{
											whiteSpace: 'nowrap',
											flex: '1',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											minWidth: '0',
										}}
									>
										<Typography
											color='primary'
											style={{
												fontFamily: 'Montserrat Regular',
												fontSize: '14px',
											}}
											component={'div'}
										>
											<div
												title={record.userId}
												style={{
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													display: 'flex',
												}}
											>
												<TestBeakerIcon />
												{record.userId}
											</div>
										</Typography>
									</div>
								);
							} else {
								return (
									<div
										style={{
											whiteSpace: 'nowrap',
											flex: ' 1',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											minWidth: '0',
										}}
									>
										<Typography
											style={{
												fontFamily: 'Montserrat Regular',
												fontSize: '14px',
											}}
											component={'div'}
										>
											<div
												title={record.userId}
												style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
											>
												{record.userId}
											</div>
										</Typography>
									</div>
								);
							}
						},
					},
					{
						id: 'name',
						name: translate('Name'),
						sortable: true,
					},
					{
						id: 'status',
						name: translate('Status'),
						sortable: true,
						width: '125px',
						align: 'center',
						render: record => <StatusChip record={record} source='status' />,
						isInverted: true,
					},
					{
						id: 'role',
						name: translate('Role'),
						sortable: true,
						width: '125px',
						align: 'center',
						render: record => {
							switch (record.role) {
								case 'super':
									return translate('Super Admin');
								case 'TS-super':
									return translate('TS-Super Admin');
								case 'admin':
									return translate('Admin');
								case 'TS-admin':
									return translate('TS-Admin');
								case 'manager':
									return translate('Manager');
								case 'TS-manager':
									return translate('TS-Manager');
								case 'TS-user':
									return translate('TS-User');
								default:
									return translate('User');
							}
						},
					},
					{
						id: 'lastLogin',
						name: translate('Last Login'),
						align: 'right',
						sortable: true,
						width: '150px',
						render: record => (
							<TimeSinceField
								record={record}
								source='lastLogin'
								fontFamily={'Montserrat Regular'}
							/>
						),
					},
					{
						id: 'teams',
						name: translate('Teams'),
						sortable: true,
						width: '80px',
						align: 'right',
						isInverted: true,
					},
					{
						id: 'notes',
						name: translate('Notes'),
						sortable: false,
						width: '65px',
						align: 'center',
						render: record =>
							record.note ? (
								<img style={{ width: '20px' }} src={pageIcon} alt='page icon' />
							) : (
								<div></div>
							),
					},
				]}
				sort={{ field: 'userId', order: 'ASC' }}
				staticFilters={null}
				dynamicFilters={[
					{
						id: 'name',
						name: translate('Search Users'),
						type: 'safe_regex',
						display: 'search',
					},
					{
						id: 'status',
						name: translate('Status'),
						type: 'exact',
						clearBulkSelectionOnFilterChange: true,
						choices: expandBulkActionButton ? [
							{ value: 'enabled', label: translate('Enabled') },
							...(isDormancyEnabled ? [{ value: 'dormant', label: translate('Dormant')}] : []),
							{ value: 'disabled', label: translate('Disabled') }
						] : [
							{ value: '*', label: translate('All Statuses') },
							{ value: 'enabled', label: translate('Enabled') },
							...(isDormancyEnabled ? [{ value: 'dormant', label: translate('Dormant')}] : []),
							{ value: 'disabled', label: translate('Disabled') }
						]
					},
					{
						id: 'role',
						name: translate('Role'),
						type: 'exact',
						choices: [
							{ value: '*', label: translate('All Roles') },
							{
								value: { $in: ['TS-super', 'super'] },
								label: translate('Super Admin'),
							},
							{ value: { $in: ['TS-admin', 'admin'] }, label: translate('Admin') },
							{
								value: { $in: ['TS-manager', 'manager'] },
								label: translate('Manager'),
							},
							{ value: { $in: ['TS-user', 'user'] }, label: translate('User') },
						],
					},
					{
						id: 'tester',
						name: translate('Tester'),
						type: 'exact',
						choices: [
							{ value: '*', label: translate('All Users') },
							{ value: true, label: translate('Testers Only') },
							{ value: false, label: translate('Hide Testers') },
						],
					},
				]}
				bulkActions={[
					{
						id: 'bulkActions',
						render: selectedIds => {
							if (!expandBulkActionButton) {
								return (
									<div style={{ display: "flex", alignItems: "center", height: "35px", columnGap: '10px' }}>
										{isBackGroundBulkOpInProgress && <Tooltip title={"Bulk Action In Progress"}><CircularProgress style={{ height: "32px", width: '32px' }} /></Tooltip>}
										<Button
											variant={'outlined'}
											color={'primary'}
											onClick={() => setExpandBulkActionButton(true)}
										>
											{translate('Bulk Actions')}
										</Button>
									</div>
								)
							} else if (expandBulkActionButton) {
								return (
									<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
										{isBackGroundBulkOpInProgress && <Tooltip title={"Bulk Action In Progress"}><CircularProgress style={{ height: "32px", width: '32px' }} /></Tooltip>}

										<Typography style={{ paddingLeft: "5px" }}>Bulk Actions: </Typography>

										<Button className={bulkActionMenuOpen ? classes.menuButtonActive : classes.menuButton} onClick={openBulkActionMenu} disabled={selectedUsers.length === 0}>
											<MoreHorizIcon color={'primary'} style={{ opacity: selectedUsers.length === 0 ? 0.3 : 1 }} />
										</Button>

										{bulkActionMenu()}
									</div>
								)
							}
						}
					},
					...(expandBulkActionButton ?
						[{
							id: 'done',
							render: selectedIds => (
								<Button onClick={() => collapseBulkActionButton()} variant={'contained'} color={'primary'} style={{ height: "32px" }}>
									Done
								</Button>
							),
						}] : []
					),

					{
						id: 'createUser',
						render: selectedIds => (
							<CreateButton
								size='medium'
								variant={'outlined'}
								color={'primary'}
								label={translate('Create User')}
								basePath={props.basePath}
								icon={<AddOutlined style={{ marginBottom: '2px'}}/>}
							/>
						),
					},
				]}
				actions={expandBulkActionButton ? [] : [
					{
						id: 'viewUserDetail',
						render: record => (
							<MenuItemLink
								key={'viewUserDetail'}
								to={`users/${record.id}/show`}
								primaryText={translate('View User Detail')}
							/>
						),
					},
					{
						id: 'addUserToTeam',
						render: record => (
							<AddUserToTeamsDialog
								variant='link'
								label={translate('Add User to a Team')}
								userData={record}
								id={record.id}
								basePath={props.basePath}
								location={props.location}
								key={'addUserToTeam'}
							/>
						),
					},
					{
						id: 'disableUser',

						render: record => {
							if ((record.id === getUserInfo().id)) return <span key={'disableUser'}></span>;

							// if (((getUserInfo().isAdmin &&
							// record.role !== 'super' &&
							// record.role !== 'TS-super') ||
							// getUserInfo().isSuper)) 
							return (
								<DisableUserButton
								    isDormancyEnabled={isDormancyEnabled}
									variant='link'
									record={record}
									basePath={props.basePath}
									location={props.location}
									key={'disableUser'}
							    />
							)
						}
					},						
					{
						id: "delete-user",
						render: record => {

							if (record.status === 'enabled' || (isDormancyEnabled && record.status === 'dormant') || (record.id === getUserInfo().id)) return <span key={'delete-user'}></span>;

							if ((getUserInfo().isAdmin &&
								record.role !== 'super' &&
								record.role !== 'TS-super') ||
								getUserInfo().isSuper) {
								return (
									<DeleteUserButton
									    isDormancyEnabled={isDormancyEnabled}
										variant='link'
										record={record}
										basePath={props.basePath}
										location={props.location}
										key={'deleteUser'}
									/>
								)
							} else {
								return <></>;
							}
						}
					},
					{
						id: 'ResetPerformanceData',
						render: record =>
							(getUserInfo().role === 'TS-super' ||
								getUserInfo().role === 'TS-admin') && (
								<ResetPerformanceDataButton
									variant='link'
									id={record.id}
									userId={record.userId}
									basePath={props.basePath}
									location={props.location}
									fromComponentFlag={'UserList'}
									key={'ResetPerformanceData'}
								/>
							),
					},
					{
						id: 'ExportTrainingDataButton',
						render: record =>
							getUserInfo().role === 'TS-super' && (
								<ExportTrainingDataButton
									variant='link'
									id={record.id}
									basePath={props.basePath}
									location={props.location}
									key={'ExportTrainingDataButton'}
								/>
							),
					},
					{
						id: 'ImportTrainingDataButton',
						render: record =>
							getUserInfo().role === 'TS-super' && (
								<ApplySampleDataButton
									variant='link'
									id={record.id}
									basePath={props.basePath}
									location={props.location}
									key={'ImportTrainingDataButton'}
								/>
							),
					},
				]}
				rowClicked={record => props.push('/users/' + record.id + '/show')}
				selectedIds={expandBulkActionButton ? selectedUsers.map(user => user.id) : null}
				stripped={false}
				selectionToggled={expandBulkActionButton ? (record) => handleSelection(record) : null}
				onBulkSelection={(records, action) => {
					handleBulkSelection(records, action);
				}}
			/>

			<Route
				exact={false}
				path='/users/create'
				render={() => <UserCreate {...props} />}
			/>

			{/* confirm bulk action modal */}
			{bulkAction &&
				<Dialog open={true} maxWidth={false}>
					<DialogTitle onClose={() => actionInProgres ? {} : closeConfirmDialog(true)}>
						<Text
							style={{ fontSize: '20px', fontFamily: "Archivo Bold" }}
							label={translate(error ? 'Can not Perform Bulk Action' : actionInProgres ? 'Processing Bulk Action' : 'Confirm Bulk Action')}
						/>
					</DialogTitle>

					<DialogContent style={{ width: "750px", padding: "16px 32px", minHeight: "100px" }}>
						{!actionInProgres && !error &&
							<>
								{bulkAction !== "deleted" && <Typography style={{ fontSize: '14px', margin: "auto 5px" }}>
									{selectedUsers.length} {selectedUsers.length > 1 ? "Users" : "User"} will be {bulkAction === "enabled" ? "Enabled" : bulkAction === "disabled" ? "Disabled" : bulkAction === "dormant" ? "set to dormant" : "Deleted"}.
								</Typography>}

								{/* when deleting the accounts */}

								{bulkAction === "deleted" && <div>
									<Typography style={{ display: "flex" }}>You are about to permanently delete<Typography color='primary' style={{ margin: "0 5px"}} component="span">{' '}{selectedUsers.length}</Typography>user(s).</Typography>

									<div style={{ backgroundColor: "#ecf4f6", padding: "24px", marginTop: "10px" }}>
										<Typography style={{ fontFamily: "Montserrat Bold"}}>Important information to understand when deleting a user(s):</Typography>

										<ol style={{ paddingLeft: "20px" }}>
											<li><Typography>All performance data for these users will be permanently deleted and can no longer be viewed or exported within the Talespin Dashboard.</Typography></li>
											<li><Typography>The licenses associated with these users do not return to the current license term to be used by other users.</Typography></li>
											<li><Typography>If these same users are created again after being deleted, the newly created users will be considered new users in the platform and will consume new licenses from your current license term.</Typography></li>
											<li><Typography>If the users are managers of a team or teams, your user account will be set as the new manager of those teams.</Typography></li>
										</ol>
									</div>
								</div>}

							</>
						}
						{!actionInProgres && error &&
							<Typography>
								{error}
							</Typography>}
						{actionInProgres &&
							<div>
								<LinearProgress style={{ width: "100px", margin: "auto" }} />
								<Typography style={{ textAlign: "center", padding: "16px" }}>Processing...</Typography>
								<Typography>
									This action may take up to 20 seconds per account being acted upon.
									This window can be closed and the action will complete in the background.
								</Typography>
							</div>
						}
					</DialogContent>

					<DialogActions>
						{!error && !actionInProgres && <Button
							color={'secondary'}
							variant={'contained'}
							onClick={() => closeConfirmDialog(true)}
						// disabled={actionInProgres}
						>
							{translate('Cancel')}
						</Button>}
						<Button
							color={'primary'}
							variant={'contained'}
							onClick={() => !error && !actionInProgres ? performBulkAction() : closeConfirmDialog(error)}
						// disabled={actionInProgres}
						>
							{translate('OK')}
						</Button>
					</DialogActions>
				</Dialog>}
		</div>
	);
};

const myStyles = theme =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-end',
		},
		menuButton: {
			marginLeft: "10px",
			marginRight: "20px",
			minWidth: '34px',
			maxWidth: '34px',
			width: '34px',
			minHeight: '34px',
			maxHeight: '34px',
			height: '34px',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderRadius: '5px',
			borderColor: theme.palette.borderColor.default,
			backgroundColor: theme.palette.background.default,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			'& > span': {
				flex: '1 1 auto',
				minWidth: '34px',
				maxWidth: '34px',
				width: '24px',
				minHeight: '34px',
				maxHeight: '34px',
				height: '34px',
				padding: '5px',
				'&:hover': {
					backgroundColor: theme.palette.primary.main,
					borderRadius: '5px',
					'& > svg': {
						color: '#ccc',
					}
				},
			},
		},
		menuButtonActive: {
			marginLeft: "10px",
			marginRight: "20px",
			minWidth: '34px',
			maxWidth: '34px',
			width: '34px',
			minHeight: '34px',
			maxHeight: '34px',
			height: '34px',
			borderRadius: '5px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: theme.palette.primary.main,
			border: 'none',
			'& > span': {
				flex: '1 1 auto',
				minWidth: '34px',
				maxWidth: '34px',
				width: '24px',
				minHeight: '34px',
				maxHeight: '34px',
				height: '34px',
				padding: '5px',
				'& > svg': {
					color: '#fff',
				}
			},
		},
		menuList: {
			padding: '0px',
		},
		paper: {
			top: '30%',
		},
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
		},
	});

const enhance = compose(connect(undefined, { push }), withRouter);

export default enhance((withStyles(myStyles)(UserList)));
