import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	MuiThemeProvider,
	Typography,
	withStyles,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { DialogTitle, EmbeddedList } from 'components';
import { useTranslate } from 'react-admin';
import { Cancel } from '@material-ui/icons';
import { DialogListTheme } from './Theme';
import { Chip } from '@material-ui/core';
import FilterAltIcon from 'assets/FilterIcon';

const styles = theme =>
	createStyles({
		filterButton: {
			height: '32px',
			fontSize: '12px',
			fontFamily: 'Archivo Bold',
			marginLeft: '10px',
			marginRight: '10px',
			width: '100px'
		},
		selectedSkill: {
			border: `2px solid ${theme.palette.primary.main}`,
			borderRadius: '20px',
			alignItems: 'center',
		},
		skillName: {
			color: '#000000'
		},
		cancelIcon: {
			color: theme.palette.primary.main,
		},
		dialogTitle: {
			fontSize: '20px',
			fontFamily: 'Archivo Bold',
		},
		dialogContent: {
			display: 'flex',
		},
		selectedSkillListTitle: {
			paddingBottom: '18px',
			paddingTop: '25px',
			fontFamily: 'Montserrat Medium',
		},
		moreSkillsText: {
			fontFamily: 'Archivo Bold',
			textDecoration: 'underline',
			cursor: 'pointer',
			fontSize: '16px',
		},
	});

const SkillFilter = props => {
	const { preSelectedSkills, onChange, classes, skillListContextData } = props;

	const translate = useTranslate();

	const initialRender = useRef(true);

	const skillListFileter = skillListContextData
		? Object.keys(skillListContextData).map(key => ({
			id: key,
			value: skillListContextData[key],
		}))
		: undefined;

	const [selectedSkills, setSelectedSkills] = useState(
		preSelectedSkills ? preSelectedSkills : []
	);

	const [tempSelectedSkills, setTempSelectedSkills] = useState([]);

	const [isOpen, setIsOpen] = useState(false);

	const skillSelectionToggled = (record, skills) => {
		let namedSkills = [...skills];

		const targetSkill = namedSkills.find(item => item.name === record.name);

		if (!targetSkill) {
			namedSkills.push(record);
		} else {
			const idx = namedSkills.indexOf(targetSkill);
			if (idx >= 0) {
				namedSkills.splice(idx, 1);
			}
		}

		return namedSkills;
	};

	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
		} else {
			onChange(selectedSkills);
		}
	}, [selectedSkills]);

	useEffect(() => {
		if (isOpen) {
			setTempSelectedSkills(selectedSkills);
		} else setTempSelectedSkills([]);
	}, [isOpen]);

	return (
		<>
			<div 
			    style={{
				    display: 'grid',
				    gridTemplateColumns: selectedSkills.length >= 4 ? '100px auto 100px' : '100px auto',
				    alignItems: 'center',
			    }}
			>
				<Button
					variant={'contained'}
					color={'secondary'}
					onClick={() => setIsOpen(true)}
					className={classes.filterButton}
					startIcon={<FilterAltIcon color={'primary'} style={{ fontSize: '18px', marginTop: '-2px' }}/>}
				>
					Filters
				</Button>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: `repeat(${selectedSkills.length >= 4 ? 3 : selectedSkills.length},minmax(7vw, auto))`,
						paddingLeft: '10px',
						paddingRight: '10px',
						gridGap: '10px',
						marginLeft: '10px'
					}}
				>
					{selectedSkills &&
						selectedSkills.map((skill, i) =>
							i < 3 ? (
								<Chip
									classes={{ 
										root: classes.selectedSkill, 
										icon: classes.icon, 
										label: classes.skillName,
										deleteIconOutlinedColorPrimary: classes.cancelIcon
									}}
									title={skill.name}
									color={'primary'}
									key={skill.name}
									variant={'outlined'}
									size={'medium'}
									label={skill.displayName}
									onDelete={() => setSelectedSkills(skillSelectionToggled(skill, selectedSkills))}
									deleteIcon={<Cancel />}
								/>
							) : null
						)}

				</div>
				{selectedSkills.length > 3 && (
					<div
						className={classes.moreSkillsText}
						onClick={() => setIsOpen(true)}
					>{`and ${selectedSkills.length - 3} more`}</div>
				)}
			</div>
			<MuiThemeProvider theme={DialogListTheme}>
				<Dialog
					id={'skill-filter'}
					maxWidth='lg'
					open={isOpen}
					onClose={() => setIsOpen(false)}
				>
					<DialogTitle onClose={() => setIsOpen(false)}>
						<Typography className={classes.dialogTitle}>
							Skill Filter
						</Typography>
					</DialogTitle>
					<DialogContent>
						<div className={classes.dialogContent}>
							<div style={{ width: '60%' }}>
								<EmbeddedList
									embedded={true}
									title={null}
									resource='listNamedSkills'
									columns={[
										{ id: 'displayName', name: translate('Name'), sortable: true },
										{ id: 'type', name: translate('Type') }
									]}
									sort={{ field: 'displayName', order: 'ASC' }}
									staticFilters={skillListFileter}
									dynamicFilters={[
										{
											id: 'displayName',
											name: translate('Search Skills'),
											type: 'safe_regex',
										},
										{
											id: 'type',
											name: translate('Type'),
											choices: [
												{ value: '*', label: translate('All Types') },
												{ value: 'hard', label: translate('Hard') },
												{ value: 'soft', label: translate('Soft') },
											],
										},
									]}
									bulkActions={null}
									actions={null}
									rowClicked={null}
									selectedIds={
										tempSelectedSkills
											? tempSelectedSkills.map(skill => skill.name)
											: []
									}
									selectionToggled={record =>
										setTempSelectedSkills(
											skillSelectionToggled(record, tempSelectedSkills)
										)
									}
									maxHeight={'400px'}
									isPageChooser={false}
								/>
							</div>
							<div style={{ width: '40%', paddingLeft: '10px' }}>
								<div className={classes.selectedSkillListTitle}>
									{`Skills Applied to Filter ${tempSelectedSkills.length > 0
											? `(${tempSelectedSkills.length})`
											: ''
										}`}
								</div>
								<EmbeddedList
									embedded={true}
									title={'Users added to this Team'}
									resource={tempSelectedSkills ? tempSelectedSkills : []}
									columns={[
										{
											id: 'displayName',
											name: translate('Name'),
										},
									]}
									selectionToggled={record =>
										setTempSelectedSkills(
											skillSelectionToggled(record, tempSelectedSkills)
										)
									}
									splitSelection={true}
									maxHeight={'400px'}
								/>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								setSelectedSkills([]);
								setIsOpen(false);
							}}
							variant={'contained'}
							color={'secondary'}
						>
							Clear Filter
						</Button>
						<Button
							onClick={() => {
								setSelectedSkills(tempSelectedSkills);
								setIsOpen(false);
							}}
							variant={'contained'}
							color={'primary'}
						>
							Set Filter
						</Button>
					</DialogActions>
				</Dialog>
			</MuiThemeProvider>
		</>
	);
};

export default withStyles(styles)(SkillFilter);
