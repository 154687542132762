import {DELETE_TEAM} from 'services/customActions';
import DialogButtonCombo from 'components/dialogs/DialogButtonCombo_deprecated';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {getUserInfo} from 'utils/Data';
import {withDataProvider} from 'react-admin';
import {refreshView, translate} from 'ra-core';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import IconError from 'components/Icons/IconError';

const DeleteTeamButton_deprecated = props => {
	if (!getUserInfo().isAdmin && !getUserInfo().isSuper) {
		return null;
	}

	const handleClickDelete = e => {
		if (e) e.stopPropagation();
		handleDelete();
	};

	const handleDelete = async () => {
		const {dataProvider, refreshView} = props;
		await dataProvider(
			DELETE_TEAM,
			'teams',
			{
				data: {id: props.record.id},
			},
			{
				onSuccess: {
					refresh: true,
					unselectAll: true,
				},
				onFailure: {
					unselectAll: true,
					notification: {level: 'warning'},
				},
			}
		);
		refreshView();
	};

	return (
		<DialogButtonCombo
			{...props}
			error
			variant={props.variant}
			color={props.color}
			label='Delete Team'
			titleIcon={<IconError style={{marginRight: '8px'}} />}
			dialogTitle='Delete Team?'
			record={props.record}
			basePath={props.basePath}
			location={props.location}
			dialogBody={
				<Typography>
					<br />
					Users and module will be removed from this team, and this team's
					performance statistics will no longer be tracked in Runway.
					<br />
					<br />
					Do you want to delete this team?
				</Typography>
			}
			buttonConfirmLabel={'Delete Team'}
			dialogConfirmAction={handleClickDelete}
		/>
	);
};
function mapStateToProps(state) {
	return {
		form: state.form,
	};
}

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withDataProvider
);
export default enhance(DeleteTeamButton_deprecated);
