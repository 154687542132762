import {EmbeddedList, StatusChip} from 'components';
import {MenuItem} from '@material-ui/core';
import PercentField from 'components/PercentField';
import React from 'react';
import {useTranslate} from 'react-admin';
import SkillFilter from '../../../../components/SkillFilter';
import {useLocation} from 'react-router-dom';

const LessonList = props => {
	const {rowClick, trainingId, selectedSkills, skillSelected} = props;

	const location = useLocation();

	const translate = useTranslate();

	return (
		<EmbeddedList
			embedded={false}
			subTitle
			title={'Lessons'}
			resource='lessonsfortraining'
			columns={[
				{
					id: 'name',
					name: translate('Name'),
					sortable: true,
				},
				{
					id: 'lessonType',
					name: translate('Type'),
					sortable: true,
					width: '135px',
					align: 'center',
					render: record => <StatusChip record={record} source='lessonType' width='135px' />,
					isInverted: true,
				},
				{
					id: 'completionRate',
					name: translate('Avg Completion Rate'),
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='completionRate' />
					),
					isInverted: true,
				},
				{
					id: 'passingPercent',
					name: translate('Passing Score'),
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='passingPercent' />
					),
					isInverted: true,
				},
				{
					id: 'acedPercent',
					name: translate('Aced Score'),
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='acedPercent' />
					),
					isInverted: true,
				},
				{
					id: 'orgAverageRate',
					name: translate('orgAverageRate'),
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='orgAverageRate' />
					),
					isInverted: true,
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[{id: 'id', value: props.id}]}
			dynamicFilters={[
				{
					id: 'namedSkills',
					value: selectedSkills.length > 0 ? {$in: selectedSkills.map(skill => skill.name)} : undefined,
					render: () => (
						<SkillFilter
							onChange={skills => {
								skillSelected(skills);
								//appplies the selected skill filters(filters applied at the lesson level) at trainings when navigating back to /trainings.
								location.state.selectedSkills = skills;
							}}
							preSelectedSkills={
								selectedSkills.length ? selectedSkills : undefined
							}
							skillListContextData={{trainingId: trainingId}}
						/>
					),
				},
			]}
			bulkActions={[
				{
					id: 'addTraining',
				},
			]}
			actions={[
				{
					render: record => (
						<MenuItem key={'View Lesson Detail'}>
							<div onClick={() => rowClick(record.id, null, record)}>
								{translate('View Lesson Detail')}
							</div>
						</MenuItem>
					),
				},
			]}
			rowClicked={record => rowClick(record.id, null, record)}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default LessonList;
