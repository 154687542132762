import React from 'react';
import {MenuItemLink, useTranslate} from 'react-admin';
import {Typography, MenuItem, Button} from '@material-ui/core';
import {
	EmbeddedList,
	StatusChip,
	TimeSpanField,
	ScreenTitle,
	TimeDateField,
} from 'components';
import gearsIcon from 'assets/AutoAddGearsIcon.svg';
import TestBeakerIcon from 'components/Icons/TestBeakerIcon';
import EmailIcon from 'assets/EmailIcon';

const UsedLicenses = props => {
	const translate = useTranslate();

	const {
		action,
		context,
		month,
		nearingEndDate,
		orgId,
		palLicenseId,
		palLicenseUsageFilterId,
		palSpanYears,
		planContactEmail,
		selectedRecord,
		showAllYearsUsersPALUsage,
		year,
	} = props;

	const fullYear = new Date().getFullYear();
	const allYears = fullYear[0] + fullYear[fullYear - 1];

	const viewLicenceTraining = id => {
		window.localStorage.setItem(
			'activeTrainingInfo',
			JSON.stringify({id: selectedRecord.id, name: 'Training'})
		);
		window.location = `#/users/${id}/show`;
	};

	const getScreenTitle = () => {
		if (palLicenseUsageFilterId)
			switch (palLicenseUsageFilterId) {
				case 'palNewLicenseIssued':
					return 'Newly Enabled';
				case 'unpaidPalNewLicenseIssuedUSED':
					return 'Total UnPaid';
				case 'palNewLicenseIssuedUSED':
					return 'Total Used';
				case 'palDisabledUserUSED':
					return 'Total Disabled';
				case 'palDeletedUserUSED':
					return 'Total Deleted Users';
				case 'palNewLicenseIssuedTOTAL':
					return 'Total Enabled Users';
				default:
				//do nothing
			}
		return 'Licenses Used';
	};

	return (
		<div>
			<ScreenTitle
				title={
					<div>
						<span>{getScreenTitle()}</span>
						<span style={{paddingLeft: '15px', fontSize: '20px'}}>
							{context === 'PAL' ? 'PAL' : selectedRecord.name}
						</span>
					</div>
				}
				list={orgId ? '/subLicenses' : '/manageLicenses'}
				backAction={action}
				actions={
					!orgId
						? [
								{
									render: () => {
										return (
											<Button
												width={'148px'}
												variant={'outlined'}
												color={'primary'}
												style={{backgroundColor: '#ffffff'}}
												onClick={() =>
													(window.location.href = 'mailto:' + planContactEmail)
												}
												startIcon={<EmailIcon style={{ fontSize: '16px', marginBottom: '2px' }}/>}
											>
												Get More Licenses
											</Button>
										);
									},
								},
						  ]
						: []
				}
			/>
			<EmbeddedList
				embedded={false}
				filterShrink={true}
				title={null}
				resource={
					context === 'PAL'
						? orgId
							? 'listChildAppUsersPALLicenseUsedRunway'
							: 'listUsersPALLicenseUsedRunway'
						: orgId
						? 'listChildAppUsersLicenseUsedRunway'
						: 'listUsersLicenseUsedRunway'
				}
				columns={[
					{
						id: 'licenseValue',
						name: translate('License ID'),
						sortable: true,
						align: 'center',
						width: '130px',
						render: record => {
							return (
								<div>
									{record.autoEnableMore && (
										<img
											src={gearsIcon}
											alt={'gearsicon'}
											width='16px'
											style={{marginRight: '10px'}}
										/>
									)}
									{record.licenseValue}
								</div>
							);
						},
					},
					{
						id: 'userId',
						name: translate('User ID'),
						sortable: true,
						render: record => {
							if (record.tester) {
								return (
									<div
										style={{
											whiteSpace: 'nowrap',
											flex: '1',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											minWidth: '0',
										}}
									>
										<Typography
											color='primary'
											style={{
												fontFamily: 'Montserrat Regular',
												fontSize: '14px',
											}}
											component={'div'}
										>
											<div
												title={record.userId}
												style={{
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													display: 'flex',
												}}
											>
												<TestBeakerIcon />
												{record.userId}
											</div>
										</Typography>
									</div>
								);
							} else {
								return (
									<div
										style={{
											whiteSpace: 'nowrap',
											flex: ' 1',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											minWidth: '0',
										}}
									>
										<div
											style={{
												fontFamily: 'Montserrat Regular',
												fontSize: '14px',
											}}
										>
											<div
												title={record.userId}
												style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
											>
												{record.userId}
											</div>
										</div>
									</div>
								);
							}
						},
					},
					{
						id: 'name',
						name: translate('Name'),
						sortable: true,
					},
					{
						id: 'status',
						name: translate('Status'),
						sortable: true,
						width: '125px',
						align: 'center',
						render: record => <StatusChip record={record} source='status' />,
						isInverted: true,
					},
					...(context !== 'PAL'
						? [
								{
									id: 'progress',
									name: translate('Progress'),
									sortable: true,
									width: '125px',
									align: 'center',
									render: record => (
										<StatusChip record={record} source='progress' />
									),
									isInverted: false,
								},
						  ]
						: []),
					{
						id: 'startDate',
						name: translate('Started'),
						width: '125px',
						align: 'center',
						sortable: true,
						isInverted: true,
						render: record => (
							<TimeDateField
								record={record}
								source='startDate'
								fontFamily={'Montserrat Regular'}
							/>
						),
					},
					{
						id: 'endDate',
						name:
							palLicenseUsageFilterId === 'palDeletedUserUSED'
								? 'Deleted On'
								: palLicenseUsageFilterId === 'palDisabledUserUSED'
								? 'Disabled On'
								: translate('Ended'),
						width: '125px',
						align: 'center',
						sortable: true,
						isInverted: true,
						render: record => (
							<TimeDateField
								record={record}
								source='endDate'
								nearingEndDate={nearingEndDate}
							/>
						),
					},
					{
						id: 'timeSpent',
						name: translate('Time Spent'),
						sortable: true,
						align: 'center',
						render: record => (
							<TimeSpanField record={record} source='timeSpent' />
						),
						isInverted: true,
					},
				]}
				sort={{field: 'licenseValue', order: 'ASC'}}
				dynamicFilters={[
					{
						id: 'month',
						name: translate('month'),
						type: 'exact',
						choices: [
							{value: '0', label: translate('All Months')},
							{value: '1', label: translate('January')},
							{value: '2', label: translate('February')},
							{value: '3', label: translate('March')},
							{value: '4', label: translate('April')},
							{value: '5', label: translate('May')},
							{value: '6', label: translate('June')},
							{value: '7', label: translate('July')},
							{value: '8', label: translate('August')},
							{value: '9', label: translate('September')},
							{value: '10', label: translate('October')},
							{value: '11', label: translate('November')},
							{value: '12', label: translate('December')},
						],
						value: `${month}`,
					},
					{
						id: 'year',
						name: translate('year'),
						type: 'exact',
						choices:
							context === 'PAL'
								? [
										{
											value: JSON.stringify(allYears),
											label: translate('All Years'),
										},
										...palSpanYears.map(year => ({value: year, label: year})),
								  ]
								: [
										{
											value: JSON.stringify(allYears),
											label: translate('All Years'),
										},
										{value: JSON.stringify(fullYear), label: fullYear},
										{value: JSON.stringify(fullYear - 1), label: fullYear - 1},
										{value: JSON.stringify(fullYear - 2), label: fullYear - 2},
								  ],
						value: showAllYearsUsersPALUsage ? '' : year,
					},
					{
						id: 'name',
						name: translate('Search Users'),
						type: 'safe_regex',
						display: 'search',
					},
					...(context !== 'PAL'
						? [
								{
									id: 'status',
									name: translate('Status'),
									type: 'exact',
									choices: [
										{value: '*', label: translate('All Statuses')},
										{value: 'enabled', label: translate('Enabled')},
										{value: 'disabled', label: translate('Disabled')},
									],
								},
						  ]
						: []),
					{
						id: 'autoEnableMore',
						name: translate('Auto-Added'),
						type: 'exact',
						choices: [
							{value: '*', label: translate('All Licenses')},
							{value: false, label: translate('Standard')},
							{value: true, label: translate('Auto-Added')},
						],
					},
					{
						id: 'tester',
						name: translate('Tester'),
						type: 'exact',
						choices: [
							{value: '*', label: translate('All Users')},
							{value: true, label: translate('Testers Only')},
							{value: false, label: translate('Hide Testers')},
						],
					},
				]}
				actions={
					context !== 'PAL'
						? [
								{
									id: 'viewLicensedTraining',
									render: record => (
										<MenuItem key={'viewLicensedTraining'}>
											<div onClick={() => viewLicenceTraining(record.id)}>
												{translate('View Licensed Training')}
											</div>
										</MenuItem>
									),
								},
						  ]
						: [
								...(!orgId
									? [
											{
												id: 'viewUserDetail',
												render: record => (
													<MenuItemLink
														key={'viewUserDetail'}
														to={`users/${record.id}/show`}
														primaryText={translate('View User Detail')}
														disabled={record.status === 'deleted'}
													/>
												),
											},
									  ]
									: []),
						  ]
				}
				staticFilters={[
					...(context !== 'PAL'
						? [{id: 'trainingId', value: selectedRecord.id}]
						: [
								{id: 'licenseType', value: palLicenseUsageFilterId},
								{id: 'licenseId', value: palLicenseId},
						  ]),
					...(orgId !== null ? [{id: 'orgId', value: orgId}] : []),
					{id: 'month', value: month ? month : ''},
					{id: 'year', value: year ? year : ''},
				]}
			/>
		</div>
	);
};

export default UsedLicenses;
