import React, {useState} from 'react';
import {useTranslate, withDataProvider} from 'react-admin';

import {Checkbox, Button, useTheme} from '@material-ui/core';
import {GET} from 'services/customActions';
import Grid from '../layout/Grid';
import Label from '../Label';
import {getUserInfo} from 'utils/Data';
import EmailIcon from 'assets/EmailIcon';

const ProgressReport = props => {
	const translate = useTranslate();

	const theme = useTheme();

	const {dataProvider, checked, onChange, onClick} = props;

	const [isSending, setIsSending] = useState(false);

	const email = getUserInfo().email;

	const handleClick = () => {
		dataProvider(GET, 'sendInstantMonthlyProgressReportRunway').then(response =>
			response && response.response.data
				? setIsSending(response.response.data.sent)
				: null
		);
	};

	if (!email) {
		return (
			// <div
			// 	style={{
			// 		fontFamily: 'Helvetica Now Text',
			// 		fontStyle: 'Italic',
			// 		color: '#8d8d8d',
			// 	}}
			// >
			// 	Runway will email you a snapshot of  for your users.
			// 	You need to set up an{' '}
			// 	<span style={{color: '#ffc100', cursor: 'pointer'}} onClick={onClick}>
			// 		{'email address'}
			// 	</span>{' '}
			// 	to receive a Progress Report`
			// </div>
			<div>
				<div
					style={{
						fontFamily: 'Montserrat Italic',
						fontSize: '12px',
					}}
				>
					<div>
						{translate(
							'Dashboard will email you a snapshot of module progress for your users. You need to set up a'
						)}{' '}
						<span
							style={{color: theme.palette.primary.main, cursor: 'pointer'}}
							onClick={onClick}
						>
							{translate('contact email address')}
						</span>{' '}
						{translate('for your user account to receive a Progress Report')}
					</div>
				</div>
			</div>
		);
	}

	return (
		<Grid perRow={1} gridGap={'16px 16px'}>
			<div>
				<Label label={'Module Progress Reports'} />

				<div style={{display: 'flex', alignItems: 'baseline'}}>
					<Button
						size={'small'}
						color='primary'
						variant='contained'
						onClick={() => handleClick()}
						startIcon={<EmailIcon style={{ fontSize: '18px', marginBottom: '1px' }}/>}
					>
						{translate('Send Me Report')}
					</Button>
					<span
						style={{
							paddingLeft: '15px',
							fontSize: '12px',
							fontFamily: 'Montserrat Italic',
						}}
					>
						{'Send you a snapshot of module progress for your users'}
						{isSending && (
							<span
								style={{
									color: 'white',
									paddingLeft: '15px',
								}}
							>
								{'Sending...'}
							</span>
						)}
					</span>
				</div>
			</div>
			<div>
				<div
					style={{
						backgroundColor: '#0f4358',
						width: '-webkit-fill-available',
						height: '1px',
					}}
				/>

				<div style={{flexDirection: 'row', paddingTop: '10px'}}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<Checkbox
							size='small'
							checked={checked}
							onChange={e => onChange(e)}
						/>
						<span style={{fontFamily: 'MontSerrat Medium'}}>
							{translate('Subscribe to Monthly User Progress Report')}
						</span>
					</div>

					<div
						style={{
							paddingLeft: '15px',
							fontSize: '12px',
							fontFamily: 'Montserrat Italic',
						}}
					>
						Automatically send you a user progress report on the 1st of each
						month.
					</div>
				</div>
			</div>
		</Grid>
	);
};

export default withDataProvider(ProgressReport);
