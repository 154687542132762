import React from 'react';
import {useTranslate} from 'react-admin';

import TextField from '@material-ui/core/TextField';
import {createStyles, withStyles} from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const myStyles = theme =>
	createStyles({
		paper: {
			border: `1px solid ${theme.palette.borderColor.dropdownPopover}`,
		},
		fakeSelect: {
			backgroundColor: theme.palette.background.default,
			color: 'inherit',
			fontSize: 'inherit',
			borderRadius: '2px',
			height: '28px',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingLeft: '8px',
			paddingRight: '4px',
		},
		selectText: {
			width: '100%',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		svg: {
			borderRadius: '2px',
			backgroundColor: theme.palette.background.important,
			color: theme.palette.info.main,
		},
	});

const SelectInput = props => {
	const translate = useTranslate();

	props = {disabled: false, width: '100%', ...props};

	const {choices, onChange, value, mui, classes} = props;

	if (mui) {
		return <TextField {...props} />;
	}

	if (props.onClick) {
		return (
			<div
				onClick={props.onClick}
				style={{cursor: props.disabled ? 'default' : 'pointer'}}
			>
				<div className={classes.fakeSelect}>
					<div
						className={classes.selectText}
						style={{
							...props,
						}}
					>
						{props.value ? props.value : translate(props.placeholder)}
					</div>

					{!props.disabled && (
						<KeyboardArrowDownIcon className={classes.svg} color='primary' />
					)}
				</div>
				{props.helperText && (
					<div style={{marginTop: '4px'}}>{props.helperText}</div>
				)}
			</div>
		);
	}

	const select = () => {
		return (
			<Select
				value={value}
				IconComponent={ExpandMoreIcon}
				onChange={e => onChange(e)}
				disabled={props.disabled}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					getContentAnchorEl: null,
					classes: {
						paper: classes.paper,
					},
					MenuListProps: {
						classes: {root: classes.menuItem},
					},
				}}
				style={{paddingTop: '4px', paddingRight: '6px'}}
			>
				{choices &&
					choices.map(choice => (
						<MenuItem key={choice.name} value={choice.id} disabled={choice.disabled}>{choice.name}</MenuItem>
					))}
			</Select>
		);
	};

	return select();
};

const enhance = withStyles(myStyles);

export default enhance(SelectInput);
