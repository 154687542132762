import { DataOptions } from 'components/VectorMap/OptionsBlade';
import { VectorMapContext } from '../model';

export const generateOptions = (context: VectorMapContext, _data: Array<any>): DataOptions => {
    const options: DataOptions = { filter: [], group: null, sort: null, heatmap: null, detail: [], other: null };

    switch (context.context) {
        case 'org':
            break;
        case 'team':
            break;
        case 'user':
            break;
        default:
            // Do nothing.
    }

    console.log("Generated Options", options);

    return options;
}

export const optionsChanged = (_context: VectorMapContext, _prevOptions: DataOptions, newOptions: DataOptions): DataOptions => {
    console.log("Handling Change To Options...");

    return newOptions;
}
