import MUIButton from '@material-ui/core/Button';
import React from 'react';
import TSTheme from './Theme';
import Typography from '@material-ui/core/Typography';

const EmbeddedShow = props => {
	const {
		content,
		title,
		details,
		actions,
		perRow = 8,
		children,
	} = props;

	const renderDetails = itemDetails => {
		var items = [];

		if (!itemDetails.length) {
			return items;
		}

		for (var i = 0; i < itemDetails.length; i++) {
			var detail = itemDetails[i];
			if (!detail.hide) {
				items.push(
					<td key={i}>
						<div
							style={{
								paddingRight: '50px',
								paddingTop: '15px',
								marginBottom: '6px',
								paddingLeft: i === 0 ? '15px' : '0px',
							}}
						>
							{detail.render()}
						</div>
					</td>
				);
			}
		}
		return items;
	};

	const renderTitles = itemDetails => {
		var items = [];

		if (!itemDetails) {
			return items;
		}

		for (var i = 0; i < itemDetails.length; i++) {
			var detail = itemDetails[i];
			if (!detail.hide) {
				items.push(
					<th key={i}>
						<div
							style={{
								paddingTop: '10px',
								paddingBottom: '10px',
								paddingLeft: i === 0 ? '15px' : '0px',
								minWidth: '150px',
							}}
						>
							<Typography style={{fontFamily: 'Montserrat Medium'}}>
								{detail.label}
							</Typography>
						</div>
					</th>
				);
			}
		}
		return items;
	};

	const renderActions = () => {
		var items = [];

		if (!actions) {
			return items;
		}

		for (var i = 0; i < actions.length; i++) {
			var action = actions[i];

			if (action) {
				if (action.render) {
					items.push(
						<div key={i}
							style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}
						>
							{action.render()}
						</div>
					);
				} else {
					items.push(
						<div
							style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}
							key={action.label}
						>
							<MUIButton
								variant={'outlined'}
								color='primary'
								onClick={action.onClick}
								startIcon={action.startIcon}
							>
								{action.label}
							</MUIButton>
						</div>
					);
				}
			}
		}

		if (items.length === 0) {
			return <div></div>;
		}

		return (
			<div
				style={{
					height: '100%',
					display: 'flex',
				}}
			>
				{items}

				<span style={{width: '16px'}} />
			</div>
		);
	};

	const renderContent = () => {
		if (!details?.length) {
			return;
		}
		var items = [];
		var itemDetails = [...details];
		while (itemDetails.length > 0) {
			items.push(
				<table
					style={{
						borderCollapse: 'collapse',
						width: '100%',
						textAlign: 'left',
						marginBottom: '10px',
					}}
					key={items.length}
				>
					<thead>
						<tr
							style={{
								backgroundColor: TSTheme.palette.background.default,
							}}
						>
							{renderTitles(itemDetails.slice(0, perRow))}
						</tr>
					</thead>
					<tbody>
						<tr>{renderDetails(itemDetails.slice(0, perRow))}</tr>
					</tbody>
				</table>
			);
			itemDetails.splice(0, perRow);
		}

		return items;
	};

	return (
		<div
			style={{
				width: '100%',
				border: 'none',
				borderRadius: '0px',
				display: 'flex',
				flexDirection: 'column',
				background: TSTheme.palette.background.contained,
				boxShadow: props.embedded ? 'none' : '0px 5px 20px #00000026',
			}}
		>
			{!props.headerless && (
				<div
					style={{
						textAlign: 'left',
						background: TSTheme.palette.background.contained,
						width: '100%',
						height: '61px', // Height of tab header
						borderTopRightRadius: '4px',
						borderTopLeftRadius: '4px',
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						paddingLeft: props.embedded ? 0 : '20px',
						paddingRight: '10px',
						justifyContent: 'space-between'
					}}
				>
					{typeof title === 'string' ? (
						<Typography
							style={{
								fontFamily: 'Archivo Bold',
								fontSize: '20px',
							}}
						>
							{title}
						</Typography>
					) : (
						title
					)}

					{renderActions()}
				</div>
			)}

			<div
				style={{
					flex: 1,
					borderRadius: props.headerless ? '4px' : '',
					borderColor: TSTheme.palette.background.contained,
					marginRight: props.embedded ? 0 : '20px',
					marginLeft: props.embedded ? 0 : '20px',
				}}
			>
				{renderContent()}
				{content && <div>{content}</div>}
				{children && <div>{children}</div>}
			</div>

			{!props.headerless && !props.embedded && (
				<div
					style={{
						alignSelf: 'center',
						textAlign: 'center',
						background: props.embedded
							? 'transparent'
							: TSTheme.palette.background.contained,
						height: '16px',
						width: '100%',
						borderBottomRightRadius: '4px',
						borderBottomLeftRadius: '4px',
					}}
				></div>
			)}
		</div>
	);
};

export default EmbeddedShow;
