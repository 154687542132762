import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import {Button} from 'components';

const StandardButton = props => {
	return (
		<div>
			{props.variant === 'link' ? (
				<MenuItem onClick={props.onClick}>{props.label}</MenuItem>
			) : (
				<Button
					onClick={props.onClick}
					label={props.label}
					variant={props.variant}
					color={props.color}
					width={props.width}
				>
					{props.label}
				</Button>
			)}
		</div>
	);
};

export default StandardButton;
