import {createStyles, LinearProgress, withStyles} from '@material-ui/core';
import React, {Component} from 'react';

import StarIcon from '@material-ui/icons/Star';

const myStyles = theme =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			marginBottom: '10px',
			position: 'relative',
			'&:last-of-type': {
				marginBottom: '0px',
			},
		},
		progressOverlay: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			height: '128px',
			width: '128px',
			position: 'relative',
		},
		progress: {
			position: 'absolute',
			top: 0,
			left: 0,
			color: 'red',
			backgroundColor: 'green',
		},
		progressContent: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			zIndex: 99,
		},
		titleWrapper: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row-reverse',
			alignItems: 'center',
			marginTop: '5px',
			overflow: 'visible',
			maxHeight: '15px',
		},
		title: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			padding: '5px',
		},
		value: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			fontSize: '14px',
			fontFamily: 'Montserrat Bold',
		},
		progressBarWrapper: {
			position: 'relative',
		},
		mark: {
			position: 'relative',
		},
		footer: {
			marginTop: '10px',
		},
	});

const CustomLinearProgress = withStyles(theme => ({
	root: {
		height: '5px',
		borderRadius: 10,
		backgroundColor: props =>
			props.backgroundColor
				? props.backgroundColor
				: theme.palette.background.contained,
	},
	bar: {
		borderRadius: 10,
		backgroundColor: theme.palette.primary.main,
	},
}))(LinearProgress);

/**
 * Props:
 *   - title
 *     - The title/label displayed on the top-left of the component (just above the progress bar).
 *   - value
 *     - The value representing the progress (should be a value between 0 and 100).
 *   - delta
 *     - The delta of the current progress (should be a value between 0 and 100).
 *     - This delta is displayed in to the top-right of the linear progress bar instead of the value.
 *     - If omitted, the normal value is displayed to the top-right of the linear progress bar.
 *   - hideValue
 *     - If true, then the top-right displayed value/delta is hidden (the progress bar will be the only thing indicating the value).
 *   - footer
 *     - The footer displayed on the bottom of the component (just below the progress bar).
 *   - marks
 *     - An array of "marks" to display along the progress bar.
 *     - Example: [{value: 50, color: 'yellow'}, {value: 75, color: 'green'}, {value: 100, color: 'lightblue'}]
 *   - backgroundColor
 *     - Backgroundcolor of the bar(the default is white is no backgroundColor is supplied)
 *       -
 */
class LinearProgressBar extends Component {
	render() {
		const {
			classes,
			title,
			value,
			delta,
			hideValue,
			footer,
			marks,
			backgroundColor,
		} = this.props;

		return (
			<div className={classes.root}>
				<CustomLinearProgress
					variant='determinate'
					value={value}
					style={{ backgroundColor: backgroundColor}}
				/>
				{(hideValue == null || hideValue !== true) && (
					<div className={classes.titleWrapper}>
						{(hideValue == null || hideValue !== true) &&
							delta != null &&
							!isNaN(delta) && (
								<div className={classes.value}>
									<label>
										{delta > 0 ? '+' : ''}
										{delta + '%'}
									</label>
								</div>
							)}
						{(hideValue == null || hideValue !== true) &&
							(delta == null || isNaN(delta)) && (
								<div className={classes.value}>
									<label>{value + '%'}</label>
								</div>
							)}
						<div className={classes.title}>
							<label>{title}</label>
						</div>
					</div>
				)}

				{footer && <div className={classes.footer}>{footer}</div>}

				{marks &&
					marks.length > 0 &&
					marks.map(function(item, i) {
						if (!item.value) {
							return null;
						}

						return (
							<div
								className={classes.mark}
								style={{
									position: 'absolute',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									top: hideValue ? -26 : -2,
									left: 'calc(' + item.value + '% - 8px)',
									zIndex: 99,
								}}
								key={`icon-${item.color}`}
							>
								<StarIcon style={{fontSize: '16px', color: item.color}} />
								<div
									style={{
										backgroundColor: item.color,
										width: '3px',
										height: '18px',
										marginTop: '6px',
									}}
								></div>
							</div>
						);
					})}
			</div>
		);
	}
}

export default withStyles(myStyles)(LinearProgressBar);
