import {GET_LIST} from 'react-admin';
import {S2S} from 'services/customActions';
import {saveObject} from 'utils/Util';

var defaultProxy = 'https://restportalproxy.internalg.braincloudservers.com';
var envProxy = process.env.REACT_APP_REST_PROXY;
var targetProxy = envProxy ? envProxy : defaultProxy;

const fetchPALLicenseUsageOverview = async (dataProvider, filter, orgId) => {
	try {
		let response;
		if (orgId != null) {
			const s2sResp = await dataProvider(S2S, 'runEnvironmentS2SScript', {
				orgId: orgId,
				scriptName: 'runway/listPALLicenseUsageOverviewRunway',
				scriptData: {
					...filter,
				},
			});
			response = s2sResp.data;
		} else {
			response = await dataProvider(GET_LIST, 'listPALLicenseUsageOverview', {
				filter: filter,
			});
		}
		return Promise.resolve(response);
	} catch (e) {
		Promise.reject(e);
	}
};

const fetchListLicenseUsageOverview = async (dataProvider, filter, orgId) => {
	try {
		let response;
		if (orgId != null) {
			const s2sResp = await dataProvider(S2S, 'runEnvironmentS2SScript', {
				orgId: orgId,
				scriptName: 'runway/listLicenseUsageOverviewRunway',
				scriptData: {...filter},
			});
			response = s2sResp.data;
		} else {
			response = await dataProvider(GET_LIST, 'ListLicense', {
				filter: filter,
			});
		}
		return Promise.resolve(response);
	} catch (e) {
		Promise.reject(e);
	}
};

const fetchListLicenseUsageDetailOverview = async (
	dataProvider,
	filter,
	orgId
) => {
	try {
		let response;
		if (orgId != null) {
			const s2sResp = await dataProvider(S2S, 'runEnvironmentS2SScript', {
				orgId: orgId,
				scriptName: 'runway/listLicenseUsageDetailOverviewRunway',
				scriptData: {...filter},
			});
			response = s2sResp.data;
		} else {
			response = await dataProvider(GET_LIST, 'ListLicenseDetail', {
				filter: filter,
			});
		}
		return Promise.resolve(response);
	} catch (e) {
		Promise.reject(e);
	}
};

const showChildLicenses = async project => {
	let packetId = parseInt(sessionStorage.getItem(project + '-packetId')) + 1;
	if (!packetId) packetId = 1;
	sessionStorage.setItem(project + '-packetId', packetId.toString());

	const showChildLicensesRequest = new Request(
		targetProxy + '/v1/getShowChildSubLicense',
		{
			method: 'GET',
			headers: new Headers({
				Authorization: `Bearer ${sessionStorage.getItem(project + '-token')}`,
				'Content-Type': 'application/json',
				'X-PROJECT': project,
				'X-PACKETID': packetId,
			}),
		}
	);

	await fetch(showChildLicensesRequest)
		.then(response => response.json())
		.then(data => saveObject('showSubLicenses', data?.data?.show))
		.catch(e => console.error(e));
};

export const LicensesServices = {
	showChildLicenses: showChildLicenses,
	fetchPALLicenseUsageOverview: fetchPALLicenseUsageOverview,
	fetchListLicenseUsageOverview: fetchListLicenseUsageOverview,
	fetchListLicenseUsageDetailOverview: fetchListLicenseUsageDetailOverview,
};
