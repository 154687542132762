import React, {Component} from 'react';
import {GET_LIST, GET_ONE, translate, withDataProvider} from 'react-admin';
import {DialogTitle, EmbeddedList, Text} from 'components';
import {
	createStyles,
	MuiThemeProvider,
	withStyles,
} from '@material-ui/core/styles';

import {ADD_USERS_TO_TEAM} from 'services/customActions';
import {Button, Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {DialogListTheme} from 'components/Theme';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {refreshView} from 'ra-core';

const myStyles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		normalText: {
			color: theme.palette.color.default,
		},
		importantText: {
			color: theme.palette.color.important,
		},
		divider: {
			width: '100%',
			borderColor: theme.palette.borderColor.default,
		},
		listWrapper: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'auto',
		},
		selectedInformation: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			padding: '10px',
		},
		saveButton: {
			backgroundColor: theme.palette.background.primaryButton,
			color: theme.palette.color.primaryButton,
			borderColor: theme.palette.borderColor.primaryButton,
			borderWidth: '1px',
			borderStyle: 'solid',
			borderRadius: '4px',
			'&:hover': {
				backgroundColor: theme.palette.background.primaryButton,
			},
		},
	});

class AddUsersToTeamDialog extends Component {
	state = {
		ready: false,
		currentTeam: null,
		currentUsers: [],
		selectedUsers: [],
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id || this.props.open !== prevProps.open) {
			this.setState(
				{ready: false, currentTeam: null, currentUsers: [], selectedUsers: []},
				() => {
					this.fetchData();
				}
			);
		}
	}

	fetchData = async () => {
		const {id, dataProvider} = this.props;

		if (!id) {
			this.setState({ready: false, currentTeam: null, currentUsers: []});
			return;
		}

		const {data: currentTeam} = await dataProvider(GET_ONE, 'teams', {id: id});
		const {data: currentUsers} = await dataProvider(
			GET_LIST,
			'listuserforteam',
			{filter: {teamId: id}, pagination: {page: 1, perPage: 9999}}
		);

		this.setState({ready: true, currentTeam, currentUsers});
	};

	handleCloseClick = e => {
		if (e) e.stopPropagation();

		if (this.props.onClose) this.props.onClose();

		this.setState({
			ready: false,
			currentTeam: null,
			currentUsers: [],
			selectedUsers: [],
		});
	};

	handleSaveClick = async e => {
		if (e) e.stopPropagation();

		const {dataProvider, refreshView} = this.props;
		const {selectedUsers} = this.state;

		const selectedUserIds = [];
		selectedUsers.forEach(item => {
			if (item && item.id) {
				selectedUserIds.push(item.id);
			}
		});

		await dataProvider(ADD_USERS_TO_TEAM, 'teams', {
			data: {id: this.props.id, selectedIds: selectedUserIds},
		}).then(() => {
			this.handleCloseClick();
			refreshView();
		});
	};

	handleUserSelectionToggled = record => {
		const selectedUsers = [...this.state.selectedUsers];

		const targetUser = selectedUsers.find(
			item => item != null && record != null && item.id === record.id
		);

		if (targetUser == null) {
			selectedUsers.push(record);
		} else {
			const idx = selectedUsers.indexOf(targetUser);

			if (idx >= 0) {
				selectedUsers.splice(idx, 1);
			}
		}

		this.setState({selectedUsers});
	};

	render() {
		const {classes, translate, id, open} = this.props;
		const {ready, currentTeam, currentUsers, selectedUsers} = this.state;

		const selectedUserIds = [];
		selectedUsers.forEach(item => {
			if (item && item.id) {
				selectedUserIds.push(item.id);
			}
		});

		const currentUserIds = [];
		if (currentTeam && currentTeam.ownerId)
			currentUserIds.push(currentTeam.ownerId);
		currentUsers.forEach(item => {
			if (item && item.id) {
				currentUserIds.push(item.id);
			}
		});

		return (
			<MuiThemeProvider theme={DialogListTheme}>
				<Dialog
					id={'add-user-to-team-' + id}
					fullWidth
					maxWidth='md'
					open={open && ready}
					onClose={this.handleCloseClick}
					aria-label={translate('Add User to Team')}
				>
					<DialogTitle onClose={this.handleCloseClick}>
						<Text
							style={{fontSize: '20px'}}
							label={translate('Add User to Team')}
						/>
					</DialogTitle>

					<DialogContent>
						<div className={classes.listWrapper}>
							<EmbeddedList
								embedded={true}
								title={null}
								resource='users'
								columns={[
									{id: 'userId', name: translate('User ID'), sortable: true},
									{
										id: 'teams',
										name: translate('Teams'),
										sortable: true,
										align: 'center',
										isInverted: true,
									},
									{
										id: 'trainings',
										name: translate('Module'),
										sortable: true,
										align: 'center',
										isInverted: true,
									},
									{
										id: 'lessons',
										name: translate('Lessons'),
										sortable: true,
										align: 'center',
										isInverted: true,
									},
								]}
								sort={{field: 'name', order: 'ASC'}}
								staticFilters={[
									{id: 'id', value: {$nin: currentUserIds}},
									{id: 'status', value: 'enabled'},
								]}
								dynamicFilters={[
									{
										id: 'name',
										name: translate('Search Users'),
										type: 'safe_regex',
									},
								]}
								bulkActions={null}
								actions={null}
								rowClicked={null}
								selectedIds={selectedUserIds}
								selectionToggled={this.handleUserSelectionToggled}
								stripped={false}
							/>
						</div>

						{currentTeam && selectedUsers.length > 0 && (
							<hr className={classes.divider} />
						)}

						{currentTeam && selectedUsers.length > 0 && (
							<div className={classes.selectedInformation}>
								<label className={classes.importantText}>
									{selectedUsers.length}{' '}
									{selectedUsers.length > 1
										? translate('users')
										: translate('user')}
								</label>
								<label className={classes.normalText}>
									&nbsp;{translate('will be added to')}&nbsp;
								</label>
								<label className={classes.importantText}>
									{currentTeam.name}
								</label>
							</div>
						)}
					</DialogContent>

					<DialogActions>
						<Button
							className={classes.saveButton}
							onClick={this.handleSaveClick}
							variant='raised'
						>
							<label>{translate('Add to Team')}</label>
						</Button>
					</DialogActions>
				</Dialog>
			</MuiThemeProvider>
		);
	}
}

AddUsersToTeamDialog.propTypes = {
	id: PropTypes.any.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withStyles(myStyles),
	withDataProvider
);

export default enhance(AddUsersToTeamDialog);
