import './App.css';

import {Admin, GET_ONE, Resource} from 'react-admin';
import {Redirect, Route} from 'react-router-dom';
import {createStyles, withStyles} from '@material-ui/core/styles';

import CatchAll from './services/catchAll';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core';
import CustomLayout from './layout/CustomLayout';
import Dashboard from './sections/Organization';
import Debug from './sections/Debug';
import DebugJon from './sections/Debug/jonathanm';
import DebugCSS from './sections/Debug/design-css';
import DebugMateriaUI from './sections/Debug/design-materialui';
import {FullscreenError} from 'components';
import LoginPage from './sections/Login/LoginPage';
import Overview from './sections/Overview';
import React from 'react';
import {TSTheme} from './components/Theme';
import ToolsReports from './sections/ToolsReports';
import englishMessages from './i18n/en';
import {getConfig} from './services/configProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import team from './sections/Team';
import training from './sections/Training';
import tsRestProvider from './services/tsRestProvider';
import user from './sections/User';
import DebugArtAudit from './sections/Debug/design-artaudit';
import LoadingList from './components/loading/LoadingList';
import ForgotPasswordPage from './sections/Login/ForgotPasswordPage';
import Licences from './sections/Organization/Licenses/Licenses';
import TermsOfService from './sections/TermsOfService';
import {getUserInfo} from 'utils/Data';
import FutureTraining from './sections/Training/Show/FutureTraining';
import TrainingShow from './sections/Training/Show/index';
import Support from './sections/Support';
import InDevelopment from 'sections/InDevelopment';

import { isSafari, isChrome, isEdgeChromium } from 'react-device-detect';
import SystemMessageAlert from 'components/Banners/SystemMessageAlert';
import PublishTraining from 'sections/InDevelopment/Publish';
import AutoReload from 'components/AutoReload';
import SubLicenses from 'sections/SubLicenses';
import { getOrgId } from 'utils/Util';
import UnsupportedBrowser from 'components/UnsupportedBrowser';

var defaultProxy = 'https://restportalproxy.internalg.braincloudservers.com';
var envProxy = process.env.REACT_APP_REST_PROXY;
var targetProxy = envProxy ? envProxy : defaultProxy;

const i18nProvider = polyglotI18nProvider(
	locale => {
		if (locale === 'fr') {
			return import('./i18n/fr').then(messages => messages.default);
		}

		// Always fallback on english
		return englishMessages;
	},
	'en',
	{allowMissing: true}
);

const styles = theme =>
	createStyles({
		flex: {display: 'flex', flexDirection: 'column', height: '100%'},
		edge: {
			background: TSTheme.palette.background.contained,
			flex: 1,
		},
		cardHeader: {
			alignSelf: 'center',
			textAlign: 'center',
			background: TSTheme.palette.background.contained,
			width: '100%',
		},
		cardFooter: {
			alignSelf: 'center',
			textAlign: 'center',
			background: TSTheme.palette.background.contained,
			width: '100%',
			paddingTop: '14px',
			paddingBottom: '14px',
		},
	});

let project = '';
class App extends React.Component {
	state = {
		config: null,
		dataProvider: null,
		projectCode: '',
		isAuthenticated: false,
		jwt: null,
		message: null,
	};

	componentDidMount() {
		const url = '/manifest.json?' + new Date().getTime();
		const headers = new Headers({ 'Cache-Control': 'no-cache' });
		const request = new Request(url, { headers });
	  
		fetch(request)
		  .then(response => response.json())
		  .then(data => console.log(data))
		  .catch(error => console.error(error));

		let heartbeatPulse = 5; //defualut interval to poll server in minutes

		console.log(`Using server: ${targetProxy}`);

		project = window.location.hostname.split('.')[0];

		project = getOrgId();

		if (window.location.hash.includes('id_token')) {
			var projectCache = JSON.parse(sessionStorage.getItem('project'));

			if (projectCache) {
				this.setState({
					config: projectCache.config,
					projectCode: projectCache.id,
					dataProvider: tsRestProvider(targetProxy + '/v1', projectCache.id),
				});

				return;
			}
		}
		// Retrieve the base configuration data.
		getConfig(targetProxy + '/getAppSystemConfig', project)
			.then(response => {
				console.debug('Retrieved App System Config', response);
				if (response.error) {
					console.error(
						`Error getting appSystemConfig ${JSON.stringify(response.error)}`
					);
					this.setState({error: response.error});
				} else {
					sessionStorage.setItem(
						'project',
						JSON.stringify({id: project, config: response})
					);

					this.setState({
						config: response,
						projectCode: project,
						dataProvider: tsRestProvider(targetProxy + '/v1', project),
						message: null,
					});
				}
				/*
					Format of the exected config.
					{
						"authType":"sso-okta",
						"desc":"The entity contains system data used to set up the app. This entity is queried via a webhook before authentication occurs. i.e., the 'authType' dictates the login type the app will use.",
						"project" : "testproject",
						"displayTitle" : "Test Project",
						"logo" : "https://www.server.com/path/to/logo_XL.png"
					}
				*/
			})
			.catch(err => {
				console.error(`Error getting appSystemConfig ${JSON.stringify(err)}`);
			});

		// calls the server to keep the session alive
		const interval = setInterval(() => {
			if (sessionStorage.getItem(project + '-token') != null && !window.location.hash.includes('login')) {
				// when the user logout token will be null, so it stops calling server

				const runwayOrgConfigRaw = sessionStorage.getItem('runwayOrgConfig');

				const runwayOrgConfig = runwayOrgConfigRaw
					? JSON.parse(runwayOrgConfigRaw)
					: null;

				if (runwayOrgConfig?.config?.heartbeatPulse) {
					heartbeatPulse = Number(runwayOrgConfig.config.heartbeatPulse);
				}

				this.state.dataProvider(GET_ONE, 'heartbeat').catch(async err => {
					console.error(err)
					sessionStorage.removeItem(project + '-token');
					sessionStorage.removeItem(project + '-packetId');
					sessionStorage.removeItem(project + '-user');
					sessionStorage.removeItem(project + 'appLogo');
					window.location.href = '#/login';
					console.error(`Error keeping session alive ${JSON.stringify(err)}`);
				});
			}
		}, heartbeatPulse * 60 * 1000);

		return () => clearInterval(interval);
	}

	hasAuthenticated = token => {
		this.setState({isAuthenticated: true, jwt: token});
	};

	checkOnlineStatus = async () => {
		try {
			const online = await fetch(`${process.env.PUBLIC_URL}`);
			return online.status >= 200 && online.status < 300; // either true or false
		} catch (err) {
			return false; // definitely offline
		}
	}

	render() {
		const {config} = this.state;

		const style = {
			width: '100vw',
			height: '100vh',
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: '#ecf4f6',
			color: TSTheme.palette.color.default,
		};


		if (config && !(isSafari || isChrome || isEdgeChromium)) {
			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100vw',
						height: '100vh',
					}}
					id={'app'}
				>
					<UnsupportedBrowser />
				</div>
			);
		}

		return (
			<div 
				style={{
					display: 'flex',
					flexDirection: 'column',
					backgroundColor: '#ecf4f6',
					justifyContent: 'center',
					color: TSTheme.palette.color.default,
				}} 
				id={'app'}
			>
				<ThemeProvider theme={TSTheme}>
					<CssBaseline />
					{this.state.config && (
						<div>
							<Admin
								dataProvider={this.state.dataProvider}
								layout={CustomLayout}
								dashboard={Dashboard}
								catchAll={CatchAll}
								// authProvider={authProvider(
								// 	targetProxy + '/authenticationTS/AUTHENTICATE',
								// 	this.state.projectCode
								// )}
								loginPage={LoginPage}
								theme={TSTheme}
								i18nProvider={i18nProvider}
								customRoutes={[
									<Route
										path='/termsOfService'
										render={props => {
											return sessionStorage.getItem(project + '-token') !=
												null ? (
												<TermsOfService {...props} />
											) : (
												<Redirect
													to={{
														pathname: '/login',
														state: {from: props.location},
													}}
												/>
											);
										}}
									/>,
									<Route
										path='/overview'
										render={props => {
											return sessionStorage.getItem(project + '-token') !=
												null ? (
												<Overview {...props} />
											) : (
												<Redirect
													to={{
														pathname: '/login',
														state: {from: props.location},
													}}
												/>
											);
										}}
									/>,
									<Route
										path='/forgot-password'
										ForgotPasswordPage
										render={props => {
											return <ForgotPasswordPage {...props} />;
										}}
									/>,
									<Route
										path='/manageLicenses'
										render={props => {
											return sessionStorage.getItem(project + '-token') !=
												null ? (
												getUserInfo().isAdmin || getUserInfo().isSuper ? (
													<Licences {...props} />
												) : (
													<Redirect
														to={{
															pathname: '/',
															state: {from: props.location},
														}}
													/>
												)
											) : (
												<Redirect
													to={{
														pathname: '/login',
														state: {from: props.location},
													}}
												/>
											);
										}}
									/>,
									<Route
										path='/subLicenses'
										render={props => {
											return sessionStorage.getItem(project + '-token') !=
												null ? (
												getUserInfo().isAdmin || getUserInfo().isSuper ? (
													<SubLicenses {...props} />
												) : (
													<Redirect
														to={{
															pathname: '/',
															state: {from: props.location},
														}}
													/>
												)
											) : (
												<Redirect
													to={{
														pathname: '/login',
														state: {from: props.location},
													}}
												/>
											);
										}}
									/>,
									<Route
										path='/trainings/futureTrainings/:appKeyName'
										render={props => {
											const params = new URLSearchParams(props.location.search);

											return sessionStorage.getItem(project + '-token') !=
												null ? (
												<FutureTraining {...props} />
											) : (
												<Redirect
													to={{
														pathname: '/login',
														state: {
															from: props.location,
															settoptoken: params.get('settoptoken'),
														},
													}}
												/>
											);
										}}
									/>,
									<Route
										path='/trainings/:id/show'
										render={props => {
											const params = new URLSearchParams(props.location.search);

											if (sessionStorage.getItem(project + '-token') != null) {
												return (
													<TrainingShow {...props} id={props.match.params.id} />
												);
											}

											sessionStorage.setItem(
												'editModule',
												params.get('editModule')
											);
											sessionStorage.setItem(
												'addTeams',
												params.get('addTeams')
											);

											return (
												<Redirect
													to={{
														pathname: `/login`,
														search: props.location.search,
														state: {
															from: props.location,
														},
													}}
												/>
											);
										}}
									/>,
									<Route
										path='/inDevelopment/publish/:appKeyName'
										render={props => {
											if (sessionStorage.getItem(project + '-token') != null) {
												return <PublishTraining {...props} />;
											}

											return (
												<Redirect
													to={{
														pathname: `/login`,
														search: props.location.search,
														state: {
															from: props.location,
														},
													}}
												/>
											);
										}}
									/>,
									<Route
										path='/inDevelopment'
										render={props => {
											return sessionStorage.getItem(project + '-token') !=
												null ? (
												<InDevelopment {...props} />
											) : (
												<Redirect
													to={{
														pathname: '/login',
														state: {from: props.location},
													}}
												/>
											);
										}}
									/>,
									<Route
										path='/toolsReports'
										render={props => {
											return sessionStorage.getItem(project + '-token') !=
												null ? (
												<ToolsReports {...props} />
											) : (
												<Redirect
													to={{
														pathname: '/login',
														state: {from: props.location},
													}}
												/>
											);
										}}
									/>,
									<Route
										path='/support'
										render={props => {
											return sessionStorage.getItem(project + '-token') !=
												null ? (
												<Support {...props} />
											) : (
												<Redirect
													to={{
														pathname: '/login',
														state: {from: props.location},
													}}
												/>
											);
										}}
									/>,
									<Route path='/debug' component={Debug} />,
									<Route path='/debug-jonathanm' component={DebugJon} />,
									<Route path='/debug-css' component={DebugCSS} />,
									<Route path='/debug-materialui' component={DebugMateriaUI} />,
									<Route path='/debug-artaudit' component={DebugArtAudit} />,
								]}
							>
								<SystemMessageAlert />
								<AutoReload url='/index.html' tryDelay={10 * 60 * 1000} />
								<Resource {...user} />
								<Resource {...team} />
								<Resource {...training} />
								<Resource name='teamsforuser' />
								<Resource name='trainingsforuser' />
								<Resource name='traininglessonsforuser' />
								<Resource name='userassessmentperformance' />
								<Resource name='usersfortraining' />
								<Resource name='teamsfortraining' />
								<Resource name='lessonsteamtraining' />
								<Resource name='lessonsfortraining' />
								<Resource name='listuserforteam' />
								<Resource name='listtrainingforteam' />
								<Resource name='usersbypass' />
								<Resource name='teamtrainingstats' />
								<Resource name='lessonteamtrainingstats' />
								<Resource name='lessonusertrainingstats' />
								<Resource name='usertrainingstats' />
								<Resource name='teamstats' />
								<Resource name='lessonstats' />

								<Resource name='TrainingStats' />

								<Resource name='OrgStats' />
								<Resource name='orgconfig' />

								<Resource name='userindepthstats' />

								<Resource name='profileInfo' />
								<Resource name='overviewstats' />
								<Resource name='runwayOrgConfig' />
								<Resource name='LifetimeOrgAverages' />
								<Resource name='RecentTeamAverages' />
								<Resource name='LessonsCompletedByDay' />
								<Resource name='TopRecentGraduations' />

								{/* Vector Map Related Resources */}
								<Resource name='catalogvectormap' />
								<Resource name='trainingvectormap' />
								<Resource name='lessonvectormap' />
								<Resource name='skillvectormap' />
								<Resource name='linkedrolesvectormap' />
							</Admin>
						</div>
					)}
					{this.state.error && <FullscreenError {...this.state.error} />}

					{this.state.config && this.state.error && (
						<div
							style={{
								...style,
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<LoadingList size={'large'} />
						</div>
					)}
				</ThemeProvider>
			</div>
		);
	}
}

export default withStyles(styles)(App);
