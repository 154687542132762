import React, {Component} from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {withDataProvider} from 'react-admin';
import {translate} from 'ra-core';
import MenuItem from '@material-ui/core/MenuItem';
import {EXPORT_USER_TRAINING_DATA} from 'services/customActions';
import {Dialog, DialogContent, Typography} from '@material-ui/core';
import LoadingInfo from '../../../components/loading/LoadingInfo';

class ExportTrainingDataButton extends Component {
	state = {
		showDialog: false,
	};
	handleClick = e => {
		const {dataProvider, id, appKeyName = undefined} = this.props;

		this.setState({
			showDialog: true,
		});

		let payload;

		if (appKeyName) {
			payload = {
				userId: id,
				appKeyName: appKeyName,
			};
		} else {
			payload = {
				userId: id,
			};
		}
		dataProvider(
			EXPORT_USER_TRAINING_DATA,
			'exportUserTrainingDataRunway',
			payload,
			{
				onSuccess: {
					refresh: true,
					unselectAll: true,
				},
				onFailure: {
					unselectAll: true,
					notification: {level: 'warning'},
				},
			}
		)
			.then(response => {
				const filename = 'UserTrainingData.json';
				const contentType = 'application/json;charset=utf-8;';
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					var blob = new Blob(
						[decodeURIComponent(encodeURI(JSON.stringify(response.data)))],
						{type: contentType}
					);
					navigator.msSaveOrOpenBlob(blob, filename);
				} else {
					var a = document.createElement('a');
					a.download = filename;
					a.href =
						'data:' +
						contentType +
						',' +
						encodeURIComponent(JSON.stringify(response.data));
					a.target = '_blank';
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				}
				this.setState({
					showDialog: false,
				});
			})
			.catch(error => {
				console.log(error);
			});
	};

	render() {
		const {translate} = this.props;
		const {showDialog} = this.state;

		return (
			<div>
				<MenuItem onClick={this.handleClick}>
					{translate('Export Module Data')}
				</MenuItem>
				<Dialog open={showDialog} aria-label={translate('Export Module Data')}>
					<DialogContent>
						<div
							style={{
								paddingTop: '10px',
								width: '500px',
							}}
						>
							<Typography>Preparing data. Please wait...</Typography>
							<br />
							<LoadingInfo />
							<br />
						</div>
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}

ExportTrainingDataButton.propTypes = {
	dataProvider: PropTypes.func.isRequired,
	variant: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	appKeyName: PropTypes.string,
};

export default compose(withDataProvider, translate)(ExportTrainingDataButton);
