import generateModel from './model';
import { generateOptions, optionsChanged } from './options';

const TrainingMap = {
    generateModel,
    generateOptions,
    optionsChanged
}

export default TrainingMap;
