import React from 'react';

import Grid from '../layout/Grid';
import Label from '../Label';
import {SelectInput} from '../index';
import {Typography} from '@material-ui/core';

export const Settings = ({
	availableLanguages,
	availableTimezones,
	lang,
	setLang,
	timeZone,
	setTimeZone,
	isEditing,
	state,
}) => {

	const initialLang = availableLanguages.find(language => language.id === state.profileInfo.settings.language);

	const initialTimeZone = availableTimezones.find(zone => zone.id === state.profileInfo.settings.timeZone);
	
	if (isEditing)
		return (
			<Grid perRow={2} gridGap={'16px 16px'}>
				<div>
					<Label label={'Language'} />
					<SelectInput
						value={lang}
						choices={availableLanguages}
						onChange={e => setLang(e.target.value)}
					/>
				</div>
				<div>
					<Label label={'Time Zone'} />
					<SelectInput
						value={timeZone}
						choices={availableTimezones}
						onChange={e => setTimeZone(e.target.value)}
					/>
				</div>
			</Grid>
		);

	return (
		<Grid perRow={2} gridGap={'16px 16px'}>
			<div>
				<Label label={'Language'} />
				<Typography>{initialLang ? initialLang.name : ''}</Typography>
			</div>
			<div>
				<Label label={'Time Zone'} />
				<Typography>{initialTimeZone ? initialTimeZone.name : ''}</Typography>
			</div>
		</Grid>
	);
};
