/**
 * This file was mainly copied from CPDWeb project https://github.com/talespin-vr/talespin-copilot-designer-web/blob/develop/src/analytics/analytics.ts
 * See comments in https://talespin.atlassian.net/browse/APPSERV-634 for more info on the bug that had to be resolved by overriding the Posthog plugin.
 *
 */

 import Analytics from 'analytics';
 import postHogPlugin from '@metro-fs/analytics-plugin-posthog';
 import posthog from 'posthog-js';
 
 const postHogToken = process.env.REACT_APP_ANALYTICS_POSTHOG_TOKEN;
 
 const posthogOverrides = {
     identify: ({ payload }) => {
         if (payload.userId) {
             var setProperties = payload.traits;
             var setOnceProperties = {}; // ???
             posthog.identify(payload.userId, setProperties, setOnceProperties);
         }
     },
 };
 
 const customPosthog = Object.assign(
     {},
     postHogPlugin({
         token: postHogToken || '',
         enabled: true,
         options: {
             debug: process.env.NODE_ENV === 'development',
             persistence: 'memory',
             disable_cookie: true,
             disable_session_recording: true,
         },
     }),
     posthogOverrides
 );
 
 /* 
       initialize analytics and load plugins 
       TODO: load this dynamically based on org config?
   */
 const analytics = Analytics({
     app: 'Talespin Dashboard',
     debug: process.env.NODE_ENV === 'development',
     plugins: [
         // this customization of the plugin fixes a .identify bug mentioned here: https://github.com/metro-fs/analytics-plugin-posthog/issues/3
         customPosthog,
     ],
 });
 
 var registeredPlugins = [];
 
 analytics.on('registerPlugins', ({ payload }) => {
     console.log('registering plugins: ', JSON.stringify(payload.plugins));
     let plugins = payload.plugins;
     plugins.forEach((pluginName) => {
         registeredPlugins.push(pluginName);
     });
 
     // reset enabled plugins from session storage
     plugins = localStorage.getItem('analyticsPlugins');
     if (plugins != null) {
         var pluginNames = JSON.parse(plugins);
         setAnalyticsPlugins(pluginNames);
     }
 });
 
 analytics.on('enablePlugin', ({ payload }) => {
     console.log('enabling plugins: ', JSON.stringify(payload.plugins));
 });
 
 analytics.on('disablePlugin', ({ payload }) => {
     console.log('disabling plugins: ', JSON.stringify(payload.plugins));
 });
 
 export const setAnalyticsPlugins = (pluginNames) => {
     localStorage.setItem('analyticsPlugins', JSON.stringify(pluginNames));
     var promises = [];
     registeredPlugins.forEach((pluginName) => {
         var promise;
         if (pluginNames.includes(pluginName)) {
             promise = analytics.plugins.enable(pluginName);
         } else {
             promise = analytics.plugins.disable(pluginName, () => {
                 // not sure why this is required
             });
         }
         promises.push(promise);
     });
     return Promise.all(promises);
 };
 
 export default analytics;
 
