import React from 'react';
import TSTheme from '../Theme';

const Header = ({children}) => {
	const winPathName = window.location.hash;
	return (
		<div
			style={{
				textAlign: 'left',
				background: TSTheme.palette.background.contained,
				width: '100%',
				height: '51px', // Height of tab header
				borderTopRightRadius: '4px',
				borderTopLeftRadius: '4px',
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				paddingLeft: '16px',
				fontFamily: 'Montserrat Medium',
				paddingTop: winPathName === '#/forgot-password' ? '45px' : '0px',
				paddingBottom: winPathName === '#/forgot-password' ? '50px' : '0px',
			}}
		>
			{children}
		</div>
	);
};

export default Header;
