import React, { useState } from 'react';
import NavigatingDialog from './dialogs/NavigatingDialog';

// jumps to the corresponding team details or user details page to view that content

const ResourceRedirect = ({ value, id, resource, link = false, fontFamily }) => {
	const [isNavOpen, setIsNavOpen] = useState(false);

	const openDialog = () => {
		setIsNavOpen(true);
	};

	const renderTitle = () => {
		if (link) {
			return (
				<span style={{ textDecoration: 'underline', color: '#346dec' }}>
					{value}
				</span>
			);
		} else {
			return value;
		}
	};

	return (
		<div>
			<NavigatingDialog
				isOpen={isNavOpen}
				setIsOpen={setIsNavOpen}
				title={'Navigating Away'}
				navigateTo={`/${resource}/${id}/show`}
				showOnceKey={'navigateToUsersdfs'}
			>
				This will take you to another section.
				<br />
				<br />
				Do you want to navigate away from this section?
			</NavigatingDialog>

			<div
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					cursor: 'pointer',
					fontFamily: fontFamily || 'inherit'
				}}
				title={value}
				onClick={openDialog}
			>
				{renderTitle()}
			</div>
		</div>
	);
};

export default ResourceRedirect;
