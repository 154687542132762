const enMessages = {
	'Add Module to Team': 'Add Module to Team',
	'Add User to Team': 'Add User to Team',
	Admin: 'Admin',
	ra: {
		page: {
			loading: 'Loading...',
		},
		action: {
			refresh: 'Refresh',
		},
		message: {
			loading: 'Content is loading',
		},
		validation: {
			required: 'Required',
		},
	},
	authentication: {
		email: 'Email',
		universal: 'Username',
	},
	'Authorization header not found.': 'Unauthorized',
	avgScore: 'Avg Score',
	'Button.MarkAsReturned': 'Mark as returned',
	completionRate: 'Completion Rate',
	'Create Team': 'Create Team',
	createUser: 'Create User',
	disableUser: {
		managers: 'Managers',
		selectManager: 'Select a manager *',
		teams: 'Teams',
		title: 'Disabling a Manager',
	},
	Edit: 'Edit',
	'Edit User': 'Edit User',
	'Error verifying token : jwt expired': 'Session Expired.',
	'Error verifying token : jwt malformed': 'Session Expired.',
	information: {
		createdAt: 'Created At',
		email: 'Email',
		firstName: 'First Name',
		lastName: 'Last Name',
	},
	orgAverageRate: 'Org Avg Score',
	'Please assign a new manager where needed.':
		'Please assign a new manager where needed.',
	resources: {
		lessonsfortraining: {
			fields: {
				numTasks: 'Number of Tasks',
				totalPossibleScore: 'Mastery Score',
			},
		},
		teams: {
			fields: {
				ownerId: 'Manager',
			},
			name: 'Teams',
		},
		trainings: {
			fields: {
				createdAt: 'Created',
				id: 'Id',
				updatedAt: 'Modified',
			},
			name: 'Modules',
		},
		users: {
			fields: {
				note: 'Notes',
			},
			name: 'Users',
		},
	},
	score: 'Score',
	stats: {
		acedPercent: 'Aced Score',
		acedScoreRate: 'Aced Score',
		assignedLessons: 'Assigned Lessons',
		attemptsTillFirstAce: 'Attempts to Ace',
		attemptsTillFirstPass: 'Attempts to Pass',
		avgScore: 'Module Score',
		avgTime: 'Avg Time',
		completionRate: 'Completion Rate',
		inDevelopment: 'Draft',
		inNeedOfTraining: 'In Need of Training',
		inReview: 'In Review',
		published: 'Published',
		publishLimit: 'Publish Limit',
		lessonsAced: 'Lessons Aced',
		lessonsPassed: 'Lessons Passed',
		mau: 'Monthly Active Users',
		numActiveUsers: 'Active Users',
		numAssignedLessons: 'Assigned Lessons',
		numAssignedTeams: 'Assigned Teams',
		numAssignedTraining: 'Assigned Training',
		numLessons: 'Lessons',
		numLessonsAced: 'Lessons Aced',
		numLessonsPassed: 'Lessons Passed',
		numLoginsSevenDays: 'Logins last 7 days',
		numIncompleteLessons: 'Incomplete Lessons',
		numLessonsCompleteSevenDays: 'Lessons Passed this week',
		numManagers: 'Managers',
		numTasksAttemptsPassed: 'Attempts Passed',
		numTeams: 'Teams',
		numTeamsTotal: 'Total Teams',
		numTraining: 'Training Modules',
		numUsers: 'Users',
		numUnits: 'Lessons',
		orgAvgRate: 'Org Average',
		orgAvgScore: 'Org. Average',
		orgCompletionRate: 'Completion Rate',
		passingPercent: 'Passing Score',
		passingScoreRate: 'Passing Score',
		readyForMore: 'Ready For More',
		teamAverage: 'Team Average',
		teamAvgScore: 'Team Avg Score',
		teamScore: 'Team Avg',
		totalAttempts: 'Total Attempts',
		totalSkills: 'Total Skills',
		totalTime: 'Total Training',
		totalTimeFirstAceSec: 'Time to Ace',
		totalTimeFirstPassSec: 'Time to Pass',
		totalTimeSec: 'Total Time Spent',
		skillPoints: 'Skill Points',
		userScore: 'User Score',
		numUsersInTraining: 'Users in Training',
		numUsersOnTeams: 'Users on Teams',
		initialPerformanceScore: 'Initial Performance Score',
		finalPerformanceScore: 'Final Performance Score',
		performanceChange: 'Performance Change',
		assignedLabs: 'Assigned Labs',
		labsCompleted: 'Labs Completed',
		completion: 'Completion',
		assignedSteps: 'Assigned Steps',
		stepsCompleted: 'Steps Completed',
		labScore: 'Lab Score',
		assignedObjectives: 'Assigned Observables',
		objectivesCompleted: 'Observables Completed',
		stepScore: 'Step Score',
		allModules: 'All',
		licensedModules: 'Licensed',
		unlicensedModules: 'Unlicensed',
	},
	'The order "null null" has invalid direction': '',
	time: 'Time',
	'User Details': 'User Details',
	user_manager_for_teams:
		'This user is a manager of %{team_count} team(s). Please assign new manager(s)',
	userDetails: {
		training: {
			name: 'Module',
			title: 'Assigned Module',
		},
		trainingDetail: {
			name: 'Lesson Name',
			title: 'Lesson Progress',
		},
		trainingLessonDetail: {
			name: 'Lesson Name',
			title: 'Lesson Name',
		},
	},
};

export default enMessages;
