import {
	EmbeddedList,
	ScreenTitle,
	StatsTilePanel,
	StatusChip,
	TimeSpanField,
} from 'components';
import {
	fetchEnd,
	fetchStart,
	GET_LIST,
	MenuItemLink,
	useDataProvider,
	useTranslate,
	useVersion,
} from 'react-admin';
import React, {useEffect, useState} from 'react';

import Avatar from '@material-ui/core/Avatar';
import Grid from 'components/layout/Grid';
import LoadingStat from 'components/loading/LoadingStat';
import PanelStat from 'components/Panels/PanelStat';
import PercentField from 'components/PercentField';
import {Redirect} from 'react-router-dom';
import {getUserInfo} from 'utils/Data';
import {loadObject} from 'utils/Util';
import trainingModuleIcon from 'assets/MiniCardIcons/TrainingModule.svg';
import {useHistory, useLocation} from 'react-router-dom';
import SkillFilter from 'components/SkillFilter';
import { useDispatch } from 'react-redux';

const InDevelopment = props => {
	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const enableInDevelopment =
		runwayOrgConfig && runwayOrgConfig.config
			? runwayOrgConfig.config.enableInDevelopment
			: 'false';

	const dataProvider = useDataProvider();

	const dispatch = useDispatch();

	const location = useLocation();

	const history = useHistory();

	const translate = useTranslate();

	const version = useVersion();

	const [inDevelopmentStats, setInDevelopmentStats] = useState(null);

	// The location state should be used to pre-populate any "Skill" filter.
	// The state will include a "namedSkill" field if we navigated here from the Skills Map (Vector Map) via the "View Related Training" button.
	// The state will include a "selectedSkill" filed if we navigated from the training detail view either by clikcing the back icon or click on the side menu
	const [selectedSkills, setSelectedSkills] = useState(
		location.state?.namedSkill
			? [
					{
						name: location.state.namedSkill.name,
						displayName: location.state.namedSkill.displayName,
					},
			  ]
			: location.state?.selectedSkills
			? location.state.selectedSkills
			: []
	);

	const fetchStats = async () => {
		setInDevelopmentStats(null);
		try {
			dispatch(fetchStart());
			const {data: stats} = await dataProvider(GET_LIST, 'TrainingStats', {
				filter: {detailView: true},
			});
			setInDevelopmentStats(stats);
		} finally {
			dispatch(fetchEnd());
		}
	};

	useEffect(() => {
		fetchStats();
	}, [version]);

	if (!getUserInfo().isAuthenticated) {
		return <Redirect to='login' />;
	}

	if (
		(!getUserInfo().isAdmin && !getUserInfo().isSuper) ||
		!(enableInDevelopment === 'true')
	) {
		return <Redirect to='/' />;
	}

	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to='/termsOfService' />;
	// }

	return (
		<div>
			<ScreenTitle title='In Development' />

			<Grid perRow={1}>
				{inDevelopmentStats ? (
					<StatsTilePanel record={{array: {data: inDevelopmentStats}}} />
				) : (
					<PanelStat>
						<LoadingStat />
					</PanelStat>
				)}

				<EmbeddedList
					embedded={false}
					title={null}
					resource='trainings'
					columns={[
						{
							id: 'name',
							name: translate('Module Name'),
							sortable: true,
							width: '200px',
							render: record => (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										columnGap: '10px',
										alignItems: 'center',
										padding: '10px 0px',
										whiteSpace: 'nowrap',
										flex: ' 1',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										minWidth: '0',
									}}
								>
									<Avatar
										variant={'square'}
										alt='Remy Sharp'
										src={
											record.trainingDetail.appIcons &&
											record.trainingDetail.appIcons.length > 0
												? record.trainingDetail.appIcons[0]
												: trainingModuleIcon
										}
									/>
									<span
										title={record.name}
										style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
									>
										{record.name}
									</span>
								</div>
							),
						},
						{
							id: 'seriesName',
							name: translate('Series Name'),
							sortable: true,
							width: '135px',
						},
						{
							id: 'status',
							name: 'Status',
							align: 'center',
							width: '135px',
							render: record => (
								<StatusChip record={record} source='publicationStatus' />
							),
						},
						{
							id: 'lessons',
							name: translate('Lessons'),
							sortable: true,
							width: '70px',
							align: 'center',
						},
						{
							id: 'attempts',
							name: translate('Attempts'),
							sortable: true,
							width: '70px',
							align: 'center',
						},
						{
							id: 'users',
							name: translate('Users'),
							sortable: true,
							width: '70px',
							align: 'center',
						},
						{
							id: 'totalTimeSec',
							name: translate('Time Spent'),
							sortable: true,
							width: '115px',
							align: 'center',
							render: record => (
								<TimeSpanField record={record} source='totalTimeSec' />
							),
						},
						{
							id: 'completionRate',
							name: translate('Completion'),
							sortable: true,
							width: '90px',
							align: 'center',
							render: record => (
								<PercentField record={record} source='completionRate' />
							),
						},
						{
							id: 'avgScore',
							name: translate('Avg Score'),
							sortable: true,
							width: '95px',
							align: 'center',
							render: record => (
								<PercentField record={record} source='avgScore' />
							),
						},
					]}
					sort={{field: 'name', order: 'ASC'}}
					staticFilters={[
						{
							id: 'accessibility',
							value: {all: true},
						},
						{
							id: 'moduleStatus',
							value: {$ne: 'Published'},
						},
					]}
					dynamicFilters={[
						{
							id: 'name',
							name: translate('Search Training'),
							type: 'safe_regex',
						},
						{
							id: 'namedSkills',
							value:
								selectedSkills.length > 0
									? {$in: selectedSkills.map(skill => skill.name)}
									: undefined,
							render: () => (
								<SkillFilter
									onChange={skills => {
										setSelectedSkills(skills);
									}}
									preSelectedSkills={
										selectedSkills.length ? selectedSkills : undefined
									}
								/>
							),
						},
					]}
					actions={[
						{
							id: 'viewTrainingDetail',
							render: record => (
								<MenuItemLink
									to={{
										pathname: `/inDevelopment/publish/${record.appKeyName}`,
										state: {
											selectedSkills: selectedSkills,
										},
									}}
									primaryText={translate('View Module Details')}
									key={'viewTrainingDetail'}
								/>
							),
						},
					]}
					rowClicked={record =>
						history.push({
							pathname: `/inDevelopment/publish/${record.appKeyName}`,
							state: {selectedSkills: selectedSkills},
						})
					}
					selectedIds={null}
					selectionToggled={null}
					showSpinnerOnFilter={true}
				/>
			</Grid>
		</div>
	);
};

export default InDevelopment;
