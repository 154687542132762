import {LinearProgress} from 'react-admin';
import React from 'react';
import get from 'lodash/get';
import {createStyles, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(
	createStyles({
		inputStyle: {
			whiteSpace: 'wrap',
			flex: '1',
			textOverflow: 'ellipsis',
			width: '100%',
			resize: 'none',
			color: 'inherit',
			backgroundColor: 'default',
			border: 'none',
			minHeight: '90px',
			outline: 'none',
			'&:-webkit-autofill': {
				transitionDelay: 'none',
				transitionProperty: 'background-color, color',
				'-webkit-text-fill-color': 'white',
				'-webkit-box-shadow': '0 0 0px 100px #00122f inset',
				transition: 'background-color rgb(0, 18, 47, 1) ease-in-out 0s',
				caretColor: 'white',
				fontSize: 'inherit'
			}
		}
	})
);


const TextArea = (props) => {
	const { source, record = {}} = props;

	const classes = useStyles(props);

	const renderText = value => {
		return (
			<textarea
				disabled
				className={classes.inputStyle}
				value={value}
			>
			</textarea>
		);
	};

	if (!record) {
		return <LinearProgress />;
	}
	if (!get(record, source)) {
		return <div>---</div>;
	}

	return renderText(get(record, source));
};

export default TextArea;
