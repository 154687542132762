import React from 'react';
import {CircularProgress} from '@material-ui/core';

const LoadingProccess = ({style}) => {
	return (
		<CircularProgress
			size={15}
			style={{
				position: 'relative',
				marginRight: '5px',
				top: '2px',
				color: '#fff',
			}}
		/>
	);
};

export default LoadingProccess;
