import React, {Component} from 'react';
import {GET_LIST, translate, withDataProvider} from 'react-admin';
import {DialogTitle, EmbeddedList, Text} from 'components';
import {
	MuiThemeProvider,
	createStyles,
	withStyles,
} from '@material-ui/core/styles';

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	MenuItem,
} from '@material-ui/core';
import {DialogListTheme} from 'components/Theme';
import LoadingList from 'components/loading/LoadingList';
import PercentField from 'components/PercentField';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {getUserInfo} from 'utils/Data';
import {refreshView} from 'ra-core';
import {ADD_USER_TO_TEAMS} from 'services/customActions';
import { AddOutlined } from '@material-ui/icons';

const myStyles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		normalText: {
			color: theme.palette.color.default,
		},
		importantText: {
			color: theme.palette.color.important,
		},
		divider: {
			width: '100%',
			borderColor: theme.palette.borderColor.default,
		},
		spinnerStyles: {
			position: 'relative',
			marginRight: '5px',
			top: '2px',
			color: theme.palette.primary.main,
		},
	});

class AddUserToTeamsDialog extends Component {
	state = {
		error: false,
		showDialog: false,
		isLoading: true,
		selectedTeams: [],
		currentTeams: [],
		ownerTeams: [],
		isCreateProgress: false,
	};

	async fetchUser() {
		const {dataProvider, id} = this.props;

		const ownerId = getUserInfo().id;

		const {data: currentTeams} = await dataProvider(GET_LIST, 'teamsforuser', {
			filter: {userId: id},
			pagination: {page: 1, perPage: 9999},
		});

		const {data: ownerTeams} = await dataProvider(GET_LIST, 'teamsforuser', {
			filter: {userId: ownerId},
			pagination: {page: 1, perPage: 9999},
		});

		const managerOfTeams = ownerTeams.filter(
			team => team.role.indexOf('manager') >= 0
		);

		let managerOfTeamsIds = [];

		if (managerOfTeams && managerOfTeams.length > 0) {
			managerOfTeams.forEach(team => {
				if (team && team.id) {
					managerOfTeamsIds.push(team.id);
				}
			});
		}

		this.setState({
			currentTeams,
			ownerTeams: managerOfTeamsIds,
			isLoading: false,
		});
	}

	handleClick = e => {
		if (e) e.stopPropagation();

		this.setState({
			showDialog: true,
			currentTeams: [],
			selectedTeams: [],
			isLoading: true,
		});

		this.fetchUser();
	};

	handleCloseClick = e => {
		if (e) e.stopPropagation();

		if (this.props.onClick) this.props.onClick(e);

		this.setState({
			showDialog: false,
			currentTeams: [],
			selectedTeams: [],
		});
	};

	handleSaveClick = e => {
		if (e) e.stopPropagation();

		const {selectedTeams} = this.state;

		const selectedTeamIds = [];

		selectedTeams.forEach(item => {
			if (item && item.id) {
				selectedTeamIds.push(item.id);
			}
		});
		this.setState({isCreateProgress: true});

		this.handleAddToTeams({selectedIds: selectedTeamIds});
	};

	handleAddToTeams = async values => {
		const {dataProvider, userData, id, refreshView} = this.props;

		await dataProvider(
			ADD_USER_TO_TEAMS,
			'users',
			{
				data: {
					id: userData && userData.id ? userData.id : id,
					selectedIds: values.selectedIds,
				},
			},
			{
				onSuccess: {
					refresh: true,
					unselectAll: true,
				},
				onFailure: {
					unselectAll: true,
					notification: {level: 'warning'},
				},
			}
		).then(() => {
			this.setState({isCreateProgress: false});
			this.handleCloseClick();
		});
		refreshView();
	};

	handleTeamSelectionToggled = record => {
		const selectedTeams = [...this.state.selectedTeams];

		const targetTeam = selectedTeams.find(
			item => item != null && record != null && item.id === record.id
		);

		if (targetTeam == null) {
			selectedTeams.push(record);
		} else {
			const idx = selectedTeams.indexOf(targetTeam);

			if (idx >= 0) {
				selectedTeams.splice(idx, 1);
			}
		}

		this.setState({selectedTeams});
	};

	isTeamSelected = key => {
		const {selectedTeams} = this.state;

		return selectedTeams.find(item => item != null && item.id === key) != null;
	};

	render() {
		const {classes, translate, userData} = this.props;
		const {
			selectedTeams,
			currentTeams,
			showDialog,
			isLoading,
			ownerTeams,
		} = this.state;

		const selectedTeamIds = [];
		selectedTeams.forEach(item => {
			if (item && item.id) {
				selectedTeamIds.push(item.id);
			}
		});

		const currentTeamIds = [];
		// Filter out team's that already have this user as a member.
		currentTeams.forEach(item => {
			if (item && item.id) {
				currentTeamIds.push(item.id);
			}
		});

		const teamFilterValue =
			getUserInfo().isAdmin || getUserInfo().isSuper
				? {$nin: currentTeamIds}
				: {$in: ownerTeams, $nin: currentTeamIds};

		const button =
			this.props.variant === 'link' ? (
				<MenuItem onClick={this.handleClick}>{this.props.label}</MenuItem>
			) : (
				<Button
					variant={'outlined'}
					color='primary'
					onClick={() => this.handleClick()}
					startIcon={<AddOutlined style={{ marginBottom: '2px' }}/>}
				>
					{this.props.label}
				</Button>
			);

		return (
			<div>
				{button}
				<MuiThemeProvider theme={DialogListTheme}>
					{showDialog && (
						<Dialog
							fullWidth
							maxWidth='md'
							open={true}
							onClose={this.handleCloseClick}
							aria-label='Add to Team'
							onClick={e => {
								if (e) e.stopPropagation();
							}}
						>
							<DialogTitle onClose={this.handleCloseClick}>
								<Text
									style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
									label={translate('Add User to Team')}
								/>
							</DialogTitle>

							{
								<DialogContent>
									{!isLoading && (
										<>
											<div
												style={{
													flex: '1 1 auto',
													display: 'flex',
													flexDirection: 'column',
													overflow: 'auto',
												}}
											>
												<EmbeddedList
													embedded={true}
													title={null}
													resource={'teams'}
													columns={[
														{
															id: 'name',
															name: translate('Name'),
															sortable: true,
														},
														{
															id: 'training',
															name: translate('Module'),
															sortable: true,
															align: 'center',
															isInverted: true,
														},
														{
															id: 'users',
															name: translate('Users'),
															sortable: true,
															align: 'center',
															width: '100px',
															isInverted: true,
														},
														{
															id: 'completionRate',
															name: translate('Completion'),
															sortable: true,
															align: 'center',
															width: '125px',
															render: record => {
																return (
																	<PercentField
																		record={record}
																		source='completionRate'
																	/>
																);
															},
															isInverted: true,
														},
														{
															id: 'avgScore',
															name: translate('Avg Score'),
															sortable: true,
															align: 'center',
															width: '125px',
															render: record => {
																return (
																	<PercentField
																		record={record}
																		source='avgScore'
																	/>
																);
															},
															isInverted: true,
														},
													]}
													sort={{field: 'name', order: 'ASC'}}
													staticFilters={[{id: 'id', value: teamFilterValue}]}
													dynamicFilters={[
														{
															id: 'name',
															name: translate('Search'),
															type: 'safe_regex',
														},
													]}
													bulkActions={null}
													actions={null}
													rowClicked={null}
													selectedIds={selectedTeamIds}
													selectionToggled={this.handleTeamSelectionToggled}
													stripped={false}
												/>
											</div>

											<div style={{flex: '0 0 auto'}}>
												{userData && selectedTeams.length > 0 && (
													<hr className={classes.divider} />
												)}
												{userData && selectedTeams.length > 0 && (
													<div
														style={{
															flex: '1 1 auto',
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center',
															padding: '10px',
														}}
													>
														<label className={classes.importantText}>
															{userData.name ? userData.name : userData.userId}
														</label>
														<label className={classes.normalText}>
															&nbsp;{translate('will be added to')}&nbsp;
														</label>
														<label className={classes.importantText}>
															{selectedTeams.length}{' '}
															{selectedTeams.length > 1
																? translate('teams')
																: translate('team')}
														</label>
													</div>
												)}
											</div>
										</>
									)}
									{isLoading && <LoadingList />}
								</DialogContent>
							}
							<DialogActions>
								{this.state.isCreateProgress ? (
									<div style={{display: 'flex'}}>
										<CircularProgress
											size={20}
											className={classes.spinnerStyles}
										/>
										<span>{translate('Adding user...')}</span>
									</div>
								) : (
									<div>
										<Button
											variant={'contained'}
											color={'primary'}
											disabled={selectedTeamIds.length < 1}
											onClick={() => this.handleSaveClick()}
											startIcon={<AddOutlined />}
										>
											{translate('Add to Team')}
										</Button>
									</div>
								)}
							</DialogActions>
						</Dialog>
					)}
				</MuiThemeProvider>
			</div>
		);
	}
}

AddUserToTeamsDialog.propTypes = {
	dataProvider: PropTypes.func.isRequired,
	dispatch: PropTypes.func,
	userData: PropTypes.object,
	variant: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		version: get(state, 'admin.ui.viewVersion'),
	};
};

const mapDispatchToProps = {refreshView};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withStyles(myStyles),
	withDataProvider
);

export default enhance(AddUserToTeamsDialog);
