import {EmbeddedList, StatusChip, TimeSpanField} from 'components';
import {NumberField, useTranslate} from 'react-admin';

import {MenuItem} from '@material-ui/core';
import PercentField from 'components/PercentField';
import React from 'react';
import SendEmail from 'components/SendEmail';
import SkillFilter from '../../SkillFilter';

const LessonList = props => {
	const {
		lessonSelected,
		selectedTrainingRecord,
		userData,
		selectedSkills,
		skillsSelected,
	} = props;

	const translate = useTranslate();

	return (
		<EmbeddedList
			embedded={false}
			subTitle
			title={translate('userDetails.trainingDetail.title')}
			resource='traininglessonsforuser'
			columns={[
				{
					id: 'name',
					name: translate('userDetails.trainingDetail.name'),
					sortable: true,
				},
				{
					id: 'lessonType',
					name: translate('Type'),
					render: record => <StatusChip record={record} source='lessonType' />,
					sortable: true,
					isInverted: true,
				},
				{
					id: 'status',
					name: translate('Status'),
					sortable: true,
					align: 'center',
					render: record => <StatusChip record={record} source='status' />,
					isInverted: true,
				},
				{
					id: 'attempts',
					name: translate('Attempts'),
					sortable: true,
					align: 'center',
					render: record => <NumberField record={record} source='attempts' />,
					isInverted: true,
				},
				{
					id: 'totalTimeSec',
					name: translate('time'),
					sortable: true,
					align: 'center',
					render: record => (
						<TimeSpanField record={record} source='totalTimeSec' />
					),
					isInverted: true,
				},
				{
					id: 'scoreRatio',
					name: translate('score'),
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='scoreRatio' />
					),
					isInverted: true,
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[
				{id: 'trainingId', value: selectedTrainingRecord.id},
				{id: 'userId', value: userData.id},
			]}
			dynamicFilters={[
				{
					id: 'status',
					name: translate('Status'),
					type: 'exact',
					choices: [
						{value: '*', label: translate('All Statuses')},
						{value: 'not started', label: translate('Not Started')},
						{value: 'aced', label: translate('Aced')},
						{value: 'passed', label: translate('Passed')},
						{value: 'incomplete', label: translate('Incomplete')},
					],
				},
				{
					id: 'namedSkills',
					value:
						selectedSkills.length > 0
							? {$in: selectedSkills.map(skill => skill.name)}
							: undefined,
					render: () => (
						<SkillFilter
							onChange={skills => {
								skillsSelected(skills);
							}}
							skillListContextData={{
								trainingId: selectedTrainingRecord.id,
								userId: userData.id,
							}}
							preSelectedSkills={selectedSkills}
						/>
					),
				},
			]}
			bulkActions={[
				{
					id: 'addTraining',
				},
			]}
			actions={[
				{
					render: record => (
						<MenuItem key={'View Lesson Detail'}>
							<div onClick={() => lessonSelected(record.id, null, record)}>
								{translate('View Lesson Detail')}
							</div>
						</MenuItem>
					),
				},
				{
					render: record => (
						<SendEmail
							key={'Send Reminder Email'}
							id={props.id}
							title={'Send Reminder Email'}
						/>
					),
				},
			]}
			rowClicked={record => lessonSelected(record.id, null, record)}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default LessonList;
