import { SvgIcon } from "@material-ui/core";

export default function EditIcon(props) {

  return (
    <SvgIcon {...props} viewBox="0 0 16 17">
      <path
        d="M12.8 9.30001V13.9C12.8 14.4523 12.3523 14.9 11.8 14.9H2.59997C2.04769 14.9 1.59998 14.4523 1.59998 13.9V4.70001C1.59998 4.14773 2.04769 3.70001 2.59998 3.70001H7.19998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.4053 3.60358L4.80005 9.2088L4.80005 11.7L7.29126 11.7L12.8965 6.09479M10.4053 3.60358L12.8965 6.09479M10.4053 3.60358L11.6509 2.35798C11.9948 2.01402 12.5525 2.01402 12.8965 2.35798L14.1421 3.60358C14.486 3.94755 14.486 4.50522 14.1421 4.84919L12.8965 6.09479"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}

