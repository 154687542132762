import {
	ActionMenu,
	DebugContainer,
	EmbeddedList,
	NumberValue,
	PerformanceDetail,
	ScreenTitle,
	TimeDateField
} from 'components';
import {MenuItemLink, useTranslate} from 'react-admin';
import React, {useState} from 'react';
import {Redirect} from 'react-router';
import useDevelop from 'hooks/useDevelop';
import useProduction from 'hooks/useProduction';
import DatePicker from '../../components/DatePicker';
import StatsTilePanel from '../../components/StatsTilePanel';

const Debug = props => {
	const [dataPickerState, setDatePicker] = useState({
		disabled: false,
		welcomeEmailTime: Date.now(),
		welcomeEmail: true,
		sendWelcomeType: 'sendNow',
	});

	const translate = useTranslate();

	const {isProduction, loading: prodLoading} = useProduction();

	const {isDebug, loading: devLoading} = useDevelop();

	if (prodLoading || devLoading) {
		return <></>;
	}
	if (isProduction && !isDebug) {
		return <Redirect to='/' />;
	}

	const datePicker = (
		<DebugContainer large label={'DatePicker'}>
			<DatePicker state={dataPickerState} setState={setDatePicker} />
		</DebugContainer>
	);

	const actionMenu = (
		<DebugContainer label={'ActionMenu.js'}>
			<ActionMenu>
				<MenuItemLink
					to='/'
					exact
					primaryText={<label>Menu Item Link</label>}
				/>
			</ActionMenu>
		</DebugContainer>
	);

	const embeddedList = (
		<DebugContainer large label={'EmbeddedList.jsx'}>
			<EmbeddedList
				embedded={false}
				subTitle
				title={'EmbeddedList Title'}
				resource='teams'
				columns={[
					{
						id: 'createdAt',
						name: translate('Date'),
						sortable: true,
						render: record => (
							<TimeDateField record={record} source='createdAt' />
						),
					},
					{
						id: 'id',
						name: translate('Id'),
						sortable: true,
					},
					{
						id: 'name',
						name: translate('Name'),
						sortable: true,
						align: 'right',
					},
					{
						id: 'avgScore',
						name: translate('Average Score'),
						sortable: true,
						align: 'right',
						render: record => <NumberValue record={record} source='avgScore' />,
					},
				]}
				sort={{field: 'name', order: 'ASC'}}
				staticFilters={[{id: 'userId', value: props.id}]}
				dynamicFilters={null}
				bulkActions={[
					{
						id: 'addUser',
						render: selectedIds => <div></div>,
					},
				]}
				actions={[
					{
						id: 'menuAction',
						render: record => <MenuItemLink to={`/`} primaryText={'Blank'} />,
					},
				]}
				selectedIds={null}
				selectionToggled={null}
				stripped={false}
			/>
		</DebugContainer>
	);

	const statsTilePanel = (
		<DebugContainer large label={'StatsTilePanel.jsx'}>
			<StatsTilePanel
				record={{
					array: {
						data: [
							{
								id: 'numUsers',
								displayName: 'numUsers',
								value: 97,
								type: 'number',
							},
							{
								id: 'numTeams',
								displayName: 'numTeams',
								value: 16,
								type: 'number',
							},
							{
								id: 'inNeedOfTraining',
								displayName: 'inNeedOfTraining',
								value: '5 teams',
								type: 'string',
								valueColor: '#F04242',
							},
							{
								id: 'readyForMore',
								displayName: 'readyForMore',
								value: '1 team',
								type: 'string',
								valueColor: '#99FF66',
							},
							{
								id: 'numTraining',
								displayName: 'numTraining',
								value: 3,
								type: 'number',
							},
						],
					},
				}}
			/>
		</DebugContainer>
	);

	const renderScreen = (
		<ScreenTitle
			basePath={props.basePath}
			resource={props.resource}
			title={'Debug'}
		/>
	);

	return (
		<div>
			{renderScreen}

			<div style={{display: 'flex', flexWrap: 'wrap'}}>
				<DebugContainer large label={'PerformanceDetail.jsx'}>
					<PerformanceDetail
						assessments={[
							{
								title: 'Assessment 1',
								namedSkills: [
									{
										title: 'Anchoring',
										displayName: 'Anchoring',
										preScoreNumerator: 1,
										postScoreNumerator: 8,
										scoreDenominator: 10,
										changeValue: '+7',
										changeColour: '#99FF66',
										changePercent: 0.7,
									},
								],
							},
						]}
					/>
				</DebugContainer>
				{statsTilePanel}
				{datePicker}
				{actionMenu}
				{embeddedList}
			</div>
		</div>
	);
};

export default Debug;
