import React, {useState} from 'react';
import {vectorMapSystemEnabled, vectorMapUserEnabled} from 'utils/vectorMap';

import EmbeddedShow from '../components/EmbeddedShow';
import SkillBar from './SkillBar';
import SkillPopover from './SkillPopover';
import VectorMap from 'components/VectorMap';
import moment from 'moment';

const SkillBarPanel = props => {
	const {data, actions, vectorMapContextData, fromComponent, showLastProcessedTime = true} = props;

	const skillData = data ? (data.skillData ? data.skillData : data) : [];

	const runwayOrgConfig = JSON.parse(sessionStorage.getItem('runwayOrgConfig'));

	const {
		enableSkillsMapViewTeam,
		enableSkillsMapViewTraining,
	} = runwayOrgConfig.config;

	const [useVectorMap, setUseVectorMap] = useState(
		vectorMapContextData ? vectorMapUserEnabled() : false
	);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const [activeSkill, setActiveSKill] = useState();

	const getProgressData = () => {
		return data.skillData
			? {
					timeSpent: data.timeSpent,
					score: data.score,
			  }
			: undefined;
	};

	const showMapView = () => {
		switch (fromComponent) {
			case 'teams':
				return enableSkillsMapViewTeam === 'true';
			case 'trainings':
				return enableSkillsMapViewTraining === 'true';
			default:
				return true;
		}
	};

	const renderOverview = selData => {
		var list = [];

		if (!selData) {
			return list;
		}

		for (var i = 0; i < selData.length; i++) {
			list.push(
				<SkillBar
					key={selData[i].id}
					large={i === 0}
					data={selData[i]}
					progressData={getProgressData()}
				/>
			);

			return list;
		}
	};

	let vectorMapContext = 'org';
	if (vectorMapContextData && vectorMapContextData.teamId)
		vectorMapContext = 'team';
	if (vectorMapContextData && vectorMapContextData.userId)
		vectorMapContext = 'user';

	if (
		vectorMapContextData &&
		vectorMapSystemEnabled(vectorMapContext, 'skill-map') &&
		useVectorMap
	) {
		return (
			<VectorMap
				title={'Skills'}
				type={'skill-map'}
				contextData={vectorMapContextData}
				onTableViewClicked={() => {
					vectorMapUserEnabled(false);
					setUseVectorMap(false);
				}}
			/>
		);
	} else {
		return (
			<>
				<EmbeddedShow
					title={'Skills'}
					actions={[
						...(actions ? actions : []),
						showMapView() &&
						vectorMapContextData &&
						vectorMapSystemEnabled(vectorMapContext, 'skill-map')
							? {
									label: 'Map View',
									onClick: () => {
										vectorMapUserEnabled(true);
										setUseVectorMap(true);
									},
							  }
							: null,
					]}
				>
					{renderOverview(skillData)}
					<div
						style={{
							flex: '1 1 1 1 auto',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div
							style={{
								flex: '1 1 auto',
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap',
								justifyContent: 'space-between',
							}}
						>
							{skillData.map((skill, index) => {
								if (index > 0) {
									return (
										<div
											key={index}
											onClick={() => {
												setActiveSKill(skill);
												setIsPopoverOpen(true);
											}}
											style={{width: '33%', cursor: 'pointer'}}
										>
											<SkillBar key={index} data={skill} />
										</div>
									);
								} else {
									return null;
								}
							})}
						</div>
						{runwayOrgConfig && showLastProcessedTime && (
							<div
								style={{
									textAlign: 'center',
									fontFamily: 'Montserrat Semi Bold',
									paddingTop: '10px',
								}}
							>
								{runwayOrgConfig.config.overviewProcessedText}{' '}
								{moment(runwayOrgConfig.config.overviewProcessedTime).format(
									'MMM Do h:mm A'
								)}
							</div>
						)}
					</div>
				</EmbeddedShow>
				<SkillPopover
					isPopoverOpen={isPopoverOpen}
					setIsPopoverOpen={setIsPopoverOpen}
					skillData={activeSkill}
				/>
			</>
		);
	}
};

export default SkillBarPanel;
