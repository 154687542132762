import React from 'react';
import CalenderIcon from 'assets/CalenderIcon.svg';
const calenderIcon = () => {
    return(
        <>
        <img alt={'calendar'} src={CalenderIcon} width='32' />
        </>
    )
}

export default calenderIcon;