import {
	EmailValue,
	EmbeddedShow,
	InfoTextDeprecated,
	ScreenTitle,
	StatsTilePanel,
	TextValue,
	TimeDateField,
} from 'components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { fetchEnd, fetchStart, useDataProvider, useVersion } from 'react-admin';
import { getUserInfo, safeRequestData } from '../../../utils/Data';

import { Collapse, IconButton, Tooltip } from '@material-ui/core';
import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import NavigationRefresh from '@material-ui/icons/Refresh';
import EditIcon from 'assets/EditIcon';
import EmailIcon from 'assets/EmailIcon';
import classNames from 'classnames';
import LoadingInfo from 'components/loading/LoadingInfo';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PanelStat from '../../../components/Panels/PanelStat';
import Grid from '../../../components/layout/Grid';
import LoadingStat from '../../../components/loading/LoadingStat';
import { getUserTimeZone, loadObject } from '../../../utils/Util';
import DeleteTeamDialog from '../Dialogs/DeleteTeamDialog';
import SendWelcomeEmail from '../Dialogs/SendWelcomeEmail';
import EditInformationDialog from './EditTeamDialog';

const useStyles = makeStyles(
	{
		loader: {
			margin: 14,
		},
		loadedIcon: {},
	},
	{name: 'RaLoadingIndicator'}
);

const TeamShow = props => {
	const {
		id,
		teamData,
		onUpdateTeamDetails,
		isTeamDataLoading,
		isPolling,
		showProcessCompleted,
		updateTeamData,
	} = props;

	const classes = useStyles(props);

	const [isEditing, setIsEditing] = useState(false);

	const [isSendWelcome, setIsSendWelcome] = useState(false);

	const [margin, setMargin] = useState(0);

	const [state, setState] = useState({
		runwayOrgConfig: loadObject('runwayOrgConfig'),
		TeamStats: null,
		users: null,
		isLoading: true,
		authType: '',
		pwd: '',
	});

	const dispatch = useDispatch();

	const dataProvider = useDataProvider();

	const version = useVersion();

	const isMounted = useRef(true);

	const {runwayOrgConfig, TeamStats} = state;

	const loading = useSelector(state => state.admin.loading > 0);

	const [deleteTeamDialog, setDeleteTeamDialog] = useState(false);

	const fetchData = useCallback(async () => {
		setState({...state, isLoading: true});
		let projectData = sessionStorage.getItem('project');
		if (projectData) {
			const orgConfig = JSON.parse(projectData);
			if (orgConfig && orgConfig.config) {
				if (orgConfig.config.authType) {
					state.authType = orgConfig.config.authType;
				}
			}
		}

		const requestList = [{resource: 'TeamStats', id: id}];

		try {
			dispatch(fetchStart());
			for (let i = 0; i < requestList.length; i++) {
				const request = requestList[i];
				await safeRequestData({
					dataRequest: request,
					dataProvider: dataProvider,
					isMounted: isMounted,
					setState: setState,
				});
			}

			setState(state => ({
				...state,
				isLoading: false,
			}));
		} finally {
			dispatch(fetchEnd());
		}
	}, [dataProvider]);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		fetchData();
	}, [version]);

	const renderDefaultTeam = () => {
		return (
			<div>
				<div
					style={{display: 'flex', alignItems: 'center', paddingBottom: '5px'}}
				>
					<CheckCircle color='primary' style={{marginRight: 10}} />
					<Typography color='primary'>Default Team</Typography>
				</div>
				<InfoTextDeprecated
					altRecord={{
						info: `Users logging in from an external authentication service will
						automatically be assigned to this team.`,
					}}
					source='info'
				/>
			</div>
		);
	};

	const customRefresh = () => {
		if (loading) {
			return (
				<CircularProgress
					className={classNames('app-loader', classes.loader)}
					color='inherit'
					size={18}
					thickness={5}
				/>
			);
		}

		if (isPolling) {
			return (
				<Tooltip
					title={
						<div>
							Refresh is disabled until the <br /> current process is completed
						</div>
					}
					style={{}}
				>
					<IconButton disableRipple style={{cursor: 'auto'}}>
						<NavigationRefresh color='disabled' />
					</IconButton>
				</Tooltip>
			);
		}
		return (
			<IconButton color='inherit' onClick={() => updateTeamData()}>
				<NavigationRefresh />
			</IconButton>
		);
	};

	return (
		<div>
			<DeleteTeamDialog
				isOpen={deleteTeamDialog}
				setIsOpen={setDeleteTeamDialog}
				record={teamData}
			/>
			{isSendWelcome && (
				<SendWelcomeEmail
					isOpen={true}
					setIsOpen={setIsSendWelcome}
					teamId={id}
				/>
			)}
			<ScreenTitle
				list={'/teams'}
				{...props}
				basePath={props.basePath}
				resource={props.resource}
				title='Team Details'
				disableRefreshButton={isPolling}
				customRefresh={customRefresh()}
				refresh={false}
				actions={[
					{
						render: () => {
							return (
								<MUIButton
									width={'148px'}
									variant={'outlined'}
									color={'primary'}
									style={{backgroundColor: '#ffffff'}}
									onClick={() => {
										setIsSendWelcome({...setIsSendWelcome, isOpen: true});
									}}
									startIcon={
										<EmailIcon
											style={{fontSize: '18px', marginBottom: '2px'}}
										/>
									}
								>
									Send Welcome Email
								</MUIButton>
							);
						},
					},
					{
						render: () => {
							if (getUserInfo().isAdmin || getUserInfo().isSuper)
								return (
									<MUIButton
										width={'148px'}
										variant={'outlined'}
										color={'primary'}
										style={{backgroundColor: '#ffffff'}}
										onClick={() => {
											setDeleteTeamDialog(true);
										}}
									>
										Delete Team
									</MUIButton>
								);
						},
					},
				]}
				listButton
			/>
			{
				<Collapse
					in={showProcessCompleted || isPolling}
					timeout={{enter: 300, exit: 500}}
					addEndListener={() =>
						setMargin(showProcessCompleted || isPolling ? '24px' : 0)
					}
				>
					<div
						id='team-data-inprogress'
						style={{
							backgroundColor: 'rgb(52, 109, 236)',
							height: '68px',
							borderRadius: '4px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							padding: '12px 8px',
							gap: '4px',
							background: isPolling
								? '#346DEC'
								: showProcessCompleted
								? '#89C32D'
								: 'none',
							boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.16)',
							color: '#FFFFFF',
							fontFamily: 'Montserrat Regular',
							transition: 'background-color 0.7s ease-in-out',
						}}
					>
						<div style={{display: isPolling ? undefined : 'none'}}>
							<span
								style={{
									justifyContent: 'center',
									alignItems: 'center',
									fontFamily: 'Montserrat Semi Bold',
									display: 'flex',
								}}
							>
								<CircularProgress
									color='inherit'
									size={16}
									style={{margin: 0, marginRight: '5px'}}
								/>
								Team data is currently processing…
							</span>
							This process may take some time based on the size of the team. It
							is safe to navigate away from this page while this process is
							happening.
						</div>

						<span
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								display: 'flex',
								fontFamily: 'Montserrat Semi Bold',
								opacity: showProcessCompleted ? 1 : 0,
								height: showProcessCompleted ? 'auto' : 0,
								transition: 'opacity 1s ease-in-out',
							}}
						>
							<CheckCircleOutlinedIcon
								color='inherit'
								fontSize={'small'}
								style={{margin: 0, marginRight: '5px'}}
							/>
							Team data has successfully been updated
						</span>
					</div>
				</Collapse>
			}

			{/* {showProcessCompleted && (
				<div
					id='team-data-process-completed'
					style={{
						backgroundColor: '#89C32D',
						height: '44px',
						borderRadius: '4px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '12px 8px',
						marginBottom: '24px',
						gap: '4px',
						background: '#89C32D',
						boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.16)',
						flex: 'none',
						order: '1',
						alignSelf: 'stretch',
						flexGrow: '0',
						color: '#FFFFFF',
						fontFamily: 'Montserrat Regular',
					}}
				></div>
			)} */}
			<div style={{marginTop: margin, transition: 'margin 1s ease-in-out'}}>
				<EmbeddedShow
					title={'Information'}
					actions={
						isTeamDataLoading
							? []
							: [
									{
										label: 'Edit Info',
										startIcon: (
											<EditIcon
												style={{fontSize: '16px', marginBottom: '3px'}}
											/>
										),
										onClick: () => {
											setIsEditing(true);
										},
									},
							  ]
					}
					details={
						isTeamDataLoading
							? []
							: [
									{
										label: 'Name',
										render: () => (
											<TextValue
												record={teamData}
												maxWidth='250px'
												source='name'
											/>
										),
									},
									{
										label: 'Manager',
										render: () => (
											<EmailValue
												record={teamData}
												maxWidth={'250px'}
												email={'managerEmail'}
												source={'managerName'}
												color={'primary'}
												redirect
											/>
										),
									},
									{
										label: 'Created',
										render: () => (
											<TimeDateField record={teamData} source='createdAt' />
										),
									},
									{
										label: 'Modified',
										render: () => (
											<TimeDateField record={teamData} source='updatedAt' />
										),
									},
									{
										label: 'Data Last Processed',
										render: () => {
											const userTimeZone = getUserTimeZone();
											return (
												<Typography>
													{moment(teamData.lastProcessedAt)
														.utcOffset(userTimeZone.id)
														.format('MM/DD/YYYY h:mm A')}{' '}
													{userTimeZone.name}
												</Typography>
											);
										},
									},
							  ]
					}
					content={
						isTeamDataLoading ? (
							<LoadingInfo />
						) : (
							<Grid perRow={1}>
								{teamData &&
									runwayOrgConfig &&
									runwayOrgConfig.config &&
									runwayOrgConfig.config.defaultTeamId === teamData.id &&
									renderDefaultTeam()}
							</Grid>
						)
					}
				/>
			</div>
			<div style={{marginTop: '24px'}}>
				{!TeamStats || state.isLoading ? (
					<PanelStat>
						<LoadingStat />
					</PanelStat>
				) : (
					<StatsTilePanel stats={TeamStats} />
				)}
			</div>
			{teamData && isEditing && (
				<EditInformationDialog
					isOpen={true}
					setIsOpen={setIsEditing}
					data={teamData}
					id={id}
					onUpdateTeamDetails={onUpdateTeamDetails}
				/>
			)}
		</div>
	);
};

export default TeamShow;
