import * as moment from 'moment';

export const secToHoursMinutes = secs => {
	const hours = Math.floor(moment.duration(secs, 'seconds').asHours());
	const minutes =
		'0' +
		Math.floor(
			moment.duration(secs - hours * 60 * 60, 'seconds').asMinutes()
		).toString();
	const formatedValue = `${hours}h ${minutes.substr(minutes.length - 2)}m`;

	return formatedValue;
};

export const secToMinutesSecs = secs => {
	const minutes = Math.floor(moment.duration(secs, 'seconds').asMinutes());
	const seconds =
		'0' +
		Math.floor(
			moment.duration(secs - minutes * 60, 'seconds').asSeconds()
		).toString();
	const formatedValue = `${minutes}m ${seconds.substr(seconds.length - 2)}s`;

	return formatedValue;
};
export const secToWeeksSecs = secs => {
	const years =
		secs > 31536000
			? Math.floor(moment.duration(secs, 'seconds').asYears())
			: 0;
	const weeks =
		secs > 3600 * 24 * 7
			? Math.floor(
					moment.duration(secs - years * 31536000, 'seconds').asWeeks()
			  )
			: 0;
	const days =
		secs > 3600 * 24
			? Math.floor(
					moment
						.duration(
							secs - years * 31536000 - weeks * (3600 * 24 * 7),
							'seconds'
						)
						.asDays()
			  )
			: 0;
	const hours =
		secs > 3600
			? Math.floor(
					moment
						.duration(
							secs -
								years * 31536000 -
								weeks * (3600 * 24 * 7) -
								days * (3600 * 24),
							'seconds'
						)
						.asHours()
			  )
			: 0;
	const minutes =
		secs > 60
			? Math.floor(
					moment
						.duration(
							secs -
								years * 31536000 -
								weeks * (3600 * 24 * 7) -
								days * (3600 * 24) -
								hours * 3600,
							'seconds'
						)
						.asMinutes()
			  )
			: 0;
	const seconds = Math.floor(
		moment
			.duration(
				secs -
					years * 31536000 -
					weeks * (3600 * 24 * 7) -
					days * (3600 * 24) -
					hours * 3600 -
					minutes * 60,
				'seconds'
			)
			.asSeconds()
	).toString();

	let roundupValue = 0;

	if (years) {
		return years + 'y ' + weeks + 'w';
	} else if (weeks) {
		return weeks + 'w ' + days + 'd';
	} else if (days) {
		if (minutes && minutes > 30) {
			roundupValue += 1;
		}
		if (hours) {
			return days + 'd ' + hours + 'h';
		} else {
			return days + 'd ' + (roundupValue ? hours + roundupValue : hours) + 'h';
		}
	} else if (hours) {
		if (seconds && seconds > 30) {
			roundupValue += 1;
		}
		if (minutes) {
			return hours + 'h ' + minutes + 'm';
		} else {
			return (
				hours + 'h ' + (roundupValue ? minutes + roundupValue : minutes) + 'm'
			);
		}
	} else if (minutes) {
		return minutes + 'm ' + seconds + 's';
	} else {
		return seconds + 's';
	}
};

export const secToWeeksFull = secs => {
	const years =
		secs > 31536000
			? Math.floor(moment.duration(secs, 'seconds').asYears())
			: 0;
	const weeks =
		secs > 3600 * 24 * 7
			? Math.floor(
					moment.duration(secs - years * 31536000, 'seconds').asWeeks()
			  )
			: 0;
	const days =
		secs > 3600 * 24
			? Math.floor(
					moment
						.duration(
							secs - years * 31536000 - weeks * (3600 * 24 * 7),
							'seconds'
						)
						.asDays()
			  )
			: 0;
	const hours =
		secs > 3600
			? Math.floor(
					moment
						.duration(
							secs -
								years * 31536000 -
								weeks * (3600 * 24 * 7) -
								days * (3600 * 24),
							'seconds'
						)
						.asHours()
			  )
			: 0;
	const minutes =
		secs > 60
			? Math.floor(
					moment
						.duration(
							secs -
								years * 31536000 -
								weeks * (3600 * 24 * 7) -
								days * (3600 * 24) -
								hours * 3600,
							'seconds'
						)
						.asMinutes()
			  )
			: 0;
	const seconds = Math.floor(
		moment
			.duration(
				secs -
					years * 31536000 -
					weeks * (3600 * 24 * 7) -
					days * (3600 * 24) -
					hours * 3600 -
					minutes * 60,
				'seconds'
			)
			.asSeconds()
	).toString();
	const formatedValue =
		(years ? years + 'y ' : '') +
		(weeks ? weeks + 'w ' : '') +
		(days ? days + 'd ' : '') +
		(hours ? hours + 'h ' : '') +
		(minutes ? minutes + 'm ' : '') +
		(seconds ? seconds + 's ' : '');
	return formatedValue;
};
