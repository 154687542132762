import {createStyles, makeStyles} from '@material-ui/styles';

import BackgroundShadow from './DialogBackground';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from './DialogActions';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import ReactDOM from 'react-dom';
import TSTheme from 'components/Theme';

const CustomDialog = ({
	width = '448px',
	isOpen,
	setIsOpen,
	title,
	actions,
	children,
	offsetTop,
	small = false,
	onClose = null,
	footer = true,
	overflow = 'auto',
}) => {
	const handleClose = () => {
		setIsOpen(false);
		onClose && onClose();
	};

	return ReactDOM.createPortal(
		<Fullscreen isOpen={isOpen}>
			<BackgroundShadow handleClose={handleClose} />
			<DialogPosition>
				<DialogContainer offsetTop={offsetTop} width={small ? '600px' : width}>
					<DialogHeader handleClose={handleClose}>{title}</DialogHeader>
					<DialogContent footer={footer} overflow={overflow}>
						{children}
					</DialogContent>
					{footer && (
						<DialogFooter>
							<DialogActions actions={actions} handleClose={handleClose} />
						</DialogFooter>
					)}
				</DialogContainer>
			</DialogPosition>
		</Fullscreen>,
		document.getElementById('app')
	);
};

export default CustomDialog;

const Fullscreen = ({children, isOpen}) => {
	return (
		<div
			style={{
				position: 'fixed',
				zIndex: 1000,
				left: 0,
				top: 0,
				width: '100%',
				height: '100%',
				overflow: 'auto',
				display: isOpen ? 'block' : 'none',
			}}
		>
			{children}
		</div>
	);
};

const DialogPosition = ({children}) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				width: '100vw',
				height: '100vh',
				overflow: 'none',
			}}
		>
			{children}
		</div>
	);
};

const DialogContainer = ({children, offsetTop, width}) => {
	return (
		<div
			style={{
				marginTop: `calc(1.6vh ${offsetTop ? ' - ' + offsetTop : ''})`,
				position: 'fixed',
				width: width,
				MozBoxShadow: '0px 5px 20px #00000026',
				boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.498039215686275)',
				backgroundColor: TSTheme.palette.background.contained,
			}}
		>
			{children}
		</div>
	);
};

const DialogHeader = ({children, handleClose}) => {
	return (
		<div
			style={{
				textAlign: 'left',
				background: TSTheme.palette.background.contained,
				height: '55px', // Height of tab header
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginLeft: '24px',
				marginRight: '24px',
				borderBottom: `1px solid ${TSTheme.palette.borderColor.divider}`,
			}}
		>
			<div style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}>
				{children}
			</div>
			<div style={{flex: 1}} />

			<IconButton
				style={{paddingRight: '10px', right: '-14px'}}
				aria-label='Close'
				onClick={handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
	);
};

const useStyles = makeStyles(props =>
	createStyles({
		removeScrollBar: {
			'&::-webkit-scrollbar': {
				width: '0.4em',
				backgroundColor: '#ecf4f6',
			},
			'&::-webkit-scrollbar-track': {
				boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				width: '0.4em',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#346dec',
				width: '0.4em',
				outline: 'none',
				borderRadius: '20px',
			},
		},
	})
);

const DialogContent = ({children, footer, overflow, padding}) => {
	const {removeScrollBar} = useStyles();

	return (
		<div
			className={removeScrollBar}
			style={{
				backgroundColor: TSTheme.palette.background.contained,
				margin: 0,
				padding: '0px 24px',
				maxHeight: footer ? '70vh' : '100vh',
				overflowX: 'none',
				overflowY: overflow,
			}}
		>
			<div style={{padding: padding}}>{children}</div>
		</div>
	);
};

const DialogFooter = ({children}) => {
	return (
		<div
			style={{
				alignSelf: 'center',
				textAlign: 'center',
				background: TSTheme.palette.background.contained,
				height: '62px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				margin: '0px 24px',
				borderTop: `1px solid ${TSTheme.palette.borderColor.divider}`,
			}}
		>
			{children}
		</div>
	);
};
