import {EmbeddedList, StatusChip, TimeSpanField} from 'components';
import {NumberField, useTranslate} from 'react-admin';

import {MenuItem} from '@material-ui/core';
import PercentField from 'components/PercentField';
import React from 'react';
import SendEmail from 'components/SendEmail';
import SkillFilter from '../../SkillFilter';

const LabList = props => {
	const {
		userData,
		selectedTrainingRecord,
		labSelected,
		selectedSkills,
		skillsSelected,
	} = props;

	const translate = useTranslate();

	return (
		<EmbeddedList
			embedded={false}
			subTitle
			title={translate('Lab Progress')}
			resource='listLabsForUserRunway'
			columns={[
				{
					id: 'name',
					name: translate('Lab Name'),
					sortable: true,
				},
				{
					id: 'status',
					name: translate('Status'),
					sortable: true,
					align: 'center',
					render: record => <StatusChip record={record} source='status' />,
					isInverted: true,
				},
				{
					id: 'attempts',
					name: translate('Attempts'),
					sortable: true,
					align: 'center',
					render: record => <NumberField record={record} source='attempts' />,
					isInverted: true,
				},
				{
					id: 'totalTimeSec',
					name: translate('time'),
					sortable: true,
					align: 'center',
					render: record => (
						<TimeSpanField record={record} source='totalTimeSec' />
					),
					isInverted: true,
				},
				{
					id: 'completion',
					name: 'Completion',
					sortable: true,
					align: 'center',
					render: record => (
						<PercentField record={record} source='completion' />
					),
				},
				{
					id: 'score',
					name: translate('Score'),
					sortable: true,
					align: 'center',
					render: record => <PercentField record={record} source='score' />,
					isInverted: true,
				},
			]}
			sort={{field: 'name', order: 'ASC'}}
			staticFilters={[
				{id: 'trainingId', value: selectedTrainingRecord.id},
				{id: 'userId', value: userData.id},
				{id: 'namedSkills'},
			]}
			dynamicFilters={[
				{
					id: 'status',
					name: translate('Status'),
					type: 'exact',
					choices: [
						{value: '*', label: translate('All Statuses')},
						{value: 'not started', label: translate('Not Started')},
						{value: 'aced', label: translate('Aced')},
						{value: 'passed', label: translate('Passed')},
						{value: 'incomplete', label: translate('Incomplete')},
					],
				},
				{
					id: 'namedSkills',
					value:
						selectedSkills.length > 0
							? {$in: selectedSkills.map(skill => skill.name)}
							: undefined,
					render: () => (
						<SkillFilter
							onChange={skills => {
								skillsSelected(skills);
							}}
							skillListContextData={{
								trainingId: selectedTrainingRecord.id,
								userId: userData.id,
							}}
							preSelectedSkills={
								selectedSkills.length ? selectedSkills : undefined
							}
						/>
					),
				},
			]}
			bulkActions={[]}
			actions={[
				{
					render: record => (
						<MenuItem key={'View Lab Detail'}>
							<div onClick={() => labSelected(record.id, null, record)}>
								{translate('View Lab Detail')}
							</div>
						</MenuItem>
					),
				},
				{
					render: () => (
						<SendEmail
							key={'Send Reminder Email'}
							id={userData.id}
							title={'Send Reminder Email'}
						/>
					),
				},
			]}
			rowClicked={record => labSelected(record.id, null, record)}
			selectedIds={null}
			selectionToggled={null}
			stripped={false}
		/>
	);
};

export default LabList;
