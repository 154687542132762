import SvgIcon from '@material-ui/core/SvgIcon';

export default function EmailIcon(props) {
	return (
		<SvgIcon {...props}>
			<svg
				viewBox='0 0 16 16'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<rect
					x='0.800003'
					y='3.3'
					width='14.4'
					height='10.4'
					rx='2'
					stroke='currentColor'
					// stroke-width='1.5'
				/>
				<path
					d='M14.4 13.3L10.0807 8.85836M1.6 13.3L5.87884 8.9'
          stroke='currentColor'
					// stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M1.6 3.7L6.58578 8.68578C7.36683 9.46683 8.63316 9.46683 9.41421 8.68578L14.4 3.7'
          stroke='currentColor'
					// stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
			</svg>
		</SvgIcon>
	);
}
