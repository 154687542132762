import React, {useEffect, useState} from 'react';
import {
	Typography,
	createStyles,
	withStyles,
	CircularProgress,
	Button,
	Avatar,
} from '@material-ui/core';

import isEmpty from 'lodash/isEmpty';

import Carousel from './Carousel';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Indicators from './Indicators';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import moment from 'moment';
import {loadObject, saveObject} from 'utils/Util';
import {getUserInfo} from 'utils/Data';
import trainingModuleIcon from 'assets/MiniCardIcons/TrainingModule.svg';

import FeaturedSkills from 'components/TrainingCatalogue/FeaturedSkills';
import EditTrainingContent from 'components/TrainingCatalogue/EditTrainingContent';
import AssignModuleToTeamsDialog from 'components/TrainingCatalogue/AssignModuleToTeamsDialog';
import ConfirmActionDialog from 'components/dialogs/ConfirmActionDialog';
import PanelInfo from 'components/Panels/PanelInfo';
import TalespinWebAccess from "components/TrainingCatalogue/TalespinWebAccess";
import EditIcon from 'assets/EditIcon';
import { AddOutlined } from '@material-ui/icons';

const myStyles = theme =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.contained,
			MozBoxShadow: '0px 5px 20px #00000026',
			boxShadow: '0px 5px 20px #00000026',
		},
		description: {
			backgroundColor: theme.palette.background.default,
			padding: '16px',
		},
		card: {
			backgroundColor: theme.palette.background.default,
		},
		cardTitle: {
			fontFamily: 'Archivo Bold',
			fontSize: '18px',
		},
		cardSubTitle: {
			fontFamily: 'Archivo Bold',
			fontSize: '16px',
		},
		header: {
			backgroundColor: theme.palette.background.default,
			padding: '8px 16px',
			display: 'flex',
			alignItems: 'baseline',
			gap: '5px',
			'& p:first-of-type': {
				fontFamily: 'Montserrat Semi Bold',
				fontSize: '16px',
			},
		},
		body: {
			padding: '0px 16px 0px 16px',
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
		section: {
			padding: '12px 0px',
		},
		preWrap: {
			whiteSpace: 'pre-wrap',
		},
	});

const TrainingCatalogue = props => {
	const {
		classes,
		trainingData,
		projectStatus,
		information,
		isExpandMore,
		toggleKey,
		allowEditing,
		isSubmittingForReview,
		isPublishing,
		isTrainingStillEditable,
		onUpdate,
		openSubmitPanel,
		openPublishPanel,
		onEditTrainingError,
		onToggleEditing,
		onCancelSubmitting,
		onCancelPublishing,
		onPublishTrainingModule,
		onSubmittingForReview,
		isTSAPlayable
	} = props;

	const [activeIndex, setactiveIndex] = useState({
		goals: 0,
		featuredSkills: 0,
		objectives: 0,
	});

	const [isEditing, setIsEditing] = useState(false);

	const [isMoreInfo, setMoreInfo] = useState(isExpandMore);

	const [openAssignTeamsDialog, setOpenAssignTeamsDialog] = useState(false);

	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

	const [isDeepLinkOpen, setIsDeepLinkOpen] = useState(false);

	const summaryFriendData = loadObject(loadObject('project').id + '-summaryFriendData');

	const isTester = summaryFriendData?.isTester ? true : false;

	useEffect(() => {
		//button is rendered by dangerously setting the html from trainingdetail.additionalInformation
		const copyButton = document.getElementById('copy-streaming-player-link');
		const copyButton2 = document.getElementById(
			'copy-streaming-player-link-two'
		);
		if (copyButton && trainingData.trainingDetail.streamingUrl) {
			copyButton.addEventListener('click', () =>
				copy(trainingData.trainingDetail.streamingUrl)
			);
		}
		if (copyButton2 && trainingData.trainingDetail.streamingUrlTwo) {
			copyButton2.addEventListener('click', () =>
				copy(trainingData.trainingDetail.streamingUrlTwo)
			);
		}
	}, [
		isEditing,
		document.getElementById('copy-streaming-player-link'),
		document.getElementById('copy-streaming-player-link-two'),
	]);

	const editModule = loadObject('editModule');
	const addTeams = loadObject('addTeams');

	useEffect(() => {
		if (editModule) {
			onEditTrainingDetail();
			saveObject('editModule', false);
		}
		if (addTeams) {
			setOpenAssignTeamsDialog(true);
			saveObject('addTeams', false);
		}
	}, [editModule, addTeams]);

	if (isEmpty(trainingData)) {
		return (
			<PanelInfo>
				<Typography
					style={{
						position: 'relative',
						top: '50%',
						transform: 'translateY(-50%)',
						textAlign: 'center',
						fontSize: '18px',
					}}
				>
					No Module details found
				</Typography>
			</PanelInfo>
		);
	}

	const trainingDetail = trainingData.trainingDetail;

	const trainingCategories = trainingDetail.trainingCategories;

	const processSkills = () => {
		const skills = [];

		for (let i = 0; i < trainingDetail.skillInformation.length; i++) {
			const skillInfo = trainingDetail.skillInformation[i];
			skills.push({
				borderColour: skillInfo.borderColour,
				category: skillInfo.category,
				description: skillInfo.text,
				displayName: skillInfo.displayTitle,
				displayShortName: skillInfo.displayShortName,
				iconUrl: skillInfo.iconUrl,
				mainColour: skillInfo.mainColour,
				name: skillInfo.namedSkill,
				type: skillInfo.type,
				totalPoints: skillInfo.totalPoints,
			});
		}

		return skills;
	};

	const trainingCategoriesNames = [];

	const namedSkills = processSkills();

	for (let i = 0; i < trainingCategories.length; i++) {
		if (trainingCategories[i].value) {
			trainingCategoriesNames.push(trainingCategories[i].value);
		}
	}

	const onActiveIndexChange = (id, index) => {
		setactiveIndex({...activeIndex, [id]: index});
	};

	const copy = url => {
		const listener = e => {
			const clipboard = e.clipboardData || window['clipboardData'];
			clipboard.setData('text', url);
			e.preventDefault();
		};

		document.addEventListener('copy', listener, false);
		document.execCommand('copy');
		document.removeEventListener('copy', listener, false);
	};

	const onEditTrainingDetail = async () => {
		if (isTrainingStillEditable) {
			const editable = await isTrainingStillEditable();

			if (!editable) return;
		}

		if (trainingData.publicationStatus === 'PUBLISHED')
			setOpenConfirmationDialog(true);
		else setIsEditing(true);

		if (onToggleEditing) onToggleEditing(true);
	};

	const showHideMoreInfo = () => {
		return (
			<div
				style={{
					cursor: 'pointer',
					display: 'flex',
					flexDirection: isMoreInfo ? 'column' : 'column-reverse',
					alignItems: 'center',
					paddingBottom: '16px',
				}}
				onClick={() => {
					setMoreInfo(!isMoreInfo);
					saveObject(toggleKey, isMoreInfo ? false : true);
				}}
			>
				<span style={{ paddingRight: '12px' }}>
					{isMoreInfo ? (
						<ExpandLessIcon
							color={'primary'}
							style={{
								fontSize: '24px',
							}}
						/>
					) : (
						<ExpandMoreIcon
							color={'primary'}
							style={{
								fontSize: '24px',
							}}
						/>
					)}
				</span>
				<Typography
					color={'primary'}
					style={{
						fontFamily: 'Montserrat Medium',
						fontSize: '14px',
						paddingRight: '12px'
					}}
				>
					{isMoreInfo ? 'Show Less' : 'Show More'}
				</Typography>
			</div>
		);
	};

	if (isEditing) {
		return (
			<EditTrainingContent
				trainingData={trainingData}
				processedSkills={namedSkills}
				processedCategories={trainingCategoriesNames}
				onEditTrainingError={onEditTrainingError}
				onCancelEdit={() => {
					setIsEditing(false);
					if (onToggleEditing) onToggleEditing(false);
				}}
				onUpdate={async () => {
					if (onUpdate) await onUpdate();
					setIsEditing(false);
					if (onToggleEditing) onToggleEditing(false);
				}}
			/>
		);
	}

	return (
		<div className={classes.root}>
			{(openSubmitPanel || openPublishPanel) && (
				<div
					style={{
						display: 'flex',
						padding: '20px',
						justifyContent: 'space-between',
						alignItems: 'baseline',
						boxShadow: '0px 0px 10px #00000033',
						backgroundColor: '#89C32D',
						textAlign: 'center',
					}}
				>
					<span></span>

					<span>
						<Typography
							style={{
								letterSpacing: '3.2px',
								fontFamily: 'Montserrat Semi Bold',
								color: '#ffffff',
								fontSize: '16px',
							}}
						>
							------------ {openSubmitPanel && 'SUBMIT FOR REVIEW'}{' '}
							{openPublishPanel && 'PUBLISHING'}------------
						</Typography>
						<Typography style={{color: '#ffffff', fontSize: '14px'}}>
							You are {openSubmitPanel && 'submitting'}{' '}
							{openPublishPanel && 'publishing'} content that was pre-published
							on:{' '}
							{moment
								.unix(projectStatus.stagingStatus.lastStagedDate / 1000)
								.format('MM/DD/YYYY hh:mm:ss A')}
							. Any other changes made after will not apply. Please review your
							content prior to {openSubmitPanel && 'submitting'}{' '}
							{openPublishPanel && 'publishing'}.
						</Typography>
					</span>

					{!isSubmittingForReview && !isPublishing && (
						<span style={{display: 'flex', gap: '10px'}}>
							<Button
								variant={'outlined'}
								color={'primary'}
								style={{height: '35px'}}
								onClick={() =>
									openSubmitPanel ? onCancelSubmitting() : onCancelPublishing()
								}
							>
								Cancel
							</Button>
							<Button
								variant={'contained'}
								color={'primary'}
								style={{height: '35px'}}
								onClick={() =>
									openSubmitPanel
										? onSubmittingForReview()
										: onPublishTrainingModule()
								}
							>
								{openSubmitPanel ? 'Submit' : 'Publish'}
							</Button>
						</span>
					)}

					{(isSubmittingForReview || isPublishing) && (
						<div style={{display: 'flex', alignItems: 'baseline'}}>
							<CircularProgress
								size={20}
								style={{top: '5px', position: 'relative', marginRight: '5px'}}
							/>
							<span style={{color: '#000'}}>
								{isSubmittingForReview
									? 'Submitting For Review...'
									: 'Publishing'}
							</span>
						</div>
					)}
				</div>
			)}

			{(openSubmitPanel || openPublishPanel) && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '20px',
						paddingLeft: '20px',
						paddingTop: '16px',
					}}
				>
					<div
						style={{display: 'flex', columnGap: '20px', alignItems: 'center'}}
					>
						<Avatar
							variant='square'
							alt='Remy Sharp'
							src={
								trainingDetail.appIcons && trainingDetail.appIcons.length > 0
									? trainingDetail.appIcons[0]
									: trainingModuleIcon
							}
						/>

						<Typography
							style={{
								fontSize: '16px',
							}}
						>
							Icon
						</Typography>
					</div>

					<div
						style={{
							display: 'flex',
							columnGap: '20px',
							alignItems: 'center',
						}}
					>
						<Avatar
							variant='square'
							alt='Remy Sharp'
							src={
								trainingDetail.heroIcons && trainingDetail.heroIcons.length > 0
									? trainingDetail.heroIcons[0]
									: trainingModuleIcon
							}
						/>

						<Typography
							style={{
								fontSize: '16px',
							}}
						>
							Hero Image
						</Typography>
					</div>
				</div>
			)}

			<div style={{padding: '20px 12px 20px 20px'}}>
				{!openSubmitPanel && !openPublishPanel && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div style={{display: 'flex', columnGap: '20px'}}>
							<Avatar
								variant='square'
								alt='Remy Sharp'
								src={
									trainingDetail.appIcons && trainingDetail.appIcons.length > 0
										? trainingDetail.appIcons[0]
										: trainingModuleIcon
								}
							/>

							<Typography
								style={{fontFamily: 'Archivo Bold', fontSize: '28px'}}
							>
								{trainingDetail.name}
							</Typography>
						</div>

						<div style={{display: 'flex', columnGap: '10px'}}>
							{(props.context === 'In Development' || allowEditing) && (
								<>
									<Button
										width={'148px'}
										variant={'outlined'}
										color={'primary'}
										onClick={() => onEditTrainingDetail()}
										disabled={!allowEditing}
										startIcon={
											<EditIcon
												style={{fontSize: '16px', marginBottom: '3px'}}
											/>
										}
									>
										Edit Module Details
									</Button>

									{getUserInfo().trainingModuleEditor && (
										<Button
											width={'148px'}
											variant={'outlined'}
											color={'primary'}
											onClick={() => setOpenAssignTeamsDialog(true)}
											startIcon={<AddOutlined style={{marginBottom: '2px'}} />}
										>
											Assign to Teams
										</Button>
									)}
								</>
							)}
							{trainingData.accessibility.licensed.value &&
								trainingData.isCPD &&
								(trainingData.trainingDetail.moduleStatus === 'Published' ||
									getUserInfo().trainingModuleEditor || isTester) && (
									<Button
										width={'148px'}
										variant={'outlined'}
										color={'primary'}
										onClick={() => setIsDeepLinkOpen(true)}
										startIcon={<AddOutlined />}
										disabled={!isTSAPlayable}
									>
										Create Training Link
									</Button>
								)}
						</div>
					</div>
				)}
				<div className={classes.section}>{information}</div>

				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr repeat(1, auto) 1fr',
					}}
				>
					<span
						style={{
							fontFamily: 'Archivo Bold',
							fontSize: '20px',
						}}
					>
						{isMoreInfo ? 'Information' : ''}
					</span>
				</div>
				{isMoreInfo && (
					<div>
						{/* description */}

						<div
							style={{display: 'flex', width: '100%', columnGap: '30px'}}
							className={classes.section}
						>
							<div
								style={{
									width: '50%',
									padding: '12px 12px 12px 12px',
									height: 'fit-content',
								}}
								className={classes.card}
							>
								<Typography
									style={{
										fontFamily: 'Montserrat Semi Bold',
										fontSize: '16px',
									}}
								>
									Description:
								</Typography>

								{trainingDetail.description && (
									<p
										dangerouslySetInnerHTML={{
											__html: trainingDetail.description,
										}}
										className={classes.preWrap}
									></p>
								)}
							</div>
							{trainingDetail && (
								<Carousel
									sources={[
										...trainingDetail.heroIcons,
										...trainingDetail.screenShots,
									]}
								></Carousel>
							)}
						</div>

						<div style={{display: 'flex', width: '100%', columnGap: '30px'}}>
							{/* Learning Goals */}

							{trainingDetail?.learningGoals?.length > 0 && (
								<div
									className={classes.section}
									style={{
										width:
											trainingDetail.learningObjectives?.length > 0
												? '50%'
												: '100%',
									}}
								>
									<div className={classes.header}>
										<Typography>Learning Goals:</Typography>
									</div>
									<div className={classes.body}>
										<p
											dangerouslySetInnerHTML={{
												__html:
													trainingDetail.learningGoals[activeIndex['goals']]
														.text,
											}}
											className={classes.preWrap}
										></p>
										<Indicators
											length={trainingDetail.learningGoals.length}
											active={activeIndex['goals']}
											press={index => onActiveIndexChange('goals', index)}
										/>
									</div>
								</div>
							)}

							{/* {Learning Objectives} */}

							{trainingDetail?.learningObjectives?.length > 0 && (
								<div
									className={classes.section}
									style={{
										width:
											trainingDetail?.learningGoals?.length > 0
												? '50%'
												: '100%',
									}}
								>
									<div className={classes.header}>
										<Typography>Learning Objectives:</Typography>
									</div>
									<div className={classes.body}>
										<p
											dangerouslySetInnerHTML={{
												__html:
													trainingDetail.learningObjectives[
														activeIndex['objectives']
													],
											}}
											className={classes.preWrap}
										></p>
										<Indicators
											length={trainingDetail.learningObjectives.length}
											active={activeIndex['objectives']}
											press={index => onActiveIndexChange('objectives', index)}
										/>
									</div>
								</div>
							)}
						</div>

						{/* Training Categories: */}
						{trainingCategoriesNames.length > 0 && (
							<div className={classes.section}>
								<div className={classes.header}>
									<Typography>Module Categories</Typography>
								</div>
								<div
									style={{
										padding: '16px 0px 0px 16px',
										display: 'flex',
										flexDirection: 'row',
										gap: '10px',
										flexWrap: 'wrap',
									}}
								>
									{trainingCategoriesNames.map(category => (
										<span
											key={category}
											style={{
												padding: '5px 10px',
												border: '1.5px solid #85A0AE',
												borderRadius: '20px',
												fontWeight: 'bold',
												color: '#292C3C',
											}}
										>
											{category}
										</span>
									))}
								</div>
							</div>
						)}

						{/* Skills */}
						{namedSkills.length > 0 && (
							<div className={classes.section}>
								<div style={{}} className={classes.header}>
									<Typography>Skills</Typography>
								</div>
								<FeaturedSkills skills={namedSkills} />
							</div>
						)}

						{/* About the Series */}
						{trainingDetail?.aboutSeries && (
							<div className={classes.section}>
								<div className={classes.header}>
									<Typography>About the Series</Typography>
								</div>
								<div style={{padding: '0px 16px'}}>
									<p
										dangerouslySetInnerHTML={{
											__html:
												trainingDetail && trainingDetail.aboutSeries
													? trainingDetail.aboutSeries
													: '',
										}}
										className={classes.preWrap}
									></p>
								</div>
							</div>
						)}

						{/* Additional Inforamtion */}
						{trainingDetail?.additionalInformation && (
							<div className={classes.section}>
								<div className={classes.header}>
									<Typography>Additional Information</Typography>
								</div>
								<div style={{padding: '0px 16px'}}>
									<p
										dangerouslySetInnerHTML={{
											__html:
												trainingDetail && trainingDetail.additionalInformation
													? trainingDetail.additionalInformation
													: '',
										}}
										className={classes.preWrap}
									></p>
								</div>
							</div>
						)}
					</div>
				)}
			</div>

			{(openSubmitPanel || openPublishPanel) && isMoreInfo && (
				<div style={{padding: '0px 20px 20px 20px'}}>
					<hr className={classes.divider}></hr>{' '}
					{!isSubmittingForReview && !isPublishing && (
						<span
							style={{
								display: 'flex',
								gap: '10px',
								justifyContent: 'flex-end',
								paddingTop: '12px',
							}}
						>
							<Button
								variant={'outlined'}
								color={'primary'}
								style={{height: '35px'}}
								onClick={() =>
									openSubmitPanel ? onCancelSubmitting() : onCancelPublishing()
								}
							>
								Cancel
							</Button>
							<Button
								variant={'contained'}
								color={'primary'}
								style={{height: '35px'}}
								onClick={() =>
									openSubmitPanel
										? onSubmittingForReview()
										: onPublishTrainingModule()
								}
							>
								{openSubmitPanel ? 'Submit' : 'Publish'}
							</Button>
						</span>
					)}
					{(isSubmittingForReview || isPublishing) && (
						<span
							style={{
								display: 'flex',
								gap: '10px',
								justifyContent: 'flex-end',
								paddingTop: '12px',
							}}
						>
							<div style={{display: 'flex', alignItems: 'baseline'}}>
								<CircularProgress
									size={20}
									style={{top: '5px', position: 'relative', marginRight: '5px'}}
								/>
								<span style={{color: '#000'}}>
									{isSubmittingForReview
										? 'Submitting For Review...'
										: 'Publishing'}
								</span>
							</div>
						</span>
					)}
				</div>
			)}

			<AssignModuleToTeamsDialog
				isOpen={openAssignTeamsDialog}
				onClose={() => setOpenAssignTeamsDialog(false)}
				moduleId={trainingData.id}
			/>

			<ConfirmActionDialog
				isOpen={openConfirmationDialog}
				onConfirm={() => {
					setOpenConfirmationDialog(false);
					setIsEditing(true);
				}}
				setIsOpen={setOpenConfirmationDialog}
				title={'Caution'}
				content={
					<Typography>
						Changing the global module metadata will change this metadata for
						all users with access to this module. If data has previously been
						exported related to this module the metadata information in new data
						exports related to this module may not match up. Are you sure you
						wish to make changes?
					</Typography>
				}
			></ConfirmActionDialog>

			{showHideMoreInfo()}
			{isDeepLinkOpen && (
				<TalespinWebAccess
					onClose={() => setIsDeepLinkOpen(false)}
					record={trainingDetail}
					trainingData={trainingData}
				/>
			)}
		</div>
	);
};

const enhance = compose(withStyles(myStyles));

export default enhance(TrainingCatalogue);

TrainingCatalogue.propTypes = {
	trainingData: PropTypes.object.isRequired,
	isExpandMore: PropTypes.bool.isRequired,
	information: PropTypes.node.isRequired,
	toggleKey: PropTypes.string.isRequired,
};
