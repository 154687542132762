import InfoIcon from '@material-ui/icons/Info';
import React, {useState} from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import {useQueryWithStore} from 'react-admin';
import LoadingStat from '../../../../components/loading/LoadingStat';
import {GET_STATS} from 'services/customActions';
import SkillMatrix from 'components/SkillMatrix';
import SkillPie from 'components/SkillPie';
import {EmbeddedShow, StatsTilePanel} from 'components';
import VectorMap from 'components/VectorMap';
import {vectorMapSystemEnabled, vectorMapUserEnabled} from 'utils/vectorMap';

const StatsTab = props => {
	const {userData, id} = props;

	const {data: stData, loading: userStatsLoading} = useQueryWithStore({
		type: GET_STATS,
		resource: 'UserStats',
		payload: {id},
	});

	const {data: skData, loading: skDataLoading} = useQueryWithStore({
		type: GET_STATS,
		resource: 'UserInDepthStats',
		payload: {id},
	});

	const {data: lData, loading: recentLessonProgressLoading} = useQueryWithStore({
		type: GET_STATS,
		resource: 'RecentLessonProgressPie',
		payload: {id},
	});

	const [useVectorMap, setUseVectorMap] = useState(vectorMapUserEnabled());

	const customTooltipBottom = (
		<div>
			<Tooltip
				title={`User's Average score across all training modules available to the user`}
				placement='right-start'
			>
				<InfoIcon />
			</Tooltip>
		</div>
	);

	const customTooltipTop = (
		<div style={{marginInlineStart: 'auto'}}>
			<Tooltip
				title={`Scores of user's three most recently completed lessons `}
				placement='right-start'
			>
				<InfoIcon />
			</Tooltip>
		</div>
	);

	return (
		<div style={{width: '100%'}}>
			<div style={{margin: '12px 0px'}}>
				{(stData && !userStatsLoading) ? (
					<StatsTilePanel stats={stData} />
				) : (
						<LoadingStat />
				)}
			</div>

			{((skData && skData.length > 0) && !skDataLoading) ? (
				vectorMapSystemEnabled('user', 'skill-map') && useVectorMap ?
					<VectorMap
						title={'Skills'}
						type={'skill-map'}
						contextData={{
							teamId: undefined,
							userId: userData ? userData.id : undefined,
							trainingId: undefined,
							lessonId: undefined
						}}
						onTableViewClicked={() => { vectorMapUserEnabled(false); setUseVectorMap(false); }}
					/>
				:
				<EmbeddedShow
					title='Skills'
					actions={[
						vectorMapSystemEnabled('user', 'skill-map') ? { label: 'Map View', onClick: () => { vectorMapUserEnabled(true); setUseVectorMap(true); }} : null
					].filter(item => item != null)}
					content={<SkillMatrix data={skData}></SkillMatrix>}
				/>
			) : (skData && !skDataLoading) ? (
				<EmbeddedShow
					title='Skills'
					content={
						<div
							style={{
								textAlign: 'center',
								paddingTop: '10px',
								paddingBottom: '10px',
							}}
						>
							{skData ? (
								'User has not yet earned any career skills points'
							) : (
								<LoadingStat />
							)}
						</div>
					}
				/>
			) : (
				<LoadingStat />
			)}

			{(lData && !recentLessonProgressLoading) ? (
				<SkillPie
					customTooltipTop={customTooltipTop}
					customTooltipBottom={customTooltipBottom}
					showTooltip={true}
					data={lData}
					label={'Recent Lessons'}
				/>
			) : (
				<LoadingStat />
			)}
		</div>
	);
};

export default StatsTab;
