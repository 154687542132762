import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import decodeJwt from 'jwt-decode';
import {userLogin} from 'react-admin';
import {Redirect} from 'react-router-dom';

const CatchAll = props => {
	const [state, setState] = useState({redirect: false});

	const [error, setError] = useState();

	let windowHash = window.location.hash;
	// if (windowHash.includes('id_token')) {
	// 	if (windowHash.includes('#')) {
	// 		window.location = windowHash.replace('#/id_token', '');
	// 	}
	// }

	if (windowHash.includes('#/id_token') && (windowHash.includes('userId=') && windowHash.includes('responseId='))) {
		window.location = windowHash.replace('#/id_token', '');
	}

	if (windowHash.includes('id_token=') && windowHash.includes('#')) {
		window.location = windowHash.replace('#/', '?');
	}

	/**
	 * Redirect when getting data back from Okta.
	 *
	 * Note: don't redirect on all 404 pages. Our /profile page will be redirected.
	 */
	const setRedirect = () => {
		let hash = window.location.hash;

		if (hash.includes('id_token')) {
			if (hash.includes('#')) {
				window.location = hash.replace('#/', '?');
			}
			var hashes = hash.split('&');

			if (hash.includes('userId=') && hash.includes('responseId=')) {
				var userId = '';
				var responseId = '';
				for (let i = 0; i < hashes.length; i++) {
					if (hashes[i].includes('userId')) {
						userId = hashes[i].split('=')[1];
					}
					if (hashes[i].includes('responseId')) {
						responseId = hashes[i].split('=')[1];
					}
				}

				if (responseId.includes('#')) {
					responseId = responseId.substring(0, responseId.indexOf('#'));
				}

				props.userLogin({externalId: userId, token: responseId});
			} else {
				if (hash.includes('id_token=')) {
					var id_token = '';
					for (var i = 0; i < hashes.length; i++) {
						if (hashes[i].includes('id_token')) {
							id_token = hashes[i].split('=')[1];
						}
					}

					const decodedToken = decodeJwt(id_token);
					const sub = decodedToken.sub;
					props.userLogin({externalId: sub, token: id_token});
				} else {
					props.userLogin({externalId: ' ', token: ' '});
				}
			}
		}

		if (hash.includes('handoffId')) {
			hashes = hash.split('&');

			var handoffId = '';
			for (let i = 0; i < hashes.length; i++) {
				if (hashes[i].includes('handoffId')) {
					handoffId = hashes[i].split('=')[1];
				}
			}

			props.userLogin({handoffId: handoffId});
		}

		if (hash.includes('error_description=')) {
			hashes = hash.split('&');

			for (let i = 0; i < hashes.length; i++) {
				if (hashes[i].includes('error_description=')) {
					setError(
						hashes[i].split('=')[1].replace(/\+/g, ' ') +
							' Please contact your admin.'
					);
				}
			}
		}

		if (hash.includes('error=')) {
			hashes = hash.split('&');

			for (let i = 0; i < hashes.length; i++) {
				if (hashes[i].includes('error=')) {
					setError(
						hashes[i].split('=')[1].replace(/\+/g, ' ') +
							' Please contact your admin.'
					);
				}
			}
		}

		// Only redirect if we have no reason to be on this page. Okta will handle redirects
		setState(state => ({
			...state,
			loginRedirect: hash.includes('id_token') || hash.includes('handoffId'),
			redirect: !(
				hash.includes('id_token') ||
				hash.includes('handoffId') ||
				hash.includes('error')
			),
		}));
	};

	useEffect(() => {
		setRedirect();
	}, [window.location.href]);

	if (!window.location.hash.includes('id_token' || 'handoffId' || 'error')) {
		return <Redirect to='/' />;
	}

	return (
		<div>
			{state.loginRedirect && 'Redirecting...'}
			{error}
		</div>
	);
};

export default connect(undefined, {userLogin})(CatchAll);
