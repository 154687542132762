import {useState} from 'react';

import {CheckCircle, Link as LinkIcon} from '@material-ui/icons';
import {createStyles, withStyles} from '@material-ui/styles';
import MUIButton from '@material-ui/core/Button';

import {EmbeddedShow} from 'components';
import LoadingInfo from 'components/loading/LoadingInfo';
import FadeOut from 'components/FadeOut';
import { fetchEnd, fetchStart, useDataProvider } from 'react-admin';
import { useDispatch } from "react-redux";
import { GET_USER_CPD_URLS } from 'services/customActions';
import OpenNewTabIcon from 'assets/OpenNewTabIcon';
import { copyToClipboard, getOrgId, openNewTabWithPromise } from "utils/Util";

const myStyles = theme =>
	createStyles({
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
	});

const AccessAppLink = ({
	loading,
	title,
	body,
	link,
	openLinkButtonTitle,
	classes,
	app
}) => {
	const dataProvider = useDataProvider();
	const dispatch = useDispatch();

	const [showCopiedIcon, setShowCopiedIcon] = useState(false);
	const [resetKey, setResetKey] = useState(0);
	const [fetchingAuthenticatedLink, setFetchingAuthenticatedLink] = useState(false);

	const setLinkFetched = () => {
		dispatch(fetchEnd());
		setFetchingAuthenticatedLink(false);
	}

	const fetchAuthenticatedLink = () => {
		const promise = dataProvider(GET_USER_CPD_URLS, 'getUserURLs', { fetchCPDWebToken:  app === "cpdWeb", orgId: getOrgId() });
		dispatch(fetchStart());
		setFetchingAuthenticatedLink(true);
		openNewTabWithPromise(promise, app === "streamngPlayer" ? 'streamingPlayerRootToken' : 'cpdWebServiceWithToken', setLinkFetched);
	};

	const copyLink = () => {
		copyToClipboard(link);
		setShowCopiedIcon(true);
		setResetKey(resetKey + 1);
	};

	return (
		<EmbeddedShow title={title} height={'auto'}>
			{!loading ? (
				<>
					<div className={classes.info} style={{lineHeight: 1.5}}>
						{body}
					</div>
					<hr className={classes.divider} />
					<div style={{display: 'flex', alignItems: 'center'}}>
						<MUIButton
							variant={'contained'}
							color={'primary'}
							style={{height: '32px', width: '200px'}}
							size='small'
							disabled={fetchingAuthenticatedLink}
							startIcon={<OpenNewTabIcon style={{ fontSize: '16px' }}/>}
							onClick={() => fetchAuthenticatedLink()}
						>
							{openLinkButtonTitle}
						</MUIButton>

						<MUIButton
							variant={'contained'}
							color={'primary'}
							style={{height: '32px', width: '160px', marginLeft: '32px'}}
							size='small'
							onClick={() => copyLink()}
						>
							<LinkIcon style={{paddingRight: '5px'}} /> Copy Link
						</MUIButton>

						{showCopiedIcon && (<>
							<FadeOut onHidden={() => setShowCopiedIcon(false)} key={resetKey} id={app}>							
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginLeft: '10px',
									}}
								>
									<CheckCircle
										style={{
											color: '#89c32d',
											marginRight: '5px',
										}}
									/>
									<i style={{ fontFamily: 'Montserrat Regular', paddingTop: '1px' }}>
										Link Copied
									</i>
								</div>
							</FadeOut>
						</>)}
					</div>
				</>
			) : (
				<LoadingInfo />
			)}
		</EmbeddedShow>
	);
};

export default withStyles(myStyles)(AccessAppLink);
