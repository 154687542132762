import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import { getOrgId, getUserTimeZone, loadObject, openNewTabWithPromise } from 'utils/Util';
import { getUserInfo, timezones_daylight, timezones_standard } from 'utils/Data';
import { StepConnector } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import MUIButton from '@material-ui/core/Button';

import { EmbeddedShow } from 'components';
import { GET_USER_CPD_URLS } from 'services/customActions';
import { fetchEnd, fetchStart, useDataProvider } from 'react-admin';
import { useDispatch } from "react-redux";
import OpenNewTabIcon from 'assets/OpenNewTabIcon';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    divider: {
        marginBottom: '12px',
    },
    connectorLine: {
        borderLeft: '2px solid #B5B5B5',
        display: 'none',
    },
    connectorLineNoBorder: {
        display: 'none',
        border: 'none',
    },
    connectorRoot: {
        padding: '0 !important',
    },
}));

const CPDPublicationStatus2 = ({ trainingStatus }) => {
    // Define the status constants
    const IN_DEVELOPMENT = 'In Development';
    const SUBMITTED_FOR_REVIEW = 'Submitted For Review';
    const REVIEW_IN_PROGRESS = 'Review In Progress';
    const REVIEWED_COMMENTS = 'Reviewed - Comments';
    const REVIEWED_CUSTOMER_REVISION = 'Reviewed - Customer Revision';
    const REVIEWED_APPROVED = 'Reviewed - Approved';
    const PUBLISHED = 'Published';

    const runwayOrgConfig = loadObject('runwayOrgConfig')?.config;
    const reviewEnabled = runwayOrgConfig?.reviewConfig === 'true';
    const userData = loadObject(loadObject('project').id + '-user');

    const availableTimezones = (moment(new Date()).isDST()
        ? timezones_daylight
        : timezones_standard
    ).map(tz => {
        return {
            id: tz.offset,
            name: tz.name,
            offsetString: tz.offsetString,
        };
    });

    const userTimeZone = getUserTimeZone();
      
    const projectStatus = trainingStatus.moduleStatus;

    const stepConfigs = {
        'DRAFT': {
            isCurrent: projectStatus === IN_DEVELOPMENT,
            isCompleted: [
                SUBMITTED_FOR_REVIEW,
                REVIEW_IN_PROGRESS,
                REVIEWED_COMMENTS,
                REVIEWED_CUSTOMER_REVISION,
                REVIEWED_APPROVED,
                PUBLISHED,
            ].includes(projectStatus),
            isFuture: false,
        },
        'TALESPIN REVIEW': {
            isCurrent: [
                SUBMITTED_FOR_REVIEW,
                REVIEW_IN_PROGRESS,
                REVIEWED_COMMENTS,
                REVIEWED_CUSTOMER_REVISION,
            ].includes(projectStatus),
            isCompleted: [REVIEWED_APPROVED, PUBLISHED].includes(projectStatus),
            isFuture: projectStatus === IN_DEVELOPMENT,
        },
        'PUBLISHED': {
            isCurrent: false,
            isCompleted: projectStatus === PUBLISHED,
            isFuture: [
                IN_DEVELOPMENT,
                SUBMITTED_FOR_REVIEW,
                REVIEW_IN_PROGRESS,
                REVIEWED_COMMENTS,
                REVIEWED_CUSTOMER_REVISION,
                REVIEWED_APPROVED,
            ].includes(projectStatus),
        },
    };

    const classes = useStyles();

    const dataProvider = useDataProvider();

    const dispatch = useDispatch();

    const [isMoreInfo, setMoreInfo] = useState(true);

    const [fetchingLink, setFetchingLink] = useState(false);

    const setLinkFetched = () => {
		dispatch(fetchEnd());
		setFetchingLink(false);
	}

    const fetchAuthenticatedLink = () => {
		const promise = dataProvider(GET_USER_CPD_URLS, 'getUserURLs', { appKeyName: trainingStatus?.appKeyName, fetchCPDWebToken: true, orgId: getOrgId() });
		dispatch(fetchStart());
		setFetchingLink(true);
		openNewTabWithPromise(promise, 'cpdWebServiceWithToken', setLinkFetched);
	};

    function getTitleChip(displayName, isDisabled) {
        let value = '';
        let chipColors = {};
        switch (displayName) {
            case 'inDevelopment':
                value = 'DRAFT';
                chipColors = {
                    background: '#89C32D',
                    borderColor: '#89C32D',
                };
                break;

            case 'inReview':
                value = 'TALESPIN REVIEW';
                chipColors = {
                    background: '#F0A033',
                    borderColor: '#F0A033',
                };
                break;
            case 'published':
                value = 'PUBLISHED';
                chipColors = {
                    background: '#32B0FB',
                    borderColor: '#32B0FB',
                };
                break;
            default:
            //do nothing
        }

        if (isDisabled) {
            chipColors = {
                background: '#B5B5B5',
                borderColor: '#B5B5B5',
            };
        }

        return (
            <div
                style={{
                    borderRadius: '16px',
                    alignItems: 'center',
                    textAlign: 'center',
                    display: 'flex',
                    padding: '5px 16px',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                    color: '#FFFFFF',
                    letterSpacing: '1.3px',
                    fontFamily: 'Montserrat Semi Bold',
                    width: 'fit-content',
                    lineHeight: '18px',
                    ...(isDisabled
                        ? {
                            background: '#B5B5B5',
                            borderColor: '#B5B5B5',
                        }
                        : chipColors),
                }}
            >
                {value}
            </div>
        );
    }

    function CustomStepContent({ label }) {
        const theme = useTheme();

        switch (label) {
            case 'DRAFT':
                return (
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '-38px',
                            flexDirection: 'row',
                            paddingBottom: '24px',
                            paddingTop: '10px',
                            marginBottom: '24px',
                            borderBottom: `1px solid ${theme.palette.borderColor.divider}`,
                        }}
                    >
                        <div
                            style={{
                                width: '250px',
                                minWidth: '250px',
                            }}
                        >
                            {getTitleChip('inDevelopment', false)}

                            {
                                <span
                                    style={{
                                        display: 'flex',
                                        marginTop: '8px',
                                        fontSize: '14px',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span style={{ color: '#B5B5B5' }}>Last modified by:</span>
                                    {trainingStatus.stagingStatus.userName} <br />
                                    {moment(trainingStatus.stagingStatus.lastStagedDate)
                                        .utcOffset(userTimeZone.id)
                                        .format('MM/DD/YYYY h:mm A')}{' '}
                                    {userTimeZone.name}
                                </span>
                            }
                        </div>
                        <div
                            style={{
                                color: '#000000',
                            }}
                        >
                            <span
                                style={{
                                    display: 'flex',
                                    fontFamily: 'Montserrat Bold',
                                    marginBottom: "5px"
                                }}
                            >
                                Develop your content
                            </span>
                            <span>
                                1. Build your flows.
                                <br />
                                2. Adjust metadata including module name, module description,
                                module icon, hero image, screenshots, and learning objectives
                                below by selecting the <b>Edit Module Details</b> button.
                                <br />
                                3. Double check your content and module details are configured
                                and ready for publishing.
                                <br />
                                {!reviewEnabled && <>4. When done, Publish Project via the{' '}
                                <b>{'CoPilot Designer Project > Publication'}</b> page. </>}

                                {reviewEnabled && <>4. When done, submit the project for review via the {' '}
                                <b>{'CoPilot Designer Project > Publication'}</b> page. </>}
                                <br />
                            </span>
                        </div>
                    </div>
                );
            case 'TALESPIN REVIEW':
                return (
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '-38px',
                            flexDirection: 'row',
                            paddingBottom: '24px',
                            paddingTop: '10px',
                            marginBottom: '24px',
                            borderBottom: `1px solid ${theme.palette.borderColor.divider}`,
                        }}
                    >
                        <div
                            style={{
                                width: '250px',
                                minWidth: '250px',
                            }}
                        >
                            {getTitleChip('inReview', stepConfigs['TALESPIN REVIEW'].isFuture)}

                            {(stepConfigs['TALESPIN REVIEW'].isCompleted ||
                                stepConfigs['TALESPIN REVIEW'].isCurrent) && (
                                    <span
                                        style={{
                                            display: 'flex',
                                            marginTop: '8px',
                                            fontSize: '14px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <span style={{ color: '#B5B5B5' }}>Submitted by:</span>
                                        {trainingStatus.reviewStatus.userNameSubmittedForReview ||
                                            trainingStatus.reviewStatus.userName}{' '}
                                        <br />
                                        {moment(trainingStatus.reviewStatus.dateSubmittedForReview)
                                            .utcOffset(userTimeZone.id)
                                            .format('MM/DD/YYYY h:mm A')}{' '}
                                        {userTimeZone.name}
                                    </span>
                                )}
                        </div>
                        {(stepConfigs['TALESPIN REVIEW'].isCompleted ||
                            stepConfigs['TALESPIN REVIEW'].isCurrent) && (
                                <div>
                                    <span
                                        style={{
                                            display: 'flex',
                                            fontFamily: 'Montserrat Bold',
                                            marginBottom: "5px"
                                        }}
                                    >
                                        Content is in Talespin Review
                                    </span>
                                    <span>
                                        1. A member from the Talespin content review team will review
                                        your content. <br />
                                        2. A Talespin representative will contact you if changes need
                                        to be made to the content. <br />
                                        3. If approved, your content will be published and the status will be updated below. <br />
                                    </span>
                                </div>
                            )}
                    </div>
                );
            case 'PUBLISHED':
                return (
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '-38px',
                            flexDirection: 'row',
                            paddingBottom: '24px',
                            paddingTop: '10px',
                            marginBottom: '24px',
                            borderBottom: `1px solid ${theme.palette.borderColor.divider}`,
                        }}
                    >
                        {
                            <div
                                style={{
                                    width: '250px',
                                    minWidth: '250px',
                                }}
                            >
                                {getTitleChip('published', stepConfigs.PUBLISHED.isFuture)}

                                {stepConfigs.PUBLISHED.isCompleted && (
                                    <span
                                        style={{
                                            display: 'flex',
                                            marginTop: '8px',
                                            fontSize: '14px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <span style={{ color: '#B5B5B5' }}>Published by:</span>
                                        {/* {
                                    if reviewEnable - the person who submitted the training for review would also be used as the person who published the training
                                    if reviewDisabled - should be provided based on the User who published the training via CPD Web
                                } */}
                                        {reviewEnabled
                                            ? trainingStatus.reviewStatus.userNameSubmittedForReview
                                            : trainingStatus.publishedStatus.userName}{' '}
                                        <br />
                                        {moment(trainingStatus.publishedStatus.lastPublishedDate)
                                            .utcOffset(userTimeZone.id)
                                            .format('MM/DD/YYYY h:mm A')}{' '}
                                        {userTimeZone.name}
                                    </span>
                                )}
                            </div>
                        }
                        {stepConfigs.PUBLISHED.isCompleted && (
                            <div>
                                <span
                                    style={{
                                        display: 'flex',
                                        fontFamily: 'Montserrat Bold',
                                        marginBottom: "5px"
                                    }}
                                >
                                    {reviewEnabled ? 'Approved and Published' : 'Published'}
                                </span>
                                <span>
                                    Congratulations! Your content is now published and ready to be
                                    experienced in the Talespin App by learners in your
                                    organization.
                                </span>
                            </div>
                        )}
                    </div>
                );
            default:
                return 'Unknown step';
        }
    }

    return (
			<div className={classes.root}>
				{runwayOrgConfig?.enableCPDPublication === 'true' &&
					getUserInfo().trainingModuleEditor && (
						<div
							style={{
								marginBottom: '24px',
							}}
						>
							<EmbeddedShow
								title={
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center',
											alignItems: 'center',
											gap: '10px',
										}}
									>
										<Typography
											style={{
												fontFamily: 'Archivo Bold',
												fontSize: '20px',
											}}
										>
											Publication Status
										</Typography>
									</div>
								}
								actions={[
									{
										render: () => (
											<MUIButton
												variant={'contained'}
												color='primary'
												onClick={() => fetchAuthenticatedLink()}
                                                style={{
                                                    fontSize: "12px"
                                                }}
                                                disabled={fetchingLink}
                                                startIcon={<OpenNewTabIcon style={{ fontSize: '16px' }}/>}
											>
												{'View in CoPilot Designer'}
											</MUIButton>
                                        ),
									},
								]}
							>
								<Stepper
									orientation='vertical'
									style={{padding: '12px 24px 0 24px'}}
									activeStep={3}
									connector={
										<StepConnector
											classes={{
												line: isMoreInfo
													? classes.connectorLine
													: classes.connectorLineNoBorder,
												lineVertical: classes.lineVertical,
												root: classes.connectorRoot,
											}}
										/>
									}
								>
									{Object.keys(stepConfigs).map((label, index) => {
										const stepConfig = stepConfigs[label];

										// let opacity = 1;
										let icon = null;
										if (stepConfig.isCurrent)
											icon = (
												<FiberManualRecordIcon
													style={{
														fontSize: '22px',
														width: '22px',
														height: '22px',
													}}
												/>
											);
										if (stepConfig.isCompleted)
											icon = (
												<CheckCircleIcon
													style={{
														fontSize: '33px',
													}}
												/>
											);

										if (!isMoreInfo && index === 0 && !stepConfig.isCurrent)
											return <span key={`step-${index + 1}`}></span>;
										if (index === 1 && !reviewEnabled)
											return <span key={`step-${index + 1}`}></span>;
										if (
											!isMoreInfo &&
											index === 1 &&
											((!stepConfig.isCompleted && !stepConfig.isCurrent) ||
												stepConfigs.PUBLISHED.isCompleted)
										)
											return <span key={`step-${index + 1}`}></span>;
										if (!isMoreInfo && index === 2 && !stepConfig.isCompleted)
											return <span key={`step-${index + 1}`}></span>;
										return (
											<Step
												expanded={true}
												completed={true}
												key={`step-${index + 1}`}
											>
												<StepLabel
													icon={
														<div
															style={{
																border: stepConfig.isCompleted
																	? 'none'
																	: '2px solid #B5B5B5',
																borderRadius:
																	stepConfig.isCompleted === 0 ? 'none' : '50%',
																width: '30px',
																height: '30px',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
														>
															{icon}
														</div>
													}
												></StepLabel>
												<StepContent
													style={{
														marginTop: '-1px',
														borderLeft:
															index !== 2 && isMoreInfo
																? `2px solid #B5B5B5`
																: 'none',
														paddingLeft: '32px',
														marginLeft: '14.5px',
													}}
												>
													<Typography>
														<CustomStepContent
															label={label}
															trainingStatus={trainingStatus}
														/>
													</Typography>
												</StepContent>
											</Step>
										);
									})}
									<div
										style={{
											cursor: 'pointer',
											display: 'flex',
											flexDirection: isMoreInfo ? 'column' : 'column-reverse',
											alignItems: 'center',
										}}
										onClick={() => {
											setMoreInfo(!isMoreInfo);
										}}
									>
										<span style={{}}>
											{isMoreInfo ? (
												<ExpandLessIcon
													color={'primary'}
													style={{
														fontSize: '24px',
													}}
												/>
											) : (
												<ExpandMoreIcon
													color={'primary'}
													style={{
														fontSize: '24px',
													}}
												/>
											)}
										</span>
										<Typography
											color={'primary'}
											style={{
												fontFamily: 'Montserrat Medium',
												fontSize: '14px',
											}}
										>
											{isMoreInfo ? 'Show Less' : 'Show More'}
										</Typography>
									</div>
								</Stepper>
							</EmbeddedShow>
						</div>
					)}
			</div>
		);
};

export default CPDPublicationStatus2;
