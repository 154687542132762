import * as React from 'react';
import {DiagramEngine} from '@projectstorm/react-diagrams-core';

import {CustomLinkModel} from './CustomLinkModel';
import CustomLinkSegmentWidget from './CustomLinkSegmentWidget';

export interface CustomLinkProps {
    link: CustomLinkModel;
    diagramEngine: DiagramEngine
}

export interface CustomLinkState {
}

export default class CustomLinkWidget extends React.Component<CustomLinkProps, CustomLinkState> {
    refPaths: React.RefObject<SVGPathElement>[];

    constructor(props: CustomLinkProps) {
        super(props);
        this.refPaths = [];
        this.state = { };
    }

    componentDidUpdate(): void {
        this.props.link.setRenderedPaths(
            this.refPaths.map((ref) => {
                return ref.current;
            })
        );
    }

    componentDidMount(): void {
        this.props.link.setRenderedPaths(
            this.refPaths.map((ref) => {
                return ref.current;
            })
        );
    }

    componentWillUnmount(): void {
        this.props.link.setRenderedPaths([]);
    }

    generateLink(path: string, extraProps: any, id: string | number): JSX.Element {
        const ref = React.createRef<SVGPathElement>();
        this.refPaths.push(ref);

        return (
            <CustomLinkSegmentWidget
                key={`link-${id}`}
                path={path}
                diagramEngine={this.props.diagramEngine}
                factory={this.props.diagramEngine.getFactoryForLink(this.props.link)}
                link={this.props.link}
                forwardRef={ref}
                extras={extraProps}
            />
        );
    }

    render() {
        const path = this.generateLink(
            this.props.link.getSVGPath(),
            {
                'data-linkid': this.props.link.getID(),
                'data-point': 1,
            },
            1
        );

        this.refPaths = [];

        return <g>{path}</g>;
    }
}
