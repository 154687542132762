import {useLogout, useTranslate} from 'react-admin';
import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {getUserInfo} from 'utils/Data';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MUIButton from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import {MuiThemeProvider, useTheme} from '@material-ui/core/styles';
import {DialogListTheme} from 'components/Theme';
import IconError from 'components/Icons/IconError';

import tosService from 'services/tosService';
import moment from 'moment';

const TermsOfService = props => {
	const logout = useLogout();

	const projectRaw = sessionStorage.getItem('project');
	const project = projectRaw ? JSON.parse(projectRaw) : null;
	const runwayOrgConfigRaw = sessionStorage.getItem('runwayOrgConfig');
	const runwayOrgConfig = runwayOrgConfigRaw
		? JSON.parse(runwayOrgConfigRaw)
		: null;
	const termsOfServiceRaw =
		project && project.id
			? sessionStorage.getItem(project.id + '-termsOfService')
			: null;
	const termsOfService = termsOfServiceRaw
		? JSON.parse(termsOfServiceRaw)
		: null;

	const [currentTab, setCurrentTab] = useState('termsRunway');
	const [accepted, setAccepted] = useState(
		termsOfService && termsOfService.userAccepted ? true : false
	);

	const translate = useTranslate();

	const theme = useTheme();

	// Safety-Net: If the user is not authenticated, redirect them to login.
	if (!getUserInfo().isAuthenticated) {
		return <Redirect to='login' />;
	}

	// Safety-Net: If the user has already accepted the Terms Of Service, redirect them to overview.
	if (!runwayOrgConfig || !termsOfService || termsOfService.userAccepted) {
	    return <Redirect to='/' />;
	}

	const handleAcceptAll = async () => {
		if (accepted) {
			await tosService.acceptTermsOfService(project.id, termsOfService);
			setAccepted(false);
		}
	};

	return (
		<MuiThemeProvider theme={DialogListTheme}>
			<Dialog
				aria-labelledby='simple-dialog-title'
				open={true}
				fullWidth={true}
				maxWidth={'md'}
				style={{minHeight: '50%', margin: 'auto'}}
			>
				<DialogTitle
					id='simple-dialog-title'
					style={{display: 'flex', flexDirection: 'column'}}
					disableTypography={true}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							justifyContent: 'space-between',
						}}
					>
						{' '}
							{translate('Legal Information Update')}
						{termsOfService && termsOfService.termsEntity && (
							<label
								style={{flex: '0 0 auto', color: theme.palette.text.info, fontSize: '14px', fontFamily: 'Montserrat Medium'}}
							>
								{translate('Version')}:{' '}
								{moment(termsOfService.termsEntity.modifiedAt).format(
									'MM/DD/YYYY'
								)}
							</label>
						)}
					</div>
					<div>
						<div
							style={{
								flex: '0 0 auto',
								dispaly: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								marginTop: '10px',
							}}
						>
							<Typography style={{fontFamily: 'Montserrat Italic'}}>
								{translate(
									`
                                Our legal information is updated from time to time.
                                Please review the below information and accept the latest version of our Terms of Service and Privacy Policy to continue.
                                `
								)}
							</Typography>
							<div
								style={{
									flex: '0 0 auto',
									marginTop: '10px',
									width: '100%',
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<MUIButton
									style={{textTransform: 'none', height: '32px'}}
									onClick={() => setCurrentTab('termsRunway')}
									color={currentTab === 'termsRunway' ? 'primary' : 'secondary'}
									variant={'contained'}
								>
									{translate('Terms of Service')}
								</MUIButton>
								<MUIButton
									style={{textTransform: 'none', height: '32px'}}
									onClick={() => setCurrentTab('privacyRunway')}
									color={
										currentTab === 'privacyRunway' ? 'primary' : 'secondary'
									}
									variant={'contained'}
								>
									{translate('Privacy Policy')}
								</MUIButton>
							</div>
						</div>
					</div>
				</DialogTitle>

				<DialogContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'stretch',
					}}
				>
					<div
						style={{
							flex: '1 1 auto',
							minHeight: '200px',
							marginTop: '10px',
							marginBottom: '10px',
						}}
					>
						{currentTab === 'termsRunway' && (
							<div
								dangerouslySetInnerHTML={{
									__html:
										termsOfService && termsOfService.termsEntity
											? termsOfService.termsEntity.termsRunway
											: '',
								}}
							></div>
						)}

						{currentTab === 'privacyRunway' && (
							<div
								dangerouslySetInnerHTML={{
									__html:
										termsOfService && termsOfService.termsEntity
											? termsOfService.termsEntity.privacyRunway
											: '',
								}}
							></div>
						)}
					</div>
					{termsOfService && termsOfService.error && (
						<div
							style={{
								flex: '0 0 auto',
								display: 'flex',
								flexdirection: 'row',
								alignItems: 'center',
								border: '1px solid #F14242',
								padding: '5px',
								marginBottom: '10px',
							}}
						>
							<IconError style={{marginRight: '8px'}} />
							<label>{termsOfService.error}</label>
						</div>
					)}
				</DialogContent>

				<DialogActions
					style={{
						display: 'flex',
						flexdirection: 'column',
						alignItems: 'flex-start',
						flexDirection: 'column',
					}}
				>
					<div>
						<FormControlLabel
							control={
								<Checkbox
									checked={accepted}
									onChange={() => setAccepted(!accepted)}
									color='primary'
								/>
							}
							label={
								<Typography style={{ fontFamily: 'Montserrat Italic'}}>
									{translate(
										'I have read and agree to the Terms of Service and Privacy Policy'
									)}
								</Typography>
							}
						/>
					</div>
					<div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
						<MUIButton
							color='secondary'
							variant='contained'
							onClick={() => logout()}
							style={{ marginRight:'8px'}}
						>
							{translate('Log Out')}
						</MUIButton>
						<MUIButton
							color='primary'
							variant='contained'
							disabled={!accepted}
							onClick={handleAcceptAll}
						>
							{translate('Continue')}
						</MUIButton>
					</div>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

export default TermsOfService;
