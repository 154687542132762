import 'date-fns';

import {Form} from 'react-final-form';
import React, { useEffect, useState } from 'react';
import {useTranslate} from 'react-admin';
import get from 'lodash/get';
import MUIButton from '@material-ui/core/Button';

import Box from '../layout/Box';
import CustomField from '../form/CustomField';
import Grid from '../layout/Grid';
import Label from '../Label';
import {CustomDialog} from '../index';

const ResetPasswordDialog = props => {
	let formReference;

	const translate = useTranslate();

	const [formValid, setFormValid] = useState(false);

	let {
		resetCredential,
		isResetCredential,
		setIsResetCredential,
		handleCredentialFormSubmit,
		onCredentialSubmit,
		state,
	} = props;

	useEffect(() => {
		formReference?.reset();
	}, [isResetCredential]);

	return (
		<div>
			{isResetCredential && <CustomDialog
				title={
					resetCredential.mode === 'SET'
						? translate('Set Password')
						: translate('Reset Password')
				}
				isOpen={true}
				setIsOpen={() => {
					setIsResetCredential(false);
				}}
				width={'365px'}
				actions={[
					{
						render: handleClose => {
							return (
								<MUIButton
									color='secondary'
									variant='contained'
									onClick={() => {
										handleClose();
									}}
								>
									{translate('Cancel')}
								</MUIButton>
							);
						},
					},
					{
						render: () => {
							return (
								<MUIButton
									color='primary'
									variant='contained'
									onClick={() => {
										handleCredentialFormSubmit();
									}}
									disabled={resetCredential.mode === 'SET' && !formValid}
								>
									{resetCredential.mode === 'SET'
										? translate('Set')
										: translate('Reset')}
								</MUIButton>
							);
						},
					},
				]}
			>
				<Box padding={'16px 0px'}>
					<Form
						onSubmit={onCredentialSubmit}
						initialValues={{
							newPassword: '',
							confirmPassword: '',
						}}
						validate={values => {
							const errors = {};

							if (resetCredential.mode === 'SET') {
								if (!values.newPassword) {
									errors.newPassword = 'Required';
								}

								if (!values.confirmPassword) {
									errors.confirmPassword = 'Required';
								}

								if (values.newPassword !== values.confirmPassword) {
									errors.confirmPassword = 'Passwords must match';
								}

								if (errors.confirmPassword || errors.newPassword) {
									setFormValid(false);
								} else {
									setFormValid(true);
								}
							}

							return errors;
						}}
					>
						{({handleSubmit, form, submitting, pristine, values, errors}) => {
							handleCredentialFormSubmit = handleSubmit;
							formReference = form;

							return (
								<Grid perRow={1}>
									{resetCredential.mode === 'SET' && (
										<div style={{fontFamily: 'Montserrat Medium'}}>
											{translate('Set the password for ') +
												get(state, 'profileInfo.information.name')}
										</div>
									)}
									{resetCredential.mode === 'RESET' && (
										<div style={{fontFamily: 'Montserrat Medium'}}>
											{translate('Reset the password for ') +
												`${get(state, 'profileInfo.information.name') && get(state, 'profileInfo.information.name').trim().length > 0 ? get(state, 'profileInfo.information.name'): get(state, 'profileInfo.information.email')}`}
										</div>
									)}
									{resetCredential.mode === 'SET' && (
										<div>
											<Label label={'New Password'} />
											<CustomField
												name='newPassword'
												type='password'
												component='input'
											/>
										</div>
									)}
									{resetCredential.mode === 'SET' && (
										<div>
											<Label label={'Confirm Password'} />
											<CustomField
												name='confirmPassword'
												type='password'
												component='input'
											/>
										</div>
									)}
								</Grid>
							);
						}}
					</Form>
				</Box>
			</CustomDialog>}
		</div>
	);
};

export default ResetPasswordDialog;
