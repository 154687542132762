import 'date-fns';

import React, {useEffect, useState} from 'react';
import {CREATE, useDataProvider} from 'react-admin';
import get from 'lodash/get';
import MUIButton from '@material-ui/core/Button';

import DatePicker from 'components/DatePicker';
import CustomDialog from '../../../components/dialogs/CustomDialog';
import Grid from '../../../components/layout/Grid';
import Label from '../../../components/Label';
import {loadObject} from '../../../utils/Util';
import TextInput from '../../../components/TextInput';
import moment from "moment";

const SendWelcomeEmail = ({record, isOpen, setIsOpen}) => {
	const initialState = {
		welcomeEmailTime: new Date(Date.now()),
		welcomeEmail: true,
		sendWelcomeType: 'sendNow',
		isClosing: false,
		dateValue: new Date(Date.now()),
		timeValue: moment(new Date(new Date(Date.now()).setSeconds(0))).add(5, 'm').toDate(),
	};

	const [state, setState] = useState(initialState);

	const [disabled, setDisabled] = useState(false);

	const dataProvider = useDataProvider();
	const [isProcessing, setIsProcessing] = useState(false);

	const project = loadObject('project');
	const authType = get(project, 'config.authType');

	useEffect(() => {
		setState(initialState);
	}, [isOpen])

	const isValidDate = (value) => {
		let isValid = true;
		if (Object.prototype.toString.call(value) === "[object Date]") {
			if (isNaN(value)) {
				isValid = false;
			} else {
				isValid = true;
			}
		} else {
			isValid = false;
		}
		return isValid;
	}

	const sendWelcome = handleClose => {
		handleClose();
		const timeString = state.timeValue.toString();
		const timeSliced = timeString.slice(15, timeString.length);
		const dateSliced = state.dateValue.toString().slice(0, 15);
		const value = new Date(dateSliced + timeSliced);

		dataProvider(CREATE, 'sendWelcomeMail', {
			data: {
				users: [record['id']],
				authType: authType,
				welcomeEmailTime:
					state.sendWelcomeType === 'schedule'
						? Date.parse(value.toUTCString())
						: '',
				sendEmails: true,
			},
		})
			.then(response => {
				if (response.status === 200) {
					setIsProcessing(false);
				}
			})
			.catch(error => {});
	};

	const dialog = () => {
		return (
			<CustomDialog
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title={'Send Welcome Email'}
				width={'475px'}
				actions={[
					{
						render: handleClose => {
							return (
								<MUIButton
									color='secondary'
									variant='contained'
									onClick={() => handleClose()}
								>
									Cancel
								</MUIButton>
							);
						},
					},
					{
						render: handleClose => {
							return (
								<MUIButton
									color='primary'
									variant='contained'
									onClick={() => sendWelcome(handleClose)}
									disabled={state.sendWelcomeType === 'schedule' && (!isValidDate(state.dateValue) || !isValidDate(state.timeValue) || disabled)}
								>
									Confirm
								</MUIButton>
							);
						},
					},
				]}
			>
				{(() => {
					if (isProcessing) {
						return <Grid perRow={1}>Sending email...</Grid>;
					}

					return (
						<Grid perRow={1}>
							<div>
								<Label label={'Email Address'} paddingBottom={'0px'} />
								<TextInput value={record && record.contactEmail ? record.contactEmail : ''} disabled={true} />
							</div>
							<div>
								<Label label={'Schedule Email'} />
								{isOpen && <DatePicker
									sendWelcomeType={state.sendWelcomeType}
									onDateChange={date => {
										setState({...state, dateValue: date});
									}}
									onTimeChange={date => {
										setState({...state, timeValue: date})
									}}
									handleWelcomeTypeChange={type =>
										setState({...state, sendWelcomeType: type})
									}
									onDateTimeValidityChange={(val) => {
										setDisabled(val);
									}}
									dateValue={state.dateValue}
									timeValue={state.timeValue}
									required={true}
								/>}
							</div>
						</Grid>
					);
				})()}
			</CustomDialog>
		);
	};

	return <div>{dialog()}</div>;
};

export default SendWelcomeEmail;
