import { DataOptions } from "components/VectorMap/OptionsBlade";
import { getGroupOption } from "../options";

/**
 * Determine if the current grouping is via Category.
 * 
 * @param options 
 */
export const isGroupingByCategory = (options: DataOptions) => {
    const groupOption = options.group;

    if (groupOption && 'category' === groupOption.value) return true;

    return false;
};

/**
 * Determine if the current grouping is via a specific Training.
 * 
 * @param options 
 */
export const isGroupingByTraining = (options: DataOptions) => {
    const groupOption = options.group;

    if (groupOption && 'related-training' === groupOption.value) {
        const trainingGroupOption = getGroupOption(groupOption.children, 'related-training');
        const trainingGroupOptionValue = trainingGroupOption ? trainingGroupOption.value : null;

        if (trainingGroupOptionValue) return true;
    }

    return false;
};

/**
 * Determine if the current grouping is via a specific Lesson.
 * 
 * @param options 
 */
export const isGroupingByLesson = (options: DataOptions) => {
    const groupOption = options.group;

    if (groupOption && 'related-training' === groupOption.value) {
        const trainingGroupOption = getGroupOption(groupOption.children, 'related-training');
        const trainingGroupOptionValue = trainingGroupOption ? trainingGroupOption.value : null;
        const lessonGroupOption = trainingGroupOptionValue ? getGroupOption(groupOption.children, 'related-training.' + trainingGroupOptionValue) : null;
        const lessonGroupOptionValue = lessonGroupOption ? lessonGroupOption.value : null;

        if (lessonGroupOptionValue) return true;
    }

    return false;
};

/**
 * Determine the appropriate heatmap colors for the supplied value.
 * 
 * @param userValue 
 * @param maxValue 
 */
export const determineHeatmapNodeColorByPercentage = (userValue: number | null, maxValue: number | null) => {
    const percentage = userValue != null && maxValue != null && !isNaN(userValue) && !isNaN(maxValue)
        ? userValue < 0
            ? -1
            : maxValue === 0
                ? 0
                : (userValue / maxValue) * 100
        : 0;

    if (percentage < 0) return '#EE0000';
    else if (percentage === 0) return '#878787';
    else if (percentage <= 10) return '#ABD537';
    else if (percentage > 10 && percentage <= 20) return '#97C521';
    else if (percentage > 20 && percentage <= 30) return '#92BC1F';
    else if (percentage > 30 && percentage <= 40) return '#76AC1F';
    else if (percentage > 40 && percentage <= 50) return '#54911C';
    else if (percentage > 50 && percentage <= 60) return '#4A7E19';
    else if (percentage > 60 && percentage <= 70) return '#37670F';
    else if (percentage > 70 && percentage <= 80) return '#29540A';
    else if (percentage > 80 && percentage <= 90) return '#234707';
    else return '#203E04';
}

/**
 * Determine the appropriate heatmap colors for the supplied value.
 * 
 * @param value 
 */
export const determineHeatmapNodeColorByBoolean = (value: boolean) => {
    if (value) return '#B91C06';
    else return '#878787';
}

