import React from 'react';
import {Tab} from 'react-admin';
import TeamList from './TeamList';

const Team = props => {
	return (
		<Tab label='Team'>
			<TeamList {...props} />
		</Tab>
	);
};

export default Team;
