import {CircularProgressBar, LinearProgressBar} from 'components';
import {useTheme} from '@material-ui/core/styles';
import React, {} from 'react';
import {useTranslate} from 'react-admin';
import InfoIcon from '@material-ui/icons/Info';
import PanelInfo from '../../components/Panels/PanelInfo';
import LoadingInfo from '../../components/loading/LoadingInfo';
import get from 'lodash/get';
import PanelInfoPlaceholder from '../../components/Panels/PanelInfoPlaceholder';
import ResourceRedirect from 'components/ResourceRedirect';
import CustomToolTip from '../../components/customToolTip';

const RecentTeamAveragesPanel = props => {
	const translate = useTranslate();

	const theme = useTheme();

	const { recentTeamAverages, error } = props;

	const title = translate('Recent Team Averages');

	const items = get(recentTeamAverages, 'items');

	const hasItems = items && items.length > 0;

	if (hasItems) {
		return (
			<PanelInfo
				title={
					<div style={{display: 'flex', width: '100%'}}>
						<div style={{flex: 1}}>{title} </div>

						<div style={{marginRight: '16px'}}>
							<CustomToolTip
								title={` Average scores for Teams with recently completed lessons `}
								placement='right-start'
							>
								<InfoIcon />
							</CustomToolTip>
						</div>
					</div>
				}
			>
				<div>
					<div
						style={{
							flex: '1 1 auto',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'flex-start',
						}}
					>
						<div style={{marginRight: '25px', display: 'flex'}}>
							<CircularProgressBar
								title={recentTeamAverages.label}
								value={Math.round(recentTeamAverages.avg * 100)}
								delta={Math.round(recentTeamAverages.avgDelta * 100)}
							/>
							<div style={{paddingLeft: '1px'}}>
								<CustomToolTip
									title={`Organization-wide gain in the Org Overall Average score, in the last 14 days`}
									placement='right-start'
								>
									<InfoIcon />
								</CustomToolTip>
							</div>
						</div>
						<div
							style={{
								display: 'grid',
								width: '100%',
								gridGap: '10px',
							}}
						>
							{recentTeamAverages.items &&
								Array.isArray(recentTeamAverages.items) &&
								recentTeamAverages.items.map(function(item, i) {
									return (
										<LinearProgressBar
										  key={item.id}
											title={
												<div
													style={{
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
														maxWidth: '175px',
													}}
													title={item.title}
												>
													<ResourceRedirect
														id={item.id}
														value={item.title}
														resource={'teams'}
													/>
												</div>
											}
											value={Math.round(item.scoreRatio * 100)}
											backgroundColor={theme.palette.background.default}
										/>
									);
								})}
						</div>
					</div>
				</div>
			</PanelInfo>
		);
	}

	if (items && items.length === 0) {
		return (
			<PanelInfoPlaceholder title={title}>
				Your users haven't finished any training modules yet. <br />
				<br />
				This panel will track the performance of teams in your organization over
				the last 14 days, indicating average gain in score percentages and
				listing the top three performing teams.
			</PanelInfoPlaceholder>
		);
	}

	if (error) {
		return <PanelInfoPlaceholder title={title}>{error}</PanelInfoPlaceholder>
	}

	return (
		<PanelInfo>
			<LoadingInfo />
		</PanelInfo>
	);
};

export default RecentTeamAveragesPanel;
