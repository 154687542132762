import {
	CREATE,
	fetchEnd,
	fetchStart,
	translate,
	withDataProvider,
} from 'react-admin';
import {
	DialogTitle,
	EmbeddedList,
	EmbeddedShow,
	EmbeddedTabs,
	ScreenTitle,
	Text,
} from 'components';
import DatePicker from 'components/DatePicker';
import {createStyles, withStyles} from '@material-ui/core/styles';
import React from 'react';
import {
	GET_GUIDE_URL,
	GET_TEMPLATE_URL,
	GET_GENERATE_USER_ACCESS,
	GET,
} from 'services/customActions';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {CircularProgress} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MUIButton from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ProgressReports from 'sections/ToolsReports/ProgressReports';
import LoadingList from 'components/loading/LoadingList';
import {isValidDate, loadObject} from 'utils/Util';
import {getUserInfo} from 'utils/Data';
import RawPerfomanceLog from './RawPerfomanceLog';
import CreateMultipleUsersCard from './CreateMultipleUsers';
import PALUnavailableDialog from 'components/PALUnavailableDialog';
import PackageIntegration from "sections/ToolsReports/PackageIntegration";
import moment from "moment";
import FileDownload from 'assets/FileDownload';
import { AddOutlined, InsertDriveFileOutlined } from '@material-ui/icons';
import EmailIcon from 'assets/EmailIcon';

const myStyles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		fieldRow: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			marginTop: '5px',
			padding: '10px',
			border: '1px solid',
			borderRadius: '0px',
		},
		fieldColumn: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			width: '100%',
		},
		row: {
			display: 'flex',
			flexDirection: 'row',
		},
		column: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			width: '100%',
		},
		fieldMultipleColumn: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			marginTop: '5px',
			padding: '10px',
			border: '1px solid',
			borderRadius: '0px',
		},
		basicField: {
			width: '100%',
			marginRight: '10px',
		},
		normalText: {
			color: theme.palette.color.default,
		},
		importantText: {
			color: `${theme.palette.primary.main} !important`,
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
		info: {
			marginLeft: '15px',
			fontFamily: 'Montserrat Italic',
			paddingTop: '6px',
			fontSize: '12px',
		},
		responseText: {
			marginLeft: '15px',
			color: theme.palette.primary.main,
			paddingTop: '6px',
			'& span': {
				color: theme.palette.text.primary,
			},
		},
		welcomeEmailInfo: {
			fontFamily: 'Montserrat Italic',
			paddingTop: '16px',
			paddingBottom: '16px',
			font: '12px',
		},
		label: {
			paddingTop: '10px',
			paddingBottom: '10px',
			fontFamily: 'Montserrat Medium',
		},
		spinnerStyles: {
			position: 'relative',
			marginRight: '5px',
			top: '2px',
			color: theme.palette.primary.main,
		},
		selectedFile: {
			borderRadius: '0px',
			border: `1px solid ${theme.palette.primary.main}`,
			height: '32px',
			textAlign: 'center',
			maxWidth: '204px',
			alignItems: 'center',
			display: 'flex',
		},
		selectedFileName: {
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			maxWidth: '160px',
			paddingLeft: '5px',
		},
	});

class ToolsReports extends React.Component {
	_isMounted = false;

	state = {
		getGuideUrlRunway: '',
		getTemplateUrlRunway: '',
		runwayOrgConfig: {},
		loading: 'initial',
		converted: {},
		form: [],
		isVerifying: false,
		isAdding: false,
		showDialog: false,
		filename: '',
		authType: '',
		welcomeDialog: false,
		timeValue: moment(new Date(new Date(Date.now()).setSeconds(0))).add(5, 'm').toDate(),
		dateValue: new Date(Date.now()),
		sendWelcomeType: 'sendNow',
		userIds: [],
		isUpdating: false,
		isTimerMessageShow: false,
		verifyUsers: [],
		timeout: null,
		addedUserIds: [],
		idstoMail: [],
		isLoading: false,
		faciliatorEmail: '',
		userProfileDialog: false,
		hasGenerateUserAccess: false,
		showNewPALLicenseUnAvailableDialog: false,
		newPALUnavailableErrorMessage: null,
		isDateTimeValid: true
	};
	componentDidMount() {
		this._isMounted = true;
		const project = sessionStorage.getItem('project');
		if (project) {
			try {
				const orgConfig = JSON.parse(project);

				if (orgConfig && orgConfig.config) {
					if (orgConfig.config.createUserAuthType) {
						if (this._isMounted) {
							this.verifyGenerateUserAccess();
							this.setState({
								authType: orgConfig.config.createUserAuthType,
								faciliatorEmail: orgConfig.config.planContactEmail,
								runwayOrgConfig: loadObject('runwayOrgConfig'),
							});
						}
					}
				}
			} catch (error) {
				console.log('Unable to load organization config...', error);
			}
		}
		// dataProvider(GET_RUNWAY_ORG_CONFIG, 'runwayOrgConfig')
		// 	.then(response => {
		// 		const {data: runwayOrgConfig} = response;
		// 		if (this._isMounted) this.setState({runwayOrgConfig});
		// 		if (this._isMounted) this.setState({
		// 			faciliatorEmail: runwayOrgConfig.config.planContactEmail,
		// 		});
		// 	})
		// 	.catch(error => {});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getTemplateUrl = () => {
		const {dataProvider} = this.props;
		const type = this.state.authType;
		dataProvider(GET_TEMPLATE_URL, 'getTemplateUrlRunway', {authType: type})
			.then(response => {
				const link = response.data;
				this.setState({getTemplateUrlRunway: link});
				const url = this.state.getTemplateUrlRunway;
				window.location = url;
			})
			.catch(error => {});
	};
	getGuideUrl = () => {
		const {dataProvider} = this.props;
		const type = this.state.authType;
		dataProvider(GET_GUIDE_URL, 'getGuideUrlRunway', {authType: type})
			.then(response => {
				const link = Object.values(response).join('');
				this.setState({getGuideUrlRunway: link});
				const url = this.state.getGuideUrlRunway;
				window.open(url);
			})
			.catch(error => {});
	};
	UploadCSV = content => {
		this.setState({errorVerifiedLog: null});
		const csv2json = require('csvjson-csv2json');
		const file = csv2json(content, {parseNumbers: true, parseJSON: true});
		return file;
	};
	handleClick = () => {
		this.setState({showDialog: true});
	};
	welcomeDialog = () => {
		this.setState({welcomeDialog: true, isTimerMessageShow: true});
	};
	closeWelcome = () => {
		setTimeout(() => {
			this.setState({isTimerMessageShow: false});
		}, 5000);
		this.setState({welcomeDialog: false});
	};
	handleCloseClick = () => {
		this.setState({showDialog: false});
	};
	_onSelectFile = () => {
		document.getElementById('selectFile_id').click();
	};
	//uploading the file
	handleFileChosen = file => {
		let fileReader;
		const name = file.name;
		const lastDot = name.lastIndexOf('.');
		const ext = name.substring(lastDot + 1);
		if (ext === 'csv') {
			this.setState({filename: name});
			const handleFileRead = e => {
				const content = fileReader.result;

				const fileData = this.UploadCSV(content);
				if (Array.isArray(fileData)) {
					if (fileData.length > 0 && Object.keys(fileData[0]).length > 0 && !Object.keys(fileData[0]).includes('profileId_ReadOnly')) {
						this.verify(fileData);
					} else {
						this.setState({
							errorVerifiedLog: {
								message:
									'Cannot use this file. This file does not match the column format for Bulk Import.',
							},
						});
					}
				} else {
					this.setState({
						errorVerifiedLog: {
							message:
								'File not verified.',
						},
					});
				}
			};
			fileReader = new FileReader();
			fileReader.onloadend = handleFileRead;
			fileReader.readAsText(file);
		} else {
			this.setState({
				errorVerifiedLog: {
					message:
						'File not verified. File must be of type CSV. Select a CSV file to import.',
				},
			});
		}
	};
	//aborting API call
	abortVerifyLog = () => {
		this.abortController.abort();
	};
	done = () => {
		this.setState({
			form: null,
			verifiedUsersData: null,
			existingUsersData: null,
			newUsers: null,
			newTeams: null,
			addedUserData: null,
			sentEmailData: null,
		});
	};
	handleChange = type => {
		this.setState({sendWelcomeType: type});
	};
	handleDateChange = date => {
		this.setState({dateValue: date});
	};
	handleTimeChange = time => {
		this.setState({timeValue: time});
	};
	cancelVerifiedLog = () => {
		this.setState({
			form: null,
			verifiedUsersData: null,
			existingUsersData: null,
			newUsers: null,
			newTeams: null,
		});
	};

	isNewPALLicenseAvailable = async () => {
		const {dataProvider} = this.props;

		let newLicenseAvailable = true;

		await dataProvider(GET, 'isNewPALLicenseAvailable', {
			numToCreate: this.state.verifiedUsersData.newUsers.length,
		}).catch(error => {
			newLicenseAvailable = false;
			this.setState({
				showNewPALLicenseUnAvailableDialog: true,
				newPALUnavailableErrorMessage: error.message,
			});
		});

		return newLicenseAvailable;
	};

	//verifying users
	verify = fileData => {
		const {dataProvider} = this.props;
		this.abortController = null;
		this.abortController = new AbortController();
		const signal = this.abortController.signal;
		fetchStart();
		this.setState({isVerifying: true});
		dataProvider(CREATE, 'users_verify', {
			data: {
				authType: this.state.authType,
				skipTeamUpdate: true,
				skipCreatePlaceholderLessons: true,
				users: fileData,
			},
			signal,
		})
			.then(data => {
				if (!this.state.isVerifyLogAbort) {
					if (!data.errors) {
						this.setState({
							form: fileData,
							verifiedUsersData: data.data,
							existingUsersData: data.data.existingUsers,
							newUsers: data.data.newUsers,
							newTeams: data.data.newTeams,
							isVerifying: false,
						});
						this.getExistingUserIds();
					} else {
						this.setState({
							errorVerifiedLog: data.errors.errorMessage,
						});
					}
				}
			})
			.catch(error => {
				this.setState({
					errorVerifiedLog: error,
				});
			})
			.finally(() => {
				this.setState({isVerifyLogAbort: false, isVerifying: false});
			});
		fetchEnd();
	};
	//Adding users
	addUsers = async () => {
		if (!(await this.isNewPALLicenseAvailable())) return;

		const {dataProvider} = this.props;
		this.abortController = null;
		this.abortController = new AbortController();
		const signal = this.abortController.signal;
		const {userIds} = this.state;
		fetchStart();
		this.setState({isAdding: true});
		this.verifyUsers();
		dataProvider(CREATE, 'usersAdd', {
			data: {
				authType: this.state.authType,
				skipTeamUpdate: true,
				skipCreatePlaceholderLessons: true,
				users: this.state.form,
			},
			signal,
		})
			.then(response => {
				this.setState({
					addedUserData: response.data,
					idstoMail: userIds.concat(response.data.newUsers.map(({id}) => id)),
				});
			})
			.catch(error => {})
			.finally(() => {
				this.setState({isAdding: false, form: null});
				fetchEnd();
			});
	};
	//get Ids to to pass into Send Welcome mail(exiating users)
	getExistingUserIds = () => {
		const existing = this.state.existingUsersData;
		const temp = existing.map(({id}) => id);
		this.setState({userIds: temp});
	};
	//verify the users to send welcome mail
	verifyUsers = () => {
		const {dataProvider} = this.props;
		dataProvider(CREATE, 'sendWelcomeMail', {
			data: {
				users: this.state.userIds,
				authType: this.state.authType,
				welcomeEmailTime: '',
				sendEmails: false,
			},
		})
			.then(response => {
				this.setState({
					verifyUsers: response.data.map(({id}) => id),
					welcomeDialog: false,
				});
			})
			.catch(error => {});
	};
	//sends welcome Mail
	sendWelcome = () => {
		const {dataProvider} = this.props;
		const {idstoMail} = this.state;
		const timeString = this.state.timeValue.toString();
		const timeSliced = timeString.slice(15, timeString.length);
		const dateSliced = this.state.dateValue.toString().slice(0, 15);
		const value = new Date(dateSliced + timeSliced);
		const data =
			this.state.sendWelcomeType === 'schedule'
				? {
						users: idstoMail,
						authType: this.state.authType,
						welcomeEmailTime:
							this.state.sendWelcomeType === 'schedule'
								? Date.parse(value.toUTCString())
								: '',
						sendEmails: true,
				  }
				: {
						users: idstoMail,
						authType: this.state.authType,
						sendEmails: true,
				  };
		dataProvider(CREATE, 'sendWelcomeMail', {
			data: data,
		})
			.then(response => {
				this.setState({
					sentEmailData: response.data,
					welcomeDialog: false,
					timeValue: value,
					dateValue: value,
				});
			})
			.catch(error => {});
		this.closeWelcome();
	};
	emailFaciliator = () => {
		window.location.href = 'mailto:' + this.state.faciliatorEmail;
	};
	openDialog = () => {
		if (this.state.userProfileDialog === true) {
			this.setState({userProfileDialog: false});
		} else {
			this.setState({userProfileDialog: true});
		}
	};

	verifyGenerateUserAccess = () => {
		const {dataProvider} = this.props;
		dataProvider(GET_GENERATE_USER_ACCESS, 'getGenerateUserAccessRunway', {})
			.then(response => {
				this.setState({hasGenerateUserAccess: response.data.hasAccess});
			})
			.catch(error => {
				console.log(error);
			});
	};

	render() {
		const {classes, translate} = this.props;

		const {
			runwayOrgConfig = {},
			verifiedUsersData,
			addedUserData,
			errorVerifiedLog,
			welcomeDialog,
			sendWelcomeType,
			timeValue,
			dateValue,
			idstoMail,
		} = this.state;

		const {config} = runwayOrgConfig;

		const projectConfig = loadObject('project');

		if (!getUserInfo().isAdmin && !getUserInfo().isSuper) {
			return <Redirect to='/' />;
		}

		// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
		// 	return <Redirect to='/termsOfService' />;
		// }

		return (
			<div className={classes.root}>
				<ScreenTitle title='Tools & Reports' />
				{!config && <LoadingList />}
				{config && (
					<div>
						{config && config.enableBulkUserImports === 'true' ? (
							<EmbeddedShow
								height={'auto'}
								title={translate('Bulk User Imports')}
								actions={[
									{
										label: 'Email Facilitator',
										onClick: () => {
											window.location.href =
												'mailto:' + runwayOrgConfig.config.planContactEmail;
										},
										startIcon: <EmailIcon style={{ fontSize: '18px', marginBottom: '3px' }}/>
									},
								]}
							>
								<div>
									<div className={classes.fieldColumn}>
										<div className={classes.label}>
											{translate('User Import Template')}
										</div>
										<div className={classes.fieldRow}>
											<MUIButton
												variant='outlined'
												color='primary'
												onClick={this.getTemplateUrl}
												style={{width: '172px'}}
												startIcon={<FileDownload style={{ fontSize: "20px" }}/>}
											>
												{translate('Download Template')}
											</MUIButton>
											<div className={classes.info}>
												{translate(
													`Add your users to this CSV and then upload it`
												)}
											</div>
										</div>
									</div>
									<div
										style={{paddingTop: '5px'}}
										className={classes.fieldColumn}
									>
										<div className={classes.label}>
											{translate('User Import Guide')}
										</div>
										<div className={classes.fieldRow}>
											<MUIButton
												variant='outlined'
												color='primary'
												onClick={this.getGuideUrl}
												style={{width: '172px'}}
												startIcon={<FileDownload style={{ fontSize: "20px" }}/>}
											>
												{translate('Download Guide')}
											</MUIButton>
											<div className={classes.info}>
												{translate(
													'Step-by-step instructions on how to configure users for import'
												)}
											</div>
										</div>
									</div>
									<div
										style={{paddingTop: '5px'}}
										className={classes.fieldColumn}
									>
										<div className={classes.label}>
											{translate('Import Users')}
										</div>
										<div className={classes.fieldMultipleColumn}>
											{!this.state.isVerifying ? (
												<>
													<div className={classes.row}>
														{verifiedUsersData && !addedUserData ? (
															<div className={classes.selectedFile}>
																<span
																	className={classes.selectedFileName}
																	title={this.state.filename}
																>
																	{this.state.filename}
																</span>
																<IconButton onClick={this.cancelVerifiedLog}>
																	<CloseIcon color='primary' />
																</IconButton>
															</div>
														) : (
															<MUIButton
																variant='contained'
																color='primary'
																onClick={this._onSelectFile}
																disabled={addedUserData ? true : false}
																style={{height: '32px', width: '172px'}}
																startIcon={<InsertDriveFileOutlined />}
															>
																{translate('Select File...')}
															</MUIButton>
														)}
														<input
															type='file'
															id='selectFile_id'
															className='input-file'
															accept='.csv'
															hidden={true}
															onChange={e =>
																this.handleFileChosen(e.target.files[0])
															}
														/>
														<div>
															{this.state.isVerifying === true ? (
																<div className={classes.info}>
																	{' '}
																	<CircularProgress
																		size={15}
																		className={classes.spinnerStyles}
																	/>
																	{translate('Importing file...')}
																</div>
															) : verifiedUsersData && !addedUserData ? (
																<div className={classes.responseText}>
																	{verifiedUsersData.newUsers.length}{' '}
																	{translate('New Users,')}{' '}
																	{verifiedUsersData.existingUsers.length}{' '}
																	{translate('Exisiting Users')}
																	<span> {translate('and')}</span>{' '}
																	{verifiedUsersData.newTeams.length}{' '}
																	{translate('New Teams')}{' '}
																	<span>{translate('found')}</span>
																</div>
															) : errorVerifiedLog ? (
																<div
																	style={{
																		color: 'red',
																		position: 'relative',
																		top: '5px',
																		paddingLeft: '10px',
																	}}
																>
																	{errorVerifiedLog.message}
																</div>
															) : (
																<div className={classes.info}>
																	{translate('Select a CSV file to import')}
																</div>
															)}
														</div>
													</div>
												</>
											) : (
												<div className={classes.row}>
													<MUIButton
														variant='outlined'
														color='primary'
														onClick={this.abortVerifyLog}
													>
														{translate('Cancel')}
													</MUIButton>
													<div className={classes.info}>
														{' '}
														<CircularProgress
															size={15}
															className={classes.spinnerStyles}
														/>
														{translate('Importing file...')}
													</div>
												</div>
											)}
											<hr className={classes.divider} />
											<div style={{display: 'flex'}}>
												{this.state.isAdding === true ? (
													<div>
														<MUIButton
															variant='outlined'
															color='primary'
															label={translate('Cancel')}
															onClick={this.abortVerifyLog}
														>
															{translate('Cancel')}
														</MUIButton>
													</div>
												) : !addedUserData ? (
													<div>
														<MUIButton
															variant='outlined'
															color='primary'
															style={{marginRight: '10px'}}
															disabled={verifiedUsersData ? false : true}
															onClick={this.handleClick}
															label='View Details'
														>
															{translate('View Details')}
														</MUIButton>
														{
															<MUIButton
																variant='outlined'
																color='primary'
																onClick={this.addUsers}
																disabled={verifiedUsersData ? false : true}
																startIcon={<AddOutlined style={{ marginBottom: '1px' }}/>}
															>
																{translate('Add Users')}
															</MUIButton>
														}
													</div>
												) : (
													<div style={{display: 'flex'}}>
														<MUIButton
															variant='outlined'
															color='primary'
															style={{marginRight: '10px'}}
															onClick={this.done}
														>
															{translate('Done')}
														</MUIButton>
														<MUIButton
															variant='outlined'
															color='primary'
															onClick={this.handleClick}
														>
															{translate('View Details')}
														</MUIButton>
													</div>
												)}
												<div>
													{this.state.isAdding === true ? (
														<div className={classes.info}>
															{' '}
															<CircularProgress
																size={15}
																className={classes.spinnerStyles}
															/>
															{translate(
																'Adding Users. This may take a while.'
															)}
														</div>
													) : addedUserData ? (
														<div className={classes.responseText}>
															<span>{translate('Success!')}</span>{' '}
															{addedUserData.newUsers.length}{' '}
															{translate('New Users')},{' '}
															{addedUserData.existingUsers.length}{' '}
															{translate('Exisiting')}
															Users <span>and</span>{' '}
															{addedUserData.newTeams.length}{' '}
															{translate('New Teams')}{' '}
															<span style={{marginRight: '30px'}}>
																{translate('added')}
															</span>
															{sendWelcomeType === 'schedule' &&
																this.state.sentEmailData && (
																	<span
																		onClick={this.welcomeDialog}
																		style={{
																			cursor: 'pointer',
																			textDecoration: 'underline',
																		}}
																		className={classes.importantText}
																	>
																		{translate(`Welcome Scheduled`)}
																	</span>
																)}
															{!this.state.sentEmailData && (
																<span
																	onClick={this.welcomeDialog}
																	style={{
																		cursor: 'pointer',
																		textDecoration: 'underline',
																	}}
																	className={classes.importantText}
																>
																	{translate(`Send Welcome Email`)}
																</span>
															)}
															{this.state.sentEmailData &&
																sendWelcomeType === 'sendNow' &&
																this.state.isTimerMessageShow && (
																	<span>{translate('Welcome Email Sent')}</span>
																)}
														</div>
													) : null}
												</div>
											</div>
										</div>
									</div>
								</div>
							</EmbeddedShow>
						) : null}
						{ config?.packageIntegrationEnabled === 'true' && getUserInfo().isSuper && <PackageIntegration />}
						{config && config.enableProgressReports === 'true' && (
							<ProgressReports />
						)}
						<div style={{marginTop: '24px'}}>
							<RawPerfomanceLog />
						</div>
						{projectConfig.config.authType === 'universal' &&
							this.state.hasGenerateUserAccess && (
								<div style={{marginTop: '24px'}}>
									<CreateMultipleUsersCard />
								</div>
							)}
					</div>
				)}
				{this.state.showDialog && (
					<Dialog
						maxWidth='md'
						closeLabel={false}
						open={this.state.showDialog}
						onClose={this.handleCloseClick}
					>
						<DialogTitle onClose={this.handleCloseClick}>
							<Text
								style={{fontSize: '20px'}}
								label={translate('User Import Details')}
							/>
						</DialogTitle>
						<DialogContent>
							<div>
								<EmbeddedTabs
									data={{
										tabs: [
											{
												name: 'New Users',
												content: (
													<div>
														<EmbeddedList
															embedded={true}
															title={null}
															resource={this.state.newUsers}
															columns={[
																{
																	id: 'userId',
																	name: translate('User Id'),
																	width: '200px',
																},
																{
																	id: 'name',
																	name: translate('Name'),
																	width: '200px',
																	// align: 'right',
																},
																{
																	id: 'role',
																	name: translate('Role'),
																	width: '200px',
																	// align: 'right',
																},
																{
																	id: 'jobRole',
																	name: translate('Job Role'),
																	width: '200px',
																},
															]}
															dynamicFilters={null}
															bulkActions={null}
															actions={null}
															selectedIds={null}
															selectionToggled={null}
															stripped={false}
															rowClicked={null}
															maxHeight={'auto'}
														/>
														<div style={{flex: '0 0 auto'}}>
															{this.state.newUsers.length > 0 && (
																<div
																	label='Available Users'
																	style={{
																		flex: '1 1 auto',
																		display: 'flex',
																		flexDirection: 'row',
																		alignItems: 'center',
																		padding: '10px',
																	}}
																>
																	<label className={classes.importantText}>
																		{this.state.newUsers.length}{' '}
																		{this.state.newUsers.length > 1
																			? translate('New Users')
																			: translate('New Users')}
																	</label>
																	<label className={classes.normalText}>
																		&nbsp;
																		{translate('will be imported')}
																	</label>
																</div>
															)}
														</div>
													</div>
												),
											},
											{
												name: 'New Teams',
												content: (
													<div>
														<EmbeddedList
															embedded={true}
															subTitle
															title={false}
															resource={this.state.newTeams}
															columns={[
																{
																	id: 'name',
																	name: translate('Name'),
																	width: '200px',
																},
																{
																	id: 'manager',
																	name: translate('Manager'),
																	width: '200px',
																},
																{
																	id: 'training',
																	name: translate('Module'),
																	width: '200px',
																	render: record => {
																		return (
																			<div
																				style={{
																					overflow: 'hidden',
																					textOverflow: 'ellipsis',
																					whiteSpace: 'nowrap',
																				}}
																				title={record.training?.join(', ')}
																			>
																				{record.training?.join(', ')}
																			</div>
																		);
																	},
																},
																{
																	id: 'users',
																	name: translate('Users'),
																	width: '200px',
																},
															]}
															staticFilters={null}
															dynamicFilters={null}
															bulkActions={null}
															actions={null}
															selectedIds={null}
															selectionToggled={null}
															stripped={false}
															maxHeight={'auto'}
														/>
														<div style={{flex: '0 0 auto'}}>
															{this.state.newTeams.length > 0 && (
																<div
																	label='Available Users'
																	style={{
																		flex: '1 1 auto',
																		display: 'flex',
																		flexDirection: 'row',
																		alignItems: 'center',
																		padding: '10px',
																	}}
																>
																	<label className={classes.importantText}>
																		{this.state.newTeams.length}{' '}
																		{this.state.newTeams.length > 1
																			? translate('New Teams')
																			: translate('New Teams')}
																	</label>
																	<label className={classes.normalText}>
																		&nbsp;
																		{translate('will be imported')}
																	</label>
																</div>
															)}
														</div>
													</div>
												),
											},
											{
												name: 'Existing Users',
												content: (
													<div>
														<EmbeddedList
															embedded={true}
															subTitle
															title={false}
															resource={this.state.existingUsersData}
															maxHeight={'auto'}
															columns={[
																{
																	id: 'userId',
																	name: translate('User Id'),
																	width: '200px',
																},
																{
																	id: 'name',
																	name: translate('Name'),
																	width: '200px',
																},
																{
																	id: 'role',
																	name: translate('Role'),
																	width: '200px',
																},
																{
																	id: 'jobRole',
																	name: translate('Job Role'),
																	width: '200px',
																},
															]}
															staticFilters={null}
															bulkActions={null}
															actions={null}
															selectedIds={null}
															selectionToggled={null}
															stripped={false}
														/>
														<div style={{flex: '0 0 auto'}}>
															{this.state.existingUsersData.length > 0 && (
																<div
																	label='Available Users'
																	style={{
																		flex: '1 1 auto',
																		display: 'flex',
																		flexDirection: 'row',
																		alignItems: 'center',
																		padding: '10px',
																	}}
																>
																	<label className={classes.importantText}>
																		{this.state.existingUsersData.length}{' '}
																		{this.state.existingUsersData.length > 1
																			? translate('Existing Users')
																			: translate('Existing user')}
																	</label>
																	<label className={classes.normalText}>
																		&nbsp;
																		{translate('will be imported')}
																	</label>
																</div>
															)}
														</div>
													</div>
												),
											},
										],
									}}
								/>
							</div>
						</DialogContent>
						<DialogActions>
							<MUIButton
								variant={'contained'}
								color={'primary'}
								size='small'
								onClick={this.handleCloseClick}
							>
								<label>{'Close'}</label>
							</MUIButton>
						</DialogActions>
					</Dialog>
				)}
				<Dialog maxwidth='md' open={welcomeDialog} onClose={this.closeWelcome}>
					<DialogTitle onClose={this.closeWelcome}>
						<Text
							style={{fontSize: '20px'}}
							label={translate('Send welcome Email to Imported Users')}
						/>
					</DialogTitle>
					<DialogContent>
						<div>
							{translate('A welcome message will be sent to ')}
							<span className={classes.importantText}>{idstoMail.length}</span>
							{translate(' Users.')}
						</div>
						<div className={classes.welcomeEmailInfo}>
							{translate(`Welcome emails will not be sent to members that have
											already completed enrollment nor to those that have
											already logged in.`)}
						</div>
						<div style={{paddingBottom: '12px'}}>
							<DatePicker
								sendWelcomeType={sendWelcomeType}
								onDateChange={date => this.handleDateChange(date)}
								onTimeChange={date => this.handleTimeChange(date)}
								handleWelcomeTypeChange={type => this.handleChange(type)}
								dateValue={dateValue}
								timeValue={timeValue}
								onDateTimeValidityChange={(val) => {
									this.setState({ isDateTimeValid: !val });
								}}
							/>
						</div>
					</DialogContent>
					<DialogActions>
						<MUIButton
							variant={'contained'}
							color={'secondary'}
							onClick={this.closeWelcome}
						>
							{translate('Cancel')}
						</MUIButton>
						<MUIButton
							variant={'contained'}
							color={'primary'}
							onClick={this.sendWelcome}
							disabled={!this.state.isDateTimeValid || !isValidDate(this.state.dateValue) || !isValidDate(this.state.timeValue)}
						>
							{translate('Confirm')}
						</MUIButton>
					</DialogActions>
				</Dialog>

				{this.state.showNewPALLicenseUnAvailableDialog && (
					<PALUnavailableDialog
						onClose={() =>
							this.setState({
								showNewPALLicenseUnAvailableDialog: false,
								newPALUnavailableErrorMessage: null,
							})
						}
						errorMessage={this.state.newPALUnavailableErrorMessage}
						title={'Cannot Create Users'}
					/>
				)}
			</div>
		);
	}
}

ToolsReports.propTypes = {
	dataProvider: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	return {};
};

const enhance = compose(
	connect(mapStateToProps, {}),
	withDataProvider,
	translate,
	withRouter,
	withStyles(myStyles)
);

export default enhance(ToolsReports);
