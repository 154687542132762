import Paper from '@material-ui/core/Paper';
import React from 'react';
import {useTheme} from '@material-ui/core/styles';

import {CircularProgressBar, LinearProgressBar, Theme} from 'components';

const SkillPie = props => {
	const {data, showTooltip, customTooltipTop,customTooltipBottom} = props;

	const theme = useTheme();

	return (
		<div style={{maxWidth: '50%', maxHeight: '220px'}}>
			{data && (
				<Paper
					variant='elevation'
					style={{
						flex: '1 1 auto',
						display: 'flex',
						flexDirection: 'column',
						marginTop: '1em',
						marginBottom: '1em',
						overflow: 'hidden',
					}}
				>
					<div
						style={{
							flex: '0 0 auto',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							backgroundColor: Theme.palette.background.contained,
							minHeight: '48px',
							paddingLeft: '1em',
							paddingRight: '1em',
							fontFamily: 'Archivo Bold',
							fontSize: '20px',
						}}
					>
						<div>{props.label}</div>
						{showTooltip &&customTooltipTop }
					</div>

					<div
						style={{
							flex: '1 1 auto',
							display: 'flex',
							flexDirection: 'column',
							padding: '1em',
							backgroundColor: Theme.palette.background.contained,
						}}
					>
						<div
							style={{
								flex: '1 1 auto',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'flex-start',
							}}
						>
							<div style={{marginRight: '50px',display:'flex'}}>
								<CircularProgressBar
									title={data.label}
									value={Math.ceil(data.avg * 100)}
									delta={Math.ceil(data.avgDelta * 100)}
								/>
								{showTooltip && customTooltipBottom}
							</div>
							<div
								style={{
									flex: '1 1 auto',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								{data.items &&
									Array.isArray(data.items) &&
									data.items.map(function(item, i) {
										return (
											<LinearProgressBar
											  key={item.title}
												title={
													<div
														style={{
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															whiteSpace: 'nowrap',
															maxWidth: '260px',
														}}
													>
														{item.title}
													</div>
												}
												value={Math.ceil(item.scoreRatio * 100)}
												backgroundColor={theme.palette.background.default}
											/>
										);
									})}
							</div>
						</div>
					</div>
				</Paper>
			)}
		</div>
	);
};

export default SkillPie;
