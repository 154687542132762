import {EmbeddedList, ScreenTitle} from 'components';
import {fetchEnd, fetchStart, useDataProvider, useTranslate, useVersion} from 'react-admin';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import mailIcon from 'assets/MailIcon.svg';
import Plan from './plan';
import StatsTilePanel from 'components/StatsTilePanel';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import {getUserInfo} from 'utils/Data';
import style from './style';
import {withStyles} from '@material-ui/core/styles';
import {safeRequestData} from '../../utils/Data';
import Grid from '../../components/layout/Grid';
import PanelStat from '../../components/Panels/PanelStat';
import LoadingStat from '../../components/loading/LoadingStat';
import get from 'lodash/get';
import {loadObject} from 'utils/Util';
import {Redirect} from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Dashboard = props => {
	const projectConfig = loadObject('project');
	const dataProvider = useDataProvider();
	const dispatch = useDispatch();

	const isMounted = useRef(true);
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const [state, setState] = useState({
		runwayOrgConfig: loadObject('runwayOrgConfig'),
		OrgStats: loadObject('OrgStats'),
	});

	const isLicense = get(state, 'runwayOrgConfig.config.isLicense') === 'true' ? true : false;

	const fetchData = useCallback(async () => {

		const requestList = [{resource: 'OrgStats'}];
		setState({ ...state, OrgStats: null })

		for (let i = 0; i < requestList.length; i++) {
			const request = requestList[i];
			dispatch(fetchStart());
			try {
				await safeRequestData({
					dataRequest: request,
					dataProvider: dataProvider,
					isMounted: isMounted,
					setState: setState,
				});
			} finally{
				dispatch(fetchEnd());
			}
		}
	}, [dataProvider]);

	const version = useVersion();
	useEffect(() => {
		fetchData();
	}, [version]);

	const translate = useTranslate();

	const {classes} = props;

	if (!getUserInfo().isAuthenticated) {
		return <Redirect to='login' />;
	}

	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to="/termsOfService" />
	// }

	if (getUserInfo().isAuthenticated  && !getUserInfo().isAdmin && !getUserInfo().isSuper ) {
		return <Redirect to='users' />;
	}

	return (
		<div style={{width: '100%'}}>
			<div style={{width: '864px', margin: 'auto'}}>
				<Grid perRow={1}>
					<OrgHeaderTitle
						props={props}
						projectConfig={projectConfig}
						classes={classes}
					/>
					{state.OrgStats !== null ? (
						<StatsTilePanel stats={state.OrgStats} />
					) : (
						<PanelStat>
							<LoadingStat />
						</PanelStat>
					)}

					{isLicense && <Plan />}

					<EmbeddedList
						embedded={false}
						subTitle
						title={translate('Org Admins')}
						resource='users'
						columns={[
							{id: 'userId', name: translate('User ID'), sortable: true},
							{id: 'name', name: translate('Name'), sortable: true},
							{
								id: 'role',
								name: translate('Role'),
								sortable: true,
								render: record => {
									switch (record.role) {
										case 'super':
											return translate('Super Admin');
										case 'admin':
											return translate('Admin');
										case 'TS-super':
											return translate('TS-Super Admin');
										case 'TS-admin':
											return translate('TS-Admin');
										default:
											return record.role;
									}}
								},
							{
								id: 'contactEmail',
								name: translate('Contact'),
								sortable: false,
								align: 'center',
								width: '125px',
								render: record => {
									if (
										record &&
										record.contactEmail &&
										record.contactEmail.trim().length > 0
									) {
										return (
											<a
												href={'mailto:' + record.contactEmail}
												className={classes.emailLink}
											>
												<img style={{width: '24px',}} src={mailIcon} alt='mail icon' />
											</a>
										);
									} else {
										return <div></div>;
									}
								},
							},
						]}
						sort={{field: 'userId', order: 'ASC'}}
						staticFilters={[{id: 'role', type: 'exact', value: { $in: ['TS-admin', 'TS-super', 'super', 'admin']}}]}
						dynamicFilters={[
							{
								id: 'name',
								name: translate('Search Org Admins'),
								type: 'safe_regex',
							},
						]}
						bulkActions={null}
						actions={null}
						rowClicked={null}
						selectedIds={null}
						selectionToggled={null}
						stripped={false}
					/>
				</Grid>
			</div>
		</div>
	);
};

const OrgHeaderTitle = ({projectConfig, classes, props}) => {
	const runwayOrgConfig = loadObject('runwayOrgConfig');
	const accountStatus = get(projectConfig, 'config.accountStatus');
	const displayTitle = get(runwayOrgConfig, 'config.displayTitle');

	const mainLogo = get(projectConfig, 'config.images.runwayOrganizationLogo');

	return (
		<div style={{flex: '1 1 auto', display: 'flex', flexDirection: 'column'}}>
			<ScreenTitle basePath={props.basePath} resource={props.resource} />

			{mainLogo && <img className={classes.logo} src={mainLogo} alt='logo' />}

			<CardHeader className={classes.cardHeader} title={displayTitle} />

			<Typography className={classes.accountStatus}>
				<span>{accountStatus}</span>
			</Typography>
			<br />
		</div>
	);
};

const enhance = compose(withStyles(style));

export default enhance(Dashboard);
