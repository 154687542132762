import React, {useEffect, useState} from 'react';
import {useTranslate} from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import MUIButton from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import {EmbeddedTabs, DialogTitle, Text} from 'components';
import SamePasswordTab from './Tabs/SamePasswordTab';
import UniquePasswordsTab from './Tabs/UniquePasswordsTab';
import {
	createTheme,
	createStyles,
	withStyles,
} from '@material-ui/core/styles';

const myStyles = theme =>
	createStyles({
		tableContainer: {
			'&::-webkit-scrollbar': {
				width: '0.4em',
				backgroundColor: '#ecf4f6',
			},
			'&::-webkit-scrollbar-track': {
				boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				width: '0.4em',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#346dec',
				width: '0.4em',
				outline: 'none',
				borderRadius: '20px',
			},
		},
	});

function ConfigurePasswordsDialog({
	title,
	titleInfo,
	data,
	isOpen,
	onClose,
	classes,
	...props
}) {
	const translate = useTranslate();
	const [updatedData, setUpdatedData] = useState({});

	useEffect(() => {
		setUpdatedData(data);
	}, [data]);

	const handleDone = () => {
		if (onClose) onClose(updatedData);
	};

	const onValuesChange = data => {
		setUpdatedData(data);
	};

	const onSamePasswordChange = value => {
		const dataCopy = {...updatedData};
		dataCopy.useSameValue = value;
		setUpdatedData(dataCopy);
	};
	return (
		<>
			{isOpen ? (
				<Dialog
					open={isOpen}
					aria-label={'Password Generator'}
					style={{maxHeight: 'flex', zIndex: '11'}}
				>
					<DialogTitle onClose={handleDone}>
						<Text
							style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
							label={translate('Password Generator')}
						/>
					</DialogTitle>
					<DialogContent style={{width: '500px'}}>
						<div>
							<span style={{fontSize: '12px', fontFamily: 'Montserrat Italic'}}>
								{titleInfo}
							</span>
						</div>
						<EmbeddedTabs
							data={{
								tabs: [
									{
										name: 'Unique',
										content: (
											<UniquePasswordsTab
												data={data}
												onValuesChange={onValuesChange}
											/>
										),
									},
									{
										name: 'Same',
										content: (
											<SamePasswordTab
												data={data}
												onSamePasswordChange={onSamePasswordChange}
											/>
										),
									},
								],
							}}
						/>
					</DialogContent>
					<DialogActions>
						<div>
							<MUIButton
								color='primary'
								variant='contained'
								onClick={() => handleDone()}
							>
								{translate('Done')}
							</MUIButton>
						</div>
					</DialogActions>
				</Dialog>
			) : null}
		</>
	);
}

export const CustomTheme = createTheme({
	overrides: {
		EmbeddedTabs: {
			tab: {
				width: '220px !important',
			},
		},
	},
});

export default withStyles(myStyles)(ConfigurePasswordsDialog);
