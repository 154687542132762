import React from 'react';

const DisabledButton = ({
	variant,
	small = false,
	med = false,
	width,
	children,
}) => {
	const buttonStyle = {
		textAlign: 'inherit',
		background: 'none',
		padding: 0,
		margin: 0,
		border: '1px solid #0E4258',
		color: '#0E4258',
		cursor: 'default',
		fontFamily: 'Helvetica Now',
		backgroundColor: '#00132F',
		borderRadius: '4px',
		minWidth: small ? '96px' : med ? '148px' : width ? width : '240px',
		height: '30px',
		display: 'flex',
		alignItems: 'center',
		fontSize: '13px',
	};

	return (
		<button style={buttonStyle}>
			<div
				style={{
					outline: 'none',
					padding: 0,
					margin: 0,
					flex: 1,
					textAlign: 'center',
				}}
				tabIndex='-1'
			>
				{children}
			</div>
		</button>
	);
};

export default DisabledButton;
