import {
	BooleanValue,
	CustomDialog,
	EmbeddedShow,
	ScreenTitle,
	StatusChip,
	TextInput,
	TextValue,
	TimeDateField,
} from 'components';
import {
	CREATE,
	minLength,
	required,
	useDataProvider,
	useTranslate,
} from 'react-admin';
import React, {useState} from 'react';
import {createStyles, useTheme, withStyles} from '@material-ui/core/styles';

import EditUserDialog from './EditUserDialog';
import Grid from '../../../components/layout/Grid';
import Label from '../../../components/Label';
import LoadingInfo from '../../../components/loading/LoadingInfo';
import MUIButton from '@material-ui/core/Button';
import SendWelcomeEmail from '../Dialogs/SendWelcomeEmail';
import TextArea from '../../../components/TextArea';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import {getUserInfo} from 'utils/Data';
import {loadObject} from 'utils/Util';
import EditIcon from 'assets/EditIcon';
import EmailIcon from 'assets/EmailIcon';

const myStyles = theme =>
	createStyles({
		textValue: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			width: '200px',
		},
	});

const UserShow = ({state, setState, id, classes, isUserDataLoading}) => {
	const theme = useTheme();

	const [isEditing, setIsEditing] = useState(false);

	const [isSendWelcome, setIsSendWelcome] = useState(false);

	const [isResetPassword, setIsResetPassword] = useState(false);

	const [isSetPassword, setIsSetPassword] = useState(false);

	const [isError, setIsError] = useState(false);

	const dataProvider = useDataProvider();

	const translate = useTranslate();

	const orgConfig = loadObject('runwayOrgConfig')?.config;

	async function resetPassword() {
		let contactEmail = get(userData, 'contactEmail');
		const authType = state.authType;
		const pwd = state.pwd;
		const data =
			(authType === 'universal' || authType === 'username') &&
			(!contactEmail || contactEmail.trim() === 0)
				? {
						id: id,
						authType: authType,
						pwd: pwd,
				  }
				: {
						id: id,
						authType: state.authType,
				  };

		await dataProvider(CREATE, 'resetUserPasswordRunway', {
			data: data,
		})
			.then(response => {
				if (response.data) {
					setIsResetPassword(false);
					setIsSetPassword(false);
				}
			})
			.catch(error => {
				setIsError(true);
				console.log(error);
			})
			.finally(() => {});
	}

	const userData = state.userData;
	const validatePassword = [required(), minLength(1)];
	const pwd = state.pwd;

	return (
		<div>
			<SendWelcomeEmail
				isOpen={isSendWelcome}
				setIsOpen={setIsSendWelcome}
				record={userData}
			/>
			<CustomDialog
				width='390px'
				isOpen={isResetPassword}
				setIsOpen={setIsResetPassword}
				title={'Reset User Password'}
				actions={[
					{
						render: () => {
							return (
								<MUIButton
									color='primary'
									variant='contained'
									onClick={() => resetPassword()}
								>
									Send Password Reset Email
								</MUIButton>
							);
						},
					},
				]}
			>
				<div
					style={{
						display: 'grid',
						paddingTop: '10px',
						paddingBottom: '10px',
					}}
				>
					<span>
						This user will receive an email with instructions describing the
						process to reset their password.
					</span>
					{isError && (
						<span style={{color: '#ff0000', paddingTop: '15px'}}>
							{state.authType === 'email'
								? 'Email Identity Missing'
								: 'Universal identity Missing'}
						</span>
					)}
				</div>
			</CustomDialog>
			<CustomDialog
				width='330px'
				isOpen={isSetPassword}
				setIsOpen={setIsSetPassword}
				title={'Set User Password?'}
				actions={[
					{
						render: () => {
							return (
								<MUIButton
									color='primary'
									variant='contained'
									onClick={() => resetPassword()}
									disabled={pwd === '' || pwd.trim().length < 0}
								>
									Reset User Password
								</MUIButton>
							);
						},
					},
				]}
			>
				<Grid perRow={1}>
					<div
						style={{
							display: 'grid',
							paddingTop: '10px',
							paddingBottom: '10px',
						}}
					>
						<Label paddingBottom='18px' label={'New Password'} />
						<TextInput
							value={pwd}
							validate={validatePassword}
							onChange={e => setState({...state, pwd: e.target.value})}
						/>
						{isError && (
							<span style={{color: '#ff0000', paddingTop: '15px'}}>
								{state.authType === 'email'
									? 'Email Identity Missing'
									: 'Universal identity Missing'}
							</span>
						)}
					</div>
				</Grid>
			</CustomDialog>
			<ScreenTitle
				title='User Details'
				listButton
				list={'/users'}
				actions={[
					{
						render: () => {
							let contactEmail = get(userData, 'contactEmail');
							let lastLogin = get(userData, 'lastLogin');

							if (lastLogin === null) {
								return (
									<MUIButton
										width={'148px'}
										variant={'outlined'}
										color={'primary'}
										style={{backgroundColor: '#ffffff'}}
										onClick={() => {
											setIsSendWelcome({...setIsSendWelcome, isOpen: true});
										}}
										disabled={!contactEmail || !contactEmail?.trim().length}
										startIcon={
											<EmailIcon
												style={{fontSize: '18px', marginBottom: '2px'}}
											/>
										}
									>
										Send Welcome Email
									</MUIButton>
								);
							}
						},
					},
					{
						render: () => {
							if (!userData || userData?.patCreatedUser) return;

							let contactEmail = get(userData, 'contactEmail');
							if (
								(state.authType === 'email' ||
									state.authType === 'universal') &&
								contactEmail &&
								contactEmail.trim() !== 0
							) {
								return (
									<MUIButton
										width={'148px'}
										variant={'outlined'}
										color={'primary'}
										style={{backgroundColor: '#ffffff'}}
										onClick={() => {
											setIsResetPassword(true);
											setIsError(false);
										}}
										disabled={!contactEmail || !contactEmail?.trim().length}
									>
										Reset User Password
									</MUIButton>
								);
							} else if (
								(state.authType === 'universal' &&
									(!contactEmail || contactEmail.trim() === 0)) ||
								state.authType === 'username'
							) {
								return (
									<MUIButton
										width={'148px'}
										variant={'outlined'}
										color={'primary'}
										style={{backgroundColor: '#ffffff'}}
										onClick={() => {
											setIsSetPassword({setIsSetPassword: true});
										}}
									>
										Set Password
									</MUIButton>
								);
							}
						},
					},
				]}
			/>
			<EmbeddedShow
				title={'Information'}
				perRow={5}
				spaceBetween={true}
				actions={
					userData
						? [
								{
									label: 'Edit Info',
									onClick: () => {
										setIsEditing(true);
									},
									startIcon: (
										<EditIcon style={{fontSize: '16px', marginBottom: '3px'}} />
									),
								},
						  ]
						: []
				}
			>
				{userData === null || isUserDataLoading ? (
					<LoadingInfo />
				) : (
					<div>
						<Grid perRow={1} gridGap='2px 2px' padding={'0 0 10px 0'}>
							<Grid
								perRow={6}
								padding={'10px 10px'}
								backgroundColor={theme.palette.background.default}
							>
								<div style={{width: '195px'}}>
									<Label label={'User'} />
								</div>
								<div style={{width: '150px'}}>
									<Label label={'Name'} />
								</div>
								<div style={{width: '100px'}}>
									<Label label={'Role'} />
								</div>
								<div style={{width: '70px'}}>
									<Label label={'Created'} />
								</div>
								<div style={{width: '130px'}}>
									<Label label={'Status'} />
								</div>
								<div style={{width: '195px'}}>
									<Label label={'Tester'} />
								</div>
							</Grid>
							<Grid perRow={6} padding={'10px 10px'}>
								<div style={{width: '195px'}}>
									<Tooltip title={userData.userId} placement='top'>
										<Typography component={'div'} className={classes.textValue}>
											{userData.userId}
										</Typography>
									</Tooltip>
								</div>

								<div style={{width: '150px'}}>
									{userData.name &&
									userData.name != null &&
									userData.name.trim().length > 0 ? (
										<Tooltip
											title={userData.name}
											placement='top'
											className={classes.textValue}
										>
											<Typography component={'div'}>{userData.name}</Typography>
										</Tooltip>
									) : (
										'---'
									)}
								</div>
								<div style={{width: '100px'}}>
									<Typography component={'div'}>
										{userData.role === 'super'
											? translate('Super Admin')
											: userData.role === 'TS-super'
											? translate('TS-Super Admin')
											: userData.role === 'admin'
											? translate('Admin')
											: userData.role === 'TS-admin'
											? translate('TS-Admin')
											: userData.role === 'manager'
											? translate('Manager')
											: userData.role === 'TS-manager'
											? translate('TS-Manager')
											: userData.role === 'user'
											? translate('User')
											: userData.role === 'TS-user'
											? translate('TS-User')
											: userData.role}
									</Typography>
								</div>
								<div style={{width: '70px'}}>
									<Typography component={'div'}>
										<TimeDateField record={userData} source='createdAt' />
									</Typography>
								</div>
								<div style={{width: '130px'}}>
									<StatusChip record={userData} source='status' />
								</div>
								<div style={{width: '195px'}}>
									<BooleanValue record={userData} source='tester' />
								</div>
							</Grid>
						</Grid>
						<Grid perRow={1} gridGap='2px 2px' padding={'0 0 10px 0'}>
							<Grid
								perRow={6}
								padding={'10px 10px'}
								backgroundColor={theme.palette.background.default}
							>
								<div style={{width: '195px'}}>
									<Label label={'Contact Email'} />
								</div>
								<div style={{width: '150px'}}>
									<Label label={'Job Role'} />
								</div>

								<div style={{width: '100px'}}>
									<Label label={'Employee ID'} />
								</div>

								<div style={{width: '90px'}}>
									<Label label={'Last Login'} />
								</div>
								{/* <div style={{width: '130px'}}>
									<Label label={'Send Lab Reports'} />
								</div> */}
								<div style={{width: '180px'}}>
									<Label label={'CoPilot Designer Author'} />
								</div>
								{getUserInfo().isSuper &&
									orgConfig?.packageIntegrationEnabled === 'true' && (
										<div style={{width: '195px'}}>
											<Label label={'LMSID'} />
										</div>
									)}
							</Grid>
							<Grid perRow={6} padding={'10px 10px'}>
								<div style={{width: '195px'}}>
									{userData.contactEmail ? (
										<Tooltip title={userData.contactEmail} placement='top'>
											<Typography
												component={'div'}
												className={classes.textValue}
											>
												{userData.contactEmail}
											</Typography>
										</Tooltip>
									) : (
										'---'
									)}
								</div>
								<div style={{width: '150px'}}>
									{userData.jobRole ? (
										<TextValue
											record={userData}
											source='jobRole'
											maxWidth='200px'
										/>
									) : (
										'---'
									)}
								</div>
								<div style={{width: '100px'}}>
									{userData.employeeId ? (
										<TextValue record={userData} source='employeeId' />
									) : (
										'---'
									)}
								</div>
								<div style={{width: '70px'}}>
									{userData.lastLogin ? (
										<Typography component={'div'}>
											<TimeDateField record={userData} source='lastLogin' />
										</Typography>
									) : (
										'---'
									)}
								</div>
								{/* <div style={{width: '130px'}}>
									<StatusChip
										record={userData}
										source={'sendLabEmailReports'}
									/>
								</div> */}
								<div style={{width: '180px'}}>
									<BooleanValue
										record={userData}
										source='trainingModuleEditor'
									/>
								</div>
								{getUserInfo().isSuper &&
									orgConfig?.packageIntegrationEnabled === 'true' && (
										<div style={{width: '195px'}}>
											{userData.lmsId ? (
												<Tooltip title={userData.lmsId} placement='top'>
													<Typography
														component={'div'}
														className={classes.textValue}
													>
														{userData.lmsId}
													</Typography>
												</Tooltip>
											) : (
												'---'
											)}
										</div>
									)}
							</Grid>
						</Grid>
						<div
							style={{
								backgroundColor: theme.palette.background.default,
								padding: '10px 0 0 15px',
							}}
						>
							<Typography component={'div'} variant='subtitle2'>
								Notes
							</Typography>
							<TextArea record={userData} source='note' />
						</div>
					</div>
				)}
			</EmbeddedShow>
			<EditUserDialog
				isOpen={isEditing}
				setIsOpen={setIsEditing}
				userData={userData}
			/>
		</div>
	);
};

export default withStyles(myStyles)(UserShow);
