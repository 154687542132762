import * as React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {DiagramEngine} from '@projectstorm/react-diagrams-core';

import {LabelNodeModel} from './LabelNodeModel';
import LabelNodeWidget from './LabelNodeWidget';

export class LabelNodeFactory extends AbstractReactFactory<LabelNodeModel, DiagramEngine> {
    constructor() {
        super('label');
    }

    generateModel(event) {
        return new LabelNodeModel();
    }

    generateReactWidget(event): JSX.Element {
        return <LabelNodeWidget engine={this.engine} node={event.model} />;
    }
}
