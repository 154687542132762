import React from 'react';
import {Query} from 'react-admin';
import {MenuItem, LinearProgress} from '@material-ui/core';
import {createStyles, withStyles} from '@material-ui/core/styles';

const styles = theme =>
	createStyles({
		linkText: {
			color: theme.palette.primary.main,
			textDecoration: 'none',
		},
	});

class SendEmail extends React.Component {
	render() {

		const {title, id, classes} = this.props;

		return (
			<Query type='GET_ONE' resource='users' payload={{id: id}}>
				{({data}) => {
					return (
						<div>
							{!data && (
								<div style={{ padding: '6px 16px'}}>
									<LinearProgress />
								</div>
							)}
							{data &&
								data.contactEmail &&
								data.contactEmail.trim().length > 0 && (
									<MenuItem>
										<a
											className={classes.linkText}
											href={'mailto:' + data.contactEmail}
										>
											{title}
										</a>
									</MenuItem>
								)}
						</div>
					);
				}}
			</Query>
		);
	}
}
export default withStyles(styles)(SendEmail);
