import { DataOptions, FilterOption, GroupOption, SortOption, HeatmapOption, DetailOption, OtherOptions } from '../OptionsBlade';

/**
 * Adds a child FilterOption option to the supplied parent FilterOption.
 * 
 * @param parent 
 * @param option 
 */
export const addChildFilterOption = (parent: FilterOption | Array<FilterOption>, option: FilterOption) => {
    if (!parent) return option;

    if (Array.isArray(parent)) {
        parent.push(option);

        // If the parent is an array of options, just return the updated array.
        return parent;
    } else {
        if (!parent.children) parent.children = [];
        if (parent.children && option) parent.children.push(option);

        // If the parent is an option itself, then return the child option.
        return option;
    }
}

/**
 * Adds a child GroupOption option to the supplied parent GroupOption.
 * 
 * @param parent 
 * @param option 
 */
export const addChildGroupOption = (parent: GroupOption | Array<GroupOption>, option: GroupOption) => {
    if (!parent) return option;

    if (Array.isArray(parent)) {
        parent.push(option);

        // If the parent is an array of options, just return the updated array.
        return parent;
    } else {
        if (!parent.children) parent.children = [];
        if (parent.children && option) parent.children.push(option);

        // If the parent is an option itself, then return the child option.
        return option;
    }
}

/**
 * Adds a child HeatmapOption option to the supplied parent HeatmapOption.
 * 
 * @param parent 
 * @param option 
 */
export const addChildHeatmapOption = (parent: HeatmapOption | Array<HeatmapOption>, option: HeatmapOption) => {
    if (!parent) return option;

    if (Array.isArray(parent)) {
        parent.push(option);

        // If the parent is an array of options, just return the updated array.
        return parent;
    } else {
        if (!parent.children) parent.children = [];
        if (parent.children && option) parent.children.push(option);

        // If the parent is an option itself, then return the child option.
        return option;
    }
}

/**
 * Find and return the FilterOption based on the supplied dot-notation id.
 * 
 * @param options 
 * @param id 
 */
export const getFilterOption = (options: Array<FilterOption>, id: string): FilterOption | null => {
    if (!options) return null;

    const ids = id.split('.');
    const rootId = ids.length > 0 ? ids[0] : null;
    const remainingIds = ids.slice(1);

    let rootOption = null;

    if (rootId != null) {
        rootOption = options.find(option => option.id === rootId);

        if (rootOption && remainingIds.length > 0) {
            return getFilterOption(rootOption.children, remainingIds.join('.'));
        } else {
            return rootOption;
        }
    } else {
        return null;
    }
}

/**
 * Find and return the GroupOption based on the supplied dot-notation id.
 * 
 * @param options 
 * @param id 
 */
export const getGroupOption = (options: Array<GroupOption>, id: string): GroupOption | null => {
    if (!options) return null;

    const ids = id.split('.');
    const rootId = ids.length > 0 ? ids[0] : null;
    const remainingIds = ids.slice(1);

    let rootOption = null;

    if (rootId != null) {
        rootOption = options.find(option => option.id === rootId);

        if (rootOption && remainingIds.length > 0) {
            return getGroupOption(rootOption.children, remainingIds.join('.'));
        } else {
            return rootOption;
        }
    } else {
        return null;
    }
}

/**
 * Find and return the HeatmapOption based on the supplied dot-notation id.
 * 
 * @param options 
 * @param id 
 */
export const getHeatmapOption = (options: Array<HeatmapOption>, id: string): HeatmapOption | null => {
    if (!options) return null;

    const ids = id.split('.');
    const rootId = ids.length > 0 ? ids[0] : null;
    const remainingIds = ids.slice(1);

    let rootOption = null;

    if (rootId != null) {
        rootOption = options.find(option => option.id === rootId);

        if (rootOption && remainingIds.length > 0) {
            return getHeatmapOption(rootOption.children, remainingIds.join('.'));
        } else {
            return rootOption;
        }
    } else {
        return null;
    }
}

/**
 * Find and return the DetailOption based on the supplied dot-notation id.
 * 
 * @param options 
 * @param id 
 */
export const getDetailOption = (options: Array<DetailOption>, id: string): DetailOption | null => {
    if (!options) return null;

    const ids = id.split('.');
    const rootId = ids.length > 0 ? ids[0] : null;
    const remainingIds = ids.slice(1);

    let rootOption = null;

    if (rootId != null) {
        rootOption = options.find(option => option.id === rootId);

        if (rootOption && remainingIds.length > 0) {
            return getDetailOption(rootOption.children, remainingIds.join('.'));
        } else {
            return rootOption;
        }
    } else {
        return null;
    }
}

/**
 * Clone and return the supplied FilterOption.
 * 
 * @param option 
 */
export const cloneFilterOption = (option: FilterOption): FilterOption => {
    return {
        id: option.id,
        label: option.label,
        value: option.value,
        children: option.children ? cloneFilterOptions(option.children) : option.children,
    };
}

/**
 * Clone and return the supplied collection of FilterOption's.
 * 
 * @param options 
 */
export const cloneFilterOptions = (options: Array<FilterOption>): Array<FilterOption> => {
    return options.map(option => cloneFilterOption(option));
}

/**
 * Clone and return the supplied GroupOption.
 * 
 * @param option 
 */
export const cloneGroupOption = (option: GroupOption): GroupOption => {
    return {
        id: option.id,
        options: option.options.map(item => ({ value: item.value, label: item.label })),
        value: option.value,
        children: option.children ? cloneGroupOptions(option.children) : option.children,
    };
}

/**
 * Clone and return the supplied collection of GroupOption's.
 * 
 * @param options 
 */
export const cloneGroupOptions = (options: Array<GroupOption>): Array<GroupOption> => {
    return options.map(option => cloneGroupOption(option));
}

/**
 * Clone and return the supplied SortOption.
 * 
 * @param option 
 */
export const cloneSortOption = (option: SortOption): SortOption => {
    return {
        id: option.id,
        options: option.options.map(item => ({ value: item.value, label: item.label, hidden: item.hidden })),
        value: option.value
    };
}

/**
 * Clone and return the supplied HeatmapOption.
 * 
 * @param option 
 */
export const cloneHeatmapOption = (option: HeatmapOption): HeatmapOption => {
    return {
        id: option.id,
        options: option.options.map(item => ({ value: item.value, label: item.label })),
        value: option.value,
        children: option.children ? cloneHeatmapOptions(option.children) : option.children,
    };
}

/**
 * Clone and return the supplied collection of HeatmapOption's.
 * 
 * @param options 
 */
export const cloneHeatmapOptions = (options: Array<HeatmapOption>): Array<HeatmapOption> => {
    return options.map(option => cloneHeatmapOption(option));
}

/**
 * Clone and return the supplied DetailOption.
 * 
 * @param option 
 */
export const cloneDetailOption = (option: DetailOption): DetailOption => {
    return {
        id: option.id,
        label: option.label,
        value: option.value,
        children: option.children ? cloneDetailOptions(option.children) : option.children
    };
}

/**
 * Clone and return the supplied collection of DetailOption's.
 * 
 * @param options 
 */
export const cloneDetailOptions = (options: Array<DetailOption>): Array<DetailOption> => {
    return options.map(option => cloneDetailOption(option));
}

/**
 * Clone and return the supplied OtherOptions.
 * 
 * @param options 
 */
export const cloneOtherOptions = (options: OtherOptions): OtherOptions => {
    return Object.assign({} as OtherOptions, options);
}

/**
 * Clone and return the supplied DataOptions.
 * 
 * @param options 
 */
export const cloneDataOptions = (options: DataOptions): DataOptions => {
    const clone: DataOptions = {
        filter: null,
        group: null,
        sort: null,
        heatmap: null,
        detail: null,
        other: null
    };

    if (options.filter) clone.filter = cloneFilterOptions(options.filter);
    if (options.group) clone.group = cloneGroupOption(options.group);
    if (options.sort) clone.sort = cloneSortOption(options.sort);
    if (options.heatmap) clone.heatmap = cloneHeatmapOption(options.heatmap);
    if (options.detail) clone.detail = cloneDetailOptions(options.detail);
    if (options.other) clone.other = cloneOtherOptions(options.other);

    return clone;
}
