import React from 'react';
import TrainingCatalogue from '../TrainingCatalogue';
import ScreenTitle from '../ScreenTitle';
import TextValue from '../TextValue';
import StatusChip from '../StatusChip';
import EmbeddedShow from '../EmbeddedShow';

const UnassignedTrainingDetail = props => {
	const {
		selectedTrainingRecord,
		isExpandMore,
		toggleKey,
		titleBreadCrumbs,
		backActions
	} = props;

	return (
		<div>
			<ScreenTitle
				title={selectedTrainingRecord.name}
				refresh={false}
				subTitle={true}
				coloredCrumb={true}
				backActionCrumbs={backActions}
				titleCrumbs={titleBreadCrumbs}
			/>
			<TrainingCatalogue
				trainingData={selectedTrainingRecord}
				isExpandMore={isExpandMore}
				toggleKey={toggleKey}
				information={
					<EmbeddedShow
						embedded
						headerless
						title={'Information'}
						details={[
							{
								label: 'Name',
								render: record => (
									<TextValue
										record={selectedTrainingRecord}
										source='name'
										maxWidth={'300px'}
									/>
								),
							},
							{
								label: 'Status',
								render: record => (
									<StatusChip
										record={{ status: 'Unassigned' }}
										source='status'
									/>
								),
							},
							{
								label: 'Id',
								render: () => <TextValue record={selectedTrainingRecord} source='id' />,
							},
						]}
					/>
				}
			></TrainingCatalogue>
		</div>
	);
};

export default UnassignedTrainingDetail;
