import CatalogMap from './CatalogMap';
import TrainingMap from './TrainingMap';
import LessonMap from './LessonMap';
import SkillMap from './SkillMap';

const VectorMaps = {
    CatalogMap: CatalogMap,
    TrainingMap: TrainingMap,
    LessonMap: LessonMap,
    SkillMap: SkillMap
}

export default VectorMaps;
