import {useTranslate} from 'react-admin';
import MUIButton from '@material-ui/core/Button';

import get from 'lodash/get';
import Grid from '../layout/Grid';
import Label from '../Label';
import {TextValue} from '../index';
import Box from '../layout/Box';
import React from 'react';
import Section from '../layout/Section';
import { loadObject } from "utils/Util";

export const Credentials = props => {
	return (
		<div>
			<Section padding={'0px 0px 16px 0px'}>
				<Grid perRow={1} gridGap={'16px 16px'}>
					<Credential authType={'universal'} {...props} />
					<Credential authType={'email'} {...props} />
					<Credential authType={'summaryExternalId'} {...props} />
					<Credential authType={'external'} {...props} />
				</Grid>
			</Section>
		</div>
	);
};
const Credential = ({state, authType, openResetCredentialDialog}) => {
	const translate = useTranslate();

	const authentication = get(state, 'profileInfo.authentication');
	const externalIdentityName = get(state, 'project.config.externalIdentityName');

	const summaryFriendData = loadObject(loadObject('project').id + '-summaryFriendData');

	const patCreatedUser = summaryFriendData?.patCreatedUser ? true : false;
	// https://talespin.atlassian.net/browse/APPSERV-657
	//disabl the self set password functionality for the patcreated users
	
	if (!authentication) {
		return;
	}

	const hidden = authentication[authType] ? false : true;
	if (hidden) {
		return null;
	}

	switch (authType) {
		case 'universal':
			return (
				<Grid perRow={2}>
					<div>
						<Label label={'Username'} />
						<TextValue
							maxWidth={'280px'}
							record={state}
							source='profileInfo.authentication.universal'
						/>
					</div>
					{!patCreatedUser && <Box>
						<MUIButton
							color='primary'
							variant='contained'
							onClick={() => openResetCredentialDialog('universal', 'SET')}
						>
							{translate('Set Password')}
						</MUIButton>
					</Box>}
				</Grid>
			);
		case 'email':
			return (
				<Grid perRow={2}>
					<div>
						<Label label={'Email'} />
						<TextValue
							maxWidth={'280px'}
							record={state}
							source={'profileInfo.authentication.email'}
						/>
					</div>
					{!patCreatedUser && <Box>
						<MUIButton
							size={'small'}
							color='primary'
							variant='contained'
							onClick={() => openResetCredentialDialog('email', 'RESET')}
						>
							{translate('Reset')}
						</MUIButton>
					</Box>}
				</Grid>
			);
		case 'summaryExternalId':
			return (
				<Grid perRow={2}>
					<div>
						<Label
							label={
								externalIdentityName === 'ssoSignin'
									? translate('Single Sign-On')
									: translate('External')
							}
						/>
						<TextValue
							record={state}
							maxWidth={'280px'}
							source={'profileInfo.authentication.summaryExternalId'}
						/>
					</div>
					<Box />
				</Grid>
			);
		case 'external':
			return (
				<Grid perRow={2}>
					<div>
						<Label
							label={
								externalIdentityName === 'ssoSignin'
									? translate('Single Sign-On')
									: translate('External')
							}
						/>
						<TextValue
							record={state}
							maxWidth={'280px'}
							source={'profileInfo.authentication.external'}
						/>
					</div>
					<Box />
				</Grid>
			);
		default:
			return null;
	}
};
