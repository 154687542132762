import 'date-fns';
import {Form} from 'react-final-form';
import {GET_LIST, UPDATE, useDataProvider, useRefresh} from 'react-admin';
import React, {useEffect, useState} from 'react';
import {OnChange} from 'react-final-form-listeners';
import get from 'lodash/get';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {Checkbox, Button} from '@material-ui/core';

import Box from 'components/layout/Box';
import Grid from 'components/layout/Grid';
import CustomField from 'components/form/CustomField';
import LoadingInfo from 'components/loading/LoadingInfo';
import PALUnavailableDialog from 'components/PALUnavailableDialog';
import {CustomDialog, SelectInput, Label, TextInput} from 'components';
import {GET, GET_USER_ROLE_LIST} from 'services/customActions';
import {getUserInfo} from 'utils/Data';
import { loadObject, validateEmail } from "utils/Util";

const EditUserDialog = props => {
	const {isOpen, setIsOpen, userData} = props;

	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const isDormancyEnabled = runwayOrgConfig?.config?.newPALTermDormantEnabled === 'true';

	const refresh = useRefresh();

	const dataProvider = useDataProvider();

	const [isError, setIsError] = useState(false);

	const [isWarning, setIsWarning] = useState(false);

	const [offeredJobRoles, setOfferedJobRoles] = useState([]);

	const [userRoles, setUserRoles] = useState([]);

	const [jobRole, setJobRole] = useState('');

	const [status, setStatus] = useState(null);

	const [roleValue, setRoleValue] = useState(null);

	const [jobRoleSearchValue, setJobRoleSearchValue] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	// const [sendLabReports, setSendLabReports] = useState();

	const [trainingModuleEditor, setTrainingModuleEditor] = useState(false);

	const [isTester, setIsTester] = useState(false);

	const [palUnavailableErrorMessage, setPalUnavailableErrorMessage] = useState(null);

	const [isLmsIdCharacterLimitExceeded, setIsLmsIdCharacterLimitExceeded] = useState(true);

	const [uniqueLmsId, setUniqueLmsId] = useState(true);

	const [
		showNewPALLicenseUnAvailableDialog,
		setShowNewPALLicenseUnAvailableDialog,
	] = useState(false);

	const [isSaving, setIsSaving] = useState(false);

	const lmsIdCharacterLimit = Number(runwayOrgConfig?.config?.lmsIdCharacterMax) ?? 128;

	const closeDialog = () => {
		setIsOpen(false);
		setIsError(false);
		setIsWarning(false);
		setIsLmsIdCharacterLimitExceeded(true);
	}

	const isUniqueLmsId = async (lmsId) => {
		try {
			const res = await dataProvider(GET, 'verifyLMSIdForProfile', {profileId: userData.id, lmsId: lmsId});
			const isValid = res.data.data.valid;
			setIsSaving(isValid);
			setUniqueLmsId(isValid);
			return isValid;
		} catch(e) {
			console.log(e);
			setUniqueLmsId(false);
		}
	}

	useEffect(() => {
		if (!isOpen) return;
		if (offeredJobRoles.length === 0) {
		    setIsLoading(true);
			dataProvider(GET_LIST, 'getJobRoleListRunway', {})
				.then(response => {
					setOfferedJobRoles(response.data);
					setIsLoading(false);
				})
				.catch(error => {
					console.log(error);
					setIsLoading(false);
				});
		}
		if (userRoles.length === 0) {
		    setIsLoading(true);
			dataProvider(GET_USER_ROLE_LIST, 'getUserRoleListRunway', {
				userId: userData.id,
			})
				.then(response => {
					if (Array.isArray(response.data)) {
						const roles = response.data;
						let filteredRoles = [];
						if (userData?.id && userData.role.includes('TS')) {
							filteredRoles = roles.filter(role => role.name.includes('TS'));
						} else {
							filteredRoles = roles.filter(role => !role.name.includes('TS'));
						}
						setUserRoles(filteredRoles);
					}
					setIsLoading(false);
				})
				.catch(error => {
					setIsLoading(false);
					console.error(error);
				});
		}
	}, [isOpen]);

	useEffect(() => {
		setStatus(userData?.status);
		setJobRole(userData?.jobRole);
		setRoleValue(userData?.role);
		// setSendLabReports(
		// 	userData?.sendLabEmailReports === 'enabled' ? true : false
		// );
		setTrainingModuleEditor(userData?.trainingModuleEditor);
		setIsTester(userData?.tester);
		if (userData?.lmsId && userData?.lmsId.trim().length > 0 && userData?.lmsId.trim().length > lmsIdCharacterLimit) setIsLmsIdCharacterLimitExceeded(false);
		setUniqueLmsId(true);
	}, [userData, isOpen]);

	const handleEmailChange = value => {
		if (value && value.trim().length > 0) {
			setIsWarning(false);
			if (validateEmail(value)) {
				setIsError(false);
			} else {
				setIsError(true);
			}
		} else {
			setIsWarning(true);
			setIsError(false);
		}
	};

	const isNewPALLicenseAvailable = async () => {
		let newLicenseAvailable = true;
		if (userData.role.indexOf('TS') > -1) {
			return newLicenseAvailable;
		}

		const params = {
			numToCreate: 1,
			profileIds: [userData.id]
		}; 

		await dataProvider(GET, 'isNewPALLicenseAvailable', params).then(res=> {
			if (res?.data?.numAvailable > 0) {
				newLicenseAvailable = true;
			} else if(Array.isArray(res?.errors) && res.errors.length > 0) {
				newLicenseAvailable = false;
				setIsSaving(false);
				setShowNewPALLicenseUnAvailableDialog(true);
				setPalUnavailableErrorMessage(res.errors[0]?.message);
			}
		}).catch(error => {
			newLicenseAvailable = false;
		    setIsSaving(false);
			setShowNewPALLicenseUnAvailableDialog(true);
			console.error(error);
			setPalUnavailableErrorMessage(error.message);
		});

		return newLicenseAvailable;
	};

	const onClosePalUnavailableDialog = () => {
		setShowNewPALLicenseUnAvailableDialog(false);
		setPalUnavailableErrorMessage(null);
		setStatus('disabled');
	};

	// handleFormSubmit becomes an onSubmit reference
	let handleFormSubmit = () => {};
	const onSubmit = async values => {
		setIsSaving(true);
		if (
			userData.status === 'disabled' &&
			status === 'enabled' &&
			!(await isNewPALLicenseAvailable())
		)
			return;


		let data = {...userData};

		data['contactEmail'] = values['contactEmail'] ? values['contactEmail'] : '';
		data['status'] = status;
		data['firstName'] = values['firstName'];
		data['lastName'] = values['lastName'];

		//https://talespin.atlassian.net/browse/RP-1503

		data['tester'] = roleValue && roleValue.includes('TS-') ? true : isTester;
		data['note'] = values['note'];
		data['employeeId'] = values['employeeId'] ? values['employeeId'] : '';
		data['jobRole'] = jobRole || null;
		data['role'] = roleValue;
		data['forceJobRoleUpdate'] = true;
		// data['sendLabEmailReports'] = sendLabReports;
		data['trainingModuleEditor'] = [
			'user',
			'manager',
			'TS-user',
			'TS-manager',
		].includes(roleValue)
			? false
			: trainingModuleEditor;
		data['lmsId'] = values['lmsId'] && values['lmsId'].trim().length > 0 ? values['lmsId'] : ''

		if (userData.lmsId !== data['lmsId'] && data['lmsId'] != null && data['lmsId'] !== "") {
			if (!await isUniqueLmsId(data['lmsId'])) return;
		}

		setIsError(false);

		dataProvider(UPDATE, 'users', {id: userData.id, data: data})
			.then(response => {
				if (response.data.errors) {
					setIsError(true);
					setIsSaving(false);
				} else {
					const project = JSON.parse(sessionStorage.getItem('project')).id;
					let authCreds = JSON.parse(sessionStorage.getItem('authCreds'));
					if (getUserInfo().id === userData.id && project) {
						sessionStorage.setItem(
							project + '-user',
							JSON.stringify({
								name: response.data.data.name,
								email: response.data.data.contactEmail,
								role: response.data.data.role,
								id: response.data.data.id,
							})
						);
						authCreds.userData.trainingModuleEditor =
							response.data.data.trainingModuleEditor;
						sessionStorage.setItem('authCreds', JSON.stringify(authCreds));
					}
					refresh();
					setIsOpen(false);
					setIsSaving(false);
					setUniqueLmsId(true);
				}
			})
			.catch(error => {
				console.log(error);
				setIsSaving(false);
			});
	};

	const renderScreen = () => {
		return (
			<>
				{isOpen && !showNewPALLicenseUnAvailableDialog && (
					<CustomDialog
						width='470px'
						title={'Edit User Information'}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						onClose={closeDialog}
						actions={[
							{
								render: () => {
									return (
										<Button
											color='secondary'
											variant='contained'
											onClick={() => closeDialog()}
											disabled={isSaving || isLoading}
										>
											Cancel
										</Button>
									);
								},
							},
							{
								render: () => {
									return (
										<Button
											color='primary'
											variant='contained'
											onClick={() => handleFormSubmit()}
											disabled={isSaving || isLoading || !isLmsIdCharacterLimitExceeded || isError}
										>
											Confirm
										</Button>
									);
								},
							},
						]}
					>
						<Form
							initialValues={{
								contactEmail: get(userData, 'contactEmail'),
								firstName: get(userData, 'firstName'),
								lastName: get(userData, 'lastName'),
								note: get(userData, 'note'),
								employeeId: get(userData, 'employeeId'),
								lmsId: get(userData, 'lmsId')
							}}
							onSubmit={onSubmit}
						>
							{({handleSubmit, form, submitting, pristine, values}) => {
								handleFormSubmit = handleSubmit;
								if (!userData || isLoading || isSaving) {
									return (
										<Box height={'393px'}>
											<LoadingInfo text={isSaving ? 'Saving...' : 'Loading'} />
										</Box>
									);
								}
								return (
									<Grid perRow={1} gridGap={'16px 0px'}>
										<Grid perRow={2} gridGap={'16px'}>
											<div>
												<Label label={'Contact Email'} />
												<CustomField
													name='contactEmail'
													component='input'
													placeholder='email@mailservice.com'
													error={isError}
													warning={isWarning}
												/>
												<OnChange name='contactEmail'>
													{value => handleEmailChange(value)}
												</OnChange>
												{isError && (
													<span style={{color: '#DE3737', paddingTop: '15px'}}>
														{'Please enter valid email'}
													</span>
												)}
												{isWarning && (
													<span style={{color: '#ffc107', paddingTop: '15px'}}>
														{'Not Recommended'}
													</span>
												)}
											</div>
											<div>
												<Label label={'Status'} />
												<SelectInput
													value={status}
													choices={[
														{id: 'enabled', name: 'Enabled'},
														{id: 'disabled', name: 'Disabled'},
														...(isDormancyEnabled ? [{id: 'dormant', name: 'Dormant', disabled: userData?.status === 'enabled'}] : []),
													]}
													disabled={
														!getUserInfo().isAdmin && !getUserInfo().isSuper
													}
													onChange={e => setStatus(e.target.value)}
												/>
											</div>
											<div>
												<Label label={'First Name'} />
												<CustomField
													name='firstName'
													component='input'
													placeholder='Optional'
												/>
											</div>
											<div>
												<Label label={'Last Name'} />
												<CustomField
													name='lastName'
													component='input'
													placeholder='Optional'
												/>
											</div>
											<div>
												<Label label={'Employee ID'} />
												<CustomField
													name='employeeId'
													component='input'
													placeholder='Optional'
												/>
											</div>
											<div>
												<Label label={'Job Role'} marginBottom={'-16px'} />
												<Autocomplete
													id='auto-complete-input'
													options={offeredJobRoles}
													open={jobRoleSearchValue ? true : false}
													inputValue={jobRole}
													onInputChange={(e, value, changeReason) => {
														setJobRoleSearchValue(value);
														if (changeReason !== 'reset') {
															setJobRole(value);
														}
													}}
													noOptionsText={'Job Role not found'}
													onChange={(e, value) => {
														setJobRole(value);
														setJobRoleSearchValue(null);
													}}
													onClose={(e, closeReason) => {
														if (closeReason !== 'select-option') {
															setJobRole('');
															setJobRoleSearchValue(null);
														}
													}}
													renderInput={params => (
														<div ref={params.InputProps.ref}>
															<TextInput
																inputProps={params.inputProps}
																value={jobRole ? jobRole : ''}
																placeholder='Optional'
															/>
														</div>
													)}
												/>
											</div>

											<div>
												<Label label={'Role'} />
												<SelectInput
													label={false}
													value={roleValue ? roleValue : userData.role}
													allowEmpty={false}
													//disabled={
														//!getUserInfo().isAdmin && !getUserInfo().isSuper // ||
														//userData.role.includes('super') // removed due to https://talespin.atlassian.net/browse/APPSERV-483
													//}
													choices={userRoles}
													onChange={e => {
														setRoleValue(e.target.value);
													}}
												/>
											</div>
											{getUserInfo().isSuper && runwayOrgConfig?.config?.packageIntegrationEnabled === 'true' && <div>
												<Label label={'LMSID'} />
												<CustomField
													name='lmsId'
													component='input'
													placeholder='Optional'
												/>
												<OnChange name='lmsId'>
													{value => {
														if (value && value.trim().length > 0 && value.trim().length > lmsIdCharacterLimit) {
															setIsLmsIdCharacterLimitExceeded(false);
														} else {
															setIsLmsIdCharacterLimitExceeded(true);
														}
													}}
												</OnChange>
												{!isLmsIdCharacterLimitExceeded && (
													<span style={{color: '#ff0000', marginTop: '15px', fontSize: '12px'}}>
														{`Character Count Exceeded (${lmsIdCharacterLimit})`}
													</span>
												)}
												{!uniqueLmsId && <span style={{ color: '#ff0000', marginTop: '15px', fontSize: '12px' }}>
													{'LMSID already in use'}
												</span>}
											</div>}
											{/* <div
												style={{
													paddingTop: '15px',
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Checkbox
													checked={sendLabReports}
													onChange={e => setSendLabReports(e.target.checked)}
												/>
												<span style={{fontWeight: 'bold'}}>
													Send Lab Reports
												</span>
											</div> */}
											<div
												style={{
													paddingTop: '15px',
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Checkbox
													checked={
														roleValue && roleValue.includes('TS-')
															? true
															: isTester
													}
													value='enabled'
													onChange={e => setIsTester(e.target.checked)}
													disabled={roleValue && roleValue.includes('TS-')}
													style={{
														opacity:
															roleValue && roleValue.includes('TS-')
																? '0.3'
																: '1',
													}}
												/>
												<span style={{fontWeight: 'bold'}}>Flag as Tester</span>
											</div>
											<div
												style={{
													display: 'flex',
													flexDIrection: 'row',
													alignItems: 'center',
													paddingTop: '15px',
												}}
											>
												<Checkbox
													checked={
														[
															'user',
															'manager',
															'TS-user',
															'TS-manager',
														].includes(roleValue)
															? false
															: trainingModuleEditor
													}
													onChange={e =>
														setTrainingModuleEditor(e.target.checked)
													}
													disabled={
														[
															'user',
															'manager',
															'TS-user',
															'TS-manager',
														].includes(roleValue) || !getUserInfo().isSuper
													}
													style={{
														opacity:
															[
																'user',
																'manager',
																'TS-user',
																'TS-manager',
															].includes(roleValue) || !getUserInfo().isSuper
																? '0.3'
																: '1',
													}}
												/>
												<span style={{fontWeight: 'bold'}}>
													CoPilot Designer Author
												</span>
											</div>
										</Grid>
										<div style={{fontFamily: 'Montserrat Italic'}}>
											A tester's progress is tracked in Runway, but does not
											influence an organization's stats and averages.
										</div>
										<div>
											<Label label={'Notes'} />
											<CustomField
												name='note'
												component='textarea'
												height={'103px'}
												placeholder='Notes...'
											/>
										</div>
									</Grid>
								);
							}}
						</Form>
					</CustomDialog>
				)}
				{isOpen && showNewPALLicenseUnAvailableDialog && (
					<PALUnavailableDialog
						onClose={() => onClosePalUnavailableDialog()}
						title={'Cannot Enable User'}
						errorMessage={palUnavailableErrorMessage}
					/>
				)}
			</>
		);
	};

	return <div>{renderScreen()}</div>;
};

export default EditUserDialog;
