import * as React from 'react';
import compose from 'recompose/compose';
import {createStyles, Popover, Typography, withStyles} from '@material-ui/core';
import {DiagramEngine} from '@projectstorm/react-diagrams';

import {TrainingNodeModel} from './TrainingNodeModel';
import TrainingNodePopover from './TrainingNodePopover';

import LabelIcon from 'components/VectorMap/LabelIcon';
import StatLabel from 'components/VectorMap/StatLabel';

import { getDetailOption } from 'components/VectorMap/utils/options';
import { determineAvailability, determineProgressStatus, humanizeAvailability, humanizeProgressStatus } from 'components/VectorMap/utils/CatalogMap/helpers';

export interface TrainingNodeWidgetProps {
    node: TrainingNodeModel;
    engine: DiagramEngine;
    classes?: any
}

export interface TrainingNodeWidgetState {
    popoverOpen: boolean,
    dragXCount: number | null,
    dragYCount: number | null
}

const TRAINING_TYPE_ICONS = {
    'default':'default',
    'Co-Pilot': 'co-pilot',
    'Propel': 'propel'
}

const getTrainingTypeIconId = (type?: string | null) => {
    if (TRAINING_TYPE_ICONS[type] != null) {
        return TRAINING_TYPE_ICONS[type];
    } else {
        return TRAINING_TYPE_ICONS['default'];
    }
}

class TrainingNodeWidget extends React.Component<TrainingNodeWidgetProps, TrainingNodeWidgetState> {
    state = {
        popoverOpen: false,
        dragXCount: null,
        dragYCount: null
    };
    anchorEl: any = null;

    constructor(props: TrainingNodeWidgetProps) {
        super(props);

        this.anchorEl = React.createRef();
    }

    openPopover = () => {
        this.setState({ popoverOpen: true });
    }

    closePopover = () => {
        this.setState({ popoverOpen: false });
    }

    onMouseDown = (e: React.MouseEvent) => {
        this.setState({ dragXCount: 0, dragYCount: 0 });
    }

    onMouseMove = (e: React.MouseEvent) => {
        const { dragXCount, dragYCount } = this.state;

        this.setState({ dragXCount: dragXCount + Math.abs(e.movementX), dragYCount: dragYCount + Math.abs(e.movementY) });
    }
    
    onMouseUp = (e: React.MouseEvent) => {
        const{ dragXCount, dragYCount } = this.state;

        if (dragXCount === 0 && dragYCount === 0) {
            this.openPopover();
        }

        this.setState({ dragXCount: null, dragYCount: null });
    }

    render() {
        const { classes, node } = this.props;
        const { popoverOpen } = this.state;

        const width = node.getWidth() ? node.getWidth() : 100;
        const height = node.getHeight() ? node.getHeight() : 100;

        const context = node.getContext();
        const record = node.getRecord();
        const options = node.getDataOptions();
        const detail = options ? options.detail : null;

        // If there is no record, just render the basic node shape (such as when this widget is used in the legend).
        if (!record) return (
            <div
                className={classes.root}
                style={{
                    cursor: 'default',
                    width: width,
                    height: height,
                    backgroundColor: node.getBackgroundColor(),
                    color: node.getFontColor(),
                    background: 'linear-gradient(45deg, ' + node.getBackgroundColor() + ' 0%, ' + node.getBackgroundColor() + ' 90%, transparent 90%, transparent 100%)'
                }}
            ></div>
        );

        return (
            <div
                ref={this.anchorEl}
                className={classes.root}
                style={{
                    width: width,
                    height: height,
                    backgroundColor: node.getBackgroundColor(),
                    color: node.getFontColor(),
                    background: 'linear-gradient(45deg, ' + node.getBackgroundColor() + ' 0%, ' + node.getBackgroundColor() + ' 90%, transparent 90%, transparent 100%)',
                    transform: popoverOpen ? 'scale(1.1)' : null
                }}
                onMouseDown={this.onMouseDown}
                onMouseMove={this.onMouseMove}
                onMouseUp={this.onMouseUp}
            >
                <div className={classes.header}>
                    {record.displayShortName &&
                        <div className={classes.icon} style={{ borderColor: node.getFontColor() }}>
                            <LabelIcon label={record.displayShortName} color={node.getFontColor()} fontScale={0.5} />
                        </div>
                    }

                    <Typography className={classes.truncated}>
                        {record.name}
                    </Typography>
                </div>

                <div className={classes.body}>
                    <div className={classes.stats}>
                        {/* Full width stats */}
                        {getDetailOption(detail, 'training-info.category') && getDetailOption(detail, 'training-info.category').value === true &&
                            <StatLabel
                                className={classes.stat}
                                icon={<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 90 90"><use href={'/VectorMapIcons/CatalogMap/types.svg#' + getTrainingTypeIconId(record.trainingType ? record.trainingType.map(item => item.value).join(',') : '')} /></svg>}
                                type={'text'}
                                color={node.getFontColor()}
                                value={record.trainingType && record.trainingType.length > 0 ? record.trainingType.map(item => item.value).join(', ') : ''}
                            />
                        }
                        {getDetailOption(detail, 'training-info.availability') && getDetailOption(detail, 'training-info.availability').value === true &&
                            <StatLabel
                                className={classes.stat}
                                icon={<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#availability'} /></svg>}
                                type={'text'}
                                color={node.getFontColor()}
                                value={humanizeAvailability(determineAvailability(record))}
                            />
                        }
                        {getDetailOption(detail, 'user-info.status') && getDetailOption(detail, 'user-info.status').value === true &&
                            <StatLabel
                                className={classes.stat}
                                icon={<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#status'} /></svg>}
                                type={'text'}
                                color={node.getFontColor()}
                                value={humanizeProgressStatus(determineProgressStatus(record))}
                            />
                        }

                        {/* Half-width stats */}
                        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', overflow: 'hidden' }}>
                            {getDetailOption(detail, 'training-info.lessons') && getDetailOption(detail, 'training-info.lessons').value === true &&
                                <StatLabel
                                    className={classes.stat}
                                    style={{ width: '50%' }}
                                    icon={<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#lessons'} /></svg>}
                                    type={'number'}
                                    color={node.getFontColor()}
                                    value={record.lessons}
                                />
                            }
                            {getDetailOption(detail, 'user-info.users') && getDetailOption(detail, 'user-info.users').value === true &&
                                <StatLabel
                                    className={classes.stat}
                                    style={{ width: '50%' }}
                                    icon={<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#users'} /></svg>}
                                    type={'number'}
                                    color={node.getFontColor()}
                                    value={record.users}
                                />
                            }
                            {getDetailOption(detail, 'user-info.attempts') && getDetailOption(detail, 'user-info.attempts').value === true &&
                                <StatLabel
                                    className={classes.stat}
                                    style={{ width: '50%' }}
                                    icon={<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#attempts'} /></svg>}
                                    type={'number'}
                                    color={node.getFontColor()}
                                    value={record.attempts}
                                />
                            }
                            {getDetailOption(detail, 'user-info.completion-rate') && getDetailOption(detail, 'user-info.completion-rate').value === true &&
                                <StatLabel
                                    className={classes.stat}
                                    style={{ width: '50%' }}
                                    icon={<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#completion-rate'} /></svg>}
                                    type={'percentage'}
                                    color={node.getFontColor()}
                                    value={record.completionRate}
                                />
                            }
                            {getDetailOption(detail, 'user-info.score') && getDetailOption(detail, 'user-info.score').value === true &&
                                <StatLabel
                                    className={classes.stat}
                                    style={{ width: '50%' }}
                                    icon={<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#score'} /></svg>}
                                    type={'percentage'}
                                    color={node.getFontColor()}
                                    value={record.avgScore}
                                />
                            }
                            {getDetailOption(detail, 'user-info.time-spent') && getDetailOption(detail, 'user-info.time-spent').value === true &&
                                <StatLabel
                                    className={classes.stat}
                                    style={{ width: '50%' }}
                                    icon={<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#time-spent'} /></svg>}
                                    type={'duration'}
                                    color={node.getFontColor()}
                                    value={context === 'user' ? record.timeSpent : record.totalTimeSec}
                                />
                            }
                        </div>
                    </div>
                </div>

                {/* Popup Component. */}
                {this.anchorEl.current &&
                    <Popover
                        className={classes.popover}
                        open={popoverOpen}
                        onClose={this.closePopover}
                        anchorEl={this.anchorEl.current}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        onWheel={e => { e.stopPropagation(); }}
                        onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                        onMouseDown={(e) => { e.stopPropagation(); e.preventDefault(); }}
                    >
                        <TrainingNodePopover node={node} />
                    </Popover>
                }
            </div>
        );
    }
}

const myStyles = theme =>
    createStyles({
        root: {
            '&:hover': {
                cursor: 'pointer'
            },
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            padding: 10,
            overflow: 'hidden'
        },
        truncated: {
            position: 'relative',
            fontWeight: 'bold',
            lineHeight: '1.5rem',
            maxHeight: 'calc(1.5rem * 2)',
            overflow: 'hidden',
            paddingRight: 16,
            '&::before': {
                content: '"..."',
                position: 'absolute',
                bottom: 0,
                right: 0
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                right: 0,
                width: '1rem',
                height: 24,
                background: (props: TrainingNodeWidgetProps) => props.node.getBackgroundColor()
              }
        },
        header: {
            flex: '0 0 auto',
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10,
            marginRight: 30
        },
        icon: {
            minWidth: 40,
            width: 40,
            maxWidth: 40,
            minHeight: 40,
            height: 40,
            maxHeight: 40,
            borderWidth: '4px',
            borderStyle: 'solid',
            borderRadius: '10%',
            marginRight: 10
        },
        body: {
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            overflow: 'auto'
        },
        stats: {
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        },
        stat: {
            flex: '0 0 auto',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            minHeight: 24
        },
        popover: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'center',
            '& div[tabindex="-1"]': {
                borderStyle: 'solid',
                borderColor: (props: TrainingNodeWidgetProps) => props.node.getBackgroundColor(),
                borderWidth: 5,
                borderRadius: 10,
                overflow: 'hidden',
                boxShadow: '10px 10px 0px 0px rgba(35, 56, 99, 0.3)'
            }
        }
    });

const enhance = compose(withStyles(myStyles));

export default enhance(TrainingNodeWidget);