import React from 'react';
import {Typography} from '@material-ui/core';
import {translate} from 'react-admin';

const Text = ({translate, label = '', style, params}) => (
	<Typography style={style}>
		{params ? translate(label, params) : translate(label)}
	</Typography>
);

export default translate(Text);
