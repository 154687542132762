import React, {Component} from 'react';
import {createStyles, withStyles} from '@material-ui/core';

/**
 * Talespin logo and app version via logo tooltip.
 */
class AppVersion extends Component {
	render() {
		const {classes, icon} = this.props;

		return (
			<div className={classes.root}>
				<img src={icon} alt='' title={process.env.REACT_APP_VERSION} />
			</div>
		);
	}
}

const myStyles = theme =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '10px',
		},
	});

export default withStyles(myStyles)(AppVersion);
