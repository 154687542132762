import React, { useState } from 'react';
import { ScreenTitle, EmbeddedList, StatusChip, NumberValue } from 'components';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { getUserInfo } from 'utils/Data';
import { useTranslate } from "react-admin";
import get from 'lodash/get';
import * as moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from '@material-ui/core';
import Licences from "sections/Organization/Licenses/Licenses";
import { loadObject } from 'utils/Util';

const myStyles = theme =>
    createStyles({
        divider: {
            border: 0,
            borderBottom: '1px solid',
            color: theme.palette.borderColor.divider,
            width: '100%',
            height: '1px',
        },
    });

const SubLicenses = props => {
    const translate = useTranslate();

    const globalCurrentTermUsageThreshold = 5;

	const runwayOrgConfig = loadObject('runwayOrgConfig');
    
    const showSubLicenses = 
		runwayOrgConfig && runwayOrgConfig.config
			? runwayOrgConfig.config.showChildSubLicense
			: 'false';

    const [selectedApp, setSelectedApp] = useState(null);
    const [isChildAppDetailUserLicenseView, setIsChildAppDetailUserLicenseView] = useState(false);

    if (!getUserInfo().isAuthenticated) {
        return <Redirect to='login' />;
    }

    // if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
    //     return <Redirect to="/termsOfService" />
    // }

    if (getUserInfo().isUser) {
        return (
            <div>
                Access denied. Contact your administrator to raise your permissions
            </div>
        );
    }

    if (!getUserInfo().isAdmin && !getUserInfo().isSuper) {
        return <Redirect to='/' />;
    }

    if (showSubLicenses !== 'true') {
        return <Redirect to='/' />;
    }

    return (
        <div>
            {!isChildAppDetailUserLicenseView && <><ScreenTitle title='Child Licenses' />

                <EmbeddedList
                    embedded={false}
                    title={null}
                    resource={"listCustomerOfChildLicenseOverview"}
                    showSpinnerOnFilter
                    columns={[
                        {
                            id: 'appName',
                            name: translate('App Name'),
                            sortable: true,
                            align: 'left',
                            width: "250px"
                        },
                        {
                            id: 'environmentType',
                            name: translate('Environment Type'),
                            sortable: true,
                            align: 'center',
                            width: '130px',
                            render: record => <StatusChip record={record} source='environmentType' />,
                        },
                        {
                            id: 'status',
                            name: translate('Status'),
                            sortable: true,
                            align: 'center',
                            render: record => <StatusChip record={record} source='status' />,
                            isInverted: true,
                        },
                        {
                            id: 'lifeTimePALPurchased',
                            name: translate('Life Time PAL Purchased'),
                            sortable: true,
                            align: 'center',
                            render: record => <NumberValue source="lifeTimePALPurchased" record={record} />
                        },
                        {
                            id: 'currentTermPurchased',
                            name: translate('Current Term Purchased'),
                            sortable: true,
                            align: 'center',
                            render: record => record.currentTermPurchased === -1 ? <span style={{ fontSize: '24px' }}>&infin;</span> : <NumberValue source="currentTermPurchased" record={record} />
                        },
                        {
                            id: 'autoEnableMore',
                            name: translate('Auto-Add'),
                            width: '100px',
                            sortable: true,
                            align: 'center',
                            render: record => <span style={{ color: '#006400' }}>{record.autoEnableMore ? 'YES' : 'NO'}</span>
                        },
                        {
                            id: 'currentTermUsed',
                            name: translate('Current Term Used'),
                            sortable: true,
                            align: 'center',
                            width: '150px',
                            render: record => {

                                let textColor = "inherit"

                                if (!Number.isNaN(record.currentTermPurchased) && !Number.isNaN(record.currentTermUsed)) {
                                    const usedThreshold = ((record.currentTermPurchased - record.currentTermUsed) / record.currentTermPurchased) * 100;


                                    if ((usedThreshold < globalCurrentTermUsageThreshold)) {
                                        if (!record.autoEnableMore) {
                                            textColor = "red";
                                        } else {
                                            textColor = "#006400";
                                        }
                                    }
                                }

                                return (
                                    <span style={{ color: textColor }}><NumberValue source="currentTermUsed" record={record} /></span>
                                )
                            },
                        },
                        {
                            id: 'currentTermEndsIn',
                            name: translate('Current Term Ends in'),
                            sortable: true,
                            align: 'center',
                            render: record => <TimeTo source="currentTermEndsIn" record={record} />
                        },
                        {
                            id: 'nextTermStartsIn',
                            name: translate('Next Term Starts in'),
                            sortable: true,
                            align: 'center',
                            render: record => <TimeTo source="nextTermStartsIn" record={record} />
                        },
                    ]}
                    staticFilters={null}
                    dynamicFilters={[
                        {
                            id: 'appName',
                            name: translate('Search Org Name'),
                            display: 'search',
                        },
                        {
                            id: 'environmentType',
                            name: translate('Environment Type'),
                            type: 'exact',
                            choices: [
                                { value: '*', label: translate('All Environment Types') },
                                { value: 'Live', label: translate('Live') },
                                { value: 'Test', label: translate('Test') },
                            ],
                        },
                        {
                            id: 'status',
                            name: translate('Status'),
                            type: 'exact',
                            choices: [
                                { value: '*', label: translate('All Statuses') },
                                { value: 'enabled', label: translate('Enabled') },
                                { value: 'disabled', label: translate('Disabled') },
                            ],
                        },
                    ]}
                    selectionToggled={(record) => setSelectedApp(record)}
                    selectField={"radio"}
                    selectedIds={selectedApp ? [selectedApp.appId] : []}
                    actions={[
                        {
                            id: "emailTSFaciliator",
                            render: (record) => (
                                <MenuItem>
                                    <span onClick={() => window.location.href = 'mailto:' + record.contactEmail}>Email TS Facilitator</span>
                                </MenuItem>
                            )
                        },
                        {
                            id: 'redirectToAppRunway',
                            render: (record) => <MenuItem><Link target={"_blank"} rel="noopener" href={record.runwayUrl} underline={"none"}>{'Go to Environment Login'}</Link></MenuItem>
                        }]}
                />
            </>}

            {selectedApp && <div style={{ marginTop: isChildAppDetailUserLicenseView ? 0 : "24px" }}><Licences orgId={selectedApp.orgId} onToggleChildAppDetailUserLicenseView={() => setIsChildAppDetailUserLicenseView(!isChildAppDetailUserLicenseView)} /></div>}
        </div>
    )
}

export default withStyles(myStyles)(SubLicenses);

const TimeTo = ({ record, source }) => {

    let textColor = "inherit"

    if (record.currentTermEndsIn && !Number.isNaN(record.currentTermEndsIn) && record.currentTermEndsIn !== -1 && typeof record.nextTermStartsIn !== "number") {
        const fortnightAway = new Date(Date.now() + 12096e5);
        if ((record.currentTermEndsIn < fortnightAway)) textColor = "red";
    } else if (!record.currentTermEndsIn && typeof record.nextTermStartsIn !== "number") {
        textColor = "red";
    }

    if (!get(record, source) || get(record, source) === 'NaN' || (get(record, source) < 0) || get(record, source) === '---') {
        if (source === "currentTermEndsIn") {
            if (record.currentTermEndsIn == null) {
                return <span style={{ color: 'red' }}>Expired</span>
            } else {
                return <span style={{ fontSize: '24px' }}>&infin;</span>;
            }
        }
        if (source === "nextTermStartsIn") {
            if (record.currentTermEndsIn !== -1 || record.currentTermEndsIn == null) {
                return <span style={{ color: textColor }}>None</span>
            } else {
                return <span>---</span>;
            }
        }

        return <span>---</span>;
    }

    return <span style={{ color: textColor }}>{moment.utc(get(record, source)).fromNow(true)}</span>;
}
