import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { fetchEnd, fetchStart, GET_LIST, useVersion } from 'react-admin';
import { SkillBarPanel } from '../../../components';
import LoadingList from '../../../components/loading/LoadingList';
import { useDispatch } from "react-redux";

const SkillsTab = (props) => {

  const { id, dataProvider } = props;

  const [state, setState] = useState({ lessonSkillStats: null, isError: false });

  const [loading, setLoading] = useState(false);

  const version = useVersion();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
		setLoading(true);
		dispatch(fetchStart());
      await dataProvider(GET_LIST, 'LessonSkillStatsOverallRunway', {
        filter: { trainingId: id },
        pagination: {},
        sort: {},
      })
        .then(data => setState({ lessonSkillStats: data.data, isError: false }))
        .catch(() => setState({ lessonSkillStats: null, isError: true }))
		.finally(() => {
			setLoading(false);
			dispatch(fetchEnd());
		});
    };
    fetch();
  }, [id, version]);

  return (
		<div>
			{ state.isError && <Typography style={{textAlign: 'center', fontSize: '28px'}}>No Skills Yet</Typography> }
			{(!state.lessonSkillStats || loading) && !state.isError && <LoadingList />}
			{ (state.lessonSkillStats && !loading) && (
				<SkillBarPanel
					data={state.lessonSkillStats}
					vectorMapContextData={{
						teamId: undefined,
						userId: undefined,
						trainingId: id,
						lessonId: undefined,
					}}
				/>
			)}
		</div>
	);
}

export default SkillsTab;
