import React, {} from 'react';
import StatsTilePanel from '../../components/StatsTilePanel';
import PanelStat from '../../components/Panels/PanelStat';
import LoadingStat from '../../components/loading/LoadingStat';
import get from 'lodash/get';

const OverviewStatsPanel = props => {
	const { overViewstats, error } = props;

	const stats = get(overViewstats, 'array.data');
	
	const hasStats = stats && stats.length > 0;

	if (hasStats) {
		return <StatsTilePanel record={overViewstats} />;
	}

	if (stats && stats.length === 0) {
		return <div>No stats yet. Check back later!</div>;
	}

	if (error) {
		return <div>{error}</div>
	}

	return (
		<PanelStat>
			<LoadingStat />
		</PanelStat>
	);
};

export default OverviewStatsPanel;
