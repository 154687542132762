import {GET_LIST, useDataProvider, useTranslate} from 'react-admin';
import React, {useEffect, useState} from 'react';

import Grid from 'components/layout/Grid';
import Label from 'components/Label';
import {DialogTitle, Text, TextInput} from 'components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';

import MUIButton from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {loadObject} from 'utils/Util';

import TeamsInput from './TeamsInput';
import ConfigureUserDetailsDialog from './ConfigureUserDetailsDialog';
import ConfigurePasswordsDialog from './ConfigurePasswordsDialog';
import LoadingInfo from 'components/loading/LoadingInfo';
import {EDIT_GENERATE_USER_CONFIG} from 'services/customActions';
import EditIcon from 'assets/EditIcon';

const myStyles = theme =>
	createStyles({
		authTypeHeader: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingTop: '8px',
			fontFamily: 'Montserrat Medium',
			'& > label:first-of-type': {
				flex: '1 1 auto',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'flex-start',
			},
			'& > label:last-of-type': {
				flex: '1 1 auto',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'flex-end',
				color: theme.palette.text.info,
			},
		},
		divider: {
			marginBottom: '12px',
			color: theme.palette.borderColor.divider,
			width: '100%',
		},
		informationalText: {
			color: theme.palette.color.informational,
		},
		dialogContent: {
			width: '500px',
			justifyContent: 'space-between',
		},
		errorIcon: {
			fontSize: 'medium',
			marginLeft: '15px',
			color: 'red',
		},
		changeBtn: {
			height: '32px',
			width: '100%',
		},
	});

const ConfigureCreateUsersDialog = props => {
	const {isOpen, onClose, errorOccuredOnCreate} = props;
	const translate = useTranslate();

	const runwayOrgConfig = loadObject('runwayOrgConfig');
	const [defaultTeamId, setDefaultTeamId] = useState([
		runwayOrgConfig?.config?.defaultTeamId,
	]);
	const [flagedAsTester, setFlagedAsTester] = useState(false);
	const [firstNameCheckboxValue, setFirstNameCheckboxValue] = useState(false);
	const [lastNameCheckboxValue, setLastNameCheckboxValue] = useState(false);
	const [offeredJobRoles, setOfferedJobRoles] = useState([]);
	const [jobRoleSearchValue, setJobRoleSearchValue] = useState(null);
	const [jobRole, setJobRole] = useState('');

	const [dialogTitle, setDialogTitle] = useState('');
	const [dialogTitleInfo, setDialogTitleInfo] = useState('');
	const [userDetailsDialogData, setUserDetailsDialogData] = useState({});
	const [showConfigureDetailsDialog, setShowConfigureDetailsDialog] = useState(
		false
	);
	const [showPasswordsDialog, setShowPasswordsDialog] = useState(false);
	const [passwordsRadioValue, setPasswordsRadioValue] = useState('unique');
	const [selectedDataKey, setSelectedDataKey] = useState('');
	const [currentConfig, setCurrentConfig] = useState();

	const [payload, setPayload] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const dataProvider = useDataProvider();

	const afterGenerateUserConfigFetch = response => {
		setCurrentConfig(response.data);
		response.data.firstName.createName &&
			setFirstNameCheckboxValue(response.data.firstName.createName);
		response.data.lastName.createName &&
			setLastNameCheckboxValue(response.data.lastName.createName);
		response.data.flagAsTester && setFlagedAsTester(response.data.flagAsTester);
		response.data.jobRole && setJobRole(response.data.jobRole);
		response.data.defaultTeamId.length > 0 &&
			setDefaultTeamId([...response.data.defaultTeamId]);
		setPayload({});
		response.data.token.useSame && setPasswordsRadioValue('sameForAll');

		if (offeredJobRoles.length === 0) {
			dataProvider(GET_LIST, 'getJobRoleListRunway', {})
				.then(resonse => {
					setOfferedJobRoles(resonse.data);
					setIsLoading(false);
				})
				.catch(error => {
					console.log(error);
				});
		}
	};
	useEffect(() => {
		setIsLoading(true);
		if (isOpen) {
			dataProvider(GET_LIST, 'getGenerateUserConfigRunway', {})
				.then(response => {
					afterGenerateUserConfigFetch(response);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, [isOpen]);

	// handleFormSubmit becomes an onSubmit reference
	let handleFormSubmit = () => {
		dataProvider(
			EDIT_GENERATE_USER_CONFIG,
			'editGenerateUserConfigRunway',
			payload
		)
			.then(resonse => {
				onClose();
			})
			.catch(error => {
				console.log(error);
			});
		//To-Do API Update
	};

	const handlePasswordsRadioChange = event => {
		setPasswordsRadioValue(event.target.value);
		if (!payload['token']) {
			payload['token'] = currentConfig['token'];
		}
		if (event.target.value === 'unique') {
			payload['token'].useSame = false;
		} else {
			payload['token'].useSame = true;
		}
	};

	const handleDefaultTeamsChanged = selectedTeams => {
		payload['defaultTeamId'] = selectedTeams;
	};

	const onFIrstNameCheckboxChange = event => {
		setFirstNameCheckboxValue(event.target.checked);
		if (!payload['firstName']) {
			payload['firstName'] = currentConfig['firstName'];
		}

		payload['firstName'].createName = event.target.checked;
	};

	const onLastnameCheckboxCHange = event => {
		setLastNameCheckboxValue(event.target.checked);
		if (!payload['lastName']) {
			payload['lastName'] = currentConfig['lastName'];
		}

		payload['lastName'].createName = event.target.checked;
	};

	const onFlagAsTesterChange = event => {
		setFlagedAsTester(event.target.checked);
		payload['flagAsTester'] = event.target.checked;
	};

	useEffect(() => {
		if (jobRole !== '') {
			payload['jobRole'] = jobRole;
		} else {
			payload['jobRole'] = null;
		}
	}, [jobRole]);

	const closeConfigureDetailsDialog = updatedData => {
		payload[selectedDataKey] = updatedData;
		setShowConfigureDetailsDialog(false);
		setSelectedDataKey('');
	};

	const closeConfigurePasswordsDialog = updatedData => {
		payload[selectedDataKey] = updatedData;
		setShowPasswordsDialog(false);
		setSelectedDataKey('');
	};

	const onConfigureClicked = key => {
		let title = '';
		let titleInfo = '';
		switch (key) {
			case 'userName':
				title = 'Usernames Generator';
				titleInfo = 'Define the contents of Usernames.';
				setShowConfigureDetailsDialog(true);
				break;
			case 'firstName':
				title = 'Firstnames Generator';
				titleInfo = 'Define the contents of Firstnames.';
				setShowConfigureDetailsDialog(true);
				break;
			case 'lastName':
				title = 'Lastnames Generator';
				titleInfo = 'Define the contents of Lastnames.';
				setShowConfigureDetailsDialog(true);
				break;
			case 'token':
				title = 'Passwords';
				titleInfo =
					'Define the Passwords that will be created for each account.';
				setShowPasswordsDialog(true);
				break;
			default:
				return;
		}
		setSelectedDataKey(key);
		setDialogTitle(title);
		setDialogTitleInfo(titleInfo);
		setUserDetailsDialogData(currentConfig[key]);
	};

	const renderScreen = () => {
		const {classes} = props;
		return (
			<>
				{isOpen ? (
					<Dialog
						open={isOpen}
						aria-label={'Configure User-Account Generation'}
						style={{maxHeight: 'flex', zIndex: '10'}}
					>
						<DialogTitle
							onClose={onClose}
							subTitle={
								<div className={classes.authTypeHeader}>
									<label>{translate('Authentication Type')}</label>
									<label>
										{translate('Username & Password')}
										<Tooltip
											title={translate(
												'Your organization has restricted authentication to the Username & Password type which requires a unique username and password be set when creating a user account.'
											)}
										>
											<HelpIcon
												className={classes.informationalText}
												style={{
													fontSize: 'medium',
													marginLeft: '10px',
													color: 'gray',
												}}
											/>
										</Tooltip>
									</label>
								</div>
							}
						>
							<Text
								style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
								label={translate('Configure User-Account Generation')}
							/>
						</DialogTitle>

						{isLoading ? (
							<DialogContent className={classes.dialogContent}>
								<LoadingInfo />
							</DialogContent>
						) : (
							<DialogContent className={classes.dialogContent}>
								<Grid perRow={1} gridGap={'16px 0px'} padding={'10px 0'}>
									<Grid perRow={2} gridGap={'16px'}>
										<div>
											<div style={{display: 'flex', flexDirection: 'row'}}>
												<Label label={translate('Usernames*')}></Label>{' '}
												{errorOccuredOnCreate && (
													<Tooltip
														title={
															<span>
																<b>Alert:</b> The last time the process was run,
																the system could not generate the requested
																count of Usernames. Please select Change and
																increase the number of Strings to allow a
																greater number of Usernames to be generated.
															</span>
														}
													>
														<ErrorOutlineIcon className={classes.errorIcon} />
													</Tooltip>
												)}
											</div>

											<MUIButton
												color='primary'
												variant='outlined'
												className={classes.changeBtn}
												onClick={() => onConfigureClicked('userName')}
												startIcon= {<EditIcon style={{ fontSize: '16px', marginBottom: '3px'}}/>}
											>
												{translate('Change')}
											</MUIButton>
										</div>
										<div>
											<Label label={translate('Passwords*')} />
											<RadioGroup
												aria-label='Passwords'
												name='Passwords'
												value={passwordsRadioValue}
												onChange={handlePasswordsRadioChange}
											>
												<FormControlLabel
													value='unique'
													control={<Radio />}
													label={translate('Unique Per Account (Recommended)')}
												/>
												<FormControlLabel
													value='sameForAll'
													control={<Radio />}
													label={translate('Same For All.')}
												/>
											</RadioGroup>
											<MUIButton
												color='primary'
												variant='outlined'
												className={classes.changeBtn}
												onClick={() => onConfigureClicked('token')}
												startIcon= {<EditIcon style={{ fontSize: '16px', marginBottom: '3px'}}/>}
											>
												{translate('Change')}
											</MUIButton>
										</div>

										<div>
											<Label label={translate('First Name')} />
											<div style={{display: 'flex', alignItems: 'center'}}>
												<Checkbox
													checked={firstNameCheckboxValue}
													onChange={event => onFIrstNameCheckboxChange(event)}
													inputProps={{'aria-label': 'primary checkbox'}}
												/>
												<MUIButton
													color='primary'
													variant='outlined'
													className={classes.changeBtn}
													onClick={() => onConfigureClicked('firstName')}
													startIcon= {<EditIcon style={{ fontSize: '16px', marginBottom: '3px'}}/>}
												>
													{translate('Change')}
												</MUIButton>
											</div>
										</div>

										<div>
											<Label label={translate('Last Name')} />
											<div style={{display: 'flex', alignItems: 'center'}}>
												<Checkbox
													checked={lastNameCheckboxValue}
													onChange={event => onLastnameCheckboxCHange(event)}
													inputProps={{'aria-label': 'primary checkbox'}}
												/>
												<MUIButton
													color='primary'
													variant='outlined'
													className={classes.changeBtn}
													onClick={() => onConfigureClicked('lastName')}
													startIcon= {<EditIcon style={{ fontSize: '16px', marginBottom: '3px'}}/>}
												>
													{translate('Change')}
												</MUIButton>
											</div>
										</div>

										<div>
											<Label label={translate('Teams')} />

											<div style={{display: 'flex', flexDirection: 'column'}}>
												<TeamsInput
													defaultTeamId={defaultTeamId}
													handleDefaultTeamsChanged={handleDefaultTeamsChanged}
												/>
											</div>
										</div>

										<div>
											<Label label={translate('Job Role')} />
											<Autocomplete
												id='auto-complete-input'
												options={offeredJobRoles}
												open={jobRoleSearchValue ? true : false}
												inputValue={jobRole}
												onInputChange={(e, value, changeReason) => {
													setJobRoleSearchValue(value);
													if (changeReason !== 'reset') {
														setJobRole(value);
													}
												}}
												noOptionsText={translate('Job Role not found')}
												onChange={(e, value) => {
													setJobRole(value);
													setJobRoleSearchValue(null);
												}}
												onClose={(e, closeReason) => {
													if (closeReason !== 'select-option') {
														setJobRole('');
														setJobRoleSearchValue(null);
													}
												}}
												renderInput={params => (
													<div ref={params.InputProps.ref}>
														<TextInput
															inputProps={params.inputProps}
															value={jobRole ? jobRole : ''}
															placeholder='Optional'
														/>
													</div>
												)}
											/>
										</div>
									</Grid>
								</Grid>
								<div>
									<hr className={classes.divider} />
									<div className={classes.fieldRow}>
										<FormControlLabel
											style={{width: '50%', fontFamily: 'Montserrat Medium'}}
											control={
												<Checkbox
													checked={flagedAsTester}
													value='enabled'
													onClick={event => onFlagAsTesterChange(event)}
												/>
											}
											label={translate('Flag as Tester')}
										/>
									</div>
									<div style={{fontFamily: 'Montserrat Italic'}}>
										<i>
											{translate(
												`A tester's progress is tracked, but does not influence an organization's stats and averages.`
											)}
										</i>
									</div>
								</div>
							</DialogContent>
						)}

						<DialogActions>
							<div>
								<MUIButton
									color='primary'
									variant='contained'
									onClick={() => handleFormSubmit()}
								>
									{translate('Done')}
								</MUIButton>
							</div>
						</DialogActions>
						{showConfigureDetailsDialog && (
							<ConfigureUserDetailsDialog
								title={dialogTitle}
								titleInfo={dialogTitleInfo}
								data={userDetailsDialogData}
								isOpen={showConfigureDetailsDialog}
								onClose={closeConfigureDetailsDialog}
							/>
						)}

						{showPasswordsDialog && (
							<ConfigurePasswordsDialog
								title={dialogTitle}
								titleInfo={dialogTitleInfo}
								data={userDetailsDialogData}
								isOpen={showPasswordsDialog}
								onClose={closeConfigurePasswordsDialog}
							/>
						)}
					</Dialog>
				) : null}
			</>
		);
	};

	return <div>{renderScreen()}</div>;
};

export default withStyles(myStyles)(ConfigureCreateUsersDialog);
