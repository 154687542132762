import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	MuiThemeProvider,
	Typography,
	withStyles,
} from '@material-ui/core';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {DialogTitle, EmbeddedList} from 'components';
import {GET_LIST, useTranslate, useDataProvider} from 'react-admin';
import {DialogListTheme, TSTheme} from 'components/Theme';
import {getUserInfo} from 'utils/Data';
import {loadObject} from 'utils/Util';

const styles = theme =>
	createStyles({
		filterButton: {
			height: '32px',
			fontSize: '12px',
			fontFamily: 'Archivo Bold',
			marginLeft: '15px',
		},
		selectedSkill: {
			border: `2px solid ${theme.palette.primary.main}`,
			borderRadius: '20px',
			alignItems: 'center',
		},
		skillName: {
			color: '#000000',
		},
		cancelIcon: {
			color: theme.palette.primary.main,
		},
		dialogTitle: {
			fontSize: '20px',
			fontFamily: 'Archivo Bold',
		},
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
		},
		selectedSkillListTitle: {
			paddingBottom: '18px',
			paddingTop: '25px',
			fontFamily: 'Montserrat Medium',
		},
		moreSkillsText: {
			fontFamily: 'Archivo Bold',
			textDecoration: 'underline',
			cursor: 'pointer',
			fontSize: '16px',
		},
		selectFilterBtn: {
			height: '32px',
			fontSize: '12px',
			fontFamily: 'Archivo Bold',
			textTransform: 'uppercase',
			width: '150px',
		},
		btnBubbleStyle: {
			color: TSTheme.palette.primary.main,
			minWidth: '25px',
		},
	});

const CustomTheme = createTheme({
	...TSTheme,
	overrides: {
		MuiButton: {
			endIcon: {
				position: 'absolute',
				top: '-10px',
				right: '-10px',
				margin: '0 !important',
				backgroundColor: TSTheme.palette.background.default,
				border: `2px ${TSTheme.palette.primary.main} solid`,
				borderRadius: '5px',
			},
			iconSizeMedium: {
				'& > *:first-child': {
					fontSize: '16px !important',
				},
			},
		},
	},
});

const ModuleFilterDialog = props => {
	const {
		preSelectedSkills,
		preSelectedContentOwners,
		preSelectedCreatedBys,
		selectedFilterGroupId,
		onChange,
		classes,
		isOpen,
		onClose,
		skillListContextData,
	} = props;

	const translate = useTranslate();

	const dataProvider = useDataProvider();

	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const filterGroups =
	runwayOrgConfig && runwayOrgConfig.config.enableCPDPublication === 'true' &&
		getUserInfo().trainingModuleEditor
			? [
					{id: 'skills', displayName: 'Skills'},
					{id: 'contentUpdatableOwner', displayName: 'Content Owner'},
					{id: 'createdBy', displayName: 'Created By'},
			  ]
			: [
					{id: 'skills', displayName: 'Skills'},
					{id: 'contentUpdatableOwner', displayName: 'Content Owner'},
			  ];

	const skillListFilter = skillListContextData
		? Object.keys(skillListContextData).map(key => ({
				id: key,
				value: skillListContextData[key],
		  }))
		: undefined;

	const [contentOwnerList, setContentOwnerList] = useState([]);

	const [createdByList, setCreatedByList] = useState([]);

	const [selectedFilterGroup, setSelectedFilterGroup] = useState(
		filterGroups[0]
	);

	const [tempSelectedSkills, setTempSelectedSkills] = useState([]);

	const [tempSelectedCreatedBys, setTempSelectedCreatedBys] = useState([]);

	const [tempSelectedContentOwners, setTempSelectedContentOwners] = useState(
		[]
	);

	const toggleFilterSelection = (record, filters) => {
		let updatedFilters = [...filters];

		const targetFilter = updatedFilters.find(item => item.name === record.name);

		if (!targetFilter) updatedFilters.push(record);
		else {
			const idx = updatedFilters.indexOf(targetFilter);
			if (idx >= 0) {
				updatedFilters.splice(idx, 1);
			}
		}

		return updatedFilters;
	};

	const handleButtonClick = clearFilter => {
		if (clearFilter) onChange([], [], []);
		else {
			onChange(
				tempSelectedSkills,
				tempSelectedContentOwners,
				tempSelectedCreatedBys
			);
		}
		onClose(false);
	};

	const fetchLists = async () => {
		const {data} = await dataProvider(
			GET_LIST,
			'listContentOwnerCreatedByRunway',
			{
				pagination: {page: 1, perPage: 9999},
				sort: {},
				filter: {},
			}
		);

		const tempCreatedByList = data.createdBy.map((item, i) => {
			return {name: item, displayName: item};
		});
		setCreatedByList(tempCreatedByList);

		const tempContentOwnerList = data.contentUpdatableOwner.map((item, i) => {
			return {name: item, displayName: item};
		});
		setContentOwnerList(tempContentOwnerList);
	};

	useEffect(() => {
		if (isOpen) {
			fetchLists();
			setSelectedFilterGroup(
				filterGroups.find(filter => filter.id === selectedFilterGroupId)
			);
			setTempSelectedSkills(preSelectedSkills);
			setTempSelectedCreatedBys(preSelectedCreatedBys);
			setTempSelectedContentOwners(preSelectedContentOwners);
		} else {
			setTempSelectedSkills([]);
			setTempSelectedCreatedBys([]);
			setTempSelectedContentOwners([]);
		}
	}, [isOpen]);

	return (
		<MuiThemeProvider theme={DialogListTheme}>
			<Dialog
				id={'module-filter'}
				maxWidth='lg'
				open={isOpen}
				onClose={() => onClose(false)}
			>
				<DialogTitle onClose={() => onClose(false)}>
					<Typography className={classes.dialogTitle}>Filter Modules</Typography>
				</DialogTitle>
				<DialogContent>
					<div className={classes.dialogContent}>
						<div
							style={{display: 'flex', columnGap: '15px', paddingTop: '15px'}}
						>
							{filterGroups.map((filter, i) => {
								return (
									<ThemeProvider theme={CustomTheme} key={i}>
										<Button
											key={i}
											variant={'contained'}
											color={
												filter.id === selectedFilterGroup.id
													? 'primary'
													: 'secondary'
											}
											className={classes.selectFilterBtn}
											onClick={() => setSelectedFilterGroup(filter)}
											endIcon={
												filter.id === 'createdBy'
													? tempSelectedCreatedBys.length > 0 && (
															<div className={classes.btnBubbleStyle}>
																{tempSelectedCreatedBys.length}
															</div>
													  )
													: filter.id === 'contentUpdatableOwner'
													? tempSelectedContentOwners.length > 0 && (
															<div className={classes.btnBubbleStyle}>
																{tempSelectedContentOwners.length}
															</div>
													  )
													: tempSelectedSkills.length > 0 && (
															<div className={classes.btnBubbleStyle}>
																{tempSelectedSkills.length}
															</div>
													  )
											}
										>
											{filter.displayName}
										</Button>
									</ThemeProvider>
								);
							})}
						</div>
						<div style={{display: 'flex', minHeight: '400px'}}>
							<div
								style={{
									width: '60%',
									paddingTop: selectedFilterGroup.id !== 'skills' ? '64px' : '',
								}}
							>
								<EmbeddedList
									key={'name'}
									embedded={true}
									title={null}
									resource={
										selectedFilterGroup.id === 'createdBy'
											? createdByList
											: selectedFilterGroup.id === 'contentUpdatableOwner'
											? contentOwnerList
											: 'listNamedSkills'
									}
									columns={[
										{
											id: 'displayName',
											name: translate(
												selectedFilterGroup.id === 'createdBy'
													? 'CoPilot Designer Author'
													: selectedFilterGroup.id === 'contentUpdatableOwner'
													? 'Organization'
													: 'Name'
											),
											sortable: true,
										},
										selectedFilterGroup.id === 'skills' && {
											id: 'type',
											name: translate('Type'),
										},
									]}
									sort={
										selectedFilterGroup.id === 'skills' && {
											field: 'displayName',
											order: 'ASC',
										}
									}
									staticFilters={skillListFilter}
									dynamicFilters={
										selectedFilterGroup.id === 'skills'
											? [
													{
														id: 'displayName',
														name: translate('Search Skills'),
														type: 'safe_regex',
													},
													{
														id: 'type',
														name: translate('Type'),
														choices: [
															{value: '*', label: translate('All Types')},
															{value: 'hard', label: translate('Hard')},
															{value: 'soft', label: translate('Soft')},
														],
													},
											  ]
											: []
									}
									bulkActions={null}
									actions={null}
									rowClicked={null}
									selectedIds={
										selectedFilterGroup.id === 'createdBy' &&
										tempSelectedCreatedBys
											? tempSelectedCreatedBys.map(item => item.displayName)
											: selectedFilterGroup.id === 'contentUpdatableOwner' &&
											  tempSelectedContentOwners
											? tempSelectedContentOwners.map(item => item.displayName)
											: selectedFilterGroup.id === 'skills' &&
											  tempSelectedSkills
											? tempSelectedSkills.map(skill => skill.name)
											: []
									}
									selectionToggled={record =>
										selectedFilterGroup.id === 'createdBy'
											? setTempSelectedCreatedBys(
													toggleFilterSelection(record, tempSelectedCreatedBys)
											  )
											: selectedFilterGroup.id === 'contentUpdatableOwner'
											? setTempSelectedContentOwners(
													toggleFilterSelection(
														record,
														tempSelectedContentOwners
													)
											  )
											: setTempSelectedSkills(
													toggleFilterSelection(record, tempSelectedSkills)
											  )
									}
									maxHeight={'400px'}
									isPageChooser={false}
								/>
							</div>
							<div style={{width: '40%', paddingLeft: '10px'}}>
								<div className={classes.selectedSkillListTitle}>
									{`Selected ${selectedFilterGroup.displayName} to Filter ${
										selectedFilterGroup.id === 'createdBy' &&
										tempSelectedCreatedBys.length > 0
											? `(${tempSelectedCreatedBys.length})`
											: selectedFilterGroup.id === 'contentUpdatableOwner' &&
											  tempSelectedContentOwners.length > 0
											? `(${tempSelectedContentOwners.length})`
											: selectedFilterGroup.id === 'skills' &&
											  tempSelectedSkills.length > 0
											? `(${tempSelectedSkills.length})`
											: ''
									}`}
								</div>
								<EmbeddedList
									key={'displayName'}
									embedded={true}
									resource={
										selectedFilterGroup.id === 'createdBy'
											? tempSelectedCreatedBys
											: selectedFilterGroup.id === 'contentUpdatableOwner'
											? tempSelectedContentOwners
											: tempSelectedSkills
									}
									columns={[
										{
											id: 'displayName',
											name: translate('Name'),
										},
									]}
									selectionToggled={record =>
										selectedFilterGroup.id === 'createdBy'
											? setTempSelectedCreatedBys(
													toggleFilterSelection(record, tempSelectedCreatedBys)
											  )
											: selectedFilterGroup.id === 'contentUpdatableOwner'
											? setTempSelectedContentOwners(
													toggleFilterSelection(
														record,
														tempSelectedContentOwners
													)
											  )
											: setTempSelectedSkills(
													toggleFilterSelection(record, tempSelectedSkills)
											  )
									}
									splitSelection={true}
									maxHeight={'400px'}
								/>
							</div>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleButtonClick(true)}
						variant={'contained'}
						color={'secondary'}
					>
						Clear Filter
					</Button>
					<Button
						onClick={() => handleButtonClick(false)}
						variant={'contained'}
						color={'primary'}
					>
						Set Filter
					</Button>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

export default withStyles(styles)(ModuleFilterDialog);
