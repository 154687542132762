import React from 'react';
import {useTheme} from '@material-ui/core/styles';

const PanelStat = ({children}) => {

	const theme = useTheme();
	return (
		<div
			style={{
				width: '128px',
				height: '80px',
				margin: 'auto',
				padding: '10px 35px',
				backgroundColor: theme.palette.background.contained,
				border: 'none',
				borderRadius: '4px',
			}}
		>
			{children}
		</div>
	);
};

export default PanelStat;
