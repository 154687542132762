import React from 'react';

import Button from 'components/Button';
import TextValue from 'components/TextValue';

const DialogActions = ({actions, handleClose}) => {
	var items = [];

	if (!actions) {
		return items;
	}

	for (var i = 0; i < actions.length; i++) {
		const action = actions[i];

		if (action.render) {
			items.push(
				<div key={i} style={{marginLeft: '10px'}}>
					{action.render(handleClose)}
				</div>
			);
		} else {
			items.push(
				<div style={{marginLeft: '10px'}} key={action.label}>
					<Button
						small
						variant='outlined'
						color='secondary'
						onClick={() => action.onClick(handleClose)}
					>
						<TextValue
							style={{color: '#ffc100'}}
							record={action}
							source={'label'}
							disableTooltip={true}
						/>
					</Button>
				</div>
			);
		}
	}

	if (items.length === 0) {
		return <div />;
	}

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				margin: '12px 0px',
			}}
		>
			<span style={{flex: 1}} />
			{items}
		</div>
	);
};

export default DialogActions;
