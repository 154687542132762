import * as React from 'react';
import compose from 'recompose/compose';
import {createStyles, Typography, withStyles} from '@material-ui/core';
import {DiagramEngine} from '@projectstorm/react-diagrams';

import {LabelNodeModel} from './LabelNodeModel';

export interface LabelNodeWidgetProps {
    node: LabelNodeModel;
    engine: DiagramEngine;
    classes?: any
}

class LabelNodeWidget extends React.Component<LabelNodeWidgetProps> {

    render() {
        const { node, classes } = this.props;

        const width = node.getWidth() ? node.getWidth() : 100;
        const height = node.getHeight() ? node.getHeight() : 100;

        const record = node.getRecord();

        if (!record) return null;

        return (
            <div
                className={classes.root}
                style={{
                    width: width,
                    height: height,
                    color: node.getFontColor()
                }}
            >
                <Typography noWrap={true} style={{ width: '100%', fontSize: '24px', fontWeight: 'bold' }}>{record.label}</Typography>
            </div>
        );
    }
}

const myStyles = () => createStyles({
    root: {
        '&:hover': {
            cursor: 'default'
        },
        position: 'relative',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
});

const enhance = compose(withStyles(myStyles));

export default enhance(LabelNodeWidget);
