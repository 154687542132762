import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import {Button, ThemeProvider} from '@material-ui/core';
import {CustomTheme} from 'components/DatePicker';

class AutoReload extends Component {
	constructor(props) {
		super(props);
		this.previousHash = null;
		this.state = {
			codeHasChanged: false,
			alertDismissed: false,
		};
		this.fetchSource = this.fetchSource.bind(this);
	}

	componentDidMount() {
		const {tryDelay, forceDelay} = this.props;
		this.fetchSource();
		this.interval = setInterval(this.fetchSource, tryDelay, forceDelay);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	async fetchSource() {
		return fetch(this.props.url)
			.then(response => {
				if (response.status !== 200) {
					throw new Error('offline');
				}
				return response.text();
			})
			.then(html => {
				const hash = this.hash(html);
				if (!this.previousHash) {
					this.previousHash = hash;
					return;
				}
				if (this.previousHash !== hash) {
					this.previousHash = hash;
					this.setState({codeHasChanged: true});
				}
			})
			.catch(() => {
				/* do nothing */
			});
	}

	/**
	 * Java-like hashCode function for strings
	 *
	 * taken from http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery/7616484#7616484
	 */
	hash(str) {
		const len = str.length;
		let hash = 0;
		if (len === 0) return hash;
		let i;
		for (i = 0; i < len; i++) {
			hash = (hash << 5) - hash + str.charCodeAt(i);
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}

	reloadApp(e) {
		window.location.reload(true);
		e.preventDefault();
	}

	render() {
		if (!this.state.codeHasChanged || this.state.alertDismissed) return null;
		return (
			<ThemeProvider theme={CustomTheme}>
				<Alert
					variant='filled'
					onClose={() => this.setState({alertDismissed: true})}
					action={
						<>
							<Button
								variant='contained'
								color='primary'
								size='small'
								onClick={e => this.reloadApp(e)}
							>
								Reload
							</Button>
							<Button
								style={{marginLeft: '5px'}}
								variant='outlined'
								color='secondary'
								size='small'
								onClick={e => this.setState({alertDismissed: true})}
							>
								Dismiss
							</Button>
						</>
					}
				>
					New Version of App is Available
				</Alert>
			</ThemeProvider>
		);
	}
}

AutoReload.propTypes = {
	url: PropTypes.string.isRequired,
	tryDelay: PropTypes.number.isRequired,
	forceDelay: PropTypes.number.isRequired,
};

AutoReload.defaultProps = {
	url: '/',
	tryDelay: 5 * 60 * 1000, // 5 minutes
	forceDelay: 24 * 60 * 60 * 1000, // 1 day
};

export default AutoReload;
