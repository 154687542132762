import {GET_LIST, GET_ONE} from 'react-admin';
import {loadObject, saveObject} from './Util';

export const statusChoices = [
	{id: 'all', name: 'All Statuses', selected: true},
	{id: 'enabled', name: 'Enabled'},
	{id: 'disabled', name: 'Disabled'},
];

export const availableLanguages = [{id: 'en', name: 'English (EN)'}];

/**
 * Make a request that will respect if the component is currently visible
 *
 * @param dataProvider
 * @param dataRequest - Request being made
 * @param isMounted - If the calling component is currently visible
 * @param setState - The set to set for the completeted request
 * @returns {Promise<*>}
 */
export async function safeRequestData({
	dataRequest,
	dataProvider,
	isMounted,
	setState,
}) {
	const savedName = dataRequest.dataAlias
		? dataRequest.dataAlias
		: dataRequest.resource;

	if (!isMounted.current) {
		return;
	}
	if (dataRequest.once) {
		const loadedData = loadObject(dataRequest.resource);

		if (loadedData && isMounted.current) {
			setState(state => ({
				...state,
				[savedName]: loadedData,
			}));

			return;
		}
	}

	try {
		let response = {};
		if (dataRequest.queryParams) {
			response = await dataProvider(GET_LIST, dataRequest.resource, {
				...dataRequest.queryParams,
			});
		} else if (dataRequest.resource === 'users') {
			// remove after fixing react-admin cache
			response = await dataProvider.getOne(dataRequest.resource, {
				id: dataRequest.id,
			});
		} else {
			response = await dataProvider(GET_ONE, dataRequest.resource, {
				id: dataRequest.id,
			});
		}

		if (isMounted.current) saveObject(dataRequest.resource, response.data);

		if (isMounted.current) {
			setState(state => ({
				...state,
				[savedName]: response.data,
			}));

			return response.data;
		}
	} catch {}
}

export const sortDirection = {
	ASC: 1,
	DESC: -1,
};

export const getUserInfo = () => {
	const blankRole = {
		name: 'undefined',
		email: 'undefined',
		role: '',
		id: '',
		isAdmin: false,
		isAuthenticated: false,
		isUser: false,
		isSuper: false,
		trainingModuleEditor: false,
	};

	const projectConfig = JSON.parse(sessionStorage.getItem('project'));
	if (!projectConfig) {
		return blankRole;
	}
	const project = projectConfig.id;
	let userInfo = JSON.parse(sessionStorage.getItem(project + '-user'));

	if (!userInfo) {
		userInfo = blankRole;
	} else {
		userInfo.isAuthenticated = true;
	}
	userInfo.isAdmin = userInfo && userInfo.role.indexOf('admin') >= 0;
	userInfo.isUser = userInfo && userInfo.role.indexOf('user') >= 0;
	userInfo.isSuper = userInfo && userInfo.role.indexOf('super') >= 0;

	let authCreds = JSON.parse(sessionStorage.getItem('authCreds'));

	if (authCreds && authCreds.userData)
		userInfo.trainingModuleEditor = authCreds.userData.trainingModuleEditor;

	return userInfo;
};

export const getConfigInfo = () => {
	const blankRole = {
		name: 'undefined',
		email: 'undefined',
		role: 'user',
		id: '',
		isAdmin: false,
		isAuthenticated: false,
		isSuper: false,
	};

	const projectConfig = JSON.parse(sessionStorage.getItem('project'));
	if (!projectConfig) {
		return blankRole;
	}
	const project = projectConfig.id;
	let userInfo = JSON.parse(sessionStorage.getItem(project + '-user'));

	if (!userInfo) {
		userInfo = blankRole;
	} else {
		userInfo.isAuthenticated = true;
	}
	userInfo.isAdmin = userInfo && userInfo.role.indexOf('admin') >= 0;

	return userInfo;
};

export const timezones_daylight = [
	{
		offset: -11,
		offsetString: 'UTC-11:00',
		name: 'SST',
		nameLong: 'Samoa Standard Time',
	},
	{
		offset: -10,
		offsetString: 'UTC-10:00',
		name: 'CKT',
		nameLong: 'Cook Island Time',
	},
	{
		offset: -9.5,
		offsetString: 'UTC-9:30',
		name: 'MART',
		nameLong: 'Marquesas Time',
	},
	{
		offset: -9,
		offsetString: 'UTC-9:00',
		name: 'HADT',
		nameLong: 'Hawaii Daylight Time',
	},
	{
		offset: -8,
		offsetString: 'UTC-8:00',
		name: 'AKDT',
		nameLong: 'Alaska Daylight Time',
	},
	{
		offset: -7,
		offsetString: 'UTC-7:00',
		name: 'PDT',
		nameLong: 'Pacific Daylight Time',
	},
	{
		offset: -6,
		offsetString: 'UTC-6:00',
		name: 'MDT',
		nameLong: 'Mountain Daylight Time',
	},
	{
		offset: -5,
		offsetString: 'UTC-5:00',
		name: 'CDT',
		nameLong: 'Central Daylight Time',
	},
	{
		offset: -4.5,
		offsetString: 'UTC-4:30',
		name: 'VET',
		nameLong: 'Venezuelan Standard Time',
	},
	{
		offset: -4,
		offsetString: 'UTC-4:00',
		name: 'EDT',
		nameLong: 'Eastern Daylight Time',
	},
	{
		offset: -3,
		offsetString: 'UTC-3:00',
		name: 'ADT',
		nameLong: 'Atlantic Daylight Time',
	},
	{
		offset: -2.5,
		offsetString: 'UTC-2:30',
		name: 'NDT',
		nameLong: 'Newfoundland Daylight Time',
	},
	{
		offset: -2,
		offsetString: 'UTC-2:00',
		name: 'BST',
		nameLong: 'Brazil Summer Time',
	},
	{
		offset: -1,
		offsetString: 'UTC-1:00',
		name: 'CVT',
		nameLong: 'Cape Verde Time',
	},
	{
		offset: 0,
		offsetString: 'UTC+0:00',
		name: 'UTC',
		nameLong: 'Greenwich Mean Time',
	},
	{
		offset: 1,
		offsetString: 'UTC+1:00',
		name: 'BST',
		nameLong: 'British Summer Time',
	},
	{
		offset: 2,
		offsetString: 'UTC+2:00',
		name: 'CEST',
		nameLong: 'Central European Summer Time',
	},
	{
		offset: 3,
		offsetString: 'UTC+3:00',
		name: 'EAT',
		nameLong: 'Eastern African Time',
	},
	{
		offset: 3.5,
		offsetString: 'UTC+3:30',
		name: 'IRST',
		nameLong: 'Iran Standard Time',
	},
	{
		offset: 4,
		offsetString: 'UTC+4:00',
		name: 'GET',
		nameLong: 'Georgia Standard Time',
	},
	{
		offset: 5,
		offsetString: 'UTC+5:00',
		name: 'PKT',
		nameLong: 'Pakistan Standard Time',
	},
	{
		offset: 5.5,
		offsetString: 'UTC+5:30',
		name: 'IST',
		nameLong: 'India Standard Time',
	},
	{
		offset: 5.75,
		offsetString: 'UTC+5:45',
		name: 'NPT',
		nameLong: 'Nepal Time',
	},
	{
		offset: 6,
		offsetString: 'UTC+6:00',
		name: 'BST',
		nameLong: 'Bangladesh Standard Time',
	},
	{
		offset: 6.5,
		offsetString: 'UTC+6:30',
		name: 'CCT',
		nameLong: 'Cocos Islands Time',
	},
	{
		offset: 7,
		offsetString: 'UTC+7:00',
		name: 'VST',
		nameLong: 'Vietnam Standard Time',
	},
	{
		offset: 8,
		offsetString: 'UTC+8:00',
		name: 'CTT',
		nameLong: 'China Taiwan Time',
	},
	{
		offset: 8.5,
		offsetString: 'UTC+8:30',
		name: 'PYT',
		nameLong: 'Pyongyang Time',
	},
	{
		offset: 8.75,
		offsetString: 'UTC+8:45',
		name: 'ACWST',
		nameLong: 'Australian Central Western Standard Time',
	},
	{
		offset: 9,
		offsetString: 'UTC+9:00',
		name: 'JST',
		nameLong: 'Japan Standard Time',
	},
	{
		offset: 10,
		offsetString: 'UTC+10:00',
		name: 'ChST',
		nameLong: 'Chamorro Standard Time',
	},
	{
		offset: 10.5,
		offsetString: 'UTC+10:30',
		name: 'ACDT',
		nameLong: 'Australian Central Daylight Time',
	},
	{
		offset: 11,
		offsetString: 'UTC+11:00',
		name: 'AEDT',
		nameLong: 'Australia Eastern Daylight Time',
	},
	{
		offset: 12,
		offsetString: 'UTC+12:00',
		name: 'MHT',
		nameLong: 'Marshall Islands Time',
	},
	{
		offset: 13,
		offsetString: 'UTC+13:00',
		name: 'NZDT',
		nameLong: 'New Zealand Daylight Time',
	},
	{
		offset: 13.75,
		offsetString: 'UTC+13:45',
		name: 'CHDST',
		nameLong: 'Chatham Islands Daylight Time',
	},
].sort((a, b) => (a.offset < b.offset ? -1 : a.offset > b.offset ? 1 : 0));

export const timezones_standard = [
	{
		offset: -11,
		offsetString: 'UTC-11:00',
		name: 'MIT',
		nameLong: 'Midway Islands Time',
	},
	{
		offset: -10,
		offsetString: 'UTC-10:00',
		name: 'HAST',
		nameLong: 'Hawaii Standard Time',
	},
	{
		offset: -9.5,
		offsetString: 'UTC-9:30',
		name: 'MART',
		nameLong: 'Marquesas Time',
	},
	{
		offset: -9,
		offsetString: 'UTC-9:00',
		name: 'AKST',
		nameLong: 'Alaska Standard Time',
	},
	{
		offset: -8,
		offsetString: 'UTC-8:00',
		name: 'PST',
		nameLong: 'Pacific Standard Time',
	},
	{
		offset: -7,
		offsetString: 'UTC-7:00',
		name: 'MST',
		nameLong: 'Mountain Standard Time',
	},
	{
		offset: -6,
		offsetString: 'UTC-6:00',
		name: 'CST',
		nameLong: 'Central Standard Time',
	},
	{
		offset: -5,
		offsetString: 'UTC-5:00',
		name: 'EST',
		nameLong: 'Eastern Standard Time',
	},
	{
		offset: -4.5,
		offsetString: 'UTC-4:30',
		name: 'VET',
		nameLong: 'Venezuelan Standard Time',
	},
	{
		offset: -4,
		offsetString: 'UTC-4:00',
		name: 'AST',
		nameLong: 'Atlantic Standard Time',
	},
	{
		offset: -3.5,
		offsetString: 'UTC-3:30',
		name: 'NST',
		nameLong: 'Newfoundland Standard Time',
	},
	{
		offset: -3,
		offsetString: 'UTC-3:00',
		name: 'AGT',
		nameLong: 'Argentina Standard Time',
	},
	{
		offset: -2,
		offsetString: 'UTC-2:00',
		name: 'BET',
		nameLong: 'Brazil Eastern Time',
	},
	{
		offset: -1,
		offsetString: 'UTC-1:00',
		name: 'CVT',
		nameLong: 'Cape Verde Time',
	},
	{
		offset: 0,
		offsetString: 'UTC+0:00',
		name: 'UTC',
		nameLong: 'Greenwich Mean Time',
	},
	{
		offset: 1,
		offsetString: 'UTC+1:00',
		name: 'ECT',
		nameLong: 'European Central Time',
	},
	{
		offset: 2,
		offsetString: 'UTC+2:00',
		name: 'EET',
		nameLong: 'Eastern European Time',
	},
	{
		offset: 3,
		offsetString: 'UTC+3:00',
		name: 'EAT',
		nameLong: 'Eastern African Time',
	},
	{
		offset: 3.5,
		offsetString: 'UTC+3:30',
		name: 'IRST',
		nameLong: 'Iran Standard Time',
	},
	{
		offset: 4,
		offsetString: 'UTC+4:00',
		name: 'GET',
		nameLong: 'Georgia Standard Time',
	},
	{
		offset: 4.5,
		offsetString: 'UTC+4:30',
		name: 'IRDT',
		nameLong: 'Iran Daylight Time',
	},
	{
		offset: 5,
		offsetString: 'UTC+5:00',
		name: 'PLT',
		nameLong: 'Pakistan Lahore Time',
	},
	{
		offset: 5.5,
		offsetString: 'UTC+5:30',
		name: 'IST',
		nameLong: 'India Standard Time',
	},
	{
		offset: 5.75,
		offsetString: 'UTC+5:45',
		name: 'NPT',
		nameLong: 'Nepal Time',
	},
	{
		offset: 6,
		offsetString: 'UTC+6:00',
		name: 'BST',
		nameLong: 'Bangladesh Standard Time',
	},
	{
		offset: 6.5,
		offsetString: 'UTC+6:30',
		name: 'CCT',
		nameLong: 'Cocos Islands Time',
	},
	{
		offset: 7,
		offsetString: 'UTC+7:00',
		name: 'VST',
		nameLong: 'Vietnam Standard Time',
	},
	{
		offset: 8,
		offsetString: 'UTC+8:00',
		name: 'CTT',
		nameLong: 'China Taiwan Time',
	},
	{
		offset: 8.5,
		offsetString: 'UTC+8:30',
		name: 'PYT',
		nameLong: 'Pyongyang Time',
	},
	{
		offset: 8.75,
		offsetString: 'UTC+8:45',
		name: 'ACWST',
		nameLong: 'Australian Central Western Standard Time',
	},
	{
		offset: 9,
		offsetString: 'UTC+9:00',
		name: 'JST',
		nameLong: 'Japan Standard Time',
	},
	{
		offset: 9.5,
		offsetString: 'UTC+9:30',
		name: 'ACST',
		nameLong: 'Australian Central Standard Time',
	},
	{
		offset: 10,
		offsetString: 'UTC+10:00',
		name: 'AET',
		nameLong: 'Australia Eastern Time',
	},
	{
		offset: 10.5,
		offsetString: 'UTC+10:30',
		name: 'LHST',
		nameLong: 'Lord Howe Standard Time',
	},
	{
		offset: 11,
		offsetString: 'UTC+11:00',
		name: 'SBT',
		nameLong: 'Solomon Islands Time',
	},
	{
		offset: 12,
		offsetString: 'UTC+12:00',
		name: 'NZST',
		nameLong: 'New Zealand Standard Time',
	},
	{
		offset: 12.75,
		offsetString: 'UTC+12:45',
		name: 'CHAST',
		nameLong: 'Chatham Islands Standard Time',
	},
].sort((a, b) => (a.offset < b.offset ? -1 : a.offset > b.offset ? 1 : 0));
