import {createStyles} from '@material-ui/core/styles';

const style = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			overflow: 'visible',
		},
		section: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			marginTop: '1em',
			marginBottom: '1em',
			overflow: 'hidden',
		},
		sectionHeader: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			backgroundColor: theme.palette.background.contained,
			minHeight: '48px',
			paddingLeft: '2em',
			paddingRight: '2em',
			fontWeight: '600',
		},
		sectionContent: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			padding: '1em',
			backgroundColor: theme.palette.background.contained,
		},
		table: {
			outline: 'none',
			border: 'none',
			borderCollapse: 'separate',
			tableLayout: 'fixed',
			width: '100%',
		},
		tableHeaderRow: {
			height: '32px',
		},
		tableBodyRow: {
			height: '36px',
			outline: 'none',
			'&:hover': {
				backgroundColor: 'transparent !important',
			},
		},
		tableHeadCell: {
			fontFamily: 'Montserrat Medium',
			border: 0,
			'&:first-of-type': {
				width: '45%',
			},
			'&:last-of-type': {
				width: '25%',
				textAlign: 'right',
			},
			'&:not(:last-of-type):not(:first-of-type)': {
				width: '35%',
			},
		},
		tableBodyCellOverview: {
			borderBottomColor: theme.palette.color.informational,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			'&:last-of-type': {
				textAlign: 'right',
			},
		},
		tableBodyCell: {
			borderBottomColor: theme.palette.color.informational,
			'&:last-of-type': {
				textAlign: 'right',
			},
		},
		customTooltip: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			backgroundColor: '#fff',
			color: '#000',
			padding: '5px',
			maxHeight: '32px',
			borderStyle: 'solid',
			borderWidth: '1px',
			borderColor: '#fff',
			borderRadius: '5px',
		},
	});

export default style;
