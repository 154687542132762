import {createStyles, makeStyles} from '@material-ui/core/styles';

import React from 'react';
import TextField from '@material-ui/core/TextField';
import {useTranslate} from 'react-admin';

export const useCustomFieldStyle = makeStyles(theme =>
	createStyles({
		inputStyle: {
			backgroundColor: theme.palette.background.default,
			color: 'inherit',
			fontSize: 'inherit',
			paddingTop: '4px',
			paddingBottom: '4px',
			paddingLeft: '8px',
			paddingRight: '8px',
			height: '28px',
			display: 'block',
			width: '100%',
			outline: 'none',
			fontFamily: 'Montserrat Regular',
			'&::placeholder': {
				fontFamily: 'Montserrat Regular',
			},
			'&:-webkit-autofill': {
				transitionDelay: 'none',
				transitionProperty: 'background-color, color',
				'-webkit-text-fill-color': 'inherit',
				'-webkit-box-shadow': `0 0 0px 100px ${theme.palette.background.default} inset`,
				transition: `background-color ${theme.palette.background.default} ease-in-out 0s`,
				caretColor: 'inherit',
				fontSize: 'inherit',
				fontFamily: 'inherit',
			},
		},
	})
);

const TextInput = (props) => {
	props = {
		disabled: false,
		width: '100%',
		...props,
	};

	let inputProps = {};

	if (props.inputProps) {
		inputProps = props.inputProps;
		delete props.inputProps
	}

	const translate = useTranslate();

	const classes = useCustomFieldStyle(props);

	if (props.mui) {
		return <TextField {...props} />;
	}
	return (
		<div>
			<div style={{color: 'rgb(141, 141, 141)', marginBottom: '16px'}}>
				{props.label && translate(props.label)}
			</div>
			<input
				className={classes.inputStyle}
				style={{
					width: props.width,
					border: props.error ? '1px solid #DE3737' : 'none',
					borderRadius: '2px',
				}}
				{...props}
				{...inputProps}
			/>
			{props.helperText && (
				<div style={{marginTop: '4px'}}>{props.helperText}</div>
			)}
			{props.error && props.errorMessage &&  (
				<div style={{marginTop: '4px', color: '#DE3737'}}>{props.errorMessage}</div>
			)}
		</div>
	);
};

export default TextInput;
