import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {loadObject} from 'utils/Util';
import safariLogo from '../assets/safari-logo@2x.png';
import chromeLogo from '../assets/chrome-logo@2x.png';
import edgeLogo from '../assets/edge-logo@2x.png';
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import TSTheme from './Theme';

const browserLinks = {
	Chrome: 'https://www.google.com/chrome/',
	Edge: 'https://www.microsoft.com/en-us/edge/',
	Safari: 'https://www.apple.com/safari/',
};

const UnsupportedBrowser = () => {
	const [chromeHovered, setChromeHovered] = useState(false);
	const [edgeHovered, setEdgeHovered] = useState(false);
	const [safariHovered, setSafariHovered] = useState(false);

	const talespinLogo = loadObject('project')?.config?.images?.runwayLoginLogo;

	const onBrowserHover = browserName => {
		switch (browserName) {
			case 'chrome':
				setChromeHovered(!chromeHovered);
				break;
			case 'edge':
				setEdgeHovered(!edgeHovered);
				break;
			case 'safari':
				setSafariHovered(!safariHovered);
				break;
			default:
				// Nothing to do here.
				break;
		}
	};

	const browserClicked = (url) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<ThemeProvider theme={TSTheme}>
			<CssBaseline />
			<div
				style={{
					height: '100vh',
					margin: 0,
					padding: 0,
					overflow: 'hidden',
				}}
			>
				<div
					style={{
						marginTop: '20vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<div>
						<img alt='Talespin Logo' src={talespinLogo} width='188' />
					</div>
					<div style={{margin: '1.25rem auto', marginTop: '8vh'}}>
						<Typography
							variant='h5'
							align='center'
							style={{
								fontSize: '24px',
								fontFamily: 'Archivo Bold',
							}}
						>
							Unsupported Browser
						</Typography>
					</div>
					<div>
						<Typography
							align='center'
							style={{
								fontFamily: 'Montserrat Regular',
								fontSize: '18px',
							}}
						>
							You’re trying to access Dashboard through a web browser we don’t
							support.
						</Typography>
						<Typography
							align='center'
							style={{
								fontFamily: 'Montserrat Regular',
								fontSize: '18px',
							}}
						>
							Please use the latest version of one of the following browsers:
						</Typography>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-around',
								marginTop: '1.375rem',
                                marginLeft: '1rem',
                                marginRIght: '1rem'
							}}
						>
							<div
								style={{
									display: 'inherit',
									flexDirection: 'column',
                                    color: edgeHovered ? '#346dec' : 'inherit',
                                    cursor: edgeHovered ? 'pointer': 'inherit'
								}}
								onClick={() => browserClicked(browserLinks.Edge)}
								onMouseEnter={() => onBrowserHover('edge')}
								onMouseLeave={() => onBrowserHover('edge')}
							>
								<div
									style={{
										display: 'inherit',
										flexDirection: 'column',
										'&:hover': {
											color: '#FFC440',
											cursor: 'pointer',
										},
										margin: '1rem auto',
										textAlign: 'center',
										borderRadius: '100%',
										width: '96px',
										height: '96px',
									}}
								>
									<img alt='Microsoft Edge logo' src={edgeLogo} width='96' />
								</div>
								<Typography
									align='center'
									style={{
										fontFamily: 'Archivo Semi Bold',
										fontSize: '16px',
									}}
								>
									Edge
								</Typography>
							</div>
							<div
								style={{
									display: 'inherit',
									flexDirection: 'column',
                                    color: chromeHovered ? '#346dec' : 'inherit',
                                    cursor: chromeHovered ? 'pointer': 'inherit'
								}}
								onClick={() => browserClicked(browserLinks.Chrome)}
								onMouseEnter={() => onBrowserHover('chrome')}
								onMouseLeave={() => onBrowserHover('chrome')}
							>
								<div
									style={{
										display: 'inherit',
										flexDirection: 'column',
										'&:hover': {
											color: '#FFC440',
											cursor: 'pointer',
										},
										margin: '1rem auto',
										textAlign: 'center',
										borderRadius: '100%',
										width: '96px',
										height: '96px',
									}}
								>
									<img alt='Google Chrome logo' src={chromeLogo} width='96' />
								</div>
								<Typography
									align='center'
									style={{
										fontFamily: 'Archivo Semi Bold',
										fontSize: '16px',
									}}
								>
									Chrome
								</Typography>
							</div>
							<div
								style={{
									display: 'inherit',
									flexDirection: 'column',
                                    color: safariHovered ? '#346dec' : 'inherit',
                                    cursor: safariHovered ? 'pointer': 'inherit'
								}}
								onClick={() => browserClicked(browserLinks.Safari)}
								onMouseEnter={() => onBrowserHover('safari')}
								onMouseLeave={() => onBrowserHover('safari')}
							>
								<div
									style={{
										display: 'inherit',
										flexDirection: 'column',
										'&:hover': {
											color: '#FFC440',
											cursor: 'pointer',
										},
										margin: '1rem auto',
										textAlign: 'center',
										borderRadius: '100%',
										width: '96px',
										height: '96px',
									}}
								>
									<img alt='Apple Safari logo' src={safariLogo} width='96' />
								</div>
								<Typography
									align='center'
									style={{
										fontFamily: 'Archivo Semi Bold',
										fontSize: '16px',
									}}
								>
									Safari
								</Typography>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ThemeProvider>
	);
};

export default UnsupportedBrowser;
