import 'date-fns';

import {
	CREATE,
	GET_LIST,
	UPDATE,
	useDataProvider,
	useTranslate,
} from 'react-admin';
import {GET, GET_PROFILE_INFO} from '../../services/customActions';
import React, {useEffect, useState} from 'react';
import {
	getUserInfo,
	timezones_daylight,
	timezones_standard,
} from '../../utils/Data';

import Box from '../layout/Box';
import {Credentials} from './Credentials';
import {CustomDialog} from '../index';
import {Form} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import Grid from '../layout/Grid';
import {Information} from './Information';
import LoadingList from '../loading/LoadingList';
import MUIButton from '@material-ui/core/Button';
import ProgressReport from './ProgressReport';
import ResetPasswordDialog from './ResetPasswordDialog';
import Section from '../layout/Section';
import {Settings} from './Settings';
import get from 'lodash/get';
import {loadObject, validateEmail} from '../../utils/Util';
import moment from 'moment';
import LoadingInfo from "components/loading/LoadingInfo";
import EditIcon from 'assets/EditIcon';

const Index = ({isOpen, setIsOpen, isEdit}) => {
	let formReference;
	const translate = useTranslate();

	const dataProvider = useDataProvider();

	const [state, setState] = useState({
		profileInfo: {},
		isSubscribed: false,
		runwayOrgConfig: loadObject('runwayOrgConfig'),
		jobRoleList: [],
		isLoading: true,
	});

	const [isEditing, setIsEditing] = useState(isEdit);

	const [isUpdating, setIsUpdating] = useState(false);

	// const [sendLabReports, setSendLabReports] = useState();

	const [isSubscribe, setIsSubscribe] = useState(state.isSubscribed);

	const [isValidLmsId, setIsValidLmsId] = useState(true);

	const [isValidEmail, setIsValidEmail] = useState(true);

	const [jobRole, setJobRole] = useState(
		state.profileInfo?.information?.jobRole
	);

	const [lang, setLang] = useState(state.profileInfo?.settings?.language);

	const [timeZone, setTimeZone] = useState(
		state.profileInfo?.settings?.timeZone
	);

	const [isResetCredential, setIsResetCredential] = useState(false);

	const [uniqueLmsId, setUniqueLmsId] = useState(true);

	let lmsIdCharacterLimit = 128;

	if (state.runwayOrgConfig?.config?.lmsIdCharacterMax) {
	  lmsIdCharacterLimit = Number(state.runwayOrgConfig.config.lmsIdCharacterMax) || 128;
	}
	
	const resetDefaultState = {
		open: false,
		mode: null,
		authType: null,
		newPassword: null,
		confirmPassword: null,
	};

	const [resetCredential, setResetCredential] = useState(resetDefaultState);

	const isProgressReports =
		get(state, 'runwayOrgConfig.config.enableProgressReports') === 'true' &&
		(getUserInfo().isAdmin || getUserInfo().isSuper);

	const availableLanguages = [{id: 'en', name: translate('English (EN)')}];

	const availableTimezones = (moment(new Date()).isDST()
		? timezones_daylight
		: timezones_standard
	).map(tz => {
		return {
			id: tz.offset,
			name: '(' + tz.offsetString + ') ' + tz.nameLong,
		};
	});

	const fetchData = async () => {
		setState(state => ({...state, isLoading: true}));
		try {
			const isSubscribed = await dataProvider(GET, 'isSubscribed', {});
			const profileInfo = await dataProvider(GET_PROFILE_INFO, 'profileInfo');
			let jobRoleList = Array.isArray(state.jobRoleList) ? state.jobRoleList : [];
			if (jobRoleList.length === 0) {
				jobRoleList = await dataProvider(
					GET_LIST,
					'getJobRoleListRunway',
					{}
				);
			}
			setState(state => ({
				...state,
				profileInfo: profileInfo.data,
				jobRoleList: jobRoleList.data,
				isSubscribed: isSubscribed.data.subscribed,
				isLoading: false,
			}));
		} catch (e) {
			setState(state => ({...state, isLoading: false}))
		}
	};

	const isUniqueLmsId = async (lmsId) => {
		try {
			const res = await dataProvider(GET, 'verifyLMSIdForProfile', {profileId: get(state, 'profileInfo.information.id'), lmsId: lmsId});
			const isValid = res.data.data.valid;
			setUniqueLmsId(isValid);
			setIsUpdating(isValid);
			return isValid;
		} catch(e) {
			console.log(e);
			setUniqueLmsId(false);
			setIsUpdating(false);
		}
	}

	useEffect(() => {
		if (isOpen) {
			fetchData();
		} else {
			setIsEditing(false);
			setIsValidLmsId(true);
			setIsValidEmail(true);
		}
	}, [isOpen, dataProvider]);

	useEffect(() => {
		// setSendLabReports(
		// 	state.profileInfo.information
		// 		? state.profileInfo.information.sendLabEmailReports === 'enabled'
		// 		: ''
		// );
		setJobRole(
			state.profileInfo.information ? state.profileInfo.information.jobRole : ''
		);
		setTimeZone(
			state.profileInfo.settings ? state.profileInfo.settings.timeZone : ''
		);
		setLang(
			state.profileInfo.settings ? state.profileInfo.settings.language : ''
		);
		setIsSubscribe(state.isSubscribed);
		formReference?.reset();
	}, [isEditing, state]);

	let handleFormSubmit = () => {};

	let handleCredentialFormSubmit = () => {};

	const handleChange = e => {
		setIsSubscribe(e.target.checked);
		dataProvider(CREATE, 'subscribeMonthlyReports', {
			data: {enabled: e.target.checked},
		});
	};

	const onSubmitInformation = async values => {
		setIsUpdating(true);
		const lmsId = values['lmsId'] ? values['lmsId'].trim() : '';
		if (
			get(state, 'profileInfo.information.lmsId') !== lmsId &&
			lmsId != null &&
			lmsId !== '' &&
			!(await isUniqueLmsId(lmsId))
		)
			return;
		let requestData = { ...state.profileInfo };

		// Remove authentication info to prevent password reset
		requestData.authentication = {};

		requestData.information.firstName = values['firstName']
			? values['firstName'].trim()
			: '';

		requestData.information.lastName = values['lastName']
			? values['lastName'].trim()
			: '';

		requestData.information.email = values['email']
			? values['email'].trim()
			: '';
		requestData.information.employeeId = values['employeeId']
			? values['employeeId'].trim()
			: '';

		requestData.information.lmsId = values['lmsId']
			? values['lmsId'].trim()
			: '';

		requestData.information.jobRole = jobRole ? jobRole : '';

		requestData.settings.language = lang;

		requestData.settings.timeZone = timeZone;

		requestData.information.name =
			requestData.information.firstName +
			' ' +
			requestData.information.lastName;

		requestData.id = get(state, 'profileInfo.information.id');

		// requestData.information.sendLabEmailReports = sendLabReports;

		await dataProvider(UPDATE, 'profileInfo', {
			id: get(state, 'profileInfo.information.id'),
			data: requestData,
		}).then(response => {
			setState(state => ({...state, profileInfo: response.data}));
			sessionStorage.setItem('editUser', response.data.information.name);
			const project = JSON.parse(sessionStorage.getItem('project')).id;
			const currentUserDetails = JSON.parse(
				sessionStorage.getItem(project + '-user')
			);
			sessionStorage.setItem(
				project + '-user',
				JSON.stringify({
					...currentUserDetails,
					email: response.data.information.email,
					timeZone: response.data.settings.timeZone
				})
			);
			const hashArray = window.location.hash.split('/');
			if (hashArray.includes('trainings') && hashArray.includes('show')) {
				window.location.reload();
			} else {
				setIsEditing(false);
				setIsUpdating(false);
				setIsEditing(false);
			}
		}).catch((e) => {
            console.error(e);
			setIsUpdating(false);
		});
	};

	const onCredentialSubmit = async values => {
		let requestData = {
			authentication: {[resetCredential.authType]: values.newPassword},
		};

		setIsResetCredential(false);

		await dataProvider(UPDATE, 'profileInfo', {
			id: get(state, 'profileInfo.information.id'),
			data: requestData,
		});
	};

	const openResetCredentialDialog = (authType, mode) => {
		setResetCredential(state => ({
			...state,
			open: true,
			mode: mode,
			authType: authType,
			newPassword: null,
			confirmPassword: null,
		}));
		setIsResetCredential(true);
	};

	const renderSubTitle = title => {
		return (
			<div style={{fontSize: '16px', fontFamily: 'Archivo Bold'}}>{title}</div>
		);
	};

	return (
		<div>
			<Box width={'300px'}>
				<CustomDialog
					title={'Edit User Information'}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					width={'646px'}
					actions={[
						{
							render: () => {
								return (
									<MUIButton
										color='secondary'
										variant='contained'
										onClick={() => {
											if (isEditing) {
												setIsEditing(false);
											} else {
												setIsOpen(false);
											}
										}}
									>
										{translate(isEditing ? 'Cancel' : 'Close')}
									</MUIButton>
								);
							},
						},
						{
							render: () => {
								return (
									<MUIButton
										color='primary'
										variant='contained'
										disabled={state.isLoading || isUpdating || !isValidLmsId || !isValidEmail}
										onClick={() => {
											if (isEditing) {
												handleFormSubmit();
											} else {
												setIsEditing(!isEditing);
											}
										}}
										startIcon= {!isEditing && <EditIcon style={{ fontSize: '16px', marginBottom: '2px'}}/>}
									>
										{translate(isEditing ? 'Save Changes' : 'Edit')}
									</MUIButton>
								);
							},
						},
					]}
				>
					{state.isLoading ? (
						<LoadingList />
					) : (
						isUpdating && <LoadingInfo text={'Updating...'} />
					)}
					{!state.isLoading && (
						<div style={{ display : isUpdating ? "none" : "block"}}>
							<Form
								initialValues={{
									email: get(state, 'profileInfo.information.email'),
									employeeId: get(state, 'profileInfo.information.employeeId'),
									firstName: get(state, 'profileInfo.information.firstName'),
									lastName: get(state, 'profileInfo.information.lastName'),
									lmsId: get(state, 'profileInfo.information.lmsId'),
									language: get(state, 'profileInfo.settings.language'),
									timeZone: get(state, 'profileInfo.settings.timeZone'),
								}}
								onSubmit={onSubmitInformation}
							>
								{({handleSubmit, form, submitting, pristine, values}) => {
									formReference = form;
									handleFormSubmit = handleSubmit;

									return (
										<Grid perRow={1} gridGap={'16px 0px'} padding={'0px'}>
											{renderSubTitle('Information')}
											<Section padding={'0px 0px 16px 0px'}>
												<Information
													isEditing={isEditing}
													isValidLmsId={isValidLmsId}
													isValidEmail={isValidEmail}
													state={state}
													jobRole={jobRole}
													setJobRole={setJobRole}
													// sendLabReports={sendLabReports}
													// setSendLabReports={setSendLabReports}
													uniqueLmsId={uniqueLmsId}
													lmsIdCharacterLimit={lmsIdCharacterLimit}
													isPackageIntegrationEnabled={
														state.runwayOrgConfig?.config
															?.packageIntegrationEnabled === 'true'
													}
												/>
												<OnChange name='email'>
													{value => {
														if (
															value && value != null &&
															!validateEmail(value)
														) {
															setIsValidEmail(false);
														} else {
															setIsValidEmail(true);
														}
													}}
												</OnChange>
												<OnChange name='lmsId'>
													{value => {
														if (
															value &&
															value.trim().length > 0 &&
															value.trim().length > lmsIdCharacterLimit
														) {
															setIsValidLmsId(false);
														} else {
															setIsValidLmsId(true);
														}
													}}
												</OnChange>
											</Section>
											{renderSubTitle('Authentication')}
											<Credentials
												state={state}
												openResetCredentialDialog={openResetCredentialDialog}
											/>
											{renderSubTitle('Settings')}
											<Section padding={'0px 0px 16px 0px'}>
												<Settings
													state={state}
													isEditing={isEditing}
													availableLanguages={availableLanguages}
													availableTimezones={availableTimezones}
													timeZone={timeZone}
													lang={lang}
													setTimeZone={setTimeZone}
													setLang={setLang}
												/>
											</Section>
											{renderSubTitle(
												isProgressReports ? 'Progress Reports' : null
											)}
											{isProgressReports ? (
												<ProgressReport
													checked={isSubscribe}
													onChange={handleChange}
													onClick={() => setIsEditing(true)}
												/>
											) : null}
										</Grid>
									);
								}}
							</Form>
						</div>
					)}
				</CustomDialog>
			</Box>

			<ResetPasswordDialog
				resetCredential={resetCredential}
				isResetCredential={isResetCredential}
				setIsResetCredential={setIsResetCredential}
				handleCredentialFormSubmit={handleCredentialFormSubmit}
				onCredentialSubmit={onCredentialSubmit}
				state={state}
			/>
		</div>
	);
};

export default Index;
