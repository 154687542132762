import React, {useEffect, useState} from 'react';
import DialogButtonCombo from 'components/dialogs/DialogButtonCombo_deprecated';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {useTheme} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {useDataProvider, withDataProvider} from 'react-admin';
import {refreshView} from 'ra-core';

const RemoveFromTeamButton = props => {
	const theme = useTheme();

	const dataProvider = useDataProvider();

	const [state, setState] = useState({
		error: false,
		teams: [],
		selectedTeamIds: new Set(),
	});

	useEffect(() => {
		setState({...state, mounted: true});

		return function cleanup() {
			setState({...state, mounted: false});
		};
	}, []);

	const handleSaveClick = e => {
		if (e) e.stopPropagation();

		var trainings = [props.record.id];
		var teamId = props.location.pathname.split('/')[2];

		handleRemoveTrainings({trainings, teamId});
	};

	const handleRemoveTrainings = async values => {
		const {refreshView} = props;
		dataProvider.delete(`teamsTraining`, {
			id: values.teamId,
			data: {trainings: values.trainings, teamId: values.teamId},
		});
		if (props.startPolling) props.startPolling();
		refreshView();
	};

	return (
		<DialogButtonCombo
			{...props}
			error
			label='Remove From Team'
			dialogTitle='Remove Module From Team?'
			record={props.record}
			dialogBody={
				<div style={{padding: '16px 0px'}}>
					<Typography>
						Do you want to remove the module{' '}
						<span style={{color: theme.palette.primary.main}}>
							{props.record.name}
						</span>{' '}
						from the team ?
						<br />
						<br />
						Users on team will lose access to module and its lessons.
					</Typography>
				</div>
			}
			buttonConfirmLabel={'Remove From Team'}
			dialogConfirmAction={handleSaveClick}
		/>
	);
};

const mapStateToProps = state => ({
	teams: state.admin.resources.teams.data,
});
const mapDispatchToProps = {
	refreshView,
};

RemoveFromTeamButton.propTypes = {
	dataProvider: PropTypes.func.isRequired,
	record: PropTypes.object,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDataProvider(RemoveFromTeamButton));
