import React from 'react';

const Button = ({
	disabled = false,
	variant,
	small = false,
	med = false,
	hide = false,
	width,
	onClick,
	children,
	maxWidth
}) => {
	const alt = variant === 'outlined';
	const error = variant === 'error';

	const buttonStyle = {
		textAlign: 'inherit',
		background: 'none',
		padding: 0,
		margin: 0,
		//border:  'none',
		border: alt ? '1px solid rgba(28, 118, 157, 1)' : 'none',
		color: disabled ? 'white' : alt ? '#FFC101' : 'black',
		cursor: disabled ? 'default' : 'pointer',
		fontFamily: 'Helvetica Now',
		backgroundColor: alt ? '#00132F' : error ? '#F14242' : '#346dec',
		//borderRadius: '4px',
		minWidth: small ? '96px' : med ? '148px' : width ? width : '240px',
		maxWidth: maxWidth ? maxWidth : null,
		height: alt ? '30px' : '28px',
		display: hide ? 'none' : 'inline-block',
		alignItems: 'center',
		fontSize: '13px',
	};

	return (
		<button disabled={disabled} onClick={onClick} style={buttonStyle}>
			<div
				style={{
					outline: 'none',
					padding: 0,
					margin: 0,
					flex: 1,
					textAlign: 'center',
				}}
				tabIndex='-1'
			>
				{children}
			</div>
		</button>
	);
};

export default Button;
