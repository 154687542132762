import generateModel from './model';
import { generateOptions, optionsChanged } from './options';

const LessonMap = {
    generateModel,
    generateOptions,
    optionsChanged
}

export default LessonMap;
