import React, {useState, useEffect} from 'react';
import {withDataProvider, fetchStart, fetchEnd} from 'react-admin';
import {refreshView, translate, useDataProvider} from 'ra-core';
import compose from 'recompose/compose';
import classnames from 'classnames';
import {connect} from 'react-redux';
import get from 'lodash/get';

import {Text} from 'components';
import TSTheme from 'components/Theme';
import DialogTitle from 'components/dialogs/DialogTitle';
import {getUserInfo} from 'utils/Data';

import {
	Card,
	Checkbox,
	Tooltip,
	Typography,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
} from '@material-ui/core';
import {Info, CheckCircle, Link} from '@material-ui/icons';
import {createStyles, withStyles} from '@material-ui/core/styles';
import { useDispatch } from "react-redux";

import BrowserIcon from 'assets/MiniCardIcons/BrowserIcon.svg';
import UserHeadset from 'assets/MiniCardIcons/UserHeadset.svg';
import { GET_PROFILE_INFO, GET_USER_CPD_URLS } from "services/customActions";
import { copyToClipboard, getOrgId, loadObject, openNewTabWithPromise, writeTextAsync } from "utils/Util";
import LoadingList from "components/loading/LoadingList";
import FadeOut from 'components/FadeOut';
import OpenNewTabIcon from 'assets/OpenNewTabIcon';

const styles = () =>
	createStyles({
		disabledDiv: {
			pointerEvents: 'none',
			opacity: '0.3',
		},
		iconText: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			fontFamily: 'Montserrat Bold',
		},
		infoIcon: {
			padding: '9px',
			fontSize: '40px',
			color: 'gray',
		},
		fields: {
			display: 'flex',
			alignItems: 'center',
			paddingBottom: '5px',
			'& > *:first-child': {
				flex: 1,
				'& > *:first-child': {
					marginBottom: '0 !important',
				},
			},
		},
	});

const TalespinWebAccess = ({record, classes, onClose, trainingData}) => {
	const tsaWebGLEnabled = loadObject('runwayOrgConfig')?.config?.tsaWebGLEnabled;
	const streamingEnabled = loadObject('runwayOrgConfig')?.config?.tsaStreamingEnabled;

	const [selectedOptions, setSelectedOptions] = useState(!record.streamingEnabled ? ['vr'] : []);

    const [loading, setIsLoading] = useState(false);

	const [profileInfoLoading, setProfileInfoLoading] = useState(false);

	const [showIcon, setShowIcon] = useState(false);

	const [profileInfo, setProfileInfo] = useState(null);

	const [resetKey, setResetKey] = useState(0);

	const dataProvider = useDataProvider();

	const dispatch = useDispatch();

	useEffect(() => {
		fetchProfileInfo();
	}, []);

	const fetchProfileInfo = async () => {
		setProfileInfoLoading(true);
		await dataProvider(GET_PROFILE_INFO, 'profileInfo')
		.then(res=> {
			setProfileInfo(res?.data);
			setProfileInfoLoading(false);
		}).catch(e => {
			console.error(e);
			setProfileInfoLoading(false);
		})
	}

	const copyLink = () => {
		setShowIcon(false);
		setIsLoading(true);
		dispatch(fetchStart());
		writeTextAsync(
			new Promise((resolve, reject) => {
				dataProvider(GET_USER_CPD_URLS, 'getUserURLs', {
					deliveryOption: selectedOptions,
					appKeyName: trainingData.appKeyName,
					orgId: getOrgId()
				})
					.then(result => {
						setShowIcon(true);
						setResetKey(resetKey + 1);
						resolve(result?.tsaStreamingUrlNoToken);
					})
					.catch(e => {
						reject(e);
					})
					.finally(() => {
						setIsLoading(false);
						dispatch(fetchEnd());
					});
			})
		);
	}

	const authLinkFetched = () => {
		dispatch(fetchEnd());
		setIsLoading(false);
	}
	  
	const fetchData = async() => {
		const promise = dataProvider(GET_USER_CPD_URLS, 'getUserURLs', {
				deliveryOption: selectedOptions,
				appKeyName: trainingData.appKeyName,
				orgId: getOrgId()
			});
		setShowIcon(false);
		setIsLoading(true);
		dispatch(fetchStart());
		openNewTabWithPromise(promise, 'tsaStreamingUrl', authLinkFetched)
	};

	const getUserName = () => {
		const deafultUserName = getUserInfo()?.email ?? getUserInfo()?.name ?? '';
	  
		let userName = deafultUserName;
	  
		const authType = get(loadObject('project'), 'config.authType');
	  
		switch (authType) {
		  case 'universal':
			userName = profileInfo?.authentication?.universal;
			break;
		  case 'email':
			userName = profileInfo?.authentication.email;
			break;
		  case 'summaryExternalId':
			userName = profileInfo?.authentication?.summaryExternalId;
			break;
		  case 'external':
			userName = profileInfo?.authentication?.external;
			break;
		  default:
			break;
		}
	  
		if (!userName || userName.trim().length === 0) {
		  userName = deafultUserName;
		}
	  
		return userName;
	}

	const handleCheckboxChange = id => {
		let selected = [...selectedOptions];

		if (selected.includes(id)) {
			const filteredOptions = selected.filter(item => item !== id);
			setSelectedOptions(filteredOptions);
		} else {
			selected.push(id);
		    setSelectedOptions(selected);
		}
	};

	
	const options = [
		{
			displayName: 'VR Application',
			id: 'vr',
			icon: UserHeadset,
			isEnabled: true,
		},
		{
			displayName: 'Web Application',
			id: 'browser',
			icon: BrowserIcon,
			isEnabled:
				(tsaWebGLEnabled && !streamingEnabled) ||
				(streamingEnabled && !tsaWebGLEnabled) ||
				(tsaWebGLEnabled && streamingEnabled),
		},
	];

	return (
		<div>
			<Dialog
				open={true}
				onClose={onClose}
				aria-label={'create-deep-link'}
                maxWidth={"md"}
			>
				<DialogTitle onClose={onClose}>
					<Text
						style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
						label={'Talespin App Access'}
					/>
				</DialogTitle>
				<DialogContent style={{ width: "850px", paddingBottom: "20px" }}>
					{!profileInfoLoading && <>
						<Typography style={{ paddingTop: '20px', fontFamily: "Montserrat Bold" }}>
								Select which Talespin App platforms you wish the learner to have
								access to when clicking on the link created below
                        </Typography>
						<Typography style={{ paddingBottom: '20px'}}>
							Selecting the platforms below will configure a special link which
							restricts access based on the selected platforms below. Once the
							desired platforms are selected, you can click on the "Take
							Training as {getUserName()}" link to access the training as
							yourself. Do not provide this link to learners as they will be
							able to log in as you. If you would like to provide this link to
							learners select the Copy Link to Provide to Learners button and
							send this link to your learners. NOTE: Anyone who receives this
							link will not need to be on a team with access to this training in
							order to take the training.
						</Typography>

						<div style={{display: 'flex', columnGap: '20px', justifyContent: 'center'}}>
							{options.map(option => {
								const isDisabled = !option.isEnabled;

								return (
									<Card
										key={option.id}
										style={{
											width: '250px',
											height: '150px',
											border: `2px ${
												selectedOptions.includes(option.id)
													? TSTheme.palette.primary.main
													: TSTheme.palette.secondary.main
											} solid`,
										}}
									>
										<div
											style={{
												display: 'flex',
											}}
										>
											{!isDisabled && (
												<Checkbox
													size='small'
													checked={selectedOptions.includes(option.id)}
													onChange={() => handleCheckboxChange(option.id)}
													className={isDisabled ? classes.disabledDiv : ''}
												/>
											)}

											{isDisabled && option.id === 'browser' && (
												<Tooltip
													title={
														'Contact your administrator to enable Web Streaming for your Organization.'
													}
													placement='top'
												>
													<Info
														className={classes.infoIcon}
														style={{marginLeft: 'auto'}}
													/>
												</Tooltip>
											)}
										</div>
										<div
											className={classnames({
												[classes.iconText]: true,
											})}
											style={{marginTop: option.id === 'desktop' ? '38px' : 0}}
										>
											<img
												style={{width: '40px', height: '40px'}}
												src={option.icon}
												alt={`${option.name}-icon`}
												className={classnames({
													[classes.disabledDiv]: isDisabled,
												})}
											/>
											<div
												className={classnames({
													[classes.disabledDiv]: isDisabled,
												})}
											>
												{option.displayName}
											</div>
											{isDisabled && (
												<div style={{marginTop: '-50px'}}>
													<Typography style={{fontFamily: 'Montserrat Bold'}}>
														Not Enabled
													</Typography>
												</div>
											)}
											{/* {option.id === 'desktop' && (
												<div style={{marginTop: '-50px'}}>
													<Typography style={{fontFamily: 'Montserrat Bold'}}>
														Coming Soon
													</Typography>
												</div>
											)} */}
										</div>
									</Card>
								);
							})}
						</div>
						{selectedOptions.length > 0 && <div style={{display: 'flex', flexDirection: "column", backgroundColor: "#ecf4f6", padding: "16px 0px 16px 16px", margin: "16px 0px"}}>
							<Typography style={{fontFamily: 'Montserrat Bold'}}>
								Generated Link:
							</Typography>

							<div style={{display: 'flex', padding: "8px 0px 20px 0px", alignItems: 'center' }}>
								<Button
									onClick={() => fetchData()}
									variant='contained'
									color={'primary'}
									label={'Close'}
									style={{textTransform: 'none', fontSize: "13px"}}
									disabled={loading}
									startIcon={<OpenNewTabIcon style={{ fontSize: '16px' }}/>}
								>
									Take Training as {getUserName()}
								</Button>
								<Button
									onClick={() => copyLink()}
									variant='contained'
									color={'primary'}
									label={'Close'}
									style={{textTransform: 'none', marginLeft: "10px", fontSize: "13px"}}
									disabled={loading}
								>
									<Link style={{ paddingRight: "5px" }}/> Copy Link to Provide to Learners***
								</Button>

								{showIcon && 
									<FadeOut onHidden={() => setShowIcon(false)} id={'web-access'}>
										<div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
											<CheckCircle style={{ color: "#89c32d", marginRight: "5px" }} />
											<i style={{ fontFamily: "Montserrat Medium", paddingTop: "1px" }}>Link Copied</i>
										</div>
									</FadeOut>
								}
							</div>

							<Typography>
								***People who access this link will need a user account set up in
								the platform before they can take training. Please make sure
								you've set up the appropriate user accounts before providing
								this link to users.
							</Typography>
						</div>}
					</>}
					{profileInfoLoading && <LoadingList />}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={onClose}
						variant='contained'
						color={'secondary'}
						label={'Close'}
						style={{textTransform: 'none'}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		form: state.form,
	};
}

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withStyles(styles),
	withDataProvider
);

export default enhance(TalespinWebAccess);
