import React, {useEffect, useState} from 'react';
import {AppVersion} from 'components';
import {Redirect} from 'react-router';
import Show from '../../components/Show/Show';
import Header from '../../components/Show/Header';
import Content from '../../components/Show/Content';
import Footer from '../../components/Show/Footer';
import Actions from '../../components/Actions';
import Label from '../../components/Label';
import {Field, Form} from 'react-final-form';
import get from 'lodash/get';
import Box from '../../components/layout/Box';
import LoadingText from '../../components/loading/LoadingText';
import {useHistory} from 'react-router-dom';
import {useCustomFieldStyle} from '../../components/TextInput';
import MUIButton from '@material-ui/core/Button';
import LoadingInfo from '../../components/loading/LoadingInfo';
import TSIcon from 'assets/TalespinIconDark.svg';
import {Divider} from '@material-ui/core';

const ForgotPasswordPage = () => {
	const project = JSON.parse(sessionStorage.getItem('project'));

	const [mode, setMode] = useState('resetting');
	const [mainLogo, setMainLogo] = useState();

	useEffect(() => {
		var data = JSON.parse(sessionStorage.getItem('project')).config;

		if (data && data.images) setMainLogo(data.images.runwayLoginLogo);
	}, []);

	if (
		get(project, 'config.authType') !== 'email' &&
		get(project, 'config.authType') !== 'universal'
	) {
		return <Redirect to={'login'} />;
	}

	const handleForgotPassword = values => {
		if (!values || !values.email) {
			return;
		}

		setMode('loading');

		const email = values.email;

		const url = get(project, 'config.emailAuth.resetPassword.hook');
		const secret = get(project, 'config.emailAuth.resetPassword.secret');

		const body =
			get(project, 'config.authType') === 'universal'
				? JSON.stringify({username: email})
				: JSON.stringify({email: email});

		fetch(url, {
			method: 'POST',
			headers: {'Content-Type': 'application/json', 'x-bc-secret': secret},
			body: body,
		}).then(res => {
			if (res) {
				if (res.status === 200) {
					setMode('success');
				} else {
					setMode('failure');
				}
			}
		});
	};

	let handleForgotPasswordRef = () => {};

	return (
		<Background>
			<Body>
				<Box height={'150px'}>
					<LoginLogo mainLogo={mainLogo} />
				</Box>
				<div style={{flex: 1, margin: 'auto', width: '308px', height: '368px'}}>
					<Show>
						<Head style={{fontFamily: 'Montserrat Medium'}}>
							Welcome to Talespin Platform
						</Head>
						<Divider style={{marginRight: '20px', marginLeft: '20px'}} />
						<Header
							style={{
								fontFamily: 'Montserrat Medium',
								paddingBottom: '50px',
								paddingTop: '50px',
							}}
						>
							Forgot password?
						</Header>

						{mode === 'resetting' && (
							<ResetEmail
								handleForgotPassword={handleForgotPassword}
								handleForgotPasswordRef={handleForgotPasswordRef}
							/>
						)}

						{mode !== 'resetting' && (
							<div>
								{mode === 'loading' ? (
									<ResetLoading />
								) : mode === 'success' ? (
									<ResetSuccess />
								) : mode === 'failure' ? (
									<Box height={'130px'}>An error occurred</Box>
								) : (
									<Box height={'130px'} />
								)}
							</div>
						)}
					</Show>
				</div>
				<div style={{height: '16px'}} />
				<AppVersion icon={TSIcon} />
			</Body>
		</Background>
	);
};

const LoginLogo = ({mainLogo}) => {
	if (mainLogo) {
		return (
			<img style={{width: '234px', margin: 'auto'}} src={mainLogo} alt='logo' />
		);
	} else {
		return <LoadingInfo />;
	}
};

const ResetLoading = () => {
	return (
		<div>
			<Box height={'130px'}>
				<LoadingText />
			</Box>
			<Footer />
		</div>
	);
};

const ResetSuccess = () => {
	const history = useHistory();
	const project = JSON.parse(sessionStorage.getItem('project'));

	const returnToLogin = () => {
		history.push('/login');
	};

	const resetPasswordText =
		get(project, 'config.authType') === 'universal'
			? 'Password reset email sent. Contact your administrator to help you reset your password if you did not receive a password reset email.'
			: 'Password reset email sent';

	return (
		<div>
			<Box height={'130px'}>{resetPasswordText}</Box>

			<Footer>
				<Actions>
					<MUIButton
						small={true}
						color={'primary'}
						variant={'contained'}
						onClick={returnToLogin}
					>
						Done
					</MUIButton>
				</Actions>
			</Footer>
		</div>
	);
};

const ResetEmail = ({handleForgotPassword, handleForgotPasswordRef}) => {
	const history = useHistory();
	const project = JSON.parse(sessionStorage.getItem('project'));

	const {inputStyle} = useCustomFieldStyle();

	const returnToLogin = () => {
		history.push('/login');
	};

	const labelText =
		get(project, 'config.authType') === 'universal'
			? 'Enter your username'
			: 'Enter your login email address';

	return (
		<Form
			onSubmit={handleForgotPassword}
			initialValues={{
				email: '',
			}}
			validate={values => {
				const errors = {};

				if (
					!values.email ||
					!values.email.length > 0 ||
					(get(project, 'config.authType') !== 'universal' &&
						/* eslint-disable no-control-regex */
						!values.email.match(
							/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
						))
				) {
					errors.email = 'Enter a valid email address.';
					return errors;
				}
				return errors;
			}}
		>
			{({handleSubmit, submitFailed, form, submitting, pristine, values}) => {
				handleForgotPasswordRef = handleSubmit;

				const placeHolderText =
					get(project, 'config.authType') === 'universal'
						? 'Enter your username'
						: 'Enter your email';

				return (
					<div>
						<form onSubmit={handleSubmit}>
							<Content>
								<div style={{height: '80px'}}>
									<Field name='email'>
										{({input, meta}) => (
											<div>
												<Label label={labelText} />
												<input
													{...input}
													type='string'
													className={inputStyle}
													placeholder={
														meta.submitFailed && meta.error
															? placeHolderText
															: null
													}
													style={{
														height: '40px',
														border:
															meta.submitFailed && meta.error
																? '1px solid rgba(207, 0, 15, 1)'
																: 'none',
													}}
													autoFocus={true}
												/>

												{meta.submitFailed &&
													meta.error &&
													input.value.length > 0 && (
														<div style={{paddingTop: '16px', color: 'red'}}>
															{meta.error}
														</div>
													)}
											</div>
										)}
									</Field>
								</div>
							</Content>
						</form>
						<Footer>
							<div
								style={{
									paddingTop: '14px',
									paddingBottom: '14px',
									position: 'relative',
									top: '15px',
								}}
							>
								<Actions>
									<MUIButton
										color={'secondary'}
										variant={'contained'}
										onClick={returnToLogin}
									>
										Cancel
									</MUIButton>
									<MUIButton
										color={'primary'}
										variant={'contained'}
										onClick={handleSubmit}
									>
										Send Reset Email
									</MUIButton>
								</Actions>
							</div>
						</Footer>
					</div>
				);
			}}
		</Form>
	);
};

const Background = ({children}) => {
	return (
		<div
			style={{
				flexDirection: 'column',
				display: 'flex',
				height: '100vh',
				width: '100vw',
				alignItems: 'center',
				justifyContent: 'flex-start',
				background: 'inherit',
				position: 'absolute',
				left: 0,
			}}
		>
			{children}
		</div>
	);
};

const Head = ({children}) => {
	return (
		<div
			style={{
				MozBorderRadiusTopleft: '4px',
				MozBorderRadiusTopright: '4px',
				height: '68px',
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					fontSize: '16px',
					flex: 1,
					textAlign: 'center',
					fontFamily: 'Montserrat Medium',
				}}
			>
				{children}
			</div>
		</div>
	);
};

const Body = ({children}) => {
	return (
		<div
			style={{
				flex: 1,
				margin: 'auto',
				width: '308px',
			}}
		>
			{children}
		</div>
	);
};

export default ForgotPasswordPage;
