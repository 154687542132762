import React from 'react';

const ClickableArea = props => {
	return (
		<button
			onClick={props.onClick}
			style={{
				fontSize: 'inherit',
				textAlign: 'inherit',
				backgroundColor: 'none',
				background: 'none',
				padding: '0',
				margin: 0,
				border: 'none',
				cursor: 'pointer',
				fontFamily: 'inherit',
				width: props.width ? props.width :'auto',
				height: '100%',
				marginRight: '10px'
			}}
		>
			<div
				style={{
					outline: 'none',
					padding: 0,
					margin: 0,
				}}
				tabIndex='-1'
			>
				{props.children}
			</div>
		</button>
	);
};

export default ClickableArea;
