import {UPDATE} from 'react-admin';

export const ADD_USERS = 'ADD_USERS';
export const ADD_USER_TO_TEAMS = 'ADD_USER_TO_TEAMS';
export const ADD_USERS_TO_TEAM = 'ADD_USERS_TO_TEAM';
export const ADD_TRAININGS_TO_TEAM = 'ADD_TRAININGS_TO_TEAM';
export const DELETE_TRAININGS_FROM_TEAM = 'DELETE_TRAININGS_FROM_TEAM';
export const DELETE_USER_FROM_TEAMS = 'DELETE_USER_FROM_TEAMS';
export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_USER = 'DELETE_USER';
export const GET_STATS = 'GET_STATS';
export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const SET_PROFILE_INFO = 'SET_PROFILE_INFO';
export const GET_RUNWAY_ORG_CONFIG = 'GET_RUNWAY_ORG_CONFIG';
export const GET_GUIDE_URL = 'GET_GUIDE_URL';
export const GET_TEMPLATE_URL = 'GET_TEMPLATE_URL';
export const GET_LIST_RAW_PERFOMANCE = ' GET_LIST_RAW_PERFOMANCE';
export const DOWNLOAD_RAW_PERFOMANCE_LOGS = 'DOWNLOAD_RAW_PERFOMANCE_LOGS';
export const UPDATE_RAW_PERFOMANCE_LOGS_RUNWAY =
	'UPDATE_RAW_PERFOMANCE_LOGS_RUNWAY';
export const RAW_WATCHDOG_DOWNLOAD_RUNWAY = 'RAW_WATCHDOG_DOWNLOAD_RUNWAY';
export const GET = 'GET';
export const GET_FUTURE_TRAINING = 'GET_FUTURE_TRAINING';
export const GET_TRAINING = 'GET_TRAINING';

export const SET_DEFAULT_TEAM = 'SET_DEFAULT_TEAM';
export const UNSET_DEFAULT_TEAM = 'UNSET_DEFAULT_TEAM';
export const CLEAR_USER_DATA_FOR_TRAINING = 'CLEAR_USER_DATA_FOR_TRAINING';
export const EDIT_GENERATE_USER_CONFIG = 'EDIT_GENERATE_USER_CONFIG';
export const CREATE_GENERATE_USERS = 'CREATE_GENERATE_USERS';
export const DOWNLOAD_USER_GENERATED_INFO = 'DOWNLOAD_USER_GENERATED_INFO';

export const EXPORT_USER_TRAINING_DATA = 'EXPORT_USER_TRAINING_DATA';
export const IMPORT_USER_TRAINING_DATA = 'IMPORT_USER_TRAINING_DATA';
export const GET_GENERATE_USER_ACCESS = 'GET_GENERATE_USER_ACCESS';

export const GET_TRAINING_PACKAGE_INTEGRATION_STATUS =
	'GET_TRAINING_PACKAGE_INTEGRATION_STATUS';

export const GET_USER_ROLE_LIST = 'GET_USER_ROLE_LIST';

export const LOG_OUT = 'LOG_OUT';

export const S2S = 'S2S';

export const GET_USER_CPD_URLS = 'GET_USER_CPD_URLS';

// in src/User/UserActions.js

export const DISABLE_USER = 'DISABLE_USER';
export const disableUser = (id, data, basePath) => ({
	type: DISABLE_USER,
	payload: {id, data: {...data, status: 'disabled'}},
	meta: {fetch: UPDATE, resource: 'users'},
});
export const enableUser = (id, data, basePath) => ({
	type: DISABLE_USER,
	payload: {id, data: {...data, status: 'enabled'}},
	meta: {fetch: UPDATE, resource: 'users'},
});
export const dormantUser = (id, data, basePath) => ({
	type: DISABLE_USER,
	payload: {id, data: {...data, status: 'dormant'}},
	meta: {fetch: UPDATE, resource: 'users'},
});
