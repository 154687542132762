import React, {Component} from 'react';
import {GET_LIST, GET_ONE, translate, withDataProvider} from 'react-admin';
import {
	createStyles,
	MuiThemeProvider,
	withStyles,
} from '@material-ui/core/styles';
import {Button, Dialog, DialogActions, DialogContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {refreshView} from 'ra-core';

import {DialogTitle, EmbeddedList, Text} from 'components';
import {DialogListTheme} from 'components/Theme';
import PercentField from 'components/PercentField';
import {ADD_USER_TO_TEAMS} from 'services/customActions';

const myStyles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		normalText: {
			color: theme.palette.color.default,
		},
		importantText: {
			color: theme.palette.color.important,
		},
		divider: {
			width: '100%',
			borderColor: theme.palette.borderColor.default,
		},
		listWrapper: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'auto',
		},
		selectedInformation: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			padding: '10px',
		},
		saveButton: {
			backgroundColor: theme.palette.background.primaryButton,
			color: theme.palette.color.primaryButton,
			borderColor: theme.palette.borderColor.primaryButton,
			borderWidth: '1px',
			borderStyle: 'solid',
			borderRadius: '4px',
			'&:hover': {
				backgroundColor: theme.palette.background.primaryButton,
			},
		},
	});

class AddUserToTeamsDialog extends Component {
	state = {
		ready: false,
		currentUser: null,
		currentTeams: [],
		selectedTeams: [],
	};

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id || this.props.open !== prevProps.open) {
			this.setState(
				{ready: false, currentUser: null, currentTeams: [], selectedTeams: []},
				() => {
					this.fetchData();
				}
			);
		}
	}

	fetchData = async () => {
		const {id, dataProvider} = this.props;

		if (!id) {
			this.setState({ready: false, currentUser: null, currentTeams: []});
			return;
		}

		const {data: currentUser} = await dataProvider(GET_ONE, 'users', {id: id});
		const {data: currentTeams} = await dataProvider(GET_LIST, 'teamsforuser', {
			filter: {userId: id},
			pagination: {page: 1, perPage: 9999},
		});

		this.setState({ready: true, currentUser, currentTeams});
	};

	handleCloseClick = e => {
		if (e) e.stopPropagation();

		if (this.props.onClose) this.props.onClose();

		this.setState({
			ready: false,
			currentUser: null,
			currentTeams: [],
			selectedTeams: [],
		});
	};

	handleSaveClick = async e => {
		if (e) e.stopPropagation();

		const {dataProvider, refreshView} = this.props;
		const {selectedTeams} = this.state;

		const selectedTeamIds = [];
		selectedTeams.forEach(item => {
			if (item && item.id) {
				selectedTeamIds.push(item.id);
			}
		});

		await dataProvider(ADD_USER_TO_TEAMS, 'users', {
			data: {id: this.props.id, selectedIds: selectedTeamIds},
		}).then(() => {
			this.handleCloseClick();
			refreshView();
		});
	};

	handleTeamSelectionToggled = record => {
		const selectedTeams = [...this.state.selectedTeams];

		const targetTeam = selectedTeams.find(
			item => item != null && record != null && item.id === record.id
		);

		if (targetTeam == null) {
			selectedTeams.push(record);
		} else {
			const idx = selectedTeams.indexOf(targetTeam);

			if (idx >= 0) {
				selectedTeams.splice(idx, 1);
			}
		}

		this.setState({selectedTeams});
	};

	render() {
		const {classes, translate, id, open} = this.props;
		const {ready, currentUser, currentTeams, selectedTeams} = this.state;

		const selectedTeamIds = [];
		selectedTeams.forEach(item => {
			if (item && item.id) {
				selectedTeamIds.push(item.id);
			}
		});

		const currentTeamIds = [];
		currentTeams.forEach(item => {
			if (item && item.id) {
				currentTeamIds.push(item.id);
			}
		});

		return (
			<MuiThemeProvider theme={DialogListTheme}>
				<Dialog
					id={'add-user-to-team-' + id}
					fullWidth
					maxWidth='md'
					open={open && ready}
					onClose={this.handleCloseClick}
					aria-label={translate('Add User to Team')}
				>
					<DialogTitle onClose={this.handleCloseClick}>
						<Text
							style={{fontSize: '20px'}}
							label={translate('Add User to Team')}
						/>
					</DialogTitle>

					<DialogContent>
						<div className={classes.listWrapper}>
							<EmbeddedList
								embedded={true}
								title={null}
								resource={'teams'}
								columns={[
									{id: 'name', name: translate('Name'), sortable: true},
									{
										id: 'training',
										name: translate('Module'),
										sortable: true,
										align: 'center',
										isInverted: true,
									},
									{
										id: 'users',
										name: translate('Users'),
										sortable: true,
										align: 'center',
										isInverted: true,
									},
									{
										id: 'completionRate',
										name: translate('Completion'),
										sortable: true,
										align: 'center',
										render: record => {
											return (
												<PercentField record={record} source='completionRate' />
											);
										},
										isInverted: true,
									},
									{
										id: 'avgScore',
										name: translate('Avg Score'),
										sortable: true,
										align: 'center',
										render: record => {
											return <PercentField record={record} source='avgScore' />;
										},
										isInverted: true,
									},
								]}
								sort={{field: 'name', order: 'ASC'}}
								staticFilters={[{id: 'id', value: {$nin: currentTeamIds}}]}
								dynamicFilters={[
									{id: 'name', name: translate('Search'), type: 'safe_regex'},
								]}
								bulkActions={null}
								actions={null}
								rowClicked={null}
								selectedIds={selectedTeamIds}
								selectionToggled={this.handleTeamSelectionToggled}
								stripped={false}
							/>
						</div>

						{currentUser && selectedTeams.length > 0 && (
							<hr className={classes.divider} />
						)}
						{currentUser && selectedTeams.length > 0 && (
							<div className={classes.selectedInformation}>
								<label className={classes.importantText}>
									{currentUser.name}
								</label>
								<label className={classes.normalText}>
									&nbsp;{translate('will be added to')}&nbsp;
								</label>
								<label className={classes.importantText}>
									{selectedTeams.length}{' '}
									{selectedTeams.length > 1
										? translate('teams')
										: translate('team')}
								</label>
							</div>
						)}
					</DialogContent>

					<DialogActions>
						<Button
							variant={'outlined'}
							color='primary'
							onClick={() => this.handleSaveClick()}
						>
							Add to Team
						</Button>
					</DialogActions>
				</Dialog>
			</MuiThemeProvider>
		);
	}
}

AddUserToTeamsDialog.propTypes = {
	dataProvider: PropTypes.func.isRequired,
	id: PropTypes.any.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withStyles(myStyles),
	withDataProvider
);

export default enhance(AddUserToTeamsDialog);
