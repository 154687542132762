import {createStyles, makeStyles} from '@material-ui/core/styles';

import React from 'react';
import TextField from '@material-ui/core/TextField';
import {useTranslate} from 'react-admin';


const useStyles = makeStyles(props =>
	createStyles({
		inputStyle: {
			backgroundColor: '#00132F !important',
			color: 'inherit',
			fontSize: 'inherit',
			borderRadius: '4px',
			paddingTop: '4px',
			paddingBottom: '4px',
			paddingLeft: '8px',
			paddingRight: '8px',
			height: '28px',
			display: 'block',

			outline: 'none',
			'&:-webkit-autofill': {
				transitionDelay: 'none',
				transitionProperty: 'background-color, color',
				'-webkit-text-fill-color': 'white',
				'-webkit-box-shadow': '0 0 0px 100px #00122f inset',
				transition: 'background-color rgb(0, 18, 47, 1) ease-in-out 0s',
				caretColor: 'white',
				fontSize: 'inherit',
			},
		},
	})
);

const TextInput_Deprecated = props => {

	props = {disabled: false, forceBorder: false, width: '100%', ...props};

	const translate = useTranslate();

	const classes = useStyles(props);

	if (props.mui) {
		return <TextField {...props} />;
	}

	return (
		<div>
			<div style={{color: 'rgb(141, 141, 141)', marginBottom: '16px'}}>
				{translate(props.label)}
			</div>
			<input
				className={classes.inputStyle}
				style={{
					width: props.width,
					border:
					props.disabled
							? 'none'
							: '1px solid rgba(28, 118, 157, 1)',
				}}
				{...props}
			/>

			{props.helperText && (
				<div style={{marginTop: '4px'}}>{props.helperText}</div>
			)}
		</div>
	);
};

export default TextInput_Deprecated;
