import React, {useState} from 'react';
import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	MuiThemeProvider,
	Typography,
	withStyles,
} from '@material-ui/core';
import DialogListTheme from 'components/Theme';
import {DialogTitle, EmbeddedList, TextInput} from 'components';

const styles = theme =>
	createStyles({
		trainingName: {
			display: 'flex',
			flexDirection: 'column',
			margin: '12px 0px 12px 0px',
			'& span': {
				backgroundColor: theme.palette.background.default,
				padding: '5px',
				paddingLeft: '12px',
			},
		},
		dialogTitle: {
			fontSize: '20px',
			fontFamily: 'Archivo Bold',
		},
		dialogContent: {
			display: 'flex',
			flexDirection: 'column',
		},
		divider: {
			border: 0,
			borderBottom: '1px solid',
			color: theme.palette.borderColor.divider,
			width: '100%',
			height: '1px',
		},
	});

const AddTrainingCategoriesDailog = props => {
	const {classes, trainingName} = props;

	const prepareCategories = categories => {
		let result = [];

		categories.forEach(category => {
			if (category && category.trim().length > 0) result.push({name: category});
		});

		return result;
	};

	const [selectedCategories, setSelectedCategories] = useState(
		prepareCategories(props.selectedCategories)
	);

	const [addingCategories, setAddingCategories] = useState(false);

	const [newCategory, setNewCategory] = useState('');

	const availableCategories = prepareCategories(props.availableCategories);

	const handleCategorySelection = category => {
		const tempSelectedCategories = [...selectedCategories];

		const targetCategory = tempSelectedCategories.find(
			item => item.name === category.name
		);

		if (!targetCategory) {
			tempSelectedCategories.push(category);
		} else {
			const idx = tempSelectedCategories.indexOf(targetCategory);

			if (idx >= 0) {
				tempSelectedCategories.splice(idx, 1);
			}
		}

		setSelectedCategories([...tempSelectedCategories]);
	};

	const addNewCategory = () => {
		setSelectedCategories([...selectedCategories, {name: newCategory}]);
		handleAddNewCategoryDialogClose();
	};

	const handleUpdate = () => {
		props.onUpdate(selectedCategories);
	};

	const handleClose = () => {
		props.onClose();
	};

	const handleClearAll = () => {
		setSelectedCategories([]);
	};

	const handleAddNewCategoryDialogClose = () => {
		setAddingCategories(false);
		setNewCategory('');
	};

	const sortCompareFn = (a, b) => {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	};

	return (
		<div className={classes.root}>
			<MuiThemeProvider theme={DialogListTheme}>
				<Dialog
					id={'add-training-categories'}
					maxWidth='md'
					open={!addingCategories}
					onClose={() => handleClose()}
				>
					<DialogTitle onClose={() => handleClose()}>
						<Typography className={classes.dialogTitle}>
							Edit Module Categories
						</Typography>
					</DialogTitle>

					<DialogContent style={{minWidth: '400px'}}>
						<div className={classes.dialogContent}>
							<div className={classes.trainingName}>
								<span>
									<Typography>Module Name</Typography>
								</span>

								<Typography style={{paddingTop: '12px', paddingLeft: '12px'}}>
									{trainingName}
								</Typography>
							</div>

							<hr className={classes.divider} />

							{!addingCategories && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										padding: '12px 0px',
										gap: '10px',
									}}
								>
									{/* <Button
										style={{alignSelf: 'flex-end'}}
										color={'primary'}
										variant={'outlined'}
										onClick={() => setAddingCategories(true)}
									>
										Create New
									</Button> */}
									{/*https://talespin.atlassian.net/browse/RP-1449*/}

									<div style={{display: 'flex'}}>
										<div style={{width: '60%'}}>
											<EmbeddedList
												embedded={true}
												title={null}
												resource={availableCategories.sort(sortCompareFn)}
												columns={[
													{
														id: 'name',
														name: `Available Categories  (${availableCategories.length})`,
													},
												]}
												sort={{field: 'displayName', order: 'ASC'}}
												selectedIds={selectedCategories.map(
													category => category.name
												)}
												selectionToggled={result =>
													handleCategorySelection(result)
												}
												isPageChooser={false}
											/>
										</div>

										<div style={{width: '45%', paddingLeft: '10px'}}>
											<div style={{paddingBottom: '5px'}}>
												<EmbeddedList
													embedded={true}
													resource={selectedCategories.sort(sortCompareFn)}
													columns={[
														{
															id: 'name',
															name: (
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'space-between',
																		flex: '1 1 auto',
																		alignItems: 'center',
																		paddingLeft: '12px',
																	}}
																>
																	<span>
																		Categories to Display (
																		{selectedCategories.length})
																	</span>

																	<Button
																		color={'primary'}
																		variant={'outlined'}
																		style={{height: '24px'}}
																		onClick={() => handleClearAll()}
																	>
																		Clear All
																	</Button>
																</div>
															),
														},
													]}
													selectionToggled={result =>
														handleCategorySelection(result)
													}
													stripped={false}
													splitSelection={true}
												/>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => handleUpdate()}
							variant={'contained'}
							color={'primary'}
						>
							Update
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					id={'create-training-category'}
					maxWidth='md'
					open={addingCategories}
					onClose={() => handleAddNewCategoryDialogClose()}
				>
					<DialogTitle onClose={() => handleAddNewCategoryDialogClose()}>
						<Typography className={classes.dialogTitle}>
							Create New Module Category
						</Typography>
					</DialogTitle>

					<DialogContent style={{minWidth: '400px'}}>
						<div className={classes.dialogContent}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									padding: '12px',
								}}
							>
								<Typography style={{fontSize: '16px'}}>
									Enter a name for new category
								</Typography>

								<TextInput
									value={newCategory}
									onChange={e => setNewCategory(e.target.value)}
								></TextInput>
							</div>
						</div>
					</DialogContent>

					<DialogActions>
						<Button
							onClick={() => addNewCategory()}
							variant={'contained'}
							color={'primary'}
							disabled={newCategory.trim().length === 0}
						>
							Create
						</Button>
					</DialogActions>
				</Dialog>
			</MuiThemeProvider>
		</div>
	);
};

export default withStyles(styles)(AddTrainingCategoriesDailog);
