import React from 'react';
import {useTheme} from '@material-ui/core/styles';

const PanelInfo = ({children = null, title = null}) => {

	const theme = useTheme();
	return (
		<div
			style={{
				width: '100%',
				height: '220px',
				margin: 'auto',
				backgroundColor: theme.palette.background.contained,
				border: 'none',
				borderRadius: '0px',
				padding: '16px',
				position: 'relative',
				MozBoxShadow: '0px 5px 20px #00000026',
			    boxShadow: '0px 5px 20px #00000026',
			}}
		>
			{title && (
				<div
					style={{
						flex: '0 0 auto',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						marginBottom: '26px',
						fontFamily: 'Archivo Bold',
						fontSize: '20px',
					}}
				>
					{title}
				</div>
			)}

			{children}
		</div>
	);
};

export default PanelInfo;
