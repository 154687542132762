import bookMarkIcon from '../assets/MiniCardIcons/Lesson.svg';
import checkBoxIcon from '../assets/MiniCardIcons/Passed.svg';
import checkedFolderIcon from '../assets/MiniCardIcons/AttemptsToPass.svg';
import checkedStopWatch from '../assets/MiniCardIcons/TimePass.svg';
import folderIcon from '../assets/MiniCardIcons/Attempt.svg';
import inReviewIcon from '../assets/MiniCardIcons/InReview.svg';
import linegraphIcon from '../assets/MiniCardIcons/Progress.svg';
import organizationIcon from '../assets/MiniCardIcons/Organization.svg';
import publishedIcon from '../assets/MiniCardIcons/Published.svg';
import peopleIcon from '../assets/MiniCardIcons/Teams.svg';
import personIcon from '../assets/MiniCardIcons/User.svg';
import pieChart from '../assets/MiniCardIcons/Chart.svg';
import skillPointsIcon from '../assets/MiniCardIcons/SkillPoints.svg';
import skillsIcon from '../assets/MiniCardIcons/Skills.svg';
import starIcon from '../assets/MiniCardIcons/Aced.svg';
import starredStopWatch from '../assets/MiniCardIcons/TimeAce.svg';
import stopWatchIcon from '../assets/MiniCardIcons/Time.svg';
import userHMD from '../assets/MiniCardIcons/TrainingModule.svg';
import DraftIcon from '../assets/MiniCardIcons/DraftIcon.svg';
import LicencedIcon from '../assets/MiniCardIcons/licensed.svg';
import ModuleIcon from '../assets/MiniCardIcons/Modules.svg';

const icons = {
	numTeams: peopleIcon,
	numTraining: userHMD,
	numLessons: bookMarkIcon,
	numTasksAttemptsPassed: checkedFolderIcon,
	numLessonsPassed: checkBoxIcon,
	numLessonsAced: starIcon,
	totalTime: stopWatchIcon,
	numUser: personIcon,
	numUsers: peopleIcon,
	numManagers: personIcon,
	mau: pieChart,
	readyForMore: peopleIcon,
	inNeedOfTraining: peopleIcon,
	completionRate: checkBoxIcon,
	teamAverage: checkBoxIcon,
	assignedLessons: bookMarkIcon,
	totalAttempts: folderIcon,
	lessonsPassed: checkBoxIcon,
	lessonsAced: starIcon,
	totalTimeSec: stopWatchIcon,
	attemptsTillFirstPass: folderIcon,
	attemptsTillFirstAce: folderIcon,
	totalTimeFirstPassSec: checkedStopWatch,
	totalTimeFirstAceSec: starredStopWatch,
	avgScore: starIcon,
	userScore: checkBoxIcon,
	orgAvgScore: organizationIcon,
	passingPercent: checkBoxIcon,
	acedPercent: starIcon,
	teamAvgScore: checkBoxIcon,
	teamScore: checkBoxIcon,
	orgAvgRate: organizationIcon,
	passingScoreRate: checkBoxIcon,
	acedScoreRate: starIcon,
	numAssignedTeams: peopleIcon,
	numAssignedTraining: userHMD,
	numAssignedLessons: bookMarkIcon,
	totalSkills: skillsIcon,
	skillPoints: skillPointsIcon,
	numActiveUsers: personIcon,
	numLoginsSevenDays: personIcon,
	numTeamsTotal: peopleIcon,
	numIncompleteLessons: bookMarkIcon,
	numUsersInTraining: peopleIcon,
	numLessonsCompleteSevenDays: bookMarkIcon,
	numUsersOnTeams: personIcon,
	initialPerformanceScore: checkBoxIcon,
	finalPerformanceScore: checkBoxIcon,
	performanceChange: linegraphIcon,
	assignedLabs: bookMarkIcon,
	labsCompleted: checkBoxIcon,
	completion: checkBoxIcon,
	assignedSteps: bookMarkIcon,
	stepsCompleted: checkBoxIcon,
	labScore: starIcon,
	assignedObjectives: bookMarkIcon,
	objectivesCompleted: checkBoxIcon,
	stepScore: starIcon,
	inDevelopment: DraftIcon,
	inReview: inReviewIcon,
	published: publishedIcon,
	allModules: DraftIcon,
	licensedModules: LicencedIcon,
	unlicensedModules: ModuleIcon,
};

const getStatsIcon = tag => {
	if (typeof tag === 'string' && tag.toLowerCase().startsWith('http')) {
		return tag;
	}
	return icons[tag] ? icons[tag] : skillsIcon;
};

export default getStatsIcon;
