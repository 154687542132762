export const getConfig = (serverUrl, project) => {
	const options = {
		headers: new Headers({
			'Content-Type': 'application/json'
		}),
		method: 'POST',
		body: JSON.stringify({
			project: project,
			requestingIP: '10.0.0.42'
		})
	};

	return fetch(serverUrl, options).then(res => res.json());
};
