
// Default colors.
export const DEFAULT_LINK_COLOR = '#4b5162';
export const DEFAULT_NODE_BACKGROUND_COLOR = '#233863';
export const DEFAULT_NODE_FONT_COLOR = '#FFFFFF';

// Default sizes.
export const DEFAULT_LINK_SIZE = 10;
export const LABEL_NODE_SIZE = 64;
export const LABEL_NODE_WIDTH = 256;
export const LABEL_NODE_HEIGHT = 64;
export const TRAINING_NODE_SIZE = 200;
export const PRE_ASSESSMENT_NODE_SIZE = 200;
export const LESSON_NODE_SIZE = 200;
export const SCENARIO_NODE_SIZE = 200;
export const POST_ASSESSMENT_NODE_SIZE = 200;
export const START_NODE_SIZE = 200;
export const DECISION_NODE_SIZE = 200;
export const OPTION_NODE_SIZE = 200;
export const BAD_OPTION_NODE_SIZE = 200;
export const END_NODE_SIZE = 200;
export const SKILL_NODE_SIZE = 64;

// An interface to describe the "context" in which the Vector Map is being viewed.
// Includes the primary "context" which is either 'org', 'team' or 'user'.
// Also includes a secondaryor sub-context which is either 'training' or 'lesson' (or null) which is only applicable to the Skill Map.
export interface VectorMapContext {
    context: 'org' | 'team' | 'user' | null,
    subContext?: 'training' | 'lesson' | null
}

// An interface that represents the contextual data provided to the Vector Map component.
// This includes things like the teamId, userId, trainingId or lessonId.
export interface VectorMapContextData {
    teamId?: string | null,
    userId?: string | null,
    trainingId?: string | null,
    lessonId?: string | null
}

// An interface to describe a node in a connected graph.
export interface GraphNode {
    id: any,
    type:
        'label' | // Common types.
        'training' | // Catalog Map specific types.
        'pre-assessment' | 'lesson' | 'scenario' | 'post-assessment' | // Training Map specific types.
        'start' | 'decision' | 'option' | 'bad-option' | 'end' | // Lesson Map specific types.
        'skill', // Skill Map specific types.
    record: { [prop: string]: any },
    mainColour?: string | null,
    fontColour?: string | null,
    borderColor?: string | null,
    nodes?: Array<GraphNode> | null,
}

export const connectPorts = (portFrom, portTo) => {
    const link = portFrom.link(portTo);

    link.setWidth(DEFAULT_LINK_SIZE);
    link.setColor(DEFAULT_LINK_COLOR);

    return link;
}
