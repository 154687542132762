import React from 'react';
import {SET_DEFAULT_TEAM} from 'services/customActions';
import StandardButton from 'components/StandardButton';
import {withDataProvider} from 'react-admin';
import {getUserInfo} from 'utils/Data';
import {refreshView, translate} from 'ra-core';
import compose from 'recompose/compose';
import {connect} from 'react-redux';

const MakeDefaultTeam = props => {
	if ( !getUserInfo().isSuper ) {
		return null;
	}

	const handleClick = e => {
		if (e) e.stopPropagation();
		props.onClick && props.onClick(e);
		var id = props.record.id;

		makeDefaultTeam({id});
	};

	const makeDefaultTeam = async values => {
		const {dataProvider, refreshView} = props;
		await dataProvider(
			SET_DEFAULT_TEAM,
			'config',
			{
				data: {id: values.id},
			},
			{
				onSuccess: {
					refresh: true,
					unselectAll: true,
				},
				onFailure: {
					unselectAll: true,
					notification: {level: 'warning'},
				},
			}
		).then(response => {
			let runwayOrgConfig = sessionStorage.getItem('runwayOrgConfig');

			if (runwayOrgConfig) {
				runwayOrgConfig = JSON.parse(runwayOrgConfig);
				runwayOrgConfig['config']['defaultTeamId'] = response.data.id;
				sessionStorage.setItem(
					'runwayOrgConfig',
					JSON.stringify(runwayOrgConfig)
				);
			}
		});
		refreshView();
	};

	return (
		<StandardButton
			onClick={handleClick}
			variant='link'
			label='Set Default Team'
		/>
	);
};

function mapStateToProps(state) {
	return {
		form: state.form,
	};
}

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withDataProvider
);
export default enhance(MakeDefaultTeam);
