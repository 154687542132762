import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const InDevelopment = props => {
	return (
		<SvgIcon
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/SvgIcon'
			{...props}
		>
			<g>
				<path
					fill={'#4b5162'}
					d='M22.419,12.375l-.437-.437a2.482,2.482,0,0,0-3.432-.086l-.01-.009-.094.094-.966.967-1.061,1.061h0l-3.476,3.476-.363.362-.064.509-.271,2.122L12,22.357l1.923-.245,2.122-.271.508-.065.363-.362,3.476-3.476,1.061-1.061.966-.966.094-.1-.009-.009A2.5,2.5,0,0,0,22.419,12.375Zm-6.564,7.978-2.122.271L14,18.5l3.477-3.476,1.851,1.852Zm5.579-5.617h0a1.164,1.164,0,0,1-.075.113l-.967.966L18.54,13.965,19.507,13a.98.98,0,0,1,.392-.232.9.9,0,0,1,.315-.061,1,1,0,0,1,.707.293l.438.438a.984.984,0,0,1,.207.311,1,1,0,0,1,.055.241c0,.032.017.062.019.1A.978.978,0,0,1,21.434,14.736Z'
				/>
				<path
					fill={'#4b5162'}
					d='M10.757,20.244l.087-.678H5.467a4.843,4.843,0,0,1,4.672-4.739h1.709a4.342,4.342,0,0,1,1.351.237L14.366,13.9c-.171-.081-.338-.169-.517-.233a4.612,4.612,0,0,0,1.486-3.4,4.454,4.454,0,0,0-4.342-4.55,4.455,4.455,0,0,0-4.343,4.55,4.612,4.612,0,0,0,1.487,3.4,6.517,6.517,0,0,0-4.2,5.9H3.3a.92.92,0,0,1-.91-.909V5.344A.922.922,0,0,1,3.3,4.433H18.7a.922.922,0,0,1,.91.91V9.762a3.839,3.839,0,0,1,.607-.057,4,4,0,0,1,.911.115V5.342a2.462,2.462,0,0,0-2.44-2.426H3.3A2.435,2.435,0,0,0,.875,5.343V18.657A2.437,2.437,0,0,0,3.3,21.085H10.65Zm.237-13h.024a2.672,2.672,0,0,1,1.889.8,3.158,3.158,0,0,1,.911,2.236,2.936,2.936,0,0,1-2.825,3.031,2.936,2.936,0,0,1-2.825-3.031A2.936,2.936,0,0,1,10.994,7.246Z'
				/>
			</g>
		</SvgIcon>
	);
};

export const Organization = props => {
	return (
		<SvgIcon
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/SvgIcon'
			viewBox='0 0 40 40'
			{...props}
		>
			<path
				d='M33 10.71a10.67 10.67 0 00-2.9-3.05 15.55 15.55 0 10-10.17 27.65h.65a.93.93 0 00.22 0c.37 0 .75 0 1.13-.06A15.54 15.54 0 0033 10.71zM29.1 9.4a8.84 8.84 0 012.25 2.47 13.48 13.48 0 012.5 6.91h-6.71a22.81 22.81 0 00-4.32-12.31 13.41 13.41 0 016.09 2.81zm-8.76 23.79c-2.44-2.41-4.54-7.83-4.82-12.41h9.64c-.28 4.59-2.37 10.01-4.82 12.41zm-4.8-14.41a20.9 20.9 0 014.8-12.23 20.84 20.84 0 014.8 12.23zm-5.69-7.56a13.45 13.45 0 018-4.73 22.9 22.9 0 00-4.31 12.29H6.85a13.45 13.45 0 013-7.56zm-3 9.93v-.37h6.66A25.29 25.29 0 0017.63 33 13.56 13.56 0 016.88 21.15zm24 7.18a13.42 13.42 0 01-7.8 4.72 25.29 25.29 0 004.11-12.27h6.68a13.46 13.46 0 01-2.99 7.55z'
				fill={'#4b5162'}
			/>
		</SvgIcon>
	);
};

export const Overview = props => {
	return (
		<SvgIcon
			id='prefix__Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/SvgIcon'
			viewBox='0 0 40 40'
			{...props}
		>
			<g id='prefix__Group_1478' data-name='Group 1478'>
				<path
					d='M17.51 34.78a12.25 12.25 0 010-24.49 1 1 0 011 1v10.25h10.25a1 1 0 011 1 12.26 12.26 0 01-12.25 12.24zm-1-22.44a10.24 10.24 0 1011.2 11.2h-10.2a1 1 0 01-1-1z'
					id='prefix__Path_222'
					fill='#4b5162'
				/>
				<path
					d='M34.57 18H23.32a1 1 0 01-1-1V5.79a1 1 0 01.3-.71 1 1 0 01.7-.29A12.24 12.24 0 0135.57 17a1 1 0 01-1 1zm-10.25-2h9.2a10.26 10.26 0 00-9.2-9.2z'
					fill='#4b5162'
				/>
			</g>
		</SvgIcon>
	);
};

export const Teams = props => {
	return (
		<SvgIcon
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/SvgIcon'
			viewBox='-2.5 -2.5 40 40'
			{...props}
		>
			<path
				d='M27.19 19.61a7.24 7.24 0 10-11.46-7.5 8.2 8.2 0 00-2.56-1.78 5.81 5.81 0 10-7.56-.26A8.33 8.33 0 000 17.93a1 1 0 002 0 6.33 6.33 0 016.32-6.32h1.49a6.28 6.28 0 014.79 2.21 1 1 0 00.77.35h.13a7.28 7.28 0 002.73 5.38 10.52 10.52 0 00-7.59 10.09 1 1 0 002 0 8.52 8.52 0 018.51-8.51h3a8.52 8.52 0 018.52 8.51 1 1 0 002 0 10.53 10.53 0 00-7.48-10.03zM9.54 2a3.81 3.81 0 11-3.81 3.81A3.81 3.81 0 019.54 2zm8 11.91a5.25 5.25 0 115.24 5.24 5.25 5.25 0 01-5.29-5.24z'
				fill='#4b5162'
			/>
		</SvgIcon>
	);
};

export const ToolsAndSupport = props => {
	return (
		<SvgIcon
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/SvgIcon'
			viewBox='0 0 40 40'
			{...props}
		>
			<defs>
				<clipPath id='prefix__a'>
					<path fill='none' d='M5.4 4.77h30.02v30.02H5.4z' />
				</clipPath>
			</defs>
			<g data-name='Group 1516'>
				<g data-name='Group 1512' clipPath='url(#prefix__a)'>
					<path
						d='M22.21 34.8h-3.58A1.77 1.77 0 0117 33.63l-.68-1.92a1 1 0 00-.56-.6 1 1 0 00-.84 0l-1.87.89a1.74 1.74 0 01-2-.34l-2.51-2.53a1.74 1.74 0 01-.34-2l.88-1.85a1 1 0 000-.78 1 1 0 00-.52-.58h-.09l-1.93-.69a1.76 1.76 0 01-1.14-1.65V18a1.77 1.77 0 011.17-1.65l1.93-.69a1 1 0 00.59-.56 1 1 0 000-.83l-.89-1.85a1.75 1.75 0 01.34-2l2.52-2.52a1.74 1.74 0 012-.35l1.85.88A1 1 0 0016.26 8a.36.36 0 010-.09L17 5.93a1.76 1.76 0 011.64-1.16h3.59a1.73 1.73 0 011.64 1.17l.69 1.93a1.05 1.05 0 00.51.57h.06a1 1 0 00.82 0l1.85-.88a1.75 1.75 0 012 .34l2.53 2.53a1.74 1.74 0 01.34 2l-.88 1.85a1 1 0 000 .82 1 1 0 00.6.58l1.93.69A1.74 1.74 0 0135.43 18v3.58a1.74 1.74 0 01-1.16 1.64l-1.93.69a1 1 0 00-.62 1.3.36.36 0 000 .09l.88 1.86a1.76 1.76 0 01-.34 2l-2.53 2.52a1.74 1.74 0 01-2 .34l-1.87-.88a1 1 0 00-.8 0 1 1 0 00-.58.62l-.69 1.91a1.77 1.77 0 01-1.64 1.17zM22 33zm-6.66-4a3.06 3.06 0 011.15.23A3 3 0 0118.18 31l.63 1.76H22l.65-1.76a3.06 3.06 0 011.7-1.79 3 3 0 012.43.06l1.7.81 2.28-2.27-.76-1.65a1.48 1.48 0 01-.12-.29A3 3 0 0131.67 22l1.76-.63v-3.19l-1.76-.63A3 3 0 0130 13.41l.8-1.69-2.32-2.28-1.69.8a3 3 0 01-2.23.15l-.19-.08a3 3 0 01-1.68-1.77L22 6.77h-3.19l-.63 1.76a1.48 1.48 0 01-.12.29 3 3 0 01-1.71 1.55 3 3 0 01-2.35-.12l-1.69-.81-2.27 2.28.8 1.69a3 3 0 01.16 2.45 3.08 3.08 0 01-1.78 1.69l-1.77.63v3.21l1.72.61a1.69 1.69 0 01.28.12 3 3 0 011.43 4l-.8 1.69 2.27 2.28 1.65-.77a3.12 3.12 0 011.34-.32zm-3.15 1.2zM10 27.72zm23.6-6.39zm0-3.1zM18.87 6.6zm1.55 19.83a6.65 6.65 0 116.64-6.64 6.65 6.65 0 01-6.64 6.64zm0-11.29a4.65 4.65 0 104.64 4.64 4.65 4.65 0 00-4.64-4.64z'
						fill='#4b5162'
						data-name='Path 732'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export const Module = props => {
	return (
		<SvgIcon
			id='prefix__Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/SvgIcon'
			{...props}
		>
			<g>
				<path
					fill={'#4b5162'}
					d='m 19.5 2.9 h -15.2 a 2.4 2.4 0 0 0 -2.4 2.4 v 13.2 a 2.4 2.4 0 0 0 2.4 2.4 h 15.2 a 2.4 2.4 0 0 0 2.4 -2.4 v -13.2 a 2.4 2.4 0 0 0 -2.4 -2.4 z m -7.6 10.3 a 2.9 2.9 0 0 1 -2.8 -3 a 2.9 2.9 0 0 1 2.8 -3 h 0 a 2.6 2.6 0 0 1 1.9 0.8 a 3.1 3.1 0 0 1 0.9 2.2 a 2.9 2.9 0 0 1 -2.8 3 z m -0.8 1.5 h 1.7 a 4.8 4.8 0 0 1 4.6 4.7 h -10.9 a 4.8 4.8 0 0 1 4.6 -4.7 z m 3.7 -1.1 a 4.6 4.6 0 0 0 1.5 -3.4 a 4.4 4.4 0 0 0 -4.3 -4.5 a 4.4 4.4 0 0 0 -4.3 4.5 a 4.6 4.6 0 0 0 1.5 3.4 a 6.4 6.4 0 0 0 -4.1 5.8 h -0.6 a 0.9 0.9 0 0 1 -0.9 -0.9 v -13.1 a 0.9 0.9 0 0 1 0.9 -0.9 h 15.2 a 0.9 0.9 0 0 1 0.9 0.9 v 13 l 0 0.1 a 0.9 0.9 0 0 1 -0.9 0.9 h -0.6 a 6.4 6.4 0 0 0 -4.1 -5.8 z'
				/>
			</g>
		</SvgIcon>
	);
};

export const Users = props => {
	return (
		<SvgIcon
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/SvgIcon'
			viewBox='0 0 40 40'
			{...props}
		>
			<path
				className='st0'
				d='M29.6,23.9c-1.4-1.4-3-2.3-4.9-2.9c3.4-2.6,4.1-7.4,1.5-10.8s-7.4-4.1-10.8-1.5s-4.1,7.4-1.5,10.8
				c0.4,0.6,0.9,1.1,1.5,1.5c-4.8,1.4-8.2,5.8-8.2,10.8c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1c0-5.1,4.1-9.2,9.2-9.2h3.1
				c5.1,0,9.2,4.1,9.2,9.2l0,0c0,0.6,0.4,1,1,1s1-0.4,1-1c0,0,0,0,0,0C32.8,28.9,31.7,26,29.6,23.9z M14.3,14.9c0-3.2,2.6-5.7,5.7-5.7
				s5.7,2.6,5.7,5.7s-2.6,5.7-5.7,5.7l0,0C16.9,20.6,14.3,18.1,14.3,14.9C14.3,14.9,14.3,14.9,14.3,14.9L14.3,14.9z'
				fill='#4b5162'
			/>
		</SvgIcon>
	);
};

export const Support = props => {
	return (
		<SvgIcon
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 40 40'
		>
			<defs>
				<style>{'.cls-1{fill:#4b5162}'}</style>
			</defs>
			<path
				className='cls-1'
				d='M34,16.64H27.7V10a3.53,3.53,0,0,0-3.52-3.52H6.59A3.52,3.52,0,0,0,3.08,10V20.35a3.52,3.52,0,0,0,3.51,3.52h.8a1.46,1.46,0,0,1,1.07.44l3.08,3.08a1,1,0,0,0,1.41,0L16,24.31a1.5,1.5,0,0,1,1.07-.44h.29v3.76a3,3,0,0,0,2.95,2.95h5.49a1,1,0,0,1,.67.28l2.38,2.38a1,1,0,0,0,1.42,0l2.39-2.38a.91.91,0,0,1,.66-.28H34a3,3,0,0,0,3-2.95v-8A3,3,0,0,0,34,16.64ZM14.62,22.89l-2.37,2.37L9.88,22.89a3.53,3.53,0,0,0-2.49-1h-.8a1.51,1.51,0,0,1-1.51-1.51V10A1.52,1.52,0,0,1,6.59,8.46H24.18A1.52,1.52,0,0,1,25.7,10V20.35a1.51,1.51,0,0,1-1.52,1.51H17.1A3.49,3.49,0,0,0,14.62,22.89Zm20.3,4.74a1,1,0,0,1-1,.95h-.62a2.94,2.94,0,0,0-2.08.86l-1.68,1.68-1.67-1.68a3,3,0,0,0-2.09-.86H20.34a1,1,0,0,1-.95-.95V23.87h4.79a3.53,3.53,0,0,0,3.52-3.52v-1.7H34a.94.94,0,0,1,1,.94Z'
			/>
			<path
				className='cls-1'
				d='M29.85,23.11h2.26a1,1,0,0,0,0-2H29.85a1,1,0,0,0,0,2Z'
			/>
			<path
				className='cls-1'
				d='M32.12,25.08H22a1,1,0,0,0,0,2H32.12a1,1,0,0,0,0-2Z'
			/>
			<path
				className='cls-1'
				d='M15,13.85a1,1,0,0,0-1,1v4.44a1,1,0,0,0,2,0V14.85A1,1,0,0,0,15,13.85Z'
			/>
			<path
				className='cls-1'
				d='M15,12.76l.2,0a.64.64,0,0,0,.18-.06,1.51,1.51,0,0,0,.18-.09l.15-.13a1,1,0,0,0,.29-.7.84.84,0,0,0-.08-.38.93.93,0,0,0-.21-.33l-.15-.12-.18-.1-.18-.05a1,1,0,0,0-.91.27,1,1,0,0,0-.29.71,1,1,0,0,0,1,1Z'
			/>
		</SvgIcon>
	);
};
