import React from 'react';
import TSTheme from '../Theme';

const Show = ({height = '100%', children}) => {
	return (
		<div
			style={{
				width: '100%',
				border: 'none',
				height: height,
				display: 'flex',
				flexDirection: 'column',
				MozBoxShadow: '0px 5px 20px #00000026',
				boxShadow: '0px 5px 20px #00000026',
				background: TSTheme.palette.background.containedContent,
			}}
		>
			{children}
		</div>
	);
};

export default Show;
