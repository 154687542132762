import React, {useState} from 'react';
import {withDataProvider, useRefresh} from 'react-admin';
import compose from 'recompose/compose';
import {EmbeddedList} from 'components';
import {Button} from '@material-ui/core';

import DefaultTeam from 'components/DefaultTeam';
import PercentField from 'components/PercentField';
import ViewTeamDetailButton from 'sections/Training/ViewTeamDetailButton';
import AssignModuleToTeamsDialog from 'components/TrainingCatalogue/AssignModuleToTeamsDialog';
import { AddOutlined } from '@material-ui/icons';

const TeamsTab = props => {
	const refresh = useRefresh();

	const [openAssignTeamsDialog, setOpenAssignTeamsDialog] = useState(false);

	const closeDialog = () => {
		refresh();
		setOpenAssignTeamsDialog(false);
	};

	return (
		<>
			<EmbeddedList
				embedded={false}
				subTitle
				title={'Teams'}
				resource='teamsfortraining'
				columns={[
					{
						id: 'name',
						name: 'Name',
						sortable: true,
						render: record => <DefaultTeam record={record} />,
					},
					{
						id: 'users',
						name: 'Users',
						sortable: true,
						isInverted: true,
					},
					{
						id: 'completionRate',
						name: 'Completion Rate',
						sortable: true,
						align: 'center',
						render: record => (
							<PercentField record={record} source='completionRate' />
						),
						isInverted: true,
					},
					{
						id: 'avgScore',
						name: 'Avg Score',
						sortable: true,
						align: 'center',
						render: record => (
							<PercentField record={record} source='avgScore' />
						),
						isInverted: true,
					},
				]}
				sort={{field: 'name', order: 'ASC'}}
				dynamicFilters={null}
				staticFilters={[{id: 'id', value: props.id}]}
				bulkActions={[
					{
						id: 'addTraining',
						render: () => (
							<Button
								onClick={() => setOpenAssignTeamsDialog(true)}
								width={'148px'}
								variant={'outlined'}
								color={'primary'}
								startIcon={<AddOutlined style={{ marginBottom: '2px' }}/>}
							>
								Assign Module to Teams
							</Button>
						),
					},
				]}
				actions={[
					{
						render: record => (
							<ViewTeamDetailButton
								key={'view team detail'}
								variant='link'
								record={record}
								basePath={props.basePath}
								location={props.location}
							/>
						),
					},
				]}
				selectedIds={null}
				selectionToggled={null}
				stripped={false}
			/>

			<AssignModuleToTeamsDialog
				isOpen={openAssignTeamsDialog}
				onClose={() => closeDialog()}
				moduleId={props.id}
			/>
		</>
	);
};

export default compose(withDataProvider)(TeamsTab);
