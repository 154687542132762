import {useTheme} from '@material-ui/core';
import {useState} from 'react';
import {Typography} from '@material-ui/core';
import Indicators from 'components/TrainingCatalogue/Indicators';

const FeaturedSkills = props => {
	const {skills, onEdit, numberOfAvailableSkills} = props;

	const theme = useTheme();

	const [activeIndex, setActiveIndex] = useState(0);

	const featuredSkill = i => {
		if (i >= skills.length) return;

		return (
			<div>
				<div
					style={{
						display: 'flex',
						columnGap: '40px',
						padding: '12px 0px',
					}}
				>
					{skills[i].iconUrl != null && skills[i].iconUrl.trim().length > 0 && (
						<img
							src={skills[i].iconUrl}
							style={{
								height: '75px',
								width: '75px',
								backgroundColor: skills[i].mainColour,
								borderRadius: '10%',
							}}
							alt=''
						/>
					)}

					{(!skills[i].iconUrl || skills[i].iconUrl.trim().length === 0) && (
						<div
							style={{
								flex: '0 0 auto',
								display: 'flex',
								width: '75px',
								height: '75px',
								borderRadius: '10%',
								overflow: 'hidden',
							}}
						>
							<svg
								width={'100%'}
								height={'100%'}
								viewBox={'0 0 40 40'}
								xmlns='http://www.w3.org/2000/svg'
								version='1.1'
								style={{
									pointerEvents: 'none',
									backgroundColor: skills[i].mainColour
										? skills[i].mainColour
										: 'rgb(42, 44, 60)',
								}}
							>
								<text
									textAnchor='middle'
									alignmentBaseline='middle'
									x={40 * 0.5}
									y={40 * 0.55}
									style={{
										fontFamily: 'Arial',
										fontWeight: 'bold',
										fill: '#ffffff',
										stroke: '#ffffff',
										fontSize: '24px',
									}}
								>
									{skills[i].displayShortName
										? skills[i].displayShortName
										: 'Sk'}
								</text>
							</svg>
						</div>
					)}

					<div style={{flex: '1 1 auto'}}>
						<Typography style={{fontFamily: 'Archivo Bold', fontSize: '16px'}}>
							{skills[i].displayName}
						</Typography>
						<p>{skills[i].description}</p>
						<div style={{display: 'flex', gridColumnGap: '40px'}}>
							<Typography>
								Type:{' '}
								<span style={{fontFamily: 'Montserrat Regular'}}>
									{skills[i].type}
								</span>
							</Typography>
							<Typography>
								Category:{' '}
								<span style={{fontFamily: 'Montserrat Regular'}}>
									{skills[i].category}
								</span>
							</Typography>
							{skills[i].totalPoints > 0 && (
								<Typography>
									Total Skill Points:{' '}
									<span style={{fontFamily: 'Montserrat Regular'}}>
										{skills[i].totalPoints}
									</span>
								</Typography>
							)}
						</div>
					</div>
				</div>
				<hr
					style={{
						divider: {
							border: 0,
							borderBottom: '1px solid',
							color: theme.palette.borderColor.divider,
							width: '100%',
							height: '1px',
						},
					}}
				></hr>
			</div>
		);
	};

	return (
		<div style={{padding: '12px'}}>
			{featuredSkill(activeIndex * 2)}
			{featuredSkill(activeIndex * 2 + 1)}
			<div
				style={{
					display: 'flex',
					justifyContent: onEdit ? 'space-between' : 'center',
					alignItems: 'center',
				}}
			>
				{onEdit && <span></span>}

				<Indicators
					length={
						skills.length > 1
							? skills.length % 2 === 0
								? skills.length / 2
								: skills.length / 2 + 0.5
							: skills.length
					}
					active={activeIndex}
					press={index => setActiveIndex(index)}
				/>

				{onEdit && (
					<div
						style={{
							display: 'flex',
							alignItems: 'baseline',
							columnGap: '10px',
						}}
					>
						<Typography
							style={{color: '#85A0AE'}}
						>{`${skills.length}/${numberOfAvailableSkills}`}</Typography>

						{/* <Button
							variant={'outlined'}
							color={'primary'}
							onClick={() => onEdit(true)}
						>
							Edit Skills
						</Button> */}
					</div>
				)}
			</div>
		</div>
	);
};

export default FeaturedSkills;
