import React, {Component} from 'react';
import {createStyles, withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {translate} from 'react-admin';
import {Typography} from '@material-ui/core';
import FilterTile from 'components/StatFilterTile';
import {TSTheme} from 'components/Theme';

const myStyles = () =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			columnGap: '10px',
		},
		row: {
			display: 'flex',
			columnGap: '10px',
		},
	});

class ModuleStatFilter extends Component {
	renderStatsTileRow(stats, startingIdx, numPerRow, label, titleType) {
		const {classes, onClick, selectedFilter} = this.props;

		if (stats && stats.length > 0 && startingIdx < stats.length) {
			const endingIdx =
				startingIdx + numPerRow < stats.length
					? startingIdx + numPerRow
					: stats.length;

			const rowStats = stats.slice(startingIdx, endingIdx);

			const tiles = [];

			rowStats.forEach(stat => {
				if (stat) {
					tiles.push(
						<FilterTile
							key={stat.displayName}
							title={stat.displayName}
							titleType={titleType}
							value={stat.value}
							type={stat.type}
							valueColor={stat.valueColor}
							onClick={onClick}
							selectedTile={selectedFilter}
							displayName={stat.displayName}
						/>
					);
				}
			});

			return (
				<div style={{display: 'flex', flexDirection: 'column'}}>
					{label && (
						<Typography
							style={{
								fontSize: '14px',
								fontFamily: 'Archivo Bold',
								paddingBottom: '10px',
							}}
						>
							{label}
						</Typography>
					)}
					<div key={stats.length / numPerRow} className={classes.row}>
						{tiles}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		const {classes, stats} = this.props;
		const splitId = stats.findIndex(stat => stat.type === 'split');

		if (stats && stats.length > 0)
			return (
				<div className={classes.root}>
					{this.renderStatsTileRow(
						stats,
						0,
						splitId > -1 ? splitId : stats.length,
						'License Status'
					)}
					{splitId > -1 && (
						<span
							style={{
								border: `1px ${TSTheme.palette.borderColor.secondary} solid`,
							}}
						></span>
					)}
					{splitId > -1 &&
						this.renderStatsTileRow(
							stats,
							splitId + 1,
							stats.length,
							'CoPilot Designer Modules',
							'chip'
						)}
				</div>
			);
		else return <></>;
	}
}

ModuleStatFilter.propTypes = {
	stats: PropTypes.any,
};

const enhance = compose(translate, withStyles(myStyles));

export default enhance(ModuleStatFilter);
