import {
	ADD_TRAININGS_TO_TEAM,
	ADD_USER_TO_TEAMS,
	ADD_USERS_TO_TEAM,
	DELETE_TEAM,
	DELETE_TRAININGS_FROM_TEAM,
	DELETE_USER,
	DELETE_USER_FROM_TEAMS,
	DOWNLOAD_RAW_PERFOMANCE_LOGS,
	GET,
	GET_FUTURE_TRAINING,
	GET_GUIDE_URL,
	GET_PROFILE_INFO,
	GET_RUNWAY_ORG_CONFIG,
	GET_STATS,
	GET_TEMPLATE_URL,
	RAW_WATCHDOG_DOWNLOAD_RUNWAY,
	SET_DEFAULT_TEAM,
	UNSET_DEFAULT_TEAM,
	SET_PROFILE_INFO,
	UPDATE_RAW_PERFOMANCE_LOGS_RUNWAY,
	CLEAR_USER_DATA_FOR_TRAINING,
	EDIT_GENERATE_USER_CONFIG,
	CREATE_GENERATE_USERS,
	DOWNLOAD_USER_GENERATED_INFO,
	EXPORT_USER_TRAINING_DATA,
	IMPORT_USER_TRAINING_DATA,
	GET_GENERATE_USER_ACCESS,
	GET_USER_ROLE_LIST,
	LOG_OUT,
	S2S,
	GET_TRAINING,
	GET_TRAINING_PACKAGE_INTEGRATION_STATUS,
	GET_USER_CPD_URLS,
} from './customActions';
import {
	CREATE,
	DELETE,
	DELETE_MANY,
	GET_LIST,
	GET_MANY,
	GET_ONE,
	UPDATE,
	UPDATE_MANY,
} from 'react-admin';
// in myRestProvider.js
import {stringify} from 'query-string';
import {AddAuthError} from '../utils/AuthErrors';
import { checkOnlineStatus, clearSessionStorageOnUserLogOut, parseJson } from "utils/Util";

const requestQueue = []; // Used to keep treact on how many request ar in queue for execution, used only for it lenght.
let runningRequest = null; // The currently running Promise
const allSettled = typeof Promise.allSettled;
// const apiUrl = 'http://path.to.my.api/';

export const clearRequestQueue = () => {
	console.log('Clearing Request Queue...');
	requestQueue.length = 0;
	runningRequest = null;
};

function buildRangeFilter(filter) {
	let outFilter = {};
	let andFilter = [];
	for (const key in filter) {
		if (filter.hasOwnProperty(key)) {
			if (key.startsWith('lte:')) {
				let itemClause = {};
				itemClause[key.substr(4)] = {$lte: filter[key]};
				andFilter.push(itemClause);
			} else if (key.startsWith('gte:')) {
				let itemClause = {};
				itemClause[key.substr(4)] = {$gte: filter[key]};
				andFilter.push(itemClause);
			} else if (key.startsWith('null:')) {
				let itemClause = {};
				itemClause[key.substr(5)] = filter[key] ? null : {neq: null};
				andFilter.push(itemClause);
			} else if (key === 'q') {
				// q is a special case that will do a regex case insensitive search on field name.
				if (filter[key] && filter[key].length > 0) {
					outFilter['name'] = {$regex: filter[key], $options: 'i'};
				}
			} else {
				outFilter[key] = filter[key];
			}
		}
	}
	if (andFilter.length > 0) {
		outFilter['and'] = andFilter;
	}
	return outFilter;
}

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. 'posts'
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
const request = (apiUrl, project) => {
	sessionStorage.setItem('IN-FLIGHT', 'false');
	return async (type, resource, params) => {
		let url = '';
		let query = {};
		const options = {
			headers: new Headers({
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-PROJECT': project,
			}),
		};

		switch (type) {
			case GET_LIST: {
				const sort = {};
				if (params.sort && params.sort.field && params.sort.order) {
					sort[params.sort.field] = params.sort.order === 'ASC' ? 1 : -1;
					if (
						params.filter &&
						params.filter.status &&
						params.filter.status === 'all'
					) {
						delete params.filter.status;
					}
					if (
						params.filter &&
						params.filter.role &&
						params.filter.role === 'all'
					) {
						delete params.filter.role;
					}
				}
				if (resource === 'usersbypass') {
					query = {
						sort: sort,
						pagination: params.pagination,
						filter: buildRangeFilter(params.filter),
						bypassManager: true,
					};
					url = `${apiUrl}/${resource}`;
					options.method = 'POST';
					options.body = JSON.stringify(query);
				} else if (resource === 'traininglessonsforuser') {
					const staticFilters = {
						userId: params.filter ? params.filter.userId : '',
						trainingId: params.filter ? params.filter.trainingId : '',
					};
					let filter = params.filter;

					delete filter.userId;
					delete filter.trainingId;

					query = {
						sort: JSON.stringify(sort),
						pagination: JSON.stringify(params.pagination),
						staticFilters: JSON.stringify(staticFilters),
						filter: JSON.stringify(
							buildRangeFilter(Object.values(filter).length ? filter : {})
						),
					};
					url = `${apiUrl}/${resource}?${stringify(query)}`;
				} else if (resource === 'usersfortraining') {
					const staticFilters = {
						id: params.filter ? params.filter.id : '',
					};
					let filter = params.filter;

					delete filter.id;

					query = {
						sort: JSON.stringify(sort),
						pagination: JSON.stringify(params.pagination),
						staticFilters: JSON.stringify(staticFilters),
						filter: JSON.stringify(
							buildRangeFilter(Object.values(filter).length ? filter : {})
						),
					};
					url = `${apiUrl}/${resource}?${stringify(query)}`;
				} else if (
					resource === 'listUsersLicenseUsedRunway' ||
					resource === 'listUsersPALLicenseUsedRunway' ||
					resource === 'listChildAppUsersPALLicenseUsedRunway'
				) {
					const staticFilters =
						resource === 'listUsersLicenseUsedRunway'
							? {
									trainingId: params.filter ? params.filter.trainingId : '',
							  }
							: {};

					let filter = params.filter;
					console.log(filter);

					if (
						filter.month &&
						filter.month !== 'null' &&
						filter.year &&
						filter.year !== 'null'
					) {
						if (filter.month === '0') {
							filter['startDate'] = new Date(
								`${1}/${1}/${filter.year}`
							).getTime();
							filter['endDate'] =
								new Date(`${1}/${1}/${parseInt(filter.year) + 1}`).getTime() -
								1;
						} else if (filter.month === '12') {
							filter['startDate'] =
								new Date(`${12}/${1}/${filter.year}`).getTime() - 1;
							filter['endDate'] =
								new Date(`${1}/${1}/${filter.year + 1}`).getTime() - 1;
						} else {
							filter['startDate'] = new Date(
								`${parseInt(filter.month)}/${1}/${filter.year}`
							).getTime();
							filter['endDate'] =
								new Date(
									`${parseInt(filter.month) + 1}/${1}/${filter.year}`
								).getTime() - 1;
						}
					}

					delete filter.month;
					delete filter.year;

					if (filter.trainingId) delete filter.trainingId;

					query = {
						sort: JSON.stringify(sort),
						pagination: JSON.stringify(params.pagination),
						staticFilters: JSON.stringify(staticFilters),
						filter: JSON.stringify(
							buildRangeFilter(Object.values(filter).length ? filter : {})
						),
					};
					url = `${apiUrl}/${resource}?${stringify(query)}`;
				} else if (resource === 'catalogvectormap') {
					query = {
						teamId:
							params.filter && params.filter.teamId
								? params.filter.teamId
								: undefined,
						userId:
							params.filter && params.filter.userId
								? params.filter.userId
								: undefined,
					};
					url = `${apiUrl}/${resource}?${stringify(query)}`;
				} else if (resource === 'trainingvectormap') {
					query = {
						teamId:
							params.filter && params.filter.teamId
								? params.filter.teamId
								: undefined,
						userId:
							params.filter && params.filter.userId
								? params.filter.userId
								: undefined,
						trainingId:
							params.filter && params.filter.trainingId
								? params.filter.trainingId
								: undefined,
					};
					url = `${apiUrl}/${resource}?${stringify(query)}`;
				} else if (resource === 'lessonvectormap') {
					query = {
						teamId:
							params.filter && params.filter.teamId
								? params.filter.teamId
								: undefined,
						userId:
							params.filter && params.filter.userId
								? params.filter.userId
								: undefined,
						trainingId:
							params.filter && params.filter.trainingId
								? params.filter.trainingId
								: undefined,
						lessonId:
							params.filter && params.filter.lessonId
								? params.filter.lessonId
								: undefined,
					};
					url = `${apiUrl}/${resource}?${stringify(query)}`;
				} else if (resource === 'skillvectormap') {
					query = {
						teamId:
							params.filter && params.filter.teamId
								? params.filter.teamId
								: undefined,
						userId:
							params.filter && params.filter.userId
								? params.filter.userId
								: undefined,
						trainingId:
							params.filter && params.filter.trainingId
								? params.filter.trainingId
								: undefined,
						lessonId:
							params.filter && params.filter.lessonId
								? params.filter.lessonId
								: undefined,
					};
					url = `${apiUrl}/${resource}?${stringify(query)}`;
				} else if (resource === 'linkedrolesvectormap') {
					query = {
						teamId:
							params.filter && params.filter.teamId
								? params.filter.teamId
								: undefined,
						userId:
							params.filter && params.filter.userId
								? params.filter.userId
								: undefined,
						trainingId:
							params.filter && params.filter.trainingId
								? params.filter.trainingId
								: undefined,
						lessonId:
							params.filter && params.filter.lessonId
								? params.filter.lessonId
								: undefined,
					};
					url = `${apiUrl}/${resource}?${stringify(query)}`;
				} else {
					query = {
						sort: JSON.stringify(sort),
						pagination: JSON.stringify(params.pagination),
						filter: JSON.stringify(buildRangeFilter(params.filter)),
					};
					url = `${apiUrl}/${resource}?${stringify(query)}`;
				}
				break;
			}
			case GET_ONE:
				console.debug(` GET_ONE : called with ${JSON.stringify(params)}`);

				if (params && params.id) {
					if (
						resource === 'trainingsforuser' ||
						resource === 'listtrainingforteam' || 'trainings'
					) {
						const id = params.id;
						const query = {
							filter: JSON.stringify(buildRangeFilter(params.filter)),
						};
						url = `${apiUrl}/${resource}/${id}?${stringify(query)}`;
					} else {
						url = `${apiUrl}/${resource}/${params.id}`;
					}
				} else {
					url = `${apiUrl}/${resource}`;
				}
				break;
			case CREATE:
				url = `${apiUrl}/${resource}`;
				options.method = 'POST';
				options.body = JSON.stringify(params.data);
				options.signal = params.signal;
				break;
			case UPDATE:
				url = `${apiUrl}/${resource}/${params.id}`;
				options.method = 'PUT';
				options.body = JSON.stringify(params.data);
				break;
			case UPDATE_MANY:
				query = {
					filter: JSON.stringify({id: params.ids}),
				};
				url = `${apiUrl}/${resource}?${stringify(query)}`;
				options.method = 'PATCH';
				options.body = JSON.stringify(params.data);
				break;
			case DELETE:
				url = `${apiUrl}/${resource}/${params.id}`;
				options.method = 'DELETE';
				options.body = JSON.stringify(params.data);
				break;
			case DELETE_MANY:
				query = {
					filter: JSON.stringify({id: params.ids}),
				};
				url = `${apiUrl}/${resource}?${stringify(query)}`;
				options.method = 'DELETE';
				break;
			case GET_MANY: {
				query = {
					filter: JSON.stringify({id: {$in: params.ids}}),
				};
				url = `${apiUrl}/${resource}?${stringify(query)}`;
				break;
			}
			case ADD_USER_TO_TEAMS: {
				console.debug(
					` ADD_USER_TO_TEAMS : called with ${JSON.stringify(params)}`
				);
				options.method = 'PUT';
				options.body = JSON.stringify({
					profileId: params.data.id,
					teams: params.data.selectedIds,
					role: 'user',
				});
				url = `${apiUrl}/${resource}/${params.data.id}/addToTeams`;
				break;
			}
			case ADD_USERS_TO_TEAM: {
				console.debug(
					` ADD_USER_TO_TEAMS : called with ${JSON.stringify(params)}`
				);
				options.method = 'PUT';
				options.body = JSON.stringify({
					teamId: params.data.id,
					profileIds: params.data.selectedIds,
				});
				url = `${apiUrl}/${resource}/${params.data.id}/addUsersToTeam`;
				break;
			}
			case ADD_TRAININGS_TO_TEAM: {
				console.debug(
					` ADD_TRAININGS_TO_TEAM : called with ${JSON.stringify(params)}`
				);
				options.method = 'PUT';
				options.body = JSON.stringify({
					teamId: params.data.id,
					trainings: params.data.selectedIds,
				});
				url = `${apiUrl}/${resource}/${params.data.id}/addTrainings`;
				break;
			}
			case DELETE_TEAM: {
				console.debug(` DELETE_TEAM : called with ${JSON.stringify(params)}`);
				options.method = 'DELETE';
				url = `${apiUrl}/teams/${params.data.id}`;
				break;
			}
			case DELETE_TRAININGS_FROM_TEAM: {
				console.debug(
					` DELETE_TRAININGS_FROM_TEAM : called with ${JSON.stringify(params)}`
				);
				options.method = 'PUT';
				options.body = JSON.stringify({
					teamId: params.data.teamId,
					trainings: params.data.trainings,
				});
				url = `${apiUrl}/${resource}/${params.data.teamId}/deleteTrainings`;
				break;
			}
			case DELETE_USER_FROM_TEAMS: {
				console.debug(
					` DELETE_USER_FROM_TEAMS : called with ${JSON.stringify(params)}`
				);
				options.method = 'PUT';
				options.body = JSON.stringify({
					teams: params.data.teams,
					profileId: params.data.profileId,
				});
				url = `${apiUrl}/${resource}/${params.data.profileId}/deleteUserFromTeams`;
				break;
			}

			case DELETE_USER: {
				console.debug(` DELETE_USER : called with ${JSON.stringify(params)}`);
				options.method = 'DELETE';
				url = `${apiUrl}/${resource}/${params.data.id}`;
				break;
			}

			case GET_STATS: {
				console.debug(` GET_STATS : called with ${JSON.stringify(params)}`);
				if (params && params.id) {
					url = `${apiUrl}/${resource}/${params.id}`;
				} else {
					url = `${apiUrl}/${resource}`;
				}
				break;
			}

			case GET_PROFILE_INFO: {
				console.debug(
					` GET_PROFILE_INFO : called with ${JSON.stringify(params)}`
				);
				options.method = 'GET';
				options.body = JSON.stringify(params);
				url = `${apiUrl}/${resource}`;
				break;
			}
			case SET_PROFILE_INFO: {
				console.debug(
					` SET_PROFILE_INFO : called with ${JSON.stringify(params)}`
				);
				options.method = 'POST';
				options.body = JSON.stringify(params);
				url = `${apiUrl}/${resource}`;
				break;
			}
			case GET_RUNWAY_ORG_CONFIG: {
				console.debug(
					` GET_RUNWAY_ORG_CONFIG : called with ${JSON.stringify(params)}`
				);
				url = `${apiUrl}/${resource}`;
				break;
			}

			case SET_DEFAULT_TEAM: {
				options.method = 'POST';
				url = `${apiUrl}/${resource}/default/teamid/${params.data.id}`;
				break;
			}
			case UNSET_DEFAULT_TEAM: {
				options.method = 'POST';
				url = `${apiUrl}/${resource}/unsetdefaultTeam`;
				break;
			}
			case GET_GUIDE_URL: {
				console.debug(` GET_GUIDE_URL : called with ${JSON.stringify(params)}`);
				url = `${apiUrl}/${resource}?${stringify(params)}`;
				break;
			}
			case GET_TEMPLATE_URL: {
				console.debug(
					` GET_TEMPLATE_URL : called with ${JSON.stringify(params)}`
				);
				url = `${apiUrl}/${resource}?${stringify(params)}`;
				break;
			}

			case DOWNLOAD_RAW_PERFOMANCE_LOGS: {
				console.debug(
					` DOWNLOAD_RAW_PERFOMANCE_LOGS : called with ${JSON.stringify(
						params
					)}`
				);
				url = `${apiUrl}/${resource}?${stringify(params)}`;
				break;
			}
			case UPDATE_RAW_PERFOMANCE_LOGS_RUNWAY: {
				console.debug(
					` UPDATE_RAW_PERFOMANCE_LOGS_RUNWAY : called with ${JSON.stringify(
						params
					)}`
				);

				url = `${apiUrl}/${resource}?${stringify(params)}`;
				break;
			}
			case RAW_WATCHDOG_DOWNLOAD_RUNWAY: {
				console.debug(
					` RAW_WATCHDOG_DOWNLOAD_RUNWAY : called with ${JSON.stringify(
						params
					)}`
				);

				url = `${apiUrl}/${resource}?${stringify(params)}`;
				break;
			}

			case GET: {
				console.debug(` GET : called with ${JSON.stringify(params)}`);

				const query = {params: JSON.stringify(params)};

				url = `${apiUrl}/${resource}?${stringify(query)}`;
				break;
			}

			case GET_FUTURE_TRAINING: {
				console.debug(` GET_STATS : called with ${JSON.stringify(params)}`);
				if (params && params.appKeyName) {
					url = `${apiUrl}/${resource}/${params.appKeyName}`;
				} else {
					url = `${apiUrl}/${resource}`;
				}
				if (resource === 'futureTrainings') {
					const query = { filter: JSON.stringify(params.filter) };
					url = `${url}?${stringify(query)}`;
				}
				break;
			}
			case CLEAR_USER_DATA_FOR_TRAINING: {
				console.debug(
					` CLEAR_USER_DATA_FOR_TRAINING : called with ${JSON.stringify(
						params
					)}`
				);
				options.method = 'PUT';

				if (params.trainingIds) {
					query = {
						userId: params.userId,
						trainingIds: params.trainingIds,
					};
				} else {
					query = {
						userId: params.userId,
					};
				}
				url = `${apiUrl}/${resource}?${stringify(query)}`;
				break;
			}
			case CREATE_GENERATE_USERS: {
				console.debug(
					` CREATE_GENERATE_USERS : called with ${JSON.stringify(params)}`
				);
				query = {
					numToCreate: params.numToCreate,
				};
				options.method = 'POST';
				options.body = JSON.stringify(params);
				url = `${apiUrl}/${resource}`;
				break;
			}
			case DOWNLOAD_USER_GENERATED_INFO: {
				console.debug(
					` DOWNLOAD_USER_GENERATED_INFO : called with ${JSON.stringify(
						params
					)}`
				);
				query = {};
				options.method = 'POST';
				options.body = JSON.stringify(params);
				url = `${apiUrl}/${resource}`;
				break;
			}
			case EDIT_GENERATE_USER_CONFIG: {
				console.debug(
					` EDIT_GENERATE_USER_CONFIG : called with ${JSON.stringify(params)}`
				);
				query = {
					userName: params.userName,
					firstName: params.firstName,
					lastName: params.lastName,
					token: params.token,
				};
				options.method = 'POST';
				options.body = JSON.stringify(params);
				url = `${apiUrl}/${resource}`;
				break;
			}
			case EXPORT_USER_TRAINING_DATA: {
				console.debug(
					` EXPORT_USER_TRAINING_DATA : called with ${JSON.stringify(params)}`
				);
				options.method = 'POST';
				if (params.appKeyName) {
					query = {
						userId: params.userId,
						appKeyName: params.appKeyName,
					};
				} else {
					query = {
						userId: params.userId,
					};
				}
				url = `${apiUrl}/${resource}?${stringify(query)}`;
				break;
			}
			case IMPORT_USER_TRAINING_DATA: {
				console.debug(
					` IMPORT_USER_TRAINING_DATA : called with ${JSON.stringify(params)}`
				);
				options.method = 'POST';
				options.body = JSON.stringify(params);
				url = `${apiUrl}/${resource}`;
				break;
			}
			case GET_GENERATE_USER_ACCESS: {
				console.debug(
					` GET_GENERATE_USER_ACCESS : called with ${JSON.stringify(params)}`
				);
				url = `${apiUrl}/${resource}`;
				break;
			}
			case GET_TRAINING_PACKAGE_INTEGRATION_STATUS: {
				console.debug(
					` GET_TRAINING_PACKAGE_INTEGRATION_STATUS : called with ${JSON.stringify(
						params
					)}`
				);
				url = `${apiUrl}/${resource}?${stringify(params)}`;

				break;
			}
			case GET_USER_ROLE_LIST: {
				console.debug(
					` GET_USER_ROLE_LIST : called with ${JSON.stringify(params)}`
				);
				options.method = 'POST';
				if (params.userId) {
					query = {userId: params.userId};
				} else {
					query = {};
				}
				url = `${apiUrl}/${resource}?${stringify(query)}`;
				break;
			}
			case LOG_OUT: {
				url = `${apiUrl}/${resource}`;
				break;
			}
			case S2S: {
				query = {
					params: JSON.stringify(params),
				};
				url = `${apiUrl}/${resource}?${stringify(query)}`;
				break;
			}
			case GET_USER_CPD_URLS: {
				if (params) {
					query = {
						params: JSON.stringify(params),
					};
					url = `${apiUrl}/${resource}?${stringify(query)}`;;
				} else {
					url = `${apiUrl}/${resource}`;
				}
				break;
			}
			
			default:
				throw new Error(`Unsupported Data Provider request type ${type}`);

			case GET_TRAINING: {
				query = {
					filter: JSON.stringify(params.filter),
				};
				url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`;
				break;
			}
		}

		if (url === '') {
			console.warn(
				`DATA PROVIDER WARNING, no query url could be infered from ${JSON.stringify(
					params
				)} for ${resource}. returning no data `
			);
			return Promise.resolve({data: []});
		}
		const request = {project, url, options};

		return queueRequest(request);
	};
};

export default request;

/** Using allSettled and a single Queue plus delayed Promise creation. and queue index tracking */
async function queueRequest(request) {
	requestQueue.push(request);
	let myQueueIndex = requestQueue.length;
	// console.debug(`${new Date().getTime()}: ==== Queue myQueueIndex is ${myQueueIndex} for ${request.url}`);
	while (myQueueIndex > 0) {
		if (allSettled === 'function') {
			await Promise.allSettled([runningRequest]);
		} else {
			// Firefox, and possibly other browsers do not yet support allSettled, so we'll use all() instead.
			await Promise.all([runningRequest]);
		}
		myQueueIndex--;
		// console.debug(`${new Date().getTime()}: ==== Updated myQueueIndex is now ${myQueueIndex} for ${request.url}`);
	}
	// console.debug(`${new Date().getTime()}: ==== Waiting done, now executing request ${request.url}`)
	runningRequest = fetchRequest(request);
	// console.debug(`${new Date().getTime()}: ==== Old Queue size is ${requestQueue.length} for ${request.url}`);
	requestQueue.shift(); // if the queue was not empty to start, eject the first item.
	// console.debug(`${new Date().getTime()}: ==== Updated Queue size is ${requestQueue.length} for ${request.url}`);
	return runningRequest;
}

async function fetchRequest(request) {
	if (request && request.project) {
		// console.debug(`${new Date().getTime()}: ***** Running request ${JSON.stringify(request)}`);
		const {project, options} = request;
		// Now add the Packet ID just before submitting to minimize out of order query.
		let packetId = parseInt(sessionStorage.getItem(project + '-packetId')) + 1;
		if (!packetId) {
			packetId = 1;
		}
		// console.debug(`${new Date().getTime()}: ***** New packet in flight, undating packet Id to ${packetId}...`);
		sessionStorage.setItem(project + '-packetId', packetId.toString());
		options.headers.append('X-PACKETID', packetId);

		// Add the authoritzation header just before we perform the actual fetch.
		if (sessionStorage.getItem(project + '-token')) {
			options.headers.append(
				'Authorization',
				`Bearer ${sessionStorage.getItem(project + '-token')}`
			);
		}
	}

	return fetch(request.url, request.options)
		.then(res => res.json())
		.then(response => {
			console.log(request.url);

			if (
				response.errors &&
				Array.isArray(response.errors) &&
				response.errors.length > 0
			) {
				let err = null;
				if ((request.url.includes('users_verify') && parseJson(request.options?.body)?.isLMS)) {
					err = new Error(JSON.stringify(response.errors));
				} else if (request.url.includes('updateLog')){
					err = new Error(JSON.stringify(response));
				} else {

					err = new Error(response.errors[0].errorMessage);
				}
				console.debug(
					`tsRestProvider throwing error: ${response.errors[0].errorMessage}`
				);
				err.status = response.errors[0].status;

				throw err;
			}

			if (response.error && response.error.statusCode) {
				console.debug(
					`tsRestProvider throwing error: ${response.error.message}`
				);

				if (response.error.statusCode === 401) {
					window.location = '#/login';
					return Promise.reject({});
				} else if (response.error.statusCode === 403) {
					// Temp runwayOrgConfig login fix. Remove after fixing bad runwayOrgConfig call
					let textRequest = JSON.stringify(request);
					if (textRequest.includes('runwayOrgConfig')) {
						return Promise.reject({});
					}

					let errorObj = {
						...response.error,
						reason_code: response.error.statusCode,
					};

					AddAuthError({
						...errorObj,
						type: 'error',
					});

					window.location = '#/login';
					return Promise.reject({});
				}
			}

			if (response.error) {
				console.debug(
					`tsRestProvider throwing error: ${JSON.stringify(response.error)}`
				);
				const err = new Error(JSON.stringify(response.error));
				err.status = response.status ? response.status : 500; // Default to 500 when no status available.

				throw err;
			}

			if (response.error && response.error.statusCode && response.error.statusCode === 404) {
				console.debug(
					`tsRestProvider throwing error: ${JSON.stringify(response.error)}`
				);
				const err = new Error(JSON.stringify(response.error));
				err.status = response.status ? response.status : 404;

				throw err;
			}

			return response;
		}).catch(async e => {
			const online = await checkOnlineStatus();
			if (!online) {
				clearSessionStorageOnUserLogOut(true);
				window.location.href = '#/login';
			} else {
				if (e && e.message) {
					throw e;
				} else {
					return Promise.reject({});
				}
			}
		});
}
