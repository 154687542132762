import React, {useState, useEffect} from 'react';
import {CREATE, GET_LIST, useTranslate, useDataProvider} from 'react-admin';
import {
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	MuiThemeProvider,
	Typography,
	withStyles,
} from '@material-ui/core';
import DialogListTheme from 'components/Theme';
import DefaultTeam from 'components/DefaultTeam';
import {DialogTitle, EmbeddedList} from 'components';
import LoadingInfo from 'components/loading/LoadingInfo';

const styles = theme =>
	createStyles({
		filterButton: {
			height: '32px',
			fontSize: '12px',
			fontFamily: 'Archivo Bold',
			marginLeft: '15px',
		},
		selectedSkill: {
			border: `2px solid ${theme.palette.primary.main}`,
			borderRadius: '20px',
			alignItems: 'center',
		},
		skillName: {
			color: '#000000',
		},
		cancelIcon: {
			color: theme.palette.primary.main,
		},
		dialogTitle: {
			fontSize: '20px',
			fontFamily: 'Archivo Bold',
		},
		dialogContent: {
			display: 'flex',
			minHeight: '400px',
			flexDirection: ' row',
		},
		selectedSkillListTitle: {
			paddingBottom: '18px',
			paddingTop: '25px',
			fontFamily: 'Montserrat Medium',
		},
		moreSkillsText: {
			fontFamily: 'Archivo Bold',
			textDecoration: 'underline',
			cursor: 'pointer',
			fontSize: '16px',
		},
		selectFilterBtn: {
			height: '32px',
			fontSize: '12px',
			fontFamily: 'Archivo Bold',
			textTransform: 'uppercase',
			width: '150px',
		},
	});

const AssignModuleToTeamsDialog = props => {
	const {classes, isOpen, onClose, moduleId} = props;

	const translate = useTranslate();
	const dataProvider = useDataProvider();

	const [isLoading, setIsLoading] = useState(false);

	const [tempSelectedTeams, setTempSelectedTeams] = useState([]);

	const [initallySelectedTeams, setInitiallySelectedTeams] = useState([]);

	const fetchTeams = async () => {
		setIsLoading(true);
		await dataProvider(GET_LIST, 'teamsfortraining', {
			filter: {id: moduleId},
		})
			.then(res => {
				setTempSelectedTeams([...res.data]);
				setInitiallySelectedTeams([...res.data]);
			})
			.catch(error => console.error(error))
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		if (isOpen) fetchTeams();
	}, [isOpen]);

	const handleUpdate = async () => {
		let selectedTeams = tempSelectedTeams.map(team => team.id);

		setIsLoading(true);

		await dataProvider(CREATE, 'assignTrainingToTeams', {
			data: {
				trainingId: moduleId,
				teamIds: selectedTeams,
			},
		})
			.catch(error => console.error(error))
			.finally(() => {
				setIsLoading(false);
				onClose();
			});
	};

	const toggleFilterSelection = (record, filters) => {
		let updatedFilters = [...filters];

		const targetFilter = updatedFilters.find(item => item.name === record.name);

		if (!targetFilter) updatedFilters.push(record);
		else {
			const idx = updatedFilters.indexOf(targetFilter);
			if (idx >= 0) {
				updatedFilters.splice(idx, 1);
			}
		}

		return updatedFilters;
	};

	return (
		<div className={classes.root}>
			<MuiThemeProvider theme={DialogListTheme}>
				<Dialog
					id={'assign-to-teams'}
					maxWidth='md'
					open={isOpen}
					onClose={() => onClose()}
					fullWidth
				>
					<DialogTitle onClose={() => onClose()}>
						<Typography className={classes.dialogTitle}>
							Assign to Teams
						</Typography>
					</DialogTitle>

					<DialogContent className={classes.dialogContent}>
						{isLoading ? (
							<LoadingInfo />
						) : (
							<>
								<div style={{width: '60%'}}>
									<EmbeddedList
										key={'name'}
										embedded={true}
										title={null}
										resource='teams'
										columns={[
											{
												id: 'name',
												name: 'Name',
												sortable: true,
												render: record => <DefaultTeam record={record} />,
											},
											{
												id: 'users',
												name: translate('Users'),
												sortable: true,
												align: 'center',
												width: '125px',
												isInverted: true,
											},
										]}
										sort={{field: 'name', order: 'ASC'}}
										staticFilters={null}
										dynamicFilters={[
											{
												id: 'name',
												name: translate('Search Teams'),
												type: 'safe_regex',
											},
										]}
										bulkActions={null}
										actions={null}
										rowClicked={null}
										selectedIds={
											tempSelectedTeams
												? tempSelectedTeams.map(team => team.id)
												: []
										}
										selectionToggled={record =>
											setTempSelectedTeams(
												toggleFilterSelection(record, tempSelectedTeams)
											)
										}
										maxHeight={'400px'}
										isPageChooser={false}
									/>
								</div>
								<div
									style={{width: '40%', paddingLeft: '10px', marginTop: '64px'}}
								>
									<EmbeddedList
										key={'displayName'}
										embedded={true}
										resource={tempSelectedTeams}
										columns={[
											{
												id: 'name',
												name: translate('Selected Teams'),
											},
										]}
										selectionToggled={record =>
											setTempSelectedTeams(
												toggleFilterSelection(record, tempSelectedTeams)
											)
										}
										splitSelection={true}
										maxHeight={'400px'}
									/>
								</div>
							</>
						)}
					</DialogContent>

					<DialogActions>
						<Button
							onClick={() => onClose()}
							variant={'contained'}
							color={'secondary'}
							disabled={isLoading}
						>
							Cancel
						</Button>
						<Button
							onClick={() => handleUpdate()}
							variant={'contained'}
							color={'primary'}
							disabled={(tempSelectedTeams.length < 1 && initallySelectedTeams.length === 0) || isLoading}
						>
							Update
						</Button>
					</DialogActions>
				</Dialog>
			</MuiThemeProvider>
		</div>
	);
};

export default withStyles(styles)(AssignModuleToTeamsDialog);
