import * as moment from 'moment';

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

const TimeSinceField = ({source, record = {}, altRecord, fontFamily = 'Montserrat Medium'}) => {
	if (altRecord) {
		record = altRecord;
	}

	if (!get(record,source)) {
		return <span>---</span>;
	}
	return <span style={{ fontFamily: fontFamily}}>{moment.utc(get(record,source)).fromNow()}</span>;
};
TimeSinceField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	fontFamily: PropTypes.string,
	source: PropTypes.string.isRequired,
};

export default TimeSinceField;
