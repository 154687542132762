import * as React from 'react';
import {DiagramEngine} from '@projectstorm/react-diagrams-core';

import {CustomLinkFactory} from './CustomLinkFactory';
import {CustomLinkModel} from './CustomLinkModel';

export interface CustomLinkSegmentWidgetProps {
    path: string;
    link: CustomLinkModel;
    forwardRef: React.RefObject<SVGPathElement>;
    factory: CustomLinkFactory;
    diagramEngine: DiagramEngine;
    extras: object;
}

export default class CustomLinkSegmentWidget extends React.Component<CustomLinkSegmentWidgetProps> {
    render() {
        const segment = React.cloneElement(
            this.props.factory.generateLinkSegment(this.props.link, this.props.path),
            {
                ref: this.props.forwardRef
            }
        );

        return segment;
    }
}
