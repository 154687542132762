import React from 'react';
import TSTheme from '../Theme';

const Content = ({children}) => {
	return (
		<div
			style={{
				flex: 1,
				border: '1px solid',
				borderColor: TSTheme.palette.background.contained,
			}}
		>
			<div style={{padding: '16px'}}>{children}</div>
		</div>
	);
};

export default Content;
