import {DefaultNodeModelOptions, NodeModel, NodeModelGenerics} from '@projectstorm/react-diagrams';

import {DataOptions} from 'components/VectorMap/OptionsBlade';

export interface TrainingNodeModelOptions extends DefaultNodeModelOptions {
    width?: number;
    height?: number;
    backgroundColor?: string;
    fontColor?: string;
    context: 'org' | 'team' | 'user';
    record: any;
    dataOptions: DataOptions | null;
    viewRecordDetails?: (record: any) => void
}

export interface TrainingNodeModelGenerics extends NodeModelGenerics {
    OPTIONS: TrainingNodeModelOptions
}

export class TrainingNodeModel extends NodeModel<TrainingNodeModelGenerics> {
    constructor(options?: TrainingNodeModelOptions) {
        super({
            type: 'training',
            ...options
        });
    }

    setWidth(width?: number) {
        this.options.width = width;
    }
    getWidth() {
        return this.options.width;
    }

    setHeight(height?: number) {
        this.options.height = height;
    }
    getHeight() {
        return this.options.height;
    }

    setBackgroundColor(backgroundColor?: string | null) {
        this.options.backgroundColor = backgroundColor;
    }
    getBackgroundColor() {
        return this.options.backgroundColor;
    }

    setFontColor(fontColor?: string | null) {
        this.options.fontColor = fontColor;
    }
    getFontColor() {
        return this.options.fontColor;
    }

    setContext(context: 'org' | 'team' | 'user') {
        this.options.context = context;
    }
    getContext() {
        return this.options.context;
    }

    setRecord(record: any) {
        this.options.record = record;
    }
    getRecord() {
        return this.options.record;
    }

    setDataOptions(dataOptions: DataOptions | null) {
        this.options.dataOptions = dataOptions;
    }
    getDataOptions() {
        return this.options.dataOptions;
    }

    setViewRecordDetailsHandler(handler: (record: any) => void) {
        this.options.viewRecordDetails = handler;
    }

    viewRecordDetails() {
        if (this.options.viewRecordDetails != null && this.options.record != null) {
            this.options.viewRecordDetails(this.options.record);
        }
    }
}
