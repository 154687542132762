import {translate} from 'react-admin';
import React, {Children, cloneElement, isValidElement} from 'react';
import {createStyles, withStyles} from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import compose from 'recompose/compose';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Typography } from "@material-ui/core";

// Since we are apparently mixing icon components, the Resource links don;t always play nice
// with all of the properties. This functional component merely wraps the icon and prevents
// the props from being passed to the actual icon.
const IconWrapper = props => {
	const {children} = props;

	return {...children};
};

const myStyles = theme =>
	createStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			paddingTop: '20px',
			paddingBottom: '20px'
		},
		userProfileItem: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			lineHeight: '16px',
			'& span': {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
			},
			'& svg': {
				color: theme.palette.color.important,
			},
		},
		bordered: {
			border: 0,
		},
		padded: {
			paddingLeft: '10px',
			paddingRight: '10px',
		},
		importantText: {
			color: theme.palette.color.important,
			fontSize: 'smaller',
		},
		informationText: {
			color: theme.palette.color.informational,
		},
	});

class UserMenu extends React.Component {
	static propTypes = {
		children: PropTypes.node,
		role: PropTypes.string,
		name: PropTypes.string,
		logout: PropTypes.node,
		icon: PropTypes.node,
		translate: PropTypes.func.isRequired,
	};

	static defaultProps = {
		icon: <AccountCircle />,
	};

	state = {
		auth: true,
		anchorEl: null,
		open: false,
	};

	handleChange = (event, checked) => {
		this.setState({auth: checked});
	};

	handleMenu = event => {
		this.setState({open: true, anchorEl: event.currentTarget});
	};

	handleClose = () => {
		this.setState({anchorEl: null, open: false});
	};

	render() {
		const {
			translate,
			children,
			logout,
			role,
			name,
			classes,
			bordered,
		} = this.props;

		if (!logout && !children) return null;

		const { anchorEl, open } = this.state;

		const updatedUserName = sessionStorage.getItem('editUser');

		const roleLabel =
			role === 'super'
				? translate('Super Admin')
				: role === 'TS-super'
				? translate('TS-Super Admin')
				: role === 'admin'
				? translate('Admin')
				: role === 'manager'
				? translate('Manager')
				: role === 'TS-manager'
				? translate('TS-Manager')
				: role === 'TS-admin'
				? translate('TS-Admin')
				: role === 'user'
				? translate('user')
				: role;
		return (
			<div
				className={classnames({
					[classes.root]: true,
					[classes.padded]: bordered,
				})}
			>
				<div
					className={classnames({
						[classes.userProfileItem]: true,
						[classes.bordered]: bordered,
					})}
					style={{ display: 'flex', gap: '16px', cursor: 'pointer', paddingLeft: bordered ? 0 : '14px' }}
					onClick={(event) => this.handleMenu(event)}
				>
					<IconWrapper>
						<AccountCircle />
					</IconWrapper>
					{bordered &&<div
						style={{flex: '1 1 auto', display: 'flex', flexDirection: 'row'}}
					>
						<div
							style={{
								flex: '1 1 auto',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<span
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									width: '120px',
									display: 'block',
									paddingBottom: '5px',
								}}
							>
								<Typography className={classes.informationText} style={{fontSize: '16px'}}>
									{updatedUserName ? updatedUserName : name}
								</Typography>
							</span>
							<Typography className={classes.importantText} style={{fontSize: '12px'}}>
								{translate(roleLabel)}
							</Typography>
						</div>
					</div>}
					{bordered && <KeyboardArrowDownIcon color='primary' size='medium' />}
				</div>

				<Menu
					onClose={this.handleClose}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					{Children.map(children, menuItem =>
						isValidElement(menuItem)
							? cloneElement(menuItem, {
									onClick: () => {
										menuItem.props.onClick();
										if (menuItem.props && menuItem.props.id === 'logout') return;
										this.handleClose()
									},
							  })
							: null
					)}
					{logout}
				</Menu>
			</div>
		);
	}
}

export default compose(translate, withStyles(myStyles))(UserMenu);
