import {DefaultPortModelOptions, PortModel, PortModelGenerics} from '@projectstorm/react-diagrams';

import {CustomLinkModel} from '../CustomLink/CustomLinkModel';

export interface DefaultPortModelGenerics extends PortModelGenerics {
    OPTIONS: DefaultPortModelOptions;
}

export class CustomPortModel extends PortModel<DefaultPortModelGenerics> {
    constructor(name?: string | null) {
        super({
            type: 'custom',
            name: name ? name : 'default'
        });
    }

    createLinkModel(): CustomLinkModel {
        return new CustomLinkModel();
    }

    link(port: CustomPortModel) {
        const link = this.createLinkModel();

        link.setSourcePort(this);
        link.setTargetPort(port);

        return link;
    }
}
