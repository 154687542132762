import { ScreenTitle } from 'components';
import React, { useEffect, useRef, useState } from 'react';
import { fetchEnd, fetchStart, GET_LIST, useTranslate, useVersion, withDataProvider } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import LifetimeOrgAveragesPanel from './LifetimeOrgAveragesPanel';
import LessonsCompletedByDayPanel from './LessonsCompletedByDayPanel';
import Grid from '../../components/layout/Grid';
import OverviewStatsPanel from './OverviewStatsPanel';
import RecentTeamAveragesPanel from './RecentTeamAveragesPanel';
import TopRecentGraduationsPanel from './TopRecentGraduationsPanel';
import { loadObject, saveObject } from 'utils/Util';
import moment from "moment"
import { GET } from "services/customActions";
import { useDispatch } from 'react-redux';

const Overview = props => {

	const getConfigStorage = JSON.parse(sessionStorage.getItem('runwayOrgConfig'));

	const { overviewProcessedText, overviewProcessedTime } = getConfigStorage.config;

	const translate = useTranslate();

	const version = useVersion();

	const dispatch = useDispatch();

	const mountedRef = useRef(true);

	const { dataProvider } = props;

	const [state, setState] = useState({
		overViewStats: loadObject('overViewStats'),
		overViewStatsError: null,
		lifetimeOrgAverages: loadObject('lifetimeOrgAverages'),
		lifetimeOrgAveragesError: null,
		recentTeamAverages: loadObject('recentTeamAverages'),
		recentTeamAveragesError: null,
		topRecentGraduations: loadObject('topRecentGraduations'),
		topRecentGraduationsError: null,
		lessonsCompletedByDay: loadObject('lessonsCompletedByDay'),
		lessonsCompletedByDayError: null,
	})

	const fetchOverViewPanelStats = async () => {
		await dataProvider(GET, 'overviewstats')
			.then(response => {
				if (!mountedRef.current) return null;
				saveObject('overViewStats', response.data);
				setState(currentState => ({ ...currentState, overViewStats: response.data }));
			})
			.catch(error => setState(currentState => ({ ...currentState, overViewStatsError: error.message })));
	}

	const fecthLifetimeOrgAverages = async () => {
		await dataProvider(GET_LIST, 'LifetimeOrgAverages', {})
			.then(response => {
				saveObject('lifetimeOrgAverages', response.data);
				if (!mountedRef.current) return null;
				setState(currentState => ({ ...currentState, lifetimeOrgAverages: response.data }));
			})
			.catch(error => setState(currentState => ({ ...currentState, lifetimeOrgAveragesError: error.message })));
	};

	const fecthRecentTeamAverages = async () => {
		await dataProvider(GET_LIST, 'RecentTeamAverages', {})
			.then(response => {
				if (!mountedRef.current) return null;
				saveObject('recentTeamAverages', response.data);
				setState(currentState => ({ ...currentState, recentTeamAverages: response.data }));
			})
			.catch(error => setState(currentState => ({ ...currentState, recentTeamAveragesError: error.message })));
	};

	const fetchTopRecentGraduations = async () => {
		await dataProvider(GET_LIST, 'TopRecentGraduations', {})
			.then(response => {
				if (!mountedRef.current) return null;

				saveObject('topRecentGraduations', response.data);
				setState(currentState => ({ ...currentState, topRecentGraduations: response.data }));
			})
			.catch(error => setState(currentState => ({ ...currentState, topRecentGraduationsError: error.message })));
	}

	const fetchLessonsCompletedByDay = async () => {
		await dataProvider(GET_LIST, 'LessonsCompletedByDay', {})
			.then(response => {
				if (!mountedRef.current) return null;

				const formattedLessonsCompletedByDay = formatLessonsCompletedByDay(response.data);

				saveObject('lessonsCompletedByDay', formattedLessonsCompletedByDay);
				setState(currentState => ({ ...currentState, lessonsCompletedByDay: formattedLessonsCompletedByDay }));
			})
			.catch(error => setState(currentState => ({ ...currentState, lessonsCompletedByDayError: error.message })));
	}

	const fetchData = async () => {
		setState({ 
			overViewStats: null,
			lifetimeOrgAverages: null,
			recentTeamAverages: null,
			topRecentGraduations: null,
			lessonsCompletedByDay: null,
		})
		try {
			dispatch(fetchStart())
			if (mountedRef.current) await fetchOverViewPanelStats();
			if (mountedRef.current) await fecthLifetimeOrgAverages();
			if (mountedRef.current) await fecthRecentTeamAverages();
			if (mountedRef.current) await fetchLessonsCompletedByDay();
			if (mountedRef.current) await fetchTopRecentGraduations();
		} finally {
          dispatch(fetchEnd())
		}
	};

	useEffect(() => {
		return () => {
			mountedRef.current = false
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [version]);

	const formatLessonsCompletedByDay = (data) => {
		let formattedLessonsCompletedByDay = [];

		data.forEach(item => {
			if (item && item.value !== null) {
				let label = null;

				switch (new Date(item.labelValue).getDay()) {
					case 0:
						label = 'S';
						break;
					case 1:
						label = 'M';
						break;
					case 2:
						label = 'T';
						break;
					case 3:
						label = 'W';
						break;
					case 4:
						label = 'T';
						break;
					case 5:
						label = 'F';
						break;
					case 6:
						label = 'S';
						break;
					default:
						label = null;
				}

				if (label != null) {
					formattedLessonsCompletedByDay.push({
						labelValue: item.labelValue,
						label: label,
						value: item.value,
					});
				}
			}
		});
		return formattedLessonsCompletedByDay.reverse();
	}

	// if (!tosService.hasUserAcceptedLatestTermsOfService(projectConfig.id)) {
	// 	return <Redirect to='/termsOfService' />;
	// }

	return (
		<div style={{ width: '100%' }}>
			<div style={{ width: '100%', margin: 'auto' }}>
				<ScreenTitle basePath={props.basePath} resource={props.resource} />
				<Typography
					style={{
						fontSize: 40,
						textAlign: 'right',
						paddingRight: 32,
						marginBottom: '50px',
					}}
				>
					{translate('WELCOME TO ')}
					<b>{translate('TALESPIN PLATFORM')}</b>
				</Typography>

				<Grid perRow={1}>
					<OverviewStatsPanel overViewstats={state.overViewStats} error={state.overViewStatsError} />

					<div
						style={{
							width: '100%',
							paddingRight: '16px',
							gridTemplateColumns: 'repeat(2, 50%)',
							gridGap: '20px 20px',
							display: 'grid',
						}}
					>
						<LifetimeOrgAveragesPanel lifetimeOrgAverages={state.lifetimeOrgAverages} error={state.lifetimeOrgAveragesError} />
						<RecentTeamAveragesPanel recentTeamAverages={state.recentTeamAverages} error={state.recentTeamAveragesError} />
						<LessonsCompletedByDayPanel lessonsCompletedByDay={state.lessonsCompletedByDay} error={state.lessonsCompletedByDayError} />
						<TopRecentGraduationsPanel topRecentGraduations={state.topRecentGraduations} error={state.topRecentGraduationsError} />
					</div>
				</Grid>
			</div>

			{getConfigStorage && getConfigStorage !== 'undefined' && getConfigStorage !== null && (
				<div style={{
					textAlign: 'center',
					fontWeight: 'bold',
					marginTop: '2%'
				}}
				>
					{overviewProcessedText} {' '}
					{moment(overviewProcessedTime).format('MMM Do h:mm A')}
				</div>
			)}

		</div>
	);
};

export default withDataProvider(Overview);
