import React, {Component} from 'react';
import {CREATE, withDataProvider} from 'react-admin';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	MenuItem,
	Typography,
	withStyles,
} from '@material-ui/core';
import {DialogTitle, EmbeddedList, Text, SelectInput} from 'components';
import {refreshView, translate} from 'ra-core';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {DialogListTheme} from 'components/Theme';
import {DELETE_USER_FROM_TEAMS} from 'services/customActions';

const styles = theme => ({
	importantText: {
		color: theme.palette.primary.main,
	},
});

class RemoveUserFromTeamButton extends Component {
	state = {
		showDialog: false,
		showChooseManagerDialog: false,
		selectedTeamManagers: [],
		newTeamManagerId: null,
	};

	handleClick = e => {
		e.preventDefault();
		this.setState({
			showDialog: true,
			showChooseManagerDialog: false,
			selectedTeamManagers: [],
			newTeamManagerId: null,
		});
	};

	handleClose = e => {
		this.setState(
			{
				showDialog: false,
				showChooseManagerDialog: false,
				selectedTeamManagers: [],
				newTeamManagerId: null,
			},
			() => {
				this.props.onClick && this.props.onClick(e);
			}
		);
	};

	handleChooseManagerClick = e => {
		e.preventDefault();
		this.setState({showChooseManagerDialog: true});
	};

	handleChooseManagerClose = e => {
		e.preventDefault();
		this.setState({showChooseManagerDialog: false});
	};

	handleManagerSelectionToggled = record => {
		this.setState({selectedTeamManagers: [record]});
	};

	handleChooseManagerSubmit = e => {
		const {selectedTeamManagers} = this.state;

		if (selectedTeamManagers && selectedTeamManagers.length > 0) {
			this.setState({
				showChooseManagerDialog: false,
				newTeamManagerId: selectedTeamManagers[0].id,
			});
		}
	};

	handleSubmit = e => {
		const {userData, teamData, dataProvider} = this.props;
		const {selectedTeamManagers, newTeamManagerId} = this.state;

		const newTeamManager = selectedTeamManagers.find(
			item => item.id === newTeamManagerId
		);

		if (
			userData.id !== teamData.managerId ||
			(userData.id === teamData.managerId &&
				newTeamManagerId &&
				newTeamManagerId !== teamData.managerId)
		) {
			if (newTeamManager) {
				dataProvider(CREATE, 'teamsManager', {
					data: {
						teams: [
							{
								id: teamData.id,
								ownerId: newTeamManager.id,
								ownerName: newTeamManager.name,
							},
						],
					},
				}).then(() => {
					dataProvider(
						DELETE_USER_FROM_TEAMS,
						'users',
						{
							data: {teams: [teamData.id], profileId: userData.id},
						},
						{
							onSuccess: {
								refresh: true,
								unselectAll: true,
							},
							onFailure: {
								unselectAll: true,
								notification: {level: 'warning'},
							},
						}
					).then(() => {
						this.setState(
							{
								showDialog: false,
								showChooseManagerDialog: false,
								selectedTeamManagers: [],
								newTeamManagerId: null,
							},
							() => {
								this.props.onClick && this.props.onClick(e);
								this.props.refreshView();
							}
						);
					});
				});
			} else {
				dataProvider(
					DELETE_USER_FROM_TEAMS,
					'users',
					{
						data: {teams: [teamData.id], profileId: userData.id},
					},
					{
						onSuccess: {
							refresh: true,
							unselectAll: true,
						},
						onFailure: {
							unselectAll: true,
							notification: {level: 'warning'},
						},
					}
				).then(() => {
					this.setState(
						{
							showDialog: false,
							showChooseManagerDialog: false,
							selectedTeamManagers: [],
							newTeamManagerId: null,
						},
						() => {
							this.props.onClick && this.props.onClick(e);
							this.props.refreshView();
						}
					);
				});
			}
		}
	};

	render() {
		const {translate, userData, teamData, classes} = this.props;
		const {
			showDialog,
			showChooseManagerDialog,
			selectedTeamManagers,
			newTeamManagerId,
		} = this.state;

		return (
			<div>
				<MenuItem onClick={this.handleClick}>
					{translate('Remove From Team')}
				</MenuItem>

				{/* Primary Remove User From Team dialog */}
				<Dialog
					open={showDialog}
					aria-label={translate('Remove User From Team')}
				>
					<DialogTitle onClose={this.handleClose}>
						<Text
							style={{fontSize: '20px'}}
							label={translate('Remove User From Team')}
						/>
					</DialogTitle>

					<DialogContent>
						<div style={{paddingTop: '20px'}}>
							<Typography>
								{translate('Do you want to remove the user')}
								<span className={classes.importantText}> {userData.name} </span>
								{translate('from the team')}
								<span className={classes.importantText}> {teamData.name}</span>
								?
								<br />
								<br />
								{translate(
									"This user will lose access to all of team's module and lessons."
								)}
								<br />
								<br />
							</Typography>

							{userData.id === teamData.managerId && (
								<div
									style={{
										marginTop: '20px',
										marginBottom: '20px',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Typography>
										This user is the manager of this team. Please assign a new
										manager
									</Typography>

									<div style={{marginTop: '20px', width: '60%'}}>
										<SelectInput
											value={'Select a Manager'}
											onClick={e => this.handleChooseManagerClick(e)}
										/>
									</div>
								</div>
							)}
						</div>
					</DialogContent>

					<DialogActions>
						<Button
							color={'primary'}
							variant={'contained'}
							med
							onClick={this.handleSubmit}
							disabled={userData.id === teamData.managerId && !newTeamManagerId}
						>
							{translate('Remove User')}
						</Button>
					</DialogActions>
				</Dialog>

				{/* Secondary Choose New Manager Dialog */}
				<MuiThemeProvider theme={DialogListTheme}>
					<Dialog
						maxWidth='md'
						open={showChooseManagerDialog}
						aria-label={translate('Select Manager')}
					>
						<DialogTitle onClose={this.handleChooseManagerClose}>
							<Text
								style={{fontSize: '20px'}}
								label={translate('Select Manager')}
							/>
						</DialogTitle>
						<DialogContent>
							<div
								style={{
									flex: '1 1 auto',
									display: 'flex',
									flexDirection: 'column',
									overflow: 'auto',
								}}
							>
								<EmbeddedList
									embedded={true}
									cached={false}
									title={translate('Available Users')}
									resource={'users'}
									columns={[
										{
											id: 'userId',
											name: translate('User ID'),
											sortable: true,
										},
										{
											id: 'name',
											name: translate('User Name'),
											sortable: true,
										},
										{
											id: 'teams',
											name: translate('Teams'),
											sortable: true,
											width: '125px',
											align: 'center',
										},
										{
											id: 'trainings',
											name: translate('Module'),
											sortable: true,
											width: '125px',
											align: 'center',
										},
									]}
									sort={{field: 'name', order: 'ASC'}}
									staticFilters={[
										{id: 'id', value: {$nin: [userData.id]}},
										{id: 'status', value: 'enabled'},
									]}
									dynamicFilters={[
										{id: 'name', name: translate('Search'), type: 'regex'},
										{
											id: 'role',
											name: translate('Role'),
											type: 'exact',
											choices: [
												{value: '*', label: translate('All Roles')},
												{value: 'admin', label: translate('Admin')},
												{value: 'manager', label: translate('Manager')},
												{value: 'user', label: translate('User')},
											],
										},
									]}
									bulkActions={null}
									actions={null}
									rowClicked={null}
									selectedIds={selectedTeamManagers.map(item => item.id)}
									selectionToggled={this.handleManagerSelectionToggled}
									stripped={false}
								/>
							</div>
						</DialogContent>
						<DialogActions>
							<Button
								color={'primary'}
								variant={'contained'}
								med
								onClick={this.handleChooseManagerSubmit}
								disabled={
									!selectedTeamManagers || selectedTeamManagers.length === 0
								}
							>
								{translate('Select Manager')}
							</Button>
						</DialogActions>
					</Dialog>
				</MuiThemeProvider>
			</div>
		);
	}
}

RemoveUserFromTeamButton.propTypes = {
	dataProvider: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired,
	userData: PropTypes.object.isRequired,
	teamData: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {};
}

const mapDispatchToProps = {
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withDataProvider,
	translate,
	withStyles(styles)
);

export default enhance(RemoveUserFromTeamButton);
