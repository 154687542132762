import {createStyles} from '@material-ui/core/styles';

const style = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			overflow: 'visible',
			maxWidth: '740px',
			margin: 'auto',
		},
		logo: {
			width: '234px',
			alignSelf: 'center',
		},
		cardHeader: {
			alignSelf: 'center',
		},
		accountStatus: {
			alignSelf: 'center',
			color: '#346dec',
		},
		cardContent: {
			display: 'flex',
			flexDirection: 'column',
		},

		normalText: {
			color: theme.palette.color.default,
		},
		informationalText: {
			color: theme.palette.color.informational,
		},
		section: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			marginTop: '1em',
			marginBottom: '1em',
			backgroundColor: '#00142E',
			borderRadius: '4px',
			MozBoxShadow: '0px 5px 20px #00000026',
			boxShadow: '0px 5px 20px #00000026',
		},
		sectionHeader: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			backgroundColor: theme.palette.background.contained,
			height: '48px',
			paddingLeft: '2em',
			paddingRight: '2em',

			MozBorderRadiusTopleft: '4px',
			MozBorderRadiusTopright: '4px',
			borderTopLeftRadius: '4px',
			borderTopRightRadius: '4px',
		},
		sectionContent: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			padding: '2em',
		},
		sectionFooter: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			backgroundColor: theme.palette.background.contained,
			height: '16px',
			paddingLeft: '2em',
			paddingRight: '2em',
			borderBottomLeftRadius: '4px',
			borderBottomRightRadius: '4px',
		},
		usersFilter: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		emailLink: {
			color: theme.palette.primary.main,
		},
	});

export default style;
