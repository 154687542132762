import React, { useEffect, useState } from 'react';
import { fetchEnd, fetchStart, GET_LIST, useDataProvider, useQueryWithStore, useVersion } from 'react-admin';

import Lesson from './Lessons/Lesson';
import LessonList from './Lessons/LessonList';
import LoadingList from '../../../../components/loading/LoadingList';
import LoadingStat from '../../../../components/loading/LoadingStat';
import PanelStat from '../../../../components/Panels/PanelStat';

import TrainingCatalogue from '../../../../components/TrainingCatalogue';
import { getOrgId, loadObject } from '../../../../utils/Util';
import {
	EmbeddedShow,
	EmbeddedTabs,
	ScreenTitle,
	StatsTilePanel,
	TextValue,
	TimeDateField,
} from 'components';
import SkillsTab from 'sections/Team/Show/Training/SkillsTab';
import LabList from 'sections/Team/Show/Training/Labs/LabList';
import LabDetail from 'sections/Team/Show/Training/Labs/LabDetail';
import { GET, GET_TRAINING } from "services/customActions";
import { useDispatch } from "react-redux";
import { getUserInfo } from "utils/Data";
import TeamTrainingStats from "sections/Team/Show/Training/TeamTrainingStats";

const TrainingDetail = props => {
	const {
		backTrainingAction,
		selectedTrainingRecord,
		teamData,
		selectedSkills,
		skillsSelected,
	} = props;

	const dataProvider = useDataProvider();

	const version = useVersion();

	const [trainingData, setTrainingData] = useState(null);

	const [selectedLessonRecord, setSelectedLessonRecord] = useState(null);

	const [selectedLabRecord, setSelectedLabRecord] = useState(null);

	const [loading, setLoading] = useState(true);

	const [isPlayable, setIsPlayable] = useState(false);

	const summaryFriendData = loadObject(loadObject('project').id + '-summaryFriendData');

	const isTester = summaryFriendData?.isTester ? true : false;

	const dispatch = useDispatch();

	const lessonSelected = record => {
		setSelectedLessonRecord(record);
	};

	const backLessonAction = () => {
		setSelectedLessonRecord(null);
	};

	const labSelected = record => {
		setSelectedLabRecord(record);
	};

	const backLabAction = () => {
		setSelectedLabRecord(null);
	};

	const fertchTrainingDetail = async () => {
		setLoading(true);
		dispatch(fetchStart());
		await dataProvider(GET_TRAINING, "listtrainingforteam", {
			id: selectedTrainingRecord.id,
			filter: {
				teamId: props.id,
				pagination: { page: 1, perPage: 1 },
				sort: {},
				orgId: getOrgId()
			}
		}).then(async (res) => {
			setTrainingData(res.data);
			await isTSAPlayable(res.data);
		}).catch((e) => {
			console.error(e);
			setTrainingData({});
			setLoading(false);
		}).finally(() => {
		   dispatch(fetchEnd());
		})
	}

	const isTSAPlayable = async (data) => {
		if (
			data && data.accessibility.licensed.value &&
			data.isCPD &&
			(data.trainingDetail.moduleStatus === 'Published' ||
				getUserInfo().trainingModuleEditor ||
				isTester)
		) {
			dispatch(fetchStart());
			await dataProvider(GET, 'isTSAPlayable', { appKeyName: data?.appKeyName })
				.then(res => {
					if (res.success) {
						setIsPlayable(true);
					}
					setLoading(false);
				})
				.catch(e => {
					console.error(e);
					setIsPlayable(false);
				})
				.finally(() => {
					dispatch(fetchEnd());
			        setLoading(false);
				});
		} else {
			dispatch(fetchEnd());
			setLoading(false);
		}
	}

	useEffect(() => {
		fertchTrainingDetail();
	}, [version]);

	return (
		<div>
			{!selectedLessonRecord && !selectedLabRecord && (
				<>
					<div>
						{' '}
						<ScreenTitle
							title={selectedTrainingRecord.name}
							refresh={false}
							subTitle={true}
							coloredCrumb={true}
							backActionCrumbs={[backTrainingAction]}
							titleCrumbs={[
								teamData.name.trim().length > 0 ? teamData.name : teamData.id,
							]}
						/>
						{(trainingData && !loading) ? (
							<TrainingCatalogue
								trainingData={trainingData}
								isExpandMore={
									loadObject('expandTeamTrainingcatalogue') === null
										? false
										: loadObject('expandTeamTrainingcatalogue')
								}
								toggleKey={'expandTeamTrainingcatalogue'}
								isTSAPlayable={isPlayable}
								information={
									<EmbeddedShow
										embedded
										headerless
										title={'Information'}
										actions={[]}
										details={[
											{
												label: 'Name',
												render: () => (
													<TextValue
														record={trainingData}
														maxWidth='300px'
														source='name'
													/>
												),
											},
											{
												label: 'Created',
												render: () => (
													<TimeDateField
														record={trainingData}
														source='createdAt'
													/>
												),
											},
											{
												label: 'Modified',
												render: () => (
													<TimeDateField
														record={trainingData}
														source='updatedAt'
													/>
												),
											},
											{
												label: 'Id',
												render: () => (
													<TextValue record={trainingData} source='id' />
												),
											},
										]}
									/>
								}
							></TrainingCatalogue>
						) : (
							<LoadingList />
						)}
					</div>
					{!loading && <div>
						<TeamTrainingStats id={props.id} selectedTrainingRecord={props.selectedTrainingRecord} />
						<EmbeddedTabs
							data={{
								tabs: [
									{
										name:
											selectedTrainingRecord &&
												selectedTrainingRecord.labDisplayEnabled
												? 'Labs'
												: 'Lessons',
										content:
											selectedTrainingRecord &&
												selectedTrainingRecord.labDisplayEnabled ? (
												<LabList
													{...props}
													labSelected={labSelected}
													selectedSkills={selectedSkills}
													skillsSelected={skillsSelected}
												/>
											) : (
												<LessonList
													{...props}
													lessonSelected={lessonSelected}
													selectedSkills={selectedSkills}
													skillsSelected={skillsSelected}
												/>
											),
									},
									{
										name: 'Skills',
										content: <SkillsTab {...props} />,
									},
								],
							}}
						/>
					</div>}
				</>
			)}

			<Lesson
				{...props}
				selectedLessonRecord={selectedLessonRecord}
				backLessonAction={backLessonAction}
			/>

			{selectedLabRecord && (
				<LabDetail
					{...props}
					selectedLabRecord={selectedLabRecord}
					backLabAction={backLabAction}
				/>
			)}
		</div>
	);
};

export default TrainingDetail;
