import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
	tableHead: {
		borderBottom: 'none',
		backgroundColor: theme.palette.background.default,
	},
	tableRow: {
		padding: '5px 0px',
	},
}));

const StaticTable = ({data, columns, actions, ...props}) => {
	const classes = useStyles();

	const [tableData, setTableData] = useState([]);
	useEffect(() => {
		if (data && data.length > 0) {
			setTableData([...data]);
		}
	}, [data]);

	const renderTable = () => {
		if (tableData && tableData.length > 0) {
			return (
				<div>
					<TableContainer component={Paper}>
						<Table aria-label='simple table'>
							<TableHead>
								<TableRow>
									{columns.map((item, idx) => {
										return (
											<>
												<TableCell
													className={classes.tableHead}
													align='left'
													key={idx}
												>
													{item.name}
												</TableCell>
												{actions &&
													actions.map(action => (
														<TableCell
															className={classes.tableHead}
															key={idx}
														></TableCell>
													))}
											</>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData.map((row, idx) => {
									const keys = Object.keys(row);
									if (typeof row !== 'object') {
										return (
											<TableRow className={classes.tableRow} key={idx}>
												<TableCell style={{width: '80%'}}>{row}</TableCell>

												{actions &&
													actions.map(action => {
														return (
															<TableCell
																className={classes.tableRow}
																style={{
																	cursor: 'pointer',
																}}
																onClick={() =>
																	action.onClick(tableData[idx], idx)
																}
															>
																{action.icon}
															</TableCell>
														);
													})}
											</TableRow>
										);
									} else {
										return (
											<TableRow className={classes.tableRow} key={idx}>
												{keys.map((key, idx) => {
													return <TableCell>{row[key]}</TableCell>;
												})}
											</TableRow>
										);
									}
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			);
		} else {
			return <></>;
		}
	};

	return <div>{renderTable()}</div>;
};

StaticTable.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.arrayOf({
		id: PropTypes.string,
		align: PropTypes.string,
		name: PropTypes.string,
	}),
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			icon: PropTypes.any.isRequired,
			onClick: PropTypes.func.isRequired,
		})
	),
};

export default StaticTable;
