import React from 'react';

const Section = props => {
	const {width = '100%', height = '100%', padding = 0, children} = props;

	return (
		<div
			style={{
				borderBottom: '1px solid',
				borderColor: '#0F4358',
				padding: padding,
			}}
		>
			<div
				style={{
					width: width,
					padding: '',
					height: height,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					flexGrow: 1,
				}}
			>
				{children}
			</div>
		</div>
	);
};

export default Section;
