import 'date-fns';

import {
	CREATE,
	Create,
	email,
	fetchEnd,
	fetchStart,
	GET_LIST,
	minLength,
	required,
	SimpleForm,
	translate,
	withDataProvider,
} from 'react-admin';
import {
	DialogTitle,
	EmbeddedList,
	SelectInput,
	Text,
	TextInput,
} from 'components';
import React, {Component} from 'react';
import {Route} from 'react-router';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {getUserInfo} from 'utils/Data';
import {
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	InputLabel,
	Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {refreshView} from 'ra-core';
import {withRouter} from 'react-router-dom';
import {isValidDate, loadObject} from 'utils/Util';
import {GET, GET_USER_ROLE_LIST} from 'services/customActions';
import LoadingInfo from 'components/loading/LoadingInfo';
import DatePicker from 'components/DatePicker';
import PALUnavailableDialog from 'components/PALUnavailableDialog';
import moment from "moment";
import { AddOutlined } from '@material-ui/icons';

export const statusChoices = [
	{id: 'enabled', name: 'Enabled'},
	{id: 'disabled', name: 'Disabled'},
];

const myStyles = theme =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		authTypeHeader: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingTop: '8px',
			fontFamily: 'Montserrat Medium',
			'& > label:first-of-type': {
				flex: '1 1 auto',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'flex-start',
			},
			'& > label:last-of-type': {
				flex: '1 1 auto',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'flex-end',
				color: theme.palette.text.info,
			},
		},
		divider: {
			backgroundColor: theme.palette.borderColor.divider,
			marginBottom: '12px',
		},
		fieldRow: {
			width: 'auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-start',
			marginTop: '12px',
			marginBottom: '12px',
			'& > div': {
				flex: '1 1 0',
			},
		},
		fieldColumn: {
			width: '50%',
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		basicField: {
			width: '100%',
			minHeight: '60px',
			marginTop: '12px',
			marginBottom: '8px',
		},
		normalText: {
			color: theme.palette.color.default,
		},
		informationalText: {
			color: theme.palette.color.informational,
		},
		importantText: {
			color: theme.palette.color.important,
		},
		createAndAddButton: {
			backgroundColor: theme.palette.background.alternateButton,
			color: theme.palette.color.alternateButton,
			borderColor: theme.palette.borderColor.alternateButton,
			borderWidth: '1px',
			borderStyle: 'solid',
			borderRadius: '4px',
			marginRight: '10px',
			'&:hover': {
				backgroundColor: theme.palette.background.alternateButton,
			},
		},
		createButton: {
			backgroundColor: theme.palette.background.primaryButton,
			color: theme.palette.color.primaryButton,
			borderColor: theme.palette.borderColor.primaryButton,
			borderWidth: '1px',
			borderStyle: 'solid',
			borderRadius: '4px',
			'&:hover': {
				backgroundColor: theme.palette.background.primaryButton,
			},
		},
		teamButton: {
			backgroundColor: theme.palette.background.alternateButton,
			color: theme.palette.color.alternateButton,
			borderColor: '#0f4358',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderRadius: '4px',
			marginRight: '10px',
			'&:hover': {
				backgroundColor: theme.palette.background.alternateButton,
			},
		},
		selectedTeamChip: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'nowrap',
			borderRadius: '2px',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: theme.palette.borderColor.alternateButton, //theme.palette.color.default,
			paddingLeft: '5px',
			paddingRight: '5px',
			margin: '3px 3px 3px 0px',
			height: '28px',
			backgroundColor: theme.palette.background.alternateButton, //theme.palette.background.default,
			color: theme.palette.color.alternateButton,
			'& > svg': {
				marginLeft: '5px',
				color: 'inherit',
				cursor: 'pointer',
			},
			'& label': {
				maxWidth: '173px',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
			},
		},
		noTeamSelected: {
			flex: '0 0 auto',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'nowrap',
			paddingLeft: '5px',
			paddingRight: '5px',
			margin: '3px 3px 3px 0px',
			backgroundColor: theme.palette.background.default,
			color: theme.palette.color.default,
			height: '28px',
		},
		inputLabel: {
			paddingTop: '1px',
		},
		spinnerStyles: {
			position: 'relative',
			marginRight: '5px',
			top: '2px',
			color: theme.palette.color.default,
		},
		disabled: {
			'& input': {
				color: '#072a44 !important',
			},

			'& span': {
				color: '#072a44 !important',
			},
			'& label': {
				color: '#072a44 !important',
			},
		},
		buttonBar: {
			display: 'flex',
		},
		pickers: {
			':focus': {
				color: '1px solid red',
			},
		},
	});

class UserCreate extends Component {
	state = {
		authType: 'universal',
		createUserAuthType: 'universal',
		externalIdentityName: '',
		alternateCreateUserIdLabel: null,
		defaultUserRole: null,
		defaultTeamId: null,
		availableTeams: [],
		selectedTeams: [],
		isCreateProgress: false,
		form: {
			userId: null,
			password: null,
			firstName: null,
			lastName: null,
			teamIds: null,
			role: null,
			contactEmail: null,
			employeeId: null,
			welcomeEmail: false,
			tester: false,
			authType: null,
			welcomeEmailTime: '',
			jobRole: null,
			trainingModuleEditor: false,
			lmsId: null
		},
		addAnother: false,
		showTeamsList: false,
		sortSelectionTop: false,
		time: null,
		sendWelcomeType: 'sendNow',
		errorMessage: null,
		errorMessageLog: false,
		isLoading: true,
		timeValue: moment(new Date(new Date(Date.now()).setSeconds(0))).add(5, 'm').toDate(),
		dateValue: new Date(Date.now()),
		jobRoles: [],
		userRoles: [],
		openJobRoleDropDown: false,
		jobRoleSearchValue: null,
		isCheckBoxHidden: false,
		emailLength: false,
		isEmailValid: false,
		isChecked: false,
		isNewPALLicenseAvailable: true,
		palUnavailableErrorMessage: null,
		isDateTimeValid: true,
		uniqueLmsId: true,
	};

	constructor(props) {
		super(props);
		this.selectTeamInputRef = React.createRef();
	}

	componentDidMount() {
		this.fetchData();
	}

	isNewPALLicenseAvailable = async () => {
		const {dataProvider} = this.props;

		let newLicenseAvailable = true;

		await dataProvider(GET, 'isNewPALLicenseAvailable', {
			numToCreate: 1,
		}).catch(error => {
			newLicenseAvailable = false;
			if (getUserInfo().role.includes('TS')) {
			  this.setState({isNewPALLicenseAvailable: true});
			} else {
				this.setState({isNewPALLicenseAvailable: false, isLoading: false, palUnavailableErrorMessage: error?.message});
			}
		});

		return newLicenseAvailable;
	};

	isUniqueLmsId = async (lmsId) => {
		const {dataProvider} = this.props;

		try {
			const res = await dataProvider(GET, 'verifyLMSIdForProfile', {profileId: "", lmsId: lmsId});
			this.setState({uniqueLmsId: res.data.data.valid, isCreateProgress: res.data.data.valid ? true : false});
			return res.data.data.valid;
		} catch(e) {
			console.log(e);
			this.setState({uniqueLmsId: false, isCreateProgress: false});
		}
	}

	async fetchData() {
		const {dataProvider} = this.props;
		let {
			authType,
			createUserAuthType,
			externalIdentityName,
			alternateCreateUserIdLabel,
			defaultUserRole,
			defaultTeamId,
			selectedTeams,
		} = this.state;
		const form = {...this.state.form};

		const project = sessionStorage.getItem('project');

		if (project) {
			try {
				const orgConfig = JSON.parse(project);

				if (orgConfig && orgConfig.config) {
					if (orgConfig.config.authType) {
						authType = orgConfig.config.authType;
					}

					if (orgConfig.config.createUserAuthType) {
						createUserAuthType = orgConfig.config.createUserAuthType;
					}

					if (orgConfig.config.externalIdentityName) {
						externalIdentityName = orgConfig.config.externalIdentityName;
					}
				}
			} catch (error) {
				console.log('Unable to load organization config...', error);
			}
		}

		const runwayOrgConfig = loadObject('runwayOrgConfig');

		if (runwayOrgConfig && runwayOrgConfig.config) {
			if (
				runwayOrgConfig.config.alternateCreateUserIdLabel &&
				runwayOrgConfig.config.alternateCreateUserIdLabel.trim().length > 0
			) {
				alternateCreateUserIdLabel =
					runwayOrgConfig.config.alternateCreateUserIdLabel;
			}

			if (runwayOrgConfig.config.defaultUserRole) {
				defaultUserRole = runwayOrgConfig.config.defaultUserRole;

				// If we are not an admin or no default user role is indicated, the default use creation role becomes user
				if (
					(!getUserInfo().isAdmin && !getUserInfo().isSuper) ||
					!defaultUserRole ||
					defaultUserRole.trim().length === 0
				) {
					defaultUserRole = 'user';
				}

				form.role = defaultUserRole;
			}

			if (runwayOrgConfig.config.defaultTeamId) {
				defaultTeamId = runwayOrgConfig.config.defaultTeamId;

				if (!defaultTeamId || defaultTeamId.trim().length === 0) {
					defaultTeamId = null;
				}

				if (defaultTeamId) {
					form.teamIds = [defaultTeamId];
					selectedTeams = [defaultTeamId];
				}
			}
		}

		const newLicenseAvailable = await this.isNewPALLicenseAvailable();

		if (!newLicenseAvailable && !getUserInfo().role.includes('TS')) {
			return;
		}

		let teamsResponse = await dataProvider(GET_LIST, 'teams', {
			sort: {field: 'name', order: 'ASC'},
			pagination: {page: 1, perPage: 9999},
		});

		let jobRolesResponse = await dataProvider(
			GET_LIST,
			'getJobRoleListRunway',
			{}
		);

		let userRolesResponse = await dataProvider(
			GET_USER_ROLE_LIST,
			'getUserRoleListRunway',
			{}
		);

		const userRoles = userRolesResponse.data;
		this.setState({
			availableTeams: teamsResponse.data,
			authType,
			createUserAuthType,
			externalIdentityName,
			alternateCreateUserIdLabel,
			defaultUserRole: (!newLicenseAvailable && getUserInfo().role.includes('TS')) ? 'TS-user' : defaultUserRole,
			defaultTeamId,
			form: (!newLicenseAvailable && getUserInfo().role.includes('TS')) ? {...form, role: 'TS-user'} : form,
			selectedTeams,
			isLoading: false,
			jobRoles: jobRolesResponse.data,
			userRoles: (!newLicenseAvailable && getUserInfo().role.includes('TS')) ? userRoles.filter(role => role.name.includes('TS')) : userRoles
		});
	}

	handleChange = (name, event) => {
		const record = {...this.state.form};

		if (record[name] !== undefined) {
			record[name] = event.target.value;

			this.setState({form: record});
		} else {
			console.error('handleChange - Unknown field', name, record[name]);
		}
		if (record[name] === record.contactEmail) {
			if (this.state.createUserAuthType !== 'email') {
				// eslint-disable-next-line
				let checkValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				if (!checkValidEmail.test(record.contactEmail)) {
					this.setState({isCheckBoxHidden: false, isEmailValid: true});
				} else {
					this.setState({isCheckBoxHidden: true, isEmailValid: false});
				}
			}
		}
	};

	handeleJobRoleChange = value => {
		const record = {...this.state.form};
		record.jobRole = value;
		this.setState({form: {...record}, openJobRoleDropDown: false});
	};

	handleOpen = () => {
		this.setState({jobRoleSearchValue: ''});
	};

	handleTypeChange = welcomeType => {
		this.setState({sendWelcomeType: welcomeType});
	};

	handleDateChange = date => {
		this.setState({dateValue: date});
	};

	handelTimeChange = time => {
		this.setState({timeValue: time});
	};

	handleToggle = (name, event) => {
		const record = {...this.state.form};

		if (record[name] !== undefined) {
			record[name] = event.target.checked;

			this.setState({form: record});
		} else {
			console.error('handleChange - Unknown field', name, record[name]);
		}
		let checkedItem = document.getElementById('contactEmailCheck');

		if (checkedItem.checked === true) {
			this.setState({isChecked: true});
		} else {
			this.setState({isChecked: false});
		}
	};

	handleTeamAddition = teamId => {
		const {selectedTeams} = this.state;

		if (!selectedTeams.includes(teamId)) {
			selectedTeams.push(teamId);
		} else {
			const index = selectedTeams.indexOf(teamId);
			if (index > -1) {
				selectedTeams.splice(index, 1);
			}
		}

		this.setState({selectedTeams, sortSelectionTop: true});
	};

	handleTeams = () => {
		const record = {...this.state.form};
		const {selectedTeams} = this.state;

		record.teamIds = [];

		selectedTeams.forEach(teamId => {
			if (!record.teamIds.includes(teamId)) {
				record.teamIds.push(teamId);
			}
		});

		this.setState({form: record, showTeamsList: false});
	};

	handleTeamRemoval = teamId => {
		const record = {...this.state.form};
		const {selectedTeams} = this.state;

		if (record.teamIds) {
			const idx = record.teamIds.indexOf(teamId);

			if (idx >= 0) {
				record.teamIds.splice(idx, 1);
				selectedTeams.splice(idx, 1);
			}
		}

		this.setState({form: record, selectedTeams});
	};

	handleClose = () => {
		this.props.push('/users');
	};

	handleCreate = addAnother => {
		this.setState(
			{
				addAnother: addAnother ? true : false,
				isCreateProgress: true,
				isCheckBoxHidden: false,
				jobRoleSearchValue: '',
			},
			() => {
				this.handleCreateUserSubmit();
			}
		);
	};

	handleCreateUserSubmit = () => {
		this.saveData();
	};

	handleJobRoleInputChange = value => {
		this.setState({
			jobRoleSearchValue: value,
			openJobRoleDropDown: value ? true : false,
		});
	};

	saveData = async () => {
		this.setState({errorMessage: null, error: '', errorMessageLog: false, uniqueLmsId: true });
		const {dataProvider, refreshView, translate} = this.props;
		const alternateCreateUserIdLabel = this.state.alternateCreateUserIdLabel;
		const addAnother = this.state.addAnother;
		const record = {...this.state.form};

		// if (
		// 	this.state.createUserAuthType === 'universal' &&
		// 	record.welcomeEmail &&
		// 	(record.contactEmail == null || record.contactEmail.trim().length === 0)
		// ) {
		// 	this.setState({
		// 		errorMessage: 'An Email is needed to send Welcome Email',
		// 		isCreateProgress: false,
		// 		errorMessageLog: true,
		// 	});
		// 	return;
		// }
		if (record.userId) record.userId = record.userId.trim();
		if (record.firstName) record.firstName = record.firstName.trim();
		if (record.lastName) record.lastName = record.lastName.trim();
		if (record.contactEmail) {
			record.contactEmail = record.contactEmail.trim();
		} else if (this.state.createUserAuthType === 'email') {
			record.contactEmail = record.userId.trim();
		}

		if (record.lmsId) record.lmsId = record.lmsId.trim();

		if (record.lmsId != null && record.lmsId !== "" && !await this.isUniqueLmsId(record.lmsId)) return;

		if (this.state.sendWelcomeType === 'schedule' && record.welcomeEmail) {
			const timeString = this.state.timeValue.toString();
			const timeSliced = timeString.slice(15, timeString.length);
			const dateSliced = this.state.dateValue.toString().slice(0, 15);
			const value = new Date(dateSliced + timeSliced);
			this.setState({time: value});
			record.welcomeEmailTime = Date.parse(value.toUTCString());
		} else {
			record.welcomeEmailTime = '';
		}

		if (record.employeeId) record.employeeId = record.employeeId.trim();
		if (record.jobRole) record.jobRole = record.jobRole.trim();
		record.authType = this.state.createUserAuthType;

		// to send the right welcome email for the type external
		record.welcomeEmailType = this.state.authType;

		if (record.trainingModuleEditor)
			record.trainingModuleEditor =
				record.role === 'user' || record.role === 'manager'
					? false
					: record.trainingModuleEditor;

		//https://talespin.atlassian.net/browse/RP-1503

		if (record.role && record.role.includes('TS-')) record.tester = true;

		// Dispatch an action letting react-admin know a API call is ongoing
		fetchStart();
		dataProvider(CREATE, 'users', {data: record})
			.then(async ({data}) => {
				if (addAnother) {
					const isNewPALLicenseAvailable = await this.isNewPALLicenseAvailable();

					if (!isNewPALLicenseAvailable && !getUserInfo().role.includes('TS')) {
						return;
					}
					if (!isNewPALLicenseAvailable && getUserInfo().role.includes('TS')) {
						const { userRoles } = this.state;
						this.setState({ userRoles: userRoles.filter(role => role.name.includes('TS'))});
					}
					this.setState({
						form: {
							userId: null,
							password: null,
							firstName: null,
							lastName: null,
							jobRole: null,
							teamIds: record.teamIds ? record.teamIds : '',
							role: !isNewPALLicenseAvailable && getUserInfo().role.includes('TS') ? 'TS-user' : this.state.defaultUserRole,
							contactEmail: null,
							employeeId: null,
							lmsId: null,
							welcomeEmail: record.welcomeEmail,
							tester: record.tester,
							trainingModuleEditor: record.trainingModuleEditor,
							welcomeEmailTime:
								this.state.sendWelcomeType === 'schedule'
									? this.state.time
									: new Date(Date.now()),
						},
						defaultUserRole: !isNewPALLicenseAvailable && getUserInfo().role.includes('TS') ? 'TS-user' : this.state.defaultUserRole,
						sendWelcomeType: this.state.sendWelcomeType,
						timeValue:
							this.state.sendWelcomeType === 'schedule'
								? this.state.time
								: new Date(Date.now()),
						dateValue:
							this.state.sendWelcomeType === 'schedule'
								? this.state.time
								: new Date(Date.now()),
					});
				} else {
					this.handleClose();
				}
			})
			.catch(error => {
				console.error(`Create failed for user ${JSON.stringify(error)}`, error);

				if (error.message.indexOf('User credential already exists')) {
					/*
					stopSubmit('create-user-form', {
						userId:
							(alternateCreateUserIdLabel
								? alternateCreateUserIdLabel
								: translate('Identifier')) + translate(' already in use'),
					});
					*/

					this.setState({
						error:
							(alternateCreateUserIdLabel
								? alternateCreateUserIdLabel
								: translate('Identifier')) + translate(' already in use'),
						isCreateProgress: false,
					});
					fetchEnd();
				}
			})
			.finally(() => {
				// Dispatch an action letting react-admin know a API call has ended
				refreshView();
				this.setState({isCreateProgress: false});
				fetchEnd();
			});
	};

	renderTeams = () => {
		const {classes, translate} = this.props;
		const record = this.state.form;
		const availableTeams =
			this.state.availableTeams && Array.isArray(this.state.availableTeams)
				? this.state.availableTeams
				: [];

		const result = [];

		if (
			record.teamIds &&
			Array.isArray(record.teamIds) &&
			record.teamIds.length > 0
		) {
			record.teamIds.forEach(teamId => {
				const team = availableTeams.find(item => item.id === teamId);

				if (team) {
					result.push(
						<div key={teamId} className={classes.selectedTeamChip}>
							<label>{team.name}</label>
							<CloseIcon onClick={() => this.handleTeamRemoval(teamId)} />
						</div>
					);
				}
			});
		} else {
			result.push(
				<div className={classes.noTeamSelected}>
					<label>{translate('No teams selected')}</label>
				</div>
			);
		}

		return result;
	};

	getFormValidity() {
		const record = this.state.form;
		const createUserAuthType = this.state.createUserAuthType;

		// // Validation common to all authType's.
		// if (createUserAuthType === 'email') {
		// 	// eslint-disable-next-line
		// 	let checkValidEmail = /^(([a-zA-Z0-9\_]+\.?[a-zA-Z0-9\+\-\_]?)+[^\!\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\@\#\.])@([^\!\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\@\#\.][a-zA-Z0-9\-]+\.)+([a-zA-z][a-zA-z]+)$/gm;
		// 	if (!checkValidEmail.test(record.userId)) return false;
		// }

		if (record.role == null || record.role.trim().length === 0) return false;

		// Validation specific to each authType.
		switch (createUserAuthType) {
			case 'universal':
				if (
					record.password == null ||
					record.password.trim().length === 0 ||
					record.userId == null ||
					record.userId.trim().length === 0
				)
					return false;
				break;
			default:
			// Do nothing.
		}

		return true;
	}

	handleTeamsListClose = () => {
		this.setState({
			showTeamsList: false,
			selectedTeams: this.state.form.teamIds,
		});
	};

	checkDateTimeValid = () => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isFormValid =
			this.getFormValidity() &&
			(this.state.createUserAuthType === 'email'
				? emailRegex.test(this.state.record.userId)
				: true) && !(this.state.isEmailValid === true &&
				this.state.emailLength === true);

		if (
			this.state.form.welcomeEmail &&
			this.state.isChecked &&
			(this.state.isCheckBoxHidden ||
				(this.state.createUserAuthType === 'email' && isFormValid))
		) {
			if (
				!isValidDate(this.state.dateValue) ||
				!isValidDate(this.state.timeValue)
			) {
				return false;
			}
			return this.state.isDateTimeValid;
		}
		return true;
	};

	render() {
		const {translate, classes, basePath, location, resource} = this.props;
		// eslint-disable-next-line
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		const runwayOrgConfig = loadObject('runwayOrgConfig');

		const showLmsIdField = getUserInfo().isSuper && runwayOrgConfig?.config?.packageIntegrationEnabled === 'true';

		const {
			authType,
			createUserAuthType,
			externalIdentityName,
			alternateCreateUserIdLabel,
			form,
			selectedTeams,
			sendWelcomeType,
			errorMessage,
			errorMessageLog,
			timeValue,
			dateValue,
			jobRoles,
			jobRoleSearchValue,
			openJobRoleDropDown,
			userRoles,
			palUnavailableErrorMessage
		} = this.state;
		const record = form;

		const lmsIdCharacterLimit = Number(runwayOrgConfig?.config?.lmsIdCharacterMax) ?? 128;

		const isValidLmsId = (record.lmsId && record.lmsId.length > 0) ? record.lmsId.length <= lmsIdCharacterLimit : true;

		const isFormValid =
			this.getFormValidity() &&
			(this.state.createUserAuthType === 'email'
				? emailRegex.test(record.userId)
				: true) && !(this.state.isEmailValid && this.state.emailLength);

		const validateIdentity = [required(), minLength(1)];
		if (['email'].includes(authType)) validateIdentity.push(email());

		const validatePassword = [required(), minLength(1)];

		const validateContactEmail = [email()];

		let authTypeTooltip = null;
		switch (createUserAuthType) {
			case 'universal':
				authTypeTooltip = translate(
					'Your organization has restricted authentication to the Username & Password type which requires a unique username and password be set when creating a user account.'
				);
				break;
			case 'email':
				authTypeTooltip = translate(
					'Your organization has restricted authentication to the Email & Password type which requires a unique email address be set when creating a user account.'
				);
				break;
			case 'external':
				authTypeTooltip = translate(
					'Your organization has restricted authentication to the Single Sign-On type which requires the External ID be set when creating a user account. This External ID is the associated ID provided by the SSO Identity Provider.'
				);
				break;
			default:
				authTypeTooltip = null;
		}

		return (
			<Create basePath={basePath} location={location} resource={resource}>
				<Route
					exact={true}
					path='/users/create'
					render={() =>
						this.state.isNewPALLicenseAvailable ||
						getUserInfo().role.includes('TS') ? (
							<Dialog
								open={true}
								aria-label={translate('Create User')}
								style={{maxHeight: 'flex '}}
							>
								<DialogTitle
									onClose={this.handleClose}
									subTitle={
										<div className={classes.authTypeHeader}>
											{!this.state.isLoading && (
												<label>{translate('Authentication Type')}</label>
											)}
											{!this.state.isLoading && (
												<label>
													{createUserAuthType === 'universal' &&
														translate('Username & Password')}
													{createUserAuthType === 'email' && translate('Email')}
													{createUserAuthType === 'external' &&
														externalIdentityName === 'ssoSignin' &&
														translate('Single Sign-On')}
													{createUserAuthType === 'external' &&
														externalIdentityName !== 'ssoSignin' &&
														translate('External')}
													<Tooltip title={authTypeTooltip}>
														<HelpIcon
															className={classes.informationalText}
															style={{
																fontSize: 'medium',
																marginLeft: '10px',
																color: 'gray',
															}}
														/>
													</Tooltip>
												</label>
											)}
										</div>
									}
								>
									<Text
										style={{fontSize: '20px', fontFamily: 'Archivo Bold'}}
										label={translate('Create User')}
									/>
								</DialogTitle>
								{this.state.isLoading ? (
									<DialogContent style={{height: '300px', width: '505px'}}>
										<LoadingInfo />
									</DialogContent>
								) : (
									<DialogContent style={{width: '505px'}}>
										<SimpleForm
											style={{paddingTop: '-20px', width: '448px'}}
											record={record}
											toolbar={null}
											submitOnEnter={false}
											onSubmit={this.handleCreateUserSubmit}
											autoComplete='off'
										>
											<div className={classes.fieldRow}>
												<div style={{'margin-right': '15px'}}>
													<InputLabel className={classes.inputLabel}>
														{alternateCreateUserIdLabel
															? alternateCreateUserIdLabel
															: translate('Identity')}
														*
													</InputLabel>
													<TextInput
														options={{autoComplete: 'new-userid'}}
														value={record.userId ? record.userId : ''}
														onChange={(e, value) =>
															this.handleChange('userId', e)
														}
														validate={validateIdentity}
														placeholder='Required'
														error={false}
														resettable={true}
													/>
													{(this.state.isEmailValid === false ||
														this.state.emailLength === false) && (
														<div style={{color: '#DE3737'}}>
															{this.state.error}
														</div>
													)}
												</div>
												{createUserAuthType === 'universal' && (
													<div>
														<InputLabel className={classes.inputLabel}>
															Password*
														</InputLabel>
														<TextInput
															options={{autoComplete: 'new-password'}}
															label={false}
															value={record.password ? record.passwoed : ''}
															onChange={(e, value) =>
																this.handleChange('password', e)
															}
															validate={validatePassword}
															resettable={true}
															placeholder='Required'
														/>
													</div>
												)}
												{createUserAuthType === 'email' && (
													<span
														style={{width: '50%', marginLeft: '5px'}}
														className={classnames({
															[classes.basicField]: true,
														})}
													>
														<i>
															{translate(
																'This user will be asked to create a password on first login.'
															)}
														</i>
													</span>
												)}
												{createUserAuthType === 'external' && (
													<span
														style={{width: '50%', marginLeft: '5px'}}
														className={classnames({
															[classes.basicField]: true,
															[classes.informationalText]: true,
														})}
													>
														<i>
															{translate(
																'When this user first logs in, the information below may change.'
															)}
														</i>
													</span>
												)}
											</div>
											<div className={classes.fieldRow}>
												<div style={{'margin-right': '15px'}}>
													<InputLabel className={classes.inputLabel}>
														First Name
													</InputLabel>
													<TextInput
														value={record.firstName ? record.firstName : ''}
														label={false}
														placeholder='Optional'
														onChange={(e, value) =>
															this.handleChange('firstName', e)
														}
														resettable={true}
													/>
												</div>
												<div>
													<InputLabel className={classes.inputLabel}>
														Last Name
													</InputLabel>
													<TextInput
														value={record.lastName ? record.lastName : ''}
														label={false}
														placeholder='Optional'
														onChange={(e, value) =>
															this.handleChange('lastName', e)
														}
														resettable={true}
													/>
												</div>
											</div>
											<div className={classes.fieldRow}>
												<div
													className={classes.fieldColumn}
													style={{marginRight: '10px', width: '50%'}}
												>
													<InputLabel className={classes.inputLabel}>
														Teams
													</InputLabel>

													<div
														style={{
															flex: '1 1 auto',
															display: 'flex',
															flexDirection: 'column',
															width: '100%',
														}}
													>
														<div
															style={{
																flex: '1 1 auto',
																display: 'flex',
																flexDirection: 'row',
																flexWrap: 'wrap',
																paddingTop: '15px',
															}}
														>
															{this.renderTeams()}
															<Button
																style={{
																	width: '135px',
																	height: '28px',
																	margin: '3px 3px 3px 0px',
																}}
																variant={'contained'}
																color={'primary'}
																onClick={() =>
																	this.setState({showTeamsList: true})
																}
																startIcon={<AddOutlined />}
															>
																{translate('Add Team')}
															</Button>
															{this.state.showTeamsList ? (
																<Dialog
																	maxWidth='md'
																	open={true}
																	aria-label={translate('Team list')}
																>
																	<DialogTitle
																		onClose={this.handleTeamsListClose}
																	>
																		<Text
																			style={{fontSize: '20px'}}
																			label={translate('Team list')}
																		/>
																	</DialogTitle>
																	<DialogContent>
																		<EmbeddedList
																			embedded={true}
																			title={null}
																			resource='teams'
																			cached={false}
																			sortSelectionTop={
																				this.state.sortSelectionTop
																			}
																			columns={[
																				{
																					id: 'name',
																					name: translate('Name'),
																					sortable: true,
																				},
																				{
																					id: 'users',
																					name: translate('Users'),
																					sortable: true,
																					align: 'center',
																					width: '150px',
																				},
																				{
																					id: 'training',
																					name: translate('Module'),
																					sortable: true,
																					align: 'center',
																					width: '150px',
																				},
																			]}
																			sort={{field: 'name', order: 'ASC'}}
																			staticFilters={null}
																			dynamicFilters={[
																				{
																					id: 'name',
																					name: translate('Search Teams'),
																					type: 'safe_regex',
																				},
																			]}
																			bulkActions={null}
																			actions={null}
																			rowClicked={null}
																			selectedIds={this.state.selectedTeams}
																			selectionToggled={record => {
																				this.handleTeamAddition(record.id);
																			}}
																			stripped={false}
																		/>
																		<div style={{flex: '0 0 auto'}}>
																			{selectedTeams.length > 0 && (
																				<hr className={classes.divider} />
																			)}

																			{selectedTeams.length > 0 && (
																				<div
																					style={{
																						flex: '1 1 auto',
																						display: 'flex',
																						flexDirection: 'row',
																						alignItems: 'center',
																						padding: '10px',
																					}}
																				>
																					<label className={classes.normalText}>
																						&nbsp;
																						{translate(
																							'The User will be Added to '
																						)}
																					</label>
																					<label
																						className={classes.importantText}
																					>
																						&nbsp;
																						{selectedTeams.length}{' '}
																						{selectedTeams.length > 1
																							? translate('Teams')
																							: translate('Team')}
																					</label>
																				</div>
																			)}
																		</div>
																	</DialogContent>
																	<DialogActions>
																		<Button
																			style={{width: 'auto', height: '32px'}}
																			variant={'contained'}
																			color={'primary'}
																			onClick={this.handleTeams}
																		>
																			{translate('Select')}
																		</Button>
																	</DialogActions>
																</Dialog>
															) : null}
														</div>
													</div>
												</div>
												<div>
													<div>
														<InputLabel className={classes.inputLabel}>
															Role
														</InputLabel>
														<div className={classes.basicField}>
															<SelectInput
																label={false}
																value={
																	record.role
																		? record.role
																		: this.state.defaultUserRole
																}
																allowEmpty={false}
																choices={userRoles}
																onChange={e => this.handleChange('role', e)}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className={classes.fieldRow}>
												<div style={{'margin-right': '15px'}}>
													<InputLabel className={classes.inputLabel}>
														Employee ID
													</InputLabel>
													<TextInput
														label={false}
														value={record.employeeId ? record.employeeId : ''}
														placeholder='Optional'
														onChange={(e, value) =>
															this.handleChange('employeeId', e)
														}
														resettable={true}
													/>
												</div>
												{jobRoles.length > 0 && (
													<div>
														<InputLabel className={classes.inputLabel}>
															Job Role
														</InputLabel>
														<Autocomplete
															id='custom-input-demo'
															options={jobRoles}
															open={openJobRoleDropDown}
															onOpen={() =>
																!jobRoleSearchValue.length &&
																this.setState({openJobRoleDropDown: false})
															}
															inputValue={jobRoleSearchValue}
															onInputChange={(e, value) =>
																this.handleJobRoleInputChange(value)
															}
															noOptionsText={'Job Role not found'}
															onChange={(e, value) =>
																this.handeleJobRoleChange(value)
															}
															renderInput={params => (
																<div
																	ref={params.InputProps.ref}
																	className={classes.paper}
																>
																	<TextInput
																		inputProps={params.inputProps}
																		value={record.jobRole}
																		placeholder='Optional'
																		resettable={true}
																	/>
																</div>
															)}
														/>
													</div>
												)}
											</div>
											<div className={classes.fieldRow}>
												{createUserAuthType !== 'email' && (
													<div style={{'margin-right': '15px'}}>
														<InputLabel className={classes.inputLabel}>
															Contact Email
														</InputLabel>
														<TextInput
															label={false}
															value={
																record.contactEmail ? record.contactEmail : ''
															}
															placeholder='Optional'
															type='email'
															onChange={(e, value) => {
																this.handleChange('contactEmail', e);
																if (e?.target?.value?.length > 0) {
																	this.setState({emailLength: true});
																} else {
																	this.setState({emailLength: false});
																}
															}}
															validate={validateContactEmail}
															resettable={true}
															error={
																((record.contactEmail == null ||
																	record.contactEmail.trim().length === 0) &&
																	record.welcomeEmail &&
																	errorMessageLog) ||
																(this.state.isEmailValid &&
																	this.state.emailLength)
																	? true
																	: false
															}
														/>
														{this.state.isEmailValid === true &&
															this.state.emailLength === true && (
																<div
																	style={{color: '#DE3737', display: 'inline'}}
																>
																	Please enter valid email
																</div>
															)}
														{(record.contactEmail == null ||
															record.contactEmail.trim().length === 0) &&
															record.welcomeEmail && (
																<div
																	style={{color: '#DE3737', display: 'inline'}}
																>
																	{errorMessage}
																</div>
															)}
													</div>
												)}

												{showLmsIdField && (
													<div>
														<InputLabel className={classes.inputLabel}>
															LMSID
														</InputLabel>
														<TextInput
															label={false}
															value={record.lmsId ? record.lmsId : ''}
															placeholder='Optional'
															onChange={(e, value) =>
																this.handleChange('lmsId', e)
															}
															resettable={true}
														/>
														{!isValidLmsId && (
															<div
																style={{color: '#DE3737', display: 'inline'}}
															>
																{`Character Count Exceeded (${lmsIdCharacterLimit})`}
															</div>
														)}
														{!this.state.uniqueLmsId && (
															<div
																style={{color: '#DE3737', display: 'inline'}}
															>
																{'LMSID already in use'}
															</div>
														)}
													</div>
												)}
											</div>
											<div
												className={classes.fieldRow}
												style={{display: 'initial'}}
											>
												<FormControlLabel
													style={{
														width: '50%',
														marginRight: '5px',
														fontFamily: 'Montserrat Medium',
													}}
													control={
														<Checkbox
															checked={
																this.state.isChecked &&
																(this.state.isCheckBoxHidden ||
																	(createUserAuthType === 'email' &&
																		isFormValid === true))
															}
															value='enabled'
															onChange={(e, value) =>
																this.handleToggle('welcomeEmail', e)
															}
															id='contactEmailCheck'
															disabled={
																createUserAuthType === 'email'
																	? !isFormValid
																	: this.state.isCheckBoxHidden === false
															}
															name={record.contactEmail}
															style={{
																opacity:
																	createUserAuthType === 'email'
																		? !isFormValid
																			? '0.3'
																			: '1'
																		: this.state.isCheckBoxHidden === false
																		? '0.3'
																		: '1',
															}}
														/>
													}
													label={translate('Send Welcome Email')}
												/>
												{form.welcomeEmail &&
													this.state.isChecked &&
													(this.state.isCheckBoxHidden ||
														(createUserAuthType === 'email' &&
															isFormValid === true)) && (
														<DatePicker
															sendWelcomeType={sendWelcomeType}
															onDateChange={date => this.handleDateChange(date)}
															onTimeChange={time => this.handelTimeChange(time)}
															handleWelcomeTypeChange={type =>
																this.handleTypeChange(type)
															}
															dateValue={dateValue}
															timeValue={timeValue}
															onDateTimeValidityChange={val => {
																this.setState({isDateTimeValid: !val});
															}}
														/>
													)}
											</div>
											<div
												className={classes.fieldRow}
												style={{fontFamily: 'Montserrat Medium'}}
											>
												<FormControlLabel
													style={{width: '50%'}}
													control={
														<Checkbox
															checked={
																record.role && record.role.includes('TS-')
																	? true
																	: record.tester
																	? record.tester
																	: false
															}
															value='enabled'
															onChange={(e, value) =>
																this.handleToggle('tester', e)
															}
															disabled={
																record.role && record.role.includes('TS-')
															}
															classes={{disabled: classes.disabledCheckbox}}
															style={{
																opacity:
																	record.role && record.role.includes('TS-')
																		? 0.3
																		: 1,
															}}
														/>
													}
													label={translate('Flag as Tester')}
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={
																form.role === 'user' || form.role === 'manager'
																	? false
																	: record.trainingModuleEditor
															}
															value='enabled'
															onChange={(e, value) =>
																this.handleToggle('trainingModuleEditor', e)
															}
															disabled={
																(form.role.indexOf('admin') < 0 &&
																	form.role.indexOf('super') < 0) ||
																!getUserInfo().isSuper
															}
															style={{
																opacity:
																	(form.role.indexOf('admin') < 0 &&
																		form.role.indexOf('super') < 0) ||
																	!getUserInfo().isSuper
																		? '0.3'
																		: '1',
															}}
														/>
													}
													label={translate('CoPilot Designer Author')}
												/>
											</div>
											<div className={classes.fieldRow}>
												<i>
													{translate(
														`A tester's progress is tracked, but does not influence an organization's stats and averages.`
													)}
												</i>
											</div>
											<br />
										</SimpleForm>
									</DialogContent>
								)}

								<DialogActions style={{display: 'initial'}}>
									<div>
										{this.state.isCreateProgress ? (
											<div style={{display: 'flex'}}>
												<CircularProgress
													size={20}
													className={classes.spinnerStyles}
												/>
												<span
													style={{color: '#000'}}
												>{`Creating user...`}</span>
											</div>
										) : (
											<div style={{display: 'flex'}}>
												<Button
													fullWidth
													style={{width: '60%'}}
													color={'secondary'}
													variant={'contained'}
													onClick={() => this.handleCreate(true)}
													startIcon={
														<AddOutlined
															color={
																!isFormValid ||
																(sendWelcomeType === 'schedule' &&
																	!this.checkDateTimeValid()) ||
																!isValidLmsId
																	? 'inherit'
																	: 'primary'
															}
														/>
													}
													disabled={
														!isFormValid ||
														(sendWelcomeType === 'schedule' &&
															!this.checkDateTimeValid()) ||
														!isValidLmsId
													}
												>
													{translate('Create and Add Another')}
												</Button>

												<Button
													fullWidth
													style={{marginLeft: '15px', width: '40%'}}
													variant={'contained'}
													color={'primary'}
													onClick={() => this.handleCreate(false)}
													disabled={
														!isFormValid ||
														(sendWelcomeType === 'schedule' &&
															!this.checkDateTimeValid()) ||
														!isValidLmsId
													}
													startIcon={<AddOutlined />}
												>
													{translate('Create')}
												</Button>
											</div>
										)}
									</div>
								</DialogActions>
							</Dialog>
						) : (
							<PALUnavailableDialog
								onClose={this.handleClose}
								title={
									this.state.addAnother
										? 'Cannot Create Another User'
										: 'Cannot Create User'
								}
								errorMessage={palUnavailableErrorMessage}
							/>
						)
					}
				/>
			</Create>
		);
	}
}

UserCreate.propTypes = {
	fetchStart: PropTypes.func,
	fetchEnd: PropTypes.func,
	push: PropTypes.func,
	refreshView: PropTypes.func,
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = {
	fetchEnd,
	fetchStart,
	push,
	refreshView,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate,
	withRouter,
	withStyles(myStyles),
	withDataProvider
);

export default enhance(UserCreate);
