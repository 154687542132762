/**
 * Basic implementation of a function that tests for object equality.
 * 
 * This function is limited to comparing keys of the supplied objects
 * and verifying that the corresponding primitive values are equal.
 * 
 * So a difference in the number of keys or if either object does not
 * share the exact same sets of keys of the other will result in the
 * comparison failing.
 * 
 * Additonally, EVERY primitive field must be equal (via the '===' comparator).
 * 
 * Non-primitive field values are ignored (i.e. we do not recurse into
 * those fields that are arrays, objects or functions).
 * 
 * @param a 
 * @param b 
 */
const objectsAreEqual = (a: { [prop: string] : any }, b: { [prop: string]: any }): boolean => {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) return false;
    if (aKeys.filter(key => !bKeys.includes(key)).length > 0) return false;

    for (let x = 0; x < aKeys.length; x++) {
        if (['boolean', 'number', 'string'].includes(typeof a[aKeys[x]])) {
            if (a[aKeys[x]] !== b[aKeys[x]]) return false;
        } else {
            // Do nothing for fields that are arrays, objects or functions.
            // We will not cover those scenarios (only primitive fields will be compared).
        }
    }

    return true;
}

/**
 * Extracts a raw value from the supplied data.
 * 
 * This functions ensures that if returning a collection of values
 * then there are no duplicates.
 * 
 * @param data 
 * @param field 
 */
export const extractRawValue = (data: any, field: string): any => {
    let result: any = null;

    if (data != null) {
        const ids = field.split('.');
        const rootId = ids.length > 0 ? ids[0] : null;
        const remainingIds = ids.slice(1);

        if (rootId) {
            if (Array.isArray(data)) {
                result = [];

                data.forEach(item => {
                    const rootValue = item[rootId];

                    if (rootValue != null) {
                        if (remainingIds.length > 0) {
                            const value = extractRawValue(rootValue, remainingIds.join('.'));

                            if (Array.isArray(value)) {
                                value.forEach(subValue => {
                                    if (result.find((item: any) => objectsAreEqual(item, subValue)) == null) result.push(subValue);
                                });
                            } else {
                                if (result.find((item: any) => objectsAreEqual(item, value)) == null) result.push(value);
                            }
                        } else{
                            if (['boolean', 'number', 'string'].includes(typeof rootValue)) {
                                if (!result.includes(rootValue)) result.push(rootValue);
                            } else {
                                if (Array.isArray(rootValue)) {
                                    rootValue.forEach(subValue => {
                                        if (result.find((item: any) => objectsAreEqual(item, subValue)) == null) result.push(subValue);
                                    });
                                } else {
                                    if (result.find((item: any) => objectsAreEqual(item, rootValue)) == null) result.push(rootValue);
                                }
                            }
                        }
                    }
                });
            } else {
                const rootValue = data[rootId];

                if (rootValue != null) {
                    if (remainingIds.length > 0) {
                        result = extractRawValue(rootValue, remainingIds.join('.'));
                    } else {
                        result = rootValue;
                    }
                }
            }
        }
    }

    return result;
}

/**
 * Extracts a value (based on the "id" or "value" sub-field) from the supplied data.
 * 
 * This function is use specifically for data structures that include an "id" or "value" field.
 * 
 * This functions ensures that there are no duplicates (if returning a collection).
 * 
 * @param data 
 * @param field 
 */
export const extractValue = (data: any, field: string): any => {
    let result: any = null;

    const fieldValue = extractRawValue(data, field);

    if (fieldValue != null) {
        if (['boolean', 'number', 'string'].includes(typeof fieldValue)) {
            result = fieldValue;
        } else {
            if (Array.isArray(fieldValue)) {
                result = [];

                fieldValue.forEach(item => {
                    if (item != null) {
                        if (item.id != null) result.push(item.id);
                        else if (item.value != null) result.push(item.value);
                        else result.push(item);
                    }
                });
            } else {
                if (fieldValue.id != null) result = fieldValue.id;
                if (fieldValue.value != null) result = fieldValue.value;
                else result = fieldValue;
            }
        }
    }

    return result;
}

/**
 * Extracts a value (based on the "id" or "value" sub-field) from the supplied collection of data.
 * 
 * This function is use specifically for data structures that include an "id" or "value" field.
 * 
 * This functions ensures that there are no duplicates in the retuned collection.
 * 
 * NOTE:
 * 
 * Not really sure if this function is needed / useful... it essentially does the same thing as
 * the "extractValue" function above which handles both collections of values as well as
 * singular values. The only difference is that this function will ALWAYS return a collection
 * of values, whereas the other one (above) will return whatever is appropirate based on the
 * situation.
 * 
 * @param data 
 * @param field 
 */
export const extractValues = (data: Array<any>, field: string): Array<any> => {
    const result: Array<any> = [];

    data.forEach(item => {
        if (!item) return;

        const fieldValue = extractRawValue(item, field);

        if (fieldValue != null) {
            if (['boolean', 'number', 'string'].includes(typeof fieldValue)) {
                if (!result.includes(fieldValue)) result.push(fieldValue);
            } else {
                if (Array.isArray(fieldValue)) {
                    fieldValue.forEach(value => {
                        if (value != null) {
                            if (value.id != null) {
                                if (!result.includes(value.id)) result.push(value.id);
                            } else if (value.value != null) {
                                if (!result.includes(value.value)) result.push(value.value);
                            } else {
                                if (!result.includes(value)) result.push(value);
                            }
                        }
                    });
                } else {
                    if (fieldValue.id != null && !result.includes(fieldValue.id)) result.push(fieldValue.id);
                    else if (fieldValue.value != null && !result.includes(fieldValue.value)) result.push(fieldValue.value);
                    else {
                        if (!result.includes(fieldValue)) result.push(fieldValue);
                    }
                }
            }
        }
    });

    return result;
}

/**
 * Returns a sorting function that sorts by a string value (case-insensitive).
 * 
 * DEFAULT: Ascending alphanumeric (as implemented by String.prototype.localeCompare).
 * 
 * @param field 
 * @param reverse 
 * @param isGraphNode 
 */
export const sortByString = (field?: string | null, reverse?: boolean, isGraphNode?: boolean) => {
    return (a: any, b: any) => {
        const aRecord = isGraphNode && a ? a.record : a;
        const bRecord = isGraphNode && b ? b.record : b;

        const aValue = field != null
            ? aRecord && aRecord[field] != null ? '' + aRecord[field] : null
            : aRecord;
        const bValue = field != null
            ? bRecord && bRecord[field] != null ? '' + bRecord[field] : null
            : bRecord;

        // NULL's get pushed to the end of the sorted results.
        if (aValue == null && bValue != null) return 1;
        else if (aValue != null && bValue == null) return -1;
        else if (aValue == null && bValue == null) return 0;
        else {
            if ((aValue as string).match(/^\d+/g) || (bValue as string).match(/^\d+/g)) {
                // If the string begins with a number, treat it as a numerical value.
                const aValueNumberMatches = (aValue as string).match(/^\d+/g);
                const bValueNumberMatches = (bValue as string).match(/^\d+/g);

                const aValueNumber = aValueNumberMatches && aValueNumberMatches.length > 0 ? aValueNumberMatches[0] : null;
                const bValueNumber = bValueNumberMatches && bValueNumberMatches.length > 0 ? bValueNumberMatches[0] : null;

                return sortByNumber(null, reverse, false)(Number.parseInt(aValueNumber), Number.parseInt(bValueNumber));
            } else {
                return reverse ? -('' + aValue).toLowerCase().localeCompare(('' + bValue).toLowerCase()) : ('' + aValue).toLowerCase().localeCompare(('' + bValue).toLowerCase());
            }
        }
    };
}

/**
 * Returns a sorting function that sorts by a number value.
 * 
 * DEFAULT: Ascending numeric.
 * 
 * @param field 
 * @param reverse 
 * @param isGraphNode 
 */
export const sortByNumber = (field?: string | null, reverse?: boolean, isGraphNode?: boolean) => {
    return (a: any, b: any) => {
        const aRecord = isGraphNode && a ? a.record : a;
        const bRecord = isGraphNode && b ? b.record : b;

        const aValue = field != null
            ? aRecord && aRecord[field] != null ? Number.parseFloat(aRecord[field]) : null
            : aRecord;
        const bValue = field != null
            ? bRecord && bRecord[field] != null ? Number.parseFloat(bRecord[field]) : null
            : bRecord;

        // NULL's or NaN's get pushed to the end of the sorted results.
        if ((aValue == null || Number.isNaN(aValue)) && (bValue != null && !Number.isNaN(bValue))) return 1;
        else if ((aValue != null && !Number.isNaN(aValue)) && (bValue == null || Number.isNaN(bValue))) return -1;
        else if ((aValue == null || Number.isNaN(aValue)) && (bValue == null || Number.isNaN(bValue))) return 0;
        else {
            if (aValue > bValue) return reverse ? -1 : 1;
            else if (aValue < bValue) return reverse ? 1 : -1;
            else return 0;
        }
    };
}

/**
 * Returns a sorting function that sorts by a boolean field.
 * 
 * DEFAULT: Ascending boolean (from false to true).
 * 
 * @param field 
 * @param reverse 
 * @param isGraphNode 
 */
export const sortByBoolean = (field?: string | null, reverse?: boolean, isGraphNode?: boolean) => {
    return (a: any, b: any) => {
        const aRecord = isGraphNode && a ? a.record : a;
        const bRecord = isGraphNode && b ? b.record : b;

        const aValue = field != null
            ? aRecord && aRecord[field] != null ? aRecord[field] === true || ['true', 'yes'].includes(aRecord[field]) ? true : false : null
            : aRecord;
        const bValue = field != null
            ? bRecord && bRecord[field] != null ? bRecord[field] === true || ['true', 'yes'].includes(bRecord[field]) ? true : false : null
            : bRecord;

        // NULL's get pushed to the end of the sorted results.
        if (aValue == null && bValue != null) return 1;
        else if (aValue != null && bValue == null) return -1;
        else if (aValue == null && bValue == null ) return 0;
        else {
            if (aValue && !bValue) return reverse ? -1 : 1;
            else if (!aValue && bValue) return reverse ? 1 : -1;
            else return 0;
        }
    };
}

/**
 * Returns a sorting function that sorts by array length.
 * 
 * DEFAULT: Ascending length (from smaller array length to bigger array length).
 * 
 * @param field 
 * @param reverse 
 * @param isGraphNode 
 */
export const sortByArrayLength = (field?: string | null, reverse?: boolean, isGraphNode?: boolean) => {
    return (a: any, b: any) => {
        const aRecord = isGraphNode && a ? a.record : a;
        const bRecord = isGraphNode && b ? b.record : b;

        const aValue = field != null
            ? aRecord && aRecord[field] != null && Array.isArray(aRecord[field]) ? aRecord[field].length : 0
            : aRecord && Array.isArray(aRecord) ? aRecord.length : 0;
        const bValue = field != null
            ? bRecord && bRecord[field] != null && Array.isArray(bRecord[field]) ? bRecord[field].length : 0
            : bRecord && Array.isArray(bRecord) ? bRecord.length : 0;

        if (aValue > bValue) return reverse ? -1 : 1;
        else if (aValue < bValue) return reverse ? 1 : -1;
        else return 0;
    };
}

/**
 * Returns a capitalized version of the supplied string.
 * 
 * This function is limited to capitalizing only the first character of the supplied string.
 * 
 * @param value 
 */
export const capitalizeString = (value?: string | null) => {
    if (value && value.length > 0) {
        return value[0].toUpperCase() + value.substring(1);
    }

    return value;
}
