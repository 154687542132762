import React from 'react';

const Box = ({width = '100%', height = '100%', padding = 0, children}) => {
	return (
		<div
			style={{
				width: width,
				padding: padding,
				height: height,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
			}}
		>
			{children}
		</div>
	);
};

export default Box;
