import * as React from 'react';
import compose from 'recompose/compose';
import {Button, createStyles, Typography, withStyles} from '@material-ui/core';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import {TrainingNodeModel} from './TrainingNodeModel';

import LabelIcon from 'components/VectorMap/LabelIcon';
import StatLabel from 'components/VectorMap/StatLabel';

import {determineAvailability, humanizeAvailability} from 'components/VectorMap/utils/CatalogMap/helpers';

export interface TrainingNodePopoverProps {
    node: TrainingNodeModel;
    classes?: any
}

class TrainingNodePopover extends React.Component<TrainingNodePopoverProps> {
    render() {
        const { classes, node } = this.props;

        const context = node.getContext();
        const record = node.getRecord();

        if (!record) return null;

        return (
            <div
                className={classes.root}
                onClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
                onMouseDown={(event) => { event.preventDefault(); event.stopPropagation(); }}
                onMouseUp={(event) => { event.preventDefault(); event.stopPropagation(); }}
                onDrag={(event) => { event.preventDefault(); event.stopPropagation(); }}
            >
                <div className={classes.header}>
                    {record.displayShortName && record.displayShortName.trim().length > 0 &&
                        <div className={classes.icon} style={{ backgroundColor: 'transparent', color: node.getBackgroundColor(), borderColor: node.getBackgroundColor() }}>
                            <LabelIcon label={record.displayShortName} fontScale={0.5} />
                        </div>
                    }

                    <Typography className={classes.title}>{record.name}</Typography>

                    {record.id && record.id.trim().length > 0 &&
                        <Button className={classes.button} onClick={() => node.viewRecordDetails()}>View Training <ArrowRightIcon style={{ width: 24, height: 24 }} /></Button>
                    }
                </div>

                <div className={classes.body}>
                    <div className={classes.description}>
                        <Typography className={classes.descriptionLabel}>Description</Typography>
                        <Typography className={classes.descriptionValue}>{record.description}</Typography>
                    </div>

                    {context === 'org' &&
                        <div className={classes.stats}>
                            <div className={classes.stat}>
                                <Typography className={classes.statLabel}>Type</Typography>
                                <StatLabel className={classes.statValue} type={'text'} value={record.trainingType && record.trainingType.length > 0 ? record.trainingType.map(item => item.value).join(', ') : ''} />
                            </div>
                            <div className={classes.stat}>
                                <Typography className={classes.statLabel}>Availability</Typography>
                                <StatLabel className={classes.statValue} type={'text'} value={humanizeAvailability(determineAvailability(record))} />
                            </div>
                            <div className={classes.stat}>
                                <Typography className={classes.statLabel}># Lessons</Typography>
                                <StatLabel className={classes.statValue} type={'number'} value={record.lessons} />
                            </div>
                            <div className={classes.stat}>
                                <Typography className={classes.statLabel}># Users</Typography>
                                <StatLabel className={classes.statValue} type={'number'} value={record.users} />
                            </div>
                            <div className={classes.stat}>
                                <Typography className={classes.statLabel}>Avg Score</Typography>
                                <StatLabel className={classes.statValue} type={'percentage'} value={record.avgScore} />
                            </div>
                            <div className={classes.stat}>
                                <Typography className={classes.statLabel}>Avg Time Spent</Typography>
                                <StatLabel className={classes.statValue} type={'duration'} value={record.totalTimeSec} />
                            </div>
                            <div className={classes.stat}>
                                <Typography className={classes.statLabel}>Completion Rate</Typography>
                                <StatLabel className={classes.statValue} type={'percentage'} value={record.completionRate} />
                            </div>
                        </div>
                    }

                    {context === 'team' &&
                        <div className={classes.stats}>
                        </div>
                    }

                    {context === 'user' &&
                        <div className={classes.stats}>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const myStyles = theme =>
    createStyles({
        root: {
            minWidth: 550,
            maxWidth: 550,
            padding: 10,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            backgroundColor: '#FFFFFF',
            color: '#233863'
        },
        header: {
            flex: '0 0 auto',
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10
        },
        icon: {
            flex: '0 0 auto',
            display: 'flex',
            width: 48,
            height: 48,
            borderWidth: '4px',
            borderStyle: 'solid',
            borderRadius: '10%',
            overflow: 'hidden',
            marginRight: 10
        },
        title: {
            flex: '1 1 auto',
            fontWeight: 'bold',
            fontSize: 18
        },
        button: {
            flex: '0 0 auto',
            backgroundColor: '#233863',
            color: '#FFFFFF',
            borderRadius: 5,
            overflow: 'hidden',
            fontWeight: 'bold',
            maxHeight: 22,
            '&:hover': {
                backgroundColor: '#233863',
                color: '#FFFFFF',
            }
        },
        body: {
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            overflow: 'auto'
        },
        description: {
            flex: '0 0 auto'
        },
        descriptionLabel: {
            fontWeight: 'bold',
            fontSize: 16,
            marginBottom: 5
        },
        descriptionValue: {
            fontWeight: 'normal',
            fontSize: 14,
            fontStyle: "italic",
            marginBottom: 10
        },
        stats: {
            flex: '0 0 auto',
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateRows: 'repeat(2, auto)',
            marginBottom: 10
        },
        stat: {
            flex: '0 0 auto',
            display: 'flex',
            flexDirection: 'column',
            padding: 5
        },
        statLabel: {
            fontWeight: 'bold',
            fontSize: 16,
            marginRight: 5
        },
        statValue: {
            fontWeight: 'normal',
            fontSize: 14,
            fontStyle: "italic"
        }
    });

const enhance = compose(withStyles(myStyles));

export default enhance(TrainingNodePopover);
