import {LinearProgress} from 'react-admin';
import React from 'react';
import get from 'lodash/get';

const DisplayTextValue = ({source, record = {}, altRecord}) => {
	const renderText = value => {
		return (
			<div
				style={{
					whiteSpace: 'nowrap',
					flex: '1 ',
					display: 'flex',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					border: '1px solid #0F4358',
					borderRadius: '4px',
					height: '28px',
					paddingLeft: '8px',
					paddingRight: '8px',

					backgroundColor: '#00132F !important',
					color: 'inherit',
					fontSize: 'inherit',
					paddingTop: '4px',
					paddingBottom: '4px',
					maxWidth: '406px',
					minWidth: '220px'
				}}
			>
				<div style={{
					whiteSpace: 'nowrap',
					flex: '1',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					minWidth: '0',
				}}
				title={value}> 
				{value}
				</div>
			</div>
		);
	};

	if (altRecord) {
		record = altRecord;
	}

	if(!record) {
		return <LinearProgress/>;
	}
	if (!get(record, source)) {
		return <div>---</div>;
	}

	return renderText(get(record, source));
};

export default DisplayTextValue;
