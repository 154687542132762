import React from 'react';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';

import classnames from "classnames";

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import { DataOptions } from '../OptionsBlade';
import TrainingNodeWidget from '../custom/CatalogMap/TrainingNode/TrainingNodeWidget';
import { TrainingNodeModel } from '../custom/CatalogMap/TrainingNode/TrainingNodeModel';
import { Typography } from '@material-ui/core';
import { DEFAULT_NODE_BACKGROUND_COLOR } from '../utils/model';
import { SkillNodeModel } from '../custom/SkillMap/SkillNode/SkillNodeModel';
import SkillNodeWidget from '../custom/SkillMap/SkillNode/SkillNodeWidget';
import { capitalizeString, sortByString } from '../utils/helpers';
import { getHeatmapOption } from '../utils/options';
import { determineMaxAttempts, determineMaxLessons, determineMaxTotalSkillPoints, determineMaxTimeSpent, determineMaxTotalTimeSec } from '../utils/CatalogMap/helpers';
import { duration } from 'moment';

const MAX_LEGEND_ITEMS_PER_COLUMN = 4;

interface PROPS {
    context: 'org' | 'team' | 'user',
    type: 'catalog-map' | 'training-map' | 'lesson-map' | 'skill-map',
    options?: DataOptions | null,
    data?: Array<any>,
    expanded?: boolean,
    onToggleExpanded: () => void
    classes?: any
}

class Legend extends React.Component<PROPS> {
    renderNodeLegendItems() {
        const { classes, type } = this.props;

        const results = [];

        switch (type) {
            case 'catalog-map':
                // Add a legend item for each map element.
                const trainingNodeModel = new TrainingNodeModel();
                trainingNodeModel.setWidth(24);
                trainingNodeModel.setHeight(24);
                trainingNodeModel.setBackgroundColor(DEFAULT_NODE_BACKGROUND_COLOR);
                results.push(
                    <div key={'training'} className={classes.legendNodeItem}>
                        <div style={{ marginRight: 4, width: 32, height: 24 }}>
                            <TrainingNodeWidget node={trainingNodeModel} />
                        </div>

                        <Typography>Training</Typography>
                    </div>
                );

                break;
            case 'training-map':
                break;
            case 'lesson-map':
                break;
            case 'skill-map':
                // Add a legend item for each map element.
                const skillNodeModel = new SkillNodeModel();
                skillNodeModel.setWidth(24);
                skillNodeModel.setHeight(24);
                skillNodeModel.setBackgroundColor(DEFAULT_NODE_BACKGROUND_COLOR);
                results.push(
                    <div key={'skill'} className={classes.legendNodeItem}>
                        <div style={{ marginRight: 4, width: 32, height: 24 }}>
                            <SkillNodeWidget node={skillNodeModel} />
                        </div>
                        
                        <Typography>Skill</Typography>
                    </div>
                );

                break;
            default:
                // Do nothing.
        }

        return results;
    }

    renderStatLegendItems() {
        const { classes, context, type, options, data } = this.props;

        const results = [];

        switch (type) {
            case 'catalog-map':
                results.push(
                    <div key={'availability'} className={classes.legendStatItem}>
                        <div style={{ marginRight: 4, width: 16, height: 16 }}>
                            <svg viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#availability'} /></svg>
                        </div>
                        
                        <Typography>Availability</Typography>
                    </div>
                );

                results.push(
                    <div key={'lessons'} className={classes.legendStatItem}>
                        <div style={{ marginRight: 4, width: 16, height: 16 }}>
                            <svg viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#lessons'} /></svg>
                        </div>
                        
                        <Typography># Lessons</Typography>
                    </div>
                );

                if (['org'].includes(context)) {
                    results.push(
                        <div key={'users'} className={classes.legendStatItem}>
                            <div style={{ marginRight: 4, width: 16, height: 16 }}>
                                <svg viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#users'} /></svg>
                            </div>
                            
                            <Typography># Users</Typography>
                        </div>
                    );
                }

                if (['user', 'team'].includes(context)) {
                    results.push(
                        <div key={'attempts'} className={classes.legendStatItem}>
                            <div style={{ marginRight: 4, width: 16, height: 16 }}>
                                <svg viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#attempts'} /></svg>
                            </div>
                            
                            <Typography># Attempts</Typography>
                        </div>
                    );
                }

                if (['team', 'org'].includes(context)) {
                    results.push(
                        <div key={'completion-rate'} className={classes.legendStatItem}>
                            <div style={{ marginRight: 4, width: 16, height: 16 }}>
                                <svg viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#completion-rate'} /></svg>
                            </div>
                            
                            <Typography>Completion Rate</Typography>
                        </div>
                    );
                }

                if (['user'].includes(context)) {
                    results.push(
                        <div key={'status'} className={classes.legendStatItem}>
                            <div style={{ marginRight: 4, width: 16, height: 16 }}>
                                <svg viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#status'} /></svg>
                            </div>
                            
                            <Typography>Progress Status</Typography>
                        </div>
                    );
                }

                results.push(
                    <div key={'score'} className={classes.legendStatItem}>
                        <div style={{ marginRight: 4, width: 16, height: 16 }}>
                            <svg viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#score'} /></svg>
                        </div>
                        
                        <Typography>{['user'].includes(context) ? 'Score' : 'Avg Score'}</Typography>
                    </div>
                );

                results.push(
                    <div key={'time-spent'} className={classes.legendStatItem}>
                        <div style={{ marginRight: 4, width: 16, height: 16 }}>
                            <svg viewBox="0 0 60 60"><use href={'/VectorMapIcons/CatalogMap/stats.svg#time-spent'} /></svg>
                        </div>

                        <Typography>{['user'].includes(context) ? 'Time Spent' : 'Avg Time Spent'}</Typography>
                    </div>
                );

                break;
            case 'training-map':
                break;
            case 'lesson-map':
                break;
            case 'skill-map':
                // If a heatmap is enabled, then do not show any category legend items.
                if (options && options.heatmap && options.heatmap.value) break;

                // Determine every distinct skill category.
                const skillCategories: Array<{ id: string, mainColour: string, fontColour: string }> = [];
                if (data) {
                    data.forEach(item => {
                        if (item.skillGroup && skillCategories.find(category => category.id === item.skillGroup) == null) {
                            skillCategories.push({ id: item.skillGroup, mainColour: item.mainColour, fontColour: item.fontColour });
                        }
                    });
                }
                skillCategories.sort(sortByString('id'));

                // Add a legend item for each catagory.
                skillCategories.forEach((category, idx) => {
                    results.push(
                        <div key={idx} className={classes.legendStatItem}>
                            <div style={{ marginRight: 4, width: 32, height: 8, backgroundColor: category.mainColour ? category.mainColour : DEFAULT_NODE_BACKGROUND_COLOR }}>
                            </div>
                            
                            <Typography>{capitalizeString(category ? category.id : '')}</Typography>
                        </div>
                    );
                });

                break;
            default:
                // Do nothing.
        }

        return results;
    }

    renderGenericPercentageHeatMapLegendItem = (labels: Array<string>) => {
        return (
            <div style={{ flex: '1 1 auto', display: 'flex', alignItems: 'stretch' }}>
                <div style={{ height: '100%', flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#203E04' }}></div>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#234707' }}></div>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#29540A' }}></div>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#37670F' }}></div>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#4A7E19' }}></div>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#54911C' }}></div>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#76AC1F' }}></div>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#92BC1F' }}></div>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#97C521' }}></div>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#ABD537' }}></div>
                    <div style={{ flex: '1 1 auto', width: 32, backgroundColor: '#878787' }}></div>
                </div>

                <div style={{ height: '100%', flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    {labels.map((label, idx) => {
                        return <Typography key={idx} style={{ flex: '0 0 auto', fontSize: 12, marginLeft: 10, paddingRight: 4 }}>{label}</Typography>
                    })}
                </div>
            </div>
        );
    }

    renderGenericBooleanHeatMapLegendItem = (positiveLabel: string, negativeLabel: string) => {
        return (
            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: '0 0 auto', height: 15, display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '0 0 auto', width: 32, height: 8, margin: 1, backgroundColor: '#B91C06' }}></div>
                    <Typography style={{ flex: '0 0 auto', fontSize: 10, marginLeft: 10, paddingRight: 4 }}>{positiveLabel}</Typography>
                </div>
                <div style={{ flex: '0 0 auto', height: 15, display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '0 0 auto', width: 32, height: 8, margin: 1, backgroundColor: '#878787' }}></div>
                    <Typography style={{ flex: '0 0 auto', fontSize: 10, marginLeft: 10, paddingRight: 4 }}>{negativeLabel}</Typography>
                </div>
            </div>
        );
    }

    renderHeatmapLegendItem() {
        const { context, type, data, options } = this.props;

        if (!options) return null;

        const rootHeatmapOption = options.heatmap
        const rootHeatmapOptionValue = rootHeatmapOption ? rootHeatmapOption.value : null;

        if (!rootHeatmapOptionValue) return null;

        let result = null;

        switch (type) {
            case 'catalog-map':
                switch (rootHeatmapOptionValue) {
                    case 'lessons':
                        result = this.renderGenericPercentageHeatMapLegendItem(['' + determineMaxLessons(data), '0']);

                        break;
                    case 'total-skill-points':
                        result = this.renderGenericPercentageHeatMapLegendItem(['' + determineMaxTotalSkillPoints(data), '0']);

                        break;
                    case 'completion-rate':
                        result = this.renderGenericPercentageHeatMapLegendItem(['>90%', ' 50%', '<10%']);

                        break;
                    case 'attempts':
                        result = this.renderGenericPercentageHeatMapLegendItem(['' + determineMaxAttempts(data), '0']);

                        break;
                    case 'score':
                        result = this.renderGenericPercentageHeatMapLegendItem(['>90%', ' 50%', '<10%']);

                        break;
                    case 'time-spent':
                        const timeSpentDuration = duration(['user'].includes(context) ? determineMaxTimeSpent(data) : determineMaxTotalTimeSec(data), 'seconds');
                        const timeSpentHours = Math.floor(timeSpentDuration.asHours());
                        const timespentMinutes = Math.floor(timeSpentDuration.asMinutes()) - (timeSpentHours * 60);
                        const timeSpentSeconds = Math.floor(timeSpentDuration.asSeconds()) - (timeSpentHours * 60 * 60) - (timespentMinutes * 60);
            
                        const humanizedDuration = timeSpentHours + ':' + (timespentMinutes < 10 ? '0' + timespentMinutes : timespentMinutes) + ':' + (timeSpentSeconds < 10 ? '0' + timeSpentSeconds : timeSpentSeconds);
            
                        result = this.renderGenericPercentageHeatMapLegendItem([humanizedDuration, '0']);

                        break;
                    case 'training-complete':
                        result = this.renderGenericBooleanHeatMapLegendItem('Completed Training', 'Other Training');

                        break;
                    case 'training-passed':
                        result = this.renderGenericBooleanHeatMapLegendItem('Passed Training', 'Other Training');

                        break;
                    default:
                        // Do nothing.
                }

                break;
            case 'skill-map':
                switch (rootHeatmapOptionValue) {
                    case 'linked-roles':
                        const linkedRolesHeatmapOption = getHeatmapOption(rootHeatmapOption.children, 'linked-roles');
                        const linkedRolesHeatmapOptionValue = linkedRolesHeatmapOption ? linkedRolesHeatmapOption.value : null;
                        const linkedRolesHeatmapOptionLabel = linkedRolesHeatmapOptionValue && linkedRolesHeatmapOption.options ? linkedRolesHeatmapOption.options.find(item => item.value === linkedRolesHeatmapOptionValue).label : null;

                        result = this.renderGenericBooleanHeatMapLegendItem(linkedRolesHeatmapOptionLabel ? linkedRolesHeatmapOptionLabel : 'All Linked Roles', 'Other Skills');

                        break;
                    case 'other-roles':
                        const otherRolesHeatmapOption = getHeatmapOption(rootHeatmapOption.children, 'other-roles');
                        const otherRolesHeatmapOptionValue = otherRolesHeatmapOption ? otherRolesHeatmapOption.value : null;
                        const otherRolesHeatmapOptionLabel = otherRolesHeatmapOptionValue && otherRolesHeatmapOption.options? otherRolesHeatmapOption.options.find(item => item.value === otherRolesHeatmapOptionValue).label : null;

                        result = this.renderGenericBooleanHeatMapLegendItem(otherRolesHeatmapOptionLabel ? otherRolesHeatmapOptionLabel : 'All Other Roles', 'Other Skills');

                        break;
                    case 'related-training':
                        const trainingHeatmapOption = getHeatmapOption(rootHeatmapOption.children, 'related-training');
                        const trainingHeatmapOptionValue = trainingHeatmapOption ? trainingHeatmapOption.value : null;
                        const trainingHeatmapOptionLabel = trainingHeatmapOptionValue && trainingHeatmapOption.options? trainingHeatmapOption.options.find(item => item.value === trainingHeatmapOptionValue).label : null;
                        const lessonHeatmapOption = trainingHeatmapOptionValue ? getHeatmapOption(trainingHeatmapOption.children, trainingHeatmapOptionValue) : null;
                        const lessonHeatmapOptionValue = lessonHeatmapOption ? lessonHeatmapOption.value : null;
                        const lessonHeatmapOptionLabel = lessonHeatmapOptionValue && lessonHeatmapOption.options ? lessonHeatmapOption.options.find(item => item.value === lessonHeatmapOptionValue).label : null;
                        
                        result = this.renderGenericBooleanHeatMapLegendItem(trainingHeatmapOptionLabel && lessonHeatmapOptionLabel ? trainingHeatmapOptionLabel + ' / ' + lessonHeatmapOptionLabel : trainingHeatmapOptionLabel ? trainingHeatmapOptionLabel + ' / All Lessons' : 'All Training', 'Other Skills');

                        break;
                    case 'skill-points-acquired':
                        result = this.renderGenericPercentageHeatMapLegendItem(['>90%', ' 50%', '<10%']);

                        break;
                    default:
                        // Do nothing.
                }

                break;
            default:
            // Do nothing.
        }

        return result;
    }

    render() {
        const { classes, expanded, context, type, options } = this.props;

        const nodeElements = this.renderNodeLegendItems();
        const statElements = this.renderStatLegendItems();
        const heatmapElement = this.renderHeatmapLegendItem();

        // Determine an appropriate label for the stats legend section.
        let statsLabel: string | null = null;
        switch(type) {
            case 'catalog-map':
                statsLabel = 'Details';

                break;
            case 'training-map':
                statsLabel = 'Details';

                break;
            case 'lesson-map':
                statsLabel = 'Details';

                break;
            case 'skill-map':
                statsLabel = 'Skill Categories';

                break;
            default:
                // Do nothing.
        }

        // Determine an appropriate label for the heatmap legend section.
        let heatmapLabel: string | null = null;
        const heatmapOption = options.heatmap;
        const heatmapOptionValue = heatmapOption ? heatmapOption.value : null;
        switch (type) {
            case 'catalog-map':
                switch (heatmapOptionValue) {
                    case 'lessons':
                        heatmapLabel = '# Lessons';

                        break;
                    case 'total-skill-points':
                        heatmapLabel = 'Total Skill Points';

                        break;
                    case 'completion-rate':
                        heatmapLabel = 'Completion Rate';

                        break;
                    case 'attempts':
                        heatmapLabel = '# Attempts';

                        break;
                    case 'score':
                        heatmapLabel = ['user'].includes(context) ? 'Score' : 'Avg Score';

                        break;
                    case 'time-spent':
                        heatmapLabel = ['user'].includes(context) ? 'Time Spent' : 'Avg Time Spent';

                        break;
                    case 'training-complete':
                        heatmapLabel = 'Training Complete';

                        break;
                    case 'training-passed':
                        heatmapLabel = 'Training Passed';

                        break;
                    default:
                        // Do nothing.
                }

                break;
            case 'training-map':
                heatmapLabel = 'Heatmap';

                break;
            case 'lesson-map':
                heatmapLabel = 'Heatmap';

                break;
            case 'skill-map':
                switch (heatmapOptionValue) {
                    case 'linked-roles':
                        heatmapLabel = 'Role Related Skills';

                        break;
                    case 'other-roles':
                        heatmapLabel = 'Role Related Skills';

                        break;
                    case 'related-training':
                        heatmapLabel = 'Training/Lesson Related Skills';

                        break;
                    case 'skill-points-acquired':
                        heatmapLabel = 'Points Acquired';

                        break;
                    default:
                        // Do nothing.
                }

                break;
            default:
                // Do nothing.
        }

        return (
            <div className={classnames({ [classes.root]: true, [classes.expanded]: expanded })}>
                <div className={classes.expander} onClick={this.props.onToggleExpanded}>
                    {!expanded &&
                        <ArrowRightIcon />
                    }
                    {expanded &&
                        <ArrowLeftIcon />
                    }
                </div>

                {expanded &&
                    <div className={classes.content}>
                        {nodeElements && nodeElements.length > 0 &&
                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>Map Elements</Typography>
                                </div>

                                <div style={{ display: 'grid', gridAutoFlow: 'column', gridTemplateRows: 'repeat(' + MAX_LEGEND_ITEMS_PER_COLUMN + ', auto)' }}>
                                    {nodeElements}
                                </div>
                            </div>
                        }

                        {statElements && statElements.length > 0 &&
                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
                                <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{statsLabel}</Typography>
                                </div>

                                <div style={{ display: 'grid', gridAutoFlow: 'column', gridTemplateRows: 'repeat(' + MAX_LEGEND_ITEMS_PER_COLUMN + ', auto)' }}>
                                    {statElements}
                                </div>
                            </div>
                        }

                        {heatmapElement  &&
                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
                                <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{heatmapLabel}</Typography>
                                </div>

                                <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', padding: 4 }}>
                                    {heatmapElement}
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

const myStyles = (theme) => createStyles({
    root: {
        position: 'absolute',
        bottom: 10,
        left: 10,
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        backgroundColor: '#FFFFFF',
        color: '#233863',
        minHeight: MAX_LEGEND_ITEMS_PER_COLUMN * 32 + 28,
        maxHeight: MAX_LEGEND_ITEMS_PER_COLUMN * 32 + 28,
        minWidth: 'unset',
        maxWidth: 'unset',
        boxShadow: '4px 4px 0px 0px rgba(35, 56, 99, 0.3)',
        border: '1px solid #4b5162',
        borderRadius: 10,
        overflow: 'hidden',
        userSelect: 'none'
    },
    expanded: {
        '& > div:first-of-type': {
            borderRight: '1px solid #4b5162'
        }
    },
    expander: {
        minWidth: 28,
        width: 28,
        maxWidth: 28,
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& > svg': {
            width: 24,
            height: 24
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
    },
    content: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10
    },
    legendNodeItem: {
        minHeight: 32,
        maxHeight: 32,
        minWidth: 110,
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        padding: 4
    },
    legendStatItem: {
        minHeight: 32,
        maxHeight: 32,
        minWidth: 110,
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        padding: 4
    }
});

const enhance = compose(withStyles(myStyles));

export default enhance(Legend);
