import React from 'react';

const DialogBackground = ({handleClose}) => {
	return (
		<div
			onClick={handleClose}
			style={{
				width: '100%',
				height: '100%',
				backgroundColor: 'rgb(0,0,0)',
				opacity: '0.607843',
				position: 'fixed',
			}}
		/>
	);
};

export default DialogBackground;
