import React from 'react';
import LoadingProccess from '../loading/LoadingProcess';

const LoadingButton = ({
	variant,
	small = false,
	med = false,
	width,
	children,
}) => {
	const alt = variant === 'outlined';
	const error = variant === 'error';

	const buttonStyle = {
		textAlign: 'inherit',
		background: 'none',
		padding: 0,
		margin: 0,
		//border:  'none',
		border: alt ? '1px solid rgba(28, 118, 157, 1)' : 'none',
		color: alt ? '#FFC101' : 'black',
		cursor: 'default',
		fontFamily: 'Helvetica Now',
		backgroundColor: alt ? '#00132F' : error ? '#F14242' : '#FFC101',
		borderRadius: '4px',
		minWidth: small ? '96px' : med ? '148px' : width ? width : '240px',
		height: alt ? '30px' : '28px',
		alignItems: 'center',
		fontSize: '13px',
	};

	return (
		<div style={{display: 'flex', height: alt ? '30px' : '28px'}}>
			<button style={buttonStyle}>
				<div
					style={{
						outline: 'none',
						padding: 0,
						margin: 0,
						flex: 1,
						textAlign: 'center',
					}}
					tabIndex='-1'
				>
					{children}
				</div>
			</button>

			<div
				style={{marginLeft: '16px', marginTop: 'auto', marginBottom: 'auto'}}
			>
				<LoadingProccess />
			</div>
		</div>
	);
};

export default LoadingButton;
