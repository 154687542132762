import Grid from '../layout/Grid';
import Label from '../Label';
import CustomField from '../form/CustomField';
import {StatusChip, TextValue, TimeDateField} from '../index';
import React, {useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextInput from 'components/TextInput';
import Checkbox from '@material-ui/core/Checkbox';
import { getUserInfo } from "utils/Data";

export const Information = ({
	isEditing,
	isValidLmsId,
	isValidEmail,
	state,
	jobRole,
	setJobRole,
	// sendLabReports,
	// setSendLabReports,
	uniqueLmsId,
	isPackageIntegrationEnabled,
	lmsIdCharacterLimit
}) => {
	const [jobRoleSearchValue, setJobRoleSearchValue] = useState(null);

	if (isEditing)
		return (
			<Grid perRow={2} gridGap={'16px 16px'}>
				<div>
					<Label label={'Contact Email'} />
					<CustomField
						name='email'
						component='input'
						placeholder='email@mailservice.com'
						error={!isValidEmail}
					/>
					{!isValidEmail && <span style={{ color: '#DE3737', marginTop: '15px', fontSize: '12px' }}>{' Please enter valid email'}</span>}
				</div>
				<div> </div>
				<div>
					<Label label={'First Name'} />
					<CustomField
						name='firstName'
						component='input'
						placeholder='Optional'
					/>
				</div>
				<div>
					<Label label={'Last Name'} />
					<CustomField
						name='lastName'
						component='input'
						placeholder='Optional'
					/>
				</div>
				{state.jobRoleList?.length > 0 && (
					<div>
						<Label label={'Job Role'} marginBottom={'-16px'} />
						<Autocomplete
							id='auto-complete-input'
							options={state.jobRoleList}
							open={jobRoleSearchValue ? true : false}
							inputValue={jobRole}
							onInputChange={(e, value, changeReason) => {
								setJobRoleSearchValue(value);
								if (changeReason !== 'reset') {
									setJobRole(value);
								}
							}}
							noOptionsText={'Job Role not found'}
							onChange={(e, value) => {
								setJobRole(value);
								setJobRoleSearchValue(null);
							}}
							onClose={(e, closeReason) => {
								if (closeReason !== 'select-option') {
									setJobRole('');
									setJobRoleSearchValue(null);
								}
							}}
							renderInput={params => (
								<div ref={params.InputProps.ref}>
									<TextInput
										inputProps={params.inputProps}
										value={jobRole}
										placeholder='Optional'
									/>
								</div>
							)}
						/>
					</div>
				)}
				<div>
					<Label label={'Employee ID'} />
					<CustomField
						name='employeeId'
						component='input'
						placeholder='Optional'
					/>
				</div>
				{/* <div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Checkbox
						checked={sendLabReports}
						onChange={e => setSendLabReports(e.target.checked)}
					/>
					<span style={{fontWeight: 'bold'}}>Send Lab Reports</span>
				</div> */}
				{isPackageIntegrationEnabled && getUserInfo().isSuper && <div>
					<Label label={'LMSID'} />
					<CustomField
						name='lmsId'
						component='input'
						placeholder='Optional'
						error={!isValidLmsId}
					/>
					{!isValidLmsId && (
						<span style={{ color: '#DE3737', marginTop: '15px', fontSize: '12px' }}>
							{`Character Count Exceeded (${lmsIdCharacterLimit})`}
						</span>
					)}
					{!uniqueLmsId && <span style={{ color: '#DE3737', marginTop: '15px', fontSize: '12px' }}>{'LMSID already in use'}</span>}
				</div>}
			</Grid>
		);

	return (
		<Grid perRow={2} gridGap={'16px 16px'}>
			<div>
				<Label label={'Contact Email'} />
				<TextValue
					maxWidth={'280px'}
					record={state}
					source={'profileInfo.information.email'}
				/>
			</div>
			<div>
				<Label label={'Created'} />
				<TimeDateField
					record={state}
					source={'profileInfo.information.createdAt'}
				/>
			</div>
			<div>
				<Label label={'Name'} />
				<TextValue
					maxWidth={'230px'}
					record={state}
					source={'profileInfo.information.name'}
				/>
			</div>
			<div>
				<Label label={'Status'} />
				<StatusChip record={state} source={'profileInfo.information.status'} />
			</div>
			<div>
				<Label label={'Job Role'} />
				<TextValue
					maxWidth={'280px'}
					record={state}
					source={'profileInfo.information.jobRole'}
				/>
			</div>
			<div>
				<Label label={'Employee ID'} />
				<TextValue
					record={state}
					source={'profileInfo.information.employeeId'}
				/>
			</div>
			{/* <div>
				<Label label={'Send Lab Reports'} />
				<StatusChip
					record={state}
					source={'profileInfo.information.sendLabEmailReports'}
				/>
			</div> */}
			{isPackageIntegrationEnabled && getUserInfo().isSuper && <div>
				<Label label={'LMSID'} />
				<TextValue
				    maxWidth={'280px'}
					record={state}
					source={'profileInfo.information.lmsId'}
				/>
			</div>}
		</Grid>
	);
};
