import { NumberField } from 'react-admin';
import PropTypes from 'prop-types';
import React from 'react';

const NumberValue = ({ source, record = {}, altRecord, options }) => {
	if (altRecord) {
		record = altRecord;
	}

	if (record[source] == null) {
		return <span>---</span>;
	}

	return <NumberField record={record} source={source} options={options} />;
};
NumberValue.propTypes = {
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
};

export default NumberValue;
