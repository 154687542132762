import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {FixedSizeList} from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';

const style = theme => ({
	root: {
		flex: 1,
	},
	cost: {
		marginRight: '1em',
		color: theme.palette.text.primary,
	},
	fixedSizeList: {
		flex: '1 1 auto',
	},
});

const Row = items => props => {
	const {index, style} = props;
	const record = items[index];
	return (
		<ListItem key={record.id} style={style}>
			<div
				style={{
					border: '1px solid rgba(15, 67, 88, 1)',
					width: '100%',
					padding: '2px 10px',
				}}
			>
				<ListItemText primary={record.name.trim().length>0 ? record.name : record.userId} />
			</div>
		</ListItem>
	);
};

const SimpleList = ({items = [], title, translate, classes, className}) => (
	<Card className={classes.root + ' ' + className}>
		<CardHeader title={translate(title)} />
		{/* <List dense={true}> */}
		<FixedSizeList
			className={classes.fixedSizeList}
			height={400}
			itemSize={46}
			itemCount={items.length}
		>
			{Row(items)}
		</FixedSizeList>
		{/* </List> */}
	</Card>
);

const enhance = compose(withStyles(style), translate);

export default enhance(SimpleList);
