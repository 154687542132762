import 'date-fns';

import React, { useState } from 'react';
import { UPDATE, useDataProvider } from 'react-admin';
import { Form } from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import get from 'lodash/get';
import MUIButton from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

import { CustomDialog } from 'components';
import CustomField from 'components/form/CustomField';
import SelectInput from '../../../components/SelectInput';
import Grid from 'components/layout/Grid';
import Label from 'components/Label';
import { getUserInfo } from 'utils/Data';
import SelectManagerDialog from '../../../components/dialogs/SelectManagerDialog';

const EditTeamDialog = props => {
	const { id, isOpen, setIsOpen, data, onUpdateTeamDetails } = props;

	const [selectingManager, setSelectingManager] = useState(false);

	const [isUpdating, setIsUpdating] = useState(false);

	const [name, setName] = useState(get(data, 'name'));

	const [selectedManager, setSelectedManager] = useState({
		name: data.managerName,
		id: data.ownerId,
	});

	const dataProvider = useDataProvider();

	// handleFormSubmit becomes an onSubmit reference
	let handleFormSubmit = () => { };
	const onSubmit = async values => {
		const teamData = { ...props };
		teamData.ownerId = selectedManager.id;
		teamData.ownerName = selectedManager.name;
		teamData.role = 'manager';
		teamData.name = values['name'];

		setIsUpdating(true);

		dataProvider(UPDATE, 'teams', { id: id, data: teamData })
			.then(() => {
				setIsOpen(false);
				onUpdateTeamDetails();
			})
			.catch(() => setIsUpdating(false));
	};

	const renderScreen = () => {
		return (
			<CustomDialog
				title={'Edit Team Information'}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				width={'400px'}
				actions={[
					...(isUpdating
						? [
							{
								render: () => (
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
										{' '}
										<CircularProgress size={20} />
										<span style={{ color: '#000' }}>{'Updating...'}</span>
									</div>
								),
							},
						]
						: [
							{
								render: () => {
									return (
										<MUIButton
											color='secondary'
											variant='contained'
											onClick={() => {
												setIsOpen(false);
											}}
										>
											Cancel
										</MUIButton>
									);
								},
							},
							{
								render: () => {
									if (isUpdating)
										return (
											<div style={{ display: 'flex' }}>
												<CircularProgress size={20} />
												<span style={{ color: '#000' }}>{'Updating...'}</span>
											</div>
										);
									return (
										<MUIButton
											color='primary'
											variant='contained'
											onClick={() => handleFormSubmit()}
											disabled={name == null || name.trim().length === 0}
										>
											Confirm
										</MUIButton>
									);
								},
							},
						]),
				]}
			>
				<Form
					initialValues={{
						name: get(data, 'name'),
						ownerName: get(data, 'managerName'),
					}}
					onSubmit={onSubmit}
				>
					{({ handleSubmit }) => {
						handleFormSubmit = handleSubmit;
						

						return (
							<div style={{ display: 'flex', flexDirection: 'column', padding: "16px 0px" }}>
								<div style={{ marginBottom: '15px', display: 'flex', flexDirection: 'column' }}>
									<Label label={'Name*'} />
									<CustomField
										name='name'
										component='input'
										placeholder='Team Name'
										error={name == null || name.trim().length == 0}
									/>
									{(name == null || name.trim().length == 0) && (
										<span style={{color: '#DE3737', marginTop: '5px' }}>{'Required'}</span>
									)}
									<OnChange name='name'>{value => setName(value)}</OnChange>
								</div>
								<div>
									<Label label={'Manager*'} />
									<SelectInput
										value={
											selectedManager.name &&
											selectedManager.name.trim().length > 0
												? selectedManager.name
												: selectedManager.userId
										}
										onClick={() =>
											getUserInfo().isAdmin || getUserInfo().isSuper
												? setSelectingManager(true)
												: {}
										}
										disabled={!getUserInfo().isAdmin && !getUserInfo().isSuper}
									/>
								</div>
							</div>
						);
					}}
				</Form>
			</CustomDialog>
		);
	};

	return (
		<div>
			{renderScreen()}
			<SelectManagerDialog
				isOpen={selectingManager}
				setIsOpen={setSelectingManager}
				selectedManager={selectedManager}
				setSelectedManager={setSelectedManager}
			/>
		</div>
	);
};

export default EditTeamDialog;
