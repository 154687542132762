import React from 'react';

import loadingIcon from 'assets/loadingIcon.svg';

const LoadingInfo = (props) => {
	const size = props.size ? props.size : 120;
	return (
		<div
			style={{
				width: size,
				height: size,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'relative',
				margin:'10px auto',
			}}
		>
			<img
				style={{
					width: '100%',
					height: '100%',
					position: 'absolute',
					top: 0,
					left: 0
				}}
				src={loadingIcon}
				alt='logo'
			/>
			<p style={{ color: '#000' }}>{props.text ? props.text : 'Loading...'}</p>
		</div>
	);
};

export default LoadingInfo;

