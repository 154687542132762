import React from 'react';
import placeholderSmall from 'assets/placeholderSmall.png';

const PanelInfoPlaceholderB = ({children = null, title = null}) => {
	return (
		<div
			style={{
				width: '422px',
				height: '100%',
				minHeight: '220px',
				margin: 'auto',
				backgroundColor: 'rgba(15, 67, 88, 1)',
				boxShadow: '0px 5px 20px #00000026',
				border: 'none',
				borderRadius: '4px',
				padding: '16px',
				position: 'relative',
			}}
		>
			{title && (
				<div
					style={{
						flex: '0 0 auto',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						backgroundColor: '#0F4358',
						marginBottom: '26px',
					}}
				>
					{title}
				</div>
			)}

			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr 1fr',
					gridTemplateRows: 1,
				}}
			>
				<img
					style={{
						width: '109px',
						height: '128px',
						left: '10%',
						top: '38px',
						gridColumn: '1 / -2',
					}}
					alt={'placeholder'}
					src={placeholderSmall}
				/>

				<div
					style={{
						color: '#80B8DF',
						position: 'relative',
						gridColumn: '2 / -1',
						minHeight: '128px',
						display: 'flex',
						justifyItems: 'center',
						alignContent: 'stretch',
					}}
				>
					<div style={{margin: 'auto'}}>{children}</div>
				</div>
			</div>
		</div>
	);
};

export default PanelInfoPlaceholderB;
