import React from 'react';
import get from 'lodash/get';

const StatusChip = ({source, record = {}, altRecord, width = '115px', letterSpacing}) => {
	const render = value => {
		var color = {};
		let customValue = '';
		var customStyle = {};

		// General.
		if (value === 'enabled') {
			color = {
				color: '#89C32D',
				borderColor: '#89C32D',
			};
		}

		if (value === 'disabled') {
			color = {
				color: '#F03333',
				borderColor: '#F03333',
			};
		}

		if (value === 'deleted') {
			color = {
				color: 'rgb(119, 119, 119)',
				borderColor: 'rgb(119, 119, 119)',
			};
		}

		// Lesson Status.
		if (value === 'not started') {
			color = {
				color: '#F0A033',
				borderColor: '#F0A033',
			};
		}

		if (value === 'viewed') {
			color = {
				color: '#1C7BA4',
				borderColor: '#1C7BA4',
			};
		}

		if (value === 'incomplete') {
			color = {
				color: '#F0A033',
				borderColor: '#F0A033',
			};
		}

		if (value === 'completed') {
			color = {
				color: '#89C32D',
				borderColor: '#89C32D',
			};
		}

		if (value === 'failed') {
			color = {
				color: '#F03333',
				borderColor: '#F03333',
			};
		}

		if (value === 'passed') {
			color = {
				color: '#89C32D',
				borderColor: '#89C32D',
			};
		}

		if (value === 'aced') {
			color = {
				color: '#32B0FB',
				borderColor: '#32B0FB',
			};
		}

		// Lesson Types.
		if (value === 'scenario') {
			color = {
				color: '#FB3297',
				borderColor: '#FB3297',
			};
		}

		if (value === 'story') {
			color = {
				color: '#346DEC',
				borderColor: '#346DEC',
			};
		}

		if (value === 'drill') {
			color = {
				color: '#6767E0',
				borderColor: '#6767E0',
			};
		}

		if (value === 'assessment') {
			color = {
				color: '#FF99CC',
				borderColor: '#FF99CC',
			};
		}

		if (value === 'dormant') {
			color = {
				color: '#000000',
				borderColor: '#000000',
			};
		}

		if (['pre-assessment', 'pre assessment'].includes(value)) {
			color = {
				color: '#32B0FB',
				borderColor: '#32B0FB',
			};
		}

		if (['post-assessment', 'post assessment'].includes(value)) {
			color = {
				color: '#F03333',
				borderColor: '#F03333',
			};
		}

		//training module status
		if (value === 'In Development') {
			color = {
				color: '#89C32D',
				borderColor: '#89C32D',
			};
		}

		if (value === 'In Review') {
			color = {
				color: '#F0A033',
				borderColor: '#F0A033',
			};
		}

		//environment type status

		if (value === 'Live') {
			color = {
				color: '#7ba2d5',
				borderColor: '#7ba2d5',
			};
		}

		if (value === 'Test') {
			color = {
				color: '#a899bd',
				borderColor: '#a899bd',
			};
		}

		// Publication Status
		if (source === 'publicationStatus') {
			customValue = value;

			const commonStyle = {
				color: '#FFFFFF',
				borderRadius: '20px',
			};
			switch (value) {
				case 'DRAFT':
					customStyle = {
						background: '#89C32D',
						borderColor: '#89C32D',
						...commonStyle,
					};
					break;

				case 'TALESPIN REVIEW':
					customStyle = {
						background: '#F0A033',
						borderColor: '#F0A033',
						...commonStyle,
					};
					break;
				case 'PUBLISHED':
					customStyle = {
						background: '#32B0FB',
						borderColor: '#32B0FB',
						...commonStyle,
					};
					break;
				default:
				//do nothing
			}
		}

		// customerProjectStatus
		if (source === 'customerProjectStatus') {
			customValue = value;

			const commonStyle = {
				color: '#FFFFFF',
				borderRadius: '20px',
			};
			switch (value) {
				case 'In Development':
					customValue = 'DRAFT';
					customStyle = {
						background: '#89C32D',
						borderColor: '#89C32D',
						...commonStyle,
					};
					break;

				case 'In Review':
					customValue = 'TALESPIN REVIEW';;
					customStyle = {
						background: '#F0A033',
						borderColor: '#F0A033',
						...commonStyle,
					};
					break;

				default:
				//do nothing
			}
		}

		var style = {
			borderWidth: '2px',
			borderStyle: 'solid',
			borderRadius: '4px',
			height: '32px',
			alignItems: 'center',
			textAlign: 'center',
			display: 'flex',
			padding: '5px',
			width: width,
			letterSpacing: letterSpacing,
			...color,
			...customStyle,
		};

		return (
			<div style={{...style}}>
				<span
					style={{
						fontSize: '10px',
						margin: 'auto',
						textTransform: 'uppercase',
						fontWeight: 'bolder',
					}}
				>
					{customValue ? customValue : value}
				</span>
			</div>
		);
	};

	if (altRecord) {
		record = altRecord;
	}

	if (!get(record, source)) {
		return <div>---</div>;
	}

	return render(get(record, source));
};

export default StatusChip;
