import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import {useState} from 'react';
import {useTheme} from '@material-ui/core';

const Collapsible = props => {

	const {children, title} = props;

	const theme = useTheme();

	const [collapsed, setIsCollapsed] = useState(true);

	return (
		<div>
			<div
				onClick={() => setIsCollapsed(!collapsed)}
				style={{
					backgroundColor: theme.palette.background.default,
					padding: '10px 10px 10px 15px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer'
				}}
			>
				<span style={{fontFamily: 'Montserrat Medium', fontSize: '14px'}}>
					{title}
				</span>
				{collapsed ? <AddIcon /> : <RemoveIcon />}
			</div>
			{!collapsed && children}
		</div>
	);
};

export default Collapsible;
