import React, {useEffect, useState} from 'react';
import 'chartjs-plugin-zoom';
import 'chartjs-plugin-annotation';
import Chart from 'chart.js';
import moment from 'moment';

import get from 'lodash/get';
import TSTheme from '../components/Theme';
import {loadObject} from '../utils/Util';
import EmbeddedShow from '../components/EmbeddedShow';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import EvenlySpace from '../assets/EvenlySpace.svg';
import SpaceByTime from '../assets/SpaceByTime.svg';
import {createStyles, withStyles, IconButton} from '@material-ui/core';

const styles = () =>
	createStyles({
		iconButton: {
			padding: '2px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '5px',
			cursor: 'pointer',
			marginBottom: '10px',
			borderWidth: '1px',
			boxShadow: '0px 5px 20px #00000026',
			backgroundColor: TSTheme.palette.secondary.main,
		},
	});

const SkillGraphPanel = props => {
	const {
		unprocessedData,
		selectedAttemptValue,
		fromComponentFlag,
		attemptChangeHandler,
		preAssessmentDate,
		title,
		classes,
	} = props;

	const runwayOrgConfig = loadObject('runwayOrgConfig');

	const namedSkillColourList = JSON.parse(
		get(runwayOrgConfig, 'config.namedSkillColourList')
	);

	const [chartData, setChartData] = useState({});

	const [chartInstance, setChartInstance] = useState(null);

	const [ctx, setCtx] = useState(null);

	const [isPanSelected, setIsPanSelected] = useState(false);

	const [isZoomSelected, setIsZoomSelected] = useState(true);

	const [graphContainerId] = useState(Date.now());

	const [graphCanvasId, setgraphCanvasId] = useState(null);

	const [evenSpaceMode, setEvenSpaceMode] = useState(true);

	let processedData = [];

	var dummyDate = null;

	switch (fromComponentFlag) {
		case 'LessonDetailsView':
		case 'SkillsTabView':
			processDataFromLessonDetailsView();
			break;
		case 'PerformanceDetailView':
			processDataFromPerformanceDetailView();
			break;
		default:
		// do nothing
	}

	function processDataFromLessonDetailsView() {
		for (let i = 0; i < unprocessedData.length; i++) {
			let obj = {attemptDate: unprocessedData[i].createdAt};

			for (let j = 0; j < unprocessedData[i].skillData.length; j++) {
				let skill = unprocessedData[i].skillData[j];
				obj[skill.id] = {
					label: skill.displayName,
					value: parseInt(skill.subValue),
					borderColor: namedSkillColourList[j],
					backgroundColor: namedSkillColourList[j],
				};
			}
			processedData.push(obj);
		}
	}

	function processDataFromPerformanceDetailView() {
		dummyDate = new Date(unprocessedData[0].createdAt);
		dummyDate.setDate(dummyDate.getDate() - 1);
		var preAssessmentObj = {attemptDate: preAssessmentDate};

		for (let i = 0; i < unprocessedData.length; i++) {
			let obj = {attemptDate: unprocessedData[i].createdAt};

			for (let j = 0; j < unprocessedData[i].namedSkills.length; j++) {
				let skill = unprocessedData[i].namedSkills[j];
				obj[skill.id] = {
					label: skill.displayName,
					value: skill.postScoreNumerator,
					borderColor: namedSkillColourList[j],
					backgroundColor: namedSkillColourList[j],
				};
				if (i === 0) {
					preAssessmentObj[skill.id] = {
						label: skill.displayName,
						value: skill.preScoreNumerator,
						borderColor: namedSkillColourList[j],
						backgroundColor: namedSkillColourList[j],
					};
				}
			}
			if (i === 0) {
				processedData.push(preAssessmentObj);
			}
			processedData.push(obj);
		}
	}

	const chart = attemptValue => {
		var graphContainer = document.getElementById(graphContainerId);
		graphContainer.innerHTML = '&nbsp';
		var canvasElement = document.createElement('canvas');
		var canvasId = Date.now();
		canvasElement.id = canvasId;
		setgraphCanvasId(canvasId);
		canvasElement.height = 100;
		graphContainer.appendChild(canvasElement);

		var labels = processedData?.map(item => {
			return item.attemptDate;
		});

		function array_move(arr, old_index, new_index) {
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1;
				while (k--) {
					arr.push(undefined);
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
			return arr; // for testing
		}

		function getDataSet() {
			var allDataSets = [];
			var obj = processedData[0];
			var objKeys = Object.getOwnPropertyNames(obj);
			var overallIndex = 0;
			objKeys.forEach((item, idx) => {
				var dataPoints = [];
				var label = '';
				var borderColor = '';
				var backgroundColor = '';
				if (item !== 'attemptDate') {
					for (var i = 0; i < processedData.length; i++) {
						var dataObject = processedData[i];
						dataPoints.push(dataObject[item].value);
						label = dataObject[item].label;
						borderColor = dataObject[item].borderColor;
						backgroundColor = dataObject[item].backgroundColor;
					}
					var dataSet = {
						label: label,
						data: dataPoints,
						fill: false,
						borderColor: borderColor,
						backgroundColor: backgroundColor,
					};
					if (dataSet.label.includes('Overall')) {
						dataSet.borderColor = TSTheme.palette.primary.main;
						dataSet.backgroundColor = TSTheme.palette.primary.main;
						overallIndex = idx - 1;
					}
					allDataSets.push(dataSet);
				}
			});
			allDataSets = [...array_move(allDataSets, overallIndex, 0)];
			return allDataSets;
		}

		const data = {
			labels: labels,
			datasets: [],
		};
		data.datasets = getDataSet();
		setChartData(data);
		var ctx = document.getElementById(canvasId).getContext('2d');
		chartConfig.options.annotation.annotations[0].value = attemptValue;
		chartConfig.options.annotation.annotations[0].endValue = attemptValue;
		chartConfig.data = data;
		var chartInstance = new Chart(ctx, chartConfig);
		chartInstance.getDatasetMeta(0).hidden = true;
		chartInstance.update();
		// chartInstance.data = data;
		// chartInstance.update()
		canvasElement.addEventListener('click', evt =>
			canvasClick(evt, chartInstance)
		);
		setChartInstance(chartInstance);
		setEvenSpaceMode(true);
		setCtx(ctx);
	};

	useEffect(() => {
		chart(selectedAttemptValue);
	}, [selectedAttemptValue]);

	function toolTipCallback(tooltipItem, data) {
		var multistringText = [];
		var label = data.datasets[tooltipItem.datasetIndex].label;
		var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
		multistringText.push(' ' + label + ': ' + value);
		return multistringText;
	}

	const resetChart = () => {
		var graphCanvas = document.getElementById(graphCanvasId);
		graphCanvas.removeAttribute('onClick');
		chartInstance.destroy();
		chartConfig.options.annotation.annotations[0].value = selectedAttemptValue;
		chartConfig.options.annotation.annotations[0].endValue = selectedAttemptValue;
		var newChartInstance = new Chart(ctx, chartConfig);
		newChartInstance.data = chartData;
		newChartInstance.getDatasetMeta(0).hidden = true;
		newChartInstance.update();
		graphCanvas.addEventListener('click', evt =>
			canvasClick(evt, newChartInstance)
		);
		setChartInstance(newChartInstance);

		setIsPanSelected(false);
		setIsZoomSelected(true);
		setEvenSpaceMode(true);
	};

	const enablePan = () => {
		setIsPanSelected(true);
		setIsZoomSelected(false);
		chartInstance.options.pan.enabled = true;
		chartInstance.options.zoom.enabled = false;
		chartInstance.update();
	};

	const toggleXaxisType = () => {
		setEvenSpaceMode(!evenSpaceMode);
	};

	useEffect(() => {
		if (evenSpaceMode) {
			if (chartInstance) chartInstance.options.scales.xAxes[0].type = undefined;
		} else {
			if (chartInstance) chartInstance.options.scales.xAxes[0].type = 'time';
		}
		if (chartInstance) chartInstance.update();
	}, [evenSpaceMode]);

	const enableZoom = () => {
		setIsPanSelected(false);
		setIsZoomSelected(true);
		chartInstance.options.pan.enabled = false;
		chartInstance.options.zoom.enabled = true;
		chartInstance.update();
	};

	function getXaxisLabel(value, index, values) {
		if (
			(value === preAssessmentDate ||
				values[index].value === preAssessmentDate) &&
			fromComponentFlag === 'PerformanceDetailView'
		) {
			return 'Pre Assessment';
		} else {
			return moment(value).format('L hh:mm a');
		}
	}

	const range_min = processedData[0].attemptDate; //start date

	const range_max = processedData[processedData.length - 1].attemptDate; //end date

	var chartConfig = {
		type: 'line',
		height: 70,
		data: null,
		options: {
			legend: {
				position: 'top',
				align: 'middle',
				labels: {
					boxWidth: 12,
				},
				onHover: function(e) {
					e.target.style.cursor = 'pointer';
				},
			},
			hover: {
				onHover: function(e) {
					var point = this.getElementAtEvent(e);
					if (point.length) e.target.style.cursor = 'pointer';
					else e.target.style.cursor = 'default';
				},
			},
			responsive: true,
			tooltips: {
				enabled: true,
				interaction: {
					mode: 'index',
				},
				callbacks: {
					label: (tooltipItem, data) => {
						return toolTipCallback(tooltipItem, data);
					},
					title: (tooltipItems, data) => {
						var attemptNumber = 0;
						if (fromComponentFlag !== 'PerformanceDetailView') {
							attemptNumber = tooltipItems[0].index + 1;
						} else {
							attemptNumber = tooltipItems[0].index;
						}

						return (
							'Attempt #' +
							attemptNumber +
							' ' +
							moment(tooltipItems[0].xLabel).format('L hh:mm a')
						);
					},
				},
			},
			scales: {
				yAxes: [
					{
						id: 'yAxis',
						ticks: {
							autoSkip: true,
							beginAtZero: true,
						},
					},
				],
				xAxes: [
					{
						distribution: 'linear',
						min: range_min,
						max: range_max,
						time: {
							unit: 'day',
							unitStepSize: 1,
							displayFormats: {
								day: 'L hh:mm a',
							},
						},
						id: 'xAxis',
						ticks: {
							source: 'labels',
							autoSkip: false,
							callback: (value, index, values) => {
								return getXaxisLabel(value, index, values);
							},
							maxRotation: 60,
							minRotation: 60,
						},
					},
				],
			},
			pan: {
				enabled: false,
				mode: 'x',
				speed: 1,
				threshold: 1,
				rangeMax: {
					x: range_max,
				},
				rangeMin: {
					x: range_min,
				},
			},
			zoom: {
				sensitivity: 0.5,
				enabled: true,
				drag: true,
				mode: 'x',
				rangeMax: {
					x: range_max,
				},
				rangeMin: {
					x: range_min,
				},
				onZoom: chart => {
					onZoomFn(chart);
				},
			},
			annotation: {
				annotations: [
					{
						type: 'line',
						mode: 'vertical',
						scaleID: 'xAxis',
						value: 0,
						endValue: 0,
						borderColor: '#EF3F45',
						borderWidth: 3,
					},
				],
			},
		},
	};

	const onZoomFn = chartInstance => {
		if (chartInstance.chart.options.scales.xAxes[0].type !== 'time') return;
		var leftEnd = chartInstance.chart.getDatasetMeta(0).dataset._scale.chart
			.scales['xAxis']._table[0].time;
		var rightEnd = chartInstance.chart.getDatasetMeta(0).dataset._scale.chart
			.scales['xAxis']._table[1].time;

		var labels = chartInstance.chart.config.data.labels;
		var labelsCopy = [...labels];

		var closestleft = getClosestItem(leftEnd, labels, 'left');
		var closestRight = getClosestItem(rightEnd, labels, 'right');

		var closestLeftIndex = closestleft === 0 ? 0 : labels.indexOf(closestleft);
		var closestRightIndex =
			closestRight === 0 ? labels.length - 1 : labels.indexOf(closestRight);
		var spliceStartIdx =
			closestLeftIndex === 0
				? getClosestValuesIndex(closestLeftIndex, leftEnd, labels)
				: closestLeftIndex + 1;
		var selectionPoints = labelsCopy.splice(
			spliceStartIdx,
			closestRightIndex - spliceStartIdx
		);
		if (selectionPoints.length > 1) {
			chartInstance.chart.options.scales.xAxes[0].time.min = selectionPoints[0];
			chartInstance.chart.options.scales.xAxes[0].time.max =
				selectionPoints[selectionPoints.length - 1];
			chartInstance.chart.update();
		} else {
			if (closestleft === 0) {
				chartInstance.chart.options.scales.xAxes[0].time.min = labels[0];
				chartInstance.chart.options.scales.xAxes[0].time.max = rightEnd;
				chartInstance.chart.update();
			} else if (closestRight === 0) {
				chartInstance.chart.options.scales.xAxes[0].time.min = leftEnd;
				chartInstance.chart.options.scales.xAxes[0].time.max =
					labels[labels.length - 1];
				chartInstance.chart.update();
			} else {
				chartInstance.chart.options.scales.xAxes[0].time.min = closestleft;
				chartInstance.chart.options.scales.xAxes[0].time.max = closestRight;
				chartInstance.chart.update();
			}
		}
	};

	function getClosestValuesIndex(closestLeftidx, value, labels) {
		var leftdiff = value - labels[closestLeftidx];
		var rightdiff = labels[closestLeftidx + 1] - value;
		if (leftdiff > rightdiff) {
			return closestLeftidx + 1;
		} else {
			return closestLeftidx;
		}
	}

	function getClosestItem(item, labels, direction) {
		var foundVal = 0;
		for (var i = 0; i < labels.length; i++) {
			if (labels[i] < item && labels[i + 1] > item) {
				switch (direction) {
					case 'left':
						foundVal = labels[i];
						break;
					case 'right':
						foundVal = labels[i + 1];
						break;
					default:
					// do nothing
				}
				break;
			}
		}
		return foundVal;
	}

	const canvasClick = (evt, instance) => {
		var activePoints = instance.getElementsAtEvent(evt);

		if (activePoints[0]) {
			var chartData = activePoints[0]['_chart'].config.data;
			var idx = activePoints[0]['_index'];
			var label = chartData.labels[idx];

			if (fromComponentFlag === 'PerformanceDetailView' && idx === 0) {
				return;
			}
			attemptChangeHandler(unprocessedData, label);
		}
	};

	return (
		<div style={{marginTop: '24px'}}>
			<EmbeddedShow title={title}>
				<div style={{display: 'flex', flexDirection: 'row'}}>
					<div id={graphContainerId} style={{width: '97%'}}>
						<div>
							<canvas
								id='skillRetentionGraph'
								onClick={evt => canvasClick(evt)}
							></canvas>
						</div>
					</div>
					<div style={{width: '3%'}}>
						<IconButton
							id='zoomBtnDiv'
							variant={'outlined'}
							color='primary'
							onClick={enableZoom}
							className={classes.iconButton}
							style={{
								border: isZoomSelected ? '1px solid blue' : ' none',
							}}
						>
							<SearchIcon color='primary' />
						</IconButton>
						<IconButton
							id='panBtnDiv'
							variant={'outlined'}
							color='primary'
							onClick={enablePan}
							className={classes.iconButton}
							style={{
								border: isPanSelected ? '1px solid blue' : ' none',
							}}
						>
							<OpenWithIcon color='primary' />
						</IconButton>
						<IconButton
							id='xAxesType'
							variant={'outlined'}
							color='primary'
							onClick={toggleXaxisType}
							className={classes.iconButton}
						>
							{evenSpaceMode ? (
								<img alt={'Space By Time Icon'} src={SpaceByTime} width='24' />
							) : (
								<img alt={'Evenly Space Icon'} src={EvenlySpace} width='24' />
							)}
						</IconButton>
						<IconButton
							variant={'outlined'}
							color='primary'
							onClick={resetChart}
							className={classes.iconButton}
						>
							<RefreshIcon color='primary' />
						</IconButton>
					</div>
				</div>
			</EmbeddedShow>
		</div>
	);
};

export default withStyles(styles)(SkillGraphPanel);
