import { SvgIcon } from "@material-ui/core";

export default function FilterAltIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 17">
      <path
        d="M6.22748 7.85685L1.86462 3.89756C1.32502 3.40787 1.66588 2.5 2.38934 2.5L13.6107 2.5C14.3341 2.5 14.675 3.40788 14.1354 3.89756L9.77259 7.85678V13.2082C9.77259 13.4803 9.63662 13.7337 9.41159 13.8811L7.44199 15.171C6.91777 15.5144 6.22748 15.1319 6.22748 14.4982V7.85685Z"
        stroke={'currentColor'}
        strokeWidth="1.5"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
