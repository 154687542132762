import {
	EmbeddedShow,
	ScreenTitle,
	StatsTilePanel,
	TextValue,
	TimeDateField,
} from 'components';
import {useDataProvider, useVersion, withDataProvider} from 'react-admin';


import {GET_STATS} from 'services/customActions';
import LoadingInfo from 'components/loading/LoadingInfo';
import LoadingStat from 'components/loading/LoadingStat';
import PanelStat from 'components/Panels/PanelStat';
import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import TrainingCatalogue from 'components/TrainingCatalogue';
import compose from 'recompose/compose';
import {loadObject} from 'utils/Util';
import {useHistory, useLocation} from 'react-router-dom';
import {getUserInfo} from 'utils/Data';
import CPDPublicationStatus from "sections/Training/Show/CPDPublicationStatus";

const TrainingShow = props => {
	const history = useHistory();

	const location = useLocation();

	const dataProvider = useDataProvider();

	const version = useVersion();

	const [state, setState] = useState({statsData: null, isStatsLoading: true});

	const {id, trainingData, onUpdateTraining, trainingStatus, isTrainingDataLoading} = props;

	useEffect(() => {
		if (getUserInfo().isAuthenticated) fetchTrainingStats();
	}, [version]);

	const fetchTrainingStats = async () => {
		setState({...state, isStatsLoading: true });
		await dataProvider(GET_STATS, 'TrainingStats', {id}).then(res =>
			setState({statsData: res.data, isStatsLoading: false})
		);
	};

	if (
		trainingData &&
		Object.keys(trainingData).length === 0 &&
		trainingData.constructor === Object
	) {
		return <Redirect to='/trainings' />;
	}

	const handleUpdate = async () => {
		await onUpdateTraining();
	};

	const orgConfig = loadObject('runwayOrgConfig');

	const contentCompanyTeamId =
		orgConfig && orgConfig.config && orgConfig.config.contentCompanyTeamId
			? orgConfig.config.contentCompanyTeamId
			: '';

	const isTrainingModuleEditor = () => {
		if (contentCompanyTeamId === trainingData.trainingDetail.contentOwnerTeamId)
			return getUserInfo().trainingModuleEditor;
		else return false;
	};

	return (
		<div>
			<ScreenTitle
				{...props}
				list={'/trainings'}
				title={'Module Details'}
				basePath={props.basePath}
				resource={props.resource}
				listButton
				backAction={() =>
					history.push({pathname: '/trainings', state: location.state})
				}
				actions={[]}
			/>

			{trainingData &&
				trainingStatus &&
				trainingStatus.cpdWebIdentifier &&
				!isTrainingDataLoading &&
				(contentCompanyTeamId === trainingData?.trainingDetail?.contentOwnerTeamId) &&
				getUserInfo()?.trainingModuleEditor && (
					<CPDPublicationStatus
						trainingData={trainingData}
						trainingStatus={trainingStatus}
					/>
				)
			}

			{trainingData && trainingStatus && !isTrainingDataLoading ? (
				<TrainingCatalogue
					trainingData={trainingData}
					isExpandMore={
						loadObject('expandTrainingcatalogue') === null
							? true
							: loadObject('expandTrainingcatalogue')
					}
					toggleKey={'expandTrainingcatalogue'}
					isTSAPlayable={props.isTSAPlayable}
					allowEditing={
						(isTrainingModuleEditor() &&
							!trainingStatus.publishedStatus.reviewInProgress) ||
						getUserInfo().role === 'TS-super' ||
						getUserInfo().role === 'TS-admin'
					}
					onUpdate={() => handleUpdate()}
					information={
						<EmbeddedShow
							headerless
							embedded
							actions={[]}
							details={[
								{
									label: 'Name',
									render: () => (
										<TextValue
											record={trainingData}
											source='name'
											maxWidth={'300px'}
										/>
									),
								},
								{
									label: 'Series',
									render: () => (
										<TextValue
											record={trainingData}
											source='seriesName'
											maxWidth={'300px'}
										/>
									),
								},
								{
									label: 'Content Owner',
									render: () => (
										<TextValue
											record={trainingData}
											source='contentUpdatableOwner'
											maxWidth={'300px'}
										/>
									),
								},
								{
									label: 'Created',
									render: () => (
										<TimeDateField record={trainingData} source='createdAt' />
									),
								},
								{
									label: 'Modified',
									render: () => (
										<TimeDateField record={trainingData} source='updatedAt' />
									),
								},
								{
									label: 'Id',
									render: () => <TextValue record={trainingData} source='id' />,
								},
							]}
						/>
					}
				></TrainingCatalogue>
			) : (
				<LoadingInfo />
			)}
			<div style={{paddingTop: '24px'}}>
				{state.isStatsLoading ? (
					<PanelStat>
						<LoadingStat />
					</PanelStat>
				) : (
					<StatsTilePanel stats={state.statsData} />
				)}
			</div>
		</div>
	);
};

const enhance = compose(withDataProvider);

export default enhance(TrainingShow);
