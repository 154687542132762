import * as React from 'react';

function CircleLoading(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 59.006 60' {...props}>
            <g data-name='circle loading' transform='translate(-930.994 -666)'>
                <circle
                    data-name='Ellipse 126'
                    cx={3.5}
                    cy={3.5}
                    r={3.5}
                    transform='translate(957 666)'
                    fill='#83879d'
                />
                <circle
                    data-name='Ellipse 131'
                    cx={3.5}
                    cy={3.5}
                    r={3.5}
                    transform='translate(957 719)'
                    fill='#83879d'
                />
                <circle
                    data-name='Ellipse 126'
                    cx={3.5}
                    cy={3.5}
                    r={3.5}
                    transform='rotate(-33 1605.173 -1251.439)'
                    fill='#83879d'
                />
                <circle
                    data-name='Ellipse 126'
                    cx={3.5}
                    cy={3.5}
                    r={3.5}
                    transform='rotate(-70 957.573 -320.369)'
                    fill='#83879d'
                />
                <circle
                    data-name='Ellipse 126'
                    cx={3.5}
                    cy={3.5}
                    r={3.5}
                    transform='scale(-1) rotate(70.99 30.521 -1008.311)'
                    fill='#83879d'
                />
                <circle
                    data-name='Ellipse 126'
                    cx={3.5}
                    cy={3.5}
                    r={3.5}
                    transform='rotate(-144 590.205 207.456)'
                    fill='#83879d'
                />
                <circle
                    data-name='Ellipse 131'
                    cx={3.5}
                    cy={3.5}
                    r={3.5}
                    transform='rotate(-144 598.813 180.955)'
                    fill='#83879d'
                />
                <circle
                    data-name='Ellipse 105'
                    cx={5.5}
                    cy={5.5}
                    r={5.5}
                    transform='translate(979 699)'
                    fill='#b1b4c3'
                />
                <circle data-name='Ellipse 106' cx={5} cy={5} r={5} transform='translate(970 713)' fill='#fff' />
                <circle
                    data-name='Ellipse 107'
                    cx={4.5}
                    cy={4.5}
                    r={4.5}
                    transform='translate(981 683)'
                    fill='#b1b4c3'
                />
            </g>
        </svg>
    );
}

const MemoCircleLoading = React.memo(CircleLoading);
export default MemoCircleLoading;
