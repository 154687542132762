import React, {useEffect, useState} from 'react';
import {useTranslate} from 'react-admin';
import StaticTable from 'components/StaticTable';
import Label from 'components/Label';
import MUIButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {createStyles, withStyles} from '@material-ui/core/styles';
import { AddOutlined } from '@material-ui/icons';

const myStyles = theme =>
	createStyles({
		tableRow: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			columnGap: '10px',
			marginBottom: '10px',
		},
		deleteBtn: {
			paddingRight: '10px',
			right: '-14px',
		},
		tableContainer: {
			height: '300px',
			overflowY: 'scroll',
			'&::-webkit-scrollbar': {
				width: '0.4em',
				backgroundColor: '#ecf4f6',
			},
			'&::-webkit-scrollbar-track': {
				boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				width: '0.4em',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#346dec',
				width: '0.4em',
				outline: 'none',
				borderRadius: '20px',
			},
		},
		errorInfo: {
			fontFamily: 'Montserrat Italic',
			fontSize: '12px',
			color: 'red',
		},
	});

function ConfigureUserDetailsDialog({data, onValuesChange, classes, ...props}) {
	const translate = useTranslate();

	const [updatedData, setUpdatedData] = useState({});
	const [tableValues, setTableValues] = useState([]);
	const [tableValuesCopy, setTableValuesCopy] = useState([]);
	const [
		combineStringsCheckboxValue,
		setCombineStringsCheckboxValue,
	] = useState(false);
	const [appendDigitsCheckboxValue, setAppendDigitsCheckboxValue] = useState(
		false
	);
	const [
		appendSpecialCharCheckboxValue,
		setAppendSpecialCharCheckboxValue,
	] = useState(false);
	const [newText, setNewText] = useState('');
	const [searchText, setSearchText] = useState('');
	const [isDuplicate, setIsDuplicate] = useState(false);
	const [addOnEnter, setAddOnEnter] = useState(false);

	useEffect(() => {
		setUpdatedData(data);
		setTableValues(data.values);
		setTableValuesCopy(data.values);
		setCombineStringsCheckboxValue(data.combineStrings);
		setAppendDigitsCheckboxValue(data.addTwoDigits);
		setAppendSpecialCharCheckboxValue(data.specialCharacter);
	}, [data]);

	useEffect(() => {
		updatedData.values = tableValuesCopy;
		updatedData.combineStrings = combineStringsCheckboxValue;
		updatedData.addTwoDigits = appendDigitsCheckboxValue;
		updatedData.specialCharacter = appendSpecialCharCheckboxValue;

		onValuesChange(updatedData);
	}, [
		tableValuesCopy,
		combineStringsCheckboxValue,
		appendDigitsCheckboxValue,
		appendSpecialCharCheckboxValue,
	]);

	useEffect(() => {
		const add = e => {
			if (e.keyCode === 13) {
				setAddOnEnter(true);
			}
		};
		document.addEventListener('keydown', add);
		return () => document.removeEventListener('keydown', add);
	}, []);

	useEffect(() => {
		if (addOnEnter) {
			handleAddBtn();
		}
	}, [addOnEnter]);

	const onAddTextChange = event => {
		setNewText(event.target.value);
	};

	const handleAddBtn = () => {
		if (newText.trim() !== '') {
			setAddOnEnter(false);
			const copy = [...tableValues];
			const textToAdd = [
				...newText.split(',').map(item => item.replace(/ /g, '')),
			];
			const textToAddCopy = [...textToAdd];
			let duplicateItems = '';
			textToAdd.forEach((item, idx) => {
				if (copy.find(item1 => item1 === item) !== undefined) {
					duplicateItems += item + ', ';
					textToAddCopy.splice(
						textToAddCopy.findIndex(item2 => item2 === item),
						1
					);
				}
			});
			if (duplicateItems.length !== 0) {
				setNewText(duplicateItems.substring(0, duplicateItems.length - 2));
				setIsDuplicate(true);
			} else {
				setNewText('');
				setIsDuplicate(false);
			}
			copy.push(...textToAddCopy);
			setTableValues(copy);
			setTableValuesCopy(copy);
			setSearchText('');
		}
	};

	const onDeleteItemClicked = (item, index) => {
		const copy = [...tableValuesCopy];
		const indexOfItem = copy.findIndex(element => element === item);
		copy.splice(indexOfItem, 1);
		setTableValues(copy);
		setTableValuesCopy(copy);
		setSearchText('');
	};

	const onSearchTextChange = event => {
		const value = event.target.value;
		setSearchText(value);
		if (value === '') {
			setTableValues(tableValuesCopy);
			return;
		}
		const filteredData = tableValues.filter(item =>
			item.toLowerCase().includes(value.toLowerCase())
		);
		setTableValues(filteredData);
	};
	return (
		<>
			<div className={classes.tableRow}>
				<TextField
					id='standard-basic'
					placeholder='Wing, Ding, Cat'
					value={newText}
					onChange={event => onAddTextChange(event)}
				/>
				<MUIButton
					color='primary'
					variant='contained'
					style={{height: '28px'}}
					onClick={() => handleAddBtn()}
					startIcon={<AddOutlined />}
				>
					{translate('Add')}
				</MUIButton>
				<TextField
					id='input-with-icon-textfield'
					label={'Search'}
					placeholder={'Search'}
					value={searchText}
					onChange={onSearchTextChange}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</div>
			{isDuplicate && (
				<div>
					<span className={classes.errorInfo}>
						{translate('Not Added. String already present')}
					</span>
				</div>
			)}
			<div className={classes.tableContainer}>
				<StaticTable
					data={tableValues}
					columns={[
						{
							id: 'string',
							name: (
								<span style={{fontWeight: 'bold'}}>
									{translate(`Strings (${tableValues.length})`)}
								</span>
							),
						},
					]}
					actions={[
						{
							id: 'delete',
							icon: (
								<IconButton className={classes.deleteBtn} aria-label='Close'>
									<CloseIcon />
								</IconButton>
							),
							onClick: onDeleteItemClicked,
						},
					]}
				/>
			</div>
			<div className={classes.tableRow}>
				<Checkbox
					checked={combineStringsCheckboxValue}
					onChange={event =>
						setCombineStringsCheckboxValue(event.target.checked)
					}
					inputProps={{'aria-label': 'Strings checkbox'}}
				/>
				<Label
					paddingBottom={0}
					label={translate('Combine strings into random pairs (eg. MsrTig)')}
				/>
			</div>
			<div className={classes.tableRow}>
				<Checkbox
					checked={appendDigitsCheckboxValue}
					onChange={event => setAppendDigitsCheckboxValue(event.target.checked)}
					inputProps={{'aria-label': 'Digits checkbox'}}
				/>
				<Label
					paddingBottom={0}
					label={translate('Append a two digit number (eg. Tig88)')}
				/>
			</div>
			<div className={classes.tableRow}>
				<Checkbox
					checked={appendSpecialCharCheckboxValue}
					onChange={event =>
						setAppendSpecialCharCheckboxValue(event.target.checked)
					}
					inputProps={{'aria-label': 'Special charaters checkbox'}}
				/>
				<Label
					paddingBottom={0}
					label={translate('Include a special charater (eg CircleTriangle#88)')}
				/>
			</div>
		</>
	);
}

export default withStyles(myStyles)(ConfigureUserDetailsDialog);
